<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"ADD_GROUP" | translate}}</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-10 p-2">
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"NAME" | translate}}:</label><br>
                        <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="groupName" (ngModelChange)="groupName=$event" type="text">
                    </div>
                </div>
            </div>
            <!-- Modal Footer -->
            <div class="flex justify-center items-center mt-4">
                <button type="button" 
                    class="button-green" 
                    (click)="save()">
                    {{"SAVE" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

