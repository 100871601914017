import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {

  getFileDataUpdated = new Subject<any>();
  getRiskFileDataUpdated = new Subject<any>();
  getSecurityFileDataUpdated = new Subject<any>();
  getSecurityExtFileDataUpdated = new Subject<any>();
  getProductFileDataUpdated = new Subject<any>();
  getPictureFileDataUpdated = new Subject<any>();

  constructor(private http: HttpClient) { }

  upload(file: File) {

    const formData: FormData = new FormData();
    if (file.text) {
      var text = file.text.toString();
      var params = new HttpParams();
      params = params.append("name", sanitizeText(text.split('|')[0]));
      params = params.append("description",text.split('|')[1]);
    }

    formData.append('file', file);

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    if (!params) {
      this.http.post<any>(BackendUrl + "/file/upload", formData, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {
          this.getFileDataUpdated.next(response);
        },
        error: error => {
          this.getFileDataUpdated.next(null);
        }
    });
    } else {
      this.http.post<any>(BackendUrl + "/file/upload", formData, {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
          this.getFileDataUpdated.next(response);
        },
        error: error => {
          this.getFileDataUpdated.next(null);
        }
      });
    }
  }

  uploadRiskfile(file: File) {

    const formData: FormData = new FormData();

    formData.append('file', file);

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/file/upload", formData, {headers: headers})
    .pipe(take(1)).subscribe({next: response => {
          this.getRiskFileDataUpdated.next(response);
        },
        error: error => {
          this.getRiskFileDataUpdated.next(null);
        }
    });
  }

  uploadSecurityfile(file: File) {

    const formData: FormData = new FormData();

    formData.append('file', file);

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/file/upload", formData, {headers: headers})
    .pipe(take(1)).subscribe({next: response => {
          this.getSecurityFileDataUpdated.next(response);
        },
        error: error => {
          this.getSecurityFileDataUpdated.next(null);
        }
    });
  }

  uploadSecurityExtfile(file: File) {

    const formData: FormData = new FormData();

    formData.append('file', file);

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/file/upload", formData, {headers: headers})
    .pipe(take(1)).subscribe({next: response => {
          this.getSecurityExtFileDataUpdated.next(response);
        },
        error: error => {
          this.getSecurityExtFileDataUpdated.next(null);
        }
    });
  }

  uploadProductfile(file: File) {

    const formData: FormData = new FormData();

    formData.append('file', file);

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/file/upload", formData, {headers: headers})
    .pipe(take(1)).subscribe({next: response => {
          this.getProductFileDataUpdated.next(response);
        },
        error: error => {
          this.getProductFileDataUpdated.next(null);
        }
    });
  }

  uploadPicturefile(file: File) {

    const formData: FormData = new FormData();

    formData.append('file', file);

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/file/upload", formData, {headers: headers})
    .pipe(take(1)).subscribe({next: response => {
          this.getPictureFileDataUpdated.next(response);
        },
        error: error => {
          this.getPictureFileDataUpdated.next(null);
        }
    });
  }

  public getFileDataListener() {
    return this.getFileDataUpdated.asObservable();
  }

  public getRiskFileDataListener() {
    return this.getRiskFileDataUpdated.asObservable();
  }

  public getSecurityFileDataListener() {
    return this.getSecurityFileDataUpdated.asObservable();
  }

  public getSecurityExtFileDataListener() {
    return this.getSecurityExtFileDataUpdated.asObservable();
  }

  public getProductFileDataListener() {
    return this.getProductFileDataUpdated.asObservable();
  }

  public getPictureFileDataListener() {
    return this.getPictureFileDataUpdated.asObservable();
  }
}

function sanitizeText(text) {
  // Replace en dash with hyphen
  text = text.replace(/–/g, '-');
  return text;
}
