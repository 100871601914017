import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { AuthService } from '../auth/auth.service';
import { take } from 'rxjs/operators';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})
export class SubstanceService {

  getSubstanceDataUpdated = new Subject<any>();
  getSubstanceDataCheck = new Subject<any>();

  constructor(private router: Router,
              private authService: AuthService,
              private http: HttpClient,
              private utilService: UtilService) {}

  getAll() {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http.get<any>(BackendUrl + "/substances", this.utilService.setHeadersAndParamsObject())
      .pipe(take(1)).subscribe({
        next: (substance) => {
          this.getSubstanceDataUpdated.next(substance);
        },
        error: (error) => {
          this.getSubstanceDataUpdated.next(null);
        },
    });
  }

  getSingle(substanceId) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http.get<any>(BackendUrl + "/substances/" + substanceId, this.utilService.setHeadersAndParamsObject())
    .pipe(take(1)).subscribe({
      next: (substances) => {
        this.getSubstanceDataUpdated.next(substances);
      },
      error: (error) => {
        this.getSubstanceDataUpdated.next(null);
      },
    });
  }

  createSubstanceWithId(substance){
    this.http.post(BackendUrl + '/substances/', substance, this.utilService.setHeadersAndParamsObject())
    .pipe(take(1)).subscribe({
      next: (values) => {
        this.router.navigate(["substances"])
      },
      error: (error) => {
        return;
      },
    })
  }

  updateSubstanceWithId(id,substance){
    this.http.put(BackendUrl + '/substances/' + id, substance, this.utilService.setHeadersAndParamsObject())
    .pipe(take(1)).subscribe({
      next: (values) => {
        this.router.navigate(["substances"])
      },
      error: (error) => {
        return;
      },
    })
  }

  deleteSubstanceWithId(id){
    this.http.delete(BackendUrl + '/substances/'+id, this.utilService.setHeadersAndParamsObject())
    .pipe(take(1)).subscribe({
      next: (values) => {
        this.router.navigate(["substances"])
      },
      error: (error) => {
        return;
      },
    })
  }

  getcDocFileWithId(fileId) {
    let token = localStorage.getItem('token');
    if (localStorage.getItem('rootOrganisation', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('rootOrganisation', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get(BackendUrl + '/organisations/' + orgId + '/substance/file/' + fileId, {headers: headers, responseType: "blob"}).pipe(take(1)).subscribe({next: response => {
          var file = new Blob([response], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          this.getSubstanceDataUpdated.next(fileURL);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getSubstanceDataUpdated.next(null);
        }
      });
    }
  }

  checkIfSubstanceExists ( substance ) {
    let token = localStorage.getItem( "token" );
    const headers = new HttpHeaders( {
      Accept: "application/json",
      Authorization: token,
    } );
    let params = new HttpParams();
    params = params.append( "substance", JSON.stringify(substance) );
    this.http.get(BackendUrl + '/substances/checkifsubstanceexists', { headers:headers, params:params} )
      .pipe(take(1)).subscribe({
        next: (values) => {
          this.getSubstanceDataCheck.next(values);
        },
        error: (error) => {
          this.getSubstanceDataCheck.next('error');
          return;
        },
      })
  }



  public getSubstanceDataListener() {
    return this.getSubstanceDataUpdated.asObservable();
  }

  public getSubstanceDataCheckListener() {
    return this.getSubstanceDataCheck.asObservable();
  }
}