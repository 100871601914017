import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { OrganisationService } from 'src/app/services/organisation.service';
import { FilterByPipe } from 'ngx-pipes';
import { OrderByPipe} from 'ngx-pipes';
import * as _ from "lodash";
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-company-users',
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.css']
})
export class CompanyUsersComponent implements OnInit {

  itemsPerPage:number=10;
  pageNumber:number=1;
  currentUser: any;
  isUserProfile: boolean;
  isCompanyUsers: boolean;
  isDepartmentSetup: boolean;
  isGroups: boolean;
  isCompanyInfo: boolean;
  isArticles: boolean;
  isStampedSds: boolean;
  isMisc: boolean;
  subscription_stamped_SDS: boolean;
  subscribesToFreemium: boolean;
  allUsersOnOrganisation: any[];
  organisation: any;
  searchText: any;
  allDepartmentNames: any;
  showAddExcell: boolean;
  arrayBuffer: any;
  userAddList: any;
  file: any;
  selectedRole: any;
  departmentNames: any;
  userDepartments: any;
  availableRoles: any;
  showTableSave: boolean;
  sortDirection: any = null;
  sortValue: any = null;
  sortOrderAscOrDesc = true;
  userSearch = {
    all: "",
    username: "",
    email: "",
    role: ""
  };

  ngAfterViewInit(): void {
    $('#usertable').tablesort();
  }


  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
              private userService:UserService, private translate: TranslateService, private formBuilder:FormBuilder,
              private organisationService:OrganisationService) { }

  ngOnInit(): void {
    this.isUserProfile = false;
    this.isCompanyUsers = true;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;
    this.subscription_stamped_SDS = false;
    this.subscribesToFreemium = false;
    this.allUsersOnOrganisation = [];
    this.showTableSave = false;

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscription_stamped_SDS = this.utilService.subscribesTo("subscription_stamped_SDS");
    this.subscribesToFreemium = this.utilService.subscribesTo("subscription_freemium");
    this.departmentNames = [];
    this.userDepartments = [];

    if (this.organisation) {
      for (var i = 0; i < this.organisation.orgs.length; i++)
      {
        this.departmentNames.push(this.organisation.orgs[i].name);
        this.userDepartments.push(this.organisation.orgs[i]);
      }
    }

    this.sortDirection = localStorage.getItem('sortDirection', );
    if (this.sortDirection == null)
    {
      this.sortDirection = 'asc';
    }

    this.sortValue = localStorage.getItem('sortValue', );
    if (this.sortValue == null)
    {
      this.sortValue = 'username';
    }

    this.availableRoles = [this.translate.instant("ADMIN"),
                           this.translate.instant("USERADMIN"),
                           this.translate.instant("LOCAL_ASSIGNEE"),
                           this.translate.instant("LOCAL_ADMIN"),
                           this.translate.instant("MAINTENANCE_USER"),
                           this.translate.instant("USER"),
                           this.translate.instant("LOCAL_USER"),
                           this.translate.instant("READONLY_USER"),
                           this.translate.instant("GUEST")];

    this.getAllUsers();
  }

  logout() {
    this.authService.logout();
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  sortHeader(value) {
    if (this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
    }
    else
    {
      this.sortDirection = 'asc';
    }

    localStorage.setItem('sortDirection', this.sortDirection);
    localStorage.setItem('sortValue', value);
    window.location.reload();
  }

  getDepartmentNames() {

    this.organisationService.getAllDepartmentNames(this.organisation);
    //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
      next: departments => {
        if (departments != null)
        {
          this.allDepartmentNames = departments;

          for (var i = 0; i < this.allUsersOnOrganisation.length; i++) {
            this.allUsersOnOrganisation[i].departmentName = "";
            for (var j = 0; j < this.allDepartmentNames.length; j++) {
              if (this.allUsersOnOrganisation[i].department == this.allDepartmentNames[j].id) {
                this.allUsersOnOrganisation[i].departmentName = this.allDepartmentNames[j].name;
              }
            }
            if((this.allUsersOnOrganisation[i].is_local_user||this.allUsersOnOrganisation[i].is_local_admin)
                  &&this.allUsersOnOrganisation[i].is_local_user_department!=null
                  &&this.allUsersOnOrganisation[i].is_local_user_department!=""){
                    var thisDepartmentsArray = this.allUsersOnOrganisation[i].is_local_user_department.split(',');
                    var thisDepartments="";
                    for(var l = 0; l < thisDepartmentsArray.length; l++) {
                      thisDepartmentsArray[l] = thisDepartmentsArray[l].trim();
                      for (var k = 0; k < this.allDepartmentNames.length; k++)
                      {
                        if (this.allDepartmentNames[k].id == thisDepartmentsArray[l])
                        {
                          if (thisDepartments == "") {
                            thisDepartments += this.allDepartmentNames[k].name;
                          } else {
                            thisDepartments += ', ' + this.allDepartmentNames[k].name;
                          }
                        }
                      }
                    }
                    if(thisDepartments!=""){
                      this.allUsersOnOrganisation[i].local_user_departments=thisDepartments;
                    } else {
                      this.allUsersOnOrganisation[i].local_user_departments="";
                    }
                  }
                }
        } else {
          Swal.fire({
            title: "Organisation users!",
            text: "No departments found!",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK")
          });
        }
      },
      error: error => {
        Swal.fire({
          title: "Organisation users!",
          text: "No departments found!",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: this.translate.instant("OK")
        });
      }
    })
  }

  getAllUsers() {

    if (this.organisation) {
      this.userService.getAllUsers(this.organisation.id);
      this.userService.getCurrentAllUsersListener().pipe(take(1)).subscribe({
        next: allUsers => {
          if (allUsers != null)
          {
            this.allUsersOnOrganisation = allUsers;
            for(var i = 0; i < this.allUsersOnOrganisation.length; i++) {
              if(this.allUsersOnOrganisation[i].role == null) {
                if(this.allUsersOnOrganisation[i].is_admin == 1) {
                  this.allUsersOnOrganisation[i].role = this.translate.instant("ADMIN");
                }
                if(this.allUsersOnOrganisation[i].is_super_user == 1) {
                  this.allUsersOnOrganisation[i].role = this.translate.instant("SUPERUSER");
                }
                if(this.allUsersOnOrganisation[i].is_useradmin == 1) {
                  this.allUsersOnOrganisation[i].role = this.translate.instant("USERADMIN");
                }
                if(this.allUsersOnOrganisation[i].is_local_assignee == 1) {
                  this.allUsersOnOrganisation[i].role = this.translate.instant("LOCAL_ASSIGNEE");
                }
                if(this.allUsersOnOrganisation[i].is_local_admin == 1) {
                  this.allUsersOnOrganisation[i].role = this.translate.instant("LOCAL_ADMIN");
                }
                if(this.allUsersOnOrganisation[i].is_maintenance_user == 1) {
                  this.allUsersOnOrganisation[i].role = this.translate.instant("MAINTENANCE_USER");
                }
                if(this.allUsersOnOrganisation[i].is_local_user == 1){
                  this.allUsersOnOrganisation[i].role = this.translate.instant("LOCAL_USER");
                }
                if(this.allUsersOnOrganisation[i].is_readonly_user == 1) {
                  this.allUsersOnOrganisation[i].role = this.translate.instant("READONLY_USER");
                }
                if(this.allUsersOnOrganisation[i].is_guest == 1){
                  this.allUsersOnOrganisation[i].role = this.translate.instant("GUEST");
                }
                if(this.allUsersOnOrganisation[i].is_admin == 0 &&
                  this.allUsersOnOrganisation[i].is_useradmin == 0 &&
                  this.allUsersOnOrganisation[i].is_super_user == 0 &&
                  this.allUsersOnOrganisation[i].is_local_assignee == 0 &&
                  this.allUsersOnOrganisation[i].is_local_admin == 0 &&
                  this.allUsersOnOrganisation[i].is_maintenance_user == 0 &&
                  this.allUsersOnOrganisation[i].is_readonly_user == 0 &&
                  this.allUsersOnOrganisation[i].is_guest == 0 &&
                  this.allUsersOnOrganisation[i].is_local_user == 0) {
                  this.allUsersOnOrganisation[i].role = this.translate.instant("USER");
                }
                this.getItemsPerPage();
              }
            }

            if (this.sortDirection === 'desc') {
              this.allUsersOnOrganisation = _.orderBy(this.allUsersOnOrganisation, ['type', this.sortValue]).reverse();
            }

            if (this.sortDirection === 'asc') {
              this.allUsersOnOrganisation = _.orderBy(this.allUsersOnOrganisation, ['type', this.sortValue]);
            }

            this.getDepartmentNames();
          }
          else {
            Swal.fire({
              title: "Organisation users!",
              text: "Not able to get users!",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
            });
          }
        },
        error: error => {
          Swal.fire({
            title: "Organsiation users!",
            text: "Not able to get users!",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK")
          });
        }
      })
    }
  }

  AddNewUser() {
    this.router.navigate(['/edituser', 'new']);
  }

  editUser(userId) {
    this.router.navigate(['/edituser', userId]);
  }

  addUsersFromExcel() {
    this.showAddExcell = !this.showAddExcell;
  }

  createTemplate() {
    var data = [{username: '', email: '', firstname: '', surname: '', phone: '', password: ''}];

    var worksheet = XLSX.utils.json_to_sheet(data);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "users");
    XLSX.writeFile(workbook, "import_user_template.xlsx");
  }

  incomingfile(event) {
    this.file= event.target.files[0];
  }

  loadFromFile() {
    this.showTableSave = true;

    this.availableRoles = [this.translate.instant("ADMIN"),
                           this.translate.instant("USERADMIN"),
                           this.translate.instant("LOCAL_ASSIGNEE"),
                           this.translate.instant("LOCAL_ADMIN"),
                           this.translate.instant("MAINTENANCE_USER"),
                           this.translate.instant("USER"),
                           this.translate.instant("LOCAL_USER"),
                           this.translate.instant("READONLY_USER"),
                           this.translate.instant("GUEST")];

    if(this.showAddExcell)
    {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary"});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          this.userAddList = XLSX.utils.sheet_to_json(worksheet,{raw:true});
          for (var i = 0; i < this.userAddList.length; i++) {
            this.userAddList[i].is_admin = 1;
            this.userAddList[i].is_super_user = 0;
            this.userAddList[i].is_useradmin = 0;
            this.userAddList[i].is_local_assignee = 0;
            this.userAddList[i].is_local_admin = 0;
            this.userAddList[i].is_local_user = 0;
            this.userAddList[i].is_maintenance_user = 0;
            this.userAddList[i].is_readonly_user = 0;
            this.userAddList[i].is_guest = 0;
            this.userAddList[i].role = this.translate.instant("ADMIN");
            this.userAddList[i].is_local_user_department = this.departmentNames[0];
            this.userAddList[i].departmentName = this.departmentNames[0];
            var index = 0;
            for (var j = 0; j < this.userDepartments.length; j++)
            {
              if (this.userDepartments[j].name == this.departmentNames[0] )
              {
                index = j;
                break;
              }
            }


            this.userAddList[i].department = this.userDepartments[index].id;
          }
      }
      fileReader.readAsArrayBuffer(this.file);
    }
  }

  saveTable() {
    for(var i = 0; i < this.userAddList.length; i++) {
      var user = {
          id: '',
          email: "",
          first_name: "",
          hashed_password: "",
          is_admin: 0,
          is_super_user: 0,
          is_useradmin: 0,
          is_local_user: 0,
          is_local_admin: 0,
          is_local_assignee: 0,
          is_maintenance_user: 0,
          is_readonly_user: 0,
          is_guest: 0,
          is_local_user_department: "",
          last_name: "",
          phone: "",
          root_organisation_id: '',
          salt: "",
          static_token: "",
          password: "",
          username: "",
          department: ""
      };

      user.username = this.userAddList[i].username;
      user.email = this.userAddList[i].email;
      user.last_name = this.userAddList[i].surname;
      user.phone = this.userAddList[i].phone;
      user.password = this.userAddList[i].password;
      user.first_name = this.userAddList[i].firstname;
      user.root_organisation_id = this.organisation.id;
      user.department = this.userAddList[i].department;
      user.is_admin = this.userAddList[i].is_admin;
      user.is_super_user = this.userAddList[i].is_super_user;
      user.is_useradmin = this.userAddList[i].is_useradmin;
      user.is_local_user = this.userAddList[i].is_local_user;
      user.is_local_admin = this.userAddList[i].is_local_admin;
      user.is_local_assignee = this.userAddList[i].is_local_assignee;
      user.is_guest = this.userAddList[i].is_guest;
      user.is_maintenance_user = this.userAddList[i].is_maintenance_user;
      user.is_readonly_user = this.userAddList[i].is_readonly_user;
      user.is_local_user_department = this.userAddList[i].department;
      this.userService.CreateUser(user);
      this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
        next: response => {
          if (response == null)
          {
            Swal.fire({
              title: "User!",
              text: "Error when creating user!",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
            });
          } else  {
            window.location.reload();
          }
        },
        error: error => {
          Swal.fire({
            title: "User!",
              text: "Error when creating user!",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK")
          });
        }
      })
    }
  }

  setDepartment(user, department) {
    var index = 0;
    var indexDep = 0;
    for (var i = 0; i < this.userAddList.length; i++)
    {
      if (this.userAddList[i].username == user.username )
      {
        index = i;
        break;
      }
    }

    this.userAddList[index] = user;
    this.userAddList[index].departmentName = department;

    for (var i = 0; i < this.userDepartments.length; i++)
    {
      if (this.userDepartments[i].name == department )
      {
        indexDep = i;
        break;
      }
    }


    this.userAddList[index].department = this.userDepartments[indexDep].id;
  }

  setUserRoleList(user, role) {
    var index = 0;
    for (var i = 0; i < this.userAddList.length; i++)
    {
      if(_.isEqual(this.userAddList[i].username, user.username)) {
        index = i;
        break;
      }
    }

    this.userAddList[index] = user;
    this.userAddList[index].active = 1;

    switch (role) {
    case this.translate.instant("ADMIN"):
      this.userAddList[index].is_admin = 1;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 0;
      this.userAddList[index].is_local_assignee = 0;
      this.userAddList[index].is_local_admin = 0;
      this.userAddList[index].is_local_user = 0;
      this.userAddList[index].is_maintenance_user = 0;
      this.userAddList[index].is_readonly_user = 0;
      this.userAddList[index].is_guest = 0;
      this.userAddList[index].role = role;
      break;

    case this.translate.instant("USERADMIN"):
      this.userAddList[index].is_admin = 0;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 1;
      this.userAddList[index].is_local_assignee = 0;
      this.userAddList[index].is_local_admin = 0;
      this.userAddList[index].is_local_user = 0;
      this.userAddList[index].is_maintenance_user = 0;
      this.userAddList[index].is_readonly_user = 0;
      this.userAddList[index].is_guest = 0;
      this.userAddList[index].role = role;
      break;

    case this.translate.instant("LOCAL_ASSIGNEE"):
      this.userAddList[index].is_admin = 0;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 0;
      this.userAddList[index].is_local_assignee = 1;
      this.userAddList[index].is_local_admin = 0;
      this.userAddList[index].is_local_user = 0;
      this.userAddList[index].is_maintenance_user = 0;
      this.userAddList[index].is_readonly_user = 0;
      this.userAddList[index].is_guest = 0;
      this.userAddList[index].role = role;
      break;

    case this.translate.instant("LOCAL_ADMIN"):
      this.userAddList[index].is_admin = 0;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 0;
      this.userAddList[index].is_local_assignee = 0;
      this.userAddList[index].is_local_admin = 1;
      this.userAddList[index].is_local_user = 0;
      this.userAddList[index].is_maintenance_user = 0;
      this.userAddList[index].is_readonly_user = 0;
      this.userAddList[index].is_guest = 0;
      this.userAddList[index].role = role;
      break;

    case this.translate.instant('LOCAL_USER'):
      this.userAddList[index].is_admin = 0;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 0;
      this.userAddList[index].is_local_assignee = 0;
      this.userAddList[index].is_local_admin = 0;
      this.userAddList[index].is_local_user = 1;
      this.userAddList[index].is_maintenance_user = 0;
      this.userAddList[index].is_readonly_user = 0;
      this.userAddList[index].is_guest = 0;
      this.userAddList[index].role = role;
      break;

      case this.translate.instant('MAINTENANCE_USER'):
      this.userAddList[index].is_admin = 0;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 0;
      this.userAddList[index].is_local_assignee = 0;
      this.userAddList[index].is_local_admin = 0;
      this.userAddList[index].is_local_user = 0;
      this.userAddList[index].is_maintenance_user = 1;
      this.userAddList[index].is_readonly_user = 0;
      this.userAddList[index].is_guest = 0;
      this.userAddList[index].role = role;
      break;

      case this.translate.instant('READONLY_USER'):
      this.userAddList[index].is_admin = 0;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 0;
      this.userAddList[index].is_local_assignee = 0;
      this.userAddList[index].is_local_admin = 0;
      this.userAddList[index].is_local_user = 0;
      this.userAddList[index].is_maintenance_user = 0;
      this.userAddList[index].is_readonly_user = 1;
      this.userAddList[index].is_guest = 0;
      this.userAddList[index].role = role;
      break;

      case this.translate.instant('GUEST'):
      this.userAddList[index].is_admin = 0;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 0;
      this.userAddList[index].is_local_assignee = 0;
      this.userAddList[index].is_local_admin = 0;
      this.userAddList[index].is_local_user = 0;
      this.userAddList[index].is_maintenance_user = 0;
      this.userAddList[index].is_readonly_user = 0;
      this.userAddList[index].is_guest = 1;
      this.userAddList[index].role = role;
      break;

    case this.translate.instant("USER"):
      this.userAddList[index].is_admin = 0;
      this.userAddList[index].is_super_user = 0;
      this.userAddList[index].is_useradmin = 0;
      this.userAddList[index].is_local_assignee = 0;
      this.userAddList[index].is_local_admin = 0;
      this.userAddList[index].is_local_user = 0;
      this.userAddList[index].is_maintenance_user = 0;
      this.userAddList[index].is_readonly_user = 0;
      this.userAddList[index].is_guest = 0;
      this.userAddList[index].role = role;
      break;
    }
  }

  showUserProfile() {
    this.isUserProfile = true;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/userprofile']);
  }

  showCompanyUsers() {
    this.isUserProfile = false;
    this.isCompanyUsers = true;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyusers']);
  }

  showDepartmentSetup() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = true;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/editdepartments']);
  }

  showGroups() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = true;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/productgroup']);
  }

  showCompanyInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = true;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyinfo']);
  }

  showArticles() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = true;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyarticles']);
  }

  showStampedSds() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = true;
    this.isMisc = false;

    this.router.navigate(['/stampedsds']);
  }

  showMiscInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = true;

    this.router.navigate(['/companymisc']);
  }
}
