<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="overflow-auto mb-10">
    <div class="flex flex-col min-h-screen border border-gray-300">
        <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="cancel()">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
            <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
            </svg>
        </button>

        <div *ngIf="loadingData==false&&workstep" class="flex flex-col w-full py-2 px-4">
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <span class="text-lg font-bold mb-2">{{"WORK_STEP" | translate}}</span>
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"HEADING" | translate}}:</label><br>
                        <textarea title="{{'HEADING' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="workstep.heading" (ngModelChange)="workstep.heading = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
                    </div>
                
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"PRESENCE" | translate}}{{" (max 200 tecken) "}}:</label><br>
                        <textarea title="{{'PRESENCE' | translate}}" class="w-full border border-gray-300 p-2" rows="3"  maxlength="200" [(ngModel)]="workstep.place" (ngModelChange)="workstep.place = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
                        <label *ngIf="workstep.place?.length>200 " class="text-red-500">{{'NUMBER_OF_CHARACTERS_MORE_THAN_MAX' | translate}}</label>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"DESCRIPTION" | translate}}{{" (max 200 tecken) "}}:</label><br>
                        <textarea title="{{'DESCRIPTION' | translate}}" class="w-full border border-gray-300 p-2" rows="3" maxlength="200" [(ngModel)]="workstep.risk_description" (ngModelChange)="workstep.risk_description = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
                        <label *ngIf="workstep.place?.risk_description>200 " class="text-red-500">{{'NUMBER_OF_CHARACTERS_MORE_THAN_MAX' | translate}}</label>
                    </div>
                </div>
            </div>

            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <table *ngIf="products" class="table-auto w-full bg-white mt-4 mb-4 border border-gray-400" id="productaddtable">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header-no-border" colspan="12">
                                <div class="flex flex-row items-center">
                                    <div class="mt-4">
                                        <input st-search="" type="search" [(ngModel)]="productAddSearch.all" class="prompt" 
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th class="table-row-header-no-border" colspan="12">
                                <div class="flex flex-row items-center">
                                    <div class="mt-4">
                                        <input st-search="" type="search" [(ngModel)]="productAddSearch.product_name" class="prompt" 
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full mr-2" placeholder="{{'SEARCH_NAME' | translate}} ..." />
                                    </div>
                                    <div class="mt-4">
                                        <input st-search="" type="search" [(ngModel)]="productAddSearch.supplier_name" class="prompt" 
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_MANUFACTURER' | translate}} ..." />
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
                            <th st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of products
                            | filter : productAddSearch.all
                            | filterBy  : ['product_name'] : productAddSearch.product_name
                            | filterBy  : ['supplier_name'] : productAddSearch.supplier_name
                            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };"
                            (click)="addProduct(product)" [ngStyle]="product.isSelected && {'background-color': 'rgb(217, 232, 202)'}">
                            <td class="table-row-column"><strong>{{product.product_name}}</strong></td>
                            <td class="table-row-column">{{product.supplier_name}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="p-6" colspan="1">
                                <div class="relative inline-block text-left" appDropdown>
                                    <button type="button" class="button-gray">
                                        <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                                        <div class="py-1">
                                            <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                            <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                            <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td colspan="1">
                                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                                    previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                            </td>
                        </tr>
                    </tfoot>
                </table>
        
                <div class="overflow-x-auto">
                    <table *ngIf="workstep" class="table-auto w-full bg-white mt-4 mb-4 border border-gray-400" id="worksteptable">
                        <thead class="bg-white">
                            <tr>
                                <th class="table-row-header">{{"NAME" | translate}}</th>
                                <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                                <th class="table-row-header">{{"RISK_LEVEL" | translate}}</th>
                                <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                                <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                                <th class="table-row-header">{{"PROTECTIVE_GEAR" | translate}}</th>
                                <th class="table-row-header"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of workstep.products; let i = index">
                                <td class="table-row-column">{{product.name}}</td>
                                <td class="table-row-column">{{product.supplier_name}}</td>
                                <td class="table-row-column">{{product.risk_prio}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-row justify-center items-center">
                                        <img class="-mr-3" *ngIf="subscribesToSds && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                                        <img class="-mr-3" *ngIf="subscribesToSds && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                                        <img class="-mr-3" *ngIf="subscribesToSds && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                                        <img *ngIf="subscribesToGrb && product.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                                    </div>
                                </td>
                                <td class="table-row-column">
                                    <div class="flex flex-row mr-1 justify-center items-center">
                                        <span *ngFor="let pictogram of product.pictograms">
                                            <img title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                                        </span>
                                    </div>
                                </td>
                                <td class="table-row-column">
                                    <div class="flex flex-row mr-1 justify-center items-center">
                                        <span *ngFor="let protective of product.protectiveArray">
                                            <img title="{{protective.filename}}" src="/images/protective/{{protective.filename}}-tiny.png" />
                                        </span>
                                    </div>
                                </td>
                                <td class="table-row-column" (click)="removeProduct(product)">
                                    <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg justify-center items-center mb-2 p-7">
                <span class="text-2xl py-2 mb-8">{{"PROTECTIVE_GEAR_SYMBOLS" | translate}}</span>
                <div class="flex flex-row">
                    <span *ngFor="let protective of protectiveImages">
                        <img title="{{protective.filename}}" class="w-20 h-20 cursor-pointer mr-1" [class.selected-border]="protectiveInWorkstep(protective.name)" src="/images/protective/{{protective.filename}}-small.png" (click)="addOrRemoveProtective(protective.name)" />
                    </span>
                </div>
            </div>

            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg justify-center items-center mb-2 p-7">
                <div *ngIf="questionList" class="flex flex-col justify-center items-center">
                    <p class="text-lg font-bold mb-4">{{questionList[0].heading}}</p>
                    <p class="text-sm mb-4 text-center">{{questionList[0].typeDescription}}</p>
                    <div class="flex flex-row justify-center items-center">
                        <a *ngFor="let option of riskQuestion">
                            <label class="circle" [ngClass]="{'selected': answers[0].value==option.id}">
                                <input type="radio" [(ngModel)]="answers[0].value" value="option.id" (click)="setSelectedOption(0, option.id)" >
                                {{option.id}}
                            </label>
                        </a>
                    </div>
                </div>
                <div *ngIf="questionList" class="flex flex-col justify-center items-center mt-10">
                    <p class="text-lg font-bold mb-4">{{questionList[1].heading}}</p>
                    <p class="text-sm mb-4 text-center">{{questionList[1].typeDescription}}</p>
                    <div class="flex flex-row justify-center items-center">
                        <a *ngFor="let option of riskQuestion">
                            <label class="circle" [ngClass]="{'selected': answers[1].value==option.id}">
                                <input type="radio" [(ngModel)]="answers[1].value" value="option.id" (click)="setSelectedOption(1, option.id)" >
                                {{option.id}}
                            </label>
                        </a>
                    </div>
                </div>
            </div>

            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RISKS" | translate}}:</label><br>
                        <textarea title="{{'RISKS' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="workstep.comments" (ngModelChange)="workstep.comments = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
                    </div>
                </div>
            </div>

            <div class="flex flex-row justify-center mb-20 mt-4">
                <button class="button-red" title="{{'REMOVE' | translate}}" (click)="cancel()">
                    <span class="text-white text-lg mr-1">{{ 'CANCEL' | translate }}</span>
                </button>
                <button class="button-green" title="{{'SAVE' | translate}}" *ngIf="workstep&&workstep.consequence>0&&workstep.propability>0" (click)="submit()">
                  <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                  <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>


