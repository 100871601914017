<div *ngIf="loadingFileData==true" class="flex justify-center items-center mt-4">
  <div class="loader"></div>
</div>
<div class="mb-4">
<div *ngIf="loadingFileData==false" class="flex flex-col border border-gray-300">
  <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="goToProducts()" id="itemProductButton18">
    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
      <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
    </svg>
  </button>
  <div class="flex flex-col px-4 py-2">
    <span class="text-lg font-bold px-2">{{"IMPORT_PRODUCTS_FROM_FILE" | translate}}</span>
    <div class="flex item-center mt-2">
      <button class="button-cdocblue" title="{{'DOWNLOAD_INVENTORY_TEMPLATE' | translate}}" (click)="downloadInventoryTemplate()">
        <span class="text-white text-lg">{{ 'DOWNLOAD_INVENTORY_TEMPLATE' | translate }}</span>
      </button>
    </div>
    <span class="text-lg font-bold px-2 mt-4">{{"UPLOAD_INVENTORY" | translate}}</span>
    <div class="flex flex-row items-center">
      <input title="{{'UPLOAD_INVENTORY' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" id="upload-file" (change)="incomingfile($event)"/>
      <div class="flex item-center mt-2">
        <button id="addProductButton3" class="button-white" title="{{'UPLOAD' | translate}}" (click)="loadFromFile()">
          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                        9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2
                        18.8286 2 20.2429 2.87868 21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </button>
      </div>
    </div>
    <label class="text-red-500" *ngIf="currentUser.is_super_user">Senast uppdaterat: {{inventoryfileName}}</label>
    <app-sareq-search (dataOutput)="addProduct($event)"></app-sareq-search>
    </div>
  </div>
</div>
<div class="mb-20">
<div *ngIf="loadingFileData==false" class="flex flex-col border border-gray-300">
  <div class="flex flex-col px-4 py-2">
    <span class="text-xl px-2 mt-4 font-bold">{{"PRODUCTS_TO_ADD" | translate}} {{" "}} {{selectedDepartment.name}}</span>
    <span class="font-bold px-2 mb-4">{{"QUANTITY" | translate}}: {{selectedProducts?.length}}</span>
    <div class="overflow-x-auto">
      <table class="table-auto w-full bg-white border border-gray-400 mt-2 mb-40" st-table="products" st-safe-src="products" id="productaddtable">
        <thead class="bg-white">
          <tr>
            <th st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
            <th st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
            <th class="table-row-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
            <th class="table-row-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
            <th class="table-row-header">{{"MAXIMUM_AMOUNT" | translate}}</th>
            <th class="table-row-header">{{"OWN_ARTICLE" | translate}}</th>
            <th class="table-row-header">{{"UNIT" | translate}}</th>
            <th class="table-row-header">{{"PRODUCT_GROUP" | translate}}</th>
            <th class="table-row-header">{{"DEPARTMENTS" | translate}}</th>
            <th class="table-row-header"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let product of selectedProducts; let last=last">
          <tr>
            <td class="table-row-column">{{product.name}}</td>
            <td class="table-row-column">{{product.supplier_name}}</td>
            <td class="table-row-column"><input title="amount" type="number" min="0" class="border border-gray-300 w-20 p-1" [(ngModel)]="product.amount" /></td>
            <td class="table-row-column"><input title="annual_consumption" type="number" min="0" class="border border-gray-300 w-20 p-1" [(ngModel)]="product.annual_consumption" /></td>
            <td class="table-row-column"><input title="maximum_amount" type="number" min="0" class="border border-gray-300 w-20 p-1" [(ngModel)]="product.maximum_amount" /></td>
            <td class="table-row-column"><input title="own_article_number" type="text" min="0" class="border border-gray-300 w-20 p-1" [(ngModel)]="product.new_own_article_number" /></td>
            <td class="table-row-column">
              <div class="relative inline-block text-left p-1 w-10 border border-gray-300" appDropdown>
                <button type="button" class="flex justify-between items-center text-black-900 flex items-start space-x-1 p-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500">
                  <span class="nav-home-text text-sm">{{product?.unit?.id}}</span>
                  <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <div id="dropdownMenu" class="hidden dropdown-menu w-20 z-20 absolute mt-1 bg-white border border-gray-300 shadow-lg rounded-md" #dropdownMenuInside>
                  <div class="py-1">
                    <div *ngFor="let unit of units" data-value="unit.id" class="dropdown-menu-item w-10" (click)="setUnit(product, unit.id)" tabindex="1">{{ unit.id }}</div>
                  </div>
                </div>
              </div>
            </td>
            <td class="table-row-column">
              <div class="flex flex-row">
                <div class="relative inline-block text-left w-40 border border-gray-300" appDropdown>
                  <button type="button" class="button-white w-full justify-between border border-gray-300">
                    <span class="nav-home-text text-sm">{{product.group_name}}</span>
                    <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                  <div id="dropdownMenu" class="hidden dropdown-menu z-20 w-40 absolute mt-1 bg-white border border-gray-300 shadow-lg rounded-md" #dropdownMenuInside>
                    <div class="py-1">
                      <div *ngFor="let group of groups" data-value="group.name" class="dropdown-menu-item w-40" (click)="setGroupName(product, group.name)" tabindex="1">{{ group.name }}</div>
                    </div>
                  </div>
                </div>
                <button class="button-blue" title="{{'ADD_GROUP' | translate}}" (click)="addGroup()">
                  <svg class="w-4 h-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                    <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                  </svg>
                </button>
              </div>
            </td>
            <td class="table-row-column">
              <div class="relative inline-block text-left w-72 z-30 mb-1" appDropdown>
                <div class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white bg-teal-100 border border-gray-300 p-2">
                  <div class="flex flex-wrap">
                    <div *ngFor="let productdepartment of product.department">
                      <div class="flex flex-row border bg-gray-200 border-blue-500 p-1 mr-1 mt-1 rounded">
                        <div class="text-xs font-normal leading-none max-w-full flex-initial">{{productdepartment.name}}</div>
                        <div class="flex flex-auto flex-row-reverse">
                          <div>
                            <svg (click)="product.department.length==1?warningShow():removeDepartment(productdepartment,product)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <svg class="w-6 h-6 ml-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div id="dropdownMenu" class="hidden dropdown-menu absolute mt-1 right-10 overflow-y-auto max-h-64 bg-white border border-gray-300 shadow-lg rounded-md z-40" #dropdownMenuInside>
                <input title="search" st-search="" type="search" fullTextSearch="false" (input)="onSearchChangeDepartment($event.target.value, product.department, product)"
                  class="w-full px-2 py-2 border border-cdocblue rounded" placeholder="" #dropdownSearch/>
                <div class="py-1">
                    <div *ngFor="let department of product.filteredlistOfDepartments | FilterPipe : searchDepartment" class="item" data-value="selectDepartment" class="dropdown-menu-item multi-items" (click)="setDepartment(department,product)" (keydown.enter)="setDepartment(department,product)" tabindex="1">{{ department.name }}</div>
                </div>
              </div>
            </div>
            </td>
            <td class="table-row-column">
              <a title="{{'REMOVE_FROM_LIST' | translate}}" (click)="removeProduct(product)">
                <svg class="h-6 w-6 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                    21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                    5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                    3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                    stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
    <!-- 123 -->
    <div class="flex flex-row justify-center mb-20 mt-4">
      <button (click)="addProducts()" [disabled]="loadingButton" class="button-green" [class.cursor-not-allowed]="loadingButton==true">
        <ng-container *ngIf="!loadingButton; else loading">
            <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
        </ng-container>
        <ng-template #loading>
            <svg class="w-6 h-6 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
        </ng-template>
      </button>
    </div>
  </div>
</div>
</div>
