import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: "root",
})
export class SubstitutionService {
  getSubstitutionDataUpdated = new Subject<any>();
  getSubstitutionAllDataUpdated = new Subject<any>();
  getSubstitutionListUpdated = new Subject<any>();
  getSubstitutionDataFileUpdated = new Subject<any>();
  getSubstitutionProductDataUpdated = new Subject<any>();
  getSubstitutionWorkstepProductDataUpdated = new Subject<any>();
  getCreatePDFUpdated = new Subject<any>();
  getAllSubstitutionDataUpdated = new Subject<any>();

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private productConversions: ProductConversionsService
  ) {}

  /**
   * Convert date from string to Date object
   */
  convertStringToDate(data) {
    var i, dataLength;

    if (_.isArray(data)) {
      dataLength = data.length;
      for (i = 0; i < dataLength; i += 1) {
        data[i].last_chaged = this.utilService.convertStringToDate(
          data[i].last_changed
        );
      }
    } else if (_.isObject(data)) {
      data.last_changed = this.utilService.convertStringToDate(
        data.last_changed
      );
    }

    return data;
  }

  getAll() {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedOrganisation") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedOrganisation")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/substitution/getsubstitution",
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionAllDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionAllDataUpdated.next(null);
          },
        });
    }
  }

  getAllForDashboard(ids) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedOrganisation") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedOrganisation")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      let params = new HttpParams();
      params = params.append("data", ids);

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/substitution/getsubstitutionfordashboard",
          { headers: headers,
            params: params }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getAllSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getAllSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  getSubstitutionReport(ids) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      let params = new HttpParams();
      params = params.append("data", ids);

      this.http
        .get(BackendUrl + "/organisations/" + orgId + "/substitution/getsubstitutionforreport", {
          headers: headers,
          params: params
        })
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  getPDFAsTrustedResourceUrl(thisid, orgId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/organisations/' + orgId + '/substitution/' + thisid + "/getSinglePDF", {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {

        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getSubstitutionDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getSubstitutionDataUpdated.next(null);
      }
    });
  }

  getSingle(id) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
      this.http
        .get(BackendUrl + "/organisations/" + orgId + "/substitution/" + id, {
          headers: headers,
        })
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.convertStringToDate(response);
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  createSubstitution(info) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      let token = localStorage.getItem("token");

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .post<any>(
          BackendUrl + "/organisations/" + orgId + "/substitutions",
          info,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  addToSubstitutionList(sareq_id, sub_list) {

    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    var info = {'sareq_id': sareq_id,'sub_list': sub_list}

    this.http
      .post<any>(BackendUrl + "/substitution/addToSubstitutionList", info, { headers: headers }
      )
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSubstitutionListUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSubstitutionListUpdated.next(null);
        },
    });
  }

  getSubstitutionList() {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http.get(BackendUrl + "/substitution/getAllSubstitutionListProducts/", {headers: headers,})
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSubstitutionListUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSubstitutionListUpdated.next(null);
        },
      });
  }

  getSingleItemInSubstitutionList(sareqId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http.get(BackendUrl + "/substitution/getSingleSubstitutionListProducts/" + sareqId, {headers: headers,})
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSubstitutionListUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSubstitutionListUpdated.next(null);
        },
      });
  }

  deleteSubstitutionListItem(id) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .delete(
        BackendUrl + "/substitution/removeSubstitutionProduct/" + id,
        { headers: headers }
      )
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSubstitutionListUpdated.next(true);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSubstitutionListUpdated.next(true);
        },
      });
  }

  updateSubstitution(id, info) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      let token = localStorage.getItem("token");

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .put<any>(
          BackendUrl + "/organisations/" + orgId + "/substitutions/" + id,
          info,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  updateSubstitutionStatus(id, info) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      let token = localStorage.getItem("token");

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .put<any>(
          BackendUrl + "/organisations/" + orgId + "/substitutionsstatus/" + id,
          info,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  dosubstitution(item) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      let token = localStorage.getItem("token");

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .put<any>(
          BackendUrl + "/organisations/" + orgId + "/dosubstitution",
          item,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  deleteSubstitution(id) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .delete(
          BackendUrl + "/organisations/" + orgId + "/substitutions/" + id,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(true);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(true);
          },
        });
    }
  }

  deleteDepartmentSubstitution(id, depid) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = depid;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .delete(
          BackendUrl + "/organisations/" + orgId + "/substitutionsThis/" + id,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(true);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(true);
          },
        });
    }
  }

  getAllProducts() {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/getallproductssubstitution",
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.productConversions.convertPictogramsOnProductArray(response);
            this.productConversions.convertProtectiveGearImagesOnProductArray(
              response
            );
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  getSingleProduct(id) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/substitution/product/" +
            id,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.productConversions.convertPictogramsOnProductArray(response);
            this.productConversions.convertProtectiveGearImagesOnProductArray(
              response
            );
            this.getSubstitutionProductDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionProductDataUpdated.next(null);
          },
        });
    }
  }

  getSingleWorkstepProduct(id) {
    if(!id){ // id not defined -> error
      return;
    }
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/substitution/workstepproduct/" +
            id,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.productConversions.convertPictogramsOnProduct(response[0]);
            this.productConversions.convertProtectiveGearImagesToProduct(response[0]);
            this.productConversions.convertPhrasesOnProduct(response[0]);
            this.getSubstitutionWorkstepProductDataUpdated.next(response[0]);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionWorkstepProductDataUpdated.next(null);
          },
        });
    }
  }

  getSingleProductAny(id) {
    if(!id){ // id not defined -> error
      return;
    }
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/substitution/productany/" +
            id,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.productConversions.convertPictogramsOnProduct(response[0]);
            this.productConversions.convertProtectiveGearImagesToProduct(response[0]);
            this.productConversions.convertPhrasesOnProduct(response[0]);
            this.getSubstitutionProductDataUpdated.next(response[0]);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionProductDataUpdated.next(null);
          },
        });
    }
  }

  checkIfProductExists(object) {
    let token = localStorage.getItem("token");
    var objectid = object.subprodid;

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/substitution/checkifexists/" +
            objectid,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  getFileAsTrustedResourceUrl(id, name) {
    if (typeof name == "undefined") {
      return;
    }
    var ext = name?.substr(name.lastIndexOf(".") + 1);

    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/substitution/picturesub/" +
            id,
          { headers: headers, responseType: "blob" }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (ext == "pdf") {
              var file = new Blob([response], { type: "application/pdf" });
              var fileURL = URL.createObjectURL(file);
              this.getSubstitutionDataFileUpdated.next(fileURL);
            } else {
              var file = new Blob([response], { type: "application/image" });
              if (file.size !== 0) {
                const unsafeImg = URL.createObjectURL(file);
                var image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
                this.getSubstitutionDataFileUpdated.next(image);
              }
            }
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  getcDocFileWithId(fileId) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get(
          BackendUrl +
            "/organisations/" +
            orgId +
            "/substitution/filesub/" +
            fileId,
          { headers: headers, responseType: "arraybuffer" }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            var file = new Blob([response], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            this.getSubstitutionDataUpdated.next(fileURL);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  copySubstitution(info) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }
      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
      this.http
        .post<any>(
          BackendUrl + "/organisations/" + orgId + "/copysubstitutions",
          info,
          { headers: headers }
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.getSubstitutionDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getSubstitutionDataUpdated.next(null);
          },
        });
    }
  }

  substituteProduct(oldProductId, newProductId, departments) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }
      var info = { oldProductId: oldProductId, newProductId: newProductId, departments:departments };
    }
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .put<any>(
        BackendUrl + "/organisations/" + orgId + "/substituteProduct",
        info,
        { headers: headers }
      )
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getSubstitutionDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getSubstitutionDataUpdated.next(null);
        },
      });
  }

  createPDFSubstitution(id) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("token", token);

    this.http
      .get(BackendUrl + "/organisations/" + id + "/createpdfsubstitution", {
        headers: headers,
        responseType: "arraybuffer",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getCreatePDFUpdated.next(response);
        },
        error: (error) => {
          this.getCreatePDFUpdated.next(null);
        },
      });
  }

  checkIfSubstitutionExists( object ) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("object", JSON.stringify(object));

    this.http
      .get(BackendUrl + "/substitution/checkifsubstitutionexists", {
        headers: headers,
        params: params
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getSubstitutionDataUpdated.next(response);
        },
        error: (error) => {
          this.getSubstitutionDataUpdated.next(null);
        },
      });
  }

  public getSubstitutionDataListener() {
    return this.getSubstitutionDataUpdated.asObservable();
  }

  public getSubstitutionAllDataListener() {
    return this.getSubstitutionAllDataUpdated.asObservable();
  }

  public getSubstitutionListListener() {
    return this.getSubstitutionListUpdated.asObservable();
  }

  public getAllSubstitutionDataListener() {
    return this.getAllSubstitutionDataUpdated.asObservable();
  }

  public getSubstitutionDataFileListener() {
    return this.getSubstitutionDataFileUpdated.asObservable();
  }

  public getSubstitutionProductDataListener() {
    return this.getSubstitutionProductDataUpdated.asObservable();
  }

  public getCreatePDFUpdatedListener() {
    return this.getCreatePDFUpdated.asObservable();
  }

  public getSubstitutionWorkstepProductDataListener() {
    return this.getSubstitutionWorkstepProductDataUpdated.asObservable();
  }
}


