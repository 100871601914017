<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
  <!-- Modal Content -->
  <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
    <div class="flex justify-end">
      <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
    </div>
    <div class="mt-3">
        <div class="flex flex-row">
          <div class="text-gray-900 font-bold mr-1">{{"REPORT_FIRE" | translate}}{{" --- Kategori:"}}</div>
          <span *ngIf="category === 'extremely'">{{"EXTREMELY_HIGHLY_FLAMMALBE" | translate}}</span>
          <span *ngIf="category === 'high'">{{"EXPLOSIVE_AND_HIGHLY_FLAMMABLE_PRODUCTS" | translate}}</span>
          <span *ngIf="category === 'low'">{{"FLAMMABLE_AND_OXIDIZING_PRODUCTS" | translate}}</span>
          <span *ngIf="category === 'oxidizing'">{{"OXIDIZING" | translate}}</span>
          <span *ngIf="category === 'gases'">{{"FLAMMABLE_GASES" | translate}}</span>
          <span *ngIf="category === 'all'">{{"ALL" | translate}}</span>
        </div>
        <!-- Modal Body -->
        <div class="flex flex-col center-items mt-4 p-2">
            <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                <div class="loader"></div>
            </div>

            <div *ngIf="loadingData==false" class="overflow-auto mb-20">
              <div class="flex flex-row justify-start items-center w-full relative inline-block text-left z-20 mb-4" appDropdown>
                <label for="category">{{"CHOOSE_CATEGORY" | translate}}</label>:&nbsp;
                <button title="{{categoryName}}" type="button" class="button-white border border-gray-300">
                  <span class="text-sm">{{categoryName}}</span>
                  <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <div id="dropdownMenu" class="hidden dropdown-menu top-10" #dropdownMenuInside>
                    <div class="py-1">
                      <a class="dropdown-menu-item" (click)="setCategory('extremely')" tabindex="1">{{"EXTREMELY_HIGHLY_FLAMMALBE" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('high')" tabindex="2">{{"EXPLOSIVE_AND_HIGHLY_FLAMMABLE_PRODUCTS" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('low')" tabindex="3">{{"FLAMMABLE_AND_OXIDIZING_PRODUCTS" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('oxidizing')" tabindex="4">{{"OXIDIZING" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('gases')" tabindex="5">{{"FLAMMABLE_GASES" | translate}}</a>
                      <a class="dropdown-menu-item" (click)="setCategory('all')" tabindex="6">{{"ALL" | translate}}</a>
                    </div>
                </div>
              </div>
              <hr />
              <div *ngIf="category==='extremely'">
                <table datatable [dtOptions]="dtOptions" class="table">
                  <thead>
                    <tr></tr>
                    <tr>
                      <th st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
                      <th st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                      <th st-sort="department" class="table-row-header sort-header">{{"DEPARTMENT" | translate}}</th>
                      <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                      <th st-sort="article_number" class="table-row-header sort-header">{{"ARTICLE_NUMBER" | translate}}</th>
                      <th st-sort="amount" class="table-row-header sort-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                      <th st-sort="annual" class="table-row-header sort-header">{{"ESTIMATED_ANNUAL_CONSUMPTION" | translate}}</th>
                      <th st-sort="category" class="table-row-header sort-header">{{"CATEGORY" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of reportInformation.products.extremely">
                      <td class="table-row-column">{{product.name}}</td>
                      <td class="table-row-column">{{product.supplier_name}}</td>
                      <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                      <td class="table-row-column">
                        <div class="flex flex-row">
                          <span *ngFor="let pictogram of product.pictograms">
                            <img class="w-6 h-6" title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                          </span>
                        </div>
                      </td>
                      <td class="table-row-column">{{product.customerarticle}}</td>
                      <td class="table-row-column">{{product.amount}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.annual}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.category | translate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="category==='high'">
                <table datatable [dtOptions]="dtOptions" class="table">
                  <thead>
                    <tr>
                      <th st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
                      <th st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                      <th st-sort="department" class="table-row-header sort-header">{{"DEPARTMENT" | translate}}</th>
                      <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                      <th st-sort="article_number" class="table-row-header sort-header">{{"ARTICLE_NUMBER" | translate}}</th>
                      <th st-sort="amount" class="table-row-header sort-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                      <th st-sort="annual" class="table-row-header sort-header">{{"ESTIMATED_ANNUAL_CONSUMPTION" | translate}}</th>
                      <th st-sort="category" class="table-row-header sort-header">{{"CATEGORY" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of reportInformation.products.high">
                      <td class="table-row-column">{{product.name}}</td>
                      <td class="table-row-column">{{product.supplier_name}}</td>
                      <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                      <td class="table-row-column">
                        <div class="flex flex-row">
                          <span *ngFor="let pictogram of product.pictograms">
                            <img class="w-6 h-6" title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                          </span>
                        </div>
                      </td>
                      <td class="table-row-column">{{product.customerarticle}}</td>
                      <td class="table-row-column">{{product.amount}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.annual}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.category | translate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="category=== 'low'">
                <table datatable [dtOptions]="dtOptions" class="table">
                  <thead>
                    <tr>
                      <th st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
                      <th st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                      <th st-sort="department" class="table-row-header sort-header">{{"DEPARTMENT" | translate}}</th>
                      <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                      <th st-sort="article_number" class="table-row-header sort-header">{{"ARTICLE_NUMBER" | translate}}</th>
                      <th st-sort="amount" class="table-row-header sort-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                      <th st-sort="annual" class="table-row-header sort-header">{{"ESTIMATED_ANNUAL_CONSUMPTION" | translate}}</th>
                      <th st-sort="category" class="table-row-header sort-header">{{"CATEGORY" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of reportInformation.products.low">
                      <td class="table-row-column">{{product.name}}</td>
                      <td class="table-row-column">{{product.supplier_name}}</td>
                      <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                      <td class="table-row-column">
                        <div class="flex flex-row">
                          <span *ngFor="let pictogram of product.pictograms">
                            <img class="w-6 h-6" title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                          </span>
                        </div>
                      </td>
                      <td class="table-row-column">{{product.customerarticle}}</td>
                      <td class="table-row-column">{{product.amount}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.annual}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.category | translate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="category=== 'oxidizing'">
                <table datatable [dtOptions]="dtOptions" class="table">
                  <thead>
                    <tr>
                      <th st-sort="name" class="sort-header">{{"NAME" | translate}}</th>
                      <th st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                      <th st-sort="department" class="table-row-header sort-header">{{"DEPARTMENT" | translate}}</th>
                      <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                      <th st-sort="article_number" class="table-row-header sort-header">{{"ARTICLE_NUMBER" | translate}}</th>
                      <th st-sort="amount" class="table-row-header sort-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                      <th st-sort="annual" class="table-row-header sort-header">{{"ESTIMATED_ANNUAL_CONSUMPTION" | translate}}</th>
                      <th st-sort="category" class="table-row-header sort-header">{{"CATEGORY" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of reportInformation.products.oxidizing">
                      <td class="table-row-column">{{product.name}}</td>
                      <td class="table-row-column">{{product.supplier_name}}</td>
                      <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                      <td class="table-row-column">
                        <div class="flex flex-row">
                          <span *ngFor="let pictogram of product.pictograms">
                            <img class="w-6 h-6" title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                          </span>
                        </div>
                      </td>
                      <td class="table-row-column">{{product.customerarticle}}</td>
                      <td class="table-row-column">{{product.amount}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.annual}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.category | translate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="category=== 'gases'">
                <table datatable [dtOptions]="dtOptions" class="table">
                  <thead>
                    <tr>
                      <th st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
                      <th st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                      <th st-sort="department" class="table-row-header sort-header">{{"DEPARTMENT" | translate}}</th>
                      <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                      <th st-sort="article_number" class="table-row-header sort-header">{{"ARTICLE_NUMBER" | translate}}</th>
                      <th st-sort="amount" class="table-row-header sort-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                      <th st-sort="annual" class="table-row-header sort-header">{{"ESTIMATED_ANNUAL_CONSUMPTION" | translate}}</th>
                      <th st-sort="category" class="table-row-header sort-header">{{"CATEGORY" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of reportInformation.products.gases">
                      <td class="table-row-column">{{product.name}}</td>
                      <td class="table-row-column">{{product.supplier_name}}</td>
                      <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                      <td class="table-row-column">
                        <div class="flex flex-row">
                          <span *ngFor="let pictogram of product.pictograms">
                            <img class="w-6 h-6" title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                          </span>
                        </div>
                      </td>
                      <td class="table-row-column">{{product.customerarticle}}</td>
                      <td class="table-row-column">{{product.amount}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.annual}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.category | translate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="category==='all'">
                <table datatable [dtOptions]="dtOptions" class="table">
                  <thead>
                    <tr>
                      <th st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
                      <th st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                      <th st-sort="department" class="table-row-header sort-header">{{"DEPARTMENT" | translate}}</th>
                      <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                      <th st-sort="article_number" class="table-row-header sort-header">{{"ARTICLE_NUMBER" | translate}}</th>
                      <th st-sort="amount" class="table-row-header sort-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                      <th st-sort="annual" class="table-row-header sort-header">{{"ESTIMATED_ANNUAL_CONSUMPTION" | translate}}</th>
                      <th st-sort="category" class="table-row-header sort-header">{{"CATEGORY" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let product of allProducts">
                      <td class="table-row-column">{{product.name}}</td>
                      <td class="table-row-column">{{product.supplier_name}}</td>
                      <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                      <td class="table-row-column">
                        <div class="flex flex-row">
                          <span *ngFor="let pictogram of product.pictograms">
                            <img class="w-6 h-6" title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                          </span>
                        </div>
                      </td>
                      <td class="table-row-column">{{product.customerarticle}}</td>
                      <td class="table-row-column">{{product.amount}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.annual}} {{product.unit}}</td>
                      <td class="table-row-column">{{product.category | translate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
  </div>
