import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from 'src/app/auth/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal-add-group',
  templateUrl: './modal-add-group.component.html',
  styleUrls: ['./modal-add-group.component.css']
})
export class ModalAddGroupComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  message;
  groups;
  root_organisation;
  groupName;

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder,
              private productService: ProductService, private authService: AuthService) { }

  ngOnInit(): void {
    this.getAllGroups();
  }

  ngOnDestroy(): void {
  }

  save() {
    this.message="";

    for(var n=0;n<this.groups.length;n++){
      if(this.groups[n].name?.toLowerCase()==this.groupName?.toLowerCase()){
        this.message="Namnet finns redan";
        return;
      }
    }

    if (this.groupName.length > 0) {
      this.passEntry.emit(this.groupName);
    }

    this.activeModal.close(ModalAddGroupComponent);
  }

  getAllGroups() {
      this.root_organisation = localStorage.getItem('rootOrganisation', );
      if (this.root_organisation) {
        this.productService.getGroups(JSON.parse(this.root_organisation).id);
      }
      this.productService.getProductGroupDataListener().pipe(take(1)).subscribe({
        next: groups => {
          if (groups != null) {
            this.groups = groups;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalAddGroupComponent);
  }

  logout() {
    this.authService.logout();
  }
}
