<!-- Form Container -->
<div class="p-4 mb-20">
    <div class="flex flex-col bg-white w-full rounded shadow-lg p-7">
        <span class="text-2xl font-bold mt-4 mb-4">{{"HISTORY_LINK" | translate}}</span>
        <div class="flex flex-col w-full mt-4">
            <div class="mb-4">
                <label class="font-bold mb-1">{{"DATE_FROM" | translate}}:</label><br>
                <input title="{{'DATE_FROM' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="reportInformation.date2 | date:'yyyy-MM-dd'" (ngModelChange)="$event?reportInformation.date2 = $event:null" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"DATE_TO" | translate}}:</label><br>
                <input title="{{'DATE_TO' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="reportInformation.date | date:'yyyy-MM-dd'" (ngModelChange)="$event?reportInformation.date = $event:null" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"REPORT_TYPE" | translate}}:</label><br>
                <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
                    <button type="button" class="button-white w-full justify-between border border-gray-300">
                        <span>{{reportInformation.name | translate}}</span>
                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <div id="dropdownMenu" class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                        <div>
                            <div *ngFor="let type of supportedReportTypes" data-value="type" class="dropdown-menu-item" (click)="choseReport(type.name)" tabindex="1">{{ type.name | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <div class="flex items-center space-x-2 mt-2">
                    <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" (click)="onToggleShowSubDepartments()">
                    <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                </div>
            </div>
        </div>
        <div class="flex flex-row justify-center mb-10 mt-4">
            <button class="button-green" title="{{'CREATE_REPORT' | translate}}" (click)="createReport()">
              <img title="CREATE_REPORT" src="images/icons/save-svgrepo-com.svg" class="nav-home">
              <span class="text-lg">{{ 'CREATE_REPORT' | translate }}</span>
            </button>
          </div>
    </div>
</div>

