<div class="flex flex-row">
    <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="cancel()">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
        <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
        </svg>
    </button>
</div>

<div *ngIf="manufacturer" class="flex flex-col bg-white w-full rounded shadow-lg mb-40 md:mb-20 p-7">
    <div class="flex flex-col w-full mt-2">
        <div class="mb-4">
            <label class="font-bold mb-1">{{"NAME" | translate}}:</label><br>
            <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.name" (ngModelChange)="manufacturer.name = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
            <label class="font-bold mb-1">{{"ADDRESS" | translate}}:</label><br>
            <input title="{{'ADDRESS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.address_1" (ngModelChange)="manufacturer.address_1 = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
            <label class="font-bold mb-1">{{"ADDRESS_VISIT" | translate}}:</label><br>
            <input title="{{'ADDRESS_VISIT' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.address_2" (ngModelChange)="manufacturer.address_2 = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
            <label class="font-bold mb-1">{{"CITY" | translate}}:</label><br>
            <input title="{{'CITY' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.city" (ngModelChange)="manufacturer.city = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
            <label class="font-bold mb-1">{{"ZIP" | translate}}:</label><br>
            <input title="{{'ZIP' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.zip" (ngModelChange)="manufacturer.zip = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
            <label class="font-bold mb-1">{{"COUNTRY" | translate}}:</label><br>
            <input title="{{'COUNTRY' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.country" (ngModelChange)="manufacturer.country = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
            <label class="font-bold mb-1">{{"WEB_PAGE" | translate}}:</label><br>
            <input title="{{'WEB_PAGE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.www" (ngModelChange)="manufacturer.www = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
            <label class="font-bold mb-1">{{"EMAIL" | translate}}:</label><br>
            <input title="{{'EMAIL' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.email" (ngModelChange)="manufacturer.email = $event" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="mb-4">
            <label class="font-bold mb-1">{{"PHONE" | translate}}:</label><br>
            <input title="{{'PHONE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="manufacturer.phone" (ngModelChange)="manufacturer.phone = $event" [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="flex flex-row justify-center mb-2 mt-4">
        <button *ngIf="currentUser.is_super_user && !this.newManufacturer" class="button-red mr-2" title="{{'REMOVE' | translate}}" (click)="delete()">
          <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'INACTIVATE' | translate }}</span>
        </button>
        <button class="button-green" title="{{'SAVE' | translate}}" (click)="save()">
          <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'SAVE' | translate }}</span>
        </button>
    </div>
</div>






