import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { CatalogueService } from 'src/app/services/catalogue.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { KebnekaiseService } from "src/app/services/kebnekaise.service";
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-catalogue-edit',
  templateUrl: './catalogue-edit.component.html',
  styleUrls: ['./catalogue-edit.component.css']
})
export class CatalogueEditComponent implements OnInit {
  loadingData: boolean = false;
  selectedDepartment: any;
  organisation: any;
  selectedOrganisation: any;
  listAlldepartments: any;
  catalogueId;
  departmenstList;
  isError: boolean=false;
  isErrorArray: any=[];

  product=({
    Kebnekaise_id: "",
    amount_in_stock:null,
    description:({
      eco:""
    }),
    eco:"",
    equipments:[""],
    estimated_use_per_year:null,
    flammable: null,
    laws: [""],
    organisation_id: "",
    product_name: "",
    protective_gear: "",
    requirements: [""],
    sds_file_id: "",
    sds_file: null,
    supplier_name: "",
    unit: ""
  });

  units=[];
  riskfile;

  constructor(private utilService:UtilService,
              private organisationService:OrganisationService,
              private router: Router,
              private catalogueService:CatalogueService,
              private route: ActivatedRoute,
              private staticDataService:StaticDataService,
              private kebnekaiseService: KebnekaiseService,
              private translate: TranslateService,
              private authService: AuthService) { }

  ngOnInit(): void {

    this.route.params.pipe(take(1)).subscribe(params => {
      this.catalogueId = params['id'];
    });

    this.loadingData = true;

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    this.getAllDepartments();

    if(this.catalogueId!="new"){
      this.getProduct(this.catalogueId);
    }else{
      this.product.description=null;
      this.loadingData = false;
    }

    setTimeout(() => {
      this.units = this.staticDataService.getUnitDefinitions();
    }, 2000);
  }

  logout() {
    this.authService.logout();
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  setUnit(unit) {
    this.product.unit = unit.name;
  }

  openSdsInNewWindow(product) {
    var filename = product.name + '.pdf';
    if (product.kebnekaise_id)
      this.kebnekaiseService.getSdsAsTrustedResourceUrl(product.kebnekaise_id);
    else
      this.kebnekaiseService.getSdsAsTrustedResourceUrl(product.Kebnekaise_id);
    this.kebnekaiseService.getKebnekaiseDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: (departments) => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  getProduct(id){
    if (_.isObject(this.selectedDepartment)) {
      this.catalogueService.getSingle(this.catalogueId);
      this.catalogueService.getSingleCatalogueDataListener().pipe(take(1)).subscribe({
      next: (subject) => {
        if (subject == null) {
        }
        else {
          this.product = subject[0];

          this.product.laws = ["Egenkontroll förordningen", "AFS 2014:43"];
          if(this.product.description)
          {
            this.product.eco = this.product.description.eco;
          }
          this.product.eco = "Okänt";
          this.product.requirements = ["Se säkerhetsdatablad kap 2", "Se säkerhetsdatablad kap 7"];
          this.product.equipments = ["Se säkerhetsdatablad kap 8"];
          this.catalogueService.getDepartmentsWhereProductAppearsByCatalogueId(this.catalogueId);
          this.catalogueService.getCatalogueDataListener().pipe(take(1)).subscribe({
            next: (departments) => {
              this.departmenstList = departments;
            },
            error: error => {
            this.loadingData = false;
          }
        })
        }
        this.loadingData = false;
      },
      error: error => {
        this.loadingData = false;
        console.log("Error: ", error);
      }
    })
    } else {
        console.log("Error: No department selected!");
    }
  }

  gotoCatalogueList(){
    this.router.navigate(['/catalogue']);
  }

  fileChange(event) {
      this.product.sds_file=event.target.files[0];
      event.target.value = '';
  }

  save(){
    this.loadingData = true;
    this.isError=false;
    this.isErrorArray=[{value:false,text:'NAME'},{value:false,text:'SUPPLIER'}];
    if (this.product.product_name=="") {
      this.isError=true;
      this.isErrorArray[0].value=true;
    }
    if (this.product.supplier_name=="") {
      this.isError=true;
      this.isErrorArray[1].value=true;
    }

    if(this.isError==true){
      this.loadingData = false;
      return;
    }

    if (this.catalogueId!="new") {
      if (_.isString(this.product.unit)) {
        for (var i = 0; i < this.units.length; i += 1) {
            if (this.product.unit?.toLowerCase() === this.units[i].id) {
                this.product.unit = this.units[i];
                break;
            }
        }
      }
    }

    if(this.product.sds_file){
      var getSDSFile = new Promise((resolve, reject) => {
        this.utilService.uploadFile(this.product.sds_file);
        this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
          next: (response) => {
            resolve(response.filename);
          },
          error: error => {
            console.log("Dochistory error: ", error);
            this.loadingData = false;
            this.utilService.onError(error);
          }
        })
      });
      Promise.all([getSDSFile]).then(values=> {
        if(this.product.sds_file){
          this.product.sds_file_id = values[0] as string;
        }
        if(this.catalogueId!="new"){
          this.kebnekaiseService.updateProductWithId(JSON.parse(localStorage.getItem("catalogueDepartment")), this.catalogueId, this.product);
          this.kebnekaiseService.getKebnekaiseDataListener().pipe(take(1)).subscribe({
            next: (pdfContent) => {
              this.router.navigate(['/catalogue']);
              this.loadingData = false;
            },
            error: error => {

            }
          })
        }else{
          let product2 = {
            name: this.product.product_name,
            supplier_name: this.product.supplier_name,
            description: this.product.description,
            flammable: this.product.flammable,
            protective_gear: this.product.protective_gear,
            sds_file: this.product.sds_file,
            sds_file_id: this.product.sds_file_id,
            amount_in_stock:this.product.amount_in_stock,
            estimated_use_per_year: this.product.estimated_use_per_year,
            unit: this.product.unit
        }
          this.kebnekaiseService.createProductWithId(JSON.parse(localStorage.getItem("catalogueDepartment")), product2);
          this.kebnekaiseService.getKebnekaiseDataListener().pipe(take(1)).subscribe({
            next: (pdfContent) => {
              this.router.navigate(['/catalogue']);
              this.loadingData = false;
            },
            error: error => {
              this.loadingData = false;
            }
          })
        }

      });
    } else {
        if(this.catalogueId!="new"){
          this.kebnekaiseService.updateProductWithId(JSON.parse(localStorage.getItem("catalogueDepartment")), this.catalogueId, this.product);
          this.kebnekaiseService.getKebnekaiseDataListener().pipe(take(1)).subscribe({
            next: (pdfContent) => {
              this.router.navigate(['/catalogue']);
              this.loadingData = false;
            },
            error: error => {
              this.loadingData = false;
            }
          })
        }else{
          let product2 = {
            name: this.product.product_name,
            supplier_name: this.product.supplier_name,
            description: this.product.description,
            flammable: this.product.flammable,
            protective_gear: this.product.protective_gear,
            sds_file: this.product.sds_file,
            sds_file_id: this.product.sds_file_id,
            amount_in_stock:this.product.amount_in_stock,
            estimated_use_per_year: this.product.estimated_use_per_year,
            unit: this.product.unit
        }
          this.kebnekaiseService.createProductWithId(JSON.parse(localStorage.getItem("catalogueDepartment")), product2);
          this.kebnekaiseService.getKebnekaiseDataListener().pipe(take(1)).subscribe({
            next: (pdfContent) => {
              this.router.navigate(['/catalogue']);
              this.loadingData = false;
            },
            error: error => {
              this.loadingData = false;
            }
          })
        }
    }




  }

  remove(product){
    this.loadingData = true;
    Swal.fire({
      title: this.translate.instant("REMOVE"),
      text: this.translate.instant("CONFIRM_REMOVE_PRODUCT_FROM_DEPARTMENT"),
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
      cancelButtonText: this.translate.instant("NO"),
    }).then((result) => {
      if (result.isConfirmed) {

        this.kebnekaiseService.deleteProductWithId(JSON.parse(localStorage.getItem("catalogueDepartment")), this.catalogueId);
        this.kebnekaiseService.getKebnekaiseDataListener().pipe(take(1)).subscribe({
        next: (response) => {
          this.router.navigate(['/catalogue']);
          this.loadingData = false;
        },
        error: error => {
          this.router.navigate(['/catalogue']);
          this.loadingData = false;
        }
      })

      } else if (result.isDismissed == true || result.isDenied == true) {
        this.loadingData = false;
      }
    });

  }
}
