import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-modal-send-mail',
  templateUrl: './modal-send-mail.component.html',
  styleUrls: ['./modal-send-mail.component.css']
})
export class ModalSendMailComponent implements OnInit {

  emailrecipent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private authService: AuthService, private formBuilder:FormBuilder) { }

  copyToSelf: boolean = false;
  result: any;

  ngOnInit(): void {
    this.result = {
      email: {
        "copyToSelf": false,
        "email": ""
      }
    };
  }

  ngOnDestroy(): void {
  }

  toggleSelf() {
    this.copyToSelf = !this.copyToSelf;
    this.result.email["copyToSelf"] = this.copyToSelf;
  }

  save() {
    if (this.emailrecipent?.length > 0) {
      this.result.email["email"] = this.emailrecipent;
      this.passEntry.emit(this.result);
    }

    if (this.copyToSelf == true) {
      this.passEntry.emit(this.result);
    }

    this.activeModal.close(ModalSendMailComponent);
  }

  remove() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalSendMailComponent);
  }

  logout() {
    this.authService.logout();
  }
}
