import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class AssessmentService {

  getAssessmentDataUpdated = new Subject<any>();
  getAssessmentSingleDataUpdated = new Subject<any>();
  getAssessmentProductDataUpdated = new Subject<any>();
  getAssessmentPictureDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService, private authService: AuthService,
              private productConversionsService: ProductConversionsService, private sanitizer: DomSanitizer) { }

  convertStringToDate(data) {
    var i,
        dataLength;

    if (_.isArray(data)) {
        dataLength = data.length;
        for (i = 0; i < dataLength; i += 1) {
            data[i].last_chaged = this.utilService.convertStringToDate(data[i].last_changed);
        }
    } else if (_.isObject(data)) {
        data.last_changed = this.utilService.convertStringToDate(data.last_changed);
    }

    return data;
  }

  getAll() {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + '/organisations/' + orgId + '/assessments', {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.convertStringToDate(response);
          this.getAssessmentDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(null);
        }
      });
    }
  }

  AllWithSubDepartments() {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + '/organisations/' + orgId + '/assessments/supdepartments', {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.convertStringToDate(response);
          this.getAssessmentDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(null);
        }
      });
    }
  }

  getSingle(id) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + '/organisations/' + orgId + '/assessments/' + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentSingleDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentSingleDataUpdated.next(null);
        }
      });
    }
  }

  getArbAsTrustedResourceUrl(assessment) {

    let token = localStorage.getItem('token');

    var thisid = assessment.id;
    var orgId = assessment.organisation_id;

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/organisations/' + orgId + '/assessments/' + thisid + "/arb", {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {

        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getAssessmentDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getAssessmentDataUpdated.next(null);
      }
    });
  }

  getArbAsTrustedResourceUrlProducts(thisid, orgId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/organisations/' + orgId + '/assessments/' + thisid + "/arb", {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {

        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getAssessmentDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getAssessmentDataUpdated.next(null);
      }
    });
  }

  getFileAsTrustedResourceUrl(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/assessments/picture/' + id, {headers: headers, responseType: "blob"}).pipe(take(1)).subscribe({next: response => {

          var file = new Blob([response], {type: 'application/image'});
          if (file.size !== 0)
          {
            const unsafeImg = URL.createObjectURL(file);
            var image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
            this.getAssessmentPictureDataUpdated.next(image);
          }
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentPictureDataUpdated.next(null);
        }
      });
    }
  }

  getcDocFileWithId(fileId) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/assessments/file/' + fileId, {headers: headers, responseType: "blob"}).pipe(take(1)).subscribe({next: response => {

          var file = new Blob([response], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          this.getAssessmentDataUpdated.next(fileURL);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(null);
        }
      });
    }
  }

  createARB(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/assessments/' + id + "/generateARB", {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {

          var file = new Blob([response], {type: 'application/pdf'});
          this.getAssessmentDataUpdated.next(file);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(null);
        }
      });
    }
  }

  createAssessment(info) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post<any>(BackendUrl + '/organisations/' + orgId + '/assessments', info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(null);
        }
      });
    }
  }

  updateAssessment(id, info) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.put<any>(BackendUrl + '/organisations/' + orgId + '/assessments/' + id, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(null);
        }
      });
    }
  }

  updateAssessmentStatusRisk(id, info) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.put<any>(BackendUrl + '/organisations/' + orgId + '/assessments/status/' + id, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(null);
        }
      });
    }
  }

  deleteAssessment(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.delete(BackendUrl + "/organisations/" + orgId + "/assessments/" + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentDataUpdated.next(true);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(true);
        }
      });
    }
  }

  getAllProducts() {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + '/organisations/' + orgId + '/assessments/getallproducts', {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.productConversionsService.convertPictogramsOnProductArray(response);
          this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
          this.getAssessmentDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentDataUpdated.next(null);
        }
      });
    }
  }

  getAssSingleProduct(id) {
    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + '/organisations/' + orgId + '/assessments/product/' + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.productConversionsService.convertPictogramsOnProductArray(response);
          this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
          this.getAssessmentProductDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentProductDataUpdated.next(null);
        }
      });
    }
  }

  getActivityBasedDataSheets(productid) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }
    }
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("productid", productid);
    this.http.get(BackendUrl + "/organisations/" + orgId + "/assessments/activityBaseDataSheets", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        this.getAssessmentProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getAssessmentProductDataUpdated.next(null);
      }
    });


  }

  public getAssessmentDataListener() {
    return this.getAssessmentDataUpdated.asObservable();
  }

  public getAssessmentSingleDataListener() {
    return this.getAssessmentSingleDataUpdated.asObservable();
  }

  public getAssessmentProductDataListener() {
    return this.getAssessmentProductDataUpdated.asObservable();
  }

  public getAssessmentPictureDataListener() {
    return this.getAssessmentPictureDataUpdated.asObservable();
  }
}

