<div class="flex max-w-screen bg-cdoclightblue pl-2 pr-7 py-2">
    <div class="md:hidden mr-10 z-20" appDropdown>
        <button class="bg-white hover:bg-cdocblue">
            <svg class="nav-org" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" fill="#34495E"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 8.25H4.5V6.75H19.5V8.25Z" fill="#ffffff"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 12.75H4.5V11.25H19.5V12.75Z" fill="#ffffff"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 17.25H4.5V15.75H19.5V17.25Z" fill="#ffffff"/>
            </svg>
        </button>
        <div class="hidden dropdown-menu" #dropdownMenuInside>
            <div class="py-1">
                <a (click)="navigateHome()" id="go_dashboard" class="dropdown-menu-help-item" tabindex="1">
                    {{"DASHBOARD" | translate}}
                </a>
                <a *ngIf="subscribesToCatalogue&&!currentUser.is_guest"  (click)="gotoCatalogue()" id="go_catalogue" class="dropdown-menu-help-item" tabindex="2">
                    {{"SUBSCRIPTION_CATALOGUE" | translate}}
                </a>
                <a (click)="gotoProducts()" id="user_settings" id="go_products" class="dropdown-menu-help-item" tabindex="3">
                    {{"PRODUCTS" | translate}}
                </a>
                <a *ngIf="subscribesToCoordinator"  (click)="goToCoordinator()" id="coordinator" class="dropdown-menu-help-item" tabindex="4">
                    {{"COORDINATOR" | translate}}
                </a>
                <a *ngIf="subscribesToExposures" (click)="goToExposures()" id="exposures" class="dropdown-menu-help-item" tabindex="5">
                    {{"EXPOSURE_LIST" | translate}}
                </a>
                <a *ngIf="subscribesToSubstitution" (click)="gotoSubstitution()" id="substitution" class="dropdown-menu-help-item" tabindex="6">
                    {{"SUBSTITUTION_LINK" | translate}}
                </a>
                <a *ngIf="currentUser&&!currentUser.is_local_user&&subscribesToReports" (click)="goToHistory()" id="history" class="dropdown-menu-help-item" tabindex="7">
                    {{"HISTORY_LINK" | translate}}
                </a>
                <a *ngIf="subscribesToRiskAssessment" (click)="goToAssessment()" id="nav_assessment" class="dropdown-menu-help-item" tabindex="8">
                    {{"ACTIVITIES" | translate}}
                </a>
                <a *ngIf="subscribesToRiskAssessment" (click)="goToAssessmentChemical()" id="nav_assessment_chemicals" class="dropdown-menu-help-item" tabindex="9">
                    {{"CHEMICALS" | translate}}
                </a>
                <a *ngIf="currentUser && !currentUser.is_guest && subscribesToReports" (click)="gotoReports()" class="dropdown-menu-help-item" tabindex="10">
                    {{"REPORTS" | translate}}
                </a>
                <a *ngIf="subscribesToProjects&&!currentUser.is_guest" (click)="gotoMaterialList()" class="dropdown-menu-help-item" tabindex="11">
                    {{"PROJECTS" | translate}}
                </a>
                <a *ngIf="currentUser && !currentUser.is_guest && (subscribesToLawLawpoint || subscribesToLawAptor)" (click)="gotoLaw()" class="dropdown-menu-help-item" tabindex="12">
                    {{"LAW_ENFORCEMENT_LINK" | translate}}
                </a>
                <a *ngIf="currentUser && !currentUser.is_guest && subscribesToSamtec" (click)="gotoSamtec()" class="dropdown-menu-help-item" tabindex="13">
                    {{"SAMTEC" | translate}}
                </a>
                <a *ngIf="subscribesToEducation&&!currentUser.is_guest" (click)="gotoEducation()" class="dropdown-menu-help-item" tabindex="14">
                    {{"ONLINE_ACADEMY" | translate}}
                </a>
                <a *ngIf="currentUser&&(currentUser.is_super_user||currentUser.is_maintenance_user||currentUser.is_readonly_user)" (click)="gotoControlPanel()" class="dropdown-menu-help-item" tabindex="15">
                    {{"CONTROL_PANEL" | translate}}
                </a>
            </div>
        </div>
    </div>
    
    <div class="flex flex-row mr-auto">
        <span *ngIf="subscribesToCatalogue" (click)="goToCatalogue()" class="hidden md:block text-brightblue cursor-pointer font-bold ml-4">
            <span class="font-bold text-white">{{"SUBSCRIPTION_CATALOGUE" | translate}}:</span>&nbsp; {{cataloguesCount}}
        </span>
        <span *ngIf="subscribesToProducts" (click)="goToProducts()" class="text-brightblue md:ml-4 font-bold cursor-pointer">
            <span class="font-bold text-white">{{"PRODUCTS" | translate}}:</span>&nbsp; {{productsCount}}
        </span>
        <span *ngIf="subscribesToCoordinator&&currentUser&&(currentUser.is_admin == 1 || currentUser.is_local_admin == 1 ||currentUser.is_local_assignee == 1 || 
            currentUser.is_super_user == 1 || currentUser.is_useradmin == 1)" (click)="goToCoordinator()" class="hidden md:block text-brightblue ml-4 font-bold cursor-pointer">
            <span class="font-bold text-white">{{"COORDINATOR" | translate}}:</span>&nbsp; {{coordinatorCount}}
        </span>
        <span *ngIf="subscribesToSubstitution&&currentUser&&(currentUser.is_admin == 1 || currentUser.is_local_admin == 1 ||currentUser.is_local_assignee == 1 || 
            currentUser.is_super_user == 1 || currentUser.is_useradmin == 1)" (click)="goToSubstitution()" class="hidden md:block text-brightblue ml-4 font-bold cursor-pointer">
            <span class="font-bold text-white">{{"SUBSTITUTION" | translate}}:</span>&nbsp; {{substitutionCount}}
        </span>
        <span *ngIf="subscribesToRiskAssessment&&currentUser&&(currentUser.is_admin == 1 || currentUser.is_local_admin == 1 ||currentUser.is_local_assignee == 1 || 
            currentUser.is_super_user == 1 || currentUser.is_useradmin == 1)" (click)="gotoLocalRiskAssessments()" class="hidden md:block text-brightblue font-bold ml-4 cursor-pointer">
            <span class="font-bold text-white">LRB:</span>&nbsp; {{lrbCount}}
        </span>
        <span *ngIf="subscribesToRiskAssessment&&currentUser&&(currentUser.is_admin == 1 || currentUser.is_local_admin == 1 ||currentUser.is_local_assignee == 1 || 
            currentUser.is_super_user == 1 || currentUser.is_useradmin == 1)" (click)="gotoActivityRiskAssessments()" class="hidden md:block text-brightblue font-bold ml-4 cursor-pointer">
            <span class="font-bold text-white">ARB:</span>&nbsp; {{arbCount}}
        </span>
    </div>

    <div class="ml-auto">
        <div class="relative inline-block text-left z-20" appDropdown>
            <button type="button" class="text-white flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <img title="icon" src="images/icons/avatar-svgrepo-com.svg" class="h-6 w-6 mr-1">
                <span class="hidden md:block font-bold">{{currentUser.first_name}} {{currentUser.last_name}}</span>
            </button>
            <div class="hidden dropdown-menu right-1" #dropdownMenuInside>
                <div class="py-1">
                    <div *ngIf="!currentUser.is_guest"  (click)="goToHelp()" id="help" class="dropdown-menu-help-item" tabindex="1">
                        {{"HELP" | translate}}
                    </div>
                    <div *ngIf="!currentUser.is_guest" (click)="goToSettings()" id="user_settings" class="dropdown-menu-help-item" tabindex="2">
                        {{"SETTINGS" | translate}}
                    </div>
                    <div *ngIf="!currentUser.is_guest" (click)="showContactForm()" id="showContactForm" class="dropdown-menu-help-item" tabindex="3">
                        Support
                    </div>
                    <div (click)="logout()" id="log_out" class="dropdown-menu-help-item" tabindex="4">
                        {{"LOGOUT" | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
