<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-xl text-gray-900 font-bold">{{"UPLOAD" | translate}}</div>
            <!-- Modal Body -->
            <input title="{{'UPLOAD' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" id="upload-file" (change)="incomingfile($event)"/>
            <button class="button-blue mt-2 mb-2" title="{{'UPLOAD' | translate}}" (click)="loadFromFile()">
                <span class="text-lg">{{"UPLOAD" | translate}}...</span>
            </button>
        </div>
    </div>
</div>