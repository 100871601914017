import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/services/report.service';
import { FormBuilder, Validators } from '@angular/forms';
import { StaticDataService } from 'src/app/services/static-data.service';
import { HistorySimplifiedComponent } from 'src/app/history/history-simplified/history-simplified.component';
import { HistoryInventoryComponent } from 'src/app/history/history-inventory/history-inventory.component';
import { HistoryArticleComponent } from 'src/app/history/history-article/history-article.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any;
  showSubdepartments: boolean = false;
  supportedReportTypes: any = [];
  reportInformation: any;
  dateoptions: any;
  chosenComponent: any;
  reportForm: any;

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private router: Router,
    private formBuilder: FormBuilder,
    private staticDataService: StaticDataService,
    private modalService: NgbModal) { }

  ngOnInit(): void {

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    this.reportForm = this.formBuilder.group({
      type: [''],
      date2: [''],
      date: ['']
    });

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.supportedReportTypes = this.staticDataService.getSupportedHistoryTypes();

    if (this.supportedReportTypes[0]) {
      this.reportInformation = {
        start_date: new Date("2015 1 January"),
        date: new Date(),
        date2: new Date(new Date().getFullYear(), 0, 1),
        reportType: this.supportedReportTypes[0],
        name: this.supportedReportTypes[0].name,
        id: this.supportedReportTypes[0].id,
        includeSubDepartments: false,
        registerProducts: false,
        product: false
      };
    }

    this.chosenComponent = HistorySimplifiedComponent;
    this.reportInformation.reportType = this.supportedReportTypes[0];
    this.reportInformation.name = this.supportedReportTypes[0].name;
    this.reportInformation.id = this.supportedReportTypes[0].id;

    this.dateoptions = {
      maxDate: new Date()
    };

    this.getAllDepartments();
  }

  choseReport(type) {

    this.chosenComponent = null;

    switch(type) {

      case this.translate.instant("REPORT_SIMPLIFIED"):
      case "REPORT_SIMPLIFIED":
        this.chosenComponent = HistorySimplifiedComponent;
        this.reportInformation.reportType = this.supportedReportTypes[0];
        this.reportInformation.name = this.supportedReportTypes[0].name;
        this.reportInformation.id = this.supportedReportTypes[0].id;
        break;

      case this.translate.instant("REPORT_STATUTORY"):
      case "REPORT_STATUTORY":
        this.chosenComponent = HistoryInventoryComponent;
        this.reportInformation.reportType = this.supportedReportTypes[1];
        this.reportInformation.name = this.supportedReportTypes[1].name;
        this.reportInformation.id = this.supportedReportTypes[1].id;
        break;

      case this.translate.instant("REPORT_ARTICLES"):
      case "REPORT_ARTICLES":
        this.chosenComponent = HistoryArticleComponent;
        this.reportInformation.reportType = this.supportedReportTypes[2];
        this.reportInformation.name = this.supportedReportTypes[2].name;
        this.reportInformation.id = this.supportedReportTypes[2].id;
        break;

      default:
        break;
    }
  }

  createReport() {

    if (this.chosenComponent&&this.chosenComponent != null) {
      const modalRef = this.modalService.open(this.chosenComponent, {windowClass: 'gr-modal-full', size: 'lg', backdrop: "static"});
      modalRef.componentInstance.reportInfo = this.reportInformation;
      /*modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((receivedGroupName) => {
      });*/
    }
  }

  onToggleShowSubDepartments(){
    this.reportInformation.includeSubDepartments = !this.reportInformation.includeSubDepartments;
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  logout() {
    this.authService.logout();
  }
}
