import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { ExposuresService } from 'src/app/services/exposures.service';
import { SareqService } from 'src/app/services/sareq.service';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FileUploadService } from 'src/app/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-edit-exposure',
  templateUrl: './edit-exposure.component.html',
  styleUrls: ['./edit-exposure.component.css']
})

export class EditExposureComponent implements OnInit {
  itemsPerPage:number = 10;
  pageNumber:number = 1;
  currentUser: any;
  exposure: any;
  prev_exposure: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  listAlldepartments: any;
  created_date: any = 0;
  exposureId: any;
  products: any = [];
  filteredProducts: any = [];
  date: any;
  loadingData: boolean = false;
  sareqSelected: boolean = false;
  allProductSelected: boolean = false;
  chemical_name: string = "";

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
              private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
              private productService: ProductService, private organisationService: OrganisationService,
              private route: ActivatedRoute, private exposuresService: ExposuresService,private sareqService: SareqService,
              private uploadService: FileUploadService) { }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.exposureId = params['id'];
    });
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if (this.organisation == null || this.selectedDepartment == null || this.selectedOrganisation == null) {
      this.logout();
    }
    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }
    this.loadingData = true;
    this.getAllDepartments();
    this.getSingleExposure();
  }

  // Method to check if two objects are different
  hasPostChanged(): boolean {
    return JSON.stringify(this.exposure) != JSON.stringify(this.prev_exposure);
  }

  cancel() {
    if (this.hasPostChanged() == true)
    {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        } else {
          this.router.navigate(['/exposures']);
        }
      })
    }
    else
    {
      this.router.navigate(['/exposures']);
    }
  }

  onSearchProduct(searchValue: string): void {

    this.filteredProducts =  this.products;

    if (searchValue !== "")
    {
        this.filteredProducts = this.products.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  getSingleExposure() {
    if (this.selectedDepartment != null) {
      this.productService.AllMoreInformation(this.selectedDepartment.id);
      this.productService.getProductAllSubListener().pipe(take(1)).subscribe({
        next: products => {
          if (products.length == 0) {

            this.loadingData = false;
          } else {
            this.products = _.orderBy(products, ['type', 'name']);
            this.filteredProducts =  this.products;
            this.exposuresService.getSingle(this.exposureId);
            this.exposuresService.getExposuresDataListener().pipe(take(1)).subscribe({
              next: exposure => {
                if (exposure == null) {
                  this.loadingData = false;
                }
                else {
                  this.exposure = exposure[0];
                  var id=this.exposure.chemical;
                  this.exposure.chemical = this.getProductById(this.exposure.chemical, this.products);
                  if(this.exposure.file_id){
                    this.exposure.uploaded_file_id=this.exposure.file_id;
                  }
                  if (this.exposure.chemical == null) {
                    this.exposure.chemical=[];
                    this.sareqService.getname(id);
                    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
                      next: (responce) => {
                        if(responce=='Not found'){
                          this.exposure.chemical = null;
                          this.prev_exposure = _.cloneDeep(this.exposure);
                          return;
                        }
                        else
                        {
                          this.exposure.chemical.name = responce.rows[0].name;
                          this.prev_exposure = _.cloneDeep(this.exposure);
                        }
                      }
                    });
                  }
                  this.loadingData = false;
                }
              },
              error: error => {
                this.loadingData = false;
                console.log("Error: ", error);
              }
            })
          }
        },
        error: error => {
          this.loadingData = false;
          console.log("Error: ", error);
        }
      });
    }
  }

  getProductById(productId, productArray) {
    var i,
        foundProduct = null,
        arrLength;
    if (_.isArray(productArray)) {
      arrLength = productArray.length;
      for (i = 0; i < arrLength; i += 1) {
        if (productArray[i].id === productId) {
          foundProduct = productArray[i];
          break;
        }
      }
    }
    return foundProduct;
  }

  remove() {
    Swal.fire({
      title: this.translate.instant("REMOVE_EXPOSURE") + "?",
      html: this.translate.instant("REMOVE_EXPOSURE_WARNING") + "<br><br><div align='left'></div>",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("NO"),
      confirmButtonText: this.translate.instant("YES"),
      closeOnConfirm: true,
    }).then((result) =>
    {
      if (result.isConfirmed) {
        this.exposuresService.deleteExposure(this.exposureId);
        this.exposuresService.getExposuresDataListener().pipe(take(1)).subscribe({
          next: exposure => {
            if (exposure == null) {
              Swal.fire("Error", "generiskt fel");
            }
            else {

              this.router.navigate(['/exposures']);
            }
          },
          error: error => {
            this.loadingData = false;
            Swal.fire("Error", "generiskt fel");
          }
        })
      }
    });
  }

  submit() {
    this.exposuresService.updateExposure(this.exposureId, this.exposure);
    this.exposuresService.getExposuresDataListener().pipe(take(1)).subscribe({
      next: exposure => {
        if (exposure == null) {
          Swal.fire("Error", "generiskt fel");
        }
        else {
          this.router.navigate(['/exposures']);
        }
      },
      error: error => {
        this.loadingData = false;
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  goToExposureList() {
    this.router.navigate(['/exposures']);
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {

          }
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  setProduct(product) {
    this.exposure.chemical = product;
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  logout() {
    this.authService.logout();
  }

  openFileInNewWindow(item){
    this.exposuresService.getFileAsTrustedResourceUrl(this.exposure.id, item);
    this.exposuresService.getExposuresDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })

  }

  incomingfile(event){
    var file = event.target.files[0];
    if (event.target.files[0] != null) {
      var file = event.target.files[0];
      this.uploadService.upload(event.target.files[0]);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {

          }
          else {
            this.exposure.file_id = result.filename;
            this.exposure.file_name = file.name;
          }
        },
        error: error => {
        console.log("Error: ", error);
        }
      })
    }
  }

  fileRemove(){
    this.exposure.old_file_name = "";
    this.exposure.old_file_id = "";
    this.exposure.old_file_name = this.exposure.file_name;
    this.exposure.old_file_id = this.exposure.file_id;
    this.exposure.uploaded_file_id="";
    this.exposure.file_name = "";
    this.exposure.file_id = "";
  }
}
