import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import * as _ from "lodash";

export class ReportType {
  public static Simplified:       0;
  public static Statutory:        1;
  public static Fire:             2;
  public static Note:             3;
  public static Sds:              4;
  public static Grb:              5;
  public static Classification:   6;
  public static Bom:              7;
  public static Enviroment:       8;
  public static Purchase:         9;
  public static Cmr:              10;
  public static Sds_Activity:     11;
  public static Sll:              12;
  public static Simple_Kebnekaise:13;
  public static Articles:         14;
  public static Allergenic:       15;
  public static Substitution:     16;
  public static Styren:           17;
  public static Sareq_History:    18;
}

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor(private translate: TranslateService, private utilService: UtilService) { }

  /**
     * Return array of supported report types, depending on company's subscriptions
     */
   getSupportedReportTypes() {
    var reportTypes = {
            simple: {
                id: ReportType.Simplified,
                name: this.translate.instant("REPORT_SIMPLIFIED"),
                url: "simplified",
                translation: "REPORT_SIMPLIFIED",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_SIMPLE")
            },
            statutory: {
                id: ReportType.Statutory,
                name: this.translate.instant("REPORT_STATUTORY"),
                url: "inventory",
                translation: "REPORT_STATUTORY",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_STATUTORY")
            },
            fire: {
                id: ReportType.Fire,
                name: this.translate.instant("REPORT_FIRE"),
                url: "fire",
                translation: "REPORT_FIRE",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_FIRE")
            },
            cmr: {
              id: ReportType.Cmr,
              name: this.translate.instant("REPORT_CMR"),
              url: "cmrlist",
              translation: "REPORT_CMR",
              activated: false,
              disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_CMR")
            },
            notes: {
                id: ReportType.Note,
                name: this.translate.instant("REPORT_NOTE"),
                url: "noteslist",
                translation: "REPORT_NOTE",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_NOTES")
            },
            sds: {
                id: ReportType.Sds,
                name: this.translate.instant("REPORT_SDS"),
                url: "simplified",
                translation: "REPORT_SDS",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_SDS")
            },
            grb: {
                id: ReportType.Grb,
                name: this.translate.instant("REPORT_GRB"),
                url: "simplified",
                translation: "REPORT_GRB",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_GRB")
            },
            classification: {
                id: ReportType.Classification,
                name: this.translate.instant("REPORT_PH_CLASSIFICATION"),
                url: "classification",
                translation: "REPORT_PH_CLASSIFICATION",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_CLASSIFICATION")
            },
            enviroment: {
                id: ReportType.Enviroment,
                name: this.translate.instant("REPORT_ENVIROMENT"),
                url: "enviroment",
                translation: "REPORT_ENVIROMENT",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_ENVIROMENT")
            },
            sds_activity: {
                id: ReportType.Sds_Activity,
                name: this.translate.instant("REPORT_SDS_ACTIVITY"),
                url: "simplified",
                translation: "REPORT_SDS_ACTIVITY",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_SDS_ACTIVITY")
            },
            sll: {
                id: ReportType.Sll,
                name: this.translate.instant("REPORT_SLL"),
                url: "sll",
                translation: "REPORT_SLL",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_SLL")
            },
            simple_kebnekaise: {
                id: ReportType.Simple_Kebnekaise,
                name: this.translate.instant("REPORT_K_SIMPLIFIED"),
                url: "simple_kebnekaise",
                translation: "REPORT_K_SIMPLIFIED",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_CATALOGUE")
            },
            articles: {
                id: ReportType.Articles,
                name: this.translate.instant("REPORT_ARTICLES"),
                url: "articles",
                translation: "REPORT_ARTICLES",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_SDS_ACTIVITY")
            },
            allergenic: {
                id: ReportType.Allergenic,
                name: this.translate.instant("REPORT_ALLERGENIC"),
                url: "allergenic",
                translation: "REPORT_ALLERGENIC",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_ALLERGENIC")
            },
            substitution: {
              id: ReportType.Substitution,
              name: this.translate.instant("REPORT_SUBSTITUTION"),
              url: "substitution",
              translation: "REPORT_SUBSTITUTION",
              activated: false,
              disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_SUBSTITUTION")
            },
            styren: {
              id: ReportType.Styren,
              name: this.translate.instant("REPORT_STYREN"),
              url: "styren",
              translation: "REPORT_STYREN",
              activated: false,
              disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_STYREN")
            },
            sareq_history: {
              id: ReportType.Sareq_History,
              name: this.translate.instant("REPORT_SAREQ_HISTORY"),
              url: "sareq_history",
              translation: "REPORT_SAREQ_HISTORY",
              activated: false,
              disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_STYREN")
            }
        },
        result = [];

    if (this.utilService.subscribesTo("subscription_report_simple")) {
        reportTypes.simple.activated = true;
        reportTypes.simple.disabled_tooltip = "";
        result.push(reportTypes.simple);
    }

    if (this.utilService.subscribesTo("subscription_report_statutory")) {
        reportTypes.statutory.activated = true;
        reportTypes.statutory.disabled_tooltip = "";
        result.push(reportTypes.statutory);
    }

    if (this.utilService.subscribesTo("subscription_report_fire")) {
        reportTypes.fire.activated = true;
        reportTypes.fire.disabled_tooltip = "";
        result.push(reportTypes.fire);
    }

    if (this.utilService.subscribesTo("subscription_report_cmr")) {
      reportTypes.cmr.activated = true;
      reportTypes.cmr.disabled_tooltip = "";
      result.push(reportTypes.cmr);
    }

    if (this.utilService.subscribesTo("subscription_report_notes")) {
        reportTypes.notes.activated = true;
        reportTypes.notes.disabled_tooltip = "";
        result.push(reportTypes.notes);
    }

    if (this.utilService.subscribesTo("subscription_report_sds")) {
        reportTypes.sds.activated = true;
        reportTypes.sds.disabled_tooltip = "";
        result.push(reportTypes.sds);
    }

    if (this.utilService.subscribesTo("subscription_report_grb")) {
        reportTypes.grb.activated = true;
        reportTypes.grb.disabled_tooltip = "";
        result.push(reportTypes.grb);
    }

    if (this.utilService.subscribesTo("subscription_report_classification")) {
        reportTypes.classification.activated = true;
        reportTypes.classification.disabled_tooltip = "";
        result.push(reportTypes.classification);
    }

    if (this.utilService.subscribesTo("subscription_report_enviroment")) {
        reportTypes.enviroment.activated = true;
        reportTypes.enviroment.disabled_tooltip = "";
        result.push(reportTypes.enviroment);
    }

    if (this.utilService.subscribesTo("subscription_report_sds_activity")) {
        reportTypes.sds_activity.activated = true;
        reportTypes.sds_activity.disabled_tooltip = "";
        result.push(reportTypes.sds_activity);
    }

    if (this.utilService.subscribesTo("subscription_report_sll") && this.utilService.subscribesTo("subscription_freemium")==false) {
        reportTypes.sll.activated = true;
        reportTypes.sll.disabled_tooltip = "";
        result.push(reportTypes.sll);
    }

    if (this.utilService.subscribesTo("subscription_catalogue")) {
        reportTypes.simple_kebnekaise.activated = true;
        reportTypes.simple_kebnekaise.disabled_tooltip = "";
        result.push(reportTypes.simple_kebnekaise);
    }

    if (this.utilService.subscribesTo("subscription_reports") && this.utilService.subscribesTo("subscription_freemium")==false) {
        reportTypes.articles.activated = true;
        //reportTypes.articles_tooltip = "";
        result.push(reportTypes.articles);
    }
    if (this.utilService.subscribesTo("subscription_report_allergenic")) {
        reportTypes.allergenic.activated = true;
        reportTypes.allergenic.disabled_tooltip = "";
        result.push(reportTypes.allergenic);
    }

    if (this.utilService.subscribesTo("subscription_substitution")) {
      reportTypes.substitution.activated = true;
      reportTypes.substitution.disabled_tooltip = "";
      result.push(reportTypes.substitution);
    }

    if (this.utilService.subscribesTo("subscription_styren")) {
      reportTypes.styren.activated = true;
      reportTypes.styren.disabled_tooltip = "";
      result.push(reportTypes.styren);
    }

    if (JSON.parse(localStorage.getItem('currentUser', )).is_super_user) {
      reportTypes.sareq_history.activated = true;
      reportTypes.sareq_history.disabled_tooltip = "";
      result.push(reportTypes.sareq_history);
    }
    return result;
  }

  getSupportedHistoryTypes() {
    var reportTypes = {
            simple: {
                id: ReportType.Simplified,
                name: this.translate.instant("REPORT_SIMPLIFIED"),
                url: "simplified",
                translation: "REPORT_SIMPLIFIED",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_SIMPLE")
            },
            statutory: {
                id: ReportType.Statutory,
                name: this.translate.instant("REPORT_STATUTORY"),
                url: "inventory",
                translation: "REPORT_STATUTORY",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_STATUTORY")
            },
            articles: {
                id: ReportType.Articles,
                name: this.translate.instant("REPORT_ARTICLES"),
                url: "articles",
                translation: "REPORT_ARTICLES",
                activated: false,
                disabled_tooltip: this.translate.instant("MISSING_SUBSCRIPTION") + ": " + this.translate.instant("SUBSCRIPTION_REPORT_SDS_ACTIVITY")
            }
        },
        result = [];

    if (this.utilService.subscribesTo("subscription_report_simple")) {
        reportTypes.simple.activated = true;
        reportTypes.simple.disabled_tooltip = "";
        result.push(reportTypes.simple);
    }

    if (this.utilService.subscribesTo("subscription_report_statutory")) {
        reportTypes.statutory.activated = true;
        reportTypes.statutory.disabled_tooltip = "";
        result.push(reportTypes.statutory);
    }

    if (this.utilService.subscribesTo("subscription_reports") && this.utilService.subscribesTo("subscription_freemium")==false) {
        reportTypes.articles.activated = true;
        //reportTypes.articles_tooltip = "";
        result.push(reportTypes.articles);
    }
    return result;
  }

  /**
     * Unit definitions with translations
     */
   getUnitDefinitions() {
    return [
        {id: "l",  name: this.translate.instant("UNIT_L"),  translation: "UNIT_L"},
        {id: "ml", name: this.translate.instant("UNIT_ML"), translation: "UNIT_ML"},
        {id: "g",  name: this.translate.instant("UNIT_G"),  translation: "UNIT_G"},
        {id: "kg", name: this.translate.instant("UNIT_KG"), translation: "UNIT_KG"},
        {id: "m3", name: this.translate.instant("UNIT_M3"), translation: "UNIT_M3"}
    ];
  }

  /**
     * Available languages
     */
   getAvailableLanguages() {
    return [
        {id: 1, name: this.translate.instant("SWEDISH"), translation: "SWEDISH", languageCode: "sv"},
        {id: 2, name: this.translate.instant("ENGLISH"), translation: "ENGLISH", languageCode: "en"},
        {id: 3, name: this.translate.instant("FINNISH"), translation: "FINNISH", languageCode: "fi"}
    ];
  }

  /**
     * Available Coordinator status
     */
   getAvailableCoordinatorSubjectStatus() {
    var result = [
        {id: 0, name: this.translate.instant("NOT_STARTED"), translation: "NOT_STARTED"},
        {id: 1, name: this.translate.instant("STARTED"), translation: "STARTED"},
        {id: 2, name: this.translate.instant("WAITING_FOR_ANSWER"), translation: "WAITING_FOR_ANSWER"},
        {id: 3, name: this.translate.instant("ANSWERED"), translation: "ANSWERED"},
        {id: 4, name: this.translate.instant("DONE"), translation: "DONE"},
    ];

    result.sort(function (a, b) {
        var c = a.id;
        var d = b.id;

        return c > d ? 1 : c === d ? 0 : -1;
    });

    return result;
  }

  /**
   * Available restriction lists
   */
  getAvailableRestrictionLists() {
    return [
        {id: 0, name: this.translate.instant("RESTRICTION_LIST_REACH_14"), translation: "RESTRICTION_LIST_REACH_14", identifier: "list_reach_14"},
        {id: 1, name: this.translate.instant("RESTRICTION_LIST_REACH_17"), translation: "RESTRICTION_LIST_REACH_17", identifier: "list_reach_17"},
        {id: 2, name: this.translate.instant("RESTRICTION_LIST_REACH_CANDIDATE"), translation: "RESTRICTION_LIST_REACH_CANDIDATE", identifier: "list_reach_candidate"},
        {id: 3, name: this.translate.instant("RESTRICTION_LIST_CHEM_LIMIT"), translation: "RESTRICTION_LIST_CHEM_LIMIT", identifier: "list_chem_limit"},
        {id: 4, name: this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_P"), translation: "RESTRICTION_LIST_CHEM_PRIO_P", identifier: "list_chem_prio_p"},
        {id: 5, name: this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_U"), translation: "RESTRICTION_LIST_CHEM_PRIO_U", identifier: "list_chem_prio_u"},
        {id: 6, name: this.translate.instant("RESTRICTION_LIST_WATER_DIRECTIVE"), translation: "RESTRICTION_LIST_WATER_DIRECTIVE", identifier: "list_water_directive"},
        {id: 7, name: this.translate.instant("RESTRICTION_LIST_AMV_A"), translation: "RESTRICTION_LIST_AMV_A", identifier: "list_amv_a"},
        {id: 8, name: this.translate.instant("RESTRICTION_LIST_AMV_B"), translation: "RESTRICTION_LIST_AMV_B", identifier: "list_amv_b"},
        {id: 9, name: this.translate.instant("RESTRICTION_LIST_SLL"), translation: "RESTRICTION_LIST_SLL", identifier: "list_sll"},
        {id: 10, name: this.translate.instant("RESTRICTION_LIST_BASTA"), translation: "RESTRICTION_LIST_BASTA", identifier: "list_BASTA"},
        {id: 11, name: this.translate.instant("RESTRICTION_LIST_SIN"), translation: "RESTRICTION_LIST_SIN", identifier: "list_sin"},
    ];
  }

  /**
   * Returns a list of valid classifications
   */
  getAvailableClassifications() {
    return [
        {id: 0, name: this.translate.instant("UNKNOWN"), translation: "UNKNOWN", class: "cdoc-classification-unknown"},
        {id: 1, name: this.translate.instant("ACID"), translation: "ACID", class: "cdoc-classification-acid"},
        {id: 2, name: this.translate.instant("NEUTRAL"), translation: "NEUTRAL", class: "cdoc-classification-neutral"},
        {id: 3, name: this.translate.instant("ALCALINE"), translation: "ALCALINE", class: "cdoc-classification-alcaline"},
        {id: 4, name: this.translate.instant("PETROLEUM"), translation: "PETROLEUM", class: "cdoc-classification-petroleum"},
        {id: 5, name: this.translate.instant("OTHER"), translation: "OTHER", class: "cdoc-classification-other"}
    ];
  }

  /**
   * Returns a list of available subscriptions
   */
   getAvailableSubscriptions() {
    var result = [
      {
        id: 1,
        name: this.translate.instant("SUBSCRIPTION_ACTIVITIES"),
        identifier: "subscription_activities",
        description: "SUBSCRIPTION_ACTIVITIES_DESCRIPTION",
        active: false,
      },
      {
        id: 2,
        name: this.translate.instant("SUBSCRIPTION_APP"),
        identifier: "subscription_app",
        description: "SUBSCRIPTION_APP_DESCRIPTION",
        active: false,
      },
      {
        id: 3,
        name: this.translate.instant("SUBSCRIPTION_CLASSIFICATION"),
        identifier: "subscription_classification",
        description: "SUBSCRIPTION_CLASSIFICATION_DESCRIPTION",
        active: false,
      },
      {
        id: 4,
        name: this.translate.instant("SUBSCRIPTION_COORDINATOR"),
        identifier: "subscription_coordinator",
        description: "SUBSCRIPTION_COORDINATOR_DESCRIPTION",
        active: false,
      },
      {
        id: 5,
        name: this.translate.instant("SUBSCRIPTION_EXPOSURES"),
        identifier: "subscription_exposures",
        description: "SUBSCRIPTION_EXPOSURES_DESCRIPTION",
        active: false,
      },
      {
        id: 6,
        name: this.translate.instant("SUBSCRIPTION_GRB"),
        identifier: "subscription_grb",
        description: this.translate.instant("SUBSCRIPTION_GRB_DESCRIPTION"),
        active: false,
      },
      {
        id: 7,
        name: this.translate.instant("SUBSCRIPTION_LAWPOINT"),
        identifier: "subscription_lawpoint",
        description: "SUBSCRIPTION_LAWPOINT_DESCRIPTION",
        active: false,
      },
      {
        id: 8,
        name: this.translate.instant("SUBSCRIPTION_PRODUCTS"),
        identifier: "subscription_products",
        description: this.translate.instant(
          "SUBSCRIPTION_PRODUCTS_DESCRIPTION"
        ),
        active: false,
      },
      {
        id: 9,
        name: this.translate.instant("SUBSCRIPTION_PRODUCTSHEET"),
        identifier: "subscription_productsheet",
        description: "SUBSCRIPTION_PRODUCTSHEET_DESCRIPTION",
        active: false,
      },
      {
        id: 10,
        name: this.translate.instant("SUBSCRIPTION_PROJECTS"),
        identifier: "subscription_projects",
        description: "SUBSCRIPTION_PROJECTS_DESCRIPTION",
        active: false,
      },
      {
        id: 11,
        name: this.translate.instant("SUBSCRIPTION_PURCHASE"),
        identifier: "subscription_purchase",
        description: "SUBSCRIPTION_PURCHASE_DESCRIPTION",
        active: false,
      },
      {
        id: 12,
        name: this.translate.instant("SUBSCRIPTION_REPORTS"),
        identifier: "subscription_reports",
        description: "SUBSCRIPTION_REPORTS_DESCRIPTION",
        active: false,
      },
      {
        id: 13,
        name: this.translate.instant("SUBSCRIPTION_REPORT_CLASSIFICATION"),
        identifier: "subscription_report_classification",
        description: "SUBSCRIPTION_REPORT_CLASSIFICATION_DESCRIPTION",
        active: false,
      },
      {
        id: 14,
        name: this.translate.instant("SUBSCRIPTION_REPORT_CMR"),
        identifier: "subscription_report_cmr",
        description: "SUBSCRIPTION_REPORT_CMR_DESCRIPTION",
        active: false,
      },
      {
        id: 15,
        name: this.translate.instant("SUBSCRIPTION_REPORT_FIRE"),
        identifier: "subscription_report_fire",
        description: "SUBSCRIPTION_REPORT_FIRE_DESCRIPTION",
        active: false,
      },
      {
        id: 16,
        name: this.translate.instant("SUBSCRIPTION_REPORT_GRB"),
        identifier: "subscription_report_grb",
        description: "SUBSCRIPTION_REPORT_GRB_DESCRIPTION",
        active: false,
      },
      {
        id: 17,
        name: this.translate.instant("SUBSCRIPTION_REPORT_NOTES"),
        identifier: "subscription_report_notes",
        description: "SUBSCRIPTION_REPORT_NOTES_DESCRIPTION",
        active: false,
      },
      {
        id: 18,
        name: this.translate.instant("SUBSCRIPTION_REPORT_SDS"),
        identifier: "subscription_report_sds",
        description: "SUBSCRIPTION_REPORT_SDS_DESCRIPTION",
        active: false,
      },
      {
        id: 19,
        name: this.translate.instant("SUBSCRIPTION_REPORT_SIMPLE"),
        identifier: "subscription_report_simple",
        description: "SUBSCRIPTION_REPORT_SIMPLE_DESCRIPTION",
        active: false,
      },
      {
        id: 20,
        name: this.translate.instant("SUBSCRIPTION_REPORT_STATUTORY"),
        identifier: "subscription_report_statutory",
        description: "SUBSCRIPTION_REPORT_STATUTORY_DESCRIPTION",
        active: false,
      },
      {
        id: 21,
        name: this.translate.instant("SUBSCRIPTION_SDS"),
        identifier: "subscription_sds",
        description: this.translate.instant("SUBSCRIPTION_SDS_DESCRIPTION"),
        active: false,
      },
      {
        id: 22,
        name: this.translate.instant("SUBSCRIPTION_REPORT_ENVIROMENT"),
        identifier: "subscription_report_enviroment",
        description: this.translate.instant(
          "SUBSCRIPTION_REPORT_ENVIROMENT_DESCRIPTION"
        ),
        active: false,
      },
      {
        id: 23,
        name: this.translate.instant("SUBSCRIPTION_TESTER"),
        identifier: "subscription_tester",
        description: this.translate.instant("SUBSCRIPTION_TESTER_DESCRIPTION"),
        active: false,
      },
      {
        id: 24,
        name: this.translate.instant("SUBSCRIPTION_DISTRIBUTION"),
        identifier: "subscription_distribution",
        description: this.translate.instant(
          "SUBSCRIPTION_DISTRIBUTION_DESCRIPTION"
        ),
        active: false,
      },
      {
        id: 25,
        name: this.translate.instant("SUBSCRIPTION_CHECKLIST"),
        identifier: "subscription_checklist",
        description: this.translate.instant("SUBSCRIPTION_CHECKLIST"),
        active: false,
      },
      {
        id: 26,
        name: this.translate.instant("SUBSCRIPTION_CATALOGUE"),
        identifier: "subscription_catalogue",
        description: this.translate.instant("SUBSCRIPTION_CATALOGUE"),
        active: false,
      },
      {
        id: 27,
        name: this.translate.instant("SUBSCRIPTION_PRODUCT_ANALYSIS"),
        identifier: "subscription_product_analysis",
        description: this.translate.instant(
          "SUBSCRIPTION_PRODUCT_ANALYSIS_DESCRIPTION"
        ),
        active: false,
      },
      {
        id: 28,
        name: this.translate.instant("SUBSCRIPTION_REPORT_PURCHASE"),
        identifier: "subscription_report_purchase",
        description: this.translate.instant("SUBSCRIPTION_REPORT_PURCHASE"),
        active: false,
      },
      {
        id: 29,
        name: this.translate.instant("SUBSCRIPTION_REPORT_SDS_ACTIVITY"),
        identifier: "subscription_report_sds_activity",
        description: "SUBSCRIPTION_REPORT_SDS_ACTIVITY",
        active: false,
      },
      {
        id: 30,
        name: this.translate.instant("SUBSCRIPTION_TRANSPORT"),
        identifier: "subscription_transport",
        description: "SUBSCRIPTION_TRANSPORT_DESCRIPTION",
        active: false,
      },
      {
        id: 31,
        name: this.translate.instant("SUBSCRIPTION_SPECIAL_DEMANDS"),
        identifier: "subscription_special_demands",
        description: "SUBSCRIPTION_SPECIAL_DEMANDS_DESCRIPTION",
        active: false,
      },
      {
        id: 32,
        name: this.translate.instant("SUBSCRIPTION_REPORT_SLL"),
        identifier: "subscription_report_sll",
        description: "SUBSCRIPTION_REPORT_SLL_DESCRIPTION",
        active: false,
      },
      {
        id: 33,
        name: this.translate.instant("SUBSCRIPTION_LABELS"),
        identifier: "subscription_labels",
        description: "SUBSCRIPTION_LABELS_DESCRIPTION",
        active: false,
      },
      {
        id: 34,
        name: this.translate.instant("SUBSCRIPTION_FREEMIUM"),
        identifier: "subscription_freemium",
        description: "SUBSCRIPTION_FREEMIUM_DESCRIPTION",
        active: false,
      },
      {
        id: 35,
        name: this.translate.instant("SUBSCRIPTION_RISK_ASSESSMENT"),
        identifier: "subscription_risk_assessment",
        description: "SUBSCRIPTION_RISK_ASSESSMENT_DESCRIPTION",
        active: false,
      },
      {
        id: 36,
        name: this.translate.instant("SUBSCRIPTION_SUBSTITUTION"),
        identifier: "subscription_substitution",
        description: "SUBSCRIPTION_SUBSTITUTION_DESCRIPTION",
        active: false,
      },
      {
        id: 37,
        name: this.translate.instant("SUBSCRIPTION_Samtec"),
        identifier: "subscription_samtec",
        description: this.translate.instant("SUBSCRIPTION_Samtec_description"),
        active: false,
      },
      {
        id: 38,
        name: this.translate.instant("SUBSCRIPTION_REVISE_DATE"),
        identifier: "subscription_revise_date",
        description: this.translate.instant("SUBSCRIPTION_REVISE_DATE"),
        active: false,
      },
      {
        id: 39,
        name: this.translate.instant("SUBSCRIPTION_QRCODE"),
        identifier: "subscription_qrcode",
        description: this.translate.instant("SUBSCRIPTION_QRCODE_DESCRIPTION"),
        active: false,
      },
      {
        id: 40,
        name: this.translate.instant("SUBSCRIPTION_SECRET_CHEMICAL"),
        identifier: "subscription_secret_chemical",
        description: this.translate.instant(
          "SUBSCRIPTION_SECRET_CHEMICAL_DESCRIPTION"
        ),
        active: false,
      },
      {
        id: 41,
        name: this.translate.instant("SUBSCRIPTION_STAMPED_SDS"),
        identifier: "subscription_stamped_SDS",
        description: this.translate.instant(
          "SUBSCRIPTION_STAMPED_SDS_DESCRIPTION"
        ),
        active: false,
      },
      {
        id: 42,
        name: this.translate.instant("SUBSCRIPTION_SIN"),
        identifier: "subscription_sin",
        description: this.translate.instant("SUBSCRIPTION_LIST_SIN"),
        active: false,
      },
      {
        id: 43,
        name: this.translate.instant("SUBSCRIPTION_SLL"),
        identifier: "subscription_sll",
        description: this.translate.instant("SUBSCRIPTION_LIST_SLL"),
        active: false,
      },
      {
        id: 44,
        name: this.translate.instant("SUBSCRIPTION_BASTA"),
        identifier: "subscription_basta",
        description: this.translate.instant("SUBSCRIPTION_LIST_BASTA"),
        active: false,
      },
      {
        id: 45,
        name: this.translate.instant("SUBSCRIPTION_REPORT_ALLERGENIC"),
        identifier: "subscription_report_allergenic",
        description: this.translate.instant(
          "SUBSCRIPTION_REPORT_ALLERGENIC_DESCRIPTION"
        ),
        active: false,
      },
      {
        id: 46,
        name: this.translate.instant("SUBSCRIPTION_FOOTPRINT"),
        identifier: "subscription_footprint",
        description: this.translate.instant(
          "SUBSCRIPTION_FOOTPRINT_DESCRIPTION"
        ),
        active: false,
      },
      {
        id: 47,
        name: "cDoc Online Academy",
        identifier: "subscription_education",
        description: "cDoc Online Academy",
        active: false,
      },
      {
        id: 48,
        name: this.translate.instant("SUBSCRIPTION_APTOR"),
        identifier: "subscription_law_aptor",
        description: "SUBSCRIPTION_APTOR_DESCRIPTION",
        active: false,
      },
      {
        id: 49,
        name: this.translate.instant("SUBSCRIPTION_MAIL_SDS"),
        identifier: "subscription_mail_sds",
        description: "SUBSCRIPTION_MAIL_SDS_DESCRIPTION",
        active: false,
      },{
        id: 50,
        name: this.translate.instant("SUBSCRIPTION_STYREN"),
        identifier: "subscription_styren",
        description: "SUBSCRIPTION_STYREN_DESCRIPTION",
        active: false,
      },{
        id: 51,
        name: this.translate.instant("SUBSCRIPTION_PRODUCT_APPLICATION"),
        identifier: "subscription_product_application",
        description: "SUBSCRIPTION_PRODUCT_APPLICATION_DESCRIPTION",
        active: false,
      },{
        id: 52,
        name: this.translate.instant("SUBSCRIPTION_LIST_POP"),
        identifier: "subscription_list_pop",
        description: "SUBSCRIPTION_LIST_POP_DESCRIPTION",
        active: false,
      },{
        id: 53,
        name: this.translate.instant("SUBSCRIPTION_LIST_ROHS"),
        identifier: "subscription_list_rohs",
        description: "SUBSCRIPTION_LIST_ROHS_DESCRIPTION",
        active: false,
      }
    ];

      result.sort(function (a, b) {
          a = a.name;
          b = b.name;

          return a > b ? 1 : a === b ? 0 : -1;
      });

      return result;
  }

  /**
   * Search the available subscription objects for subscription identified by "identifier"
   *
   * @param identifier {string}   - Identifier for subscription
   *
   * @return {object}             - Subscription object if found, null if not found
   */
  getSubscriptionByIdentifier(identifier) {
      var i,
          result = null,
          availableSubscriptions = this.getAvailableSubscriptions();

      if (_.isString(identifier)) {
          for (i = 0; i < availableSubscriptions.length; i += 1) {
              if (availableSubscriptions[i].identifier === identifier) {
                  result = availableSubscriptions[i];
                  break;
              }
          }
      }

      return result;
  }

  /**
   * Available Distribution status
   */
  getAvailableDistributionStatus() {
      var result = [
          {id: 0, name: this.translate.instant("OK"), translation: "OK"},
          {id: 1, name: this.translate.instant("INVALID_ARTICLENUMBER"), translation: "INVALID_ARTICLENUMBER"},
          {id: 2, name: this.translate.instant("COULD_NOT_FETCH_PRODUCT"), translation: "COULD_NOT_FETCH_PRODUCT"},
          {id: 3, name: this.translate.instant("COULD_NOT_GET_SDS"), translation: "COULD_NOT_GET_SDS"},
          {id: 4, name: this.translate.instant("COULD_NOT_SEND_MESSAGE"), translation: "COULD_NOT_SEND_MESSAGE"},
          {id: 5, name: this.translate.instant("COULD_NOT_FILTER_ARTICLES"), transtation: "COULD_NOT_FILTER_ARTICLES"},
          {id: 6, name: this.translate.instant("EMAIL_BOUNCED"), translation: "EMAIL_BOUNCED"}
      ];

      result.sort(function (a, b) {
          var c = a.id;
          var d = b.id;

          return c > d ? 1 : c === d ? 0 : -1;
      });

      return result;
  }

  getStatusOptionsList() {
    return [
      {
          id: 0,
          name: this.translate.instant("UNKNOWN2"),
      },
      {
          id: 1,
          name: this.translate.instant("APPROVED2"),
      },
      {
          id: 2,
          name: this.translate.instant("ONGOING"),
      },
      {
          id: 3,
          name: this.translate.instant("NEW"),
      },
      ]
  }

  getOptionsAreaOfUse() {
    return [
      {
          id: 0,
          value: 3,
          name: this.translate.instant("UNKNOWN2"),
      },
      {
          id: 1,
          value: 0,
          name: this.translate.instant("USAGE_SELECT_1"),
      },
      {
          id: 2,
          value: 1,
          name: this.translate.instant("USAGE_SELECT_2"),
      },
      {
          id: 3,
          value: 2,
          name: this.translate.instant("USAGE_SELECT_3"),
      }
      ]
  }

  getOptionsProductChoice() {
    return [
      {
          id: 0,
          value: 5,
          name: this.translate.instant("UNKNOWN2"),
      },
      {
          id: 1,
          value: 1,
          name: this.translate.instant("CHOICE_SELECT_1"),
      },
      {
          id: 2,
          value: 3,
          name: this.translate.instant("CHOICE_SELECT_2"),
      },
      {
          id: 3,
          value: 4,
          name: this.translate.instant("CHOICE_SELECT_3"),
      },
      {
          id: 4,
          value: 6,
          name: this.translate.instant("CHOICE_SELECT_4"),
      }
      ]
  }

  getOptionsUsage() {
      return [
          {
              id: 0,
              value: 5,
              name: this.translate.instant("UNKNOWN2"),
          },
          {
              id: 1,
              value: 1,
              name: this.translate.instant("PROTUSAGE_SELECT_1"),
          },
          {
              id: 2,
              value: 3,
              name: this.translate.instant("PROTUSAGE_SELECT_2"),
          },
          {
              id: 3,
              value: 4,
              name: this.translate.instant("PROTUSAGE_SELECT_3"),
          },
          {
              id: 4,
              value: 6,
              name: this.translate.instant("PROTUSAGE_SELECT_4"),
          },
          {
              id: 5,
              value: 8,
              name: this.translate.instant("PROTUSAGE_SELECT_5"),
          }
      ]
  }

  getOptionsWaste () {
      return [
          {
              id: 0,
              value: 5,
              name: this.translate.instant("UNKNOWN2"),
          },
          {
              id: 1,
              value: 1,
              name: this.translate.instant("WASTE_SELECT_1"),
          },
          {
              id: 2,
              value: 3,
              name: this.translate.instant("WASTE_SELECT_2"),
          },
          {
              id: 3,
              value: 4,
              name: this.translate.instant("WASTE_SELECT_3"),
          },
          {
              id: 4,
              value: 6,
              name: this.translate.instant("WASTE_SELECT_4"),
          }
      ]
  }

  getOptionsStoring() {
    return [
      {
          id: 0,
          value: 6,
          name: this.translate.instant("UNKNOWN2"),
      },
      {
          id: 1,
          value: 1,
          name: this.translate.instant("STORAGE_SELECT_1"),
      },
      {
          id: 2,
          value: 3,
          name: this.translate.instant("STORAGE_SELECT_2"),
      },
      {
          id: 3,
          value: 4,
          name: this.translate.instant("STORAGE_SELECT_3"),
      },
      {
          id: 4,
          value: 6,
          name: this.translate.instant("STORAGE_SELECT_4"),
      }
      ]
  }
}
