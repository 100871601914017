import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-modal-edit-group',
  templateUrl: './modal-edit-group.component.html',
  styleUrls: ['./modal-edit-group.component.css']
})
export class ModalEditGroupComponent implements OnInit {

  @Input() groupName;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  groupNewName: any = "";

  constructor(public activeModal: NgbActiveModal, private authService: AuthService, private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.groupNewName = this.groupName;
  }

  ngOnDestroy(): void {
  }

  save() {
    if (this.groupNewName.length > 0) {
      this.passEntry.emit(this.groupNewName);
    }

    this.activeModal.close(ModalEditGroupComponent);
  }

  remove() {
    this.activeModal.close(ModalEditGroupComponent);
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalEditGroupComponent);
  }

  logout() {
    this.authService.logout();
  }
}
