import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { Md5 }  from 'ts-md5/dist/md5';
import { take } from 'rxjs/operators';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class UserService {

  getCurrentUserDataUpdated = new Subject<any>();
  getCurrentSingleUserDataUpdated = new Subject<any>();
  setLockedUserDataUpdated = new Subject<any>();
  getCurrentAllUsersUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService) {}

  getCurrentUser(force) {
    let currentUser = null;
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    if (force === true) {
      localStorage.removeItem('currentUser');
    }

    currentUser = localStorage.getItem('currentUser', );
    if (currentUser)
    {
      // This doesnt work
      this.getCurrentUserDataUpdated.next(currentUser);

    } else {
      this.http.get<any>(BackendUrl + "/login/", { headers: headers})
        .subscribe({next: currentUser => {
          this.http.get<any>(BackendUrl + "/users/" + currentUser.user_id, { headers: headers})
            .pipe(take(1)).subscribe({next: currentUser => {
              currentUser = this.utilService.deserializeAndTransformToSingle(currentUser);
              this.getCurrentUserDataUpdated.next(currentUser);
            },
            error: error => {
              this.getCurrentUserDataUpdated.next(null);
            }
          });
        },
        error: error => {
          this.getCurrentUserDataUpdated.next(null);
        }
      });
    }
  }

  getCurrentUser2() {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get<any>(BackendUrl + "/login/", { headers: headers})
        .pipe(take(1)).subscribe({next: currentUser => {
          this.http.get<any>(BackendUrl + "/users/" + currentUser.user_id, { headers: headers})
            .pipe(take(1)).subscribe({next: currentUser => {
              currentUser = this.utilService.deserializeAndTransformToSingle(currentUser);
              this.getCurrentUserDataUpdated.next(currentUser);
            },
            error: error => {
              this.getCurrentUserDataUpdated.next(null);
            }
          });
        },
        error: error => {
          this.getCurrentUserDataUpdated.next(null);
        }
      });
  }

  deleteUserWithId(userId) {

    this.http.delete(BackendUrl + '/users/' + userId, this.utilService.setHeadersAndParamsObject())
    .pipe(take(1)).subscribe({
      next: (values) => {
        this.getCurrentUserDataUpdated.next(values);
      },
      error: (error) => {
        this.getCurrentUserDataUpdated.next(null);
      },
    })
  }

  getSingleUser(userId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get<any>(BackendUrl + "/users/" + userId, { headers: headers})
      .pipe(take(1)).subscribe({next: User => {
        this.getCurrentSingleUserDataUpdated.next(User[0]);
      },
      error: error => {
        this.getCurrentSingleUserDataUpdated.next(null);
      }
    });
  }

  updateUser(userId, userInfo) {
    if(userInfo.password != undefined)
    {
        this.createPassword(userInfo);
    }

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.put<any>(BackendUrl + "/users/" + userId, userInfo, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {
        this.getCurrentUserDataUpdated.next(response);
      },
      error: error => {
      this.getCurrentUserDataUpdated.next(null);
      }
    })
  }

  createPassword(user){
    var salt = Math.random().toString(36).substring(7);
    var saltedPw = salt + "" + user.password;
    user.salt = salt;

    user.password = Md5.hashStr(saltedPw)
  }

  getAllUsers(orgId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    var params = new HttpParams().set("organisationId", orgId);

    this.http.get<any>(BackendUrl + "/users", {headers: headers, params: params, responseType: "json"})
      .pipe(take(1)).subscribe({next: response => {

        if (response.length == 0)
        {
          this.getCurrentAllUsersUpdated.next(null);
        }
        else
        {
          var users = response;
          this.getCurrentAllUsersUpdated.next(users);
        }
      },
      error: error => {
        this.getCurrentAllUsersUpdated.next(null);
      }
    });
  }

  createStaticToken() {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/users/token", token, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getCurrentUserDataUpdated.next(response);
      },
      error: error => {
        this.getCurrentUserDataUpdated.next(null);
      }
    });
  }

  CreateUser(userInfo) {
    this.createPassword(userInfo);

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/users", userInfo, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getCurrentUserDataUpdated.next(response);
      },
      error: error => {
        this.getCurrentUserDataUpdated.next(null);
      }
    });
  }

  CheckIfUserExists(username) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    var params = new HttpParams().set("username", username);
    this.http.get<any>(BackendUrl + "/users/checkifusernameexists", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        if (response.data == false)
        {
          this.getCurrentUserDataUpdated.next(false);
        }
        else
        {
          this.getCurrentUserDataUpdated.next(true);
        }
      },
      error: error => {
        this.getCurrentUserDataUpdated.next(true);
      }
    });
  }

  getCdocUsers() {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    var params = new HttpParams().set("orgid",JSON.parse(localStorage.getItem('currentUser')).root_organisation_id);
    this.http.get<any>(BackendUrl + "/users/getcdocusers", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: responce => {
        if (responce == null) {
          this.getCurrentUserDataUpdated.next(null);
        }
        else {
          this.getCurrentUserDataUpdated.next(responce);
        }
      },
      error: error => {
        this.getCurrentUserDataUpdated.next(true);
      }
    });
  }

  getProductListSettings(info) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    var params = new HttpParams().set("info", info);
    this.http.get<any>(BackendUrl + "/users/getproductlistsettings", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        if (response == null)
        {
          this.getCurrentUserDataUpdated.next(response);
        }
        else
        {
          this.getCurrentUserDataUpdated.next(response);
        }
      },
      error: error => {
        this.getCurrentUserDataUpdated.next(null);
      }
    });
  }

  saveProductListSettings(info) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    var params = new HttpParams().set("info", info);
    this.http.get<any>(BackendUrl + "/users/saveproductlistsettings", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        if (response.data == false)
        {
          this.getCurrentUserDataUpdated.next(false);
        }
        else
        {
          this.getCurrentUserDataUpdated.next(true);
        }
      },
      error: error => {
        this.getCurrentUserDataUpdated.next(true);
      }
    });
  }

  setLockedStatus(info) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    var params = new HttpParams().set("info", info);
    this.http.get<any>(BackendUrl + "/users/setlockedstatus", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        if (response.data == false)
        {
          this.setLockedUserDataUpdated.next(false);
        }
        else
        {
          this.setLockedUserDataUpdated.next(true);
        }
      },
      error: error => {
        this.setLockedUserDataUpdated.next(true);
      }
    });
  }

  public getCurrentUserDataListener() {
    return this.getCurrentUserDataUpdated.asObservable();
  }

  public getCurrentSingleUserDataListener() {
    return this.getCurrentSingleUserDataUpdated.asObservable();
  }

  public getCurrentAllUsersListener() {
    return this.getCurrentAllUsersUpdated.asObservable();
  }

  public setLockedUserDataListener() {
    return this.setLockedUserDataUpdated.asObservable();
  }
}
