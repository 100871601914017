import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationService } from '../services/organisation.service';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ModalSelectOrganisationComponent } from 'src/app/modals/modal-select-organisation/modal-select-organisation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: "app-controlpanel",
  templateUrl: "./controlpanel.component.html",
  styleUrls: ["./controlpanel.component.css"],
})
export class ControlpanelComponent implements OnInit {
  selectedOrganisation: any;
  setTestId;
  testing = !environment.production;
  organisationList: any;
  currentUser: any;

  constructor(
    private utilService: UtilService,
    private router: Router,
    private translate: TranslateService,
    private organisationService: OrganisationService,
    private modalService: NgbModal,
    private authService:AuthService,
  ) {}

  ngOnInit(): void {
    if(localStorage.getItem('sareqeditlink', )=='sareq'){
      localStorage.removeItem('sareqeditlink');
      this.router.navigate(["/sareq"]);
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }
  }

  gotoSareq() {
    this.router.navigate(["/sareq"]);
  }

  gotoSubstances() {
    this.router.navigate(["/substances"]);
  }

  gotoManufacturers() {
    this.router.navigate(["/manufacturer"]);
  }

  gotoRestriction() {
    this.router.navigate(["/restriction"]);
  }

  clearOrg() {
    this.selectedOrganisation = null;
    localStorage.removeItem("selectedOrganisation");
    localStorage.removeItem("selectedDepartment");
  }

  gotoTransportinfo(){
    this.router.navigate(['/transportinfo']);
  }

  gotoSubstitutinfo(){
    this.router.navigate(['/substitutionproducts']);
  }

  gotoCoordinator(){
    this.router.navigate(['/controlpanelcoordinator']);
  }

  gotoKebnekaise(){
    this.router.navigate(['/kebnekaise']);
  }

  gotoFreemium(){
    this.router.navigate(['/freemium']);
  }

  gotoCustomerActions(){
    this.SetupUserOrganisations_CustomerActions();
  }

  SetupUserOrganisations_CustomerActions() {

    if (this.currentUser && this.currentUser.is_super_user) {
      this.organisationService.getAllCompanies();
      this.organisationService.getOrganisationNavDataListener().pipe(take(1)).subscribe({
        next: organisations => {
          if (organisations != null)
          {
            this.organisationList = organisations;
            const modalRef = this.modalService.open(ModalSelectOrganisationComponent);
            localStorage.setItem('oldDepartment',localStorage.getItem("selectedOrganisation"));
            modalRef.componentInstance.orgList = this.organisationList;
            modalRef.componentInstance.path = "controlpanel";
            modalRef.componentInstance.my_modal_content = 'I am your content';
            modalRef.result.then((result) => {
              if(typeof(result)=="string"&&result=="Close click"){
                localStorage.removeItem("newItem");
                // Do nothing
              }
              else if(localStorage.getItem("newItem")=="new"){
                localStorage.removeItem("newItem");
                this.router.navigate(["/customeractions", "new"]);
              }else{
                localStorage.removeItem("newItem");
                if(localStorage.getItem("selectedOrganisation")!=localStorage.getItem("oldDepartment")){
                  this.organisationService.changeDepartmentById(JSON.parse(
                    localStorage.getItem("rootOrganisation")).id);
                  this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                    this.utilService.departmentChangeClicked(this.router.url);
                    this.router.navigate(["/customeractions"]).then(() => {
                      window.location.reload();
                    });
                  })
                }else{
                  this.router.navigate(["/customeractions"]).then(() => {
                    window.location.reload();
                  });
                }
              }
            })
          }
          else {
            this.clearOrg()
          }
        },
        error: error => {
          this.clearOrg()
        }
      })
    }
  }

  gotoOrganisation() {
    this.router.navigate(['/organisation']);
  }

  logout() {
    this.authService.logout();
  }
  gotoNewFreemiumRegistration(){
    this.router.navigate(['/new_freemium']);
  }

  gotoNews(){
    this.router.navigate(['/news']);
  }

  gotoAllProductApplications(){
    this.router.navigate( [ '/productapplicationall' ] );
  }


}


