<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div [hidden]="loadingData" class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
    <div class="flex justify-between">
        <div class="flex mt-4">
            <p class="text-lg font-bold mt-4">{{"COORDINATOR" | translate}}: {{coordinatorSubjectList?.length}}</p>
        </div>
        <button (click)="addCoordinator()" class="button-cdocblue mt-4">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white text-lg">{{"ADD" | translate}}</span>
        </button>
    </div>
</div>

<div class="overflow-auto">
    <div *ngIf="loadingData==false" class="mt-4 px-4 py-2 mb-20">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="exposuretable">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="coordinatorSearch.all" id="searchProductList1" class="prompt" 
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                            <div class="flex mt-6 md:ml-4 mr-20">
                                <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-2" type="checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                                <span class="text-sm mr-4">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                            </div>
                            <div class="flex mt-6 mr-20 cursor-pointer" (click)="toggleStatus(-1)">
                                <label for="toggle" class="mr-2 text-sm font-medium text-gray-700"></label>
                                <div class="relative">
                                <input title="{{'ALL' | translate}}" type="checkbox" id="toggle" [(ngModel)]="all" class="sr-only">
                                <div class="block bg-gray-600 w-14 h-6 rounded"></div>
                                <div
                                    class="dot absolute left-3 top-1 bg-white w-4 h-4 rounded transition"
                                    [ngClass]="{ 'transform translate-x-full bg-green-400': all }"
                                ></div>
                                </div>
                                <span class="ml-2 text-sm">{{ 'SHOW' | translate }} {{ all ? ('SELECTION' | translate) : ('ALL' | translate) }}</span>
                            </div>
                            <div *ngIf="all" class="flex flex-col mt-6">
                                <div class="flex items-center space-x-2 mb-2">
                                    <input title="{{'SHOW_NOT_STARTED' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-2" type="checkbox" name="example" [ngModel]="showNotStarted" (click)="toggleStatus(0)">
                                    <span class="text-sm mr-4">{{"SHOW_NOT_STARTED" | translate}}</span>
                                </div>
                                <div class="flex items-center space-x-2 mb-2">
                                    <input title="{{'SHOW_ONGOING' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-2" type="checkbox" name="example" [ngModel]="showOngoing" (click)="toggleStatus(1)">
                                    <span class="text-sm mr-4">{{"SHOW_ONGOING" | translate}}</span>
                                </div>
                                <div class="flex items-center space-x-2 mb-2">
                                    <input title="{{'SHOW_DONE' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-2" type="checkbox" name="example" [ngModel]="showDone" (click)="toggleStatus(2)">
                                    <span class="text-sm mr-4">{{"SHOW_DONE" | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr *ngIf="coordinatorSubjectList.length > 0">
                    <th class="table-row-header" (click)="sortItem('name',0)">{{"HEADING" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('department',1)">{{"DEPARTMENT" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('prio',2)">{{"PRIORITY" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('status',3)">{{"STATUS" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('due',4)">{{"DUE_DATE" | translate}}</th>
                    <th class="table-row-header" (click)="sortItem('responsible',5)" >{{"RESPONSIBLE" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="hover:bg-gray-200" *ngFor="let subject of coordinatorSubjectList
                    | filter : coordinatorSearch.all
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" (click)="coordinatorSelected(subject)">
                    <td class="table-row-column">{{subject.name}}</td>
                    <td class="table-row-column">{{subject.department}}</td>
                    <td class="table-row-column">{{subject.prio}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==0">{{availableCoordinatorSubjectStatus[0].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==1">{{availableCoordinatorSubjectStatus[1].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==2">{{availableCoordinatorSubjectStatus[2].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==3">{{availableCoordinatorSubjectStatus[3].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.status==4">{{availableCoordinatorSubjectStatus[4].name}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.due===''">{{''}}</td>
                    <td class="table-row-column" *ngIf="availableCoordinatorSubjectStatus&&subject.due!==''">{{subject.due | date:'yyyy-MM-dd'}}</td>
                    <td class="table-row-column">{{subject.responsible}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr *ngIf="coordinatorSubjectList.length > 0">
                    <td class="p-6" colspan="2">
                        <div class="relative inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

