<div *ngIf="isAuthenticated" class="flex min-h-screen">
  <div *ngIf="!toggleNav" class="hidden md:block fixed top-0 bottom-0 w-80">
    <app-navigation></app-navigation>
  </div>
  <div [ngClass]="{'bg-cdocbackground border border-gray-300 flex-grow': true, 'sm:ml-80 max-w-[100vw] sm:max-w-[79vw] 2xl:max-w-[87vw]': !toggleNav, 'max-w-[100vw]': toggleNav}">
    <div>
      <app-top-header></app-top-header>
      <div class="hidden md:block">
        <div class="flex justify-start cursor-pointer" (click)="toggleNavigation()">
          <span *ngIf="!toggleNav" class="-ml-3 -mt-8 z-20" title="{{ 'MAXIMIZE_WINDOW' | translate }}">
            <svg class="h-6 w-6 bg-cdoclightblue" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5" d="M12.9999 21.9994C17.055 21.9921 19.1784 21.8926 20.5354 20.5355C21.9999 19.0711 21.9999 16.714 21.9999 12C21.9999 7.28595 21.9999 4.92893 20.5354 3.46447C19.071 2 16.714 2 11.9999 2C7.28587 2 4.92884 2 3.46438 3.46447C2.10734 4.8215 2.00779 6.94493 2.00049 11" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M17 7L12 12M12 12H15.75M12 12V8.25" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 18C2 16.1144 2 15.1716 2.58579 14.5858C3.17157 14 4.11438 14 6 14C7.88562 14 8.82843 14 9.41421 14.5858C10 15.1716 10 16.1144 10 18C10 19.8856 10 20.8284 9.41421 21.4142C8.82843 22 7.88562 22 6 22C4.11438 22 3.17157 22 2.58579 21.4142C2 20.8284 2 19.8856 2 18Z" stroke="#ffffff" stroke-width="1.5"/>
            </svg>
          </span>
          <span *ngIf="toggleNav" class="-ml-1 -mt-8 z-20 mr-2" title="{{ 'MINIMIZE_WINDOW' | translate }}">
            <svg class="h-6 w-6 bg-cdoclightblue" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9999 2C16.714 2 19.071 2 20.5354 3.46447C21.9999 4.92893 21.9999 7.28595 21.9999 12C21.9999 16.714 21.9999 19.0711 20.5354 20.5355C19.1784 21.8926 17.055 21.9921 12.9999 21.9994M2.00049 11C2.00779 6.94493 2.10734 4.8215 3.46438 3.46447C4.43813 2.49071 5.8065 2.16443 8 2.0551" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M12 12L17 7M17 7H13.25M17 7V10.75" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 18C2 16.1144 2 15.1716 2.58579 14.5858C3.17157 14 4.11438 14 6 14C7.88562 14 8.82843 14 9.41421 14.5858C10 15.1716 10 16.1144 10 18C10 19.8856 10 20.8284 9.41421 21.4142C8.82843 22 7.88562 22 6 22C4.11438 22 3.17157 22 2.58579 21.4142C2 20.8284 2 19.8856 2 18Z" stroke="#ffffff" stroke-width="1.5"/>
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div class="px-5 py-2 border-b border-cdoclightblue">
      <app-header></app-header>
    </div>
    <router-outlet></router-outlet>
  </div>
  <footer class="bg-cdoclightblue text-black w-full text-center fixed bottom-0 z-50">
    <app-footer></app-footer>
  </footer>
</div>

<div *ngIf="!isAuthenticated">
  <router-outlet></router-outlet>
</div>
