<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-30" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="remove()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3">
            <!-- Modal Body -->
            <div class="flex flex-col p-2">
              <div class="flex flex-col w-full mt-2">
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"EMAIL" | translate}}:</label><br>
                    <input title="{{'EMAIL' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="emailrecipent" (ngModelChange)="emailrecipent=$event" type="text">
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2 mt-2 mr-4">
                        <input title="{{'SEND_ME_A_COPY' | translate}}" type="checkbox" (click)="toggleSelf()">
                        <span class="text-sm mr-1">{{"SEND_ME_A_COPY" | translate}}</span>
                    </div>
                </div>
                <div class="justify-center items-center">
                    <button (click)="save()" class="button-cdocblue" id="modalSendMailButton2">
                        <svg class="w-6 h-6" width="800px" height="800px" viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-568.000000, -254.000000)" fill="#ffffff">
                                    <path d="M584,283 C584,283 580.872,276.976 580,275 L596.075,259.779 L584,283 L584,283 Z M572,270 L595,259 L579,274 C578.996,273.996 572,270 572,270 L572,270 Z M599,255 C597.844,255.563 568,270 568,270 C568,270 578.052,276.059 578,276 C577.983,275.981 584,287 584,287 C584,287 599.75,256.5 600,256 C600.219,255.375 599.75,254.688 599,255 L599,255 Z" id="send-email" sketch:type="MSShapeGroup">
                                    </path>
                                </g>
                            </g>
                          </svg>
                        {{"SEND" | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

