import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesService {
  private unsavedChanges:boolean = false;

  setUnsavedChanges(status: boolean) {
    this.unsavedChanges = status;
  }

  getUnsavedChanges() {
    if (this.unsavedChanges == true) {
      return true;
    } else {
      return false;
    }
  }
}
