import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class HistoryService {

  getHistoryDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService, private authService: AuthService,
    private productConversionsService: ProductConversionsService) { }

  getReport(info) {

    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;    
    if (orgId == null)
    {
      this.authService.logout();
    }

    let token = localStorage.getItem('token');

    let params = new HttpParams();
    params = info;

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    
    this.http.get<any>(BackendUrl + '/organisations/' + orgId + '/historyreport/' + info.reportType, {headers: headers, params: params}).pipe(take(1)).subscribe({next: response => {

        this.productConversionsService.convertPictogramsOnProductArray(response);
        this.productConversionsService.convertFootprintOnProductArray(response);
        this.productConversionsService.setProductArrayRiskLists(response);
        this.productConversionsService.convertClassificationOnProductArray(response);
        this.getHistoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getHistoryDataUpdated.next(null);
      }
    });
  }

  Article(info) {

    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;    
    if (orgId == null)
    {
      this.authService.logout();
    }

    let token = localStorage.getItem('token');

    let params = new HttpParams();
    params = info;

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    
    this.http.get<any>(BackendUrl + '/organisations/' + orgId + '/products/subMoreInformationHistory', {headers: headers, params: params}).pipe(take(1)).subscribe({next: response => {

        this.getHistoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getHistoryDataUpdated.next(null);
      }
    });
  }

  public getHistoryDataListener() {
    return this.getHistoryDataUpdated.asObservable();
  } 
}
