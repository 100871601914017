import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const loginReroute = environment.loginReroute;

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuth = this.authService.getIsAuth();
      if( !isAuth ){
        if (window.location.search.indexOf("#") == -1) {
          if (window.location.search.indexOf("access_token") > 0) {
            window.location.href = loginReroute + window.location.search;
            return isAuth;
          }
        }

        this.router.navigate(['/login']);
      }
      return isAuth;
  }
}
