// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //DEV
  BackendUrl: "https://api-dev.cdoc.se/rest",
  loginReroute: "http://demo.cdoc.se/#/login"
};


