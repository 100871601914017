import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SareqService } from 'src/app/services/sareq.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { SubstitutionService } from 'src/app/services/substitution.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;
import * as _ from "lodash";

@Component({
  selector: 'app-substitution-products',
  templateUrl: './substitution-products.component.html',
  styleUrls: ['./substitution-products.component.css']
})
export class SubstitutionProductsComponent {
  loadingData: boolean;
  currentUser: any;
  displayedProducts: any = [];
  itemsPerPage: number = 5;
  pageNumber:number=1;
  sortDirection="asc";
  products: any;
  sub_products: any;
  order=['asc','asc','asc','asc'];
  addItems: boolean = false;
  loadingSareq: boolean = false;
  substituteProducts: any = [];
  substituteProductsIds: any = [];
  sareqProducts: any = [];
  itemsPerPageSareq: number = 5;
  pageNumberSareq = 1;
  editList: boolean = false;
  loadingButton: boolean = false;
  productEditIndex: any;

  sareqSearch = {
    name:"",
    manufacturer:"",
    articlenumber:"",
  };
  
  productSearch = {
    all:"",
    name: "",
    supplier_name: "",
  };

  constructor(
    private router: Router,
    private previousrouter: ActivatedRoute,
    private sareqService: SareqService,
    private organisationService: OrganisationService,
    private productService: ProductService,
    private translate: TranslateService,
    private utilService: UtilService,
    private authService: AuthService,
    private substitutionService: SubstitutionService
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.getAll()
  }

  gotoControlPanel() {
    this.router.navigate(["/controlpanel"]);
  }

  addProductToSubstituteList( index, sup_product ) {
    if (this.products[index] && this.products[index].sup_products) {
      this.products[index].sup_products.push({
        id: sup_product.id,
        name: sup_product.name,
        supplier_name: sup_product.supplier_name
      });
    } else if (this.products[index]) {
      // If sup_products array doesn't exist, create it and then push the new object
      this.products[index].sup_products = [{
        id: sup_product.id,
        name: sup_product.name,
        supplier_name: sup_product.supplier_name
      }];
    }
    this.editList = false;
  }

  saveSubstitutions() {
    let timeConstant = 0;
    for (let i = 0; i < this.products.length; i++)
    {
      timeConstant += 300;
      this.substituteProductsIds = [];
      for (let j = 0; j < this.products[i].sup_products.length; j++)
      {
        this.substituteProductsIds.push(this.products[i].sup_products[j]);
      }

      this.saveSubstitution(this.products[i].sareq_id, this.substituteProductsIds)
    }

    setTimeout( () => {
      this.ngOnInit();
    }, timeConstant );
  }

  removeSubstituteSubProduct(product_index, sup_product) {
    const index = this.products[product_index].sup_products.findIndex(p => p.id === sup_product.id);
    if (index !== -1) {
      this.products[product_index].sup_products.splice(index, 1);
    }
    this.editList = false;
  }

  removeSubstituteProduct(sareqId) {
    this.substitutionService.deleteSubstitutionListItem(sareqId);
    this.substitutionService.getSubstitutionListListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire("Error", "Error");
        }
        else {
          this.ngOnInit();
        }
      },
      error: error => {
        Swal.fire("Error", "Error");
      }
    })
  }

  saveSubstitution(sareqId, substituteProductsIds) {
    this.substitutionService.addToSubstitutionList(sareqId, substituteProductsIds);
    this.substitutionService.getSubstitutionListListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire("Error", "Error");
        }
        else {
          Swal.fire("Ok", "Success");
        }
      },
      error: error => {
        Swal.fire("Error", "Error");
      }
    })
  } 

  filterSearchItems(){
    if(this.productSearch.all==''){
      this.productSearch.all=null;
    }

    if(this.productSearch.name==''){
      this.productSearch.name=null;
    }

    if(this.productSearch.supplier_name==''){
      this.productSearch.supplier_name=null;
    }
    
    if(this.productSearch.all!=null){
      this.products=this.products.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.productSearch.all.toLowerCase())>-1);
    }

    if(this.productSearch.name!=null){
      this.products=this.products.filter(o => o.name?.toLowerCase().indexOf(this.productSearch.name.toLowerCase())>-1);
    }

    if(this.productSearch.supplier_name!=null){
      this.products=this.products.filter(o => o.supplier_name?.toLowerCase().indexOf(this.productSearch.supplier_name.toLowerCase())>-1);
    }
  }

  sortItem(type,id){
    if(this.order[id]=='asc'){
      this.order[id]='desc';
    } else if(this.order[id]=='desc'){
      this.order[id]='asc';
    }

    this.products = _.orderBy(
      this.products,
      [(item) => typeof item[type] === "string" ? item[type].toLowerCase() : item[type]],
      [this.order[id]]
    );
  }

  trackById(index:number, el:any) :number{
    return el;
  }

  addSubstituteItem() {
    this.addItems = !this.addItems;
  }

  getAll() {
    this.substitutionService.getSubstitutionList();
    this.substitutionService.getSubstitutionListListener().pipe(take(1)).subscribe({
      next: products => {
        if (products == null) {
          this.loadingData = false;
        }
        else {
          this.products = products.data;
          this.loadingData = false;
        }
      },
      error: error => {
        this.loadingData = false;
      }
    })
  }

  editSubList(productEditIndex) {
    this.editList = !this.editList;
    this.productEditIndex = productEditIndex;
  }

  addProduct( product ) {
    this.products.push({
      sareq_id: product.id,
      name: product.name,
      supplier_name: product.supplier_name
    });
    this.addItems = false;
  }
}


