import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-modal-add-department',
  templateUrl: './modal-add-department.component.html',
  styleUrls: ['./modal-add-department.component.css']
})
export class ModalAddDepartmentComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  departmentName: any;
  departmentMisc: any;
  departmentAddress: any;

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder, private authService: AuthService) { }

  ngOnInit(): void {
    this.departmentName = "";
    this.departmentMisc = "";
    this.departmentAddress = "";
  }

  ngOnDestroy(): void {
  }

  save() {
  
    let object = {
      'name':'',
      'misc':'',
      'address_visit':''
    };

    if (this.departmentName != "")
    {
      object.name = this.departmentName;
    }
    else
    {
      return;
    }

    if (this.departmentMisc != "")
    {
      object.misc = this.departmentMisc;
    }

    if (this.departmentMisc != "")
    {
      object.misc = this.departmentMisc;
    }

    if (this.departmentMisc != "")
    {
      object.address_visit = this.departmentAddress;
    }
    
    this.passEntry.emit(object);

    this.activeModal.close(ModalAddDepartmentComponent);
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalAddDepartmentComponent);
  }

  logout() {
    this.authService.logout();
  }
}
