<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
  <!-- Modal Content -->
  <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white">
      <div class="flex justify-end">
          <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
      </div>
      <div class="mt-3">
          <div class="p-2 text-lg text-gray-900 font-bold">{{productName}}</div>
          <!-- Modal Body -->
          <div class="flex flex-col mt-4 p-2">
            <div class="flex flex-col mb-4">
              <span class="font-bold mb-4" *ngIf="approvedProduct[0]?.name&&approvedProduct[0]?.name!='noproduct'">{{"MODEL_SUBSTITUTE_TEXT_1" | translate}} <b>{{approvedProduct[0]?.name}}</b></span>
              <span class="font-bold mb-4" *ngIf="!approvedProduct[0]?.name||approvedProduct[0]?.name=='noproduct'">{{"MODEL_SUBSTITUTE_TEXT_2" | translate}}!</span>
              <hr>
            </div>
            <div class="flex flex-col w-full mt-2">
              <label>{{"MODEL_SUBSTITUTE_TEXT_3" | translate}}:</label>
              <div class="mb-2 mt-4">
                  <div class="flex items-center space-x-2 mr-4">
                      <input title="{{'ALL_DEPARTMENTS' | translate}}" type="checkbox" name="departmentForSubstitution" (click)="activateordeactivateitem(-1)" (checked)="substitutionDepsItemAll==true" id="modalSelectSubstitutionButton1">
                      <span class="text-sm mr-1">{{"ALL_DEPARTMENTS" | translate}}:</span>
                  </div>
              </div>
              <div class="mb-4">
                <div class="flex flex-col" *ngFor="let deps of variousDepartments; let i = index">
                  <div class="flex items-center space-x-2 mb-2 mr-4">
                    <input title="{{deps.name}}" type="checkbox" [disabled]="selectedDepartment==deps.organisation_id"
                      name="deps.organisation_id"
                      (click)="activateordeactivateitem(i)"
                      [checked]="deps.active==true">
                    <div class="flex flex-row">
                      <span class="text-sm mr-1">{{deps.name}}</span> 
                      <span *ngIf="selectedDepartment==deps.organisation_id">&nbsp;{{'CURRENT_DEPARTMENT' | translate}}</span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- Modal Footer -->
          <div class="items-center mt-10">
              <button type="button" 
                  class="px-4 py-2 bg-cdoclightblue text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300" 
                  (click)="submit()">
                  {{"SAVE" | translate}}
              </button>
          </div>
      </div>
  </div>
</div>
