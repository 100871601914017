import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StaticDataService } from 'src/app/services/static-data.service';
import { ProductService } from 'src/app/services/product.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-modal-print-cargodeclaration',
  templateUrl: './modal-print-cargodeclaration.component.html',
  styleUrls: ['./modal-print-cargodeclaration.component.css']
})
export class ModalPrintCargodeclarationComponent implements OnInit {

  selectedDepartment: any;
  shipperRow1: any = "";
  shipperRow2: any = "";
  shipperRow3: any = "";
  consigneeRow1: any = "";
  consigneeRow2: any = "";
  consigneeRow3: any = "";
  units: any = "";
  modalTitle: any = "";
  product: any;
  typeOfPackages: any;
  volume: any = 0;
  numberOfPackages: any = 0;
  netWeight: any = 0;
  environment: any = "n";
  hphrasesToSearchFor=["h400","h410","h411"];
  overpack:boolean=false;

  @Input() thisProduct;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder:FormBuilder,
              private staticDataService: StaticDataService,
              private productService: ProductService,
              private authService: AuthService,
              private translate: TranslateService) { }

  ngOnInit(): void {

    this.product = this.thisProduct;


    if (this.product) {
      for(var n=0;n<this.product.phrases.H.length;n++){
        if(this.hphrasesToSearchFor.indexOf(this.product.phrases.H[n].toLowerCase())>-1){
          this.environment='y';
          break;
        }
      }

      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      this.shipperRow1 = this.selectedDepartment.name!=null?this.selectedDepartment.name:'';
      this.shipperRow2 = this.selectedDepartment.address_1!=null?this.selectedDepartment.address_1:'';
      this.shipperRow3 = this.selectedDepartment.zip!=null?this.selectedDepartment.zip:'' + ' ' + this.selectedDepartment!=null?this.selectedDepartment.city:'';
      this.typeOfPackages = this.translate.instant("CARGOTYPE3");
      this.units = this.staticDataService.getUnitDefinitions();
      this.modalTitle = this.product.product_name!=null?this.product.product_name:''
    }
  }

  ngOnDestroy(): void {
  }

  save() {

    this.productService.createCargoDeclaration(this.product.id, this.numberOfPackages, this.typeOfPackages, this.netWeight, this.volume,
      this.shipperRow1, this.shipperRow2, this.shipperRow3, this.consigneeRow1, this.consigneeRow2, this.consigneeRow3, this.environment, this.overpack);
    this.productService.getProductCargoDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent != null) {
          this.activeModal.close(ModalPrintCargodeclarationComponent);
          var fileURL = URL.createObjectURL(pdfContent);
          var opened=window.open(fileURL,"_blank");
          if(!opened){
            window.location.href=fileURL;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
        Swal.fire(this.translate.instant("COULD_NOT_GET_TRANSPORT_INFO"), this.translate.instant("COULD_NOT_GET_TRANSPORT_INFO"), "error");
      }
    });
  }

  overpackCheckbox(event) {
    this.overpack = false;
    if (event.target.checked == true)
    {
      this.overpack=event.target.checked
    }
  }

  selectCargoType(index){
    this.typeOfPackages = this.translate.instant("CARGOTYPE"+index);

  }

  remove() {
    this.activeModal.close(ModalPrintCargodeclarationComponent);
  }

  close() {
    this.activeModal.close(ModalPrintCargodeclarationComponent);
  }

  logout() {
    this.authService.logout();
  }
}
