<div class="flex flex-col md:flex-row mb-40 md:mb-20">
  <div class="flex flex-col bg-white w-full md:w-1/2 rounded overflow-hidden shadow-lg m-4 mb-2 p-7">
    <p class="font-xl font-bold mb-4 text-center">{{"ENVIRONMENTAL_LAWYER_HEADER" | translate}}</p>
    <div class="flex flex-col md:flex-row mb-6">
      <p class="text-sm w-full md:w-3/4">
        {{"ENVIRONMENTAL_LAWYER_PHRASE1" | translate}}
        {{"ENVIRONMENTAL_LAWYER_PHRASE2" | translate}}
      </p>
      <div class="w-full md:w-1/4 mt-4 md:mt-0">
        <img src="/images/loggos/Daniel_Drott.jpg" />
      </div>
    </div>

    <ul class="list-disc list-inside mb-6">
      <li class="text-sm">{{"ENVIRONMENTAL_LAWYER_POINT1" | translate}}</li>
      <li class="text-sm">{{"ENVIRONMENTAL_LAWYER_POINT2" | translate}}</li>
      <li class="text-sm">{{"ENVIRONMENTAL_LAWYER_POINT3" | translate}}</li>
      <li class="text-sm">{{"ENVIRONMENTAL_LAWYER_POINT4" | translate}}</li>
    </ul>

    <p class="text-sm mb-4">{{"ENVIRONMENTAL_LAWYER_PHRASE3" | translate}}</p>
    <a href="https://www.rosholmdell.se/" target="_blank" rel="noreferrer noopener"><small><img
      src="/images/loggos/rosholmdell_logo_payoff_black.png" class="m-4" width="90%" /></small></a>
  </div>

  <div *ngIf="subscribesToLawLawpoint" class="flex flex-col bg-white w-full md:w-1/2 rounded overflow-hidden shadow-lg m-4 mb-2 p-7">
    <p class="font-xl font-bold mb-4 text-center">{{"LAW_ENFORCEMENT_HEADER" | translate}}</p>
    <div class="flex flex-col md:flex-row mb-4">
      <p class="text-sm text-center w-full w-full">
        {{"LAW_ENFORCEMENT_PHRASE1" | translate}}
      </p>
    </div>

    <div class="flex flex-row justify-center mt-4">
      <button class="button-cdocblue" title="{{'SAVE' | translate}}">
        <a href="http://www.lagpunkten.se" target="_blank" rel="noreferrer noopener">
          <span class="text-white ">{{"LAW_POINT" | translate}}</span>
        </a>
      </button>
    </div>
  </div>

  <div *ngIf="subscribesToLawAptor" class="flex flex-col bg-white w-full md:w-1/2 rounded overflow-hidden shadow-lg m-4 mb-2 p-7">
    <p class="font-xl font-bold mb-4 text-center">{{"APTOR_HEADER" | translate}}</p>
    <div class="flex flex-col md:flex-row mb-4">
      <p class="text-sm text-center w-full w-full">
        {{"APTOR_PHRASE1" | translate}}
      </p>
    </div>

    <div class="flex flex-row justify-center mt-4">
      <button class="button-cdocblue" title="{{'SAVE' | translate}}">
        <a href='{{"APTOR_LINK" | translate}}' target="_blank" rel="noreferrer noopener">
          <span class="text-white ">Aptor</span>
        </a>
      </button>
    </div>
  </div>
</div>
