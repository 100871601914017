import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class ExposuresService {

  getExposureDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService, private authService: AuthService) { }

  /**
     * Since backend return numbers as strings, we need to convert them
     * to numbers in frontend before use
     */
   convertStringToNumber(exposure) {
    if (_.isObject(exposure)) {
        if (_.isString(exposure.exp_per_moment)) {
            exposure.exp_per_moment = parseInt(exposure.exp_per_moment);
        }

        if (_.isString(exposure.exp_times)) {
            exposure.exp_times = parseInt(exposure.exp_times);
        }
    }

    return exposure;
  }

  /**
   * Since backend return dates as strings, we need to convert them to
   * dates in frontend before use
   */
  convertStringToDate(exposure) {
      exposure.start = this.utilService.convertStringToDate(exposure.start);
      exposure.end = this.utilService.convertStringToDate(exposure.end);

      return exposure;
  }

  /**
   * Adjust dates for timezone offset
   */
  convertAndTimezoneAdjustDates(exposure) {
      exposure.start = this.utilService.adjustDateForTimezoneOffset(exposure.start);
      exposure.end = this.utilService.adjustDateForTimezoneOffset(exposure.end);

      return exposure;
  }

  getAll() {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/explist',{headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getExposureDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getExposureDataUpdated.next(null);
        }
      });
    }
  }

  getAllIncludingSubdeps(subdepartments) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');
      let params = new HttpParams();
      params = params.append("departments", subdepartments);
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/explistwithallsubdeps',{headers: headers, params: params}).pipe(take(1)).subscribe({next: response => {

          this.getExposureDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getExposureDataUpdated.next(null);
        }
      });
    }
  }

  getSingle(id) {

    let token = localStorage.getItem('token');

    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
    if (orgId == null)
    {
      this.authService.logout();
    }

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/organisations/' + orgId + '/explist/' + id,{headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.convertStringToNumber(response);
        this.convertStringToDate(response);
        this.getExposureDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getExposureDataUpdated.next(null);
      }
    });
  }

  createExposure(info) {

    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
    if (orgId == null)
    {
      this.authService.logout();
    }

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + '/organisations/' + orgId + '/explist', info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getExposureDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getExposureDataUpdated.next(null);
      }
    });
  }

  updateExposure(id, info) {

    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
    if (orgId == null)
    {
      this.authService.logout();
    }

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    if(_.isObject(info.chemical)){
      info.chemical=info.chemical.sareq_id;
    }

    this.http.put<any>(BackendUrl + '/organisations/' + orgId + '/explist/' + id, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getExposureDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getExposureDataUpdated.next(null);
      }
    });
  }

  deleteExposure(id) {

    let token = localStorage.getItem('token');

    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
    if (orgId == null)
    {
      this.authService.logout();
    }

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.delete(BackendUrl + "/organisations/" + orgId + "/explist/" + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getExposureDataUpdated.next(true);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getExposureDataUpdated.next(true);
      }
    });
  }

  getFileAsTrustedResourceUrl(exposureId, fileId) {
    let token = localStorage.getItem('token');
    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get(BackendUrl + '/organisations/' + orgId + '/explist/' + exposureId + "/files/" + fileId, {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {
        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getExposureDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
          this.getExposureDataUpdated.next(null);
        }
      });
    }
  }

  public getExposuresDataListener() {
    return this.getExposureDataUpdated.asObservable();
  }
}


