<div *ngIf="currentUser&&(currentUser.is_super_user||currentUser.is_admin||currentUser.is_useradmin||currentUser.is_local_admin)" class="flex flex-col md:flex-row max-w-screen">
  <div [hidden]="!loadingChangedProducts" class="flex flex-row w-full">
    <!-- Form Container -->
    <div class="flex flex-col items-left bg-white w-full md:w-full rounded overflow-hidden shadow-lg mb-4 p-7 md:ml-4" id="item">
      <p class="text-xl font-bold">{{"YEARLY_REPORT" | translate}}</p>
      <p class="text-lg mb-4">{{"YEARLY_REPORT_MESSAGE" | translate}}</p>
      <hr/>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded w-64"
              [disabled]="loadingChangedProducts" (click)="buttonClickedYearlyReport()">
              {{timeperiod != 'Monthly' ? ('GET_MONTHLY_REPORT' | translate) : ('GET_YEARLY_REPORT' | translate)}}</button>
      <br>
      <div class="flex items-center">
        <input type="radio" class="hidden" id="option1" name="options" [disabled]="loadingChangedProducts"
              (click)="buttonPercentOrAmount(true)" [checked]="showPercent">
        <label for="option1" class="flex items-center cursor-pointer">
          <span class="w-5 h-5 inline-block mr-2 rounded-full border border-gray-500 flex items-center justify-center">
            <span class="block w-3 h-3 bg-blue-500 rounded-full" *ngIf="showPercent"></span>
          </span>
          {{"SHOW_PERCENT" | translate}}
        </label>
      </div>
      <div class="flex items-center">
        <input type="radio" class="hidden" id="option2" name="options" [disabled]="loadingChangedProducts"
              (click)="buttonPercentOrAmount(false)" [checked]="!showPercent">
        <label for="option2" class="flex items-center cursor-pointer">
          <span class="w-5 h-5 inline-block mr-2 rounded-full border border-gray-500 flex items-center justify-center">
            <span class="block w-3 h-3 bg-blue-500 rounded-full" *ngIf="!showPercent"></span>
          </span>
          {{"SHOW_AMOUNT" | translate}}
        </label>
      </div>
      <div class="flex">
        <div [hidden]="loadingChangedProducts" class="flex justify-left items-left mt-10">
          <div *ngIf="products&&products?.length>0" class="flex flex-col items-right bg-white pt-7 md:ml-4 z-10">
            <div class="flex-grow">
              <div class="flex flex-row shadow-lg mt-6">
                <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="yearlyreportChartConfig"
                    [(update)]="updateYearlyReportChart"
                    id = "highchart-yearlyreportchart">
                  </highcharts-chart>
                </div>
              </div>
              <hr>
              <div class="flex flex-row shadow-lg mt-6">
                <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="yearlyRiskChartConfig"
                    [(update)]="updateYearlyRiskChart"
                    id = "highchart-yearlyreportchart">
                  </highcharts-chart>
                </div>
              </div>
              <hr>
              <div class="flex flex-row shadow-lg mt-6">
                <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="yearlyRestrictionChartConfig"
                    [(update)]="updateYearlyRestrictionChart"
                    id = "highchart-yearlyreportchart">
                  </highcharts-chart>
                </div>
              </div>
              <hr>
              <div class="flex flex-row shadow-lg mt-6">
                <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="yearlyreportCMRAChartConfig"
                    [(update)]="updateCMRAChart"
                    id = "highchart-yearlyreportchart">
                  </highcharts-chart>
                </div>
              </div>
              <hr>
              <div class="flex flex-row shadow-lg mt-6" *ngIf = "subscribesToSpecial">
                <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="yearlyreportSpecialChartConfig"
                    [(update)]="updateSpecialChart"
                    id = "highchart-yearlyreportchart">
                  </highcharts-chart>
                </div>
              </div>
              <hr>
              <div class="flex flex-row shadow-lg mt-6">
                <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="yearlyreportPictogramChartConfig"
                    [(update)]="updatePictogramChart"
                    id = "highchart-yearlyreportchart">
                  </highcharts-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loadingChangedProducts" class="flex justify-center items-center mt-10">
          <div class="loader w-10 h-10"></div>
        </div>
      </div>
    </div>
</div>
