import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OrganisationService } from "src/app/services/organisation.service";
import * as _ from "lodash";
import { take } from "rxjs/operators";
import { UtilService } from "src/app/services/util.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from 'src/app/auth/auth.service';
import { SareqService } from 'src/app/services/sareq.service';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FilterByPipe } from "ngx-pipes";
import { OrderByPipe } from "ngx-pipes";
declare var $: any;

@Component({
  selector: "app-controlpanelcoordinator",
  templateUrl: "./controlpanelcoordinator.component.html",
  styleUrls: ["./controlpanelcoordinator.component.css"],
})
export class ControlpanelcoordinatorComponent implements OnInit {
  activeTab: any = 0;
  listAlldepartments: any;
  allUsersOnOrganisation: any = [];
  currentUser: any;
  selectedOrganisation: any;
  organisationId: any;
  allDepartmentNames: any;
  loadingData: boolean;
  showAllInfo: boolean = true;
  organisation: any = {
    name: "",
    org_nr: "",
    address_1: "",
    address_2: "",
    address_visit: "",
    zip: "",
    city: "",
    country: "",
    www: "",
    email: "",
    phone: "",
    contact_person: "",
    resp_consultant: "",
    misc: "",
    subscriptions: [],
    customer_type: null,
  };
  selectedDepartment: any;
  displayedCompanys;
  coordinatorInformation = {
    start_date: new Date(new Date().getFullYear(), 0, 1),
    end_date: new Date(),
  };
  coordinatorProducts;
  coordinatorCalls;

  coordinatorSearch = {
    all: "",
    customer: "",
    department: "",
    supplier: "",
    product: "",
    email: "",
    contact_person: "",
    resp_consultant: "",
  };

  coordinatorSearch2 = {
    all: "",
    customer: "",
    department: "",
    supplier: "",
    product: "",
    email: "",
    contact_person: "",
    resp_consultant: "",
  };
  itemsPerPage: number = 5;
  pageNumber: number = 1;
  itemsPerPage2: number = 5;
  pageNumber2: number = 1;

  constructor(
    private router: Router,
    private organisationService: OrganisationService,
    private utilService: UtilService,
    private translate: TranslateService,
    private authService: AuthService,
    private sareqService: SareqService
  ) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("rootOrganisation")
    );

    this.getAllDepartments();
    this.updateCoordinatorList();
    this.updateCoordinatorCalls();
  }

  ngAfterViewInit(): void {
    $("#coordinatortable").tablesort();
    $("#coordinatortable2").tablesort();
  }

  setActiveTab(tabnumber) {
    this.activeTab = tabnumber;
  }

  logout() {
    this.authService.logout();
  }

  gotoControlpanel() {
    this.router.navigate(["/controlpanel"]);
  }

  updateCoordinatorList() {
    var date: Date = null,
      date = new Date(this.coordinatorInformation.start_date);
    this.coordinatorInformation.start_date =
      this.utilService.adjustDateForTimezoneOffset(date);
    date = new Date(this.coordinatorInformation.end_date);
    this.coordinatorInformation.end_date =
      this.utilService.adjustDateForTimezoneOffset(date);
    this.sareqService.getCoordinatorProducts(this.coordinatorInformation);
    this.sareqService
      .getSareqDataListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.coordinatorProducts = response;
        },
        error: (error) => {
          console.log("Error: ", error);
          return null;
        },
      });
  }

  updateCoordinatorCalls() {
    var date: Date = null,
      date = new Date(this.coordinatorInformation.start_date);
    this.coordinatorInformation.start_date =
      this.utilService.adjustDateForTimezoneOffset(date);
    date = new Date(this.coordinatorInformation.end_date);
    this.coordinatorInformation.end_date =
      this.utilService.adjustDateForTimezoneOffset(date);
    this.sareqService.getCoordinatorCalls(this.coordinatorInformation);
    this.sareqService
      .getSareqDataListener2()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.coordinatorCalls = response;
        },
        error: (error) => {
          console.log("Error: ", error);
          return null;
        },
      });
  }

  getDepartmentNames() {
    this.organisationService.getAllDepartmentNames(this.organisation);
    //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: (departments) => {
          if (departments != null) {
            this.allDepartmentNames = departments;
            if (this.allUsersOnOrganisation != null) {
              for (var i = 0; i < this.allUsersOnOrganisation.length; i++) {
                this.allUsersOnOrganisation[i].departmentName = "";
                for (var j = 0; j < this.allDepartmentNames.length; j++) {
                  if (
                    this.allUsersOnOrganisation[i].department ==
                    this.allDepartmentNames[j].id
                  ) {
                    this.allUsersOnOrganisation[i].departmentName =
                      this.allDepartmentNames[j].name;
                  }
                }
              }
            }
          } else {
            Swal.fire({
              title: "Organisation users!",
              text: "No departments found!",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK"),
            });
          }
        },
        error: (error) => {
          Swal.fire({
            title: "Organisation users!",
            text: "No departments found!",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK"),
          });
        },
      });
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;
            }
          },
          error: (error) => {
            console.log("Error: ", error);
            return null;
          },
        });
    } else {
      return null;
    }
  }

  departFullName(department) {
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  sareqSelected(product) {
    this.router.navigate(["/editsareq", product.id]);
    delete this.coordinatorProducts;
  }
}
