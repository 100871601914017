import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-modal-select-product-department',
  templateUrl: './modal-select-product-department.component.html',
  styleUrls: ['./modal-select-product-department.component.css']
})
export class ModalSelectProductDepartmentComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() productName;
  @Input() departments;

  chosenDepartment: any = null;
  selectedDepartment: any;
  selectedOrganisation: any;

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder,
              private translate: TranslateService, private authService: AuthService) { }

  ngOnInit(): void {
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation'));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.departments = sortDepartments( this.departments, this.selectedDepartment.id, this.selectedOrganisation.id );
  }

  ngOnDestroy(): void {
    if (this.chosenDepartment == null)
    {
      this.passEntry.emit(this.selectedDepartment);
    }
  }

  setDepartment(department) {
    this.chosenDepartment = department;
    this.submit();
  }

  submit() {
    if (this.chosenDepartment != null) {
      this.passEntry.emit(this.chosenDepartment);
    }
    else {
      this.chosenDepartment = this.departments[0];
      this.passEntry.emit(this.chosenDepartment);
    }

    this.activeModal.close(ModalSelectProductDepartmentComponent);
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalSelectProductDepartmentComponent);
  }

  logout() {
    this.authService.logout();
  }
}

function sortDepartments(departments,selectedDepartmentId,selectedOrganisationId){
  const selectedDepartmentExists = departments.some(department => department.id === selectedDepartmentId);
  // Sort the departments array
  departments.sort((a, b) => {
  // If selectedDepartment does not exist and a is the selected organisation, it should come first
  if (!selectedDepartmentExists && a.id === selectedOrganisationId) {
    return -1;
  }
  // If selectedDepartment does not exist and b is the selected organisation, it should come first
  if (!selectedDepartmentExists && b.id === selectedOrganisationId) {
    return 1;
  }
  // If a is the selected department, it should come first
  if (a.id === selectedDepartmentId) {
    return -1;
  }
  // If b is the selected department, it should come first
  if (b.id === selectedDepartmentId) {
    return 1;
  }
  // If neither is the selected department or organisation, sort alphabetically
  return a.name.localeCompare(b.name);
  });
  return departments;
}
