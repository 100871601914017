import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { PdfService } from 'src/app/services/pdf.service';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, concatMap } from 'rxjs/operators';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-pdf-substitution',
  templateUrl: './pdf-substitution.component.html',
  styleUrls: ['./pdf-substitution.component.css']
})
export class PdfSubstitutionComponent {
  @ViewChild('pdfContent') pdfContent: ElementRef;
  generatePdf() {
    this.pdfService.generatePDF(this.pdfContent.nativeElement);
  }

  @Input() substitution: any;
  @Input() pictures: any[];
  @Input() product: any;

  pictureHeight = 400;
  organisation: any;
  imageLogo: any;

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, 
    private organisationService: OrganisationService, private uploadService: FileUploadService,
    private modalService: NgbModal, 
    private route: ActivatedRoute, private pdfService: PdfService) { }

    ngOnInit(): void {
      this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.getLogoAsPdf();
    }
  
    getLogoAsPdf() {
      this.productService.getLogoAsTrustedResourceUrl(this.organisation.logo_file_id);
      this.productService
        .getLogoListener()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response != null) {
              this.imageLogo = response;
            }
            else
            {
              this.imageLogo = "/images/LoggacDoc2.png"
            }
          },
          error: (error) => {
          },
      });
    }

    getNameStatus(status) {
      switch (status) {
        case 0:
            return this.translate.instant("NEW");
            break;
        case 1:
            return this.translate.instant("ONGOING");
            break;
        case 2:
            return this.translate.instant("FOR_REVIEW");
            break;
        case 3:
            return this.translate.instant("SAREQ_WAITING_FOR_PRODUCT");
            break;
        case 4:
            return this.translate.instant("FINISHED");
            break;
        case 5:
            return this.translate.instant("FINISHED_AND_SUBSTITUTED");
            break;
        default:
            break;
      }
    }
}
