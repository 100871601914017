<div class="container mr-auto px-4 mt-2">
  <ul class="flex flex-col md:flex-row mb-2">
    <li class="mr-1">
        <a (click)="showUserProfile()" [class.active]="isUserProfile"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"PROFILE" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_local_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showCompanyUsers()" [class.active]="isCompanyUsers"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"USERS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showDepartmentSetup()" [class.active]="isDepartmentSetup"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DEPARTMENT_SETUP" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showGroups()" [class.active]="isGroups"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"PRODUCT_GROUPS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showCompanyInfo()" [class.active]="isCompanyInfo"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"COMPANY_INFO" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showArticles()" [class.active]="isArticles"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"ARTICLES" | translate}}</a>
    </li>
    <li *ngIf="subscription_stamped_SDS && subscribesToFreemium==false && currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user || currentUser.is_maintenance_user)" class="mr-1">
      <a (click)="showStampedSds()" [class.active]="isStampedSds"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SUBSCRIPTION_STAMPED_SDS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showMiscInfo()" [class.active]="isMisc"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"MISC" | translate}}</a>
    </li>
  </ul>
</div>

<div class="max-w-screen m-4 mb-40 md:mb-20" *ngIf="isMisc">
  <div class="px-6 py-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
    <div *ngIf="organisation && organisation.subscription_product_application" class="flex flex-col w-full mt-2">
      <span class="text-xl font-bold mb-4">{{"PRODUCT_APPLICATION" | translate}}</span>
      <div class="mb-4">
        <label class="font-bold mb-1">{{"EMAIL_PRODUCT_APPLICATION" | translate}}:</label><br>
        <input title="{{'EMAIL_PRODUCT_APPLICATION' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.product_application_mail" (ngModelChange)="organisation.product_application_mail = $event" [ngModelOptions]="{standalone: true}" type="text">
      </div>
    </div>
    <div class="flex flex-row justify-center mb-20 mt-4">
      <button class="button-green" title="{{'SAVE' | translate}}" (click)="save()">
        <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
        <span class="text-lg">{{ 'SAVE' | translate }}</span>
      </button>
    </div>
  </div>
</div>

