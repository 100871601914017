<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
    <!-- Modal Content -->
    <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"REPORT_SIMPLIFIED" | translate}} ({{"HISTORY" | translate}})</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mb-20 mt-10 p-2">
                <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                    <div class="loader"></div>
                </div>

                <div *ngIf="loadingData==false" class="overflow-auto border border-gray-200">
                    <table datatable [dtOptions]="dtOptions" id="table">
                        <thead class="bg-white">
                            <tr>
                                <th class="table-row-header">{{"DATE" | translate}}</th>
                                <th class="table-row-header">{{"ACTION" | translate}}</th>
                                <th class="table-row-header">{{"COMMENT" | translate}}</th>
                                <th class="table-row-header">{{"PERFORMED_BY" | translate}}</th>
                                <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                                <th class="table-row-header">{{"NAME" | translate}}</th>
                                <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                                <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                                <th class="table-row-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                                <th class="table-row-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                                <th class="table-row-header">{{"UNIT" | translate}}</th>
                                <th class="table-row-header">{{"ARTICLE_NUMBER" | translate}}</th>
                                <th class="table-row-header">{{"COMMON_USE" | translate}}</th>
                                <th class="table-row-header">{{"OBSERVATION" | translate}}</th>
                                <th class="table-row-header">{{"DEPARTMENT_INFO" | translate}}</th>
                                <th class="table-row-header">{{"HYGIENE_LIMITS" | translate}}</th>
                                <th class="table-row-header" *ngIf="subscribesToClassification">{{"CLASSIFICATION" | translate}}</th>
                                <th class="table-row-header">{{"OWN_ARTICLE" | translate}}</th>
                                <th class="table-row-header">{{"DATE" | translate}}</th>
                                <th class="table-row-header" *ngIf="subscribesToTransport">
                                    {{"DANGEROUS_GOODS" | translate}}
                                </th>
                                <th class="table-row-header">{{"SAFETY_EXT_DATA_SHEET" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of reportInformation.products">
                                <td class="table-row-column">{{product.last_changed | date:'yyyy-MM-dd HH:mm'}}</td>
                                <td class="table-row-column">
                                    <span *ngIf="product.deleted&&product.deleted=='yes'">{{"DELETED" | translate}}</span>
                                    <span *ngIf="product.added&&product.added=='yes'&&product.deleted!='yes'">{{"ADDED" | translate}}</span>
                                    <span *ngIf="product.added!='yes'&&product.deleted!='yes'">{{"CHANGED" | translate}}</span>
                                </td>
                                <td class="table-row-column">
                                    <span *ngIf="product.deleted&&product.deleted=='yes'&&product.deletedcomment">
                                        <em>{{product.deletedcomment}}</em>
                                    </span>
                                </td>
                                <td class="table-row-column"><span *ngIf="product.username!=''&&product.username!='null'&&product.username!=null">{{product.username}}</span><span *ngIf="product.username==''||product.username=='null'||product.username==null">-</span></td>
                                <td class="table-row-column">{{product.supplier_name}}</td>
                                <td class="table-row-column">{{product.name}}</td>
                                <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-row">
                                        <span *ngFor="let pictogram of product.pictograms">
                                            <img title="pictogram" class="w-6 h-6" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                                        </span>
                                    </div>
                                </td>
                                <td class="table-row-column">{{product.amount}}</td>
                                <td class="table-row-column">{{product.annual}}</td>
                                <td class="table-row-column">{{product.unit}}</td>
                                <td class="table-row-column">{{product.supplier_article_number}}</td>
                                <td class="table-row-column">{{product.com_use}}</td>
                                <td class="table-row-column">{{product.observation}}</td>
                                <td class="table-row-column">{{product.department_info}}</td>
                                <td class="table-row-column"><span *ngIf="product.hygiene_limits">X</span></td>
                                <td class="table-row-column" *ngIf="subscribesToClassification">
                                    <span *ngFor="let c_phrases of removeItemsWithSameName(product.c_phrases)">{{c_phrases}}{{' '}}<br/></span>
                                </td>
                                <td class="table-row-column">{{product.customerarticle}}</td>
                                <td class="table-row-column">{{product.last_changed | date:'yyyy-MM-dd'}}</td>
                                <ng-container *ngIf="product.adr_not_classfied!==1 && product.transport_un_nbr && subscribesToTransport; else adrNotClassified">
                                    <td class="table-row-column">{{product.transport_un_nbr.toUpperCase().startsWith('UN')? '' : 'UN'}}{{product.transport_un_nbr}}</td>
                                </ng-container>
            
                                <ng-template #adrNotClassified>
                                    <ng-container *ngIf="product.adr_not_classfied===1 && subscribesToTransport; else defaultTemplate">
                                        <td class="table-row-column">{{"NO" | translate}}</td>
                                    </ng-container>
                                </ng-template>
            
                                <ng-template #defaultTemplate>
                                    <td *ngIf="subscribesToTransport" class="table-row-column">{{"" | translate}}</td>
                                </ng-template>
                                <td class="table-row-column" *ngIf="product.esdb_available===1">
                                    {{"YES" | translate}}
                                </td>
                                <td class="table-row-column" *ngIf="product.esdb_available===0">
                                    {{"NO" | translate}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

