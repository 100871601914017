import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StaticDataService } from 'src/app/services/static-data.service';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class ProductConversionsService {

  constructor( private translate: TranslateService,
                private staticDataService: StaticDataService
            ) { }

  /**
     * Remove parameter from object and return the parameter
     *
     * @param object {Object}   - The object for which the parameter should be separated
     * @param paramter {string} - The parameter (key) that should be separated
     * @return The value of object[parameter] or null if not found
     */
  breakOutParameterFromObject(object, parameter) {
    var result = null;
    if (_.isObject(object) && object[parameter] !== undefined) {
        result = object[parameter];
        delete object[parameter];
    }
    return result;
  }

  /**
     * Add information about a pictogram on the specified product.
     * The pictogram information is stored in the array "pictograms" on the
     * product. If "pictograms" does not exist it's created
     *
     * @param product {object}      - Product to add the pictogram
     * @param pictogram {string}    - Pictogram identifier string
     * @param filename {string}     - Filename for pictogram images
     */
   addPictogramToProduct(product, pictogram, filename) {
    var pictogramInfo = null;

    // Create pictograms array on product
    if (!product.pictograms || !_.isArray(product.pictograms)) {
        product.pictograms = [];
    }

    if (product[pictogram] && filename) {
        pictogramInfo = {
            name: pictogram,
            filename: filename,
            header: "",
            description: "",
            descriptionList: []
        };

        switch (pictogram) {
        case "clp_is_dangerous_to_the_environment":
            pictogramInfo.header = this.translate.instant("ENVIRONMENTAL_HAZARD");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_DANGEROUS_TO_THE_ENVIRONMENT");
            pictogramInfo.descriptionList = [
              this.translate.instant("HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT"),
              this.translate.instant("ACUTE"),
              this.translate.instant("CHRONIC")
            ];
            break;
        case "clp_is_toxic":
            pictogramInfo.header = this.translate.instant("TOXIC");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_TOXIC");
            pictogramInfo.descriptionList = [
              this.translate.instant("ACUTE_TOXICITY")
            ];
            break;
        case "clp_is_gas_under_pressure":
            pictogramInfo.header = this.translate.instant("GAS_CONTAINER");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_GAS_UNDER_PRESSURE");
            pictogramInfo.descriptionList = [
              this.translate.instant("GASES_UNDER_PRESSURE"),
              this.translate.instant("COMPRESSED_GASES"),
              this.translate.instant("CONDENSED_GASES"),
              this.translate.instant("COOLED_CONDENSED_GASES"),
              this.translate.instant("DISSOLVED_GASES")
            ];
            break;
        case "clp_is_corrosive":
            pictogramInfo.header = this.translate.instant("CORROSIVE");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_CORROSIVE");
            pictogramInfo.descriptionList = [
              this.translate.instant("CORROSIVE_TO_METALS"),
              this.translate.instant("CORROSIVE_TO_THE_SKIN"),
              this.translate.instant("SERIOUS_EYE_DAMAGE")
            ];
            break;
        case "clp_is_explosive":
            pictogramInfo.header = this.translate.instant("EXPLOSIVE");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_EXPLOSIVE");
            pictogramInfo.descriptionList = [
              this.translate.instant("INSTABILE_EXPLOSIVES_MIXTURES_AND_OBJECTS"),
              this.translate.instant("EXPLOSIVES_MIXTURES_AND_OBJECTS"),
              this.translate.instant("SELFACTIVE_SUBSTANCES_AND_MIXTURES"),
              this.translate.instant("ORGANIC_PEROXIDES")
            ];
            break;
        case "clp_is_flammable":
            pictogramInfo.header = this.translate.instant("FLAMMABLE");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_FLAMMABLE");
            pictogramInfo.descriptionList = [
              this.translate.instant("FLAMMABLE_GASES"),
              this.translate.instant("FLAMMABLE_AEROSOLS"),
              this.translate.instant("FLAMMABLE_LIQUIDS"),
              this.translate.instant("FLAMMABLE_SOLIDS"),
              this.translate.instant("SELFACTIVE_SUBSTANCES_AND_MIXTURES"),
              this.translate.instant("PYROPHORESIC_LIQUIDS_AND_SOLIDS"),
              this.translate.instant("SELFHEATING_SUBSTANCES_AND_MIXTURES"),
              this.translate.instant("SUBSTANCES_THAT_DEVELOP_FLAMMABLE_GASES"),
              this.translate.instant("ORGANIC_PEROXIDES")
            ];
            break;
        case "clp_is_caution":
            pictogramInfo.header = this.translate.instant("DELETERIOUS");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_CAUTION");
            pictogramInfo.descriptionList = [
              this.translate.instant("ACUTE_TOXICITY"),
              this.translate.instant("SKIN_IRRITATION"),
              this.translate.instant("EYE_IRRITATION"),
              this.translate.instant("SKIN_SENSITIZATION"),
              this.translate.instant("SPECIFIC_ORGAN_TOXICITY_SINGLE_EXPOSURE"),
              this.translate.instant("RESPIRATORY_TRACT_IRRITATION"),
              this.translate.instant("NARCOTIC_EFFECTS")
            ];
            break;
        case "clp_is_oxidising":
            pictogramInfo.header = this.translate.instant("OXIDIZING");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_OXIDIZING");
            pictogramInfo.descriptionList = [
              this.translate.instant("OXIDIZING_GASES"),
              this.translate.instant("OXIDIZING_LIQUIDS"),
              this.translate.instant("OXIDIZING_SOLIDS")
            ];
            break;
        case "clp_is_longer_term_health_hazards":
            pictogramInfo.header = this.translate.instant("HEALTH_HAZARD");
            pictogramInfo.description = this.translate.instant("PICTOGRAM_DESC_CLP_IS_LONGER_TERM_HEALTH_HAZARDS");
            pictogramInfo.descriptionList = [
              this.translate.instant("RESPIRATORY_SENSITIZATION"),
              this.translate.instant("MUTAGENICITY_IN_GERM_CELLS"),
              this.translate.instant("CARCINOGENICITY"),
              this.translate.instant("REPRODUCTIVE_TOXICITY"),
              this.translate.instant("SPECIFIC_ORGAN_TOXICITY_SINGLE_EXPOSURE"),
              this.translate.instant("SPECIFIC_ORGAN_TOXICITY_REPEATED_EXPOSURE"),
              this.translate.instant("DANGEROUS_IN_ASPIRATION")
            ];
            break;
        case "kifs_is_flammable":
            pictogramInfo.header = this.translate.instant("EXTREMELY_HIGHLY_FLAMMALBE");
            pictogramInfo.description = "";
            pictogramInfo.descriptionList = [
            ];
            break;
        case "kifs_is_explosive":
            pictogramInfo.header = this.translate.instant("EXPLOSIVE");
            pictogramInfo.description = "";
            pictogramInfo.descriptionList = [
            ];
            break;
        case "kifs_is_corrosive":
            pictogramInfo.header = this.translate.instant("CORROSIVE");
            pictogramInfo.description = "";
            pictogramInfo.descriptionList = [
            ];
            break;
        case "kifs_is_toxic":
            pictogramInfo.header = this.translate.instant("VERY_TOXIC_TOXIC");
            pictogramInfo.description = "";
            pictogramInfo.descriptionList = [
            ];
            break;
        case "kifs_is_harmful":
            pictogramInfo.header = this.translate.instant("HARMFUL_IRRITANT");
            pictogramInfo.description = "";
            pictogramInfo.descriptionList = [
              this.translate.instant("SENSITIZATION"),
              this.translate.instant("CARCINOGENIC"),
              this.translate.instant("MUTAGENIC"),
              this.translate.instant("REPRODUCTIVE_TOXICITY"),
              this.translate.instant("IRRITATING")
            ];
            break;
        case "kifs_is_dangerous_to_the_environment":
            pictogramInfo.header = this.translate.instant("ENVIRONMENTAL_HAZARD");
            pictogramInfo.description = "";
            pictogramInfo.descriptionList = [
            ];
            break;
        case "kifs_is_oxidising":
            pictogramInfo.header = this.translate.instant("OXIDIZING");
            pictogramInfo.description = "";
            pictogramInfo.descriptionList = [
            ];
            break;
        case "kifs_is_warning":
            pictogramInfo.header = this.translate.instant("WARNING");
            pictogramInfo.description = "";
            pictogramInfo.descriptionList = [
            ];
            break;
        default:
            console.log("Information missing for pictogram: ", pictogram);
            break;
        }
        product.pictograms.push(pictogramInfo);
    }
  }

  /**
     * Convert picogram identifiers on products to pictogram information,
     * with header, description, descriptionlist and image filename
     */
   convertPictogramsOnProduct(product) {
    if (product !== undefined && product !== null && _.isObject(product)) {
        this.addPictogramToProduct(product, "clp_is_dangerous_to_the_environment", "clp_pollution");
        this.addPictogramToProduct(product, "clp_is_toxic", "clp_skull");
        this.addPictogramToProduct(product, "clp_is_gas_under_pressure", "clp_bottle");
        this.addPictogramToProduct(product, "clp_is_corrosive", "clp_acid");
        this.addPictogramToProduct(product, "clp_is_explosive", "clp_explosion");
        this.addPictogramToProduct(product, "clp_is_flammable", "clp_flame");
        this.addPictogramToProduct(product, "clp_is_caution", "clp_exclamation");
        this.addPictogramToProduct(product, "clp_is_oxidising", "clp_roundflame");
        this.addPictogramToProduct(product, "clp_is_longer_term_health_hazards", "clp_silhouete");
        this.addPictogramToProduct(product, "kifs_is_flammable", "kifs_brandcol");
        this.addPictogramToProduct(product, "kifs_is_explosive", "kifs_explcol");
        this.addPictogramToProduct(product, "kifs_is_corrosive", "kifs_fratcol");
        this.addPictogramToProduct(product, "kifs_is_toxic", "kifs_giftcol");
        this.addPictogramToProduct(product, "kifs_is_harmful", "kifs_korscol");
        this.addPictogramToProduct(product, "kifs_is_dangerous_to_the_environment", "kifs_miljocol");
        this.addPictogramToProduct(product, "kifs_is_oxidising", "kifs_oxidcol");
        this.addPictogramToProduct(product, "kifs_is_warning", "kifs_varncol");

        // Remove old parameters
        delete product.clp_is_dangerous_to_the_environment;
        delete product.clp_is_toxic;
        delete product.clp_is_gas_under_pressure;
        delete product.clp_is_corrosive;
        delete product.clp_is_explosive;
        delete product.clp_is_flammable;
        delete product.clp_is_caution;
        delete product.clp_is_oxidising;
        delete product.clp_is_longer_term_health_hazards;
        delete product.kifs_is_flammable;
        delete product.kifs_is_explosive;
        delete product.kifs_is_corrosive;
        delete product.kifs_is_toxic;
        delete product.kifs_is_harmful;
        delete product.kifs_is_dangerous_to_the_environment;
        delete product.kifs_is_oxidising;
        delete product.kifs_is_warning;
    }
    return product;
  }

  /**
     * Convert picogram identifiers on products to pictogram information,
     * with header, description, descriptionlist and image filename
     */
   convertPictogramsOnProductArray(productArray) {
    var i,
        arrayLength = 0;

    if (productArray !== undefined && productArray !== null) {
      if (_.isArray(productArray)) {
        arrayLength = productArray.length;
        for (i = 0; i < arrayLength; i += 1) {
          this.convertPictogramsOnProduct(productArray[i]);
        }
      }
    }
    return productArray;
  }

  /**
     * Convert H-, P-, R- and S-phrases from array of object to array of string
     * (removes the old parameters from product)
     */
   convertPhrasesOnProduct(product) {
    var i,
        phrasesLength;

    if (product !== undefined && product !== null && _.isObject(product)) {
        product.phrases = {
            "H": [],
            "P": [],
            "R": [],
            "S": []
        };

        // H-phrases
        if (_.isArray(product.h_phrases)) {
            phrasesLength = product.h_phrases.length;
            for (i = 0; i < phrasesLength; i += 1) {
                product.phrases.H.push(product.h_phrases[i].h_phrase);
            }
            delete product.h_phrases;

            // Sort phrases
            product.phrases.H.sort(function (a, b) {
                return a > b ? 1 : a === b ? 0 : -1;
            });
        }

        // P-phrases
        if (_.isArray(product.p_phrases)) {
            phrasesLength = product.p_phrases.length;
            for (i = 0; i < phrasesLength; i += 1) {
                product.phrases.P.push(product.p_phrases[i].p_phrase);
            }
            delete product.p_phrases;

            // Sort phrases
            product.phrases.P.sort(function (a, b) {
                return a > b ? 1 : a === b ? 0 : -1;
            });
        }

        // R-phrases
        if (_.isArray(product.r_phrases)) {
            phrasesLength = product.r_phrases.length;
            for (i = 0; i < phrasesLength; i += 1) {
                product.phrases.R.push(product.r_phrases[i].r_phrase);
            }
            delete product.r_phrases;

            // Sort phrases
            product.phrases.R.sort(function (a, b) {
                return a > b ? 1 : a === b ? 0 : -1;
            });
        }

        // S-phrases
        if (_.isArray(product.s_phrases)) {
            phrasesLength = product.s_phrases.length;
            for (i = 0; i < phrasesLength; i += 1) {
                product.phrases.S.push(product.s_phrases[i].s_phrase);
            }
            delete product.s_phrases;

            // Sort phrases
            product.phrases.S.sort(function (a, b) {
                return a > b ? 1 : a === b ? 0 : -1;
            });
        }
    }
    return product;
  }

  /**
     * Convert H-, P-, R- and S-phrases from array of object to array of string
     * (removes the old parameters from product)
     * Applies to array of products
     */
   convertPhrasesOnProductArray(productArray) {
    var i,
        arrayLength;

    if (productArray !== undefined && productArray !== null && _.isArray(productArray)) {
        arrayLength = productArray.length;
        for (i = 0; i < arrayLength; i += 1) {
          this.convertPhrasesOnProduct(productArray[i]);
        }
    }
    return productArray;
  }

  /**
     * Converts footprint_<X> to footprint.<X>
     */
   convertFootprintOnProduct(product) {
    if (product !== undefined && product !== null && _.isObject(product)) {
        product.footprint = {
            "air": false,
            "product": false,
            "waste": false,
            "water": false
        };

        if (product.footprint_air) {
            product.footprint.air = true;
        }
        delete product.footprint_air;

        if (product.footprint_product) {
            product.footprint.product = true;
        }
        delete product.footprint_product;

        if (product.footprint_waste) {
            product.footprint.waste = true;
        }
        delete product.footprint_waste;

        if (product.footprint_water) {
            product.footprint.water = true;
        }
        delete product.footprint_water;
    }
    return product;
  }

  /**
     * Converts footprint_<X> to footprint.<X> on product array
     */
   convertFootprintOnProductArray(productArray) {
    var i,
        arrayLength;

    if (productArray !== undefined && productArray !== null && _.isArray(productArray)) {
        arrayLength = productArray.length;
        for (i = 0; i < arrayLength; i += 1) {
          this.convertFootprintOnProduct(productArray[i]);
        }
    }
    return productArray;
  }

  /**
     * Converts ice_<X> to ice.<X> on product
     */
   convertICEOnProduct(product) {
    if (_.isObject(product)) {
        product.ice = {
            "consumption": null,
            "eye_contact": null,
            "general_information": null,
            "inhalation": null,
            "skin_contact": null
        };

        product.ice.consumption = this.breakOutParameterFromObject(product, "ice_consumption");
        product.ice.eye_contact = this.breakOutParameterFromObject(product, "ice_eye_contact");
        product.ice.general_information = this.breakOutParameterFromObject(product, "ice_general_information");
        product.ice.inhalation = this.breakOutParameterFromObject(product, "ice_inhalation");
        product.ice.skin_contact = this.breakOutParameterFromObject(product, "ice_skin_contact");
    }

    return product;
  }

  /**
     * Converts prot_<X> to protection.<X> on product
     */
   convertProtectionOnProduct(product) {
    if (_.isObject(product)) {
        product.protection = {
            "breath": null,
            "clothes": null,
            "glasses": null,
            "gloves": null,
            "hygiene": null
        };

        product.protection.breath = this.breakOutParameterFromObject(product, "prot_breath");
        product.protection.clothes = this.breakOutParameterFromObject(product, "prot_clothes");
        product.protection.glasses = this.breakOutParameterFromObject(product, "prot_glasses");
        product.protection.gloves = this.breakOutParameterFromObject(product, "prot_gloves");
        product.protection.hygiene = this.breakOutParameterFromObject(product, "prot_hygiene");
    }

    return product;
  }

  // TEMPORARY CONVERTER OF PRODUCT ID:s
  /**
   * If products id is returned as "product_id", rename it to "id"
   */
    convertIdOnProduct(product) {
    if (_.isObject(product) && !_.isString(product.id)) {
        if (_.isString(product.product_id)) {
            product.id = product.product_id;
            delete product.product_id;
        } else {
            console.log("convertIdOnProduct warning: No ID found on product: ", product);
        }
    }
    return product;
  }

  /**
     * If products id is returned as "product_id", rename it to "id". Applies to product array
     */
   convertIdOnProductArray(productArray) {
    var i,
        productArrayLength;

    if (_.isArray(productArray)) {
        productArrayLength = productArray.length;

        for (i = 0; i < productArrayLength; i += 1) {
            this.convertIdOnProduct(productArray[i]);
        }
    }
    return productArray;
  }

  setProductRiskLists(product) {
    var i,
        length;

    if (product !== null && product !== undefined && _.isObject(product)) {

        if (_.isArray(product.mixtures)) {
            length = product.mixtures.length;
            for (i = 0; i < length; i += 1) {
                if (product.mixtures[i].list_reach_14) {
                    product.list_reach_14 = 1;
                }
                if (product.mixtures[i].list_reach_17) {
                    product.list_reach_17 = 1;
                }
                if (product.mixtures[i].list_reach_candidate) {
                    product.list_reach_candidate = 1;
                }
                if (product.mixtures[i].list_chem_limit) {
                    product.list_chem_limit = 1;
                }
                if (product.mixtures[i].list_chem_prio_p) {
                    product.list_chem_prio_p = 1;
                }
                if (product.mixtures[i].list_chem_prio_u) {
                    product.list_chem_prio_u = 1;
                }
                if (product.mixtures[i].list_water_directive) {
                    product.list_water_directive = 1;
                }
                if (product.mixtures[i].list_amv_a) {
                    product.list_amv_a = 1;
                }
                if (product.mixtures[i].list_amv_b) {
                    product.list_amv_b = 1;
                }
                if (product.mixtures[i].list_sll) {
                    product.list_sll = 1;
                }
                if (product.mixtures[i].list_BASTA) {
                    product.list_BASTA = 1;
                }
                if (product.mixtures[i].list_sin) {
                    product.list_sin = 1;
                }
            }
        }

        if (_.isArray(product.substances)) {
            length = product.substances.length;
            for (i = 0; i < length; i += 1) {
                if (product.substances[i].list_reach_14) {
                    product.list_reach_14 = 1;
                }
                if (product.substances[i].list_reach_17) {
                    product.list_reach_17 = 1;
                }
                if (product.substances[i].list_reach_candidate) {
                    product.list_reach_candidate = 1;
                }
                if (product.substances[i].list_chem_limit) {
                    product.list_chem_limit = 1;
                }
                if (product.substances[i].list_chem_prio_p) {
                    product.list_chem_prio_p = 1;
                }
                if (product.substances[i].list_chem_prio_u) {
                    product.list_chem_prio_u = 1;
                }
                if (product.substances[i].list_water_directive) {
                    product.list_water_directive = 1;
                }
                if (product.substances[i].list_amv_a) {
                    product.list_amv_a = 1;
                }
                if (product.substances[i].list_amv_b) {
                    product.list_amv_b = 1;
                }
                if (product.substances[i].list_sll) {
                    product.list_sll = 1;
                }
                if (product.substances[i].list_BASTA) {
                    product.list_BASTA = 1;
                }
                if (product.substances[i].list_sin) {
                    product.list_sin = 1;
                }
            }
        }
    }

    return product;
  }

  setProductArrayRiskLists(productArray) {
    var i,
        productArrayLength;

    if (_.isArray(productArray)) {
        productArrayLength = productArray.length;

        for (i = 0; i < productArrayLength; i += 1) {
            this.setProductRiskLists(productArray[i]);
        }
    }
    return productArray;
  }

  /**
     * Translate backend classification from number to classification object
     */
   convertClassificationOnProduct(product) {
    var availableClassifications = this.staticDataService.getAvailableClassifications();

    if (_.isObject(product)) {
        if (product.classification && product.classification >= 0 && product.classification <= 5) {
            product.classification = availableClassifications[product.classification];
        } else {
            product.classification = availableClassifications[0];
        }
    }
    return product;
  }

  convertClassificationOnProductArray(productArray) {
    var i,
        arrayLength;

    if (_.isArray(productArray)) {
        arrayLength = productArray.length;
        for (i = 0; i < arrayLength; i += 1) {
            productArray[i] = this.convertClassificationOnProduct(productArray[i]);
        }
    }

    return productArray;
  }

  convertProtectiveGearImagesOnProductArray(productArray){
    var i,
        arrayLength;

    if (_.isArray(productArray)) {
        arrayLength = productArray.length;
        for (i = 0; i < arrayLength; i += 1) {
          productArray[i] = this.convertProtectiveGearImagesToProduct(productArray[i]);
          if ( productArray[i].chemical_id && productArray[i].chemical_id != null ) {
            productArray[i] = this.convertLRBProtectiveGearImagesToProduct(productArray[i]);
          }
        }
    }

    return productArray;
  }

  convertProtectiveGearImagesToProduct(product){
      var protectiveImages = [{"name": "image_mask", "filename": "mask"}, {"name": "image_boots", "filename": "boots"}, {"name": "image_breath", "filename": "breath"}, {"name": "image_clothes", "filename": "clothes"},{"name": "image_gloves", "filename": "gloves"}, {"name": "image_glasses", "filename": "glasses"}];
      var tempProtective = [];
      for(var i = 0; i < protectiveImages.length; i++){
          if(protectiveImages[i].name =="image_mask"){
              protectiveImages[i]['header'] = this.translate.instant("Ansiktsskydd");
              protectiveImages[i]['description'] = this.translate.instant("Bra att använda när det är risk för stänk och man behöver skydda hela ansiktet. ");
          }
          if(protectiveImages[i].name =="image_boots"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsskor");
              protectiveImages[i]['description'] = this.translate.instant("Speciella skor kan behövas vid arbete med vissa typer av kemikalier, oftast i våta miljöer. Exempel: Antistatiska; Kemikaliebeständiga; Vattentäta osv.");
          }
          if(protectiveImages[i].name =="image_breath"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsmask");
              protectiveImages[i]['description'] = this.translate.instant("Ska användas på allmän ventilation inte är tillräcklig. Till exempel vid arbete i slutna utrymmen. Skyddar mot luftburna ångor eller partiklar beroende på filtertyp. Val av filtertyp kan hittas på produktinformationen i cDoc-tjänsten eller i säkerhetsdatabladet.");
          }
          if(protectiveImages[i].name =="image_clothes"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddskläder");
              protectiveImages[i]['description'] = this.translate.instant("Vanliga arbetskläder räknas inte som skyddskläder i riskbedömningen. Skyddar mot hudkontakt för exempelvis frätande kemikalier eller kemikalier som får hälsoeffekt vid hudkontakt. Val av skyddskläder kan hittas på produktinformationen i cDoctjänsten eller i säkerhetsdatabladet. Skyddskläder kan också gälla en fysikalisk fara såsom högtrycksanordningar.");
          }
          if(protectiveImages[i].name =="image_gloves"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddshandskar");
              protectiveImages[i]['description'] = this.translate.instant("Val av skyddshandske kan hittas på produktinformationen i cDoctjänsten under avsnittet ”Skyddsutrustning” eller i säkerhetsdatabladet. Skyddar bra mot till exempel uttorkning och produkter som kan orsaka allergi vid hudkontakt.");
          }
          if(protectiveImages[i].name =="image_glasses"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsglasögon");
              protectiveImages[i]['description'] = this.translate.instant("Vanligast förekommande skyddet eftersom ögat ofta inte går att reparera vid skada. Ex: Tättslutande skyddsglasögon");
          }
          if(product[protectiveImages[i].name]){
              tempProtective.push(protectiveImages[i]);
          }
      }

      if(tempProtective.length == 0 && !product.no_protection){
          tempProtective.push({"name": "no_protection", "filename": "no_protection", "header": "Skyddsutrustning okänd", "description": ""});
      }
      product['protectiveArray'] = tempProtective;
      return product;
    }

    convertLRBProtectiveGearImagesToProduct(product){
      var protectiveImages = [
        {"name": "LRB_image_mask", "filename": "mask"},
        {"name": "LRB_image_boots", "filename": "boots"},
        {"name": "LRB_image_breath", "filename": "breath"},
        {"name": "LRB_image_clothes", "filename": "clothes"},
        {"name": "LRB_image_gloves", "filename": "gloves"},
        {"name": "LRB_image_glasses", "filename": "glasses"}
      ];
      var tempProtective = [];
      for(var i = 0; i < protectiveImages.length; i++){
          if(protectiveImages[i].name =="LRB_image_mask"){
              protectiveImages[i]['header'] = this.translate.instant("Ansiktsskydd");
              protectiveImages[i]['description'] = this.translate.instant("Bra att använda när det är risk för stänk och man behöver skydda hela ansiktet. ");
          }
          if(protectiveImages[i].name =="LRB_image_boots"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsskor");
              protectiveImages[i]['description'] = this.translate.instant("Speciella skor kan behövas vid arbete med vissa typer av kemikalier, oftast i våta miljöer. Exempel: Antistatiska; Kemikaliebeständiga; Vattentäta osv.");
          }
          if(protectiveImages[i].name =="LRB_image_breath"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsmask");
              protectiveImages[i]['description'] = this.translate.instant("Ska användas på allmän ventilation inte är tillräcklig. Till exempel vid arbete i slutna utrymmen. Skyddar mot luftburna ångor eller partiklar beroende på filtertyp. Val av filtertyp kan hittas på produktinformationen i cDoc-tjänsten eller i säkerhetsdatabladet.");
          }
          if(protectiveImages[i].name =="LRB_image_clothes"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddskläder");
              protectiveImages[i]['description'] = this.translate.instant("Vanliga arbetskläder räknas inte som skyddskläder i riskbedömningen. Skyddar mot hudkontakt för exempelvis frätande kemikalier eller kemikalier som får hälsoeffekt vid hudkontakt. Val av skyddskläder kan hittas på produktinformationen i cDoctjänsten eller i säkerhetsdatabladet. Skyddskläder kan också gälla en fysikalisk fara såsom högtrycksanordningar.");
          }
          if(protectiveImages[i].name =="LRB_image_gloves"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddshandskar");
              protectiveImages[i]['description'] = this.translate.instant("Val av skyddshandske kan hittas på produktinformationen i cDoctjänsten under avsnittet ”Skyddsutrustning” eller i säkerhetsdatabladet. Skyddar bra mot till exempel uttorkning och produkter som kan orsaka allergi vid hudkontakt.");
          }
          if(protectiveImages[i].name =="LRB_image_glasses"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsglasögon");
              protectiveImages[i]['description'] = this.translate.instant("Vanligast förekommande skyddet eftersom ögat ofta inte går att reparera vid skada. Ex: Tättslutande skyddsglasögon");
          }
          if(product[protectiveImages[i].name]){
              tempProtective.push(protectiveImages[i]);
          }
      }

      if(tempProtective.length == 0 && !product.no_protection){
          tempProtective.push({"name": "no_protection", "filename": "no_protection", "header": "Skyddsutrustning okänd", "description": ""});
      }
      product['protectiveArrayLRB'] = tempProtective;
      return product;
  }
  }
