import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import * as _ from "lodash";
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { SponsorService } from 'src/app/services/sponsor.service';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from 'src/app/auth/auth.service';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FilterByPipe } from 'ngx-pipes';
import { OrderByPipe} from 'ngx-pipes';
declare var $: any;

@Component({
  selector: "app-organisation",
  templateUrl: "./organisation.component.html",
  styleUrls: ["./organisation.component.css"],
})
export class OrganisationComponent implements OnInit {

  loadingData:boolean = false;
  organisationList: any = [];
  filteredOrganisationList: any = [];
  subscribtionList: any = [];
  itemsPerPage:number=10;
  pageNumber:number=1;
  filterSearch = {
    name: "",
    subscription: "",
  }

  subscriptionMoreInfoDefault = {
    type_of_subscription: "",
  }

  constructor(
    private router: Router,
    private previousrouter: ActivatedRoute,
    private organisationService: OrganisationService,
    private productService: ProductService,
    private userService: UserService,
    private sponsorService: SponsorService,
    private staticDataService: StaticDataService,
    private utilService: UtilService,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getOrganisations();
  }

  logout() {
    this.authService.logout();
  }

  gotoControlPanel() {
    this.router.navigate(["/controlpanel"]);
  }

  add() {
    this.router.navigate(["/organisation", 'new']);
  }

  selectOrganisation(organisation) {
    this.router.navigate(["/organisation", organisation.id]);
  }

  filterByType(){

    this.filteredOrganisationList = this.organisationList;

    if(this.filterSearch.name!=null){
      this.filteredOrganisationList=this.filteredOrganisationList.filter(o => o.name?.toLowerCase().indexOf(this.filterSearch.name.toLowerCase())>-1);
    }

    if(this.filterSearch.subscription!=null){
      this.filteredOrganisationList=this.filteredOrganisationList.filter(o => o.subscriptionMoreInfo?.type_of_subscription.toLowerCase().indexOf(this.filterSearch.subscription.toLowerCase())>-1);
    }
  }

  addSubscriptionInfoToCompanies() {
    for (var i = 0; i < this.organisationList.length; i++) {
      this.organisationList[i].subscriptionMoreInfo = this.subscriptionMoreInfoDefault;
      for (var j = 0; j < this.subscribtionList.length; j++) {
        if (this.organisationList[i].id === this.subscribtionList[j].org_id) {
          this.organisationList[i].subscriptionMoreInfo = this.subscribtionList[j];
        }
      }
    }
  }

  getOrganisations() {

    this.loadingData = true;

    this.organisationService.getAllCompanies();
    this.organisationService.getOrganisationNavDataListener().pipe(take(1)).subscribe({
      next: organisations => {
        if (organisations != null)
        {
          this.organisationList = organisations;
          this.organisationService.getAllSubscriptionInfo();
          this.organisationService.getOrganisationSubDataListener().pipe(take(1)).subscribe({
            next: subscribtions => {
              if (subscribtions != null)
              {
                this.subscribtionList = subscribtions;
                this.addSubscriptionInfoToCompanies();
                this.filteredOrganisationList =_.cloneDeep(this.organisationList);
              }
              this.loadingData = false;
            },
            error: error => {
              this.loadingData = false;
            }
          })
        }
        else
        {
          this.loadingData = false;
        }
      },
      error: error => {
        this.loadingData = false;
      }
    })
  }
}