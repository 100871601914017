import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  today: Date;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  showDanger() {
    //$state.go("password_recovery");
  }

  logout() {
    this.authService.logout();
  }
}
