import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAllowDecimalSeparator]'
})
export class AllowDecimalSeparatorDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;

    // Replace commas with dots and update the input value
    const sanitizedValue = inputValue.replace(/[^0-9,.]/g, '');
    inputElement.value = sanitizedValue;

    // Emit the input event to update the ngModel
  }
}
