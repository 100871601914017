<div *ngIf="loadingData" id="loading-productadd" class="flex justify-center items-center mt-4 mb-4">
  <div class="loader"></div>
</div>

<div>
  <div class="flex flex-col min-h-screen border border-gray-300">
    <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="goToProducts()">
      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
        <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
      </svg>
    </button>

    <div *ngIf="loadingData==false" class="mt-2 px-4 py-2 mb-20">
      <div class="overflow-x-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400 mr-4" st-table="selectedProducts" st-safe-src="editAllProducts" id="editAllProducts">
          <thead class="bg-white">
            <tr>
              <th class="table-row-header-no-border no-sort mt-2" colspan="12">
                <div class="flex flex-col md:flex-row">
                  <input st-search="" type="search" [(ngModel)]="productSearch.all" id="addProductSearch1" (input)="filterSearchItems()"
                    class="flex w-full md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400 mr-2" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                </div>
              </th>
            </tr>
            <tr>
              <th class="table-row-header-no-border no-sort" colspan="12">
                <div class="flex flex-col md:flex-row mb-1">
                  <input st-search="" type="search" [(ngModel)]="productSearch.name" id="addProductSearch2" (input)="filterSearchItems()"
                      class="flex w-full md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400 mr-1" placeholder="{{'SEARCH_PRODUCT_NAME' | translate}} ..." />

                  <input st-search="" type="search" [(ngModel)]="productSearch.alternative_name" id="addProductSearch3" (input)="filterSearchItems()"
                      class="flex w-full md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400 mr-1" placeholder="{{'SEARCH_ALTERNATIVE_NAME' | translate}} ..." />

                  <input st-search="" type="search" [(ngModel)]="productSearch.supplier_name" id="addProductSearch4" (input)="filterSearchItems()"
                      class="flex w-full md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400" placeholder="{{'SEARCH_MANUFACTURER' | translate}} ..." />
                </div>
              </th>
            </tr>
            <tr>
              <th (click)="sortItem('name',0)" st-sort="name" class="table-row-header sort-header">{{"NAME" | translate}}</th>
              <th (click)="sortItem('alternative_name',2)" st-sort="alternative_name" class="table-row-header sort-header">{{"ALTERNATIVE_NAME" | translate}}</th>
              <th (click)="sortItem('supplier_name',1)" st-sort="supplier_name" class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
              <th class="table-row-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
              <th class="table-row-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
              <th class="table-row-header">{{"MAXIMUM_AMOUNT" | translate}}</th>
              <th class="table-row-header">{{"UNIT" | translate}}</th>
              <th class="table-row-header">{{"PRODUCT_GROUP" | translate}}</th>
              <th class="table-row-header"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of productsOverride | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" [hidden]="product.remove=='remove'">
              <td class="table-row-column">{{product.name}}</td>
              <td class="table-row-column">{{product.alternative_name}}</td>
              <td class="table-row-column">{{product.supplier_name}}</td>
              <td class="table-row-column"><input title="amount" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.amount" /></td>
              <td class="table-row-column"><input title="annual_consumption" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.annual_consumption" /></td>
              <td class="table-row-column"><input title="maximum_amount" class="border border-gray-300 w-20 p-1" type="number" min="0" [(ngModel)]="product.maximum_amount" /></td>
              <td class="table-row-column">
                <div class="relative inline-block text-left p-1 w-10 border border-gray-300" appDropdown>
                  <button type="button" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span class="nav-home-text text-sm">{{isObjectType(product.unit) ? product.unit.id : product.unit}}</span>
                    <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                  <div id="dropdownMenu" class="hidden dropdown-menu w-10 z-20" #dropdownMenuInside>
                    <div class="py-1">
                      <div *ngFor="let unit of units" data-value="unit.id" class="dropdown-menu-item w-10" (click)="setUnit(product, unit.id)" tabindex="1">{{ unit.id }}</div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="table-row-column">
                <div class="relative inline-block text-left w-40 border border-gray-300 p-1" appDropdown>
                  <button type="button" class="flex justify-between items-center text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500">
                    <span class="nav-home-text text-sm">{{product.group_name}}</span>
                    <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                  <div id="dropdownMenu" class="hidden dropdown-menu z-20 w-40 left-10" #dropdownMenuInside>
                    <div class="py-1">
                      <div *ngFor="let group of groups" data-value="group.name" class="dropdown-menu-item w-40" (click)="setGroupName(product, group.name)" tabindex="1">{{ group.name }}</div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="table-row-column">
                <div title="{{'REMOVE_FROM_LIST' | translate}}" (click)="removeProduct(product)">
                  <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                    21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                    5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                    3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                    stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="p-6" colspan="2">
                  <div class="relative inline-block text-left" appDropdown>
                      <button type="button" class="button-gray">
                          <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                          <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </button>
                      <div id="dropdownMenu" class="hidden dropdown-menu left-40" #dropdownMenuInside>
                          <div class="py-1">
                          <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                          <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                          <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                          <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                          </div>
                      </div>
                  </div>
              </td>
              <td colspan="4">
                  <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                      previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="flex flex-row justify-center mb-20 mt-4">
          <button id="addProductButton7" class="button-green" title="{{'SAVE' | translate}}" (click)="saveProducts()">
            <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'SAVE' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

