<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-30" id="my-modal">
  <!-- Modal Content -->
  <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white">
      <div class="flex justify-end">
          <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
      </div>
      <div class="mt-3 text-center">
          <div class="text-xl text-gray-900 font-bold">{{"ACTION_TO_BE_DONE_NOW" | translate}}</div>
          <!-- Modal Body -->
          <div class="flex flex-col center-items mt-10 p-2">
              <div class="flex flex-col w-full items-center mt-2">
                  <div class="mb-4">
                    <div class="flex items-center space-x-2 mt-2">
                      <input type="radio" name="modal-select-status-0" id="modal-select-status-0" [checked]="selectOption==0" (click)="selectRadio(0)">
                      <label class="font-bold mb-1">{{"SAVE_ALTERNATIVE_0" | translate}}</label>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="flex items-center space-x-2 mt-2">
                      <input type="radio" name="modal-select-status-1" id="modal-select-status-1" [checked]="selectOption==1" (click)="selectRadio(1)">
                      <label class="font-bold mb-1">{{"SAVE_ALTERNATIVE_1" | translate}}</label>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="flex items-center space-x-2 mt-2">
                      <input type="radio" name="modal-select-status-2" id="modal-select-status-2" [checked]="selectOption==2" (click)="selectRadio(2)">
                      <label class="font-bold mb-1">{{"SAVE_ALTERNATIVE_2" | translate}}</label>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="flex items-center space-x-2 mt-2">
                      <input type="radio" name="modal-select-status-3" id="modal-select-status-3" [checked]="selectOption==3" (click)="selectRadio(3)">
                      <label class="font-bold mb-1">{{"SAVE_ALTERNATIVE_3" | translate}}</label>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="flex items-center space-x-2 mt-2">
                      <input type="radio" name="modal-select-status-4" id="modal-select-status-4" [checked]="selectOption==4" (click)="selectRadio(4)">
                      <label class="font-bold mb-1">{{"SAVE_ALTERNATIVE_4" | translate}}</label>
                    </div>
                  </div>
              </div>
          </div>
          <!-- Modal Footer -->
          <div class="flex justify-center items-center mt-4">
              <button type="button" 
                  class="button-green" 
                  (click)="saveOption()">
                  {{"SAVE" | translate}}
              </button>
          </div>
      </div>
  </div>
</div>

