import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { PdfService } from 'src/app/services/pdf.service';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import * as _ from 'lodash';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SupportService } from 'src/app/services/support.service';
declare var $: any;

@Component({
  selector: 'app-assessment-pdf',
  templateUrl: './assessment-pdf.component.html',
  styleUrls: ['./assessment-pdf.component.css']
})
export class AssessmentPdfComponent {
  @ViewChild('pdfContent') pdfContent: ElementRef;
  generatePdf() {
    this.pdfService.generatePDF(this.pdfContent.nativeElement);
  }

  @Input() assessment: any;
  @Input() pictures: any[];
  @Input() uniqueproducts: any[];

  pictureWidth = 800;
  pictureHeight = 800;
  organisation: any;
  imageLogo: any;

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, private materialListService: MaterialListService,
    private organisationService: OrganisationService, private uploadService: FileUploadService,
    private modalService: NgbModal, private assessmentService: AssessmentService,
    private route: ActivatedRoute,private supportService: SupportService, private pdfService: PdfService) { }

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.getLogoAsPdf();
  }

  getLogoAsPdf() {
    this.productService.getLogoAsTrustedResourceUrl(this.organisation.logo_file_id);
    this.productService
      .getLogoListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response != null) {
            this.imageLogo = response;
          }
        },
        error: (error) => {
        },
      });
  }
}
