<div class="container mr-auto px-4 mt-2">
  <ul class="flex flex-col md:flex-row mb-2">
    <li class="mr-1">
        <a (click)="showUserProfile()" [class.active]="isUserProfile"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"PROFILE" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_local_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showCompanyUsers()" [class.active]="isCompanyUsers"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"USERS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showDepartmentSetup()" [class.active]="isDepartmentSetup"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DEPARTMENT_SETUP" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showGroups()" [class.active]="isGroups"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"PRODUCT_GROUPS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showCompanyInfo()" [class.active]="isCompanyInfo"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"COMPANY_INFO" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showArticles()" [class.active]="isArticles"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"ARTICLES" | translate}}</a>
    </li>
    <li *ngIf="subscription_stamped_SDS && subscribesToFreemium==false && currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user || currentUser.is_maintenance_user)" class="mr-1">
      <a (click)="showStampedSds()" [class.active]="isStampedSds"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SUBSCRIPTION_STAMPED_SDS" | translate}}</a>
    </li>
    <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
      <a (click)="showMiscInfo()" [class.active]="isMisc"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"MISC" | translate}}</a>
    </li>
  </ul>
</div>

<div class="flex flex-col md:flex-row max-w-screen mr-0 md:mr-4 mb-40 md:mb-20" *ngIf="isUserProfile&&currentUser">
  <div class="flex w-full md:w-2/3">
    <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
      <div class="flex flex-col w-full mt-2">
        <div class="mb-4">
          <label class="font-bold mb-1">{{"USERNAME" | translate}}:</label><br>
          <input title="{{'USERNAME' | translate}}" class="w-full border border-gray-300 p-2" type="text" placeholder="{{currentUser.username}}" [required]="true" [disabled]="true">
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"FIRST_NAME" | translate}}:</label><br>
          <input title="{{'FIRST_NAME' | translate}}" class="w-full border border-gray-300 p-2" type="text" placeholder="{{currentUser.username}}" [required]="true" [disabled]="true">
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"LAST_NAME" | translate}}:</label><br>
          <input title="{{'LAST_NAME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="currentUser.last_name" (ngModelChange)="currentUser.last_name = $event" [ngModelOptions]="{standalone: true}" type="text">
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"EMAIL" | translate}}:</label><br>
          <input *ngIf="currentUser.email!='null'" title="{{'EMAIL' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="currentUser.email" (ngModelChange)="currentUser.email = $event" [ngModelOptions]="{standalone: true}" type="text">
          <input *ngIf="currentUser.email=='null'" placeholder="-" type="text">
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"PHONE" | translate}}:</label><br>
          <input *ngIf="currentUser.phone!='null'" class="w-full border border-gray-300 p-2" title="{{'PHONE' | translate}}" [(ngModel)]="currentUser.phone" (ngModelChange)="currentUser.phone = $event" [ngModelOptions]="{standalone: true}" type="text">
          <input *ngIf="currentUser.phone=='null'" placeholder="-" type="text">
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"LANGUAGE" | translate}}: </label><br>
          <div class="relative inline-block text-left w-full z-20" appDropdown>
            <button title="{{'LANGUAGE' | translate}}" type="button" class="button-white border justify-between order-gray-300 w-full">
              <label *ngIf="currentLanguage=='sv'" class="mt-1">Svenska</label>
              <label *ngIf="currentLanguage=='en'" class="mt-1">English</label>
              <label *ngIf="currentLanguage=='fi'" class="mt-1">Suomi</label>
              <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <div id="dropdownMenu" class="hidden dropdown-menu right-1" #dropdownMenuInside>
                <div class="py-1">
                  <div class="dropdown-menu-item" (click)="setLanguage('fi')" data-value="fi" tabindex="1">Suomi</div>
                  <div class="dropdown-menu-item" (click)="setLanguage('sv')" data-value="sv" tabindex="2">Svenska</div>
                  <div class="dropdown-menu-item" (click)="setLanguage('en')" data-value="en" tabindex="3">English</div>
                </div>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label class="font-bold mb-1">{{"PASSWORD" | translate}}:</label><br>
          <input *ngIf="currentUser&&currentUser.password!='null'"
            [readonly]="!(currentUser && ((currentUser.is_local_assignee || currentUser.is_local_admin || currentUser.is_admin ||
            currentUser.is_useradmin) || currentUser.is_super_user))" class="w-full border border-gray-300 p-2" [(ngModel)]="currentUser.password" (ngModelChange)="currentUser.password = $event" [ngModelOptions]="{standalone: true}" type="text" placeholder="********">
        </div>
      </div>
      <div class="flex flex-row justify-center mb-10">
        <button class="button-green" title="{{'SAVE' | translate}}" (click)="saveCurrentUser()">
          <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
          <span class="text-lg">{{ 'SAVE' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="flex w-full md:w-1/3">
    <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="cataloguetable">
        <thead class="bg-white">
            <tr>
              <th class="table-row-header">{{"USER_ROLES" | translate}}</th>
              <th class="table-row-header"></th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td class="table-row-column">{{"USERADMIN" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser && currentUser.is_useradmin">
              <img class="w-8 h-8" title="{{'USERADMIN' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
          <tr>
            <td class="table-row-column">{{"ADMIN" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser && currentUser.is_admin">
              <img class="w-8 h-8" title="{{'ADMIN' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
          <tr>
            <td class="table-row-column">{{"LOCAL_ASSIGNEE" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser && currentUser.is_local_assignee">
              <img class="w-8 h-8" title="{{'LOCAL_ASSIGNEE' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
          <tr>
            <td class="table-row-column">{{"LOCAL_ADMIN" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser && currentUser.is_local_admin">
              <img class="w-8 h-8" title="{{'LOCAL_ADMIN' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
          <tr>
            <td class="table-row-column">{{"MAINTENANCE_USER" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser && currentUser.is_maintenance_user">
              <img class="w-8 h-8" title="{{'MAINTENANCE_USER' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
          <tr>
            <td class="table-row-column">{{"LOCAL_USER" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser && currentUser.is_local_user">
              <img class="w-8 h-8" title="{{'LOCAL_USER' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
          <tr *ngIf="currentUser.is_super_user">
            <td class="table-row-column">{{"READONLY_USER" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser && currentUser.is_readonly_user">
              <img class="w-8 h-8" title="{{'READONLY_USER' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
          <tr>
            <td class="table-row-column">{{"GUEST" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser && currentUser.is_guest">
              <img class="w-8 h-8" title="{{'GUEST' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
          <tr>
            <td class="table-row-column">{{"USER" | translate}}</td>
            <td class="table-row-column" *ngIf="currentUser&&!currentUser.is_useradmin&&!currentUser.is_admin&&!currentUser.is_local_assignee&&!currentUser.is_local_admin&&
                       !currentUser.is_local_user&&!currentUser.is_super_user&&!currentUser.guest&&!currentUser.is_maintenance_user&&!currentUser.is_readonly_user">
                       <img class="w-8 h-8" title="{{'GUEST' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
            </td>
          </tr>
        </tbody>
    </table>
    </div>
  </div>
</div>

