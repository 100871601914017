import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class SupportService {

  getSupportDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService) { }

  mail(object) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/support", object, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getSupportDataUpdated.next(response);
      },
      error: error => {
        this.getSupportDataUpdated.next(null);
      }
    });
  }

  infoMail(object) {
    let token = localStorage.getItem('token');

    // Token is not used in some instances and cant be null with Authorization
    if(token==null){
      token = '123456';
    }

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post<any>(BackendUrl + "/infomail", object, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getSupportDataUpdated.next(response);
      },
      error: error => {
        this.getSupportDataUpdated.next(null);
      }
    });
  }

  forgotPassword(object) {
    let token = localStorage.getItem('token');
    token = '123';
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.post<any>(BackendUrl + "/forgotpassword", object, {headers: headers}).pipe(take(1)).subscribe({next: response => {
        this.getSupportDataUpdated.next(response);
      },
      error: error => {
        this.getSupportDataUpdated.next(null);
      }
    });
  }

  public getSupportDataListener() {
    return this.getSupportDataUpdated.asObservable();
  }
}
