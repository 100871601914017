import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ReportService } from 'src/app/services/report.service';
import { ProductService } from 'src/app/services/product.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ModalSendMailComponent } from 'src/app/modals/modal-send-mail/modal-send-mail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { MaterialListService } from 'src/app/services/material-list.service';
import { SareqService } from "src/app/services/sareq.service";
import { UserService } from 'src/app/services/user.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-report-sds',
  templateUrl: './report-sds.component.html',
  styleUrls: ['./report-sds.component.css']
})
export class ReportSdsComponent implements OnInit {

  dtOptions: any = {};

  @Input() reportInfo;

  itemsPerPage: number = 10;
  pageNumber: number = 1;
  reportInformation: any = [];
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  currentLanguage: any;
  subscribesToClassification: any;
  subscribesToTransport: any;
  subscribesToSIN: any;
  subscribesToSLL: any;
  subscribesToBASTA: any;
  listAlldepartments: any;
  loadingData: boolean = false;
  productsToPrint: any;
  amountInArray: any = 0;
  toggleDoc: boolean = false;
  sortField: string;
  sortAscending: boolean = true;
  sortOrder=['asc','asc','asc','asc'];
  restriktionList: any;
  tempReportInformationProducts: any;
  downLoadFile: boolean = false;
  downLoadFileId: any;

  reportSearch = {
    all:"",
    riskPrio:"",
    restrictionList:""
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private utilService: UtilService,
              private authService: AuthService,
              private reportService: ReportService,
              private productService: ProductService,
              private translate: TranslateService,
              private organisationService: OrganisationService,
              public activeModal: NgbActiveModal,
              private sareqService: SareqService,
              private formBuilder:FormBuilder,
              private modalService: NgbModal,
              private userService: UserService,
              private materialListService: MaterialListService) { }

  ngOnInit(): void {

    this.reportInformation = {
      start_date: new Date("2015 1 January"),
      date: new Date(),
      reportType: "",
      name: "",
      id: "",
      includeSubDepartments: false,
      registerProducts: false,
      product: false
    };

    this.reportInformation = this.reportInfo;
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToClassification = this.getSubscription('subscription_classification');
    this.subscribesToTransport = this.getSubscription('subscription_transport');
    this.subscribesToSIN = this.getSubscription('subscription_sin');
    this.subscribesToSLL = this.getSubscription('subscription_sll');
    this.subscribesToBASTA = this.getSubscription('subscription_basta');

    this.currentLanguage = localStorage.getItem('usedLanguage');
    switch(this.currentLanguage) {
      case 'sv':
        this.currentLanguage = 0;
      break;

      case 'en':
        this.currentLanguage = 1;
      break;

      case 'fi':
        this.currentLanguage = 2;
      break;

      default:
        this.currentLanguage = 0;
      break;
    }

    this.getAllDepartments();
    this.getReport();
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    let departName = this.utilService.departFullName(department.departmentid, this.listAlldepartments);
    department.fullName = departName;
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  addToPrint(){
    for (var i = 0; i < this.reportInformation.products.length; i++){
        if (this.reportInformation.products[i].selected){
          this.productsToPrint.push(this.reportInformation.products[i]);
        }
    }
    this.amountInArray = this.productsToPrint.length;
  }

  toggleAllDoc(checked) {
    if (checked){
      this.productsToPrint = JSON.parse(JSON.stringify(this.reportInformation.products));

      for(var i = 0; i < this.reportInformation.products.length; i++){
        this.reportInformation.products[i].selected = true;
      }
    } else {
      this.productsToPrint = [];
      for(var i = 0; i < this.reportInformation.products.length; i++){
        this.reportInformation.products[i].selected = false;
      }
    }
  }

  toggle(checked, index) {
    if (checked){
      this.reportInformation.products[index].selected = true;
    } else {
      this.reportInformation.products[index].selected = false;
    }
  }

  mailModal() {
    this.loadingData = true;
    this.productsToPrint=[];
    this.addToPrint();

    if (this.productsToPrint.length == 0) {
      this.loadingData = false;
      Swal.fire( this.translate.instant( "COULD_NOT_CREATE_REPORT" ),
        this.translate.instant( "NO_PRODUCTS_CHOOSEN" ),
        "error");
      this.loadingData = false;
      return;
    }

    var sareqIds = _.uniq(this.productsToPrint.map(function (a) {return a.sareq_id}));
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    if (new Date(this.reportInfo.date) < oneMonthAgo) {
      this.getFileIdByDate(this.productsToPrint);
    } else {
    }

    const modalRef = this.modalService.open(ModalSendMailComponent);
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((email) => {
      this.materialListService.createMultipleSDSLink(sareqIds);
      this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
        next: data => {
          if (data.file_id == null) {}
          else {
            var projectInfo=[];
            var idArray = [];
            var idArray2 = [];
            idArray = _.uniq(this.productsToPrint.map(function (a) {return a.sareq_id;}));
            idArray2 = this.productsToPrint.map(function (a) {
                return {
                    "id":a.sareq_id,
                    "name":a.name,
                    "supplier_name":a.supplier_name,
                    "sds_file_id":a.sds_file_id}
                }
            );
            idArray2 = _.uniqBy(idArray2,'id');
            projectInfo['idArray'] =   idArray;
            projectInfo['idArray2'] =   idArray2;
            projectInfo['receiver'] = email.email.email;
            projectInfo['name'] = "SDB";
            projectInfo['multiple_sds'] = data.file_id;
            this.materialListService.sendSDBAsMail(projectInfo);
              this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
                next: info => {
                  if (info == null) {
                    Swal.fire("Error", "Mailet was not sent!", "error");
                    this.loadingData = false;
                  }
                  else {
                    if (email.copyToSelf === true) {
                      projectInfo['receiver'] = this.currentUser.email;
                      this.materialListService.sendSDBAsMail(projectInfo);
                      this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
                        next: data => {
                        },
                        error: error => {
                          console.log("Error: ", error);
                          this.loadingData = false;
                        }
                      })
                    }
                    Swal.fire("Ok", "Mailet was sent: " + projectInfo['receiver'], "success");
                    this.loadingData = false;
                  }
                },
                error: error => {
                  console.log("Error: ", error);
                  Swal.fire("Error", "Mailet was not sent!", "error");
                  this.loadingData = false;
                }
              })
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire("Error", "Mailet was not sent!", "error");
          this.loadingData = false;
        }
      });
    });
  }

  printSds() {
    this.loadingData = true;
    this.productsToPrint=[];
    this.addToPrint();
    if (this.productsToPrint.length == 0) {
      this.loadingData = false;
      Swal.fire( this.translate.instant( "COULD_NOT_CREATE_REPORT" ),
        this.translate.instant( "NO_PRODUCTS_CHOOSEN" ),
        "error");
      this.loadingData = false;
      return;
    }

    var idArray = [];
    idArray = this.productsToPrint.map(function (a) {
      return a.sareq_id;
    });
    var date = this.reportInformation.date;
    this.productService.getMultipleSdsAsTrustedResourceUrlBySareqIdArray(idArray,date);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response == null) {
          Swal.fire(this.translate.instant("COULD_NOT_CREATE_REPORT"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
          this.loadingData = false;
        } else {
          this.productService.getMultipleSdsBySareqIdArray(response.file_id);
          this.productService.getProductDataListener().pipe(take(1)).subscribe({
            next: pdfContent => {
              if (pdfContent == null) {
                Swal.fire(this.translate.instant("COULD_NOT_CREATE_REPORT"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
                this.loadingData = false;
              } else {
                if (response.file_id) {
                // var popup = window.open('');
                // popup.location.href = pdfContent;
                var opened=window.open(pdfContent,"_blank");
                if(!opened){
                  window.location.href=pdfContent;
                }
                this.loadingData = false;
              } else if (response.file_url) {
                this.downLoadFile = true;
                this.downLoadFileId = response.file_url;
                this.loadingData = false;
              }
            }
            },
            error: error => {
              console.log("Error: ", error);
              this.loadingData = false;
              Swal.fire(this.translate.instant("COULD_NOT_CREATE_REPORT"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
            }
          })
        }
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData = false;
        Swal.fire(this.translate.instant("COULD_NOT_CREATE_REPORT"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  getFileIdByDate(sareqList){
    this.sareqService.getFileIdByDate(sareqList,this.reportInformation.date);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: responce => {
        if (responce == null) {}
        else
        {
          this.productsToPrint.forEach( product => {
              const matchingResponse = responce.find(r => r.sareq_id === product.sareq_id);
              if (matchingResponse && matchingResponse.sds_file_id !== product.sds_file_id) {
                product.sds_file_id = matchingResponse.sds_file_id;
              }
          });
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  getReport() {

    this.loadingData = true;

    if (this.reportInfo) {

      var reportDate = new Date(this.reportInfo.date);
      reportDate = this.utilService.adjustDateForTimezoneOffset(reportDate);

      var info = {
        reportType: this.reportInfo.reportType.url,
        start_date: reportDate,
        date: reportDate,
        sub: this.reportInfo.includeSubDepartments,
        actual: this.reportInfo.Actual
      }

      this.reportService.getProducts(info);
      this.reportService.getReportDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products == null) {
            this.loadingData = false;
          }
          else {
            this.reportInformation.products = products;

            var ids = [];
            for (var i = 0; i < this.reportInformation.products.length; i++) {
                ids.push({id: this.reportInformation.products[i].sareq_id, lang: this.currentLanguage});
            }

            for (var i = 0; i < this.reportInformation.products.length; i++ ) {
                if( this.reportInformation.products[i].override != null) {
                  this.reportInformation.products.splice(i, 1);
                }
            }

            this.loadingData = false;

            if (this.currentLanguage != 0) {
              this.productService.checkForOtherLanguages(this.organisation.id, ids);
              this.productService.getProductDataListener().pipe(take(1)).subscribe({
                next: otherLangs => {
                  if (otherLangs == null) {}
                  else {
                    for (var i = 0; i < this.reportInformation.products.length; i++){
                      for (var j = 0; j < otherLangs.length; j++) {
                        if (this.reportInformation.products[i].sareq_id == otherLangs[j].override) {

                            this.reportInformation.products[i].name = otherLangs[j].name;
                            this.reportInformation.products[i].sds_file_id = otherLangs[j].sds_file_id;
                            this.reportInformation.products[i].overridden = true;
                            this.reportInformation.products[i].origProdId = this.reportInformation.products[i].id;
                            this.reportInformation.products[i].id = otherLangs[j].id;
                        }
                      }
                    }
                  }
                },
                error: error => {
                  console.log("Error: ", error);
                  this.loadingData = false;
                }
              })
            } else {
              for ( var i = 0; i < this.reportInformation.products.length; i++ ) {
                if (this.reportInformation.products[i].override != undefined ) {
                  this.reportInformation.products.splice( i, 1 );
                }
              }
            }
            for (var i = 0; i < this.reportInformation.products.length; i++) {
              this.departFullName(this.reportInformation.products[i]);
            }
            this.getRestrictionLists();
            setTimeout(() => {
              this.tempReportInformationProducts = _.cloneDeep(this.reportInformation.products);
              this.loadingData = false;
            }, 1000);
          }
          this.getItemsPerPage();
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire({
            title: this.translate.instant("NO_PRODUCTS"),
            text: this.translate.instant("NO_PRODUCTS_WARNING"),
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK"),
            closeOnConfirm: true
          });
          this.loadingData = false;
        }
      })
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
  }

  remove() {
    this.activeModal.close(ReportSdsComponent);
  }

  filterByType(type:string){
    this.reportInformation.products = this.tempReportInformationProducts;
    if(this.reportSearch.all==''){
      this.reportSearch.all=null;
    }

    if(this.reportSearch.riskPrio==''){
      this.reportSearch.riskPrio=null;
    }

    if(this.reportSearch.restrictionList==''){
      this.reportSearch.restrictionList=null;
    }

    if(this.reportSearch.all!=null){
      this.reportInformation.products=this.reportInformation.products.filter(o => JSON.stringify(o).toLowerCase().indexOf(this.reportSearch.all.toLowerCase())>-1);
    }

    if ( this.reportSearch.riskPrio !== null && this.reportSearch.riskPrio !== undefined) {
      this.reportInformation.products = this.reportInformation.products.filter(o =>
        o.risk_prio !== null && o.risk_prio !== undefined && o.risk_prio.toString().toLowerCase().indexOf(this.reportSearch.riskPrio.toString().toLowerCase()) > -1
      );
    }

    if ( this.reportSearch.restrictionList !== null && this.reportSearch.restrictionList !== undefined) {
      this.reportInformation.products = this.reportInformation.products.filter(o =>
        o.restrictionListText !== null && o.restrictionListText !== undefined && o.restrictionListText.toString().toLowerCase().indexOf(this.reportSearch.restrictionList.toString().toLowerCase()) > -1
      );
    }
  }

  getRestrictionLists() {
    this.productService.getRestriktionListAllAdded(this.selectedOrganisation.id);
    this.productService.getProductRestrictionDataListener().pipe(take(1)).subscribe({
      next: info => {
        if (info == null) {}
        else {
          this.restriktionList = info;
          for (var productLength = 0; productLength < this.reportInformation.products.length; productLength++){
            this.reportInformation.products[productLength]['restrictionListText'] = "";
          }

          for (var infoLength = 0; infoLength < info.length; infoLength++) {
            for (var productLength = 0; productLength < this.reportInformation.products.length; productLength++){
              if (this.reportInformation.products[productLength] != null) {
                if (this.reportInformation.products[productLength].sareq_id == info[infoLength].Sareq_id){
                  let restrictionListText = "";
                    if (info[infoLength].list_amv_a_sum > 0){
                      this.reportInformation.products[productLength].list_amv_a = [];
                      this.reportInformation.products[productLength].list_amv_a = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_AMV_A" )+" ";
                    }

                    if (info[infoLength].list_amv_b_sum > 0){
                      this.reportInformation.products[productLength].list_amv_b = [];
                      this.reportInformation.products[productLength].list_amv_b = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_AMV_B" )+" ";
                    }
                    if (info[infoLength].list_reach_14_sum > 0){
                      this.reportInformation.products[productLength].list_reach_14 = [];
                      this.reportInformation.products[productLength].list_reach_14 = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_REACH_14" )+" ";
                    }

                    if (info[infoLength].list_reach_17_sum > 0){
                      this.reportInformation.products[productLength].list_reach_17 = [];
                      this.reportInformation.products[productLength].list_reach_17 = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_REACH_17" )+" ";
                    }

                    if (info[infoLength].list_reach_candidate_sum > 0){
                      this.reportInformation.products[productLength].list_reach_candidate = [];
                      this.reportInformation.products[productLength].list_reach_candidate = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_REACH_CANDIDATE" )+" ";
                    }

                    if (this.subscribesToBASTA && info[infoLength].list_BASTA_sum > 0){
                      this.reportInformation.products[productLength].list_BASTA = [];
                      this.reportInformation.products[productLength].list_BASTA = "1";
                      restrictionListText += this.translate.instant("RESTRICTION_LIST_BASTA" )+" ";
                    }

                    if (info[infoLength].list_chem_prio_p_sum > 0){
                      this.reportInformation.products[productLength].list_chem_prio_p = [];
                      this.reportInformation.products[productLength].list_chem_prio_p = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_CHEM_PRIO_P" )+" ";
                    }

                    if (info[infoLength].list_chem_prio_u_sum > 0){
                      this.reportInformation.products[productLength].list_chem_prio_u = [];
                      this.reportInformation.products[productLength].list_chem_prio_u = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_CHEM_PRIO_U" )+" ";
                    }

                    if (info[infoLength].list_water_directive_sum > 0){
                      this.reportInformation.products[productLength].list_water_directive = [];
                      this.reportInformation.products[productLength].list_water_directive = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_WATER_DIRECTIVE" )+" ";
                    }
                    if (this.subscribesToSIN && info[infoLength].list_sin_sum > 0){
                      this.reportInformation.products[productLength].list_sin = [];
                      this.reportInformation.products[productLength].list_sin = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_SIN" )+" ";
                    }
                    if (this.subscribesToSLL && info[infoLength].list_sll_sum > 0){
                      this.reportInformation.products[productLength].list_sll = [];
                      this.reportInformation.products[productLength].list_sll = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_SLL" )+" ";
                  }

                    if (info[infoLength].list_chem_limit_sum > 0){
                      this.reportInformation.products[productLength].list_chem_limit = [];
                      this.reportInformation.products[productLength].list_chem_limit = "1";
                      restrictionListText += this.translate.instant( "RESTRICTION_LIST_REACH_CANDIDATE" )+" ";
                    }
                    this.reportInformation.products[productLength]['restrictionListText'] = restrictionListText;
                    continue;
                }
              }
            }
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  sortItem(type:string,sortId:number){
    let tableItem, order;
    order=this.sortOrder[sortId];
    order=='asc'?this.sortOrder[sortId]='desc':this.sortOrder[sortId]='asc';
    if(order=='asc'){
      this.reportInformation.products = _.orderBy(
        this.reportInformation.products,
        [
          (item) =>
            typeof item[type] === "string"
              ? item[type].toLowerCase()
              : item[type],
        ],
        ["asc"]
      );
    } else if(order=='desc'){
      this.reportInformation.products = _.orderBy(
        this.reportInformation.products,
        [
          (item) =>
            typeof item[type] === "string"
              ? item[type].toLowerCase()
              : item[type],
        ],
        ["desc"]
      );
    }
  }
  downloadFile ( link ) {
    this.loadingData = true;
    this.reportService.getMultiplePDF ( link, 'sds' );
    this.reportService
      .getMultipleSDSListener ( )
      .pipe ( take ( 1 ) )
      .subscribe ( {
        next: ( pdfContent ) => {
          if ( pdfContent == null ) {
            Swal.fire (
              this.translate.instant ( "COULD_NOT_GET_SDS" ),
              this.translate.instant ( "COULD_NOT_GET_SDS_WARNING" ),
              "error"
            );
            this.loadingData = false;
          } else {
            var file: any, fileURL: string, contentType: string;
            contentType = "application/pdf";
            file = new Blob ( [pdfContent], { type: contentType } );
            fileURL = URL.createObjectURL ( file );
            const downloadLink = document.createElement( 'a' );
            downloadLink.href = fileURL;
            downloadLink.download = 'file.pdf';
            document.body.appendChild ( downloadLink );
            downloadLink.click ( );
            document.body.removeChild ( downloadLink );
            URL.revokeObjectURL( fileURL );
            this.loadingData = false;
            this.downLoadFile = false;
          }
        },
        error: ( error ) => {
          Swal.fire(
            this.translate.instant ( "COULD_NOT_GET_SDS" ),
            this.translate.instant ( "COULD_NOT_GET_SDS_WARNING" ),
            "error"
          );
          this.loadingData = false;
        },
      });
    }
}

