import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { ExposuresService } from 'src/app/services/exposures.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-exposures',
  templateUrl: './exposures.component.html',
  styleUrls: ['./exposures.component.css']
})
export class ExposuresComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any;
  sortDirection: any = null;
  sortValue: any = null;
  exposures: any = [];
  includeSubDepartments: boolean = true;

  exposureSearch = {
    all:""
  };

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private userService: UserService,
    private router: Router,
    private exposuresService: ExposuresService) { }

  ngOnInit(): void {

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    this.sortDirection = localStorage.getItem('sortDirection', );
    if (this.sortDirection == null)
    {
      this.sortDirection = 'asc';
    }

    this.sortValue = localStorage.getItem('sortValue', );
    if (this.sortValue == null)
    {
      this.sortValue = 'name';
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.loadingData = true;

    this.getAllDepartments();
    this.getExposures();

    // Change department
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      this.ngOnInit();
    })
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              this.itemsPerPage = 10;
              return;
            }
          } else {
            this.itemsPerPage = 10;
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  editExposure(exposure) {
    this.router.navigate(['/exposureedit', exposure.id]);
  }

  getExposures() {
    if (this.includeSubDepartments == false) {
      this.exposuresService.getAll();
      this.exposuresService.getExposuresDataListener().pipe(take(1)).subscribe({
        next: exposures => {
          if ((exposures == null) || exposures.length == 0) {
            for (var n = 0; n < exposures.length; n++) {
              if (exposures[n].department !== undefined && exposures[n].department !== null) {
                exposures[n].departmentfullname = this.departFullName(exposures[n].department);
                exposures[n].departmentname = this.getLastDepartmentName(exposures[n].departmentfullname);
              }
            }
            this.exposures = exposures;
            this.loadingData = false;
          }
          else {
            for (var n = 0; n < exposures.length; n++) {
              if (exposures[n].department !== undefined && exposures[n].department !== null) {
                exposures[n].departmentfullname = this.departFullName(exposures[n].department);
                exposures[n].departmentname = this.getLastDepartmentName(exposures[n].departmentfullname);
              }
            }
            this.exposures = exposures;
            if (this.sortDirection === 'desc') {
              this.exposures = _.orderBy(this.exposures, ['type', this.sortValue]).reverse();
            }
            if (this.sortDirection === 'asc') {
              this.exposures = _.orderBy(this.exposures, ['type', this.sortValue]);
            }
            this.loadingData = false;
          }
          this.getItemsPerPage();
        },
        error: error => {
          this.loadingData = false;
          console.log("Error: ", error);
        }
      })
    } else {
      var subDepartmentsIds = this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
      this.exposuresService.getAllIncludingSubdeps(subDepartmentsIds);
      this.exposuresService.getExposuresDataListener().pipe(take(1)).subscribe({
        next: exposures => {
          if ((exposures == null) || exposures.length == 0) {
            for (var n = 0; n < this.exposures.length; n++) {
              if (exposures[n].department !== undefined && exposures[n].department !== null) {
                exposures[n].departmentfullname = this.departFullName(exposures[n].department);
                exposures[n].departmentname = this.getLastDepartmentName(exposures[n].departmentfullname);
              }
            }
            this.exposures = exposures;
            this.loadingData = false;
          }
          else {
            for (var n = 0; n < this.exposures.length; n++) {
              if (exposures[n].department !== undefined && exposures[n].department !== null) {
                exposures[n].departmentfullname = this.departFullName(exposures[n].department);
                exposures[n].departmentname = this.getLastDepartmentName(exposures[n].departmentfullname);
              }
            }
            this.exposures = exposures;
            if (this.sortDirection === 'desc') {
              this.exposures = _.orderBy(this.exposures, ['type', this.sortValue]).reverse();
            }
            if (this.sortDirection === 'asc') {
              this.exposures = _.orderBy(this.exposures, ['type', this.sortValue]);
            }
            this.loadingData = false;
          }
          this.getItemsPerPage();
        },
        error: error => {
          this.loadingData = false;
          console.log("Error: ", error);
        }
      })
    }
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  sortHeader(value) {
    if (this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
    }
    else
    {
      this.sortDirection = 'asc';
    }

    localStorage.setItem('sortDirection', this.sortDirection);
    localStorage.setItem('sortValue', value);
    this.ngOnInit();
  }

  exposureSelected(exposure) {
    if (exposure.department != this.selectedDepartment.id) {
      this.organisationService.changeDepartmentById(exposure.department);
                    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                      this.utilService.departmentChangeClicked(this.router.url);
                      localStorage.setItem('departmentchanged','set');
                      this.router.navigate(['/exposureedit', exposure.id]).then(() => { window.location.reload(); });
                    })
    } else {
      this.router.navigate(['/exposureedit', exposure.id]);
    }

  }

  addExposure() {
    this.router.navigate(['/exposureadd']);
  }

  logout() {
    this.authService.logout();
  }

  onToggleShowSubDepartments() {
    this.includeSubDepartments = !this.includeSubDepartments;
    this.getExposures();
  }

  getLastDepartmentName(fulldepartmentname: string): string {
    return fulldepartmentname.split('/').pop() || fulldepartmentname;
  }
}
