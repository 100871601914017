<div class="flex flex-col bg-white max-w-screen rounded overflow-hidden shadow-lg m-4 p-7 mb-40">
  <span class="text-2xl font-bold text-center mb-8">{{"CONTROL_PANEL" | translate}}</span>
  <div class="flex flex-col md:flex-row">
    <div *ngIf="(currentUser.is_super_user||currentUser.is_maintenance_user||currentUser.is_readonly_user)" (click)="gotoSareq()" class="flex bg-cdoccontrablue m-4 shadow-lg p-2 w-full md:w-1/4 justify-center items-center cursor-pointer">
        <div>
          <span class="text-lg font-bold text-gray-700">{{"SAREQ" | translate}}</span>
        </div>
    </div>
    <div *ngIf="(currentUser.is_super_user||currentUser.is_maintenance_user)" (click)="gotoSubstances()" class="flex bg-cdoccontrablue shadow-lg p-2 w-full md:w-1/4 m-4 justify-center items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">{{"SUBSTANCES" | translate}}</span>
      </div>
    </div>
    <div *ngIf="(currentUser.is_super_user)" (click)="gotoOrganisation()" class="flex bg-cdoccontrablue shadow-lg p-2 w-full md:w-1/4 justify-center m-4 items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">{{"ORGANISATIONS" | translate}}</span>
      </div>
    </div>
    <div *ngIf="(currentUser.is_super_user||currentUser.is_maintenance_user)" (click)="gotoManufacturers()" class="flex bg-cdoccontrablue shadow-lg p-2 w-full md:w-1/4 justify-center m-4 items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">{{"MANUFACTURERS" | translate}}</span>
      </div>
    </div>
  </div>
  <div class="flex flex-col md:flex-row">
    <div (click)="gotoNewFreemiumRegistration()" *ngIf="(currentUser.is_super_user)" class="flex bg-cdoccontrablue m-4 shadow-lg p-2 w-full md:w-1/4 justify-center items-center cursor-pointer">
        <div>
          <span class="text-lg font-bold text-gray-700">{{"NEW_REGISTRATION" | translate}}</span>
        </div>
    </div>
    <div *ngIf="(currentUser.is_super_user)" (click)="gotoAllProductApplications()" class="flex bg-cdoccontrablue m-4 shadow-lg p-2 w-full md:w-1/4 justify-center items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">{{"ALL_PRODUCT_APPLICATIONS" | translate}}</span>
      </div>
  </div>
    <div *ngIf="(currentUser.is_super_user)" (click)="gotoKebnekaise()" class="flex bg-cdoccontrablue shadow-lg p-2 w-full md:w-1/4 justify-center m-4 items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">Kebnekaise</span>
      </div>
    </div>
    <div *ngIf="(currentUser.is_super_user)" (click)="gotoCoordinator()" class="flex bg-cdoccontrablue shadow-lg p-2 w-full md:w-1/4 justify-center m-4 items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">{{"COORDINATOR" | translate}}</span>
      </div>
    </div>
  </div>
  <div class="flex flex-col md:flex-row">
    <div *ngIf="(currentUser.is_super_user)" (click)="gotoRestriction()" class="flex bg-cdoccontrablue m-4 shadow-lg p-2 w-full md:w-1/4 justify-center items-center cursor-pointer">
        <div>
          <span class="text-lg font-bold text-gray-700">{{"RESTRICTION_LISTS" | translate}}</span>
        </div>
    </div>
    <div *ngIf="(currentUser.is_super_user)" (click)="gotoNews()" class="flex bg-cdoccontrablue shadow-lg p-2 w-full md:w-1/4 m-4 justify-center items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">{{"NEWS" | translate}}</span>
      </div>
    </div>
    <div *ngIf="(currentUser.is_super_user)" (click)="gotoTransportinfo()" class="flex bg-cdoccontrablue shadow-lg p-2 w-full md:w-1/4 justify-center m-4 items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">{{"TRANSPORTINFO" | translate}}</span>
      </div>
    </div>
    <div *ngIf="(currentUser.is_super_user)" (click)="gotoSubstitutinfo()" class="flex bg-cdoccontrablue shadow-lg p-2 w-full md:w-1/4 justify-center m-4 items-center cursor-pointer">
      <div>
        <span class="text-lg font-bold text-gray-700">{{"SUBSTITUTION" | translate}}</span>
      </div>
    </div>
  </div>
</div>


