import { Component, OnInit } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { CatalogueService } from 'src/app/services/catalogue.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ProductService } from "src/app/services/product.service";
import { CoordinatorService } from "src/app/services/coordinator.service";
import { SubstitutionService } from "src/app/services/substitution.service";
import { AssessmentChemicalService } from "src/app/services/assessment-chemical.service";
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css'],
  providers: [DatePipe]
})

export class DashboardAdminComponent implements OnInit{

  currentUser: any;
  loadingChangedProducts: boolean = false;
  getProductsIsClicked: boolean = false;
  errorDate = "";
  changedProducts: any[] = [];
  changedProductsHeaders: any;
  changedProductsFrom;
  changedProductsTo;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;

  constructor(private organisationService: OrganisationService, private translate: TranslateService,
    private utilService: UtilService, private catalogueService: CatalogueService,
    private authService: AuthService, private productService: ProductService,
    private coordinatorService: CoordinatorService, private assessmentChemicalService: AssessmentChemicalService,
    private substitutionService: SubstitutionService, private router: Router, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.changedProductsFrom = new Date(new Date().getTime() - (100 * 24 * 60 * 60 * 1000));
    this.changedProductsTo = new Date();
    this.changedProducts = [];

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
  }

  logout() {
    this.authService.logout();
  }

  tabDashboard() {
    this.router.navigate(['/dashboard']);
  }

  tabDashboardAdmin() {
    this.router.navigate(['/dashboardadmin']);
  }

  getProducts(){
    this.getProductsIsClicked = true;
    this.errorDate = "";
    this.changedProductsFrom = this.datePipe.transform(this.changedProductsFrom,'yyyy-MM-dd');
    this.changedProductsTo = this.datePipe.transform(this.changedProductsTo,'yyyy-MM-dd');
    if( (this.changedProductsFrom.substring(0,4)>1899 && this.changedProductsFrom.substring(0,4)<2100)&&
        (this.changedProductsTo.substring(0,4)>1899 && this.changedProductsTo.substring(0,4)<2100)&&
        this.changedProductsFrom.replaceAll("-","")<this.changedProductsTo.replaceAll("-",""))
    {

    } else {
      this.changedProductsFrom = new Date(new Date().getTime() - (100 * 24 * 60 * 60 * 1000))
      this.changedProductsTo = new Date();
      this.errorDate = "Ogiltigt datum";
      return;
    }
    var allSubdepartments=this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
    this.loadingChangedProducts=true;
    this.productService.getChangedProducts(this.organisation.id,allSubdepartments,this.changedProductsFrom,this.changedProductsTo);
    this.productService.getChangedProductsDataListener().pipe(take(1)).subscribe({
      next: info => {
        if (info == null) {this.loadingChangedProducts=false;}
        else {
          this.loadingChangedProducts = false;
          this.changedProducts = this.checkForChanges(info);
        }
      },
      error: error => {
        this.loadingChangedProducts=false;
        console.log("Error: ", error);
      }
    })
  }

  checkForChanges(products){
    var productsArray=[];
    var text=[];
    var date=[];
    var clp=[];
    var clpRemoved=[];
    var protective=[];
    var protectiveRemoved=[];
    var n = 1;
    products.forEach(element => {
      text = [];
      clp = [];
      clpRemoved = [];
      protective=[];
      protectiveRemoved=[];
      date = [];
  
      // Risknivå
      if(element.risk_prio!=element.risk_prio_history){text.push("Risknivå ändrad från "+element.risk_prio_history+" till "+element.risk_prio+" ")}
  
      // Pictogram
      if(element.clp_is_toxic!=element.clp_is_toxic_history){
        if(element.clp_is_toxic==1&&element.clp_is_toxic_history!=1){
          clp.push("/images/pictograms/clp_skull-tiny.png");
        }
        if(element.clp_is_toxic_history==1&&element.clp_is_toxic!=1){
          clpRemoved.push("/images/pictograms/clp_skull-tiny.png");
        }
      }
      if(element.clp_is_dangerous_to_the_environment!=element.clp_is_dangerous_to_the_environment_history){
        if(element.clp_is_dangerous_to_the_environment==1&&element.clp_is_dangerous_to_the_environment_history!=1){
          clp.push("/images/pictograms/clp_pollution-tiny.png");
        }
        if(element.clp_is_dangerous_to_the_environment_history==1&&element.clp_is_dangerous_to_the_environment!=1){
          clpRemoved.push("/images/pictograms/clp_pollution-tiny.png");
        }
      }
      if(element.clp_is_gas_under_pressure!=element.clp_is_gas_under_pressure_history){
        if(element.clp_is_gas_under_pressure==1&&element.clp_is_gas_under_pressure_history!=1){
          clp.push("/images/pictograms/clp_bottle-tiny.png");
        }
        if(element.clp_is_gas_under_pressure_history==1&&element.clp_is_gas_under_pressure!=1){
          clpRemoved.push("/images/pictograms/clp_bottle-tiny.png");
        }
      }
      if(element.clp_is_corrosive!=element.clp_is_corrosive_history){
        if(element.clp_is_corrosive==1&&element.clp_is_corrosive_history!=1){
          clp.push("/images/pictograms/clp_acid-tiny.png");
        }
        if(element.clp_is_corrosive_history==1&&element.clp_is_corrosive!=1){
          clpRemoved.push("/images/pictograms/clp_acid-tiny.png");
        }
      }
      if(element.clp_is_explosive!=element.clp_is_explosive_history){
        if(element.clp_is_explosive==1&&element.clp_is_explosive_history!=1){
          clp.push("/images/pictograms/clp_explosion-tiny.png");
        }
        if(element.clp_is_explosive_history==1&&element.clp_is_explosive!=1){
          clpRemoved.push("/images/pictograms/clp_explosion-tiny.png");
        }
      }
      if(element.clp_is_flammable!=element.clp_is_flammable_history){
        if(element.clp_is_flammable==1&&element.clp_is_flammable_history!=1){
          clp.push("/images/pictograms/clp_flame-tiny.png");
        }
        if(element.clp_is_flammable_history==1&&element.clp_is_flammable!=1){
          clpRemoved.push("/images/pictograms/clp_flame-tiny.png");
        }
      }
      if(element.clp_is_caution!=element.clp_is_caution_history){
        if(element.clp_is_caution==1&&element.clp_is_caution_history!=1){
          clp.push("/images/pictograms/clp_silhouete-tiny.png");
        }
        if(element.clp_is_caution_history==1&&element.clp_is_caution!=1){
          clpRemoved.push("/images/pictograms/clp_silhouete-tiny.png");
        }
      }
      if(element.clp_is_oxidising!=element.clp_is_oxidising_history){
        if(element.clp_is_oxidising==1&&element.clp_is_oxidising_history!=1){
          clp.push("/images/pictograms/clp_roundflame-tiny.png");
        }
        if(element.clp_is_oxidising_history==1&&element.clp_is_oxidising!=1){
          clpRemoved.push("/images/pictograms/clp_roundflame-tiny.png");
        }
      }
      if(element.clp_is_longer_term_health_hazards!=element.clp_is_longer_term_health_hazards_history){
        if(element.clp_is_longer_term_health_hazards==1&&element.clp_is_longer_term_health_hazards_history!=1){
          clp.push("/images/pictograms/clp_exclamation-tiny.png");
        }
        if(element.clp_is_longer_term_health_hazards_history==1&&element.clp_is_longer_term_health_hazards!=1){
          clpRemoved.push("/images/pictograms/clp_exclamation-tiny.png");
        }
      }
      if(element.kifs_is_harmful!=element.kifs_is_harmful_history){
        if(element.kifs_is_harmful==1&&element.kifs_is_harmful_history!=1){
          clp.push("/images/pictograms/kifs_korscol-tiny.png");
        }
        if(element.kifs_is_harmful_history==1&&element.kifs_is_harmful!=1){
          clpRemoved.push("/images/pictograms/kifs_korscol-tiny.png");
        }
      }
      if(element.kifs_is_toxic!=element.kifs_is_toxic_history){
        if(element.kifs_is_toxic==1&&element.kifs_is_toxic_history!=1){
          clp.push("/images/pictograms/kifs_giftcol-tiny.png");
        }
        if(element.kifs_is_toxic_history==1&&element.kifs_is_toxic!=1){
          clpRemoved.push("/images/pictograms/kifs_giftcol-tiny.png");
        }
      }
      if(element.kifs_is_corrosive!=element.kifs_is_corrosive_history){
        if(element.kifs_is_corrosive==1&&element.kifs_is_corrosive_history!=1){
          clp.push("/images/pictograms/kifs_fratcol-tiny.png");
        }
        if(element.kifs_is_corrosive_history==1&&element.kifs_is_corrosive!=1){
          clpRemoved.push("/images/pictograms/kifs_fratcol-tiny.png");
        }
      }
      if(element.kifs_is_dangerous_to_the_environment!=element.kifs_is_dangerous_to_the_environment_history){
        if(element.kifs_is_dangerous_to_the_environment==1&&element.kifs_is_dangerous_to_the_environment_history!=1){
          clp.push("/images/pictograms/kifs_miljocol-tiny.png");
        }
        if(element.kifs_is_dangerous_to_the_environment_history==1&&element.kifs_is_dangerous_to_the_environment!=1){
          clpRemoved.push("/images/pictograms/kifs_miljocol-tiny.png");
        }
      }
      if(element.kifs_is_flammable!=element.kifs_is_flammable_history){
        if(element.kifs_is_flammable==1&&element.kifs_is_flammable_history!=1){
          clp.push("/images/pictograms/kifs_brandcol-tiny.png");
        }
        if(element.kifs_is_flammable_history==1&&element.kifs_is_flammable!=1){
          clpRemoved.push("/images/pictograms/kifs_brandcol-tiny.png");
        }
      }
      if(element.kifs_is_flammable!=element.kifs_is_flammable_history){
        if(element.kifs_is_flammable==1&&element.kifs_is_flammable_history!=1){
          clp.push("/images/pictograms/kifs_brandcol-tiny.png");
        }
        if(element.kifs_is_flammable_history==1&&element.kifs_is_flammable!=1){
          clpRemoved.push("/images/pictograms/kifs_brandcol-tiny.png");
        }
      }
      if(element.kifs_is_explosive!=element.kifs_is_explosive_history){
        if(element.kifs_is_explosive==1&&element.kifs_is_explosive_history!=1){
          clp.push("/images/pictograms/kifs_explcol-tiny.png");
        }
        if(element.kifs_is_explosive_history==1&&element.kifs_is_explosive!=1){
          clpRemoved.push("/images/pictograms/kifs_explcol-tiny.png");
        }
      }
      if(element.kifs_is_oxidising!=element.kifs_is_oxidising_history){
        if(element.kifs_is_oxidising==1&&element.kifs_is_oxidising_history!=1){
          clp.push("/images/pictograms/kifs_oxidcol-tiny.png");
        }
        if(element.kifs_is_oxidising_history==1&&element.kifs_is_oxidising!=1){
          clpRemoved.push("/images/pictograms/kifs_oxidcol-tiny.png");
        }
      }
      if(element.kifs_is_warning!=element.kifs_is_warning_history){
        if(element.kifs_is_warning==1&&element.kifs_is_warning_history!=1){
          clp.push("/images/pictograms/kifs_varncol-tiny.png");
        }
        if(element.kifs_is_warning_history==1&&element.kifs_is_warning!=1){
          clpRemoved.push("/images/pictograms/kifs_varncol-tiny.png");
        }
      }
  
      // Skyddssymboler
      if(element.image_mask!=element.image_mask_history){
        if(element.image_mask==1&&element.image_mask_history!=1){
          protective.push("/images/protective/mask-tiny.png");
        }
        if(element.image_mask_history==1&&element.image_mask!=1){
          protectiveRemoved.push("/images/protective/mask-tiny.png");
        }
      }
      if(element.image_boots!=element.image_boots_history){
        if(element.image_boots==1&&element.image_boots_history!=1){
          protective.push("/images/protective/boots-tiny.png");
        }
        if(element.image_boots_history==1&&element.image_boots!=1){
          protectiveRemoved.push("/images/protective/boots-tiny.png");
        }
      }
      if(element.image_breath!=element.image_breath_history){
        if(element.image_breath==1&&element.image_breath_history!=1){
          protective.push("/images/protective/breath-tiny.png");
        }
        if(element.image_breath_history==1&&element.image_breath!=1){
          protectiveRemoved.push("/images/protective/breath-tiny.png");
        }
      }
      if(element.image_clothes!=element.image_clothes_history){
        if(element.image_clothes==1&&element.image_clothes_history!=1){
          protective.push("/images/protective/clothes-tiny.png");
        }
        if(element.image_clothes_history==1&&element.image_clothes!=1){
          protectiveRemoved.push("/images/protective/clothes-tiny.png");
        }
      }
      if(element.image_gloves!=element.image_gloves_history){
        if(element.image_gloves==1&&element.image_gloves_history!=1){
          protective.push("/images/protective/gloves-tiny.png");
        }
        if(element.image_gloves_history==1&&element.image_gloves!=1){
          protectiveRemoved.push("/images/protective/gloves-tiny.png");
        }
      }
      if(element.image_glasses!=element.image_glasses_history){
        if(element.image_glasses==1&&element.image_glasses_history!=1){
          protective.push("/images/protective/glasses-tiny.png");
        }
        if(element.image_glasses_history==1&&element.image_glasses!=1){
          protectiveRemoved.push("/images/protective/glasses-tiny.png");
        }
      }
  
      date.push("Revisionsdatum: "+element.cdoc_date.substring(0,10));
      date.push("Datum ändringar gjordes: "+element.created_date.substring(0,10));
      productsArray.push({
        'itemnumber':n,
        'name':element.name,
        'value':text,
        'date':date,
        'clp':clp,
        'clpRemoved':clpRemoved,
        'protective':protective,
        'protectiveRemoved':protectiveRemoved
      });
      n++;
    });
    return productsArray;
  }
}
