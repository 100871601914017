import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class AssessmentChemicalService {

  getAssessmentChemicalDataUpdated = new Subject<any>();
  getAssessmentProductsDataUpdated = new Subject<any>();
  getAssessmentChemicalDocDataUpdated = new Subject<any>();
  getAssessmentChemicalUpdateDataUpdated = new Subject<any>();
  getAssessmentChemicalDataUpdated2 = new Subject<any>();
  getAssessmentChemicalPictureDataUpdated = new Subject<any>();
  getAssessmentChemicalDepartmentDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService, private authService: AuthService,
              private productConversionsService: ProductConversionsService, private sanitizer: DomSanitizer,
              private translate: TranslateService) { }

  getSingle(id) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + '/organisations/' + orgId + '/asschemical/' + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentChemicalDataUpdated2.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDataUpdated2.next(null);
        }
      });
    }
  }

  getProductsWithSuborganisations() {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + '/asschemical/' + orgId + '/productsub', {headers: headers}).pipe(take(1)).subscribe({next: response => {
          this.productConversionsService.convertPictogramsOnProductArray(response);
          this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
          this.getAssessmentProductsDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentProductsDataUpdated.next(null);
        }
      });
    }
  }

  createChemical(info) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post<any>(BackendUrl + '/organisations/' + orgId + '/asschemical', info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentChemicalDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDataUpdated.next(null);
        }
      });
    }
  }

  updateChemical(id, info) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.put<any>(BackendUrl + '/organisations/' + orgId + '/asschemical/' + id, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentChemicalUpdateDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalUpdateDataUpdated.next(null);
        }
      });
    }
  }

  deleteChemical(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.delete(BackendUrl + "/organisations/" + orgId + "/asschemical/" + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentChemicalDataUpdated.next(true);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDataUpdated.next(true);
        }
      });
    }
  }

  createLRB(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/asschemical/' + id + "/generateLRB", {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {

          var file = new Blob([response], {type: 'application/pdf'});
          this.getAssessmentChemicalDocDataUpdated.next(file);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDocDataUpdated.next(null);
        }
      });
    }
  }

  updateChemicalStatusRisk(id, info) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.put<any>(BackendUrl + '/organisations/' + orgId + '/asschemical/status/' + id, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentChemicalDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDataUpdated.next(null);
        }
      });
    }
  }

  getProducts() {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + '/asschemical/' + orgId + '/products', {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.productConversionsService.convertPictogramsOnProductArray(response);
          this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
          this.getAssessmentProductsDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentProductsDataUpdated.next(null);
        }
      });
    }
  }

  getNumberOfRiskAssessments() {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post<any>(BackendUrl + '/organisations/' + orgId + '/getNumberOfRiskAssessments',null, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentChemicalDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDataUpdated.next(null);
        }
      });
    }
  }

  copyChemicalToDepartment(departmentid,chemical) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      var info = {'departmentid': departmentid,'chemical': chemical}

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post<any>(BackendUrl + '/organisations/' + orgId + '/copychemicaltodepartment', info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAssessmentChemicalDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDataUpdated.next(null);
        }
      });
    }
  }

  getLrbAsTrustedResourceUrl(orgId, id, entrance) {

    var file, fileURL;
    var thisid = id.chemical_id;

    // Wrong id otherwise
    if (entrance == 'product')
    {
        for (var i = 0; i < id.riskfileorganisation.length; i++)
        {
            if (id.riskfileorganisation[i] == orgId)
            {
                orgId = id.riskfileorganisation[i];
                thisid = id.chemical_id[i];
            }
        }
    }

    if (entrance == 'chemical_riskassessment')
    {
        orgId = id.organisation_id;
    }

    if (entrance == 'productitem')
    {
      orgId = orgId;
      thisid = id.LRB_id;
    }

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/organisations/' + orgId + '/asschemical/' + thisid + "/lrb", {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {
      if(response==null||response.byteLength==0){
        this.getAssessmentChemicalDataUpdated.next(null);
      } else {
        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getAssessmentChemicalDataUpdated.next(fileURL);
      }
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getAssessmentChemicalDataUpdated.next(null);
      }
    });
  }

  getFileAsTrustedResourceUrl(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/asschemical/picture/' + id, {headers: headers, responseType: "blob"}).pipe(take(1)).subscribe({next: response => {

          var file = new Blob([response], {type: 'application/image'});
          if (file.size !== 0)
          {
            const unsafeImg = URL.createObjectURL(file);
            var image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
            this.getAssessmentChemicalPictureDataUpdated.next(image);
          }
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalPictureDataUpdated.next(null);
        }
      });
    }
  }

  getcDocFileWithId(fileId) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/asschemical/file/' + fileId, {headers: headers, responseType: "blob"}).pipe(take(1)).subscribe({next: response => {

          var file = new Blob([response], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          this.getAssessmentChemicalDataUpdated.next(fileURL);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDataUpdated.next(null);
        }
      });
    }
  }

  getLRBInfo ( fileId ) {
    let token = localStorage.getItem('token');
    if (localStorage.getItem('selectedDepartment', ) != null ) {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null) {
        this.authService.logout();
      }
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get ( BackendUrl + '/asschemical/' + fileId + '/LRBInfo',
        {headers: headers})
        .pipe(take(1))
        .subscribe( { next: response => {
          this.convertProtectiveGearImagesToProduct(response);
          this.getAssessmentChemicalDataUpdated.next( response );
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAssessmentChemicalDataUpdated.next(null);
        }
      });
    }
  }

  convertProtectiveGearImagesToProduct(product){

      var protectiveImages = [
        {"name": "LRB_image_mask", "filename": "mask"},
        {"name": "LRB_image_boots", "filename": "boots"},
        {"name": "LRB_image_breath", "filename": "breath"},
        {"name": "LRB_image_clothes", "filename": "clothes"},
        {"name": "LRB_image_gloves", "filename": "gloves"},
        {"name": "LRB_image_glasses", "filename": "glasses"}
      ];
      var tempProtective = [];
      for(var i = 0; i < protectiveImages.length; i++){
          if(protectiveImages[i].name =="LRB_image_mask"){
              protectiveImages[i]['header'] = this.translate.instant("Ansiktsskydd");
              protectiveImages[i]['description'] = this.translate.instant("Bra att använda när det är risk för stänk och man behöver skydda hela ansiktet. ");
          }
          if(protectiveImages[i].name =="LRB_image_boots"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsskor");
              protectiveImages[i]['description'] = this.translate.instant("Speciella skor kan behövas vid arbete med vissa typer av kemikalier, oftast i våta miljöer. Exempel: Antistatiska; Kemikaliebeständiga; Vattentäta osv.");
          }
          if(protectiveImages[i].name =="LRB_image_breath"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsmask");
              protectiveImages[i]['description'] = this.translate.instant("Ska användas på allmän ventilation inte är tillräcklig. Till exempel vid arbete i slutna utrymmen. Skyddar mot luftburna ångor eller partiklar beroende på filtertyp. Val av filtertyp kan hittas på produktinformationen i cDoc-tjänsten eller i säkerhetsdatabladet.");
          }
          if(protectiveImages[i].name =="LRB_image_clothes"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddskläder");
              protectiveImages[i]['description'] = this.translate.instant("Vanliga arbetskläder räknas inte som skyddskläder i riskbedömningen. Skyddar mot hudkontakt för exempelvis frätande kemikalier eller kemikalier som får hälsoeffekt vid hudkontakt. Val av skyddskläder kan hittas på produktinformationen i cDoctjänsten eller i säkerhetsdatabladet. Skyddskläder kan också gälla en fysikalisk fara såsom högtrycksanordningar.");
          }
          if(protectiveImages[i].name =="LRB_image_gloves"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddshandskar");
              protectiveImages[i]['description'] = this.translate.instant("Val av skyddshandske kan hittas på produktinformationen i cDoctjänsten under avsnittet ”Skyddsutrustning” eller i säkerhetsdatabladet. Skyddar bra mot till exempel uttorkning och produkter som kan orsaka allergi vid hudkontakt.");
          }
          if(protectiveImages[i].name =="LRB_image_glasses"){
              protectiveImages[i]['header'] = this.translate.instant("Skyddsglasögon");
              protectiveImages[i]['description'] = this.translate.instant("Vanligast förekommande skyddet eftersom ögat ofta inte går att reparera vid skada. Ex: Tättslutande skyddsglasögon");
          }
          if(product[protectiveImages[i].name]){
              tempProtective.push(protectiveImages[i]);
          }
      }

      if(tempProtective.length == 0 && !product.no_protection){
          tempProtective.push({"name": "no_protection", "filename": "no_protection", "header": "Skyddsutrustning okänd", "description": ""});
      }
      product['protectiveArray'] = tempProtective;
      return product;
  }

  fetchLabels(organisationId, productId, size, thistoken) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("data", size);
    params = params.append("token", thistoken);

    this.http.get(BackendUrl + "/asschemical/" + organisationId + "/products/labels/" + productId, {headers: headers, params: params, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {

        var contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        var file = new Blob([response], { type: contentType });
        var fileURL = URL.createObjectURL(file);
        this.getAssessmentChemicalDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getAssessmentChemicalDataUpdated.next(null);
      }
    });
  }

  getLabelForPrinter(organisationId, productId, size, thistoken) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("data", size);
    params = params.append("token", thistoken);

    this.http.get(BackendUrl + "/asschemical/" + organisationId + "/products/printer/" + productId, {headers: headers, params: params, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {

        var contentType = 'application/pdf';
        var file = new Blob([response], { type: contentType });
        var fileURL = URL.createObjectURL(file);
        this.getAssessmentChemicalDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getAssessmentChemicalDataUpdated.next(null);
      }
    });
  }

  getDepartmentsWhereProductAppearsByProductId(productId, org) {
    if ( org ) {
      var departmentId = JSON.parse(localStorage.getItem('selectedOrganisation', )).id;
    } else {
      var departmentId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
    }

    if (departmentId == null) {
      this.authService.logout();
    }

    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get<any>(BackendUrl + "/asschemical/" + departmentId + "/products/" + productId + "/suborgs", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {
        this.getAssessmentChemicalDepartmentDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401) {
          this.authService.logout();
        }
        this.getAssessmentChemicalDepartmentDataUpdated.next(null);
      }
    });
  }

  public getAssessmentChemicalDataListener() {
    return this.getAssessmentChemicalDataUpdated.asObservable();
  }

  public getAssessmentChemicaUpdateDataListener() {
    return this.getAssessmentChemicalUpdateDataUpdated.asObservable();
  }

  public getAssessmentChemicalDepartmentDataListener() {
    return this.getAssessmentChemicalDepartmentDataUpdated.asObservable();
  }

  public getAssessmentChemicalDocDataListener() {
    return this.getAssessmentChemicalDocDataUpdated.asObservable();
  }

  public getAssessmentChemicalDataListener2() {
    return this.getAssessmentChemicalDataUpdated2.asObservable();
  }

  public getAssessmentChemicalPictureDataListener() {
    return this.getAssessmentChemicalPictureDataUpdated.asObservable();
  }

  public getAssessmentProductsDataListener() {
    return this.getAssessmentProductsDataUpdated.asObservable();
  }
}
