
<div class="bg-white z-10">
    <div class="flex flex-col bg-cdoclightblue w-full relative cursor-pointer" (click)="navigateHome()">
        <a class="flex justify-center p-5" id="navigateHome">
            <img class="w-5/6" src="../../images/LoggacDoc.png" alt="image description">
        </a>
    </div>
        
    <div class="bg-purple-100 min-h-screen w-full relative flex flex-col items-start space-y-2">

        <div class="flex mx-auto mt-4 item-center" *ngIf="currentUser && currentUser.is_super_user">
            <button class="button-cdocblue" (click)="showSelectOrganisationModal()">
                <svg class="nav-org mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 10L21 7M21 7L18 4M21 7H7M6 14L3 17M3 17L6 20M3 17H17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-white text-lg">{{"CHANGE_ORGANISATION" | translate}}</span>
            </button>
        </div>

        <div class="pt-4" *ngIf="!currentUser.is_guest" >
            <a class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isHome" (click)="navigateHome()">
                <img title="{{ 'DASHBOARD' | translate }}" src="images/icons/dashboard-builder-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'DASHBOARD' | translate }}</span>
            </a>
        </div>

        <div *ngIf="subscribesToCatalogue&&!currentUser.is_guest">
            <a id="tourStep21" class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isCatalogue" (click)="gotoCatalogue()">
                <img title="{{ 'SUBSCRIPTION_CATALOGUE' | translate }}" src="images/icons/chemical-plant-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'SUBSCRIPTION_CATALOGUE' | translate }}</span>
            </a>
        </div>

        <div *ngIf="subscribesToProducts">
            <a id="tourStep22" class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isProducts" (click)="gotoProducts()">
                <img title="{{ 'PRODUCTS' | translate }}" src="images/icons/chemical-lab-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'PRODUCTS' | translate }}</span>
            </a>
        </div>

        <div *ngIf="currentUser && (currentUser.is_admin == 1 || currentUser.is_local_admin == 1 ||currentUser.is_local_assignee == 1 || 
                currentUser.is_super_user == 1 || currentUser.is_useradmin == 1) &&
                (subscribesToCoordinator || subscribesToSubstitution || subscribesToExposures || subscribesToReports)"
                class="inline-block text-left hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-2" appDropdown>
            <button type="button" [class.active]="isAdministration" class="text-black flex items-center space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <img title="{{ 'CHEMICAL_MANAGEMENT' | translate }}" src="images/icons/bars-svgrepo-com.svg" class="nav-home">
                <span class="nav-home-text text-gray-600 text-lg font-bold">{{ 'CHEMICAL_MANAGEMENT' | translate }}</span>
                <svg class="w-5 h-5" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                    <path d="M384 192v640l384-320.064z"/>
                </svg>
            </button>
            <div class="hidden dropdown-menu z-40 left-40" #dropdownMenuInside>
                <div class="py-1">
                    <a *ngIf="subscribesToCoordinator"  (click)="goToCoordinator()" id="coordinator" class="dropdown-menu-item" tabindex="1">
                        {{"COORDINATOR" | translate}}
                    </a>
                    <a *ngIf="subscribesToExposures" (click)="goToExposures()" id="exposures" class="dropdown-menu-item" tabindex="2">
                        {{"EXPOSURE_LIST" | translate}}
                    </a>
                    <a *ngIf="subscribesToSubstitution" (click)="goToSubstitution()" id="substitution" class="dropdown-menu-item" tabindex="3">
                        {{"SUBSTITUTION_LINK" | translate}}
                    </a>
                    <a *ngIf="currentUser&&!currentUser.is_local_user&&subscribesToReports" (click)="goToHistory()" id="history" class="dropdown-menu-item" tabindex="4">
                        {{"HISTORY_LINK" | translate}}
                    </a>
                    <a *ngIf="subscribesToProductApplication" (click)="goToProductApplication()" id="productapplication" class="dropdown-menu-item" tabindex="5">
                        {{"PRODUCT_APPLICATION" | translate}}
                    </a>
                </div>
            </div>
        </div>
        
        <div *ngIf="currentUser && (currentUser.is_admin == 1 || currentUser.is_local_admin == 1 ||currentUser.is_local_assignee == 1 || 
                currentUser.is_super_user == 1 || currentUser.is_useradmin == 1) && subscribesToRiskAssessment&&!currentUser.is_guest" 
                class="inline-block text-left hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-2" appDropdown>
            <button type="button" [class.active]="isAssessment" class="text-black flex items-center space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <img title="{{ 'RISK_ASSESSMENTS' | translate }}" src="images/icons/balancing-act-dangerous-balance-risk-svgrepo-com.svg" class="h-7 w-7">
                <span class="nav-home-text text-gray-600 text-lg font-bold">{{ 'RISK_ASSESSMENTS' | translate }}</span>
                <svg class="w-5 h-5" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                    <path d="M384 192v640l384-320.064z"/>
                </svg>
            </button>
            <div class="hidden dropdown-menu z-40 left-40" #dropdownMenuInside>
                <div class="py-1">
                <a (click)="goToAssessment()" id="nav_assessment" class="dropdown-menu-item" tabindex="1">
                    {{"ACTIVITY_RISK" | translate}}
                </a>
                <a (click)="goToAssessmentChemical()" id="nav_assessment_chemicals" class="dropdown-menu-item" tabindex="2">
                    {{"LOCAL_RISK" | translate}}
                </a>
                </div>
            </div>
        </div>

        <div *ngIf="currentUser && !currentUser.is_guest && subscribesToReports">
            <a id="tourStep24" class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isReports" (click)="gotoReports()">
                <img title="{{ 'REPORTS' | translate }}" src="images/icons/chart-line-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'REPORTS' | translate }}</span>
            </a>
        </div>

        <div *ngIf="subscribesToProjects&&!currentUser.is_guest">
            <a id="tourStep25" class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isMaterialList" (click)="gotoMaterialList()">
                <img title="{{ 'PROJECTS' | translate }}" src="images/icons/notes-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'PROJECTS' | translate }}</span>
            </a>
        </div>

        <div *ngIf="currentUser && !currentUser.is_guest && (subscribesToLawLawpoint || subscribesToLawAptor)">
            <a id="tourStep26" class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isLaw" (click)="gotoLaw()">
                <img title="{{ 'LAW_ENFORCEMENT_LINK' | translate }}" src="images/icons/justice-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'LAW_ENFORCEMENT_LINK' | translate }}</span>
            </a>
        </div>

        <div *ngIf="currentUser && !currentUser.is_guest && subscribesToSamtec">
            <a id="tourStep27" class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isSamtec" (click)="gotoSamtec()">
                <img title="{{ 'SAMTEC' | translate }}" src="images/icons/work-case-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'SAMTEC' | translate }}</span>
            </a>
        </div>

        <div *ngIf="subscribesToEducation&&!currentUser.is_guest">
            <a id="tourStep29" class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isEducation" (click)="gotoEducation()">
                <img title="{{ 'ONLINE_ACADEMY' | translate }}" src="images/icons/education-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'ONLINE_ACADEMY' | translate }}</span>
            </a>
        </div>

        <div *ngIf="currentUser&&(currentUser.is_super_user||currentUser.is_maintenance_user||currentUser.is_readonly_user)">
            <a id="tourStep29" class="nav-home-text hover:transform hover:text-black hover:-translate-x-1 transition-transform duration-300 pl-4 pr-4" [class.active]="isControlPanel" (click)="gotoControlPanel()">
                <img title="{{ 'CONTROL_PANEL' | translate }}" src="images/icons/cog-svgrepo-com.svg" class="nav-home">
                <span class="text-lg text-gray-600 font-bold">{{ 'CONTROL_PANEL' | translate }}</span>
            </a>
        </div>
    </div>
</div>