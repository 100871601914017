<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
  <!-- Modal Content -->
  <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
    <div class="flex justify-end">
      <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
    </div>
    <div class="mt-3 text-center">
        <div class="text-gray-900 font-bold">{{"REPORT_SUBSTITUTION" | translate}}</div>
        <!-- Modal Body -->
        <div class="flex flex-col center-items mt-4 p-2">
            <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                <div class="loader"></div>
            </div>

            <div *ngIf="loadingData==false" class="overflow-auto mb-20">
              <table datatable [dtOptions]="dtOptions" class="table">
                  <thead>
                    <tr>
                        <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                        <th class="table-row-header">{{"PRODUCT" | translate}}</th>
                        <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                        <th class="table-row-header">{{"STATUS" | translate}}</th>
                        <th class="table-row-header">{{"DATE" | translate}}</th>
                        <th class="table-row-header">{{"CHEMICAL_CANDIDATES" | translate}}</th>
                        <th class="table-row-header">{{"APPROVED_CHEMICAL_CANDIDATE" | translate}}</th>
                        <th class="table-row-header">{{"ACTION_PRODUCT" | translate}}</th>
                        <th class="table-row-header">{{"ACTION_FOR_REMAINGING_PRODUCT" | translate}}</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let substitution of reportInformation.products">
                        <td class="table-row-column">{{substitution.suppliername}}</td>
                        <td class="table-row-column">{{substitution.sareqname}}</td>
                        <td class="table-row-column">{{substitution.department}}</td>
                        <td class="table-row-column">{{getNameStatus(substitution.substatus)}}</td>
                        <td class="table-row-column">{{substitution.subcreateddate|date:'yyyy-MM-dd'}}</td>
                        <td class="table-row-column">
                          <ul  class="list-disc list-inside" *ngFor="let names of substitution.names">
                            <li><span [ngClass]="names.approved==1?'greenbox':'redbox'">{{names.name}}</span></li>
                          </ul>
                        </td>
                        <td class="table-row-column">
                          <span *ngFor="let names of substitution.names">
                            <span *ngIf="names.approved==1">{{names.name}}</span>
                          </span>
                        </td>
                        <td class="table-row-column">{{getNameKeep(substitution)}}</td>
                        <td class="table-row-column">{{getNameKeep2(substitution)}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
</div>
