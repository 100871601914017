<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
    <!-- Modal Content -->
    <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"REPORT_STYREN" | translate}}</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-4 p-2">
                <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                    <div class="loader"></div>
                </div>
  
                <div *ngIf="loadingData==false" class="overflow-auto mb-20">
                    <table datatable [dtOptions]="dtOptions" class="table">
                        <thead>
                        <tr>
                            <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                            <th class="table-row-header">{{"PRODUCT" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                            <th class="table-row-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                            <th class="table-row-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                            <th class="table-row-header">{{"UNIT" | translate}}</th>
                            <th class="table-row-header">{{"ARTICLE_NUMBER" | translate}}</th>
                            <th class="table-row-header">{{"COMMON_USE" | translate}}</th>
                            <th class="table-row-header">{{"OBSERVATION" | translate}}</th>
                            <th class="table-row-header">{{"DEPARTMENT_INFO" | translate}}</th>
                            <th class="table-row-header">{{"HYGIENE_LIMITS" | translate}}</th>
                            <th class="table-row-header" *ngIf="subscribesToClassification">{{"CLASSIFICATION" | translate}}</th>
                            <th class="table-row-header">{{"OWN_ARTICLE" | translate}}</th>
                            <th class="table-row-header">{{"DATE" | translate}}</th>
                            <th *ngIf="subscribesToTransport">{{"DANGEROUS_GOODS" | translate}}</th>
                            <th class="table-row-header">{{"SAFETY_EXT_DATA_SHEET" | translate}}</th>
                            <th class="table-row-header">{{"RISK_LEVEL" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let styrenproduct of reportInformation.products">
                            <td class="table-row-column">{{styrenproduct.supname}}</td>
                            <td class="table-row-column">{{styrenproduct.Sname}}</td>
                            <td class="table-row-column">{{departFullName(styrenproduct.Oid)}}</td>
                            <td class="table-row-column">
                                <div class="flex flex-row">
                                    <span *ngFor="let pictogram of styrenproduct.pictograms">
                                        <img title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                                    </span>
                                </div>
                            </td>
                            <td class="table-row-column">{{styrenproduct.amount}}</td>
                            <td class="table-row-column">{{styrenproduct.annual}}</td>
                            <td class="table-row-column">{{styrenproduct.unit}}</td>
                            <td class="table-row-column">{{styrenproduct.supplier_article_number}}</td>
                            <td class="table-row-column">{{styrenproduct.com_use}}</td>
                            <td class="table-row-column">{{styrenproduct.observation}}</td>
                            <td class="table-row-column">{{styrenproduct.department_info}}</td>
                            <td class="table-row-column"><span>{{styrenproduct.hygiene_limits==1?'Ja':'Nej'}}</span></td>
                            <td class="table-row-column" *ngIf="subscribesToClassification">
                                <span *ngFor="let c_phrases of removeItemsWithSameName(styrenproduct.c_phrases)">{{c_phrases}}{{' '}}<br/></span>
                            </td>
                            <td class="table-row-column">{{styrenproduct.customerarticle}}</td>
                            <td class="table-row-column">{{styrenproduct.last_changed | date:'yyyy-MM-dd'}}</td>
                            <td class="table-row-column" *ngIf="!styrenproduct.adr_not_classfied && !styrenproduct.transport_un_nbr && subscribesToTransport">
                                {{"" | translate}}
                            </td>
                            <td class="table-row-column" *ngIf="(styrenproduct.transport_un_nbr && !styrenproduct.adr_not_classfied) && subscribesToTransport">
                                {{"UN"}}{{styrenproduct.transport_un_nbr}}
                            </td>
                            <td class="table-row-column" *ngIf="((!styrenproduct.transport_un_nbr && styrenproduct.adr_not_classfied) || (styrenproduct.transport_un_nbr && styrenproduct.adr_not_classfied)) && subscribesToTransport">
                                {{"NO" | translate}}
                            </td>
                            <td class="table-row-column" *ngIf="styrenproduct.esdb_available===1">
                                {{"YES" | translate}}
                            </td>
                            <td class="table-row-column" *ngIf="styrenproduct.esdb_available===0">
                                {{"NO" | translate}}
                            </td>
                            <td class="table-row-column">{{styrenproduct.risk_prio}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
