import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { OrganisationService } from 'src/app/services/organisation.service';
import { SareqService } from 'src/app/services/sareq.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-sareq-history',
  templateUrl: './modal-sareq-history.component.html',
  styleUrls: ['./modal-sareq-history.component.css']
})
export class ModalSareqHistoryComponent {

  @Input() thisSareq;

  itemsPerPage:number=10;
  pageNumber:number=1;
  sareqItems: any;
  sareqId: any;
  loadingData: boolean = false;

  constructor(public activeModal: NgbActiveModal, 
    private authService: AuthService,
    private sareqService: SareqService,
    private translate: TranslateService) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.sareqId = this.thisSareq.id;
    this.sareqService.getSareqHistory(this.sareqId);
    this.sareqService.getSareqHistoryListener().pipe(take(1)).subscribe({
      next: items => {
        this.sareqItems = items;
        this.loadingData = false;
      },
      error: error => {
        this.loadingData = false;
      }
    })
  }

  getStatus(status){
    var status_text;
      switch (status) {
        case 0:
          status_text = this.translate.instant("SAREQ_STATUS_0");
          return status_text;
          break;

        case 1:
          status_text = this.translate.instant("SAREQ_STATUS_1");
          return status_text;
          break;

        case 2:
          status_text = this.translate.instant("SAREQ_STATUS_2");
          return status_text;
          break;

        case 3:
          status_text = this.translate.instant("SAREQ_STATUS_3");
          return status_text;
          break;

        case 4:
          status_text = this.translate.instant("SAREQ_STATUS_4");
          return status_text;
          break;

        case 5:
          status_text = this.translate.instant("SAREQ_STATUS_5");
          return status_text;
          break;
        default:
          status_text = "";
          return status_text;
          break;
    }
  }

  ngOnDestroy(): void {
  }

  logout() {
    this.authService.logout();
  }
}
