<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-1/3 shadow-lg rounded-md bg-white mb-40">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold text-lg">{{"ADD_PRODUCTS" | translate}}</div>
            <!-- Modal Body -->
            <div class="mt-2">
                <div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
                    <div class="loader"></div>
                </div>
                <div [hidden]="!loadingData" class="flex bg-gray-400 items-center p-2">
                    <div>Import pågår: Klar med {{numberOfIterations?.length}} av {{numberOfObjectItems}} produkter</div>
                </div>
                <table *ngIf="!loadingData" class="table-auto bg-white mb-4 w-full" st-table="orgList" st-safe-src="orgList">
                    <thead>
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                            <th class="table-row-header">{{"AMOUNT" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of notInOrg
                            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };"
                            (click)="product.disabled=!product.disabled" class="tablerow"
                            [ngStyle]="product.disabled && {'background-color': 'red'}" id="modalAddExcelButton1">
                            <td class="table-row-column">{{product.name}}</td>
                            <td class="table-row-column">{{product.manufacturer}}</td>
                            <td class="table-row-column">{{product.amount}} {{product.unit}}</td>
                            <td class="table-row-column"><i class="ui icon trash" style="color:red" area-hidden="true"></i></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <div class="mt-4">
                                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                                previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                            </div>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <!-- Modal Footer -->
            <div *ngIf="!loadingData" class="flex justify-center items-center mt-4">
                <button type="button" 
                    class="button-green" 
                    (click)="save()">
                    {{"SAVE" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
