import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class CatalogueService {

  getCatalogueDataUpdated = new Subject<any>();
  getSingleCatalogueDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService, private authService: AuthService) { }

  AllWithSuborganisations(orgId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get(BackendUrl + '/organisations_k_products/' + orgId + '/sub/',{headers: headers}).pipe(take(1)).subscribe({next: response => {
        this.getCatalogueDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getCatalogueDataUpdated.next(null);
      }
    });
  }
// Get all products with extra information

AllMoreInformation(orgId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get(BackendUrl + '/organisations_k_products/' + orgId,{headers: headers}).pipe(take(1)).subscribe({next: response => {
        this.getCatalogueDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getCatalogueDataUpdated.next(null);
      }
    });
  }

  getSingle(prodId) {
    var orgId = JSON.parse(localStorage.getItem("catalogueDepartment"));
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get(BackendUrl + "/organisations_k_products/" + orgId+"/"+prodId,{headers: headers})
      .pipe(take(1)).subscribe({next: (response) => {
        this.getSingleCatalogueDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getSingleCatalogueDataUpdated.next(null);
      }
    });
  }

  getDepartmentsWhereProductAppearsByCatalogueId(productId) {
    var departmentId = JSON.parse(localStorage.getItem("selectedOrganisation")).main_organisation;
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get(BackendUrl + "/organisations_k_products/" + departmentId + "/" + productId + "/suborgs",{headers: headers})
      .pipe(take(1)).subscribe({next: (response) => {
        this.getCatalogueDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getCatalogueDataUpdated.next(null);
      }
    });
  }

  public getCatalogueDataListener() {
    return this.getCatalogueDataUpdated.asObservable();
  }

  public getSingleCatalogueDataListener() {
    return this.getSingleCatalogueDataUpdated.asObservable();
  }
}

