import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-modal-select-status',
  templateUrl: './modal-select-status.component.html',
  styleUrls: ['./modal-select-status.component.css']
})
export class ModalSelectStatusComponent implements OnInit {
  selectOption = 0;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder, private authService: AuthService) { }

  ngOnInit(): void {}
  ngOnDestroy(): void {}
  saveOption(){
    this.passEntry.emit(this.selectOption);
    this.activeModal.close(ModalSelectStatusComponent);
  }

  selectRadio(selectOption){
    this.selectOption = selectOption;
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalSelectStatusComponent);
  }
}
