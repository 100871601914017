<div class="flex flex-row justify-between">
  <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
    <svg class="cursor-pointer h-7 w-7" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
      <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
    </svg>
  </button>

  <button (click)="addSubstance()" class="mt-4 mr-4 button-cdocblue">
    <svg class="cursor-pointer h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
        <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
    <span class="text-lg text-white">{{"ADD" | translate}}</span>
  </button>
</div>

<div class="px-4 py-2 mt-1 mb-40 md:mb-20">
  <div class="overflow-x-auto">
      <table class="w-full mb-4 bg-white border border-gray-400 table-auto" st-table="allSubstances" st-safe-src="allSubstances">
          <thead class="bg-white">
            <tr>
                <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-col md:flex-row">
                    <div class="mt-4 mb-2 mr-4">
                        <input st-search="" type="search" [(ngModel)]="substanceSearch.all" class="prompt" (click)="substanceSearch.all='';filterSearchItems();"
                            class="flex items-center px-4 py-2 border border-gray-400 rounded-full prompt" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                    </div>
                    <div class="flex items-center mt-4 mb-2 mr-4 space-x-2">
                      <input title="{{'SHOW_MORE_SEARCH' | translate}}" type="checkbox" class="w-5 h-5 form-checkbox text-cdocblue" (click)="showMoreSearchOptions(moreSearchOptions)">
                      <span class="mr-1 text-sm">{{"SHOW_MORE_SEARCH" | translate}}</span>
                    </div>
                    <div class="mt-4 mb-4">
                      <button type="button" *ngIf=" this.allSubstances && (this.allSubstances.length > 0)"
                            class="sticky top-0 left-0 ml-20 button-green"
                            title="{{'EXPORTDATATOEXCEL' | translate}}" (click)="exportToExcelClicked()">
                        <span class="text-lg text-white">{{ 'EXPORTDATATOEXCEL' | translate }}</span>
                      </button>
                    </div>
                  </div>
                  <div class="flex flex-col flex-wrap mt-2 md:flex-row" *ngIf="moreSearchOptions">
                    <div class="mt-4 mb-2 mr-4">
                      <input st-search="" type="search" (input)="filterSearchItems()" [(ngModel)]="substanceSearch.name" class="prompt"
                          class="flex items-center px-4 py-2 border border-gray-400 rounded-full prompt" placeholder="{{'SEARCH_NAME' | translate}} ..." />
                    </div>
                    <div class="mt-4 mb-2 mr-4">
                      <input st-search="" type="search" (input)="filterSearchItems()" [(ngModel)]="substanceSearch.cas" class="prompt"
                          class="flex items-center px-4 py-2 border border-gray-400 rounded-full prompt" placeholder="{{'SEARCH_CAS' | translate}} ..." />
                    </div>
                    <div class="mt-4 mb-2 mr-4">
                      <input st-search="" type="search" (input)="filterSearchItems()" [(ngModel)]="substanceSearch.eg" class="prompt"
                          class="flex items-center px-4 py-2 border border-gray-400 rounded-full prompt" placeholder="{{'SEARCH_EG' | translate}} ..." />
                    </div>
                    <div class="mt-4 mb-2 mr-4">
                      <input st-search="" type="search" (input)="filterSearchItems()" [(ngModel)]="substanceSearch.sareq_id" class="prompt"
                          class="flex items-center px-4 py-2 border border-gray-400 rounded-full prompt" placeholder="{{'SEARCH_SAREQ_ID' | translate}} ..." />
                    </div>
                    <div class="mt-4 mb-4">
                      <input st-search="" type="search" (input)="filterByType('restrictionList')" [(ngModel)]="substanceSearch.restrictionList" class="prompt"
                          class="flex items-center px-4 py-2 border border-gray-400 rounded-full prompt" placeholder="{{'SEARCH_RESTRICTION_LIST' | translate}} ..." />
                    </div>
                  </div>
                </th>
            </tr>
            <tr>
              <th class="table-row-header sort-header" st-sort="name" (click)="sortSubstances('name')">{{"NAME" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="cas" (click)="sortSubstances('cas')">{{"CAS" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="eg" (click)="sortSubstances('eg')">{{"EG" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="sareq.name" (click)="sortSubstances('substance_number')">{{"SAREQ" | translate}}</th>
              <th class="table-row-header sort-header">{{"RESTRICTION_LISTS" | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let substance of allSubstances
                  | filter : substanceSearch.all
                  | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };"
                    (click)="substanceSelected(substance)" class="tablerow">
              <td class="table-row-column">{{substance.name}}</td>
              <td class="table-row-column">{{substance.cas}}</td>
              <td class="table-row-column">{{substance.eg}}</td>
              <td class="table-row-column"><p>S-{{substance.substance_number}}</p></td>
              <td class="table-row-column">
                <ul class="px-4 mt-2 list-disc list-inside">
                  <li *ngIf="substance.list_amv_a=='1'">{{"RESTRICTION_LIST_AMV_A" | translate}}</li>
                  <li *ngIf="substance.list_amv_b=='1'">{{"RESTRICTION_LIST_AMV_B" | translate}}</li>
                  <li *ngIf="substance.list_chem_prio_p=='1'">{{"RESTRICTION_LIST_CHEM_PRIO_P" | translate}}</li>
                  <li *ngIf="substance.list_chem_prio_u=='1'">{{"RESTRICTION_LIST_CHEM_PRIO_U" | translate}}</li>
                  <li *ngIf="substance.list_reach_14=='1'">{{"RESTRICTION_LIST_REACH_14" | translate}}</li>
                  <li *ngIf="substance.list_reach_17=='1'">{{"RESTRICTION_LIST_REACH_17" | translate}}</li>
                  <li *ngIf="substance.list_reach_candidate=='1'">{{"RESTRICTION_LIST_REACH_CANDIDATE" | translate}}</li>
                  <li *ngIf="subscribesToSIN&&substance.list_sin=='1'">{{"RESTRICTION_LIST_SIN" | translate}}</li>
                  <li *ngIf="subscribesToSLL&&substance.list_sll=='1'">{{"RESTRICTION_LIST_SLL" | translate}}</li>
                  <li *ngIf="substance.list_water_directive=='1'">{{"RESTRICTION_LIST_WATER_DIRECTIVE" | translate}}</li>
                  <li *ngIf="subscribesToBASTA&&substance.list_BASTA=='1'">{{"RESTRICTION_LIST_BASTA" | translate}}</li>
                </ul>
            </tr>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="4">
                    <div class="flex flex-row items-center">
                      <div class="relative inline-block mr-20 text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"SUBSTANCES" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" class="dropdown-menu-item" tabindex="3">25</a>
                              </div>
                          </div>
                      </div>
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </div>
                  </td>
              </tr>
          </tfoot>
      </table>
    </div>
</div>
