import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { StaticDataService } from "src/app/services/static-data.service";
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class CoordinatorService {

  getCoordinatorDataUpdated = new Subject<any>();
  getAllCoordinatorDataUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService, private authService: AuthService,
              private staticDataService: StaticDataService) { }

  /**
     * Convert date from string to Date object.
     */
   convertStringToDate(data) {
    var i,
        dataLength;

    if (_.isArray(data)) {
        dataLength = data.length;
        for (i = 0; i < dataLength; i += 1) {
            data[i].done = this.utilService.convertStringToDate(data[i].done);
            data[i].due = this.utilService.convertStringToDate(data[i].due);
        }
    } else if (_.isObject(data)) {
        data.done = this.utilService.convertStringToDate(data.done);
        data.due = this.utilService.convertStringToDate(data.due);
    }

    return data;
  }

  /**
   * Adjust dates for timezone offset
   */
  convertAndTimezoneAdjustDates(data) {

      if (_.isObject(data)) {
          data.done = this.utilService.adjustDateForTimezoneOffset(data.done);
          data.due = this.utilService.adjustDateForTimezoneOffset(data.due);
      }

      return data;
  }

  /**
   * Return objectified status code for a coordinator subject status code, null on error
   */
  getStatusObjectFromStatusNumber(statusNumber) {
      var i,
          availableCoordinatorSubjectStatus = this.staticDataService.getAvailableCoordinatorSubjectStatus(),
          arrLength = availableCoordinatorSubjectStatus.length,
          result = null;

      if (_.isNumber(statusNumber)) {
          if (statusNumber >= 0 && statusNumber <= 4)
          {
              for (i = 0; i < arrLength; i += 1) {
                  if (availableCoordinatorSubjectStatus[i].id === statusNumber) {
                      result = availableCoordinatorSubjectStatus[i];
                      break;
                  }
              }
          } else {
              console.log("convertTodoStatus warning: Invalid status", statusNumber);
          }
      } else {
          console.log("convertTodoStatus warning: Status is not a number: ", statusNumber);
      }

      return result;
  }

  getAll() {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/todos',{headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAllCoordinatorDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAllCoordinatorDataUpdated.next(null);
        }
      });
    }
  }

  getAllSubs() {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/todosSub/',{headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getAllCoordinatorDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getAllCoordinatorDataUpdated.next(null);
        }
      });
    }
  }

  getSingle(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      var orgId2 = localStorage.getItem('coordinatorDepartment');
      if(orgId2&&orgId2!=""){
        orgId=orgId2;
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get(BackendUrl + '/organisations/' + orgId + '/todos/' + id,{headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.convertStringToDate(response);
          this.getCoordinatorDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getCoordinatorDataUpdated.next(null);
        }
      });
    }
  }

  getFileAsTrustedResourceUrl(todoId, fileId) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/todos/' + todoId + "/files/" + fileId, {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {

          // var file = new Blob([response], {type: 'application/pdf'});
          if (response['mime-type'] && response['mime-type'].startsWith("image"))  {
            var file = new Blob([response], {type: response['mime-type']});
          } else {
            var file = new Blob([response], {type: 'application/pdf'});
          }

          var fileURL = URL.createObjectURL(file);
          this.getCoordinatorDataUpdated.next(fileURL);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getCoordinatorDataUpdated.next(null);
        }
      });
    }
  }

  getImageFileAsTrustedResourceUrl(todoId, fileId, mimetype) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/organisations/' + orgId + '/todos/' + todoId + "/files/" + fileId, {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).subscribe({next: response => {
          var file = new Blob([response], {type: mimetype});
          var fileURL = URL.createObjectURL(file);
          this.getCoordinatorDataUpdated.next(fileURL);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getCoordinatorDataUpdated.next(null);
        }
      });
    }
  }

  createCoordinator(info) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post<any>(BackendUrl + '/organisations/' + orgId + '/todos', info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getCoordinatorDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getCoordinatorDataUpdated.next(null);
        }
      });
    }
  }

  updateCoordinator(id, info) {

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.put<any>(BackendUrl + '/organisations/' + orgId + '/todos/' + id, info, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getCoordinatorDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getCoordinatorDataUpdated.next(null);
        }
      });
    }
  }

  deleteCoordinator(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.delete(BackendUrl + "/organisations/" + orgId + "/todos/" + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getCoordinatorDataUpdated.next(true);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getCoordinatorDataUpdated.next(true);
        }
      });
    }
  }

  public getCoordinatorDataListener() {
    return this.getCoordinatorDataUpdated.asObservable();
  }

  public getAllCoordinatorDataListener() {
    return this.getAllCoordinatorDataUpdated.asObservable();
  }
}
