import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})
export class MaterialListService {

  getMaterialDataUpdated = new Subject<any>();

  constructor(private router: Router, private http: HttpClient, private utilService: UtilService, private authService: AuthService) {}

  getAll() {

    let token = localStorage.getItem("token");

    if (localStorage.getItem('selectedDepartment', ) != null)
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }
    }
    else
    {
      this.authService.logout();
    }

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/" + orgId + "/projects", { headers: headers })
      .pipe(take(1)).subscribe({
        next: (projects) => {
          this.getMaterialDataUpdated.next(projects);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getMaterialDataUpdated.next(null);
        },
      });
  }

  getSingleProject(id) {

    let token = localStorage.getItem("token");

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      this.http
        .get<any>(BackendUrl + "/organisations/" + orgId + "/projects/" + id, { headers: headers })
        .pipe(take(1)).subscribe({
          next: (projects) => {
            this.getMaterialDataUpdated.next(projects);
          },
          error: (error) => {
            if (error.status === 401)
            {
              this.authService.logout();
            }
            this.getMaterialDataUpdated.next(null);
          },
      });
    }
  }

  createProject(info) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post(BackendUrl + '/organisations/' + orgId + "/projects", info, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

          this.getMaterialDataUpdated.next(response);
        },
        error: error => {
          this.getMaterialDataUpdated.next(null);
        }

      })
    }
  }

  updateProject(id, info) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.put(BackendUrl + "/organisations/" + orgId + "/projects/" + id, info, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

          this.getMaterialDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getMaterialDataUpdated.next(null);
        }
      });
    }
  }

  deleteProject(id) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.delete(BackendUrl + "/organisations/" + orgId + "/projects/" + id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getMaterialDataUpdated.next(true);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getMaterialDataUpdated.next(true);
        }
      });
    }
  }

  deleteProduct(id, product_id, info) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.delete(BackendUrl + "/organisations/" + orgId + "/projects/" + id + '/products/' + product_id + '/' + info.color + '/' + info.surface, {headers: headers}).pipe(take(1)).subscribe({next: response => {

          this.getMaterialDataUpdated.next(true);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getMaterialDataUpdated.next(true);
        }
      });
    }
  }

  createMultipleSDSLink(sareqIdArray) {

    var i,
    query = {
        sareq_list: []
    };

    if (_.isArray(sareqIdArray)) {

      for (i = 0; i < sareqIdArray.length; i += 1) {
        query.sareq_list.push({sareq_id: sareqIdArray[i]});
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post(BackendUrl + '/sareq/multiple_sds', query, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

        this.getMaterialDataUpdated.next(response);
      },
      error: error => {
        this.getMaterialDataUpdated.next(null);
      }

      })
    }
  }

  sendProjectAsMail(project) {
    let token = localStorage.getItem('token');

    if (localStorage.getItem('rootOrganisation', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('rootOrganisation', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post(BackendUrl + '/organisations/' + orgId + "/projects/mail_project", project, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

          this.getMaterialDataUpdated.next(response);
        },
        error: error => {
          this.getMaterialDataUpdated.next(null);
        }

      })
    }
  }

  getProjectAsPdf(project) {
    let token = localStorage.getItem('token');

    if (localStorage.getItem('rootOrganisation', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('rootOrganisation', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      let params = new HttpParams();
      params = params.append('project', JSON.stringify(project));


      this.http.get(BackendUrl + '/organisations/' + orgId + "/projects/get_project_as_pdf", {headers: headers,params:params,
        responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {

          this.getMaterialDataUpdated.next(response);
        },
        error: error => {
          this.getMaterialDataUpdated.next(null);
        }

      })
    }
  }

  sendSDBAsMail(thislocalproject) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('rootOrganisation', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('rootOrganisation', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      var project={
        "entrance": "SDB",
        "name": thislocalproject.name,
        "number": "",
        "issuer": JSON.parse(localStorage.getItem('currentUser', )).username,
        "project_date": this.utilService.adjustDateForTimezoneOffset(new Date()),
        "mailRecipient": thislocalproject.receiver,
        "comments": "",
        "products": [],
        "receiver": thislocalproject.receiver,
        "multiple_sds": thislocalproject.multiple_sds
      }

      for(var i = 0; i<thislocalproject.idArray2.length;i++){
        project.products.push({"name":thislocalproject.idArray2[i].name,
                                "sds_file_id":thislocalproject.idArray2[i].sds_file_id,
                                "supplier_name":thislocalproject.idArray2[i].supplier_name
        });
      }

      this.http.post(BackendUrl + '/organisations/' + orgId + "/projects/mail_project", project, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

          this.getMaterialDataUpdated.next(response);
        },
        error: error => {
          this.getMaterialDataUpdated.next(null);
        }

      })
    }
  }

  public getMaterialDataListener() {
    return this.getMaterialDataUpdated.asObservable();
  }
}
