import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { take } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})
export class KebnekaiseService {
  getKebnekaiseDataUpdated = new Subject<any>();

  constructor(
    public router: Router,
    private http: HttpClient,
    private utilService: UtilService,
    private authService: AuthService
  ) { }

  getAllKebneKaiseProducts() {
    this.http
      .get<any>(
        BackendUrl + "/organisations_k_products",
        this.utilService.setHeadersAndParamsObject()
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getKebnekaiseDataUpdated.next(response);
        },
        error: (error) => {
          this.getKebnekaiseDataUpdated.next(null);
        },
      });
    }

    getSdsAsTrustedResourceUrl(kebnakaiseId) {
      let token = localStorage.getItem('token');
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      let params = new HttpParams();
      params = params.append("token", token);
      var file,fileURL,contentType;
      this.http.get(BackendUrl + "/organisations_k_products/" + kebnakaiseId + "/sds", {headers: headers, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {
          contentType = 'application/pdf';
          file = new Blob([response], { type: contentType });
          fileURL = URL.createObjectURL(file);
          this.getKebnekaiseDataUpdated.next(fileURL);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getKebnekaiseDataUpdated.next(null);
        }
      });
    }

    updateProductWithId(orgId, id, info) {
      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
    this.http
      .put(BackendUrl + '/organisations_k_products/' + orgId + '/' + id, info, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getKebnekaiseDataUpdated.next(value);
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
    }

    createProductWithId(orgId, info) {
      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
    this.http
      .post(BackendUrl + '/organisations_k_products/' + orgId, info, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getKebnekaiseDataUpdated.next(value);
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
    }

    deleteProductWithId(orgId, id) {
      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
    this.http
      .delete(BackendUrl + '/organisations_k_products/' + orgId + '/' + id, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (value) => {
          this.getKebnekaiseDataUpdated.next(value);
        },
        error: (error) => {
          console.log(error);
          return;
        },
      });
    }

  public getKebnekaiseDataListener() {
    return this.getKebnekaiseDataUpdated.asObservable();
  }
}
