import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { SareqService } from 'src/app/services/sareq.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-restriction',
  templateUrl: './restriction.component.html',
  styleUrls: ['./restriction.component.css']
})
export class RestrictionComponent implements OnInit {
  restrictionfile:any;
  restrictionfileb:any;
  restrictionfileR14:any;
  restrictionfileR17:any;
  restrictionfileRLimit:any;
  restrictionfileRPrio:any;
  restrictionfileRCandidate:any;
  restrictionfileWater:any;
  restrictionfileSLL:any;
  restrictionfileSIN:any;
  restrictionfileBASTA:any;
  restrictionfilePOPS:any;
  restrictionfileROHS:any;

  constructor(
    private router:Router,
    private utilService:UtilService,
    private sareqService:SareqService,
    private authService:AuthService
  ) { }

  ngOnInit(): void {
  }

  uploadRestrictionListAmvA()
  {
    var getRestrictionfile = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfile);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfile]).then(values => {
      this.sareqService.postRestrictionListAmvA(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfile = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfile = null;
        }
      })
    });
  }

  uploadRestrictionListAmvB()
  {
    var getRestrictionfileb = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileb);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileb]).then(values => {
      this.sareqService.postRestrictionListAmvB(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileb = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileb = null;
        }
      })
    });
  }

  uploadRestrictionListR14()
  {
    var getRestrictionfileR14 = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileR14);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileR14]).then(values => {
      this.sareqService.postRestrictionListReach14(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileR14 = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileR14 = null;
        }
      })
    });
  }

  uploadRestrictionListR17()
  {
    var getRestrictionfileR17 = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileR17);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileR17]).then(values => {
      this.sareqService.postRestrictionListReach17(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileR17 = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileR17 = null;
        }
      })
    });
  }

  uploadRestrictionListRLimit()
  {
    var getRestrictionfileRLimit = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileRLimit);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileRLimit]).then(values => {
      this.sareqService.postRestrictionListReachLimit(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileRLimit = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileRLimit = null;
        }
      })
    });
  }

  uploadRestrictionListRPrio()
  {
    var getRestrictionfileRPrio = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileRPrio);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileRPrio]).then(values => {
      this.sareqService.postRestrictionListReachPrio(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileRPrio = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileRPrio = null;
        }
      })
      this.sareqService.postRestrictionListReachPrio_p(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileRPrio = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileRPrio = null;
        }
      })
    });
  }

  uploadRestrictionListRCandidate()
  {
    var getRestrictionfileRCandidate = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileRCandidate);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileRCandidate]).then(values => {
      this.sareqService.postRestrictionListCandidate(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileRCandidate = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileRCandidate = null;
        }
      })
    });
  }

  uploadRestrictionListWater()
  {
    var getRestrictionfileWater = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileWater);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileWater]).then(values => {
      this.sareqService.postRestrictionListWater(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileWater = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileWater = null;
        }
      })
    });
  }

  uploadRestrictionListSLL()
  {
    var getRestrictionfileSLL = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileSLL);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileSLL]).then(values => {
      this.sareqService.postRestrictionListSLL(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileSLL = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileSLL = null;
        }
      })
    });
  }

  uploadRestrictionListSIN()
  {
    var getRestrictionfileSIN = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileSIN);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileSIN]).then(values => {
      this.sareqService.postRestrictionListSIN(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileSIN = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileSIN = null;
        }
      })
    });
  }

  uploadRestrictionListBASTA()
  {
    var getRestrictionfileBASTA = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileBASTA);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileBASTA]).then(values => {
      this.sareqService.postRestrictionListBASTA(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileBASTA = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileBASTA = null;
        }
      })
    });
  }

  uploadRestrictionListPOP()
  {
    var getRestrictionfilePOP = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfilePOPS);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfilePOP]).then(values => {
      this.sareqService.postRestrictionListPOP(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileBASTA = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileBASTA = null;
        }
      })
    });
  }

  uploadRestrictionListROHS()
  {
    var getRestrictionfileROHS = new Promise((resolve, reject) => {
      this.utilService.uploadFile(this.restrictionfileROHS);
      this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
        next: response => {
          resolve(response.filename);
        },
        error: error => {
          console.log("Error: ", error);
          this.utilService.onError(error);
        }
      })
    });

    Promise.all([getRestrictionfileROHS]).then(values => {
      this.sareqService.postRestrictionListROHS(values[0]);
      this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
        next: response => {
          Swal.fire("Ok", "Success");
          this.restrictionfileROHS = null;
        },
          error: error => {
          Swal.fire("Fel med att uppdatera lista", "generiskt fel");
          console.log("Error: ", error);
          this.restrictionfileROHS = null;
        }
      })
    });
  }

  fileChange(event,type) {
    if(type=="AMV_A"){
      this.restrictionfile=event.target.files[0];
    }

    if(type=="AMV_B"){
      this.restrictionfileb=event.target.files[0];
    }

    if(type=="R14"){
      this.restrictionfileR14=event.target.files[0];
    }

    if(type=="R17"){
      this.restrictionfileR17=event.target.files[0];
    }

    if(type=="RLimit"){
      this.restrictionfileRLimit=event.target.files[0];
    }

    if(type=="RPrio"){
      this.restrictionfileRPrio=event.target.files[0];
    }

    if(type=="RCandidate"){
      this.restrictionfileRCandidate=event.target.files[0];
    }

    if(type=="Water"){
      this.restrictionfileWater=event.target.files[0];
    }

    if(type=="SLL"){
      this.restrictionfileSLL=event.target.files[0];
    }

    if(type=="SIN"){
      this.restrictionfileSIN=event.target.files[0];
    }

    if(type=="BASTA"){
      this.restrictionfileBASTA=event.target.files[0];
    }

    if(type=="POP"){
      this.restrictionfilePOPS=event.target.files[0];
    }

    if(type=="ROHS"){
      this.restrictionfileROHS=event.target.files[0];
    }
  }

  gotoControlPanel(){
    this.router.navigate(['/controlpanel']);
  }

  logout() {
    this.authService.logout();
  }
}
