import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UtilService } from "src/app/services/util.service";
import { StaticDataService } from "src/app/services/static-data.service";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { Injectable, Injector } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: "root",
})
export class OrganisationService {
  departmentChanged = new Subject<any>();
  organisationChanged = new Subject<any>();
  getOrganisationDataUpdated = new Subject<any>();
  getOrganisationDataDocumentUpdated = new Subject<any>();
  getOrganisationNavDataUpdated = new Subject<any>();
  getOrganisationSubDataUpdated = new Subject<any>();
  getOrganisationNewsDataUpdated = new Subject<any>();
  getOrganisationOrgDataUpdated = new Subject<any>();
  getOrganisationAllDepartmentNamesDataUpdated = new Subject<any>();
  getOrganisationDataUpdatedDepartmentFiles = new Subject<any>();
  setProductList = new Subject<any>();
  setCoordinatorList = new Subject<any>();
  setRiskAssessmentActivityList  = new Subject<any>();
  setRiskAssessmentList = new Subject<any>();
  setCatalogueList = new Subject<any>();
  setSubstitutionList = new Subject<any>();

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private staticDataService: StaticDataService
  ) {}

  getAllcDocFiles() {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/cdoc/cdoc_files/", {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (documents) => {
          this.getOrganisationDataDocumentUpdated.next(documents);
        },
        error: (error) => {
          this.getOrganisationDataDocumentUpdated.next(null);
        },
      });
  }

  getcDocFileWithId(fileId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get(BackendUrl + "/organisations/cdoc/cdoc_files/" + fileId, {
        headers: headers,
        responseType: "arraybuffer",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          var file = new Blob([response], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          this.getOrganisationDataUpdated.next(fileURL);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  deletecDocFileWithId(fileId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .delete(BackendUrl + "/organisations/cdoc/cdoc_files/" + fileId + "/", {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  addcDocFile(fileInfo) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .post<any>(BackendUrl + "/organisations/cdoc/cdoc_files/", fileInfo, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  addEachFile(object) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .post<any>(BackendUrl + "/organisations/addEachFile/", object, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getOrganisation(orgId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get(BackendUrl + "/organisations/" + orgId + "/tree", {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationOrgDataUpdated.next(response[0]);
        },
        error: (error) => {
          this.getOrganisationOrgDataUpdated.next(null);
        },
      });
  }

  getAllDepartmentNames(mainOrganisation) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    if (mainOrganisation) {
      var params = new HttpParams().set("mainOrganisation", mainOrganisation);

      this.http
        .get<any>(
          BackendUrl +
            "/organisations/" +
            mainOrganisation.id +
            "/getAllDepartmentNames",
          { headers: headers, params: params, responseType: "json" }
        )

        .pipe(take(1)).subscribe({
          next: (response) => {
            this.getOrganisationAllDepartmentNamesDataUpdated.next(response);
          },
          error: (error) => {
            this.getOrganisationAllDepartmentNamesDataUpdated.next(null);
          },
        });
      }
  }

  getAllNews() {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/cdoc/cdoc_news/", {
        headers: headers,
      })

      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationNewsDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationNewsDataUpdated.next(null);
        },
      });
  }

  addNews(newsitem) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .post<any>(BackendUrl + "/organisations/cdoc/cdoc_news/",newsitem, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationNewsDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationNewsDataUpdated.next(null);
        },
      });
  }

  deleteNews(Id) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .delete<any>(BackendUrl + "/organisations/cdoc/cdoc_news/"+Id, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationNewsDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationNewsDataUpdated.next(null);
        },
      });
  }

  SingleWithSuborganisation(orgId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/" + orgId + "/tree", {
        headers: headers,
      })

      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getAllCompanies() {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/companies", { headers: headers })

      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationNavDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationNavDataUpdated.next(null);
        },
      });
  }

  getAllSubscriptionInfo() {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/subscriptioninfo", { headers: headers })

      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationSubDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationSubDataUpdated.next(null);
        },
      });
  }

  // So that the organisationtree isnt abused
  getAllCompanies2() {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/companies", { headers: headers })

      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getNewFreemiumCompanies() {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get<any>(BackendUrl + '/frm/register/list', {headers: headers})

    .pipe(take(1)).subscribe({next: response => {
        this.getOrganisationDataUpdated.next(response);
      },
      error: error => {
        this.getOrganisationDataUpdated.next(null);
      }
    });
  }

  getAllFreemiumCompanies() {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get<any>(BackendUrl + "/organisations/freemiumcompanies", {headers: headers})

    .pipe(take(1)).subscribe({next: response2 => {
        this.getOrganisationDataUpdated.next(response2);
      },
      error: error => {
        this.getOrganisationDataUpdated.next(null);
      }
    });
  }

  addFreemiumUser(userInfo) {
    let token = localStorage.getItem("token");
    if(!token){
      token = "123456"; // This is just something I use so that it isn't null,
                        // the backend  call doesnt send if it is null.
                        // Since this action is done before user is logged in the
                        // token is probably null the token isn't used backend.
    }

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .post<any>(BackendUrl + '/frm/register', userInfo, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getThisOrganisation() {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations", { headers: headers })

      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getSingleOrganisation(orgId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/" + orgId, { headers: headers })

      .pipe(take(1)).subscribe({
        next: (response) => {
          response = this.subscriptionIdentifiersToObjects(response);
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  ParentSubOrganisation(orgId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/" + orgId + "/parent", {
        headers: headers,
      })

      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  createNewDepartment(orgId, object, partentId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    var info = {
      name: object.name||'',
      misc: object.misc||'',
      address_visit: object.address_visit||'',
      parent_id: partentId,
    };

    this.http
      .post<any>(BackendUrl + "/organisations", info, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  removeDepartment(orgId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .delete<any>(BackendUrl + "/organisations/" + orgId, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(true);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(true);
        },
      });
  }

  updateDepartment(departmentId, department) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .put<any>(
        BackendUrl + "/organisations/" + departmentId + "/update",
        department,
        { headers: headers }
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(true);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getAllFiles(orgId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/" + orgId + "/files/", {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getAllFilesDepartments(orgId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/organisations/" + orgId + "/files/", {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdatedDepartmentFiles.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdatedDepartmentFiles.next(null);
        },
      });
  }

  getFileWithId(orgId, fileId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get(BackendUrl + "/organisations/" + orgId + "/files/" + fileId, {
        headers: headers,
        responseType: "arraybuffer",
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          var file = new Blob([response], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          this.getOrganisationDataUpdated.next(fileURL);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  deleteFileWithId(orgId, fileId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .delete(
        BackendUrl + "/organisations/" + orgId + "/files/" + fileId + "/",
        { headers: headers }
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  SingleUpdateCompanyInformation(orgId, organisation) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .put<any>(
        BackendUrl + "/organisations/" + orgId + "/update",
        organisation,
        { headers: headers }
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(true);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  SingleUpdateCompanyInformationMisc ( orgId, organisation ) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .put<any>(
        BackendUrl + "/organisations/" + orgId + "/updatemisc",
        organisation,
        { headers: headers }
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(true);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  makeOrganisationTreeChanges(department) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .put<any>(
        BackendUrl + "/organisations/" + department.id,
        { parent_id: department.parent },
        { headers: headers }
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(true);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  addFile(orgId, fileInfo) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .post<any>(BackendUrl + "/organisations/" + orgId + "/files", fileInfo, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  removeProductArticleNumber(orgId, article) {
    var articleToRemove = this.utilService.serialize(article);

    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .post<any>(
        BackendUrl + "/organisations/" + orgId + "/articleNumbersProducts",
        articleToRemove,
        { headers: headers }
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  saveProductArticleNumbers(orgId, article) {
    var articlesToBeSaved = this.utilService.serialize(article);

    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .put<any>(
        BackendUrl + "/organisations/" + orgId + "/articlenumbersProducts",
        articlesToBeSaved,
        { headers: headers }
      )
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getProductArticleNumbers(orgId) {
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get(BackendUrl + "/organisations/" + orgId + "/articlenumbersProducts", {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  getParentFromDepartment(department) {
    var parent = "";
    if (department) {
      parent = department.parent;
      this.ParentSubOrganisation(parent);
      this.getOrganisationDataListener()
        .pipe(take(1))
        .subscribe({
          next: (parentorg) => {
            if (parentorg != null) {
              if (parentorg.id === "123") {
                parentorg.name = "";
              }
              localStorage.setItem("selectedParent", parentorg);
            } else {
            }
          },
          error: (error) => {},
        });
    }
  }

  changeDepartmentById(departmentId) {
    this.getOrganisation(departmentId);
    this.getOrganisationOrgDataListener().pipe(take(1))
      .subscribe({next: (department) => {
          if (department != null) {

            localStorage.setItem('selectedDepartment', JSON.stringify(department));
            var selectedDepartment = JSON.parse(
              localStorage.getItem("selectedDepartment")
            );

            var rootOrganisation = JSON.parse(
              localStorage.getItem("rootOrganisation")
            );

            if (!rootOrganisation) {
              rootOrganisation = department;
            }

            if (selectedDepartment.main_organisation != rootOrganisation.id) {
              this.getSingleOrganisation(selectedDepartment.main_organisation);
              this.getOrganisationDataListener().pipe(take(1))
                .subscribe({
                  next: (department) => {
                    if (department != null) {
                      localStorage.setItem('selectedOrganisation', JSON.stringify(selectedDepartment));
                      localStorage.setItem('rootOrganisation', JSON.stringify(selectedDepartment));
                      this.departmentChanged.next(selectedDepartment);
                    } else {
                    }
                  },
                  error: (error) => {},
                });
            } else {
              this.departmentChanged.next(department);
            }
          }
        },
        error: (error) => {},
      });
  }

  changeDepartment(departmentId) {
    this.getOrganisation(departmentId);
    this.getOrganisationOrgDataListener().pipe(take(1))
      .subscribe({next: (department) => {
          if (department != null) {

            localStorage.setItem('selectedDepartment', JSON.stringify(department));
            var selectedDepartment = JSON.parse(
              localStorage.getItem("selectedDepartment")
            );

            var rootOrganisation = JSON.parse(
              localStorage.getItem("rootOrganisation")
            );

            if (!rootOrganisation) {
              rootOrganisation = department;
            }

            if (selectedDepartment.main_organisation != rootOrganisation.id) {
              this.getSingleOrganisation(selectedDepartment.main_organisation);
              this.getOrganisationDataListener().pipe(take(1))
                .subscribe({
                  next: (department) => {
                    if (department != null) {
                      localStorage.setItem('selectedOrganisation', JSON.stringify(selectedDepartment));
                      localStorage.setItem('rootOrganisation', JSON.stringify(selectedDepartment));
                    } else {
                    }
                  },
                  error: (error) => {},
                });
            } else {
            }
          }
        },
        error: (error) => {},
      });
  }


  getSecretChem() {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .get<any>(BackendUrl + "/organisations/getsecretchem", {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  idExistsInProducts(id){
    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get(BackendUrl + "/organisations/idexists/" + id, {
        headers: headers
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          if(!response||response==null){
            this.getOrganisationDataUpdated.next(false);
          } else {
            this.getOrganisationDataUpdated.next(response);
          }
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(false);
        },
      });

  }

  createSingle(object) {
    var organisation = this.utilService.serialize(object);
    this.subscriptionObjectsToIdentifiers(organisation, true);
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .post<any>(BackendUrl + "/organisations", organisation, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  updateSingle(id, object) {
    // var organisation = this.utilService.serialize(object);
    this.subscriptionObjectsToIdentifiers(object, false);
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .put<any>(BackendUrl + "/organisations/" + id, object, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  updateFrmUser(id, object) {
    var organisation = this.utilService.serialize(object);
    this.subscriptionObjectsToIdentifiers(organisation, false);
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .put<any>(BackendUrl + "/frm/register/" + id, organisation, {
        headers: headers,
      })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  removeSingle(id) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .delete<any>(BackendUrl + "/organisations/" + id, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
      });
  }

  addSubstitution(object) {

    let token = localStorage.getItem("token");

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    // Since I need body.object backend and not body, something that is changed from angular versions
    var params = new HttpParams().set("object", object);
    this.http.post<any>(BackendUrl + "/organisations/newsubstitution", object, {headers: headers, params:params})
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getOrganisationDataUpdated.next(response);
        },
        error: (error) => {
          this.getOrganisationDataUpdated.next(null);
        },
    });
  }

  // Needed functions for subscriptions
  subscriptionIdentifiersToObjects(organisation) {
    var subscription;

    if (_.isObject(organisation)) {
      organisation.subscriptions = [];

      if (organisation.subscription_activities) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_activities"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_app) {
        subscription =
          this.staticDataService.getSubscriptionByIdentifier(
            "subscription_app"
          );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_catalogue) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_catalogue"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_classification) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_classification"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_coordinator) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_coordinator"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_distribution) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_distribution"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_exposures) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_exposures"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_grb) {
        subscription =
          this.staticDataService.getSubscriptionByIdentifier(
            "subscription_grb"
          );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_lawpoint) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_lawpoint"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_law_aptor) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_law_aptor"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_products) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_products"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_product_analysis) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_product_analysis"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_productsheet) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_productsheet"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_projects) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_projects"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_purchase) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_purchase"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_reports) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_reports"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_classification) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_classification"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_cmr) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_cmr"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_fire) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_fire"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_grb) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_grb"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_notes) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_notes"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_sds) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_sds"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_simple) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_simple"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_statutory) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_statutory"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_sds) {
        subscription =
          this.staticDataService.getSubscriptionByIdentifier(
            "subscription_sds"
          );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_checklist) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_checklist"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_enviroment) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_enviroment"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_purchase) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_purchase"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_sds_activity) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_sds_activity"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_transport) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_transport"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_special_demands) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_special_demands"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_report_sll) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_sll"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_labels) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_labels"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_freemium) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_freemium"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_risk_assessment) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_risk_assessment"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_substitution) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_substitution"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_product_application) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_product_application"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_samtec) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_samtec"
        );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_revise_date) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_revise_date"
        );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_qrcode) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_qrcode"
        );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_secret_chemical) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_secret_chemical"
        );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_stamped_SDS) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_stamped_SDS"
        );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_sin) {
        subscription =
          this.staticDataService.getSubscriptionByIdentifier(
            "subscription_sin"
          );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_sll) {
        subscription =
          this.staticDataService.getSubscriptionByIdentifier(
            "subscription_sll"
          );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_basta) {
        subscription =
          this.staticDataService.getSubscriptionByIdentifier(
            "subscription_basta"
          );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_report_allergenic) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_report_allergenic"
        );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_footprint) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_footprint"
        );
        organisation.subscriptions.push(subscription);
      }
      if (organisation.subscription_education) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_education"
        );
        organisation.subscriptions.push(subscription);
      }

        if (organisation.subscription_mail_sds) {
          subscription = this.staticDataService.getSubscriptionByIdentifier(
            "subscription_mail_sds"
          );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_styren) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_styren"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_list_pop) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_list_pop"
        );
        organisation.subscriptions.push(subscription);
      }

      if (organisation.subscription_list_rohs) {
        subscription = this.staticDataService.getSubscriptionByIdentifier(
          "subscription_list_rohs"
        );
        organisation.subscriptions.push(subscription);
      }
    }

    return organisation;
  }

  setTransportInfo(object) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.post<any>(BackendUrl + '/organisations/setTransportInfo', object, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {
        this.getOrganisationDataUpdated.next(response);
      },
      error: error => {
        this.getOrganisationDataUpdated.next(null);
      }
    });
  }

  removeTransportInfo(object) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.post<any>(BackendUrl + '/organisations/removeTransportInfo', object, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {
        this.getOrganisationDataUpdated.next(response);
      },
      error: error => {
        this.getOrganisationDataUpdated.next(null);
      }
    });
  }

  subscriptionIdentifiersToObjectsArray(organisations) {
    var i;

    if (_.isArray(organisations)) {
        for (i = 0; i < organisations.length; i += 1) {
            this.subscriptionIdentifiersToObjects(organisations[i]);
        }
    }

    return organisations;
}

  subscriptionObjectsToIdentifiers(organisation, isNew) {
    var i;

    organisation.subscription_activities = 0;
    organisation.subscription_app = 0;
    organisation.subscription_catalogue = 0;
    organisation.subscription_classification = 0;
    organisation.subscription_coordinator = 0;
    organisation.subscription_distribution = 0;
    organisation.subscription_exposures = 0;
    organisation.subscription_grb = 0;
    organisation.subscription_lawpoint = 0;
    organisation.subscription_law_aptor = 0;
    organisation.subscription_products = 0;
    organisation.subscription_product_analysis = 0;
    organisation.subscription_productsheet = 0;
    organisation.subscription_projects = 0;
    organisation.subscription_purchase = 0;
    organisation.subscription_reports = 0;
    organisation.subscription_report_classification = 0;
    organisation.subscription_report_cmr = 0;
    organisation.subscription_report_fire = 0;
    organisation.subscription_report_grb = 0;
    organisation.subscription_report_notes = 0;
    organisation.subscription_report_sds = 0;
    organisation.subscription_report_simple = 0;
    organisation.subscription_report_statutory = 0;
    organisation.subscription_sds = 0;
    organisation.subscription_report_enviroment = 0;
    organisation.subscription_checklist = 0;
    organisation.subscription_report_purchase = 0;
    organisation.subscription_report_sds_activity = 0;
    organisation.subscription_transport = 0;
    organisation.subscription_special_demands = 0;
    organisation.subscription_report_sll = 0;
    organisation.subscription_labels = 0;
    organisation.subscription_freemium = 0;
    organisation.subscription_risk_assessment = 0;
    organisation.subscription_substitution = 0;
    organisation.subscription_samtec = 0;
    organisation.subscription_revise_date = 0;
    organisation.subscription_qrcode = 0;
    organisation.subscription_secret_chemical = 0;
    organisation.subscription_stamped_SDS = 0;
    organisation.subscription_sin = 0;
    organisation.subscription_sll = 0;
    organisation.subscription_basta = 0;
    organisation.subscription_report_allergenic = 0;
    organisation.subscription_footprint = 0;
    organisation.subscription_education = 0;
    organisation.subscription_mail_sds = 0;
    organisation.subscription_styren = 0;
    organisation.subscription_product_application = 0;
    organisation.subscription_list_pop = 0;
    organisation.subscription_list_rohs = 0;

    if (organisation.subscriptions && _.isArray(organisation.subscriptions)) {
      for (i = 0; i < organisation.subscriptions.length; i += 1) {
        if (organisation.subscriptions[i].active && isNew) {
          organisation[organisation.subscriptions[i].identifier] = 1;
        } else {
          organisation[organisation.subscriptions[i].identifier] = 1;
        }
      }
    }
    delete organisation.subscriptions;
    return organisation;
  }

  // $resource(BackendUrl + "/organisations/:organisationId", null, {

  public getOrganisationDataListener() {
    return this.getOrganisationDataUpdated.asObservable();
  }

  public getOrganisationDataDocumentListener() {
    return this.getOrganisationDataDocumentUpdated.asObservable();
  }

  public getOrganisationNavDataListener() {
    return this.getOrganisationNavDataUpdated.asObservable();
  }

  public getOrganisationSubDataListener() {
    return this.getOrganisationSubDataUpdated.asObservable();
  }

  public getOrganisationNewsDataListener() {
    return this.getOrganisationNewsDataUpdated.asObservable();
  }

  public getOrganisationOrgDataListener() {
    return this.getOrganisationOrgDataUpdated.asObservable();
  }

  public getOrganisationDataListenerDepartmentFiles() {
    return this.getOrganisationDataUpdatedDepartmentFiles.asObservable();
  }

  public getOrganisationAllDepartmentNamesDataListener() {
    return this.getOrganisationAllDepartmentNamesDataUpdated.asObservable();
  }
}
