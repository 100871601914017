<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-2/4 shadow-lg rounded-md bg-white">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"EDIT_DEPARTMENT" | translate}}</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-4 p-2">
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"NAME" | translate}}:</label><br>
                        <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="departmentName" (ngModelChange)="departmentName=$event" type="text">
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"DEPARTMENT_INFO" | translate}}:</label><br>
                        <textarea title="{{'DEPARTMENT_INFO' | translate}}" class="w-full border border-gray-300 p-2" rows="3" maxlength="1000" (ngModelChange)="departmentInfo=$event" [(ngModel)]="departmentInfo" type="text"></textarea>
                    </div>
                    <div class="mb-4">
                        <input title="{{'ATTACHE_FILES' | translate}}" class="button-white border border-gray-300 mr-1" type="file" (change)="fileChange($event)" placeholder="Upload file" id="upload-file" accept=".pdf" />
                        <div class="mt-4" *ngIf="departmentFiles && departmentFiles?.length > 0">
                            <span *ngFor="let file of departmentFiles">
                                <div class="flex flex-row items-center px-7 py-1 mt-1">
                                    <a (click)="openDocumentInNewWindow(file.id)">
                                        <img class="w-6 h-6 cursor-pointer" title="Substitution" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> 
                                    </a>
                                    <p>{{file.filename}}</p>
                                    <a class="ml-4">
                                        <i aria-hidden="true" (click)="removeDocument(file.id)">
                                            <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
                                                21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
                                                5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
                                                3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
                                                stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </i> 
                                    </a>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"ADRESS" | translate}}:</label><br>
                        <input title="{{'ADRESS' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="departmentAdressVisit" (ngModelChange)="departmentAdressVisit=$event" type="text">
                    </div>
                </div>
            </div>
            <!-- Modal Footer -->
            <div class="flex justify-center items-center mt-2">
                <button type="button" 
                    class="button-green" 
                    (click)="save()">
                    {{"SAVE" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

