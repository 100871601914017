<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white mb-20">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3">
            <div class="text-gray-900 font-bold">{{modalTitle}}!</div>
            <!-- Modal Body -->
            <div class="flex flex-col mt-10 p-2">
              <div class="flex flex-col w-full mt-2">
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"SHIPPER_ROW1" | translate}}:</label><br>
                    <input title="{{'SHIPPER_ROW1' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="shipperRow1" (ngModelChange)="shipperRow1=$event" type="text">
                </div>
                <div class="mb-4">
                  <label class="font-bold mb-1">{{"SHIPPER_ROW2" | translate}}:</label><br>
                  <input title="{{'SHIPPER_ROW2' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="shipperRow2" (ngModelChange)="shipperRow2=$event" type="text">
                </div>
                <div class="mb-4">
                  <label class="font-bold mb-1">{{"SHIPPER_ROW3" | translate}}:</label><br>
                  <input title="{{'SHIPPER_ROW3' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="shipperRow3" (ngModelChange)="shipperRow3=$event" type="text">
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"CONSIGNEE_ROW1" | translate}}:</label><br>
                    <input title="{{'CONSIGNEE_ROW1' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="consigneeRow1" (ngModelChange)="consigneeRow1=$event" type="text">
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"CONSIGNEE_ROW2" | translate}}:</label><br>
                    <input title="{{'CONSIGNEE_ROW2' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="consigneeRow2" (ngModelChange)="consigneeRow2=$event" type="text">
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"CONSIGNEE_ROW3" | translate}}:</label><br>
                    <input title="{{'CONSIGNEE_ROW3' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="consigneeRow3" (ngModelChange)="consigneeRow3=$event" type="text">
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"NUMBER_OF_PACKAGES" | translate}}:</label><br>
                    <input title="{{'NUMBER_OF_PACKAGES' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="numberOfPackages" (ngModelChange)="numberOfPackages=$event" type="text">
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"TYPE_OF_PACKAGE" | translate}}:</label><br>
                    <div class="relative inline-block bg-white text-left z-40 w-full" id="selectRisk" appDropdown>
                        <button type="button" class="text-black-900 flex items-start p-2 justify-between border border-gray-400 w-full rounded-md mt-4 mb-4">
                            <span>{{typeOfPackages}}</span>
                            <svg class="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu" #dropdownMenuInside>
                            <div class="dropdown-menu-item" data-value="typeOfPackages" (click)="selectCargoType(1)" id="modalPrintCargolistButton1" tabindex="1">{{"CARGOTYPE1" | translate}}</div>
                            <div class="dropdown-menu-item" data-value="typeOfPackages" (click)="selectCargoType(2)" id="modalPrintCargolistButton2" tabindex="2">{{"CARGOTYPE2" | translate}}</div>
                            <div class="dropdown-menu-item" data-value="typeOfPackages" (click)="selectCargoType(3)" id="modalPrintCargolistButton3" tabindex="3">{{"CARGOTYPE3" | translate}}</div>
                            <div class="dropdown-menu-item" data-value="typeOfPackages" (click)="selectCargoType(4)" id="modalPrintCargolistButton4" tabindex="4">{{"CARGOTYPE4" | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2 mt-2 mr-4">
                        <span class="text-sm mr-1">{{"OVERPACK" | translate}}:</span>
                        <input title="{{'OVERPACK' | translate}}" type="checkbox" [(ngModel)]="overpack" (ngModelChange)="overpack = $event" [ngModelOptions]="{standalone: true}" (change)="overpackCheckbox($event)">
                    </div>
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"WEIGHT" | translate}}:</label><br>
                    <input title="{{'WEIGHT' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="netWeight" (ngModelChange)="netWeight=$event" type="text">
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"VOLUME" | translate}}:</label><br>
                    <input title="{{'VOLUME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="volume" (ngModelChange)="volume=$event" type="text">
                </div>
            </div>
            <!-- Modal Footer -->
            <div class="flex justify-center items-center mt-4">
                <button type="button" 
                    class="button-green" 
                    (click)="save()">
                    {{"SAVE" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
