import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/services/product.service';
import { UtilService } from 'src/app/services/util.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { take, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-modal-add-excel',
  templateUrl: './modal-add-excel.component.html',
  styleUrls: ['./modal-add-excel.component.css']
})
export class ModalAddExcelComponent implements OnInit {

  @Input() sareqProductsToAdd;

  itemsPerPage:number=10;
  pageNumber:number=1;
  notInOrg: any;
  notInSareq: any;
  productsToAdd: any;
  selectedDepartment: any;
  loadingData: boolean = false;
  numberOfItems: number = 0;
  innerObservable;
  innerObservable2;
  numberOfIterations=[];
  numberOfObjectItems: number = 0;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder:FormBuilder,
              private productService: ProductService,
              private utilService: UtilService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.numberOfItems=0;
    this.productsToAdd = this.sareqProductsToAdd;
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.notInOrg = [];
    this.notInSareq = [];

    if (this.productsToAdd) {
      for(var i = 0; i < this.productsToAdd.length; i++){
          if(this.productsToAdd[i].status == 1){
            this.notInOrg.push(this.productsToAdd[i]);
          } else{
            this.notInSareq.push(this.productsToAdd[i]);
          }
          this.productsToAdd[i]['disabled'] = false;
      }
    }

    this.numberOfIterations=[];
    this.numberOfObjectItems=0;
    for (var i = 0; i < this.notInOrg.length; i++){
      if (!this.notInOrg[i]['disabled']){
        this.numberOfObjectItems++;
      }
    }

    if (this.numberOfObjectItems == 0)
    {
      if(this.notInSareq.length>0){
        var textHtml='<br/><br/><table id="table" border=0><thead><tr><th>Namn</th><th>Leverantör</th></tr></thead><tbody>';
        for(let n=0;n<this.notInSareq.length;n++){
          textHtml+='<tr><td>'+this.notInSareq[n].name+'</td><td>'+this.notInSareq[n].manufacturer+'</td></tr>';
        }
        textHtml+='</tbody></table>';
        Swal.fire({
          title: '<strong>Följande produkter hittades inte i Sareq:</strong>',
          icon: 'info',
          html: textHtml
        })
      }
      this.activeModal.close(ModalAddExcelComponent);
    }
  }

  ngOnDestroy(): void {
  }

  save() {
    this.numberOfIterations=[];
    this.loadingData=true;
    this.numberOfObjectItems=0;
    for (var i = 0; i < this.notInOrg.length; i++){
      if (!this.notInOrg[i]['disabled']){
        this.numberOfObjectItems++;
      }
    }

    var counter = 0;
    let unique = false;
    for (var i = 0; i < this.notInOrg.length; i++){
      if (!this.notInOrg[i]['disabled']){
        this.innerObservable = of(this.productService.getProductIdFromName(this.notInOrg[i].name, this.notInOrg[i].manufacturer));
        if (unique == false) {
          this.reveiveProductMsg(counter);
          unique = true;
      }
        //this.productService.getProductIdFromName(this.notInOrg[i].name, this.notInOrg[i].manufacturer);
      }
    }
    // this.reveiveProductMsg(counter);
  }

  close() {
    this.activeModal.close(ModalAddExcelComponent);
  }


  reveiveProductMsg(counter) {
    let unique2 = false;
    this.numberOfItems++;
    if(counter>=this.notInOrg.length){
      this.loadingData=false;
      this.activeModal.close(ModalAddExcelComponent);
    }
    //this.productService.getProductDataListener().pipe(take(1)).subscribe({
      var product=[];
    this.productService.getProductDataListener().pipe(concatMap( val => {
      // next: newProduct => {
      if (val != null) {
        for(let n=0;n<this.notInOrg.length;n++){
          if (!this.notInOrg[n]['disabled']){
            if(val&&this.notInOrg[n]&&this.notInOrg[n].name.replace('plussign','+')==val.name.replace('plussign','+')&&this.notInOrg[n].manufacturer.replace('plussign','+')==val.supplier_name.replace('plussign','+')){
              this.notInOrg[n].product_id=val.id;
              this.notInOrg[n]['orgid'] = this.selectedDepartment.id;
              product.push(this.notInOrg[n]);
              counter=n;
            } else {

            }
          }
        }
      }
      return this.innerObservable
    })).subscribe({
      next: newProduct => {
        if(product.length==this.numberOfObjectItems){
          for(let n=0;n<product.length;n++){
            this.innerObservable2 = of(this.productService.postProductInOrg(this.selectedDepartment.id, product[n]));
          }
          if (unique2 == false) {
            this.productService.getProductDataListener().pipe(concatMap( val => {
              return this.innerObservable2
            })).subscribe({
              next: newProduct2 => {
                this.numberOfIterations.push('');
                if(this.numberOfIterations.length==this.numberOfObjectItems){
                  this.loadingData=false;
                  if(this.notInSareq.length>0){
                    var textHtml='<br/><br/><table id="table" border=0><thead><tr><th>Namn</th><th>Leverantör</th></tr></thead><tbody>';
                    for(let n=0;n<this.notInSareq.length;n++){
                      textHtml+='<tr><td>'+this.notInSareq[n].name+'</td><td>'+this.notInSareq[n].manufacturer+'</td></tr>';
                    }
                    textHtml+='</tbody></table>';
                    Swal.fire({
                      title: '<strong>Följande produkter hittades inte i Sareq:</strong>',
                      icon: 'info',
                      html: textHtml
                    })
                  }
                  this.activeModal.close(ModalAddExcelComponent);
                }


              },
              error: error => {
                console.log("Error: ", error);
              }
            })
        }
      }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  logout() {
    this.authService.logout();
  }
}

