<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="overflow-auto mb-4 px-7">
    <div class="flex flex-col min-h-screen">
        <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="goToSubstitutionList()">
            <svg title="{{'BACK' | translate}}" class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
            <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
            </svg>
        </button>

        <div *ngIf="!loadingData&&products" class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg p-7">
            <div *ngIf="candidates.length > 0">
                <label class="text-xl font-bold">{{'SUBSTITUTIONLIST_CANDIDATE_PROPOSAL' | translate }} {{candidates[0].sareq_name}}:</label>
                <div *ngFor="let candidate of candidates; let i = index">
                    {{ i + 1 }}. {{'PRODUCT_NAME' | translate }}: <span (click)="choseCandidate(candidate.sub_name)" class="italic underline text-sm cursor-pointer">{{candidate.sub_name}}</span>, {{'SUPPLIER' | translate }}: <span class="italic text-sm">{{candidate.sub_supplier_name}}</span>
                </div>
            </div>
            <div class="mb-4">
                <app-sareq-search [productInput]="candidateName" (dataOutput)="addProduct($event)"></app-sareq-search>
            </div>
            <div class="overflow-x-auto">
                <table *ngIf="products&&workstep&&workstep.product||workstep&&workstep?.length>0" class="table-auto w-full bg-white mb-4 border border-gray-400" id="productaddtable">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header">{{"NAME" | translate}}</th>
                            <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                            <th class="table-row-header">{{"RISK_LEVEL" | translate}}</th>
                            <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                            <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="workstepId!='all'">
                            <tr>
                                <td class="table-row-column">{{workstep.product?.name}}</td>
                                <td class="table-row-column">{{workstep.product?.supplier_name}}</td>
                                <td class="table-row-column">{{workstep.product?.risk_prio}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-row">
                                        <span *ngFor="let pictogram of workstep.product.pictograms" tooltips tooltip-smart="true" title={{pictogram.description}}>
                                            <img class="w-6 h-6" title={{pictogram.description}} src="/images/pictograms/{{pictogram.filename}}-small.png" />
                                        </span>
                                    </div>
                                </td>
                                <td class="table-row-column">
                                    <div class="flex flex-row">
                                        <img class="-mr-3" *ngIf="subscribesToSds && workstep.product.esdb_available==0 || (workstep.product.esdb_file_id!='' && (workstep.product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(workstep.product)" />
                                        <img class="-mr-3" *ngIf="subscribesToSds && workstep.product.esdb_available==1 && (workstep.product.esdb_file_id=='' || (workstep.product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(workstep.product)" />
                                        <img class="-mr-3" *ngIf="subscribesToSds && workstep.product.esdb_available==1 && (workstep.product.esdb_file_id!='' && (workstep.product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(workstep.product)" />
                                        <img *ngIf="subscribesToGrb && workstep.product.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(workstep.product)" />
                                    </div>
                                </td>
                                <td class="table-row-column" *ngIf="workstep" (click)="removeProduct(workstep.product)">
                                    <img class="w-5 h-5" tooltips tooltip-smart="true" title="{{'REMOVE' | translate}}" src="/images/icons/cross-mark-svgrepo-com.svg" area-hidden="true" />
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="workstepId=='all'">
                            <tr *ngFor="let workstep2 of workstep">
                                <td class="table-row-column">{{workstep2.product?.name}}</td>
                                <td class="table-row-column">{{workstep2.product?.supplier_name}}</td>
                                <td class="table-row-column">{{workstep2.product?.risk_prio}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-row">
                                        <img *ngIf="subscribesToSds" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(workstep2.product)" />
                                        <img *ngIf="subscribesToGrb && workstep2 && workstep2.product.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(workstep2.product)" />
                                    </div>
                                </td>
                                <td class="table-row-column" *ngIf="workstep2" (click)="removeProduct(workstep2.product)">
                                    <img class="w-5 h-5" tooltips tooltip-smart="true" title="{{'REMOVE' | translate}}" src="/images/icons/cross-mark-svgrepo-com.svg" area-hidden="true" />
                                </td>
                                <td class="table-row-column">
                                    <div class="flex flex-row items-center space-x-2 px-4">
                                        <span class="text-sm  mr-1">{{approvestatus(workstep2.approved)}}:</span>
                                        <input title="{{'APPROVE' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="workstep2.approved==1" (click)="approve(workstep2)">
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div *ngIf="workstepId!='all'&&workstep" class="flex flex-col">
                <label class="font-bold">{{"COMMENTS" | translate }}</label>
                <textarea title="{{'COMMENTS' | translate }}"class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="workstep.comment" (ngModelChange)="workstep.comment = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false"></textarea>
            </div>
        </div>

        <div class="flex flex-row justify-center mb-20 mt-4">
            <button class="button-green mr-2" title="{{'SAVE' | translate}}" (click)="save()">
                <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                <span class="text-lg hidden md:block">{{ 'SAVE' | translate }}</span>
              </button>
            <button *ngIf="workstepId!='all'" class="button-blue mr-2" title="{{'NO_PRODUCT_IN_SAREQ' | translate}}" (click)="noproductinsareq()">
              <span class="text-lg hidden md:block">{{ 'NO_PRODUCT_IN_SAREQ' | translate }}</span>
            </button>
        </div>
    </div>
</div>


