<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
    <!-- Modal Content -->
    <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"HISTORY" | translate}}</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-4 p-2">
                <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                    <div class="loader"></div>
                </div>
  
                <div *ngIf="loadingData==false" class="overflow-auto mb-20">
                    <table datatable [dtOptions]="dtOptions" class="table">
                        <thead>
                        <tr>
                            <th class="table-row-header">{{"CREATED_DATE" | translate}}</th>
                            <th class="table-row-header">{{"RESPONSIBLE" | translate}}</th>
                            <th class="table-row-header">{{"STATUS" | translate}}</th>
                            <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                            <th class="table-row-header">{{"PRODUCT" | translate}}</th>
                            <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                            <th class="table-row-header">{{"PROTECTIVE_GEAR_SYMBOLS" | translate}}</th>
                            <th class="table-row-header">{{"ARTICLE_NUMBER" | translate}}</th>
                            <th class="table-row-header">{{"COMMON_USE" | translate}}</th>
                            <th class="table-row-header">{{"OBSERVATION" | translate}}</th>
                            <th class="table-row-header">{{"HYGIENE_LIMITS" | translate}}</th>
                            <th class="table-row-header" *ngIf="subscribesToTransport">{{"DANGEROUS_GOODS" | translate}}</th>
                            <th class="table-row-header">{{"SAFETY_EXT_DATA_SHEET" | translate}}</th>
                            <th class="table-row-header">{{"HIDDEN" | translate}}</th>
                            <th class="table-row-header">{{"HAND_PROTECTION" | translate}}</th>
                            <th class="table-row-header">{{"EYE_PROTECTION" | translate}}</th>
                            <th class="table-row-header">{{"PROTECTIVE_CLOTHING" | translate}}</th>
                            <th class="table-row-header">{{"RESPIRATORY" | translate}}</th>
                            <th class="table-row-header">{{"LANGUAGE" | translate}}</th>
                            <th class="table-row-header">{{"OVERRIDE" | translate}}</th>
                            <th class="table-row-header">{{"PRINT_DATE" | translate}}</th>
                            <th class="table-row-header">{{"REPLACEMENT_DATE" | translate}}</th>
                            <th class="table-row-header">{{"REVISION_DATE" | translate}}</th>
                            <th class="table-row-header">{{"CDOC_DATE" | translate}}</th>
                            <th class="table-row-header">{{"VERSION" | translate}}</th>
                            <th class="table-row-header">{{"RISK_LEVEL" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sareqHistoryProduct of reportInformation.products">
                            <td class="table-row-column">{{sareqHistoryProduct.created_date | date:'yyyy-MM-dd'}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.assigned}}</td>
                            <td class="table-row-column">{{getStatus(sareqHistoryProduct.status)}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.supname}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.Sname}}</td>
                            <td class="table-row-column">
                                <div class="flex flex-row">
                                    <span *ngFor="let pictogram of sareqHistoryProduct.pictograms">
                                        <img title="{{pictogram.filename}}" src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                                    </span>
                                </div>
                            </td>
                            <td class="table-row-column">
                                <ul class="list-disc list-inside mt-2 px-4">
                                    <li *ngFor="let protective of protectiveImages" style="display:inline-block;margin-left:1px">
                                        <div class="four wide">
                                        <img title="{{protective.filename}}" [hidden]="!protectiveInSareq(protective.name,sareqHistoryProduct)"
                                            [src]="'/images/protective/'+protective.filename+'-tiny.png'" />
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td class="table-row-column">{{sareqHistoryProduct.supplier_article_number}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.com_use}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.observation}}</td>
                            <td class="table-row-column"><span>{{sareqHistoryProduct.hygiene_limits==1?'Ja':'Nej'}}</span></td>
                            <td class="table-row-column" *ngIf="!sareqHistoryProduct.adr_not_classfied && !sareqHistoryProduct.transport_un_nbr && subscribesToTransport">
                                {{"" | translate}}
                            </td>
                            <td class="table-row-column" *ngIf="sareqHistoryProduct.transport_un_nbr && subscribesToTransport">
                                {{sareqHistoryProduct.transport_un_nbr}}
                            </td>
                            <td class="table-row-column" *ngIf="sareqHistoryProduct.adr_not_classfied===1 && subscribesToTransport">
                                {{"NO" | translate}}
                            </td>
                            <td class="table-row-column" *ngIf="sareqHistoryProduct.esdb_available===1">
                                {{"YES" | translate}}
                            </td>
                            <td class="table-row-column" *ngIf="sareqHistoryProduct.esdb_available===0">
                                {{"NO" | translate}}
                            </td>
                            <td class="table-row-column">{{sareqHistoryProduct.hidden}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.prot_gloves_small}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.prot_glasses_small}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.prot_clothes_small}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.prot_breath_small}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.lang}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.override}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.print_date | date:'yyyy-MM-dd'}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.replaces_date | date:'yyyy-MM-dd'}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.rework_date | date:'yyyy-MM-dd'}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.cdoc_date | date:'yyyy-MM-dd'}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.version}}</td>
                            <td class="table-row-column">{{sareqHistoryProduct.risk_prio}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
