<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
    <!-- Modal Content -->
    <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"REPORT_ENVIROMENT" | translate}}</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-4 p-2">
                <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                    <div class="loader"></div>
                </div>
  
                <div *ngIf="loadingData==false" class="overflow-auto mb-20">
                    <table datatable [dtOptions]="dtOptions" class="table">
                        <thead>
                            <tr>
                                <th class="table-row-header">{{"NAME" | translate}}</th>
                                <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                                <th class="table-row-header">{{"ECOLABELS" | translate}}</th>
                                <th class="table-row-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                                <th class="table-row-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                                <th class="table-row-header">{{"UNIT" | translate}}</th>
                                <th class="table-row-header" *ngIf="showExcelImportDate==true">{{"IMPORTDATE" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of reportInformation.products">
                                <td class="table-row-column">{{product.name}}</td>
                                <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-col">
                                        <img class="w-6 h-6" title="ecolabel" *ngIf="product.eco_eu" src="/images/loggos/ecolabel-logo.png"/><p *ngIf="product.eco_eu">EU Märkning</p>
                                        <img class="w-6 h-6" title="eco_svanen" *ngIf="product.eco_svanen" src="/images/loggos/SE_Svanen_A_POS_RGB.png"/><p *ngIf="product.eco_svanen">Svanen</p>
                                        <img class="w-6 h-6" title="eco_GEC" *ngIf="product.eco_GEC" src="/images/loggos/gec.jpg"/><p *ngIf="product.eco_GEC">Svalan</p>
                                    </div>
                                </td>
                                <td class="table-row-column">{{product.amount}}</td>
                                <td class="table-row-column">{{product.annual}}</td>
                                <td class="table-row-column">{{product.unit}}</td>
                                <td class="table-row-column" *ngIf="showExcelImportDate==true">{{product.purchase_date | date:'yyyy-MM-dd'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
