import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ModalSelectOrganisationComponent } from 'src/app/modals/modal-select-organisation/modal-select-organisation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { SareqService } from 'src/app/services/sareq.service';
import { forkJoin, concat, of } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-sareq-dashboard',
  templateUrl: './sareq-dashboard.component.html',
  styleUrls: ['./sareq-dashboard.component.css']
})
export class SareqDashboardComponent {
  pageNumber: number = 1;
  itemsPerPage: number = 10;
  currentUser: any;
  users: any[] = [];
  doneCounter: any;
  month: number = 1;
  monthText: any;
  year: number = 2024;
  years: any = [];
  loadingData: boolean = false;
  chosenUserCount: any;
  chosenUserEdit: boolean = false;
  chosenUser: any;
  chosenUserCountReturn: any;

  constructor(
    private utilService: UtilService,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal,
    private authService:AuthService,
    private sareqService: SareqService
  ) {}

  ngOnInit(): void {
    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    this.monthText = currentMonth;
    const currentMonthNumber = new Date().getMonth(); // Get month index (0 for January, 11 for December)
    this.month = currentMonthNumber + 1; // Convert to human-friendly month number (1 for January, 12 for December)

    const startYear = 2015;
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 70;

    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year);
    }

    this.year = currentYear;

    this.getUserInfo();
  }

  chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  updateUserCounters(): void {
    const batchSize = 50;

    // Helper function to process a single batch
    const processBatch = (user, sareqIdsBatch) => {
      return this.sareqService.getStatusForIds(sareqIdsBatch, this.month, this.year).pipe(take(1));
    };

    // Process each user's sareqIds in batches
    const observables = this.users.map(user => {
      const sareqIdBatches = this.chunkArray(user.sareqIds, batchSize);
      return concat(
        ...sareqIdBatches.map(batch => processBatch(user, batch))
      ).pipe(toArray());
    });

    forkJoin(observables).subscribe(
      responses => {
        responses.forEach((responseArray, index) => {
          responseArray.forEach(response => {
            if (response && response.data) {
              const user = this.users[index];
              response.data.forEach(statusData => {
                switch (statusData.status) {
                  case 1:
                    user.doneCounter = (user.doneCounter || 0) + 1;
                    break;
                  case 0:
                    user.inspectionCounter = (user.inspectionCounter || 0) + 1;
                    break;
                  case 4:
                    user.newCounter = (user.newCounter || 0) + 1;
                    break;
                  case 3:
                    user.freeCounter = (user.freeCounter || 0) + 1;
                    break;
                  case 5:
                    user.ongoingCounter = (user.ongoingCounter || 0) + 1;
                    break;
                  case 2:
                    user.returnCounter = (user.returnCounter || 0) + 1;
                    break;
                }
              });
            }
          });
        });
        this.loadingData = false;
      },
      error => {
        console.log("Error: ", error);
        this.loadingData = false;
      }
    );
  }

  getUserInfo() {
    this.chosenUserEdit = false;
    this.loadingData = true;
    this.sareqService.getDistinctAssigned(this.month, this.year);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response == null) {
          this.loadingData = false;
        }
        else {
          this.users = response.data;

          if (!this.currentUser.is_super_user)
          {
            // Filter users to keep only the one with assigned equal to currentUser.username
            this.users = this.users.filter(user => user.assigned === this.currentUser.username);
          }

          for (let j = 0; j < this.users.length; j++)
          {
            this.users[j].doneCounter = 0;
            this.users[j].inspectionCounter = 0;
            this.users[j].newCounter = 0;
            this.users[j].freeCounter = 0;
            this.users[j].ongoingCounter = 0;
            this.users[j].returnCounter = 0;
            this.users[j].sareqIds = [];
          }

          let usersArray = this.users.map(item => item.assigned);
          if (usersArray.length == 0) {
            this.loadingData = false;
            return;
          }
          this.sareqService.getSareqForMonthAndYear(usersArray, this.month, this.year);
          this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
            next: response => {
              if ((response == null) || (response.data?.length == 0)) {
                this.loadingData = false;
              }
              else {

                for (let j = 0; j < this.users.length; j++)
                {
                  for (let i = 0; i < response.data.length; i++)
                  {
                    if (this.users[j].assigned == response.data[i].assigned)
                    {
                      if (!this.users[j].sareqIds.includes(response.data[i].product_id)) {
                        this.users[j].sareqIds.push(response.data[i].product_id);
                      }
                    }
                  }
                }

                this.users = this.users.filter(user => user.sareqIds && user.sareqIds.length > 0);

                this.updateUserCounters();
              }
            },
            error: error => {
              console.log("Error: ", error);
              this.loadingData = false;
            }
          })
        }
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData = false;
      }
    })
  }

  logout() {
    this.authService.logout();
  }

  userSelected(user) {
    this.chosenUserEdit = true,
    this.sareqService.getSareqForUserDone(user.assigned, this.year);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response == null) {}
        else {
          this.chosenUserCount = response.data.length;
          this.chosenUser = user.assigned;

          this.chosenUserEdit = true,
          this.sareqService.getSareqForUserReturn(user.assigned, this.year);
          this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
            next: response => {
              if (response == null) {}
              else {
                this.chosenUserCountReturn = response.data.length;
              }
            },
            error: error => {
              console.log("Error: ", error);
              this.loadingData = false;
            }
          })
        }
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData = false;
      }
    })
  }
}
