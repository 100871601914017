import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { take } from 'rxjs/operators';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  getSponsorDataUpdated = new Subject<any>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private utilService: UtilService) {}

  getAll() {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http.get<any>(BackendUrl + "/sponsors", this.utilService.setHeadersAndParamsObject())
      .pipe(take(1)).subscribe({
        next: (sponsors) => {
          this.getSponsorDataUpdated.next(sponsors);
        },
        error: (error) => {
          this.getSponsorDataUpdated.next(null);
        },
    });
  }

  public getSponsorDataListener() {
    return this.getSponsorDataUpdated.asObservable();
  }
}

