import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { UnsavedChangesService } from 'src/app/services/unsaved-changes.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ModalSelectOrganisationComponent } from 'src/app/modals/modal-select-organisation/modal-select-organisation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
@Injectable({
  providedIn: "root",
})
export class NavigationComponent implements OnInit {
  searchList: any;
  searchText: any;
  currentUser: any=null;
  subscribesToCatalogue: boolean=false;
  subscribesToProducts: boolean;
  subscribesToActivities: boolean;
  subscribesToChecklist: boolean;
  subscribesToRiskAssessment: boolean;
  subscribesToProjects: boolean;
  subscribesToExposures: boolean;
  subscribesToPurchase: boolean;
  subscribesToSubstitution: boolean;
  subscribesToDistribution: boolean;
  subscribesToSamtec: boolean;
  subscribesToLawLawpoint: boolean;
  subscribesToLawAptor: boolean;
  subscribesToCoordinator: boolean;
  subscribesToReports: boolean;
  subscribesToEducation: boolean;
  subscribesToProductApplication: boolean;
  isControlPanel: boolean = false;
  isCatalogue: boolean = false;
  isSettings: boolean = false;
  isProducts: boolean = false;
  isMaterialList: boolean = false;
  isReports: boolean = false;
  isAssessment: boolean = false;
  isAdministration: boolean = false;
  isLaw: boolean = false;
  isSamtec: boolean = false;
  isEducation: boolean = false;
  isHome: boolean = false;
  selectedOrganisation: any;
  selectedDepartment: any;
  organisationList: any;

  constructor(private authService:AuthService,
              private utilService: UtilService,
              private modalService: NgbModal,
              private translate: TranslateService,
              private organisationService: OrganisationService,
              private router:Router,
              private unsavedChangesService: UnsavedChangesService) { }

  ngOnInit() {

    localStorage.removeItem('productSearch');

    this.searchList = [];
    var data = localStorage.getItem('currentUser', );
    var productlist = localStorage.getItem('navigateproductlist', );

    this.getActiveLink();

    if(productlist=='set'){
      localStorage.removeItem('navigateproductlist', );
      this.isProducts = true;
    }

    if (data == null || data == undefined)
    {
        this.logout();
    }

    else
    {
        this.currentUser = JSON.parse(data);
    }

    this.selectedOrganisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.subscribesToCatalogue = this.utilService.subscribesTo('subscription_catalogue');
    this.subscribesToActivities = this.utilService.subscribesTo('subscription_activities');
    this.subscribesToChecklist = this.utilService.subscribesTo('subscription_checklist');
    this.subscribesToRiskAssessment = this.utilService.subscribesTo('subscription_risk_assessment');
    this.subscribesToProjects = this.utilService.subscribesTo('subscription_projects');
    this.subscribesToExposures = this.utilService.subscribesTo('subscription_exposures');
    this.subscribesToPurchase = this.utilService.subscribesTo('subscription_purchase');
    this.subscribesToSubstitution = this.utilService.subscribesTo('subscription_substitution');
    this.subscribesToDistribution = this.utilService.subscribesTo('subscription_distribution');
    this.subscribesToSamtec = this.utilService.subscribesTo('subscription_samtec');
    this.subscribesToLawLawpoint = this.utilService.subscribesTo('subscription_lawpoint');
    this.subscribesToLawAptor = this.utilService.subscribesTo('subscription_law_aptor');
    this.subscribesToCoordinator = this.utilService.subscribesTo('subscription_coordinator');
    this.subscribesToProducts = this.utilService.subscribesTo('subscription_products');
    this.subscribesToReports = this.utilService.subscribesTo('subscription_reports');
    this.subscribesToEducation = this.utilService.subscribesTo('subscription_education');
    this.subscribesToProductApplication = this.utilService.subscribesTo('subscription_product_application');

    this.SetupUserOrganisations();

    this.organisationService.setCatalogueList.pipe(take(1)).subscribe((msg: any) => {

      this.gotoCatalogue();
      this.ngOnInit();
    })

    this.organisationService.setProductList.pipe(take(1)).subscribe((msg: any) => {

      this.gotoProducts();
      this.ngOnInit();
    })
    this.organisationService.setCoordinatorList.pipe(take(1)).subscribe((msg: any) => {

      this.goToCoordinator();
      this.ngOnInit();
    })
    this.organisationService.setSubstitutionList.pipe(take(1)).subscribe((msg: any) => {

      this.goToSubstitution();
      this.ngOnInit();
    })
    this.organisationService.setRiskAssessmentActivityList.pipe(take(1)).subscribe((msg: any) => {

      this.goToAssessment();
      this.ngOnInit();
    })

    this.organisationService.setRiskAssessmentList.pipe(take(1)).subscribe((msg: any) => {

        this.goToAssessmentChemical();
        this.ngOnInit();
    })
  }

  SetupUserOrganisations() {

    if (this.currentUser && this.currentUser.is_super_user) {
      this.organisationService.getAllCompanies();
      this.organisationService.getOrganisationNavDataListener().pipe(take(1)).subscribe({
        next: organisations => {
          if (organisations != null)
          {
            this.organisationList = organisations;
          }
          else {
            this.clearOrg()
          }
        },
        error: error => {
          this.clearOrg()
        }
      })
    }
  }

  clearOrg() {
    this.selectedOrganisation = null;
    localStorage.removeItem("selectedOrganisation");
    localStorage.removeItem("selectedDepartment");
    localStorage.removeItem("allDepartments");
    localStorage.removeItem("changeOrg");
  }

  showSelectOrganisationModal() {
    if ( this.unsavedChangesService.getUnsavedChanges()==true ) {
      this.unsavedChangesService.setUnsavedChanges(false); // Reset the unsaved changes flag
      Swal.fire({
        title: "" + this.translate.instant("LEAVE_BEFORE_SAVE") + "",
        text: "" + this.translate.instant("LEAVE_BEFORE_SAVE_TEXT") + "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.translate.instant("OK"),
        cancelButtonText: this.translate.instant("ABORT"),
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('productSearch');
          const modalRef = this.modalService.open(ModalSelectOrganisationComponent);
          modalRef.componentInstance.orgList = this.organisationList;
          modalRef.componentInstance.passEntry.pipe(take(1)).subscribe(() => {
          localStorage.setItem('changeOrg', 'true');
          window.location.reload();
        })
        } else {
          return;
        }
      });
  } else {
    localStorage.removeItem('productSearch');
    const modalRef = this.modalService.open(ModalSelectOrganisationComponent);
    modalRef.componentInstance.orgList = this.organisationList;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe(() => {
      localStorage.setItem('changeOrg', 'true');
      window.location.reload();
    });
  }
}

  logout() {
    this.authService.logout();
  }

  getActiveLink() {
    var activeLink = localStorage.getItem('navActiveLink', );
    if (!activeLink || (activeLink == null))
    {
      this.clearActiveLinks();
      this.isHome = true;
    }
    else
    {
      if (activeLink == 'home')
      {
        this.isHome = true;
      }
      else if (activeLink == 'controlpanel')
      {
        this.isControlPanel = true;
      }
      else if (activeLink == 'catalogue')
      {
        this.isCatalogue = true;
      }
      else if (activeLink == 'product')
      {
        this.isProducts = true;
      }
      else if (activeLink == 'assessment')
      {
        this.isAssessment = true;
      }
      else if (activeLink == 'administration')
      {
        this.isAdministration = true;
      }
      else if (activeLink == 'law')
      {
        this.isLaw = true;
      }
      else if (activeLink == 'education')
      {
        this.isEducation = true;
      }
      else if (activeLink == 'report')
      {
        this.isReports = true;
      }
      else if (activeLink == 'materiallist')
      {
        this.isMaterialList = true;
      }
    }
  }

  clearActiveLinks() {
    localStorage.removeItem('productSearch');
    this.isHome = false;
    this.isControlPanel = false;
    this.isSettings = false;
    this.isCatalogue = false;
    this.isProducts = false;
    this.isMaterialList = false;
    this.isReports = false;
    this.isAssessment = false;
    this.isAdministration = false;
    this.isLaw = false;
    this.isSamtec = false;
    this.isEducation = false;
    localStorage.setItem('navActiveLink', null);
  }

  navigateHome(){
    if(this.currentUser && this.currentUser.is_guest==1){
      return;
    }
    this.clearActiveLinks();
    this.isHome = true;
    localStorage.setItem('navActiveLink', 'home');

    this.router.navigate(['/dashboard']);
  }

  gotoCatalogue(){
    this.clearActiveLinks();
    this.isCatalogue = true;
    localStorage.setItem('navActiveLink', 'catalogue');

    this.router.navigate(['/catalogue']);
  }

  gotoProducts() {
    this.clearActiveLinks();
    this.isProducts = true;
    localStorage.setItem('navActiveLink', 'product');

    this.router.navigate(['/productlist']);
  }

  goToCoordinator() {
    this.clearActiveLinks();
    this.isAdministration = true;
    localStorage.setItem('navActiveLink', 'administration');

    this.router.navigate(['/coordinator']);
  }
  goToSubstitution() {
    this.clearActiveLinks();
    this.isAdministration = true;
    localStorage.setItem('navActiveLink', 'administration');

    this.router.navigate(['/substitution']);
  }
  goToExposures() {
    this.clearActiveLinks();
    this.isAdministration = true;
    localStorage.setItem('navActiveLink', 'administration');

    this.router.navigate(['/exposures']);
  }

  goToHistory() {
    this.clearActiveLinks();
    this.isAdministration = true;
    localStorage.setItem('navActiveLink', 'administration');

    this.router.navigate(['/history']);
  }

  goToProductApplication( ) {
    this.clearActiveLinks();
    this.isAdministration = true;
    localStorage.setItem('navActiveLink', 'administration');

    this.router.navigate( ['/productapplicationlist'] );
  }

  goToAssessment() {
    this.clearActiveLinks();
    this.isAssessment = true;
    localStorage.setItem('navActiveLink', 'assessment');

    this.router.navigate(['/assessments']);
  }

  goToAssessmentChemical() {
    this.clearActiveLinks();
    this.isAssessment = true;
    localStorage.setItem('navActiveLink', 'assessment');


    this.router.navigate(['/assessmentschemical']);
  }
  gotoReports() {
    this.clearActiveLinks();
    this.isReports = true;
    localStorage.setItem('navActiveLink', 'report');


    this.router.navigate(['/reports']);
  }
  gotoMaterialList() {
    this.clearActiveLinks();
    this.isMaterialList = true;
    localStorage.setItem('navActiveLink', 'materiallist');

    this.router.navigate(['/materiallist']);
  }

  gotoLaw() {
    this.clearActiveLinks();
    this.isLaw = true;
    localStorage.setItem('navActiveLink', 'law');

    this.router.navigate(['/law']);
  }
  gotoEducation() {
    this.clearActiveLinks();
    this.isEducation = true;
    localStorage.setItem('navActiveLink', 'education');

    var link="https://portal.cloocast.com"
    window.open(link,'_blank');
  }

  gotoSamtec() {
    this.clearActiveLinks();
    this.isSamtec = true;
    localStorage.setItem('navActiveLink', 'samtec');

    if(!this.currentUser.samtectoken){
      window.open('https://portal.samtec.se/','_blank');
      return;
    }
    var link=this.translate.instant("SAMTEC_LINK_PROD")+this.currentUser.samtectoken;
    window.open(link,'_blank');
  }

  goToHelp() {
    this.clearActiveLinks();
    localStorage.setItem('navActiveLink', 'null');

    this.router.navigate(['/help']);
  }

  goToSettings() {
    this.clearActiveLinks();
    localStorage.setItem('navActiveLink', 'null');

    this.router.navigate(['/userprofile']);
  }

  showContactForm() {
    this.clearActiveLinks();
    localStorage.setItem('navActiveLink', 'null');

    this.router.navigate(['/contactform']);
  }
  gotoControlPanel() {
    this.clearActiveLinks();
    this.isControlPanel = true;
    localStorage.setItem('navActiveLink', 'controlpanel');

    this.router.navigate(['/controlpanel']);
  }
}
