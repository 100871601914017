import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';
import { take } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UserService } from "src/app/services/user.service";
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-newfreemium',
  templateUrl: './newfreemium.component.html',
  styleUrls: ['./newfreemium.component.css']
})
export class NewfreemiumComponent implements OnInit {
  organisation: any;
  selectedDepartment: any;
  showExtendedCompanySearchList = false;
  showAllCompanies = false;
  displayedCompanys:any = [];
  itemsPerPage:number = 5;
  pageNumber:number = 1;
  listAlldepartments: any;
  loadingData:boolean = false;
  organisationMoreInfo: any = [];
  subscriptionMoreInfo: any = [];
  economy: any = [];
  delivery: any = [];
  subscriptions: any = [];
  freemiumOrganisation: any = [];
  freemiumUser: any = [];
  companySearch = {
    all: "",
    name: "",
    supplier_name: "",
    group_name: ""
};
sortDirection='asc';
sortDirectionArray=['asc','asc','asc'];
removeCompany;

Freemium =  ["subscription_freemium",
              "subscription_catalogue",
              "subscription_products",
              "subscription_report_simple",
              "subscription_reports",
              "subscription_sds"];

constructor(private utilService: UtilService, private staticDataService: StaticDataService,
  private organisationService: OrganisationService, private userService: UserService,
  private router:Router, private translate: TranslateService) { }

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));

    this.organisationMoreInfo = {
      gan : false,
      sms : false,
      kam : "",
      koncern : "",
      reseller : "",
      numberOfEmployees:"",
    };

    this.subscriptionMoreInfo = {
      typeOfSubscription:"Freemium",
      numberOfChemicals:null,
      HGV:false,
      newChemicaladministrator:false,
      API:false,
      SundaHus:false,
      revision:"",
      revisionForeignLanguage:"",
      trygghetspaket:false,
      sevesoRiskassessment:false,
      pictures:false,
      history:false,
      MFaktorRevision:false,
      EHSConsult:null,
      governmentRep:false,
      cdocSupport:false,
      yearlyEnvironmentReport:false,
      siteInspector:false
    }

    this.economy = {
      orderNumber:null,
      numberFortnox:null,
      replacenumberFortnox:null,
      paymentDate:"",
      deliveryDate:"",
      terminationDate:"",
      subscriptionEnd:"",
      contractPeriod:null,
      annualLicense:null,
      historicLicense:null,
      entryCostLawpoint:null,
      entryCostSamtec:null,
      entryCostCloocast:null
    };

    this.delivery = {
      contractDate:"",
      workshop:"",
      customerCaller:"",
      welcomeCall:"",
      welcomeCallPerformed:"",
      chemicalListArrivedPlanned:"",
      organisationListArrived:"",
      chemicalListArrived:"",
      accountCreatePlanned:"",
      accountCreatedDone:"",
      instructionFilmSent:"",
      specialinformation:"",
      lawpoint:"",
      samtec:"",
      aptor:"",
      lawpointMail:"",
      setupPlannedDate:"",
      setupDoneDate:"",
      deviations:null,
      comments:null,
      revisionStarted:"",
      revisionDone:"",
    };

    this.subscriptions = this.SetDefaultOrganisationSubscriptions();
    for (var n=0;n<this.Freemium.length;n++){
      this.subscriptions.find(({ identifier }) => identifier === this.Freemium[n]).active=true;
    }    

    this.getAllDepartments();
    // needs a small wait time to not recieve wrong data
    setTimeout( () =>{this.loadFrmCompanies()},500)
  }

  SetDefaultOrganisationSubscriptions() {
    var i,
      subscriptions = this.staticDataService.getAvailableSubscriptions();

    for (i = 0; i < subscriptions.length; i += 1) {
      if (
        subscriptions[i].identifier === "LARS" // just to not get error
      ) {
        subscriptions[i].active = true;
      } else {
        subscriptions[i].active = false;
      }
    }
    return subscriptions;
  }

  loadFrmCompanies() {
    this.organisationService.getNewFreemiumCompanies();
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: organisations => {
        this.displayedCompanys = organisations;
        this.displayedCompanys.sort(this.sortByProperty("register_time",'desc'));
      },
      error: error => {
        console.log("Error: ", error);
        return (null);
      }
    })

  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  gotoControlPanel(){
    this.router.navigate(['/controlpanel']);
  }

  createNewCompany(company){
    this.freemiumOrganisation = {
      name: company.company_name,
      org_nr: company.org_nr,
      address_1: null,
      address_2: null,
      address_visit: null,
      zip: null,
      city: null,
      country: "Sverige",
      www: null,
      email: company.contact_person_email,
      phone: company.contact_person_phone,
      contact_person: company.contact_person_lastname,
      resp_consultant: null,
      misc: null,
      subscriptions: [],
      customer_type: 1,
    };

    this.freemiumUser = {
      id: "",
      email: company.contact_person_email,
      first_name: company.contact_person_firstname,
      hashed_password: "",
      is_admin: 1,
      is_super_user: 0,
      is_useradmin: 1,
      is_local_assignee: 0,
      is_local_admin: 0,
      is_readonly_user: 0,
      is_maintenance_user: 0,
      is_local_user: 0,
      is_guest: 0,
      is_local_user_department: "",
      last_name: company.contact_person_lastname,
      phone: company.contact_person_phone,
      root_organisation_id: "",
      salt: "",
      static_token: "",
      password: "",
      username: company.contact_person_email,
    };

    this.freemiumOrganisation.organisationMoreInfo=this.organisationMoreInfo;
    this.freemiumOrganisation.subscriptionMoreInfo=this.subscriptionMoreInfo;
    this.freemiumOrganisation.economy=this.economy;
    this.freemiumOrganisation.delivery=this.delivery;
    this.freemiumOrganisation.subscriptions = this.subscriptions;

    var tempsubscription = [];
    this.freemiumOrganisation.subscriptions = _.cloneDeep(this.subscriptions);
    for (var i = 0; i < this.freemiumOrganisation.subscriptions.length; i += 1) {
      if (this.freemiumOrganisation.subscriptions[i].active == true) {
        tempsubscription.push(this.freemiumOrganisation.subscriptions[i]);
      }
    }
    this.freemiumOrganisation.subscriptions = tempsubscription;

    var updateInfo = {
      process_status: "1",
    };
      
    this.organisationService.createSingle(this.freemiumOrganisation);
    this.organisationService
      .getOrganisationDataListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response != null)
          {
            this.freemiumUser.root_organisation_id = response.id;
            this.userService.CreateUser(this.freemiumUser);
            this.userService.getCurrentUserDataListener().pipe(take(1))
              .subscribe({
                next: (response2) => {
                  this.organisationService.updateFrmUser(company.id, updateInfo);
                  this.organisationService
                    .getOrganisationDataListener()
                    .pipe(take(1))
                    .subscribe({
                      next: (response3) => {
                        this.organisationService.updateSingle(
                          this.freemiumUser.root_organisation_id,
                          this.freemiumOrganisation
                        );
                        this.organisationService
                          .getOrganisationDataListener()
                          .pipe(take(1))
                          .subscribe({
                            next: () => {
                              Swal.fire({
                                title: this.translate.instant("ORGANISATION_CREATED"),
                                timer: 2000,
                              });
                              this.ngOnInit();
                            },
                            error: (error) => {
                              this.utilService.onError(error);
                            },
                          });
                      },
                      error: (error) => {
                        console.log("Error: ", error);
                        return null;
                      },
                    });
                },
                error: (error) => {
                  Swal.fire({
                    title: "User!",
                    text: "Error when creating user!",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: this.translate.instant("OK"),
                  });
                },
            });
          }
          else
          {
            Swal.fire({
              title: "Company!",
              text: "Error when creating company!",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK"),
            });
          }
        },
        error: (error) => {
          this.utilService.onError(error);
        },
      });
  }

  removeNewCompany(company){
    var updateInfo = {
      process_status : "2",
    };
    this.organisationService.updateFrmUser(company.id, updateInfo);
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: response => {
          this.loadFrmCompanies();
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
  }

  // this is descending, for ascending reverse -1 and 1
  sortByProperty(property,order){
    if(order=='desc'){
      return function(a,b){
        if(a[property] > b[property])
           return -1;
        else if(a[property] < b[property])
          return 1;

        return 0;
     }
    }else{
      return function(a,b){
        if(a[property] > b[property])
           return 1;
        else if(a[property] < b[property])
          return -1;

        return 0;
     }
    }
  }

  tableSort(type,index){
    this.sortDirection=this.sortDirectionArray[index]=='desc'?'desc':'asc';
    this.sortDirectionArray[index]=this.sortDirectionArray[index]=='desc'?'asc':'desc';
    if(this.sortDirection=='desc'){
      this.displayedCompanys.sort((a, b) => (a[type]?.toLowerCase() > b[type]?.toLowerCase()) ? -1 : 1);
    }
    else if(this.sortDirection=='asc'){
      this.displayedCompanys.sort((a, b) => (a[type]?.toLowerCase() > b[type]?.toLowerCase()) ? 1 : -1);
    }

  }
}
