import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SupportService } from 'src/app/services/support.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  unamePattern = "[a-zA-Z ]*";
  pwdPattern = "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$";
  message: string;
  isRecovery: boolean;
  loadingButton: boolean;
  today: Date;
  access_token_recv: any = null;
  username2;
  username: any;
  password: any;
  mailsentClicked:boolean=false;
  private authListenerSubs: Subscription;
  forgotPassword:boolean=false;

  constructor(private formBuilder:FormBuilder, private authService:AuthService, private activatedRoute: ActivatedRoute,
              private router:Router, private translate: TranslateService,private route: ActivatedRoute,private supportService: SupportService) { }

  ngOnInit(): void {
    this.loadingButton = true;
    this.today = new Date();
    this.isRecovery = false;

    this.access_token_recv = this.activatedRoute.snapshot.queryParams["access_token"];
    if (this.access_token_recv != null) {
      this.login();
    }
  }

  login() {

    this.authService.login(this.username, this.password, this.access_token_recv);
    this.authListenerSubs = this.authService
      .getAuthServiceListener()
      .pipe(take(1)).subscribe(isAuthenticated => {
        if (isAuthenticated == false){
          Swal.fire({
            title: this.translate.instant("COULD_NOT_LOGIN"),
            text: this.translate.instant("WRONG_USER_PASS"),
            icon: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK")
          });
        }
    });
  }

  logout() {
    this.authService.logout();
  }

  goToNewRegister() {
    this.router.navigate(['/register']);
  }

  goBack(){
    this.username2 = "";
    this.mailsentClicked = false;
    this.forgotPassword=!this.forgotPassword;

  }

  mailsent() {
    if(this.mailsentClicked||!this.username2||this.username2==''){
      return;
    }
    // Mail functionality
    var object = {
      name: this.username2,
      email: 'example@example.com',
      cc: false,
      subject: "Glömt lösenord",
      body:
        "Användare: " + this.username2 + "\n" +
        "Har glömt sitt lösenord" + "\n" +
        "--------------------------------------------\n"

    };

          this.supportService.forgotPassword(object);
          this.supportService.getSupportDataListener().pipe(take(1)).subscribe({
            next: pdfContent => {
              this.mailsentClicked = true;
            },
            error: error => {
              console.log("Error: ", error);
              Swal.fire({
                title: this.translate.instant("AN_ERROR_OCCURED"),
                text: this.translate.instant("YOUR_MESSAGE_HAS_NOT_BEEN_SENT"),
                icon: "error",
                showCancelButton: false,
              });
            }
          })
  }
}
