<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
  <!-- Modal Content -->
  <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
    <div class="flex justify-end">
      <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
    </div>
    <div class="mt-3 text-center">
      <div class="text-gray-900 font-bold">{{"REPORT_GRB" | translate}}</div>
      <!-- Modal Body -->
      <div class="flex flex-col center-items mt-4 p-2">
        <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
            <div class="loader"></div>
        </div>

        <div *ngIf="loadingData==false" class="overflow-auto mb-20">
          <div class="flex justify-between">
            <div class="flex flex-row items-center space-x-2 mt-2">
              <input title="{{'SELECT_ALL' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" (click)="toggleAllDoc($event.target.checked)">
              <span class="text-sm mr-1">{{"SELECT_ALL" | translate}}</span>
            </div>
            <button type="button" class="button-green text-white mt-4" (click)="printGrb()">
                {{"PRINT" | translate}}
            </button>
          </div>

          <div class="flex flex-row">
            <div class="mb-4 mt-4 mr-2">
              <input st-search="" type="search" [(ngModel)]="reportSearch.all" class="prompt" (ngModelChange)="reportSearch.all=$event;filterByType('all')"
                  class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
            </div>
            <div class="mb-4 mt-4 mr-2">
              <input st-search="" type="search" [(ngModel)]="reportSearch.riskPrio" class="prompt" (ngModelChange)="reportSearch.riskPrio=$event;filterByType('riskPrio')"
                  class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_RISK_PRIO' | translate}} ..." />
            </div>
            <div class="mb-4 mt-4">
              <input st-search="" type="search" [(ngModel)]="reportSearch.restrictionList" class="prompt" (ngModelChange)="reportSearch.restrictionList=$event;filterByType('restrictionList')"
                  class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_RESTRICTION_LIST' | translate}} ..." />
            </div>
          </div>

          <div class="flex justify-start">
            <div class="relative inline-block text-left" appDropdown>
              <button type="button" class="button-gray">
                  <span class="text-sm">{{"NUMBER_OF" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                  <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </button>
              <div id="dropdownMenu" class="hidden dropdown-menu left-40" #dropdownMenuInside>
                  <div class="py-1">
                    <a class="dropdown-menu-item" (click)="itemsPerPage=5;pageNumber=1;" tabindex="1">5</a>
                    <a class="dropdown-menu-item" (click)="itemsPerPage=10;pageNumber=1;" tabindex="2">10</a>
                    <a class="dropdown-menu-item" (click)="itemsPerPage=25;pageNumber=1;" tabindex="3">25</a>
                    <a class="dropdown-menu-item" (click)="itemsPerPage=100;pageNumber=1;" tabindex="4">100</a>
                  </div>
              </div>
            </div>
          </div>

          <table class="table-auto mt-4 w-full">
              <thead>
                <th class="table-row-header" (click)="sortField = 'supplier_name'; sortAscending = !sortAscending;sortItem('supplier_name',0)">
                  <div class="flex items-center">
                    {{"MANUFACTURER" | translate}}
                    <a>
                      <svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
                      </svg>
                    </a>
                  </div>
                </th>
                <th class="table-row-header"  (click)="sortField = 'name'; sortAscending = !sortAscending;sortItem('name',1)">
                  <div class="flex items-center">
                    {{"NAME" | translate}}
                    <a>
                      <svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
                      </svg>
                    </a>
                  </div>
                </th>
                <th class="table-row-header"  (click)="sortField = 'fullName'; sortAscending = !sortAscending;sortItem('fullName',2)">
                  <div class="flex items-center">
                    {{"DEPARTMENT" | translate}}
                    <a>
                      <svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
                      </svg>
                    </a>
                  </div>
                </th>
                <th class="table-row-header"  (click)="sortField = 'risk_prio'; sortAscending = !sortAscending;sortItem('risk_prio',3)">
                  <div class="flex items-center">
                    {{"GENERAL_RISK_ASSESSMENT" | translate}}
                    <a>
                      <svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
                      </svg>
                    </a>
                  </div>
                </th>
                <th class="table-row-header">{{"RESTRICTION_LISTS" | translate}}</th>
                <th class="table-row-header">{{"CHOOSE" | translate}}</th>
              </thead>
              <tbody>
                <tr *ngFor="let product of reportInformation.products | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber, id: 'GRB_report_table' }; let i = index">
                  <td class="table-row-column">{{product.supplier_name}}</td>
                  <td class="table-row-column">{{product.name}}</td>
                  <td class="table-row-column">{{product.fullName}}</td>
                  <td class="table-row-column">{{product.risk_prio}}</td>
                  <td class="table-row-column">
                    <ul class="list-disc list-inside mt-2 px-4">
                      <li *ngIf="product.list_amv_a=='1'">{{"RESTRICTION_LIST_AMV_A" | translate}}</li>
                      <li *ngIf="product.list_amv_b=='1'">{{"RESTRICTION_LIST_AMV_B" | translate}}</li>
                      <li *ngIf="product.list_chem_prio_p=='1'">{{"RESTRICTION_LIST_CHEM_PRIO_P" | translate}}</li>
                      <li *ngIf="product.list_chem_prio_u=='1'">{{"RESTRICTION_LIST_CHEM_PRIO_U" | translate}}</li>
                      <li *ngIf="product.list_reach_14=='1'">{{"RESTRICTION_LIST_REACH_14" | translate}}</li>
                      <li *ngIf="product.list_reach_17=='1'">{{"RESTRICTION_LIST_REACH_17" | translate}}</li>
                      <li *ngIf="product.list_reach_candidate=='1'">{{"RESTRICTION_LIST_REACH_CANDIDATE" | translate}}</li>
                      <li *ngIf="subscribesToSIN&&product.list_sin=='1'">{{"RESTRICTION_LIST_SIN" | translate}}</li>
                      <li *ngIf="subscribesToSLL&&product.list_sll=='1'">{{"RESTRICTION_LIST_SLL" | translate}}</li>
                      <li *ngIf="product.list_water_directive=='1'">{{"RESTRICTION_LIST_WATER_DIRECTIVE" | translate}}</li>
                      <li *ngIf="subscribesToBASTA&&product.list_BASTA=='1'">{{"RESTRICTION_LIST_BASTA" | translate}}</li>
                    </ul>
                  <td class="table-row-column">
                    <input title="checkbox" type="checkbox" [ngModel]="product.selected" (click)="toggle($event.target.checked, i)">
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="table-row-column" colspan="2">
                    {{'SHOWING' | translate}} {{'TO' | translate}} {{pageNumber*itemsPerPage-itemsPerPage+1}}-{{pageNumber*itemsPerPage}} {{'OF' | translate}} {{reportInformation.products?.length}}
                  </td>
                  <td class="table-row-column" colspan="4">
                    <pagination-controls id="GRB_report_table" (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event"
                        previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
                </tr>
              </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

