import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/services/product.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { ModalAddGroupComponent } from 'src/app/modals/modal-add-group/modal-add-group.component';
import { ModalEditGroupComponent } from 'src/app/modals/modal-edit-group/modal-edit-group.component';

@Component({
  selector: 'app-product-group',
  templateUrl: './product-group.component.html',
  styleUrls: ['./product-group.component.css']
})
export class ProductGroupComponent implements OnInit {

  currentUser: any;
  isUserProfile: boolean;
  isCompanyUsers: boolean;
  isDepartmentSetup: boolean;
  isGroups: boolean;
  isCompanyInfo: boolean;
  isArticles: boolean;
  isStampedSds: boolean;
  isMisc: boolean;
  subscription_stamped_SDS: boolean=false;
  subscribesToFreemium: boolean;
  currentLanguage: any;
  newGroupName: any;
  organisation: any;
  groups: any;

  constructor(private router: Router,
              private authService:AuthService,
              private utilService:UtilService,
              private modalService: NgbModal,
              private productService: ProductService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = true;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;
    this.subscription_stamped_SDS = false;
    this.subscribesToFreemium = false;
    this.currentLanguage = localStorage.getItem('usedLanguage');
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscription_stamped_SDS = this.utilService.subscribesTo("subscription_stamped_SDS");
    this.subscribesToFreemium = this.utilService.subscribesTo("subscription_freemium");
    this.getAllGroups();
  }

  logout() {
    this.authService.logout();
  }

  getAllGroups() {
    if (this.organisation) {
      this.productService.getGroups(this.organisation.id);
      this.productService.getProductGroupDataListener().pipe(take(1)).subscribe({
        next: groups => {
          if (groups != null) {
            this.groups = groups;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  AddNewGroup() {
    const modalRef = this.modalService.open(ModalAddGroupComponent);

    modalRef.componentInstance.newGroupName = this.newGroupName;

    modalRef.componentInstance.passEntry.subscribe((receivedGroupName) => {
      this.productService.createNewGroup(this.organisation.id, receivedGroupName);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: group => {
          if (group != null) {
            window.location.reload();
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    })
  }

  editGroup(group) {
    const modalRef = this.modalService.open(ModalEditGroupComponent);
    modalRef.componentInstance.groupName = group.name;
    modalRef.componentInstance.newGroupName = this.newGroupName;

    modalRef.componentInstance.passEntry.subscribe((receivedGroupName) => {
      group.name = receivedGroupName;
      this.productService.updateGroup(this.organisation.id, group);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: group => {
          if (group != null) {
            window.location.reload();
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    })
  }

  removeGroup(group) {
    Swal.fire({
      title: this.translate.instant("REMOVE_PRODUCT_GROUP") + "?",
      text: this.translate.instant("REMOVE_PRODUCT_GROUP_WARNING") + " " + group.name + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.removeGroup(this.organisation.id, group);
        this.productService.getProductDataListener().pipe(take(1)).subscribe({
          next: group => {
            if (group != null) {
              window.location.reload();
            }
          },
          error: error => {
            console.log("Error: ", error);
          }
        })
      }
    })
  }

  showUserProfile() {
    this.isUserProfile = true;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/userprofile']);
  }

  showCompanyUsers() {
    this.isUserProfile = false;
    this.isCompanyUsers = true;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyusers']);
  }

  showDepartmentSetup() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = true;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/editdepartments']);
  }

  showGroups() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = true;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/productgroup']);
  }

  showCompanyInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = true;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyinfo']);
  }

  showArticles() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = true;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyarticles']);
  }

  showStampedSds() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = true;
    this.isMisc = false;

    this.router.navigate(['/stampedsds']);
  }

  showMiscInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = true;

    this.router.navigate(['/companymisc']);
  }
}
