import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { ProductService } from "src/app/services/product.service";
import { AuthService } from "../../auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from 'src/app/services/user.service';
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { StaticDataService } from "src/app/services/static-data.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: "app-products-edit-all",
  templateUrl: "./products-edit-all.component.html",
  styleUrls: ["./products-edit-all.component.css"],
})
export class ProductsEditComponent implements OnInit {
  itemsPerPage: number = null;
  pageNumber: number = 1;
  loadingData: boolean = true;
  listAlldepartments: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  products: any = [];
  productsOverride: any = [];
  showSubdepartments: boolean = true;
  currentLanguage: any;
  sortDirection: any = null;
  sortValue: any = null;
  productSearch = {
    all: null,
    name: null,
    alternative_name: null,
    supplier_name: null,
  };
  selectedProducts;
  changedProducts;
  units;
  groups;
  order = ["asc", "asc","asc"];

  ngAfterViewInit(): void {
    this.getGroups();
  }

  ngOnDestroy(): void {}

  constructor(
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private staticDataService: StaticDataService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.units = this.staticDataService.getUnitDefinitions();
    this.loadingData = true;
    this.organisation = JSON.parse(localStorage.getItem("rootOrganisation"));
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );
    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );
    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null
    ) {
      this.logout();
    }

    this.showSubdepartments = JSON.parse(
      localStorage.getItem("showSubdepartments")
    );
    if (this.showSubdepartments == null) {
      this.showSubdepartments = true;
      localStorage.setItem(
        "showSubdepartments",
        JSON.stringify(this.showSubdepartments)
      );
    }

    this.sortDirection = localStorage.getItem("sortDirection");
    if (this.sortDirection == null) {
      this.sortDirection = "asc";
    }

    this.sortValue = localStorage.getItem("sortValue");
    if (this.sortValue == null) {
      this.sortValue = "name";
    }

    this.currentLanguage = localStorage.getItem("usedLanguage");
    switch (this.currentLanguage) {
      case "sv":
        this.currentLanguage = 0;
        break;

      case "en":
        this.currentLanguage = 1;
        break;

      case "fi":
        this.currentLanguage = 2;
        break;

      default:
        this.currentLanguage = 0;
        break;
    }

    var data = localStorage.getItem("currentUser");
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }

    this.itemsPerPage = 10;

    this.getAllDepartments();
    this.loadTableData(this.selectedDepartment, false);
    this.getItemsPerPage();

    this.organisationService.departmentChanged
      .pipe( take( 1 ) )
      .subscribe( ( msg: any ) => {
        this.utilService.departmentChangeClicked(this.router.url);
        Swal.fire( {
          title: this.translate.instant("LEAVE_WITHOUT_SAVING"),
        } ).then( () => {
          this.router.navigate( [ '/productlist' ] );
        } );
      } );
    }

  logout() {
    this.authService.logout();
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  loadTableData(department, force) {
    if (_.isObject(department)) {
      this.getProducts().then((products) => {
        for (var n = 0; n < 10000; n++) {}
        if (products != null) {
          this.productsOverride = products;
          var ids = [];
          for (var i = 0; i < this.productsOverride.length; i++) {
            ids.push({
              id: this.productsOverride[i].sareq_id,
              lang: this.currentLanguage,
            });
          }
          this.getOtherLanguages(ids);

          for (var i = 0; i < this.productsOverride.length; i++) {
            if (this.productsOverride[i].override != null) {
              this.productsOverride.splice(i, 1);
            }
          }

          this.products = [];

          setTimeout(() => {
            this.products = this.productsOverride;
            this.loadingData = false;
          }, 500);

          this.selectedProducts = _.cloneDeep(this.productsOverride);
          this.changedProducts  = _.cloneDeep(this.productsOverride);
        }
      });
    }
  }

  getProducts() {
    return new Promise<any>((resolve) => {
      this.productService.getAllForEdit(this.selectedDepartment.id);
      this.productService
        .getProductsDataListener()
        .pipe(take(1))
        .subscribe({
          next: (products) => {
            resolve(products);
          },
          error: (error) => {
            console.log("Error: ", error);
            this.loadingData = false;
            this.utilService.onError(error);
          },
        });
    });
  }

  getAllDepartments() {
    if (
      this.listAlldepartments == null ||
      (this.organisation &&
        this.organisation.id !=
          JSON.parse(localStorage.getItem("rootOrganisation")).id)
    ) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService
        .getOrganisationAllDepartmentNamesDataListener()
        .pipe(take(1))
        .subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;
            }
          },
          error: (error) => {
            console.log("Error: ", error);
            return null;
          },
        });
    } else {
      return null;
    }
  }

  filterSearchItems() {
    this.changedProducts = this.changedProducts.map( changedProduct => {
      const productOverride = this.productsOverride.find (
        productOverride => productOverride.sareq_id === changedProduct.sareq_id
      );

      if (productOverride) {
        return {
          ...changedProduct,
          unit:  (typeof productOverride.unit === 'object' && productOverride.unit !== null) ? productOverride.unit.id : productOverride.unit,
          group_name: productOverride.group ? productOverride.group.name : productOverride.group_name
        };
      } else {
        return changedProduct;
      }
    });

    this.productsOverride = [...this.changedProducts];

    if (this.productSearch.all == "") {
      this.productSearch.all = null;
    }
    if (this.productSearch.name == "") {
      this.productSearch.name = null;
    }
    if (this.productSearch.alternative_name == "") {
      this.productSearch.alternative_name = null;
    }
    if (this.productSearch.supplier_name == "") {
      this.productSearch.supplier_name = null;
    }
    if (this.productSearch.all != null) {
      this.productsOverride = this.productsOverride.filter(
        (o) =>
          JSON.stringify(o)
            .toLowerCase()
            .indexOf(this.productSearch.all.toLowerCase()) > -1
      );
    }
    if (this.productSearch.name != null) {
      this.productsOverride = this.productsOverride.filter(
        (o) =>
          o.name?.toLowerCase().indexOf(this.productSearch.name.toLowerCase()) >
          -1
      );
    }
    if (this.productSearch.alternative_name != null) {
      this.productsOverride = this.productsOverride.filter(
        (o) =>
          o.alternative_name?.toLowerCase().indexOf(this.productSearch.alternative_name.toLowerCase()) >
          -1
      );
    }
    if (this.productSearch.supplier_name != null) {
      this.productsOverride = this.productsOverride.filter(
        (o) =>
          o.supplier_name
            ?.toLowerCase()
            .indexOf(this.productSearch.supplier_name.toLowerCase()) > -1
      );
    }
  }

  goToProducts() {
    this.router.navigate( [ "/productlist" ] ).then( () => {
      window.location.reload();
    } );
  }

  searchProducts(item1, item2) {}

  sortItem(type, id) {
    if (this.order[id] == "asc") {
      this.order[id] = "desc";
      this.productsOverride = _.orderBy(this.productsOverride, [type], ["asc"]);
    } else if (this.order[id] == "desc") {
      this.order[id] = "asc";
      this.productsOverride = _.orderBy(
        this.productsOverride,
        [type],
        ["desc"]
      );
    }
  }
  addProduct(product) {}
  trackById(index: number, el: any): number {
    return el;
  }

  setUnit(product, value) {
    for (var i = 0; i < this.units.length; i++) {
      if (value == this.units[i].id) {
        product.unit = this.units[i];
        break;
      }
    }
  }
  setGroupName(product, value) {
    for (var i = 0; i < this.groups.length; i++) {
      if (value == this.groups[i].name) {
        product.group = this.groups[i];
        product.group_name = product.group.name;
        break;
      }
    }
  }

  getGroups() {
    if (this.selectedDepartment) {
      this.productService.getGroups(this.selectedDepartment.id);
      this.productService
        .getProductGroupDataListener()
        .pipe(take(1))
        .subscribe({
          next: (groups) => {
            this.groups = groups;
            this.groups = _.sortBy(this.groups, "name");
          },
          error: (error) => {
            console.log("Error: ", error);
          },
        });
    }
  }

  removeProduct(product){
    Swal.fire({
      title: this.translate.instant("REMOVE") + "?",
      input: 'text',
      html: this.translate.instant("CONFIRM_REMOVE_PRODUCT") + " </br></br><b>" + product.name + " </b></br></br>" + this.translate.instant("CONFIRM_REMOVE_PRODUCT2"),
      showCancelButton: true,
      cancelButtonText: this.translate.instant("NO"),
      confirmButtonText: this.translate.instant("YES"),
      closeOnConfirm: true,
      inputPlaceholder: this.translate.instant("COMMENT")
    }).then((result) => {
      if (result.isConfirmed) {
        product.remove='remove';
        product.comment=result.value;
      }
    });

  }

  getOtherLanguages(products) {
    if (this.currentLanguage != 0) {
      this.productService.checkForOtherLanguages( this.organisation.id, products );
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: otherLangs => {
          if (otherLangs == null) {}
          else {
            for(var i = 0; i < this.productsOverride.length; i++){
              for(var j = 0; j < otherLangs.length; j++) {
                if(this.productsOverride[i].sareq_id == otherLangs[j].override) {
                  this.productsOverride[i].name = otherLangs[j].name;
                  this.productsOverride[i].sds_file_id = otherLangs[j].sds_file_id;
                  this.productsOverride[i].risk_id = otherLangs[j].risk_id;
                  this.productsOverride[i].risk_prio = otherLangs[j].risk_prio;
                  this.productsOverride[i].overridden = true;
                  this.productsOverride[i].origProdId = this.productsOverride[i].id;
                  this.productsOverride[i].id = otherLangs[j].id;
                }
              }
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    } else {
      for( var i = 0; i < this.productsOverride.length; i++ ) {
        if( this.productsOverride[i].override != undefined ) {
          this.productsOverride.splice( i, 1 );
        }
      }
    }
  }

  saveProducts() {
    this.loadingData = true;
    this.changedProducts = this.changedProducts.map(changedProduct => {
      const productOverride = this.productsOverride.find(
        productOverride => productOverride.sareq_id === changedProduct.sareq_id
      );

      if (productOverride) {
        return {
          ...changedProduct,
          unit:  (typeof productOverride.unit === 'object' && productOverride.unit !== null) ? productOverride.unit.id : productOverride.unit,
          group_name: productOverride.group ? productOverride.group.name : productOverride.group_name,
          amount: productOverride.amount,
          annual_consumption: productOverride.annual_consumption,
          maximum_amount: productOverride.maximum_amount,
          remove: productOverride.remove,
          comment: productOverride.comment
        };
      } else {
        return changedProduct;
      }
    })
    this.productsOverride = [...this.changedProducts];
    var itemsToSave = [];
    for ( var n = 0; n < this.selectedProducts.length; n++ ) {
      for ( var o = 0; o < this.productsOverride.length; o++ ) {
        if (
          this.productsOverride[o].sareq_id == this.selectedProducts[n].sareq_id
        ) {
          if (
            this.productsOverride[o].amount != this.selectedProducts[n].amount ||
            this.productsOverride[o].annual_consumption != this.selectedProducts[n].annual_consumption ||
            this.productsOverride[o].maximum_amount != this.selectedProducts[n].maximum_amount ||
            this.productsOverride[o].unit != this.selectedProducts[n].unit ||
            this.productsOverride[o].group_name != this.selectedProducts[n].group_name ||
            this.productsOverride[o].remove == 'remove'
          ) {
            if (typeof this.productsOverride[o].group_name === 'string') {
              this.setGroupName(
                this.productsOverride[o],
                this.productsOverride[o].group_name
              );
            }
            if (typeof this.productsOverride[o].unit === 'string') {
              this.setUnit(
                this.productsOverride[o],
                this.productsOverride[o].unit
              );
            }
            itemsToSave.push(this.productsOverride[o]);
          }
        }
      }
    }
    this.productService
      .saveAllEditedProducts( this.selectedDepartment.id, itemsToSave);

    this.productService
      .getProductDataListener()
      .pipe(take(1))
      .subscribe({
        next: (currentProducts) => {
          this.loadingData = false;
          this.router.navigate( [ "/productlist" ] ).then( () => {
            window.location.reload();
          } );
        },
        error: (error) => {
          this.loadingData = false;
          console.log("Error: ", error);
        },
      });
  }

  isObjectType(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }
}
