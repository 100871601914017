import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from "src/app/services/user.service";
import { AuthService } from '../../auth/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal-order-labels',
  templateUrl: './modal-order-labels.component.html',
  styleUrls: ['./modal-order-labels.component.css']
})
export class ModalOrderLabelsComponent implements OnInit {

  product: any;
  result: any;
  size2: boolean = false;
  size2b: boolean = false;
  size4: boolean = false;
  size4b: boolean = false;
  size8: boolean = false;
  size8b: boolean = false;
  size21: boolean = false;
  size24: boolean = false;
  qrcode: boolean = false;
  printer: boolean = false;
  printerqrcode: boolean = false;
  printer991: boolean = false;
  printer635: boolean = false;

  @Input() thisProduct;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  userStaticToken;

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder,
              private userService:UserService, private authService: AuthService,) { }

  ngOnInit(): void {
    this.product = this.thisProduct;
    this.userService.getCurrentUser('');
          this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
            next: userData => {
              userData=JSON.parse(userData);
              if (userData == null) {

              }
              else {
                if(userData.static_token&&userData.static_token!=""){
                  localStorage.setItem('static_token',userData.static_token);
                }else{
                  localStorage.removeItem('static_token');
                }
              }
            },
            error: error => {

            }
          })

    this.result = {
      email: {
        "size2b": false,
        "size2": false,
        "size4b": false,
        "size4": false,
        "size8": false,
        "size21": false,
        "size24": false,
        "qrcode": false,
        "printer": false,
        "printer991": false,
        "printerqrcode": false,
        "static_token":''
      }
    };
  }

  toggleSize2bCheckbox(event) {
    this.size2b = false;
    if (event.target.checked == true)
    {
      this.size2b = true;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  toggleSize2Checkbox(event) {
    this.size2 = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = true;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8 = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  toggleSize4bCheckbox(event) {
    this.size4 = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4 = false;
      this.size4b = true;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  toggleSize4Checkbox(event) {
    this.size4 = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = true;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  toggleSize8Checkbox(event) {
    this.size8 = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = true;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  toggleSize8bCheckbox(event) {
    this.size8b = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = true;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  toggleSize21Checkbox(event) {
    this.size21 = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = false;
      this.size21 = true;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  toggleSize24Checkbox(event) {
    this.size24 = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = true;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  toggleQrcodeCheckbox(event) {
    this.qrcode = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = true;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  togglePrinterCheckbox(event) {
    this.printer = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = true;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  togglePrinterqrcodeCheckbox(event) {
    this.printerqrcode = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = true;
      this.printer991 = false;
      this.printer635 = false;
    }
  }

  togglePrinter635Checkbox(event) {
    this.printer635 = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = false;
      this.printer635 = true;
    }
  }

  togglePrinter991Checkbox(event) {
    this.printer991 = false;
    if (event.target.checked == true)
    {
      this.size2b = false;
      this.size2 = false;
      this.size4b = false;
      this.size4 = false;
      this.size8 = false;
      this.size8b = false;
      this.size21 = false;
      this.size24 = false;
      this.qrcode = false;
      this.printer = false;
      this.printerqrcode = false;
      this.printer991 = true;
      this.printer635 = false;
    }
  }

  ngOnDestroy(): void {
  }

  save() {
    if(!localStorage.getItem('static_token')||localStorage.getItem('static_token')==''||localStorage.getItem('static_token')==null||localStorage.getItem('static_token')=='null'){
      if(JSON.parse(localStorage.getItem('currentUser')).static_token!=""){
        localStorage.setItem('static_token',JSON.parse(localStorage.getItem('currentUser')).static_token);
      }
    }
    if (this.qrcode) {
      if(!localStorage.getItem('static_token')||localStorage.getItem('static_token')==''||localStorage.getItem('static_token')==null||localStorage.getItem('static_token')=='null'){
        alert('Token saknas för denna användare');
        return;
    }
    this.result.email.qrcode=true;
    this.result.email.static_token=localStorage.getItem('static_token');
    }

    if (this.size21) {
      this.result.email["size21"] = true;
    }

    if (this.size24) {
      this.result.email["size24"] = true;
    }

    if (this.size2b) {
      this.result.email["size2b"] = true;
    }

    if (this.size2) {
      this.result.email["size2"] = true;
    }

    if (this.size4b) {
      this.result.email["size4b"] = true;
    }

    if (this.size4) {
      this.result.email["size4"] = true;
    }

    if (this.size8b) {
      this.result.email["size8b"] = true;
    }

    if (this.size8) {
      this.result.email["size8"] = true;
    }

    if (this.printer) {
      this.result.email["printer"] = true;
    }

    if (this.printerqrcode) {
      this.result.email["printerqrcode"] = true;
      if(!localStorage.getItem('static_token')||localStorage.getItem('static_token')==''||localStorage.getItem('static_token')==null||localStorage.getItem('static_token')=='null'){
        alert('Token saknas för denna användare');
        return;
      }
      this.result.email.static_token=localStorage.getItem('static_token');
      this.result.email.printerqrcode = true;
    }

    if (this.printer991) {
      this.result.email["printer991"] = true;
    }

    if ( this.thisProduct?.LRB_id && this.thisProduct?.LRB_id!='' && this.thisProduct?.LRB_id!=null && this.thisProduct?.LRB_status==3) {
      this.result.LRB_id = this.thisProduct.LRB_id;
      this.result.LRB_status = this.thisProduct.LRB_status;
    }


    this.passEntry.emit(this.result);

    this.activeModal.close(ModalOrderLabelsComponent);
  }

  close() {
    this.activeModal.close(ModalOrderLabelsComponent);
  }

  logout() {
    this.authService.logout();
  }
}
