<div class="flex min-h-screen">
    
    <div class="hidden md:block w-2/3">
        <img class="h-full w-screen" src="images/mountains-4777410_1920.jpg">
    </div>

    <div class="w-full md:w-1/3 bg-black flex items-center justify-center min-h-screen">
        <!-- Form Container -->
        <div class="max-w-md w-full bg-black p-8">
            <div class="text-center text-white">
                <h3>{{"REGISTER" | translate}}</h3>
                <small>{{"REGISTER_PHRASE1" | translate}}</small>
            </div>

            <img class="mx-auto w-50 h-20 mt-10" src="images/LoggacDoc2.png">

            <!-- Input Field 1 (fullname) -->
            <div class="mb-6">
                <label for="username" class="block text-white text-sm font-medium mb-1 mt-10">{{ 'YOUR_NAME' | translate }}</label>
                <input type="text" id="fullname" name="fullname" [(ngModel)]="fullname" (ngModelChange)="fullname=$event" [ngModelOptions]="{standalone: true}" class="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500">
            </div>

            <!-- Input Field 2 (email) -->
            <div class="mb-6">
                <label for="email" class="block text-white text-sm font-medium mb-1">{{ 'YOUR_EMAIL' | translate }}</label>
                <input type="text" id="email" name="email" [(ngModel)]="email" (ngModelChange)="email=$event" [ngModelOptions]="{standalone: true}" class="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500">
            </div>

            <!-- Input Field 3 (company) -->
            <div class="mb-6">
                <label for="company" class="block text-white text-sm font-medium mb-1">{{ 'YOUR_COMPANY' | translate }}</label>
                <input type="text" id="company" name="company" [(ngModel)]="company" (ngModelChange)="company=$event" [ngModelOptions]="{standalone: true}" class="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500">
            </div>

            <!-- Input Field 4 (email) -->
            <div class="mb-6">
                <label for="organisationNumber" class="block text-white text-sm font-medium mb-1">{{ 'ORGANISATION_NUMBER' | translate }}</label>
                <input type="text" id="organisationNumber" name="organisationNumber" [(ngModel)]="organisationNumber" (ngModelChange)="organisationNumber=$event" [ngModelOptions]="{standalone: true}" class="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500">
            </div>

            <div class="flex items-center text-white text-xs">
                <input type="checkbox" [(ngModel)]="termsApproved" (ngModelChange)="termsApproved=$event" [ngModelOptions]="{standalone: true}" id="terms-approved" required>
                <label class="ml-1" for="termsApproved">{{"I_ACCEPT" | translate}} <a class="underline cursor-pointer hover:text-grey" target="_blank" href="https://kemikaliedokumentation.se/allmana-villkor/"><strong>villkoren </strong></a> för kemikaliedokumentation.se</label>
            </div>

            <div class="flex justify-between mt-10">
                <!-- Submit Button -->
                <button class="bg-buttongreen hover:bg-buttongreen-hover text-white font-bold py-2 px-4 rounded" type="submit" (click)="createNewUser()">
                    {{ 'GET_STARTED' | translate }}
                </button>

                <button class="text-white hover:text-gray cursor-pointer" (click)="goToLogin()">{{"BACK" | translate}}</button>
            </div>

            <div class="text-white text-sm py-6 mt-10 text-center">
                <label><small>&copy; {{today | date :  "yyyy" }}{{' '}}{{"COMPANYNAME" | translate}}</small></label>
            </div>
        </div>
    </div>
</div>