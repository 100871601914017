
<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
  <div class="loader"></div>
</div>

<div [hidden]="loadingData" class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
  <div class="flex flex-row justify-between">
    <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
        <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
      </svg>
    </button>

    <button (click)="add()" class="button-cdocblue mt-4">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
            <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        <span class="text-white text-lg">{{"ADD" | translate}}</span>
    </button>
  </div>
</div>

<div *ngIf="loadingData==false" class="mt-4 px-4 py-2 mb-20">
  <div class="overflow-x-auto ">
    <table class="table-auto bg-white mb-4 w-full" st-table="orgList" st-safe-src="orgList">
      <thead>
        <tr>
          <th class="table-row-header-no-border" colspan="12">
              <div class="flex flex-row items-center">
                  <div class="mr-2">
                      <input st-search="" type="search" [(ngModel)]="filterSearch.name" class="prompt" (ngModelChange)="filterSearch.name=$event;filterByType()"
                          class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_NAME' | translate}} ..." />
                  </div>
                  <div>
                      <input st-search="" type="search" [(ngModel)]="filterSearch.subscription" (ngModelChange)="filterSearch.subscription=$event;filterByType()"
                        class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH' | translate}} {{'SUBSCRIPTIONS' | translate}}..." />
                  </div>
              </div>
          </th>
        </tr>
        <tr>
            <th class="table-row-header sort-header" st-sort="name">{{"NAME" | translate}}</th>
            <th class="table-row-header sort-header" st-sort="subscription">{{"SUBSCRIPTIONS" | translate}}</th>
            <th class="table-row-header sort-header" st-sort="date">{{"CREATED_DATE" | translate}}</th>
        </tr>
      </thead>
      <tbody>
          <tr *ngFor="let organisation of filteredOrganisationList
              | orderBy : ['name']
              | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };"
              (click)="selectOrganisation(organisation)" class="tablerow hover:bg-gray-200" id="modalSelectOrganisationButton2">
              <td class="table-row-column">{{organisation.name}}</td>
              <td class="table-row-column">{{organisation.subscriptionMoreInfo?.type_of_subscription}}</td>
              <td class="table-row-column">{{organisation.created_at | date:'yyyy-MM-dd'}}</td>
          </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="p-6" colspan="4">
            <div class="flex flex-row items-center">
              <div class="relative inline-block text-left mr-20" appDropdown>
                  <button type="button" class="button-gray">
                      <span class="text-sm">{{"ORGANISATIONS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                      <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </button>
                  <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                      <div class="py-1">
                          <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                          <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                          <a (click)="itemsPerPage=25;pageNumber=1" class="dropdown-menu-item" tabindex="3">25</a>
                      </div>
                  </div>
              </div>
              <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                  previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>