import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { KebnekaiseService } from "src/app/services/kebnekaise.service";
import { CatalogueService } from "src/app/services/catalogue.service";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: "app-catalogue",
  templateUrl: "./catalogue.component.html",
  styleUrls: ["./catalogue.component.css"],
})
export class CatalogueComponent implements OnInit {
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  listAlldepartments: any;
  products: any = [];
  catalogueSubjectList: any = [];
  sortDirection: any = null;
  itemsPerPage: number = 10;
  pageNumber: number = 1;
  showSubdepartments: boolean = true;
  loadingData: boolean = false;

  catalogueSearch = {
    all: "",
  };

  constructor(
    private utilService: UtilService,
    private translate: TranslateService,
    private organisationService: OrganisationService,
    private catalogueService: CatalogueService,
    private kebnekaiseService: KebnekaiseService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.organisation = JSON.parse(localStorage.getItem("rootOrganisation"));
    this.selectedDepartment = JSON.parse(localStorage.getItem("selectedDepartment"));
    this.selectedOrganisation = JSON.parse(localStorage.getItem("selectedOrganisation"));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    this.getAllDepartments();
    this.loadTableData();
    this.loadingData = false;

    // Change department
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      localStorage.setItem('departmentchanged','set');
      this.products = [];
      this.ngOnInit();
    })
  }

  logout() {
    this.authService.logout();
  }

  departFullName(department) {
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;
            }
          },
          error: (error) => {
            console.log("Error: ", error);
          },
        });
    }
  }

  addCatalogue() {
    localStorage.setItem("catalogueDepartment", JSON.stringify(this.selectedDepartment.id));
    this.router.navigate(['/catalogueedit','new']);
  }

  productSelected(product) {
    localStorage.setItem("catalogueDepartment", JSON.stringify(product.organisation_id));
    var id = product.kebnekaise_id;
    if (_.isString(id)) {
      this.router.navigate(['/catalogueedit', id]);
    }
  }

  openSdsInNewWindow(product) {
    var filename = product.name + '.pdf';
    this.kebnekaiseService.getSdsAsTrustedResourceUrl(product.kebnekaise_id);
    this.kebnekaiseService.getKebnekaiseDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  loadTableData() {
    this.loadingData = true;
    if (_.isObject(this.selectedDepartment)) {
      if (this.showSubdepartments) {
        this.catalogueService.AllWithSuborganisations(
          this.selectedDepartment.id
        );
        this.catalogueService
          .getCatalogueDataListener()
          .pipe(take(1))
          .subscribe({
            next: (products) => {
              if (products.length > 0)
              {
                this.products = products;
                var i;
                if (_.isArray(this.products)) {
                  // for (i = 0; i < this.products.length; i += 1) {
                    // this.products[i].description = this.utilService.deserialize(
                    // this.products[i].description
                    //);
                  // }
                } else {
                  this.loadingData = false;
                  console.log("Error: ");
                }
              }
              else
              {
                this.loadingData = false;
              }
            },
            error: (error) => {
              this.loadingData = false;
              console.log("Error: ", error);
            },
          });
      } else {
        this.catalogueService.AllMoreInformation(this.selectedDepartment.id);
        this.catalogueService
          .getCatalogueDataListener()
          .pipe(take(1))
          .subscribe({
            next: (products) => {
              if (products.length > 0)
              {
                this.products = products;
                var i;
                if (_.isArray(this.products)) {
                  //for (i = 0; i < this.products.length; i += 1) {
                    //this.products[i].description = this.utilService.deserialize(
                    //  this.products[i].description
                    //);
                  // }
                } else {
                  this.loadingData = false;
                  console.log("Error: ");
                }
              }
              else
              {
                this.loadingData = false;
              }
            },
            error: (error) => {
              this.loadingData = false;
              console.log("Error: ", error);
            },
          });
      }
    }
  }
}
