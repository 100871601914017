import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { take } from 'rxjs/operators';
import { OrganisationService } from 'src/app/services/organisation.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { v4 as uuid } from 'uuid';
declare var $: any;

@Component({
  selector: 'app-company-misc',
  templateUrl: './company-misc.component.html',
  styleUrls: ['./company-misc.component.css']
})
export class CompanyMiscComponent implements OnInit {

  currentUser: any;
  isUserProfile: boolean;
  isCompanyUsers: boolean;
  isDepartmentSetup: boolean;
  isGroups: boolean;
  isCompanyInfo: boolean;
  isArticles: boolean;
  isStampedSds: boolean;
  isMisc: boolean;
  subscription_stamped_SDS: boolean=false;
  subscription_product_application: boolean=false;
  subscribesToFreemium: boolean=false;
  currentLanguage: any;
  organisation: any;
  companyFiles: [];
  selectedFile: any;
  loadingData:boolean = false;

  constructor ( private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private organisationService: OrganisationService, private uploadService: FileUploadService ) { }

  ngOnInit(): void {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = true;
    this.subscription_stamped_SDS = false;
    this.subscribesToFreemium = false;
    this.subscription_product_application = false;
    this.selectedFile = null;
    this.currentLanguage = localStorage.getItem('usedLanguage');
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscription_stamped_SDS = this.utilService.subscribesTo("subscription_stamped_SDS");
    this.subscription_product_application = this.utilService.subscribesTo("subscription_product_application");
    this.subscribesToFreemium = this.utilService.subscribesTo("subscription_freemium");
  }

  logout() {
    this.authService.logout();
  }

  save() {
    this.loadingData=true;
    this.organisationService.SingleUpdateCompanyInformationMisc(this.organisation.id, this.organisation);
    this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      next: response => {
        this.organisationService.getOrganisation(this.organisation.id);
        this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
          next: organisation => {
            if (organisation != null) {
              localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
              localStorage.setItem('selectedOrganisation', JSON.stringify(organisation));
              this.loadingData=false;
                window.location.reload();


            }
          },
          error: error => {
            this.loadingData=false;
            console.log("Error: ", error);
          }
        })
      },
      error: error => {
        this.loadingData=false;
        console.log("Error: ", error);
      }
    });
  }

  showUserProfile() {
    this.isUserProfile = true;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/userprofile']);
  }

  showCompanyUsers() {
    this.isUserProfile = false;
    this.isCompanyUsers = true;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyusers']);
  }

  showDepartmentSetup() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = true;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/editdepartments']);
  }

  showGroups() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = true;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/productgroup']);
  }

  showCompanyInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = true;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyinfo']);
  }

  showArticles() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = true;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyarticles']);
  }

  showStampedSds() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = true;
    this.isMisc = false;

    this.router.navigate(['/stampedsds']);
  }

  showMiscInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = true;

    this.router.navigate(['/companymisc']);
  }
}
