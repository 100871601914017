import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalSelectProductDepartmentComponent } from 'src/app/modals/modal-select-product-department/modal-select-product-department.component';
import { AssessmentChemicalService } from "src/app/services/assessment-chemical.service";
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { ManufacturerService } from "src/app/services/manufacturer.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { take } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SupportService } from 'src/app/services/support.service';
declare var $: any;

@Component({
  selector: 'app-assessment-chemical-edit',
  templateUrl: './assessment-chemical-edit.component.html',
  styleUrls: ['./assessment-chemical-edit.component.css']
})
export class AssessmentChemicalEditComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  currentUser: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  listAlldepartments: any;
  created_date: any = 0;
  products: any;
  subscribesToSds: any;
  subscribesToGrb: any;
  pictureHeight = 200;
  pictureWidth = 260;
  chemicalId: any;
  pictograms: any;
  loadingData: boolean = false;
  protectiveArray: any = [];
  picture: any;
  product: any;
  pictures: any = [];
  chemical: any = {};
  prevChemical: any = {}
  issuer: any = null;
  department: any;
  picturenames: any = [];
  riskfile: any = null;
  chemicalDepartment: any;
  chemicalfile: any;
  substitution: boolean = false;
  phasing_out: boolean = false;
  risk_minimzation: boolean = false;
  food_approved_yes: boolean = false;
  food_approved_no: boolean = false;
  food_approved_not_assessed: boolean = false;
  negligible_use: boolean = false;
  no_action_required: boolean = false;
  negligible_use_comment: any;
  departmentNames: any;
  showSubdepartments: boolean = false;
  protectiveImages = [{"name": "image_mask", "filename": "mask"}, {"name": "image_boots", "filename": "boots"}, {"name": "image_breath", "filename": "breath"}, {"name": "image_clothes", "filename": "clothes"},{"name": "image_gloves", "filename": "gloves"}, {"name": "image_glasses", "filename": "glasses"}];
  assessmentchemicalfile: any;
  filenames: any = [];
  errorArray=[];
  showChemicalRiskAssessmentHelptext1: boolean = false;
  showChemicalRiskAssessmentHelptext2: boolean = false;
  showChemicalRiskAssessmentHelptext3: boolean = false;
  showChemicalRiskAssessmentHelptext4: boolean = false;
  showChemicalRiskAssessmentHelptext5: boolean = false;
  showChemicalRiskAssessmentHelptext6: boolean = false;
  showChemicalRiskAssessmentHelptext7: boolean = false;
  showChemicalRiskAssessmentHelptext8: boolean = false;
  showChemicalRiskAssessmentHelptext9: boolean = false;
  showChemicalRiskAssessmentHelptext10: boolean = false;
  showChemicalRiskAssessmentHelptext11: boolean = false;
  showChemicalRiskAssessmentHelptext12: boolean = false;

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService,
    private organisationService: OrganisationService, private uploadService: FileUploadService,
    private modalService: NgbModal, private assessmentService: AssessmentService,
    private manufacturerService: ManufacturerService,
    private route: ActivatedRoute, private assessmentChemicalService: AssessmentChemicalService,private supportService: SupportService) { }

  ngOnInit(): void {

    this.route.params.pipe(take(1)).subscribe(params => {
      this.chemicalId = params['id'];
    });

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if (this.organisation == null || this.selectedDepartment == null || this.selectedOrganisation == null) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.loadingData = true;

    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');

    this.showSubdepartments = true;
    this.pictures = [];
    this.protectiveArray = [];

    if (this.selectedDepartment != null) {
      this.chemical = {
        created_date: new Date(),
        updated_date: new Date(),
        issuer: "",  // Updated when user recived,
        responsible: "",
        participants: "",
        comments: "",
        risk: 0,
        status: 0,
        old_status: 0,
        consequence: 0,
        usage: "",
        amount: "",
        place: "",
        waste: "",
        product_id: "",
        approved_by: "",
        cdoc_comment: "",
        substitution: 0,
        phasing_out: 0,
        risk_minimzation: 0,
        food_approved_yes: 0,
        food_approved_no: 0,
        food_approved_not_assessed: 0,
        ventilation: "",
        storage: "",
        hand_protection: "",
        eye_protection: "",
        respiratory: "",
        body_protection: "",
        hand_protection_own: "",
        eye_protection_own: "",
        breath_protection_own: "",
        body_protection_own: "",
        handling_own: "",
        storage_own: "",
        waste_own: "",
        fire_own: "",
        remark: "",
        general_own: "",
        department: this.selectedDepartment.name,
        products: [],
        chemicalfiles: [],
        protectiveArray: [],
        image_mask: 0,
        image_boots: 0,
        image_breath: 0,
        image_clothes: 0,
        image_gloves: 1,
        image_glasses: 1,
        riskfile: "",
        editChemical: false,
        negligible_use: 0,
        negligible_use_comment: "",
        no_action_required: 0,
      };
    }
    else
    {
      this.logout();
    }

    if (this.chemical != null) {

      if (this.chemical.substitution == 1)
      {
        this.substitution = true;
      }

      if (this.chemical.phasing_out == 1)
      {
        this.phasing_out = true;
      }

      if (this.chemical.risk_minimzation == 1)
      {
        this.risk_minimzation = true;
      }

      if (this.chemical.food_approved == 1)
      {
        this.food_approved_yes = true;
      }
      if (this.chemical.food_approved == 0)
      {
        this.food_approved_no = true;
      }
      if (this.chemical.food_approved == 2)
      {
        this.food_approved_not_assessed = true;
      }
      if (this.chemical.negligible_use == 1)
      {
        this.negligible_use = true;
      }
      if (this.chemical.no_action_required == 1)
        {
          this.no_action_required = true;
        }

      this.chemical.created_date = Date.parse(this.chemical.created_date);
      this.chemical.updated_date = new Date();
    }

    setTimeout(() => {
      this.getAllDepartments();
      this.loadTableData();
    }, 100);
  }

  getActions(): string[] {
    const actions: string[] = [];
  
    if (this.chemical.substitution === 1) {
      actions.push(this.translate.instant('SUBSTITUTION'));
    }
    if (this.chemical.phasing_out === 1) {
      actions.push(this.translate.instant('OUT_PHASING'));
    }
    if (this.chemical.risk_minimzation === 1) {
      actions.push(this.translate.instant('RISK_MINIMISATION'));
    }
    if (this.chemical.no_action_required === 1) {
      actions.push(this.translate.instant('NO_ACTION_REQUIRED'));
    }
  
    return actions;
  }

  showManufacturerInformation(product) {
    let object = { type: "get", id: this.product.supplier_id };
    this.manufacturerService.getSingle(object);
    this.manufacturerService.getManufacturerDataListener().pipe(take(1))
      .subscribe({next: (manufacturer) => {
          if (manufacturer == null) {
          } else {
            if (manufacturer) {
              Swal.fire({
                title: manufacturer[0].name,
                type: "info",
                allowOutsideClick: "true" ,
                html:
                "<div class='ui grid' style='overflow-y: scroll;overflow-x: hidden'>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                            "<b>" + this.translate.instant("ADDRESS") + " 1:</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].address_1 +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                            "<b>" + this.translate.instant("ADDRESS") + " 2:</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].address_2 +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("ZIP") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                          manufacturer[0].zip +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("CITY") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].city +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("COUNTRY") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].country +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("WEB_PAGE") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].www +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("EMAIL") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].email +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("PHONE") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].phone +
                        "</div>" +
                    "</div>" +
                    "<div class='row' style='padding-bottom: 0px'>" +
                        "<div class='one wide column'></div>" +
                        "<div class='four wide column'>" +
                          "<b>" + this.translate.instant("FAX") + ":</b> " +
                        "</div>" +
                        "<div class='ten wide column'>" +
                            manufacturer[0].fax +
                        "</div>" +
                    "</div>" +
                  "</div>"
              });
            }
          }
        },
        error: (error) => {},
    });
  }

  loadTableData() {

    this.assessmentChemicalService.getSingle(this.chemicalId);
    this.assessmentChemicalService.getAssessmentChemicalDataListener2().pipe(take(1)).subscribe({
      next: chemical => {
        if (chemical == null) {
          this.chemical = null;
          this.loadingData = false;
        }
        else {

          if (chemical.Status == "403")
          {
            this.assessmentService.getAssSingleProduct(this.chemicalId);
            this.assessmentService.getAssessmentProductDataListener().pipe(take(1)).subscribe({
              next: product => {
                if (product == null) {
                  this.product = [];
                  this.loadingData = false;
                }
                else {
                  this.product = product[0];
                  this.chemical.food_approved =2
                  this.food_approved_yes = false;
                  this.food_approved_no = false;
                  this.food_approved_not_assessed = true;
                  this.chemical.product_id = this.product.product_id;
                  this.chemical.hand_protection_own = this.product.prot_gloves_small;
                  this.chemical.eye_protection_own = this.product.prot_glasses_small
                  this.chemical.breath_protection_own = this.product.prot_breath_small;
                  this.chemical.body_protection_own = this.product.prot_clothes_small;
                  this.chemical.handling_own = this.product.handling_emissions;
                  this.chemical.storage_own = this.product.handling_storage;
                  this.chemical.waste_own = this.product.handling_waste;
                  this.chemical.usage_area = this.product.usage_area;
                  this.chemical.negligible_use_comment = this.product.negligible_use_comment;
                  this.chemical.negligible_use = this.product.negligible_use;
                  this.chemical.fire_own = "";
                  if (this.product.fire_ext_agent != null)
                    this.chemical.fire_own += this.product.fire_ext_agent + "\r\n";

                  if (this.product.fire_uns_ext_agent != null)
                    this.chemical.fire_own += this.product.fire_uns_ext_agent + "\r\n";

                  if (this.product.combustion_products != null)
                    this.chemical.fire_own += this.product.combustion_products + "\r\n";

                  if (this.product.handling_fire_measures != null)
                    this.chemical.fire_own += this.product.handling_fire_measures + "\r\n";

                  this.loadingData = false;
                }

                this.issuer = this.currentUser;
                if ( this.chemical.participants && ( this.chemical.participants == "" || this.chemical.particapants == null ) ){
                  this.chemical.participants = this.currentUser.first_name + " " + this.currentUser.last_name;
                }

                const risk = Number(this.chemical.risk);

                if (isNaN(risk) || risk < 1 || risk > 5) {
                  this.chemical.risk = this.product.risk_prio;
                } else {
                  this.chemical.risk = risk;
                }

                this.chemical.issuer = this.issuer;
              },
              error: error => {
                this.loadingData = false;
                this.product = [];
                console.log("Error: ", error);
              }
            })
          }
          else
          {
            this.chemical = chemical[0];

            this.assessmentService.getAssSingleProduct(this.chemicalId);
            this.assessmentService.getAssessmentProductDataListener().pipe(take(1)).subscribe({
              next: product => {
                if (product == null) {
                  this.product = [];
                  this.loadingData = false;
                }
                else {
                  this.product = product[0];
                  this.chemical.product_id = this.product.product_id;

                  if (this.chemical.substitution == 1)
                  {
                    this.substitution = true;
                  }

                  if (this.chemical.phasing_out == 1)
                  {
                    this.phasing_out = true;
                  }

                  if (this.chemical.risk_minimzation == 1)
                  {
                    this.risk_minimzation = true;
                  }

                  if (this.chemical.no_action_required == 1)
                    {
                      this.no_action_required = true;
                    }

                  if (this.chemical.food_approved == 1)
                  {
                    this.food_approved_yes = true;
                  }
                  if (this.chemical.food_approved == 0)
                  {
                    this.food_approved_no = true;
                  }
                  if (this.chemical.food_approved == 2)
                  {
                    this.food_approved_not_assessed = true;
                  }
                  if (this.chemical.negligible_use == 1)
                  {
                    this.negligible_use = true;
                  }

                  this.chemical.created_date = Date.parse(this.chemical.created_date);
                  this.chemical.updated_date = Date.parse(this.chemical.updated_date);
                  this.chemical.old_status = _.cloneDeep(this.chemical.status);

                  this.userService.getSingleUser(this.chemical.issuer);
                  this.userService.getCurrentSingleUserDataListener().pipe(take(1)).subscribe({
                    next: user => {
                      this.issuer = user;

                      if ( !this.chemical.participants ){
                        this.chemical.participants = user.first_name + " " + user.last_name;
                      }

                      const risk = Number(this.chemical.risk);

                      if (isNaN(risk) || risk < 1 || risk > 5) {
                        this.chemical.risk = this.product.risk_prio;
                      } else {
                        this.chemical.risk = risk;
                      }
                      this.loadingData = false;

                      if(this.chemical.chemicalfiles)
                      {
                        for (var i = 0; i < this.chemical.chemicalfiles.length; i++)
                        {
                          this.openPicture(this.chemical.chemicalfiles[i].file_id);
                        }
                        this.reveiveProductMsg(0);
                      }
                    },
                    error: error => {
                      this.issuer = null;
                      this.loadingData = false;
                    }
                  })
                }
              },
              error: error => {
                this.loadingData = false;
                this.product = [];
                console.log("Error: ", error);
              }
            })
          }
          this.prevChemical = _.cloneDeep(this.chemical);
        }
      },
      error: error => {
        this.loadingData = false;
        this.chemical = null;
        console.log("Error: ", error);
      }
    })
  }

  addOrRemoveProtective(name)
  {
    if (!this.chemical.protectiveArray) {
      this.chemical.protectiveArray = [];
    }

    if (this.protectiveInWorkstep(name))
    {
      this.chemical[name] = 0;
      for(var i = 0; i < this.protectiveImages.length; i++)
      {
        if(this.protectiveImages[i].name === name){
          this.chemical.protectiveArray.splice(i,1);
        }
      }
    }
    else
    {
      this.chemical[name] = 1;
      for (var i = 0; i < this.protectiveImages.length; i++)
      {
        if(this.protectiveImages[i].name === name){
          this.chemical.protectiveArray.push(this.protectiveImages[i]);
        }
      }
    }
  }

  createLRB() {
    this.loadingData = true;
    if (this.chemical.editChemical == false)
    {
      this.assessmentChemicalService.createChemical(this.chemical);
      this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
        next: info => {
          if (info == null) {
            Swal.fire("Error", "generiskt fel");
            this.loadingData = false;
          }
          else {
            this.chemical.id = info.id;
            this.assessmentChemicalService.createLRB(this.chemical.id);
            this.assessmentChemicalService.getAssessmentChemicalDocDataListener().pipe(take(1)).subscribe({
              next: file => {
                if (file == null) {
                  Swal.fire("Error", "generiskt fel");
                  this.loadingData = false;
                }
                else {
                  // var popup = window.open('');
                  var fileURL = URL.createObjectURL(file);
                  // popup.location.href = fileURL;
                  var opened=window.open(fileURL,"_blank");
                  if(!opened){
                    window.location.href=fileURL;
                  }
                  this.riskfile = file;
                  this.loadingData = false;
                }
              },
              error: error => {
                console.log("Error: ", error);
                Swal.fire("Error", "generiskt fel");
                this.loadingData = false;
              }
            })
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire("Error", "generiskt fel");
          this.loadingData = false;
        }
      })
    } else {
      this.assessmentChemicalService.updateChemical(this.chemical.id, this.chemical);
      this.assessmentChemicalService.getAssessmentChemicaUpdateDataListener().pipe(take(1)).subscribe({
        next: info => {
          if (info == null) {
            Swal.fire("Error", "generiskt fel");
            this.loadingData = false;
          }
          else {
            this.assessmentChemicalService.createLRB(this.chemical.id);
            this.assessmentChemicalService.getAssessmentChemicalDocDataListener().pipe(take(1)).subscribe({
              next: file => {
                if (file == null) {
                  Swal.fire("Error", "generiskt fel");
                  this.loadingData = false;
                }
                else {
                  // var popup = window.open('');
                  var fileURL = URL.createObjectURL(file);
                  // popup.location.href = fileURL;
                  var opened=window.open(fileURL,"_blank");
                  if(!opened){
                    window.location.href=fileURL;
                  }
                  this.riskfile = file;
                  this.loadingData = false;
                }
              },
              error: error => {
                console.log("Error: ", error);
                Swal.fire("Error", "generiskt fel");
                this.loadingData = false;
              }
            })
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire("Error", "generiskt fel");
          this.loadingData = false;
        }
      })
    }
  }

  // Method to check if two objects are different
  hasChemicalChanged(): boolean {
    return JSON.stringify(this.chemical) != JSON.stringify(this.prevChemical);
  }

  cancel() {
    if (this.hasChemicalChanged() == true)
    {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        } else {
          this.router.navigate(['/assessmentschemical']);
        }
      })
    }
    else
    {
      this.router.navigate(['/assessmentschemical']);
    }
  }

  submit() {
    this.loadingData = true;
    if ( this.chemical.old_status == 3 && this.chemical.status == 3 ) {
      // Do nothing, could be that the LRB is already and another person is changing some things, dont want the original approver to dissappear
    } else if ( this.chemical.status == 3 ) {
      this.approve(); // Status is changed to 3 not still at 3
    } else {
      this.chemical.approved_by = ""; // Status is changed from 3, than it isnt approved any longer
    }
    if (this.chemical.editChemical == false)
    {
      this.assessmentChemicalService.createChemical(this.chemical);
      this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
        next: info => {
          if (info == null) {
            Swal.fire("Error", "generiskt fel");
            this.loadingData = false;
          }
          else {
            this.chemical.id = info.id;
            this.assessmentChemicalService.createLRB(this.chemical.id);
            this.assessmentChemicalService.getAssessmentChemicalDocDataListener().pipe(take(1)).subscribe({
              next: file => {
                if (file == null) {
                  Swal.fire("Error", "generiskt fel");
                  this.loadingData = false;
                }
                else {
                  this.utilService.uploadFile(file);
                  this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
                    next: response => {
                      this.chemical.riskfile = response.filename;
                      this.chemical.issuer = this.chemical.issuer.id;
                      this.assessmentChemicalService.updateChemical(this.chemical.id, this.chemical);
                      this.assessmentChemicalService.getAssessmentChemicaUpdateDataListener().pipe(take(1)).subscribe({
                        next: info => {
                          if (info == null) {
                            Swal.fire("Error", "generiskt fel");
                            this.loadingData = false;
                          }
                          else {
                            this.router.navigate(['/assessmentschemical']);
                            this.loadingData = false;
                          }
                        },
                        error: error => {
                          console.log("Error: ", error);
                          Swal.fire("Error", "generiskt fel");
                          this.loadingData = false;
                        }
                      })
                    },
                    error: error => {
                      console.log("Error: ", error);
                      this.utilService.onError(error);
                      this.loadingData = false;
                    }
                  })
                }
              },
              error: error => {
                console.log("Error: ", error);
                Swal.fire("Error", "generiskt fel");
                this.loadingData = false;
              }
            })
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire("Error", "generiskt fel");
          this.loadingData = false;
        }
      })
    } else {
      this.assessmentChemicalService.updateChemical(this.chemical.id, this.chemical);
      this.assessmentChemicalService.getAssessmentChemicaUpdateDataListener().pipe(take(1)).subscribe({
        next: info => {
          if (info == null) {
            Swal.fire("Error", "generiskt fel");
            this.loadingData = false;
          }
          else {
            this.assessmentChemicalService.createLRB(this.chemical.id);
            this.assessmentChemicalService.getAssessmentChemicalDocDataListener().pipe(take(1)).subscribe({
              next: file => {
                if (file == null) {
                  Swal.fire("Error", "generiskt fel");
                  this.loadingData = false;
                }
                else {
                  this.utilService.uploadFile(file);
                  this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
                    next: response => {
                      this.chemical.riskfile = response.filename;
                      this.assessmentChemicalService.updateChemical(this.chemical.id, this.chemical);
                      this.assessmentChemicalService.getAssessmentChemicaUpdateDataListener().pipe(take(1)).subscribe({
                        next: info => {
                          if (info == null) {
                            Swal.fire("Error", "generiskt fel");
                            this.loadingData = false;
                          }
                          else {
                            this.router.navigate(['/assessmentschemical']);
                            this.loadingData = false;
                          }
                        },
                        error: error => {
                          console.log("Error: ", error);
                          Swal.fire("Error", "generiskt fel");
                          this.loadingData = false;
                        }
                      })
                    },
                    error: error => {
                      console.log("Error: ", error);
                      this.utilService.onError(error);
                      this.loadingData = false;
                    }
                  })
                }
              },
              error: error => {
                console.log("Error: ", error);
                Swal.fire("Error", "generiskt fel");
                this.loadingData = false;
              }
            })
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire("Error", "generiskt fel");
          this.loadingData = false;
        }
      })
    }
  }

  remove() {
    Swal.fire({

      title: this.translate.instant("REMOVE") + "?",
      input: 'text',
      html: this.translate.instant("CONFIRM_REMOVE_ASSESSMENT") + "<br><br><div align='left'></div>",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("NO"),
      confirmButtonText: this.translate.instant("YES"),
      closeOnConfirm: true,
      inputPlaceholder: this.translate.instant("COMMENT")
    }).then((result) => {
      if (result.isConfirmed) {

        this.assessmentChemicalService.deleteChemical(this.chemical.id);
        this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
          next: info => {
            if (info == null) {
              Swal.fire("Error", "generiskt fel");
            }
            else {
              this.router.navigate(['/assessmentschemical']);
            }
          },
          error: error => {
            console.log("Error: ", error);
            Swal.fire("Error", "generiskt fel");
          }
        })
      }
    });
  }

  approve() {
    if (this.chemical.risk > 0)
    {
      this.chemical.status = 3;
      this.chemical.approved_by = this.currentUser.first_name + " " + this.currentUser.last_name;
    }
    else {
      Swal.fire("Error", "No risk set");
    }
  }

  approved() {
    this.router.navigate(['/assessmentschemical']);
  }

  onToggleShowSubstitution() {
    if (this.substitution == true)
    {
      this.chemical.no_action_required = 0;
      this.no_action_required = false;
      this.chemical.substitution = 1;
    }
    else
    {
      this.chemical.substitution = 0;
    }
  }

  onToggleShowOutphasing() {
    if (this.phasing_out == true)
    {
      this.chemical.no_action_required = 0;
      this.no_action_required = false;
      this.chemical.phasing_out = 1;
    }
    else
    {
      this.chemical.phasing_out = 0;
    }
  }

  onToggleShowRiskMinimzation() {
    if (this.risk_minimzation == true)
    {
      this.chemical.no_action_required = 0;
      this.no_action_required = false;
      this.chemical.risk_minimzation = 1;
    }
    else
    {
      this.chemical.risk_minimzation = 0;
    }
  }

  onToggleNoActionRequired(){
    if (this.no_action_required == true)
    {
      this.chemical.no_action_required = 1;
      this.chemical.risk_minimzation = 0;
      this.chemical.phasing_out = 0;
      this.chemical.substitution = 0;
      this.risk_minimzation = false;
      this.phasing_out = false;
      this.substitution = false;
    }
    else
    {
      this.chemical.no_action_required = 0;
    }
  }

  onToggleFoodApproved(item) {
    if ( item == 1 ){
      if ( this.food_approved_yes == true ) {
        this.chemical.food_approved = 1;
        this.food_approved_no = false
        this.food_approved_not_assessed = false;
      } else {
        this.chemical.food_approved = null;
      }
      return;
    }

    if ( item == 0 ){
      if ( this.food_approved_no == true ) {
        this.chemical.food_approved = 0;
        this.food_approved_yes = false
        this.food_approved_not_assessed = false;
      } else {
        this.chemical.food_approved = null;
      }
      return;
    }

    if ( item == 2 ){
      if ( this.food_approved_not_assessed == true ) {
        this.chemical.food_approved = 2;
        this.food_approved_yes = false
        this.food_approved_no = false;
      } else {
        this.chemical.food_approved = null;
      }
      return;
    }
  }

  onToggleShowNegligibleUse() {
    if (this.negligible_use == true)
    {
      this.chemical.negligible_use = 1;
      this.no_action_required = true;
      this.onToggleNoActionRequired();
    }
    else
    {
      this.chemical.negligible_use = 0;
    }
  }

  protectiveInWorkstep(name){

    if (this.chemical) {
      if (this.chemical[name]){
          return true;
      }
    }
    return false;
  }

  pictureRemove(index) {
    this.chemical.chemicalfiles.splice(index, 1);
    this.pictures.splice(index, 1);
  }

  incomingimage(event) {

    this.chemicalfile = event.target.files[0];

    if (this.chemicalfile != null) {

      this.uploadService.upload(this.chemicalfile);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.chemicalfile = null;
          }
          else {
            if (!this.chemical.chemicalfiles)
            {
              this.chemical.chemicalfiles = [];
            }
            var file_id = {"file_id": result.filename, "file_name": this.chemicalfile.name};
            this.chemical.chemicalfiles.push(file_id);
            this.picturenames.push(this.chemicalfile.name);
          }
        },
        error: error => {
          this.chemicalfile = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  incomingfile(event) {
    this.assessmentchemicalfile = event.target.files[0];

    if (this.assessmentchemicalfile != null) {

      this.uploadService.upload(this.assessmentchemicalfile);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.assessmentchemicalfile = null;
          }
          else {
            if (!this.chemical.assessmentchemicalfiles)
            {
              this.chemical.assessmentchemicalfiles = [];
            }
            var file_id = {"file_id": result.filename, "file_name": this.assessmentchemicalfile.name};
            this.chemical.assessmentchemicalfiles.push(file_id);
            this.filenames.push(this.assessmentchemicalfile.name);
          }
        },
        error: error => {
          this.assessmentchemicalfile = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  fileRemove(index) {
    this.chemical.assessmentchemicalfiles.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  goToChemicalList() {
    this.router.navigate(['/assessmentschemical']);
  }

  setDepartment(department) {
    this.department = department;
  }

  openPicture(fileId) {
    this.assessmentChemicalService.getFileAsTrustedResourceUrl(fileId);
  }

  reveiveProductMsg(counter) {
    this.assessmentChemicalService.getAssessmentChemicalPictureDataListener().pipe(take(1)).subscribe({
      next: data => {
        if (data == null) {
        }
        else {
          this.picture = data;
          this.pictures.push(this.picture);
          counter = counter + 1
          this.reveiveProductMsg(counter);
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  openSdsInNewWindow(product) {

    var filename = product.product_name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  openGrbInNewWindow(product) {

    var filename = product.product_name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  openLrbInNewWindow(product) {
    var filename = product.name + '_risk.pdf';
    // var popup = window.open('');
    this.assessmentChemicalService.getLrbAsTrustedResourceUrl(this.selectedDepartment.id, product, 'chemical_riskassessment');
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  logout() {
    this.authService.logout();
  }

  openExtSdsInNewWindow(prod) {
    var filename = prod.name + '.pdf';
    // var popup = window.open('')
    this.productService.getExtSdsAsTrustedResourceUrl(prod.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openFileInNewWindow(file) {
    let fileId = file.file_id;
    this.assessmentChemicalService.getcDocFileWithId(fileId);
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
        }
        else {
          // var popup = window.open('');
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

}
