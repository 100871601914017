<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
  <div class="loader"></div>
</div>

<div class="overflow-auto mb-40 md:mb-20" *ngIf="!loadingData">
  <div class="flex flex-col min-h-screen border border-gray-300">
    <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="cancel()">
      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
        <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
      </svg>
    </button>

    <div class="flex flex-row">
      <div class="flex flex-col bg-white rounded shadow-lg m-4 p-7 w-3/4">
        <div class="flex flex-col w-full">
          <div class="mb-4">
              <label class="font-bold mb-1">{{"NAME" | translate}}:</label><br>
              <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substance.name" (ngModelChange)="substance.name = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"CAS" | translate}}:</label><br>
            <input title="{{'CAS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substance.cas" (ngModelChange)="substance.cas = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"EG" | translate}}:</label><br>
            <input title="{{'EG' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substance.eg" (ngModelChange)="substance.eg = $event" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"H_PHRASES" | translate}}:</label><br>
            <div class="relative inline-block text-left w-full z-30" appDropdown>
              <div class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white bg-teal-100 border border-gray-300 p-2">
                <div class="flex flex-wrap">
                  <div *ngFor="let h of substance.hphrases">
                    <div class="flex flex-row border bg-gray-200 border-blue-500 p-1 mr-1 mt-1 rounded">
                      <div class="text-xs font-normal leading-none max-w-full flex-initial">{{h}}</div>
                      <div class="flex flex-auto flex-row-reverse">
                        <div>
                            <svg (click)="removeHPhrases(h)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <svg class="w-6 h-6 ml-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div id="dropdownMenu" class="hidden dropdown-menu right-1 overflow-y-auto max-h-64" #dropdownMenuInside>
                <input title="search" st-search="" type="search" fullTextSearch="false" (input)="onSearchChangeHphrases($event.target.value)"
                  class="w-full px-2 py-2 border border-cdocblue rounded" placeholder="" #dropdownSearch/>
                <div class="py-1">
                    <div *ngFor="let h of filteredhphrase" class="item" data-value="selectHPhrases" class="dropdown-menu-item" (click)="setHPhrases(h)" tabindex="1">{{ h }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <div class="flex flex-row m-2">
              <div class="flex flex-col w-1/2">
                <div class="flex items-center space-x-2">
                  <input title="{{'HYGIENE_LIMITS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [checked]="substance.hygiene_limits" (click)="showCheckboxClicked(0)">
                  <span class="text-sm">{{"HYGIENE_LIMITS" | translate}}</span>
                </div>
              </div>
              <div class="flex items-center space-x-2 w-1/2">
                <input title="{{'NO_HYGIENE_LIMITS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [checked]="substance.no_hygiene_limits==true" (click)="showCheckboxClicked(1)">
                <span class="text-sm">{{"NO_HYGIENE_LIMITS" | translate}}</span>
              </div>
            </div>
          </div>
          <div *ngIf="!substance.no_hygiene_limits" class="mb-2">
            <label class="font-bold">{{"NGV" | translate}}</label>
          </div>
          <div *ngIf="!substance.no_hygiene_limits" class="mb-4">
            <div class="flex flex-row">
              <div class="w-1/2 m-2">
                <label class="font-bold mb-1">{{"NGV_PPM" | translate}}:</label><br>
                <input title="{{'NGV_PPM' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substance.ngv_ppm" (ngModelChange)="substance.ngv_ppm = $event" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="w-1/2 m-2">
                <label class="font-bold mb-1">{{"NGV_KONCENTRATION" | translate}}:</label><br>
                <input title="{{'NGV_KONCENTRATION' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substance.ngv_koncentration" (ngModelChange)="substance.ngv_koncentration = $event" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
          <div *ngIf="!substance.no_hygiene_limits" class="mb-2">
            <label class="font-bold">{{"KGV" | translate}}</label>
          </div>
          <div *ngIf="!substance.no_hygiene_limits" class="mb-4">
            <div class="flex flex-row">
              <div class="w-1/2 m-2">
                <label class="font-bold mb-1">{{"KGV_PPM" | translate}}:</label><br>
                <input title="{{'KGV_PPM' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substance.kgv_ppm" (ngModelChange)="substance.kgv_ppm = $event" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="w-1/2 m-2">
                <label class="font-bold mb-1">{{"KGV_KONCENTRATION" | translate}}:</label><br>
                <input title="{{'KGV_KONCENTRATION' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="substance.kgv_koncentration" (ngModelChange)="substance.kgv_koncentration = $event" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
          <div *ngIf="!substance.no_hygiene_limits" class="mb-4">
            <label class="font-bold mb-1">{{"OBSERVATION_HGV" | translate}}:</label><br>
            <textarea title="{{'OBSERVATION_HGV' | translate}}" class="w-full border border-gray-300 p-2" rows="3" [(ngModel)]="substance.hygiene_limits_comment" (ngModelChange)="substance.hygiene_limits_comment = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="201"></textarea>
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"COMMENT" | translate}}:</label><br>
            <textarea title="{{'COMMENT' | translate}}" class="w-full border border-gray-300 p-2" rows="3" [(ngModel)]="substance.comment" (ngModelChange)="substance.comment = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="201"></textarea>
          </div>
          <div class="mb-4">
            <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" (change)="incomingfile($event)" placeholder="Upload file" id="upload-file" accept=".pdf" />
            <table *ngIf="substance?.substancefiles?.length > 0" class="table-auto w-full bg-white mb-4 mt-4 border border-gray-400">
              <thead>
                  <tr>
                      <th class="table-row-header"></th>
                      <th class="table-row-header"></th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let file of substance.substancefiles; let i = index">
                      <td class="table-row-column">{{file.file_name}}</td>
                      <td class="table-row-column">
                        <a class="ml-2" (click)="fileRemove(i)">
                          <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                              21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                              5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                              3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                              stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </a>
                      </td>
                  </tr>
              </tbody>
           </table>
          </div>
        </div>
        <div class="flex flex-row justify-center mb-20 mt-4">
          <button *ngIf="currentUser.is_super_user" class="button-red  mr-1" title="{{'REMOVE' | translate}}" (click)="delete()">
            <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="h-7 w-5">
            <span class="text-lg">{{ 'REMOVE' | translate }}</span>
          </button>
          <button class="button-green  mr-1" title="{{'SAVE_AND_MOVETOPRODUCTLIST' | translate}}" (click)="save()">
            <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'SAVE' | translate }}</span>
          </button>
        </div>
      </div>
      <div *ngIf="!loadingData&&substance" class="flex flex-col bg-white rounded shadow-lg m-4 p-7 w-1/4">
        <span class="text-lg font-bold m-4">{{"RESTRICTION_LISTS" | translate}}</span>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_AMV_A_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_amv_a" [checked]="substance.list_amv_a==1" (click)="substance.list_amv_a = (substance.list_amv_a+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_AMV_A_SHORT" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_AMV_B_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_amv_b" [checked]="substance.list_amv_b==1" (click)="substance.list_amv_b = (substance.list_amv_b+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_AMV_B_SHORT" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_CHEM_LIMIT_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_chem_limit" [checked]="substance.list_chem_limit==1" (click)="substance.list_chem_limit = (substance.list_chem_limit+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_CHEM_LIMIT_SHORT" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_CHEM_PRIO_U_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_chem_prio_u" [checked]="substance.list_chem_prio_u==1" (click)="substance.list_chem_prio_u = (substance.list_chem_prio_u+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_CHEM_PRIO_U_SHORT" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_CHEM_PRIO_P_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_chem_prio_p" [checked]="substance.list_chem_prio_p==1" (click)="substance.list_chem_prio_p = (substance.list_chem_prio_p+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_CHEM_PRIO_P_SHORT" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_REACH_14_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_reach_14" [checked]="substance.list_reach_14==1" (click)="substance.list_reach_14 = (substance.list_reach_14+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_REACH_14_SHORT" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_REACH_17_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_reach_17" [checked]="substance.list_reach_17==1" (click)="substance.list_reach_17 = (substance.list_reach_17+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_REACH_17_SHORT" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_REACH_CANDIDATE' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_reach_candidate" [checked]="substance.list_reach_candidate==1" (click)="substance.list_reach_candidate = (substance.list_reach_candidate+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_REACH_CANDIDATE" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_WATER_DIRECTIVE_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_water_directive" [checked]="substance.list_water_directive==1" (click)="substance.list_water_directive = (substance.list_water_directive+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_WATER_DIRECTIVE_SHORT" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'SIN' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_sin" [checked]="substance.list_sin==1" (click)="substance.list_sin = (substance.list_sin+1)%2">
            <span class="text-sm mr-1">{{"SIN" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_BASTA' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_BASTA" [checked]="substance.list_BASTA==1" (click)="substance.list_BASTA = (substance.list_BASTA+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_BASTA" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'RESTRICTION_LIST_SLL_SHORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_sll" [checked]="substance.list_sll==1" (click)="substance.list_sll = (substance.list_sll+1)%2">
            <span class="text-sm mr-1">{{"RESTRICTION_LIST_SLL_SHORT" | translate}}</span>
          </div>
        </div>
        <span class="text-lg font-bold m-4">{{"HEADER_POP_ROHS" | translate}}</span>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'LIST_POP' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_pop" [checked]="substance.list_pop==1" (click)="substance.list_pop = (substance.list_pop+1)%2">
            <span class="text-sm mr-1">{{"LIST_POP" | translate}}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="flex items-center space-x-2">
            <input title="{{'LIST_ROHS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" [ngModel]="substance.list_rohs" [checked]="substance.list_rohs==1" (click)="substance.list_rohs = (substance.list_rohs+1)%2">
            <span class="text-sm mr-1">{{"LIST_ROHS" | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


