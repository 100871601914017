import { Component, OnInit } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { SupportService } from "src/app/services/support.service";
import { take } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  fullname: string = "";
  email: string= "";
  company: string= "";
  organisationNumber: any= "";
  today: Date;
  user: any;
  freemiumUser: any;
  infoEmail: any;
  termsApproved = false;

  constructor(private organisationService: OrganisationService, private supportService: SupportService, private translate: TranslateService,
              private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.user = {
      company_name: "",
      contact_person_firstname: "",
      contact_person_lastname: "",
      contact_person_email: "",
      contact_person_title: "",
      contact_person_phone: "",
      address_1: "",
      address_2: "",
      org_nr: "",
      zip: "",
      city: "",
      country: "",
      referer: "",
      www: "",
      misc: "",
      address_visit: "",
      password: "1111",
    };
  }

  createNewUser() {

    if (this.termsApproved == true) {

      if ((this.fullname != "") && 
          (this.email != "") && 
          (this.company != "") &&
          (this.organisationNumber != null)) {

        if ((this.email.includes('@'))) {

          this.user = {
            company_name: this.company,
            contact_person_firstname: "",
            contact_person_lastname: this.fullname,
            contact_person_email: this.email,
            contact_person_title: "",
            contact_person_phone: "",
            address_1: "",
            address_2: "",
            org_nr: this.organisationNumber,
            zip: "",
            city: "",
            country: "",
            referer: "",
            www: "",
            misc: "",
            address_visit: "",
            password: "1111",
          };

          this.organisationService.addFreemiumUser(this.user);
          this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
            next: response => {
              this.freemiumUser = response;
            },
            error: error => {
              console.log("addFrmUser error: ", error);
              return (null);
            }
          })

          this.infoEmail = {
                name: this.fullname,
                email: this.email,
                cc: false,
                subject: "New customer",
                body: "New customer " + this.email
          };

          this.supportService.infoMail(this.infoEmail);
          this.supportService.getSupportDataListener().pipe(take(1)).subscribe({
            next: pdfContent => {
              Swal.fire({
                title: this.translate.instant("MESSAGE_SENT"),
                text: this.translate.instant("YOUR_MESSAGE_HAS_BEEN_SENT"),
                icon: "success",
                showCancelButton: false,
              });
            },
            error: error => {
              console.log("Error: ", error);
              Swal.fire({
                title: this.translate.instant("AN_ERROR_OCCURED"),
                text: this.translate.instant("YOUR_MESSAGE_HAS_NOT_BEEN_SENT"),
                icon: "error",
                showCancelButton: false,
              });
            }
          })
        }
        else {
          Swal.fire({
            title: this.translate.instant("AN_ERROR_OCCURED"),
            text: this.translate.instant("WRONG_EMAIL_ADDRESS"),
            icon: "error",
            showCancelButton: false,
          });
        }
      }
      else {
        Swal.fire({
          title: this.translate.instant("AN_ERROR_OCCURED"),
          text: this.translate.instant("FIELDS_ARE_MISSING"),
          icon: "error",
          showCancelButton: false,
        });
      }
    }
    else {
      Swal.fire({
        title: this.translate.instant("AN_ERROR_OCCURED"),
        text: this.translate.instant("NOT_APPROVE_TERMS"),
        icon: "error",
        showCancelButton: false,
      });
    }
  }

  goToLogin() {
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    })
  }

  logout() {
    this.authService.logout();
  }
}
