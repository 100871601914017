
<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
  <div class="loader"></div>
</div>

<div class="flex flex-col border border-gray-200">
  <button class="px-4 mt-4" title="{{'BACK' | translate}}" (click)="gotoCatalogueList()">
    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
      <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
    </svg>
  </button>

  <div *ngIf="loadingData==false" class="flex w-full p-4">
    <!-- Form Container -->
    <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">

      <div class="flex flex-col w-full mt-2">
        <p class="text-2xl font-bold mb-4">{{product.product_name}}</p>

        <div *ngIf="product" class="mb-4">
          <label class="font-bold mb-1">{{"NAME" | translate}}:</label><br>
          <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="product.product_name" (ngModelChange)="product.product_name = $event" name="product_name"  type="text">
          <label class="text-red" *ngIf="isError==true&&this.isErrorArray[0].value==true&&product.product_name==''" >{{"Fyll i detta fält"}}</label>
        </div>

        <div *ngIf="product" class="mb-4">
          <label class="font-bold mb-1">{{"SUPPLIER" | translate}}:</label><br>
          <input title="{{'SUPPLIER' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="product.supplier_name" (ngModelChange)="product.supplier_name = $event" name="supplier_name"  type="text">
          <label class="text-red" *ngIf="isError==true&&this.isErrorArray[0].value==true&&product.supplier_name==''" >{{"Fyll i detta fält"}}</label>
        </div>

        <div *ngIf="product" class="mb-4">
          <label class="font-bold mb-1">{{"DESCRIPTION" | translate}}:</label><br>
          <textarea title="{{'DESCRIPTION' | translate}}" rows="3" class="w-full border border-gray-300 p-2" [(ngModel)]="product.description" (ngModelChange)="product.description = $event" name="description"></textarea>
        </div>

        <div *ngIf="product" class="flex items-center space-x-2 w-1/1 md:w-1/5 mt-4 mb-4">
          <label class="font-bold mb-1 mr-2">{{"FLAMMABLE" | translate}}:</label>
          <input title="{{'FLAMMABLE' | translate}}" class="w-full form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [(ngModel)]="product.flammable" (ngModelChange)="product.flammable = $event" name="flammable">
        </div>

        <div *ngIf="product" class="mb-4">
          <label class="font-bold mb-1">{{"PROTECTIVE_GEAR" | translate}}:</label><br>
          <textarea title="{{'PROTECTIVE_GEAR' | translate}}" rows="3" class="w-full border border-gray-300 p-2" [(ngModel)]="product.protective_gear" (ngModelChange)="product.protective_gear = $event" name="protective_gear"></textarea>
        </div>

        <div *ngIf="product" class="mb-4">
          <label class="font-bold mb-1">{{"AMOUNT_ON_DEPARTMENT" | translate}}:</label><br>
          <input title="{{'AMOUNT_ON_DEPARTMENT' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="product.amount_in_stock" (ngModelChange)="product.amount_in_stock = $event" name="amount_in_stock" type="number">
        </div>

        <div *ngIf="product" class="mb-4">
          <label class="font-bold mb-1">{{"ANNUAL_CONSUMPTION" | translate}}:</label><br>
          <input title="{{'ANNUAL_CONSUMPTION' | translate}}" class="w-full border border-gray-300 p-2" [(ngModel)]="product.estimated_use_per_year" (ngModelChange)="product.estimated_use_per_year = $event" name="estimated_use_per_year" type="number">
        </div>

        <div *ngIf="product" class="mb-4">
          <label class="font-bold mb-1">{{"UNIT" | translate}}: </label><br>
          <div class="flex justify-between items-center w-full relative inline-block text-left z-30" appDropdown>
            <button type="button" class="button-white justify-between items-start border w-full border-gray-300">
                <span>{{product.unit | translate}}</span>
                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 top-10" style="-webkit-overflow-scrolling: touch;" #dropdownMenuInside>
              <div>
                <div *ngFor="let unit of units" data-value="unit" class="dropdown-menu-item" (click)="setUnit(unit)" tabindex="1">{{ unit.name | translate }}</div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="product&&product.sds_file_id" class="mb-4 mt-4">
          <label class="font-bold mb-1">{{"REPORT_SDS" | translate}}:</label><br>
          <a class="cursor-pointer" (click)="$event.stopPropagation();openSdsInNewWindow(product)">
            <img tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg"/>
          </a>
        </div>

        <div *ngIf="product" class="mb-4">
          <label class="font-bold mb-1">{{"NEW_EDIT_SDS" | translate}}:</label><br>
          <input class="w-1/1 md:w-1/5 border border-gray-300 p-2" type="file" (change)="fileChange($event)" placeholder="Upload file" id="upload-file" accept=".pdf">

          <div class="flex" *ngIf="product.sds_file">
            <i><img class="cursor-pointer" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" (click)="$event.stopPropagation();openSdsInNewWindow(product)"/></i>
            <span class="mt-2">{{product.sds_file.name!=''?product.sds_file.name:'Generell riskbedömning'}}</span>
            <a class="mt-3 ml-2" (click)="product.sds_file = null">
              <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
                  21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
                  5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
                  3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
                  stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a>
          </div>
        </div>

        <div *ngIf="product" class="flex flex-row justify-center mb-20 mt-4">
          <button class="button-red mr-2" title="{{'REMOVE' | translate}}" (click)="remove(product)">
            <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'REMOVE' | translate }}</span>
          </button>
          <button class="button-green" title="{{'SAVE' | translate}}" (click)="save()">
            <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-lg">{{ 'SAVE' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


