<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>
<div *ngIf="loadingData==false" class="overflow-auto mb-40 md:mb-20">
    <div class="flex flex-col min-h-screen border border-gray-300">
        <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
                <path fill="#000000"
                    d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z" />
            </svg>
        </button>
        <div *ngIf="loadingData==false&&chemical" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2">
            <p>
                <span class="w-1/3">
                    <span class="flex items-center">
                        <span class="text-xl font-bold px-4 text-white">{{'RISK_ASSESSMENT' | translate}} -
                            {{product?.product_name}}</span>
                        <span class="flex items-center space-x-4 ml-2">
                            <img class="w-10 h-10 -mr-3 bg-white"
                                *ngIf="subscribesToSds && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))"
                                tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon"
                                src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                            <img class="w-10 h-10 -mr-3 bg-white"
                                *ngIf="subscribesToSds && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))"
                                tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}"
                                id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true"
                                (click)="openSdsInNewWindow(product)" />
                            <img class="w-10 h-10 -mr-3 bg-white"
                                *ngIf="subscribesToSds && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))"
                                tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}"
                                id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true"
                                (click)="openExtSdsInNewWindow(product)" />
                            <img class="w-10 h-10 bg-white" *ngIf="subscribesToGrb && product.risk_prio" tooltips
                                tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon"
                                src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                        </span><br />
                    </span>
                    <span class="text-lg px-4 text-white break-all w-1/3">{{"SUPPLIER" | translate}} -
                        {{product.supplier_name}}<br /></span>
                    <span class="text-lg px-4 text-white break-all w-1/3">{{"RISK_LEVEL" | translate}} -
                        {{product.risk_prio}}<br /></span>
                    <span class="text-lg px-4 text-white break-all w-1/3" *ngIf="product.allergic==1">{{"ALLERGIC" |
                        translate}} - {{"YES" | translate}}<br /></span>
                    <span class="text-lg px-4 text-white break-all w-1/3" *ngIf="product.allergic!=1">{{"ALLERGIC" |
                        translate}} - {{"NO" | translate}}<br /></span>
                    <span class="flex items-center">
                        <span class="text-lg px-4 text-white">{{"PICTOGRAM" | translate}} -</span>
                        <span class="flex items-center space-x-2 ml-2">
                            <span *ngFor="let pictogram of product.pictograms" tooltips tooltip-smart="true"
                                title="{{pictogram.description}}">
                                <img class="w-8 h-8" title="{{pictogram.description}}"
                                    src="/images/pictograms/{{pictogram.filename}}-tiny.png" />
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <div class="flex flex-col px-4 text-white">
                <p *ngIf='chemical.food_approved==1'><strong>{{'FOOD_APPROVED' | translate}}</strong>
                    &nbsp;-&nbsp;{{'YES' | translate}}<br></p>
                <p *ngIf='chemical.food_approved==0'><strong>{{'FOOD_APPROVED' | translate}}</strong> &nbsp;-&nbsp;{{'NO'
                    | translate}}<br></p>
                <p *ngIf='chemical.food_approved==2'><strong>{{'FOOD_APPROVED' | translate}}</strong>
                    &nbsp;-&nbsp;{{'NOT_ASSESSED' | translate}}<br></p>
                <p *ngIf='chemical.status==0'><strong>{{'STATUS' | translate}}</strong> &nbsp;-&nbsp;{{'ONGOING' |
                    translate}}<br></p>
                <p *ngIf='chemical.status==1'><strong>{{'STATUS' | translate}}</strong> &nbsp;-&nbsp;{{'FOR_REVIEW' |
                    translate}}<br></p>
                <p *ngIf='chemical.status==2'><strong>{{'STATUS' | translate}}</strong> &nbsp;-&nbsp;{{'FOR_VERIFICATION'
                    | translate}}<br></p>
                <p *ngIf='chemical.status==3'><strong>{{'STATUS' | translate}}</strong> &nbsp;-&nbsp;{{'APPROVED2' |
                    translate}}<br></p>
                <p  *ngIf='chemical.status==3'><strong>{{'APPROVED_BY' | translate}}</strong> &nbsp;-&nbsp;{{chemical.approved_by}}<br></p>
                <p *ngIf='chemical.substitution==1'><strong>{{'ACTION' | translate}}</strong>
                    &nbsp;-&nbsp;{{'SUBSTITUTION' | translate}}<br></p>
                <p *ngIf='chemical.phasing_out==1'><strong>{{'ACTION' | translate}}</strong>&nbsp;-&nbsp;{{'OUT_PHASING'
                    | translate}}<br></p>
                <p *ngIf='chemical.risk_minimzation==1'><strong>{{'ACTION' | translate}}</strong>
                    &nbsp;-&nbsp;{{'RISK_MINIMISATION' | translate}}<br></p>
            </div>
            <div *ngIf="chemical" class="px-7" (click)="createLRB()">
                <img class="w-10 h-10 cursor-pointer bg-white" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}"
                    id="LRBIcon" src="/images/icons/LRB.svg" area-hidden="true" />
            </div>
        </div>
        <div *ngIf="loadingData==false&&chemical" class="flex flex-col w-full py-2 px-4">
          <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
            <span class="text-lg font-bold mb-4">{{"RISK_DESCRIPTION" | translate}}</span>
            <div class="flex flex-col w-full mt-2">
              <div class="mb-4">
                <label class="font-bold mb-1">{{"GENERAL_RISKS" | translate}}</label><br>
                <textarea title="{{'GENERAL_RISKS' | translate}}"
                          class="w-full border border-gray-300 p-2 text-sm" rows="2"
                          [(ngModel)]="chemical.general_risks" (ngModelChange)="chemical.general_risks = $event"
                          [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
              </div>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"CHEMICAL_QUESTION_1" | translate}}</label><br>
                <textarea title="{{'CHEMICAL_QUESTION_1' | translate}}"
                          class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_1"
                          (ngModelChange)="chemical.question_1 = $event" [ngModelOptions]="{standalone: true}"
                          [disabled]="false"></textarea>
              </div>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"CHEMICAL_QUESTION_2" | translate}}</label><br>
                <textarea title="{{'CHEMICAL_QUESTION_2' | translate}}"
                          class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_2"
                          (ngModelChange)="chemical.question_2 = $event" [ngModelOptions]="{standalone: true}"
                          [disabled]="false"></textarea>
              </div>
            </div>
          </div>
          <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
            <div class="flex flex-col w-full mt-2">
              <span class="text-lg font-bold mt-1 mb-4">{{"LOCAL_CONDITIONS" | translate}}</span>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"CHEMICAL_QUESTION_3" | translate}}</label><br>
                <textarea title="{{'CHEMICAL_QUESTION_3' | translate}}"
                          class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_3"
                          (ngModelChange)="chemical.question_3 = $event" [ngModelOptions]="{standalone: true}"
                          [disabled]="false"></textarea>
              </div>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"CHEMICAL_QUESTION_4" | translate}}</label><br>
                <textarea title="{{'CHEMICAL_QUESTION_4' | translate}}"
                          class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_4"
                          (ngModelChange)="chemical.question_4 = $event" [ngModelOptions]="{standalone: true}"
                          [disabled]="false"></textarea>
              </div>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"CHEMICAL_QUESTION_5" | translate}}</label><br>
                <textarea title="{{'CHEMICAL_QUESTION_5' | translate}}"
                          class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_5"
                          (ngModelChange)="chemical.question_5 = $event" [ngModelOptions]="{standalone: true}"
                          [disabled]="false"></textarea>
              </div>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"CHEMICAL_QUESTION_6" | translate}}</label><br>
                <textarea title="{{'CHEMICAL_QUESTION_6' | translate}}"
                          class="w-full border border-gray-300 p-2 text-sm" rows="2" [(ngModel)]="chemical.question_6"
                          (ngModelChange)="chemical.question_6 = $event" [ngModelOptions]="{standalone: true}"
                          [disabled]="false"></textarea>
              </div>
                <div
                    class="flex flex-col w-full overflow-hidden justify-center items-center mb-2 p-7">
                    <span class="text-2xl py-2 mb-8">{{"PROTECTIVE_GEAR_SYMBOLS" | translate}}</span>
                    <div class="flex flex-row">
                        <span *ngFor="let protective of protectiveImages">
                            <img title="{{protective.filename}}" class="w-20 h-20 cursor-pointer mr-1"
                                [class.selected-border]="protectiveInWorkstep(protective.name)"
                                src="/images/protective/{{protective.filename}}-small.png"
                                (click)="addOrRemoveProtective(protective.name)" />
                        </span>
                    </div>
                </div>
            </div>
          </div>
          <div class="flex flex-col bg-white w-full rounded overflow-hidden justify-center items-center  shadow-lg mb-2 p-7">
            <div class="flex flex-col w-full mt-2">
              <div class="mb-4">
                <label class="font-bold mb-1">{{"DATE" | translate}}</label><br>
                <input title="{{'DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date"
                        [ngModel]="chemical.updated_date | date:'yyyy-MM-dd'"
                        (ngModelChange)="$event?chemical.updated_date = $event:null"
                        [ngModelOptions]="{standalone: true}">
              </div>
              <div *ngIf="issuer" class="mb-4">
                <label class="font-bold mb-1">{{"REGISTRAR" | translate}}:</label><br>
                <input title="{{'REGISTRAR' | translate}}" class="w-full border border-gray-300 p-2" type="text"
                        placeholder="{{issuer.first_name}} {{issuer.last_name}}" [required]="true"
                        [disabled]="true">
              </div>
              <div *ngIf="!issuer" class="mb-4">
                <label class="font-bold mb-1">{{"REGISTRAR" | translate}}</label><br>
                <input title="{{'REGISTRAR' | translate}}" class="w-full border border-gray-300 p-2" type="text"
                        placeholder="-" [required]="true" [disabled]="true">
              </div>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"RESPONSIBLE" | translate}}</label><br>
                <input title="{{'RESPONSIBLE' | translate}}" class="w-full border border-gray-300 p-2"
                        type="text" [(ngModel)]="chemical.responsible"
                        (ngModelChange)="chemical.responsible = $event" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"STATUS" | translate}}</label><br>
                <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
                  <button type="button" class="button-white w-full justify-between border border-gray-300">
                    <span *ngIf="chemical.status==0" class="text-sm">{{'ONGOING' | translate}}</span>
                    <span *ngIf="chemical.status==1" class="text-sm">{{'FOR_VERIFICATION' | translate}}</span>
                    <span *ngIf="chemical.status==2" class="text-sm">{{'FOR_VERIFICATION' | translate}}</span>
                    <span *ngIf="chemical.status==3" class="text-sm">{{'APPROVED2' | translate}}</span>
                    <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </button>
                  <div id="dropdownMenu" class="hidden dropdown-menu-static
                      overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                    <div class="py-1">
                      <div data-value="0" class="dropdown-menu-item" (click)="chemical.status=0"
                            tabindex="1">{{'ONGOING' | translate}}</div>
                      <div data-value="2" class="dropdown-menu-item" (click)="chemical.status=2"
                            tabindex="2">{{'FOR_VERIFICATION' | translate}}</div>
                      <div data-value="3" class="dropdown-menu-item" (click)="chemical.status=3"
                            tabindex="3">{{'APPROVED2' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <span *ngIf="chemical.usage_area?.length > 271" class="text-red-500">{{" (max 270 tecken) "}}</span>
                <label class="font-bold mb-1">{{"USAGE_AREA" | translate}}</label><br>
                <input title="{{'USAGE_AREA' | translate}}" class="w-full border border-gray-300 p-2"
                        type="text" [(ngModel)]="chemical.usage_area" (ngModelChange)="chemical.usage_area = $event"
                        [ngModelOptions]="{standalone: true}" maxlength="271">
              </div>
              <div class="mb-4">
                <span *ngIf="chemical.cdoc_comment?.length > 361" class="text-red-500">{{" (max 360 tecken) "}}</span>
                <label class="font-bold mb-1">{{"COMMENTS" | translate}}</label><br>
                <textarea title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2" rows="2"
                          [(ngModel)]="chemical.cdoc_comment" (ngModelChange)="chemical.cdoc_comment = $event"
                          [ngModelOptions]="{standalone: true}" [disabled]="false" maxlength="361"></textarea>
              </div>
              <div class="flex flex-col mt-2 mb-4">
                <label class="font-bold">{{"FOOD_APPROVED" | translate}}</label>
                <div class="flex flex-row">
                  <div class="flex items-center space-x-2 mt-2 py-2 px-4">
                    <input title="{{'YES' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-1"
                            type="checkbox" name="food_approved_yes" [(ngModel)]="food_approved_yes"
                            (ngModelChange)="food_approved_yes = $event" [ngModelOptions]="{standalone: true}"
                            (change)="onToggleFoodApproved(1)">
                      <span class="text-sm">{{"YES" | translate}}</span>
                  </div>
                  <div class="flex items-center space-x-2 mt-2 py-2 px-4">
                    <input title="{{'NO' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue mr-1"
                            type="checkbox" name="food_approved_no" [(ngModel)]="food_approved_no"
                            (ngModelChange)="food_approved_no = $event" [ngModelOptions]="{standalone: true}"
                            (change)="onToggleFoodApproved(0)">
                      <span class="text-sm">{{"NO" | translate}}</span>
                  </div>
                  <div class="flex items-center space-x-2 mt-2 py-2 px-4">
                    <input title="{{'NOT_ASSESSED' | translate}}"
                            class="form-checkbox h-5 w-5 text-cdocblue mr-1" type="checkbox"
                            name="food_approved_not_assessed" [(ngModel)]="food_approved_not_assessed"
                            (ngModelChange)="food_approved_not_assessed = $event"
                            [ngModelOptions]="{standalone: true}" (change)="onToggleFoodApproved(2)">
                      <span class="text-sm">{{"NOT_ASSESSED" | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <label class="font-bold mb-1">{{"ATTACHE_FILES" | translate}}</label><br>
                <input title="{{'ATTACHE_FILES' | translate}}"
                        class="button-white mt-2 border border-gray-300 mr-1" type="file" accept="application/pdf"
                        (change)="incomingfile($event)" />
                <div *ngFor="let file of chemical.assessmentchemicalfiles; let i = index">
                  <div class="flex flex-row items-center mt-4">
                    <a (click)="openFileInNewWindow(file)">
                      <img class="w-6 h-6 mr-1 cursor-pointer" id="SDBIcon"
                            src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true"
                            title="{{file.file_name}}" />
                    </a>
                    <span class="text-black mr-1">{{file.file_name}}</span>
                    <a (click)="fileRemove(i)">
                      <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                              21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                              5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                              3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                              stroke="#ff0000" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <label class="font-bold mb-4">{{"ATTACHE_PICTURES" | translate}}</label><br>
                <input title="{{'ATTACHE_PICTURES' | translate}}"
                        class="button-white mt-2 border border-gray-300 mr-1 mb-2" type="file" id="upload-file"
                        accept=".png, .jpg, .jpeg" (change)="incomingimage($event)" />
                <div class="flex flex-col" *ngFor="let image of pictures; let i = index">
                  <div class="flex flex-row">
                    <a>
                      <img title="{{'ATTACHE_PICTURES' | translate}}" [src]=image
                            height="{{pictureHeight}}" width="{{pictureWidth}}" />
                    </a>
                    <a (click)="pictureRemove(i)">
                      <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                              21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                              5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                              3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                            stroke="#ff0000" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loadingData==false&&chemical" class="flex flex-col w-full py-2 px-4">
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <span class="text-lg font-bold mb-4">{{"RISK_LIMITATION" | translate}}</span>
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"GENERAL" | translate}}<span class="text-red-500"
                                *ngIf="chemical.general_own?.length > 180" id="texterror">{{" (max 180 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'GENERAL' | translate}}" class="w-full border border-gray-300 p-2 text-sm"
                            rows="1" maxlength="181" placeholder="{{chemical.general_own}}"
                            [(ngModel)]="chemical.general_own" (ngModelChange)="chemical.general_own = $event"
                            [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"EYE_PROTECTION" | translate}}<span class="text-red-500"
                                *ngIf="chemical.eye_protection_own?.length > 180" id="texterror">{{" (max 180 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'EYE_PROTECTION' | translate}}"
                            class="w-full border border-gray-300 p-2 text-sm" rows="1" maxlength="181"
                            placeholder="{{chemical.eye_protection_own}}" [(ngModel)]="chemical.eye_protection_own"
                            (ngModelChange)="chemical.eye_protection_own = $event" [ngModelOptions]="{standalone: true}"
                            [disabled]="false"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"HAND_PROTECTION" | translate}}<span class="text-red-500"
                                *ngIf="chemical.hand_protection_own?.length > 180" id="texterror">{{" (max 180 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'HAND_PROTECTION' | translate}}"
                            class="w-full border border-gray-300 p-2 text-sm" rows="1" maxlength="181"
                            placeholder="{{chemical.hand_protection_own}}" [(ngModel)]="chemical.hand_protection_own"
                            (ngModelChange)="chemical.hand_protection_own = $event"
                            [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RESPIRATORY" | translate}}<span class="text-red-500"
                                *ngIf="chemical.breath_protection_own?.length > 180" id="texterror">{{" (max 180 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'RESPIRATORY' | translate}}"
                            class="w-full border border-gray-300 p-2 text-sm" rows="1" maxlength="181"
                            placeholder="{{chemical.breath_protection_own}}"
                            [(ngModel)]="chemical.breath_protection_own"
                            (ngModelChange)="chemical.breath_protection_own = $event"
                            [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"PROTECTIVE_CLOTHING" | translate}}<span class="text-red-500"
                                *ngIf="chemical.body_protection_own?.length > 180" id="texterror">{{" (max 180 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'PROTECTIVE_CLOTHING' | translate}}"
                            class="w-full border border-gray-300 p-2 text-sm" rows="1" maxlength="181"
                            placeholder="{{chemical.body_protection_own}}" [(ngModel)]="chemical.body_protection_own"
                            (ngModelChange)="chemical.body_protection_own = $event"
                            [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"HANDLING" | translate}}<span class="text-red-500"
                                *ngIf="chemical.handling_own?.length > 400" id="texterror">{{" (max 400 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'HANDLING' | translate}}" class="w-full border border-gray-300 p-2 text-sm"
                            rows="3" maxlength="401" placeholder="{{chemical.handling_own}}"
                            [(ngModel)]="chemical.handling_own" (ngModelChange)="chemical.handling_own = $event"
                            [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"STORAGE" | translate}}<span class="text-red-500"
                                *ngIf="chemical.storage_own?.length > 300" id="texterror">{{" (max 300 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'STORAGE' | translate}}" class="w-full border border-gray-300 p-2 text-sm"
                            rows="3" maxlength="301" placeholder="{{chemical.storage_own}}"
                            [(ngModel)]="chemical.storage_own" (ngModelChange)="chemical.storage_own = $event"
                            [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"WASTE_HANDLING_2" | translate}}<span class="text-red-500"
                                *ngIf="chemical.waste_own?.length > 500" id="texterror">{{" (max 500 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'WASTE_HANDLING_2' | translate}}"
                            class="w-full border border-gray-300 p-2 text-sm" rows="4" maxlength="501"
                            placeholder="{{chemical.waste_own}}" [(ngModel)]="chemical.waste_own"
                            (ngModelChange)="chemical.waste_own = $event" [ngModelOptions]="{standalone: true}"
                            [disabled]="false" maxlength="501"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"FIRE_MEASURES" | translate}}<span class="text-red-500"
                                *ngIf="chemical.fire_own?.length > 570" id="texterror">{{" (max 570 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'FIRE_MEASURES' | translate}}"
                            class="w-full border border-gray-300 p-2 text-sm" rows="4" maxlength="571"
                            placeholder="{{chemical.fire_own}}" [(ngModel)]="chemical.fire_own"
                            (ngModelChange)="chemical.fire_own = $event" [ngModelOptions]="{standalone: true}"
                            [disabled]="false" maxlength="571"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"REMARKS" | translate}}<span class="text-red-500"
                                *ngIf="chemical.remark?.length > 270" id="texterror">{{" (max 270 tecken)
                                "}}</span></label><br>
                        <textarea title="{{'REMARKS' | translate}}" class="w-full border border-gray-300 p-2 text-sm"
                            rows="1" maxlength="271" placeholder="{{chemical.remark}}" [(ngModel)]="chemical.remark"
                            (ngModelChange)="chemical.remark = $event" [ngModelOptions]="{standalone: true}"
                            [disabled]="false"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="loadingData==false&&chemical" class="flex flex-col w-full py-2 px-4">
            <div class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"LOCAL_RISK_LEVEL" | translate}}</label><br>
                        <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
                            <button type="button" class="button-white w-full justify-between border border-gray-300">
                                <span class="text-sm">{{chemical?.risk}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                            <div id="dropdownMenu"
                                class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10"
                                #dropdownMenuInside>
                                <div *ngFor="let i of [1, 2, 3, 4, 5]" data-value="i" class="dropdown-menu-item"
                                    (click)="chemical.risk=i" tabindex="i">{{i}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <div class="flex flex-col md:flex-row mb-4">
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'NEGLIGIBLE_USE' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue"
                                type="checkbox" name="negligible_use" [(ngModel)]="negligible_use"
                                (ngModelChange)="negligible_use = $event" [ngModelOptions]="{standalone: true}"
                                (change)="onToggleShowNegligibleUse()">
                            <span class="text-sm mb-1">{{"NEGLIGIBLE_USE" | translate}}</span>
                        </div>
                    </div>

                    <div class="mb-4">
                        <label class="mb-1">{{"NEGLIGIBLE_USE_COMMENT" | translate}}</label><br>
                        <textarea title="{{'NEGLIGIBLE_USE_COMMENT' | translate}}"
                            class="w-full border border-gray-300 p-2" rows="2"
                            [(ngModel)]="chemical.negligible_use_comment"
                            (ngModelChange)="chemical.negligible_use_comment = $event"
                            [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
                    </div>
                </div>
            </div>


            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <span class="text-lg font-bold mb-2">{{"ACTIONS" | translate}}</span>
                    <div class="flex flex-col md:flex-row mb-4">
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'SUBSTITUTION' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue"
                                type="checkbox" name="substitution" [(ngModel)]="substitution"
                                (ngModelChange)="substitution = $event" [ngModelOptions]="{standalone: true}"
                                (change)="onToggleShowSubstitution()">
                            <span class="text-sm mr-1">{{"SUBSTITUTION" | translate}}</span>
                        </div>
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'OUT_PHASING' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue"
                                type="checkbox" name="phasing_out" [(ngModel)]="phasing_out"
                                (ngModelChange)="phasing_out = $event" [ngModelOptions]="{standalone: true}"
                                (change)="onToggleShowOutphasing()">
                            <span class="text-sm mr-1">{{"OUT_PHASING" | translate}}</span>
                        </div>
                        <div class="flex items-center space-x-2 ml-4 mt-2 mdm-4">
                            <input title="{{'RISK_MINIMISATION' | translate}}"
                                class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="risk_minimzation"
                                [(ngModel)]="risk_minimzation" (ngModelChange)="risk_minimzation = $event"
                                [ngModelOptions]="{standalone: true}" (change)="onToggleShowRiskMinimzation()">
                            <span class="text-sm mr-1">{{"RISK_MINIMISATION" | translate}}</span>
                        </div>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"COMMENTS" | translate}}</label><br>
                        <textarea title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2" rows="2"
                            [(ngModel)]="chemical.comments" (ngModelChange)="chemical.comments = $event"
                            [ngModelOptions]="{standalone: true}" [disabled]="false"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-center items-center mb-20 mt-4">
            <button *ngIf="chemical.id" class="button-red" title="{{'REMOVE' | translate}}" (click)="remove()">
                <img title="{{'REMOVE' | translate}}" src="images/icons/trash-alt-svgrepo-com.svg" class="h-6 w-5">
                <span class="text-white text-lg mr-1">{{ 'REMOVE' | translate }}</span>
            </button>
            <button class="button-green" title="{{'SAVE' | translate}}" (click)="submit()">
                <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="h-6 w-5">
                <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
            </button>
        </div>
    </div>
</div>
