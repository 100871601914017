import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-law',
  templateUrl: './law.component.html',
  styleUrls: ['./law.component.css']
})
export class LawComponent implements OnInit {
  subscribesToLawLawpoint: boolean;
  subscribesToLawAptor: boolean;

  constructor(private authService: AuthService, private utilService:UtilService) { }

  ngOnInit(): void {
    this.subscribesToLawLawpoint = this.utilService.subscribesTo('subscription_lawpoint');
    this.subscribesToLawAptor = this.utilService.subscribesTo('subscription_law_aptor');
  }

  logout() {
    this.authService.logout();
  }
}
