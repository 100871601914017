import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-materiallist',
  templateUrl: './materiallist.component.html',
  styleUrls: ['./materiallist.component.css']
})
export class MateriallistComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  projects: any;
  listAlldepartments: any;
  sortDirection: any = null;
  sortValue: any = null;

  projectSearch = {
    all:"",
    name: "",
    number: "",
    issuer: "",
    mailRecipient: "",
    mailDate: ""
  };

  ngAfterViewInit(): void {
    $('#materialtable').tablesort();
  }

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private userService: UserService,
    private router: Router,
    private materialListService: MaterialListService) { }

  ngOnInit(): void {

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    this.sortDirection = localStorage.getItem('sortDirection', );
    if (this.sortDirection == null)
    {
      this.sortDirection = 'desc';
    }

    this.sortValue = localStorage.getItem('sortValue', );
    if (this.sortValue == null)
    {
      this.sortValue = 'project_date';
    }

    localStorage.removeItem('sortDirection');
    localStorage.removeItem('sortValue');

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.getAllDepartments();
    this.getProjects();

    // Set Login Status
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      if(this.router.url.indexOf('materiallist')>0){
        this.ngOnInit();
      }
    })
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              this.itemsPerPage = 10;
              return;
            }
          } else {
            this.itemsPerPage = 10;
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  editMaterialList(project) {
    this.router.navigate(['/materialedit', project.id]);
  }

  sortHeader(value) {
    if (this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
    }
    else
    {
      this.sortDirection = 'asc';
    }

    localStorage.setItem('sortDirection', this.sortDirection);
    localStorage.setItem('sortValue', value);
    window.location.reload();
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  addProject() {
    this.router.navigate(['/materialadd']);
  }

  getProjects() {
    this.materialListService.getAll();
    this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
      next: projects => {
        if (projects == null) {}
        else {
          this.projects = projects;
          this.projects = _.orderBy(this.projects, [this.sortValue],[this.sortDirection]);
        }
        this.getItemsPerPage();
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  projectSelected(project) {
    this.router.navigate(['/material', project.id]);
  }

  copyMaterialList(project){
    this.materialListService.getSingleProject(project.id);
    this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
      next: project => {
        this.loadingData = false;
        if (project == null) {}
        else {
          project.project_date = this.utilService.adjustDateForTimezoneOffset(project.project_date);
          project.name = project.name + ' (kopia) ';
          if(typeof(project.products)!="undefined"){
            for(var i = 0; i < project.products.length; i++) {
              project.products[i].sareq_id=project.products[i].product_id;
            }
          }
          this.materialListService.createProject(project);
          this.materialListService.getMaterialDataListener().pipe(take(1)).subscribe({
          next: project => {
            if (project == null) {}
          else {
            this.router.navigate(['/materiallist']);
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
          return;
        }
      },
      error: error => {
        this.loadingData = false;
        console.log("Error: ", error);
      }
    })
  }

  logout() {
    this.authService.logout();
  }
}
