<div class="flex flex-row bg-white w-full rounded shadow-lg mb-2 p-7">
    <div class="flex justify-between items-center w-1/2 relative inline-block text-left z-30" appDropdown>
        <button type="button" class="button-white justify-between w-full items-start border border-gray-300 mr-2">
        <span class="text-sm">{{monthText}}</span>
        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </button>
        <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
            <div class="py-1">
                <div data-value="1" class="dropdown-menu-item" (click)="month=1;monthText='Januari';getUserInfo()" tabindex="1">Januari</div>
                <div data-value="2" class="dropdown-menu-item" (click)="month=2;monthText='Februari';getUserInfo()" tabindex="2">Februari</div>
                <div data-value="3" class="dropdown-menu-item" (click)="month=3;monthText='Mars';getUserInfo()" tabindex="3">Mars</div>
                <div data-value="4" class="dropdown-menu-item" (click)="month=4;monthText='April';getUserInfo()" tabindex="4">April</div>
                <div data-value="5" class="dropdown-menu-item" (click)="month=5;monthText='Maj';getUserInfo()" tabindex="5">Maj</div>
                <div data-value="6" class="dropdown-menu-item" (click)="month=6;monthText='Juni';getUserInfo()" tabindex="6">Juni</div>
                <div data-value="7" class="dropdown-menu-item" (click)="month=7;monthText='Juli';getUserInfo()" tabindex="7">Juli</div>
                <div data-value="8" class="dropdown-menu-item" (click)="month=8;monthText='Augusti';getUserInfo()" tabindex="8">Augusti</div>
                <div data-value="9" class="dropdown-menu-item" (click)="month=9;monthText='September';getUserInfo()" tabindex="9">September</div>
                <div data-value="10" class="dropdown-menu-item" (click)="month=10;monthText='Oktober';getUserInfo()" tabindex="10">Oktober</div>
                <div data-value="11" class="dropdown-menu-item" (click)="month=11;monthText='November';getUserInfo()" tabindex="11">November</div>
                <div data-value="12" class="dropdown-menu-item" (click)="month=12;monthText='December';getUserInfo()" tabindex="12">December</div>
            </div>
        </div>
    </div>
    <div class="flex justify-between items-center w-1/2 relative inline-block text-left z-30" appDropdown>
        <button type="button" class="button-white justify-between w-full items-start border border-gray-300 mr-2">
        <span class="text-sm">{{year}}</span>
        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </button>
        <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
            <div class="py-1">
                <div *ngFor="let new_year of years" data-value="year" class="dropdown-menu-item" (click)="year=new_year;getUserInfo()" tabindex="1">{{ new_year }}</div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
    <div class="loader"></div>
  </div>
<div *ngIf="loadingData==false" class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
    <div class="overflow-x-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400">
            <thead class="bg-white">
                <tr>
                <th class="table-row-header">{{"USERNAME" |translate}}</th>
                <th class="table-row-header">{{"SAREQ_STATUS_4" |translate}}</th>
                <th class="table-row-header">{{"SAREQ_STATUS_3" |translate}}</th>
                <th class="table-row-header">{{"SAREQ_STATUS_5" |translate}}</th>
                <th class="table-row-header">{{"SAREQ_STATUS_0" |translate}}</th>
                <th class="table-row-header">{{"SAREQ_STATUS_2" |translate}}</th>
                <th class="table-row-header">{{"SAREQ_STATUS_1" |translate}}</th>
                </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of users | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };">
                <td class="table-row-column" (click)="userSelected(user)">{{ user.assigned }}</td>
                <td class="table-row-column" (click)="userSelected(user)">{{ user.newCounter }}</td>
                <td class="table-row-column" (click)="userSelected(user)">{{ user.freeCounter }}</td>
                <td class="table-row-column" (click)="userSelected(user)">{{ user.ongoingCounter }}</td>
                <td class="table-row-column" (click)="userSelected(user)">{{ user.inspectionCounter }}</td>
                <td class="table-row-column" (click)="userSelected(user)">{{ user.returnCounter }}</td>
                <td class="table-row-column" (click)="userSelected(user)">{{ user.doneCounter }}</td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="4">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"SAREQ" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
<div *ngIf="loadingData==false&&chosenUserEdit==true" class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
    <p>Antal poster gjorda {{year}} av {{chosenUser}}: <strong>{{chosenUserCount}}</strong></p>
    <p>Antal poster i retur {{year}} av {{chosenUser}}: <strong>{{chosenUserCountReturn}}</strong></p>
</div>
