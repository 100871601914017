import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Md5 }  from 'ts-md5/dist/md5';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private authStatusListener = new Subject<boolean>();
  private cred_password: string;
  private credentials: string;
  private token: string;
  private userId: string;
  private userName: string;
  private orgid: string;
  private localOrgid: string;
  private isAuthenticated = false;
  private currentUser: any;

  constructor(private http: HttpClient, private router:Router, private userService: UserService,
              private utilService: UtilService, private organisationService: OrganisationService) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': "",
      'Content-Type': 'application/json'
    })
  }

  getIsAuth() {
    const token = localStorage.getItem('token');
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser == null) {
      return false;
    }
    const userId = this.utilService.serialize(currentUser).id;
    const time = +localStorage.getItem('tokenTime')-Date.now();
    if(token && userId && time > 0){
      return true;
    }
    return false;
  }

  getAuthServiceListener() {
    return this.authStatusListener.asObservable();
  }

  login(username, password, access_token) {

    if (access_token == null) {
      this.http.get<any>(BackendUrl + "/userinfo/" + username).pipe(take(1)).subscribe({
        next: response => {
          this.cred_password = Md5.hashStr(response + password || "")
          this.http.post<any>(BackendUrl + "/login/", { username: username, password: this.cred_password }).subscribe({
            next: response => {
              localStorage.setItem('token', response.token);
              this.userService.getCurrentUser(true);
              this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
                next: userData => {
                  if (userData == null) {
                    this.authStatusListener.next(false);
                  }
                  else {
                    this.isAuthenticated = true;
                    localStorage.setItem('currentUser', userData);
                    userData = JSON.parse(userData);
                    switch(userData.language)
                    {
                      case 0 :
                        localStorage.setItem('usedLanguage', 'sv');
                      break;
                      case 1:
                        localStorage.setItem('usedLanguage', 'en');
                      break;
                      case 2:
                        localStorage.setItem('usedLanguage', 'fi');
                      break;
                      default:
                        localStorage.setItem('usedLanguage', 'sv');
                      break;
                    }

                    this.setTokenTime();
                    this.organisationService.getOrganisation(userData.root_organisation_id);
                    this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
                      next: organisation => {
                        // A recursive function to get All departments of an organisation
                        // the function must be defined here but used later
                        let startPoint = organisation;
                        let searchId = [];
                        let idArray=[];
                        let allOrganisations=[];
                        idArray.push(organisation.id);
                        allOrganisations.push(organisation);

                          function getId (startPoint){
                            searchId.push(startPoint.id);
                            startPoint.orgs.forEach(element => {
                              if(searchId.indexOf(element.id)<0){
                                idArray.push(element.id);
                                allOrganisations.push(element);
                                getId(element);
                              }
                            });
                          }

                        if (organisation == null) {
                          this.authStatusListener.next(false);
                        }
                        else
                        {
                          localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
                          localStorage.setItem('selectedOrganisation', JSON.stringify(organisation));

                          // Get all departments
                          getId(startPoint);


                          if (userData.is_local_user == 1 || userData.is_local_admin == 1 || userData.is_local_assignee == 1 || userData.guest == 1 ||
                            (!userData.is_admin&&!userData.is_super_user&&!userData.is_useradmin&&!userData.maintenance_user&&!userData.readonly_user)) {

                            userData.LocalUserDepartments = [];

                            if ((!userData.department||userData.department.trim() == ""||userData.department==0)
                                &&(!userData.is_local_user_department||userData.is_local_user_department.trim() == ""||userData.is_local_user_department==0)) {
                                userData.LocalUserDepartments.push(userData.root_organisation_id.trim());
                            } else {
                              userData.LocalUserDepartments.push(userData.department);
                              var departmentArrayTemp = userData.is_local_user_department.split(',');
                              for (let value of departmentArrayTemp) {
                                if (value != ""){
                                userData.LocalUserDepartments.push(value.trim());
                                }
                              }
                            }

                            for (let i = 0; i < userData.LocalUserDepartments.length; i++) {
                              for (var j = 0; j < allOrganisations.length; j++) {
                                if (userData.LocalUserDepartments[i] == allOrganisations[j].id) {
                                  localStorage.setItem('selectedDepartment', JSON.stringify(allOrganisations[j]));
                                  this.authStatusListener.next(true);
                                  //window.location.href = '/';
                                  if(userData.is_admin == 1 || userData.is_super_user == 1 || userData.is_useradmin == 1 || userData.is_local_admin == 1 || userData.is_local_assignee == 1 || userData.is_maintenance_user == 1){
                                    this.router.navigate(['/dashboard']).then(() => {
                                      window.location.reload();
                                    })
                                  } else {
                                    this.router.navigate(['/productlist']).then(() => {
                                      window.location.reload();
                                    })
                                  }
                                }
                              }
                            }
                          } else {
                            if ((userData.department != null) && (userData.department != '')) {
                              for (var j = 0; j < allOrganisations.length; j++) {
                                if (userData.department == allOrganisations[j].id) {
                                  localStorage.setItem('selectedDepartment', JSON.stringify(allOrganisations[j]));
                                  this.authStatusListener.next(true);
                                  //window.location.href = '/';
                                  if(userData.is_admin == 1 || userData.is_super_user == 1 || userData.is_useradmin == 1 || userData.is_local_admin == 1 || userData.is_local_assignee == 1 || userData.is_maintenance_user == 1){
                                    this.router.navigate(['/dashboard']).then(() => {
                                      window.location.reload();
                                    })
                                  } else {
                                    this.router.navigate(['/productlist']).then(() => {
                                      window.location.reload();
                                    })
                                  }
                                }
                              }
                            }
                            else
                            {
                              localStorage.setItem('selectedDepartment', JSON.stringify(organisation));
                              this.authStatusListener.next(true);
                              //window.location.href = '/';
                              if(userData.is_admin == 1 || userData.is_super_user == 1 || userData.is_useradmin == 1 || userData.is_local_admin == 1 || userData.is_local_assignee == 1 || userData.is_maintenance_user == 1){
                                this.router.navigate(['/dashboard']).then(() => {
                                  window.location.reload();
                                })
                              } else {
                                this.router.navigate(['/productlist']).then(() => {
                                  window.location.reload();
                                })
                              }
                            }
                          }
                        }
                      },
                      error: error => {
                        this.authStatusListener.next(false);
                      }
                    })
                  }
                },
                error: error => {
                  this.authStatusListener.next(false);
                }
              })
            },
            error: error => {
              this.authStatusListener.next(false);
            }
          })
        },
        error: error => {
          this.authStatusListener.next(false);
        }
      })
    }
    else
    {
      this.http.post<any>(BackendUrl + "/login/", { username: username, password: null, access_token: access_token}).pipe(take(1)).subscribe({
        next: response => {
          localStorage.setItem('token', response.token);
          this.userService.getCurrentUser(true);
          this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
            next: userData => {
              if (userData == null) {
                this.authStatusListener.next(false);
              }
              else {
                this.isAuthenticated = true;
                localStorage.setItem('currentUser', userData);
                userData = JSON.parse(userData);
                switch(userData.language)
                {
                  case 0 :
                    localStorage.setItem('usedLanguage', 'sv');
                  break;
                  case 1:
                    localStorage.setItem('usedLanguage', 'en');
                  break;
                  case 2:
                    localStorage.setItem('usedLanguage', 'fi');
                  break;
                  default:
                    localStorage.setItem('usedLanguage', 'sv');
                  break;
                }
                this.setTokenTime();
                this.organisationService.getOrganisation(userData.root_organisation_id);
                this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
                  next: organisation => {
                    // A recursive function to get All departments of an organisation
                        // the function must be defined here but used later
                        let startPoint = organisation;
                        let searchId = [];
                        let idArray=[];
                        let allOrganisations=[];
                        idArray.push(organisation.id);
                        allOrganisations.push(organisation);

                          function getId (startPoint){
                            searchId.push(startPoint.id);
                            startPoint.orgs.forEach(element => {
                              if(searchId.indexOf(element.id)<0){
                                idArray.push(element.id);
                                allOrganisations.push(element);
                                getId(element);
                              }
                            });
                          }
                    if (organisation == null) {
                      this.authStatusListener.next(false);
                    }
                    else
                    {
                      localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
                      localStorage.setItem('selectedOrganisation', JSON.stringify(organisation));

                      // Get all departments
                      getId(startPoint);


                      if (userData.is_local_user == 1 || userData.is_local_admin == 1 || userData.is_local_assignee == 1 || userData.is_guest == 1||
                        (!userData.is_admin&&!userData.is_super_user&&!userData.is_useradmin&&!userData.is_maintenance_user&&!userData.is_readonly_user)) {

                        userData.LocalUserDepartments = [];

                        // if (userData.department == null || userData.department.trim() == "" && userData.is_local_user_department.trim() == "") {
                        if ((!userData.department||userData.department.trim() == ""||userData.department==0)
                              &&(!userData.is_local_user_department||userData.is_local_user_department.trim() == ""||userData.is_local_user_department==0)) {
                          userData.LocalUserDepartments.push(userData.root_organisation_id.trim());
                        } else {
                          userData.LocalUserDepartments.push(userData.department);
                          var departmentArrayTemp = userData.is_local_user_department.split(',');
                          for (let value of departmentArrayTemp) {
                            if (value != ""){
                            userData.LocalUserDepartments.push(value.trim());
                            }
                          }
                        }

                        for (let i = 0; i < userData.LocalUserDepartments.length; i++) {
                          for (var j = 0; j < allOrganisations.length; j++) {
                            if (userData.LocalUserDepartments[i] == allOrganisations[j].id) {
                              localStorage.setItem('selectedDepartment', JSON.stringify(allOrganisations[j]));
                              this.authStatusListener.next(true);
                              // window.location.href = '/';
                              if(userData.is_admin == 1 || userData.is_super_user == 1 || userData.is_useradmin == 1 || userData.is_local_admin == 1 || userData.is_local_assignee == 1 || userData.is_maintenance_user == 1){
                                this.router.navigate(['/dashboard']).then(() => {
                                  window.location.reload();
                                })
                              } else {
                                this.router.navigate(['/productlist']).then(() => {
                                  window.location.reload();
                                })
                              }
                            }
                          }
                        }
                      } else {
                        if ((userData.department != null) && (userData.department != '')) {
                          for (var j = 0; j < allOrganisations.length; j++) {
                            if (userData.department == allOrganisations[j].id) {
                        localStorage.setItem('selectedDepartment', JSON.stringify(allOrganisations[j]));
                        this.authStatusListener.next(true);
                        //window.location.href = '/';
                        if(userData.is_admin == 1 || userData.is_super_user == 1 || userData.is_useradmin == 1 || userData.is_local_admin == 1 || userData.is_local_assignee == 1 || userData.is_maintenance_user == 1){
                          this.router.navigate(['/dashboard']).then(() => {
                            window.location.reload();
                          })
                        } else {
                          this.router.navigate(['/productlist']).then(() => {
                            window.location.reload();
                          })
                        }

                      }
                    }
                  } else {
                    for (var j = 0; j < allOrganisations.length; j++) {
                      if (userData.root_organisation_id == allOrganisations[j].id) {
                    localStorage.setItem('selectedDepartment', JSON.stringify(allOrganisations[j]));
                    this.authStatusListener.next(true);
                    if(userData.is_admin == 1 || userData.is_super_user == 1 || userData.is_useradmin == 1 || userData.is_local_admin == 1 || userData.is_local_assignee == 1 || userData.is_maintenance_user == 1){
                      this.router.navigate(['/dashboard']).then(() => {
                        window.location.reload();
                      })
                    } else {
                      this.router.navigate(['/productlist']).then(() => {
                        window.location.reload();
                      })
                    }
                  }
                }
                  }
                }
              }
            },
                  error: error => {
                    this.authStatusListener.next(false);
                  }
                })
              }
            },
            error: error => {
              this.authStatusListener.next(false);
            }
          })
        },
        error: error => {
          this.authStatusListener.next(false);
        }
      })
    }
  }

  setTokenTime() {
    var date=Date.now()+12*60*60*1000;
    localStorage.setItem('tokenTime', date.toString());
  }

  logout() {
    this.token = null;
    this.userId = null;
    this.authStatusListener.next(false);
    this.isAuthenticated = false;
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenTime');
    var usedLanguage = localStorage.getItem('usedLanguage');
    var items_per_page = localStorage.getItem('items_per_page');
    localStorage.clear();
    localStorage.setItem('usedLanguage',usedLanguage);
    if ( items_per_page !== null && !isNaN(Number(items_per_page) ) ) {
      localStorage.setItem('items_per_page',items_per_page);
    }
    window.location.href = '/';
    // this.router.navigate(['/login']);
  }
}
