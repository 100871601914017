<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
    <!-- Modal Content -->
    <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"REPORT_ARTICLES" | translate}}</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-4 p-2">
                <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                    <div class="loader"></div>
                </div>
  
                <div *ngIf="loadingData==false" class="overflow-auto border mb-20 border-gray-200">
                    <table datatable [dtOptions]="dtOptions" class="table-auto w-full">
                        <thead>
                            <tr>
                                <th class="table-row-header sort-header">{{"MANUFACTURER" | translate}}</th>
                                <th class="table-row-header sort-header">{{"PRODUCT" | translate}}</th>
                                <th class="table-row-header sort-header">{{"ARTICLE_NUMBER" | translate}}</th>
                                <th class="table-row-header sort-header">{{"DEPARTMENT" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let article of productArticles">
                                <td class="table-row-column">{{article.supplier_name}}</td>
                                <td class="table-row-column">{{article.name}}</td>
                                <td class="table-row-column">{{article.customerarticle}}</td>
                                <td class="table-row-column">{{article.department}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
    
