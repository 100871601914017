import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: "root",
})
export class ManufacturerService {
  getManufacturerDataUpdated = new Subject<any>();

  constructor(private router: Router, private http: HttpClient, private utilService: UtilService,
              private authService: AuthService, private translate: TranslateService) {}

  getAll() {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    this.http
      .get<any>(BackendUrl + "/manufacturer", { headers: headers })
      .pipe(take(1)).subscribe({
        next: (manufacturer) => {
          this.getManufacturerDataUpdated.next(manufacturer);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getManufacturerDataUpdated.next(null);
        },
      });
  }

  getSingle(object) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    if(object.type=='get'){
      this.http
      .get<any>(BackendUrl + "/manufacturer/"+object.id, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (manufacturer) => {
          this.getManufacturerDataUpdated.next(manufacturer);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getManufacturerDataUpdated.next(null);
        },
      });
    }
  }

  createManufacturerWithId(info) {
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http.post(BackendUrl + '/manufacturer', info, { headers: headers })
    .pipe(take(1)).subscribe({
      next: (values) => {
        Swal.fire(this.translate.instant("MANUFACTURER_CREATED"));
        this.router.navigate(["manufacturer"])
      },
      error: (error) => {
        if (error.status === 401)
        {
          this.authService.logout();
        } else {
          Swal.fire("Error", this.translate.instant("ERROR_IN_INPUT"));
        }
        return;
      },
    })
    }

    updateManufacturerWithId(id, info) {
      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
      this.http.put(BackendUrl + '/manufacturer/' + id, info, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (values) => {
          Swal.fire(this.translate.instant("MANUFACTURER_UPDATED"));
          this.router.navigate(["manufacturer"])
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          } else {
            Swal.fire("Error", this.translate.instant("ERROR_IN_INPUT"));
          }
          return;
        },
      })
    }

    deleteManufacturerWithId(id) {
      let token = localStorage.getItem("token");
      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });
      this.http.delete(BackendUrl + '/manufacturer/' + id, { headers: headers })
      .pipe(take(1)).subscribe({
        next: (values) => {
          this.router.navigate(["manufacturer"])
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          return;
        },
      })
    }

  public getManufacturerDataListener() {
    return this.getManufacturerDataUpdated.asObservable();
  }
}
