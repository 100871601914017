import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ProductService } from "src/app/services/product.service";
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
import * as Highcharts from 'highcharts';
declare var $: any;

@Component({
  selector: 'app-dashboard-yearly-report',
  templateUrl: './dashboard-yearly-report.component.html',
  styleUrls: ['./dashboard-yearly-report.component.css'],
  providers: [DatePipe]
})

export class DashboardYearlyReportComponent implements OnInit {
  currentUser: any;
  loadingChangedProducts: boolean = false;
  getProductsIsClicked: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  products: any;
  historyProductGroup:any;
  Highcharts: typeof Highcharts = Highcharts;
  allDep:boolean = false;
  changedProductsFrom;
  changedProductsTo;
  changedProducts: any[] = [];
  errorDate = "";
  groupInfo: any;
  groupInfo2: any;
  updateYearlyReportChart = false;
  updateYearlyRiskChart = false;
  updateYearlyRestrictionChart = false;
  updateCMRAChart = false;
  updateSpecialChart = false;
  updatePictogramChart = false;
  CMRA_amount: any[] = [];
  sareqIds: any[];
  dynamicTitle = "";
  dynamicTitleHeader= "";
  ecoCounter: any[] = [];
  restrictionListsCount: any[] = [];
  restriktionList: any[] = [];
  timeperiod: any = "Yearly";
  subscribesToSIN: any;
  subscribesToSLL: any;
  subscribesToBASTA: any;
  subscribesToSpecial: any;
  showPercent: boolean = true;
  pictogramLinks = [];

  yearlyreportChartConfig: Highcharts.Options = {
    chart: {
        type: 'column',
        width: this.getWidth(),
        height: 400
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500  // This is the breakpoint
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: false,
      itemStyle: {
        'cursor': 'default'
      }
    },
    xAxis: {
      categories: [],
      title: {
        text: ""
      },
      labels: {
        x: 5,
        useHTML: true,
      },
    },
    yAxis: {
      min: 0,
      max: null,
      title: {
        text: ""

      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    tooltip: {
      pointFormat:null,
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: false,
      cursor: 'default',
      point: {

      },
      data: []
    }]
  }

  yearlyRiskChartConfig: Highcharts.Options = {
    chart: {
        type: 'column',
        width: this.getWidth(),
        height: 400
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500  // This is the breakpoint
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: true,
      itemStyle: {
        'cursor': 'default'
      }
    },
    tooltip: {
      pointFormat:null,
    },
    xAxis: {
      categories: [],
      title: {
        text: ""
      },
      labels: {
        x: 5,
        useHTML: true,
      },
    },
    yAxis: {
      min: 0,
      max: null,
      title: {
        text: ""

      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            //stacking: 'normal',
            pointPadding: 0.2,
            borderWidth: 0,
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: false,
      cursor: 'default',
      point: {

      },
      data: []
    }]
  }

  yearlyRestrictionChartConfig: Highcharts.Options = {
    chart: {
        type: 'column',
        width: this.getWidth(),
        height: 400
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500  // This is the breakpoint
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: true,
      itemStyle: {
        'cursor': 'default'
      }
    },
    xAxis: {
      categories: [],
      title: {
        text: ""
      },
      labels: {
        x: 5,
        useHTML: true,
      },
    },
    yAxis: {
      min: 0,
      max: null,
      title: {
        text: ""

      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            //stacking: 'normal',
            pointPadding: 0.2,
            borderWidth: 0,
            dataLabels: {
                enabled: false
            }
        }
    },
    tooltip: {
      pointFormat:null,
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: false,
      cursor: 'default',
      point: {
},
      data: []
    }]
  }

  yearlyreportCMRAChartConfig: Highcharts.Options = {
    chart: {
        type: 'column',
        width: this.getWidth(),
        height: 400
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500  // This is the breakpoint
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: true,
      itemStyle: {
        'cursor': 'default'
      }
    },
    xAxis: {
      categories: [],
      title: {
        text: ""
      },
      labels: {
        x: 5,
        useHTML: true,
      },
    },
    yAxis: {
      min: 0,
      max: null,
      title: {
        text: ""

      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            //stacking: 'normal',
            pointPadding: 0.2,
            borderWidth: 0,
            dataLabels: {
                enabled: false
            }
        }
    },
    tooltip: {
      pointFormat:null,
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: false,
      cursor: 'default',
      point: {

      },
      data: []
    }]
  }

  yearlyreportSpecialChartConfig: Highcharts.Options = {
    chart: {
        type: 'column',
        width: this.getWidth(),
        height: 400
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500  // This is the breakpoint
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: [],
      title: {
        text: ""
      },
      labels: {
        x: 5,
        useHTML: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: ""

      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            //stacking: 'normal',
            pointPadding: 0.2,
            borderWidth: 0,
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [{
      name: '',
      type: 'column',
      colorByPoint: false,
      cursor: 'default',
      point: {

      },

      data: []
    }],
    legend: {
      useHTML: true,
      enabled: true,
      itemStyle: {
        'cursor': 'default'
      }
    },
    tooltip: {
      pointFormat:null,
    }
  }

  yearlyreportPictogramChartConfig: Highcharts.Options = {
    chart: {
        type: 'column',
        width: this.getWidth(),
        height: 400
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500  // This is the breakpoint
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: true,
      useHTML: true,
      itemStyle: {
        'cursor': 'default'
      }
    },
    xAxis: {
      categories: [],
      title: {
        text: ""
      },
      labels: {
        x: 5,
        useHTML: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: ""

      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            //stacking: 'normal',
            pointPadding: 0.2,
            borderWidth: 0,
            dataLabels: {
                enabled: false
            }
        }
    },
    tooltip: {
      pointFormat:null,
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: false,
      cursor: 'default',
      point: {

      },
      data: []
    }]
  }

  constructor(
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private utilService: UtilService,
    private authService: AuthService, private productService: ProductService,
    private productConversionsService: ProductConversionsService,
    private router: Router, private datePipe: DatePipe) { }

  ngOnInit(): void {
    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    this.subscribesToSIN = this.utilService.subscribesTo('subscription_sin');
    this.subscribesToSLL = this.utilService.subscribesTo('subscription_sll');
    this.subscribesToBASTA = this.utilService.subscribesTo('subscription_basta');
    this.subscribesToSpecial = this.utilService.subscribesTo('subscription_special_demands');
    this.dynamicTitle = this.translate.instant("PERCENT");
    this.dynamicTitleHeader = this.translate.instant("PERCENTSIGN");
    this.getProducts('');
  }

  logout() {
    this.authService.logout();
  }

  tabDashboard() {
    this.router.navigate(['/dashboard']);
  }

  tabDashboardAdmin() {
    this.router.navigate(['/dashboardadmin']);
  }

  resetChartData() {
    // This is to reset the data in the chart for riskprio
    var numberOfColumnsRestrictionChart = 13;
    if (this.timeperiod == "Monthly") {
      this.yearlyreportChartConfig.series[0]["data"]  = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.yearlyRiskChartConfig.series[0]["data"]    = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.yearlyRestrictionChartConfig.series[0]["data"]    = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let chartColumn = 0; chartColumn < numberOfColumnsRestrictionChart; chartColumn++) {
        if (chartColumn != 0 && this.yearlyRestrictionChartConfig.series.length <= chartColumn) {
          var seriesCopy = Object.assign({}, this.yearlyRestrictionChartConfig.series[0]);
          this.yearlyRestrictionChartConfig.series.push(seriesCopy);
        }
        this.yearlyRestrictionChartConfig.series[chartColumn]["data"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yearlyRestrictionChartConfig.series[chartColumn].name = "";
      }
      for (let chartColumn = 0; chartColumn < 4; chartColumn++) {
        if (chartColumn != 0 && this.yearlyreportCMRAChartConfig.series.length <= chartColumn) {
          var seriesCopy = Object.assign({}, this.yearlyreportCMRAChartConfig.series[0]);
          this.yearlyreportCMRAChartConfig.series.push(seriesCopy);
        }
        this.yearlyreportCMRAChartConfig.series[chartColumn]["data"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yearlyreportCMRAChartConfig.series[chartColumn].name = "";
      }
      for (let chartColumn = 0; chartColumn < 4; chartColumn++) {
        if (chartColumn != 0 && this.yearlyreportSpecialChartConfig.series.length <= chartColumn) {
          var seriesCopy = Object.assign({}, this.yearlyreportSpecialChartConfig.series[0]);
          this.yearlyreportSpecialChartConfig.series.push(seriesCopy);
        }
        this.yearlyreportSpecialChartConfig.series[chartColumn]["data"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yearlyreportSpecialChartConfig.series[chartColumn].name = "";
      }
      for (let chartColumn = 0; chartColumn < 9; chartColumn++) {
        if (chartColumn != 0 && this.yearlyreportPictogramChartConfig.series.length <= chartColumn) {
          var seriesCopy = Object.assign({}, this.yearlyreportPictogramChartConfig.series[0]);
          this.yearlyreportPictogramChartConfig.series.push(seriesCopy);
        }
        this.yearlyreportPictogramChartConfig.series[chartColumn]["data"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yearlyreportPictogramChartConfig.series[chartColumn].name = "";
      }
    } else {
      this.yearlyreportChartConfig.series[0]["data"]  = [0, 0, 0, 0, 0, 0];
      this.yearlyRiskChartConfig.series[0]["data"]    = [0, 0, 0, 0, 0, 0];
      this.yearlyRestrictionChartConfig.series[0]["data"]    = [0, 0, 0, 0, 0, 0];
      for (let chartColumn = 0; chartColumn < numberOfColumnsRestrictionChart; chartColumn++) {
        if (chartColumn != 0 && this.yearlyRestrictionChartConfig.series.length <= chartColumn) {
          var seriesCopy = Object.assign({}, this.yearlyRestrictionChartConfig.series[0]);
          this.yearlyRestrictionChartConfig.series.push(seriesCopy);
        }
        this.yearlyRestrictionChartConfig.series[chartColumn]["data"] = [0, 0, 0, 0, 0, 0];
        this.yearlyRestrictionChartConfig.series[chartColumn].name = "";
      }
      for (let chartColumn = 0; chartColumn < 4; chartColumn++) {
        if (chartColumn != 0 && this.yearlyreportCMRAChartConfig.series.length <= chartColumn) {
          var seriesCopy = Object.assign({}, this.yearlyreportCMRAChartConfig.series[0]);
          this.yearlyreportCMRAChartConfig.series.push(seriesCopy);
        }
        this.yearlyreportCMRAChartConfig.series[chartColumn]["data"] = [0, 0, 0, 0, 0, 0];
        this.yearlyreportCMRAChartConfig.series[chartColumn].name = "";
      }
      for (let chartColumn = 0; chartColumn < 4; chartColumn++) {
        if (chartColumn != 0 && this.yearlyreportSpecialChartConfig.series.length <= chartColumn) {
          var seriesCopy = Object.assign({}, this.yearlyreportSpecialChartConfig.series[0]);
          this.yearlyreportSpecialChartConfig.series.push(seriesCopy);
        }
        this.yearlyreportSpecialChartConfig.series[chartColumn]["data"] = [0, 0, 0, 0, 0, 0];
        this.yearlyreportSpecialChartConfig.series[chartColumn].name = "";
      }
      for (let chartColumn = 0; chartColumn < 9; chartColumn++) {
        if (chartColumn != 0 && this.yearlyreportPictogramChartConfig.series.length <= chartColumn) {
          var seriesCopy = Object.assign({}, this.yearlyreportPictogramChartConfig.series[0]);
          this.yearlyreportPictogramChartConfig.series.push(seriesCopy);
        }
        this.yearlyreportPictogramChartConfig.series[chartColumn]["data"] = [0, 0, 0, 0, 0, 0];
        this.yearlyreportPictogramChartConfig.series[chartColumn].name = "";
      }
    }

    // This is same for both monthly and yearly
    this.yearlyRestrictionChartConfig.series[0].name = this.translate.instant("RESTRICTION_LIST_AMV_A");
    this.yearlyRestrictionChartConfig.series[1].name = this.translate.instant("RESTRICTION_LIST_AMV_B");
    this.yearlyRestrictionChartConfig.series[2].name = this.translate.instant("RESTRICTION_LIST_CHEM_LIMIT");
    this.yearlyRestrictionChartConfig.series[3].name = this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_P");
    this.yearlyRestrictionChartConfig.series[4].name = this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_U");
    this.yearlyRestrictionChartConfig.series[5].name = this.translate.instant("RESTRICTION_LIST_REACH_14");
    this.yearlyRestrictionChartConfig.series[6].name = this.translate.instant("RESTRICTION_LIST_REACH_17");
    this.yearlyRestrictionChartConfig.series[7].name = this.translate.instant("RESTRICTION_LIST_REACH_CANDIDATE");
    this.yearlyRestrictionChartConfig.series[8].name = this.translate.instant("RESTRICTION_LIST_SIN");
    this.yearlyRestrictionChartConfig.series[9].name = this.translate.instant("RESTRICTION_LIST_SLL");
    this.yearlyRestrictionChartConfig.series[10].name = this.translate.instant("RESTRICTION_LIST_WATER_DIRECTIVE");
    this.yearlyRestrictionChartConfig.series[11].name = this.translate.instant("RESTRICTION_LIST_BASTA");
    this.yearlyRestrictionChartConfig.series[12].name = this.translate.instant("NO_RESTRICION_LIST");
    this.yearlyreportCMRAChartConfig.series[0]["name"]    = "";
    this.yearlyreportCMRAChartConfig.series[1]["name"]    = "";
    this.yearlyreportCMRAChartConfig.series[2]["name"]    = "";
    this.yearlyreportCMRAChartConfig.series[3]["name"]    = "";

    this.pictogramLinks = [
      {
        'src':'clp_acid-tiny',
        'name':this.translate.instant("CORROSIVE"),
        'index':0
      },
      {
        'src':'clp_bottle-tiny',
        'name':this.translate.instant("GASES_UNDER_PRESSURE"),
        'index':1
      },
      {
        'src':'clp_exclamation-tiny',
        'name':this.translate.instant("CAUTION"),
        'index':2
      },
      {
        'src':'clp_explosion-tiny',
        'name':this.translate.instant("EXPLOSIVE"),
        'index':3
      },
      {
        'src':'clp_flame-tiny',
        'name':this.translate.instant("FLAMMABLE"),
        'index':4
      },
      {
        'src':'clp_pollution-tiny',
        'name':this.translate.instant("ENVIRONMENTAL_HAZARD"),
        'index':5
      },
      {
        'src':'clp_roundflame-tiny' ,
        'name':this.translate.instant("OXIDIZING"),
        'index':6
      },
      {
        'src':'clp_silhouete-tiny',
        'name':this.translate.instant("HAZARDS"),
        'index':7
      },
      {
        'src':'clp_skull-tiny',
        'name':this.translate.instant("TOXIC"),
        'index':8
      }];
  }

  getProducts(timeperiod = "") {
    if ( timeperiod == "Monthly" ) {
      this.timeperiod = "Monthly";
      this.changedProductsFrom  = 'Monthly';
      this.changedProductsTo    = 'Monthly';
    } else {
      this.timeperiod = "Yearly";
      this.changedProductsFrom  = 'Yearly';
      this.changedProductsTo    = 'Yearly';
    }
    this.resetChartData();
    this.loadRestrictionList();
    this.products = [];
    this.getProductsIsClicked = true;
    this.loadingChangedProducts = true;
    this.allDep = true;
    if (this.selectedDepartment) {
      this.loadRestrictionList().subscribe(() => {
      // This is because the API is set up to work with two different dates but this is special cases
        this.productService.historyStatisticsReport(this.selectedOrganisation.id, this.allDep, this.changedProductsFrom, this.changedProductsTo);
        this.productService.getHistoryStatisticsListener().pipe(take(1)).subscribe({
          next: products => {
            if (products != null) {
              this.products = products;
              // Remove all products that are overrides
              this.products = this.products.map( productArray =>
                productArray.filter(product => product.override == null)
              );
              this.products = this.products.filter(productArray => productArray.length > 0);
              this.sareqIds = [];
              for(var productIndex = 0; productIndex < this.products.length; productIndex++) {
                this.products[productIndex] = this.productConversionsService.convertPictogramsOnProductArray(this.products[productIndex]);
                let tempSareqIds = [];
                for(var i = 0; i < this.products[productIndex].length; i++) {
                  tempSareqIds.push(this.products[productIndex][i].sareq_id);
                }
                this.sareqIds[productIndex]=[];
                this.sareqIds[productIndex]=tempSareqIds;
              }
              this.createYearlyReportChart();
              this.createYearlyRiskChart();
              this.createYearlyRestrictionChart();
              this.getHHPhrases();
              this.getHHPhrases().subscribe( () => {
                this.getCMRA_Category();
              });
              this.createSpecialCharts();
              this.createPictogramCharts();
              this.loadingChangedProducts = false;
            }
          },
          error: error => {}
        });
      });
    }
  }

  // Create the yearly report chart Amount of products this year or month
  createYearlyReportChart() {
    if (this.timeperiod == "Monthly") {
      this.yearlyreportChartConfig['title'].text = this.translate.instant("ANNUAL_AMOUNT_MONTHLY");
      this.yearlyreportChartConfig['yAxis']['title'].text =this.translate.instant("QUANTITY");
      this.yearlyreportChartConfig['xAxis']['title'].text =this.translate.instant("MONTH");
      const currentDate = new Date();
      const months = Array.from({length: this.products.length}, (_, i) => {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
      });
      this.yearlyreportChartConfig['xAxis']['categories'] = months.reverse();
    } else {
      this.yearlyreportChartConfig['title'].text = this.translate.instant("ANNUAL_AMOUNT_YEARLY");
      this.yearlyreportChartConfig['yAxis']['title'].text =this.translate.instant("QUANTITY");
      this.yearlyreportChartConfig['xAxis']['title'].text =this.translate.instant("YEAR");
      const currentYear = new Date().getFullYear();
      const years = Array.from({length: this.products.length}, (_, i) => currentYear - i);
      this.yearlyreportChartConfig['xAxis']['categories'] = years.reverse();
    }

    for (var productIndex = 0; productIndex < this.products.length; productIndex++) {
      if (this.products[productIndex] && this.products[productIndex].length > 0) {
        this.yearlyreportChartConfig.series[0]["data"][productIndex] = this.products[productIndex].length;
      }
    }
    this.yearlyreportChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>';
    this.yearlyreportChartConfig.series[0]["data"] = this.yearlyreportChartConfig.series[0]["data"].filter(productArray => productArray != 0);
    this.updateYearlyReportChart = true;
  }

  // Create the yearly report chart Risk of products this year or month
  createYearlyRiskChart() {
    var total = null;
    if (this.timeperiod == "Monthly") {
      this.yearlyRiskChartConfig['title'].text = this.translate.instant("RISK_PRIO_MONTHLY")+' '+this.dynamicTitleHeader;
      this.yearlyRiskChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyRiskChartConfig['xAxis']['title'].text =this.translate.instant("MONTH");
      const currentDate = new Date();
      const months = Array.from({length: this.products.length}, (_, i) => {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
      });
      this.yearlyRiskChartConfig['xAxis']['categories'] = months.reverse();
      total = new Array(this.products.length).fill(0);
    } else {
      this.yearlyRiskChartConfig['title'].text = this.translate.instant("RISK_PRIO_YEARLY")+' '+this.dynamicTitleHeader;
      this.yearlyRiskChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyRiskChartConfig['xAxis']['title'].text =this.translate.instant("YEAR");
      const currentYear = new Date().getFullYear();
      const years = Array.from({length: this.products.length}, (_, i) => currentYear - i);
      this.yearlyRiskChartConfig['xAxis']['categories'] = years.reverse();
      total = new Array(this.products.length).fill(0);
    }

    var riskPrio = [[], [], [], [], []];

    // This is to show the risk prio array,
    // riskprio = [ [ Y1, Y2, Y3, Y4, Y5 ],     Risk prio 1, Y1 = Year 1, Y2 = Year 2 etc
    //              [ Y1, Y2, Y3, Y4, Y5 ],     Risk prio 2
    //              [ Y1, Y2, Y3, Y4, Y5 ],     Risk prio 3 i.e. productIndex 0
    //              [ Y1, Y2, Y3, Y4, Y5 ],     Risk prio 4
    //              [ Y1, Y2, Y3, Y4, Y5 ] ];   Risk prio 5 ( or month )

    for (var riskPrioIndex = 0; riskPrioIndex < riskPrio.length; riskPrioIndex++) {
      for (var productIndex = 0; productIndex < this.products.length; productIndex++) {
        riskPrio[riskPrioIndex][productIndex] = 0;
      }
    }

    for (var productIndex = 0; productIndex < this.products.length; productIndex++) {
      for (var i = 0; i < this.products[productIndex].length; i++) {
        if (this.products[productIndex][i].risk_prio >= 1 && this.products[productIndex][i].risk_prio <= 5) {
          var notNegativeController = (this.products[productIndex][i].risk_prio - 1);
          riskPrio[notNegativeController][productIndex] += 1;
        }
        total[productIndex] += 1;
      }
    }

    for ( var riskPrioIndex = 0; riskPrioIndex < riskPrio.length; riskPrioIndex++ ) {
      if ( this.yearlyRiskChartConfig.series.length < riskPrio.length) {
        var seriesCopy = Object.assign({}, this.yearlyRiskChartConfig.series[0]);
        this.yearlyRiskChartConfig.series.push(seriesCopy);
      }

      this.yearlyRiskChartConfig.series[riskPrioIndex]["name"] = this.translate.instant("LEVEL")+' '+(riskPrioIndex+1).toString();
    }

    if ( this.showPercent == true ){
      this.yearlyRiskChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>%';
      for (var productIndex = 0; productIndex < this.products.length; productIndex++) {
        for ( var riskPrioIndex = 0; riskPrioIndex < riskPrio.length; riskPrioIndex++ ) {
          if ( total[productIndex] > 0 ) {
            if (total[productIndex] == 0) {
              riskPrio[riskPrioIndex][productIndex] = 0;
            } else {
              riskPrio[riskPrioIndex][productIndex] = Math.floor( ( riskPrio[riskPrioIndex][productIndex] / total[productIndex]) * 100 );
            }
          }
        }
      }
    } else {
      this.yearlyRiskChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>';
    }
    for ( var riskPrioIndex = 0; riskPrioIndex < riskPrio.length; riskPrioIndex++ ) {
      this.yearlyRiskChartConfig.series[riskPrioIndex]["data"] = riskPrio[riskPrioIndex];
    }
    this.yearlyRiskChartConfig.series[0]["color"] = "#7FFFD4";
    this.yearlyRiskChartConfig.series[1]["color"] = "rgb(25,251,139)";
    this.yearlyRiskChartConfig.series[2]["color"] = "#FFEA00";
    this.yearlyRiskChartConfig.series[3]["color"] = "#FFA500";
    this.yearlyRiskChartConfig.series[4]["color"] = "#FF0000";


    this.updateYearlyRiskChart = true;
  }

  loadRestrictionList() {
    if ( this.timeperiod == "Monthly" ) {
      this.timeperiod = "Monthly";
      this.changedProductsFrom  = 'Monthly';
      this.changedProductsTo    = 'Monthly';
    } else {
      this.timeperiod = "Yearly";
      this.changedProductsFrom  = 'Yearly';
      this.changedProductsTo    = 'Yearly';
    }
    return new Observable(observer => {
      if (this.organisation) {
        this.productService.getRestriktionListFromTwoDates(this.selectedOrganisation.id, this.allDep, this.changedProductsFrom, this.changedProductsTo);
        this.productService.getProductRestTwoDatesDataListener().pipe(take(1)).subscribe({
          next: info => {
            if (info == null) {}
            else {
              this.restriktionList = info;
            }
            observer.next();
            observer.complete();
          },
          error: error => {
            console.log("Error: ", error);
            observer.error(error);
          }
        })
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  createYearlyRestrictionChart() {
    var total = null;
    var numberOfCharts = null;
    if (this.restriktionList.length > this.products.length) {
      this.restriktionList = this.restriktionList.slice(this.restriktionList.length - this.products.length);
    }
    if (this.timeperiod == "Monthly") {
      this.yearlyRestrictionChartConfig['title'].text = this.translate.instant("RESTRICTION_MONTHLY")+' '+this.dynamicTitleHeader;
      this.yearlyRestrictionChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyRestrictionChartConfig['xAxis']['title'].text =this.translate.instant("MONTH");
      const currentDate = new Date();
      const months = Array.from({length: this.products.length}, (_, i) => {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
      });
      this.yearlyRestrictionChartConfig['xAxis']['categories'] = months.reverse();
      numberOfCharts = this.products.length;
      total = new Array(numberOfCharts).fill(0);
    } else {
      this.yearlyRestrictionChartConfig['title'].text = this.translate.instant("RESTRICTION_YEARLY")+' '+this.dynamicTitleHeader;
      this.yearlyRestrictionChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyRestrictionChartConfig['xAxis']['title'].text =this.translate.instant("YEAR");
      const currentYear = new Date().getFullYear();
      const years = Array.from({length: this.products.length}, (_, i) => currentYear - i);
      this.yearlyRestrictionChartConfig['xAxis']['categories'] = years.reverse();
      numberOfCharts = this.products.length;
      total = new Array(numberOfCharts).fill(0);
    }

    this.restrictionListsCount = [];
    for (var restrictionProductIndex = 0; restrictionProductIndex < numberOfCharts; restrictionProductIndex++) {
        this.restrictionListsCount[restrictionProductIndex] = ({
          'item': "",
          'list_amv_a_count': 0,
          'list_amv_b_count': 0,
          'list_chem_limit_count': 0,
          'list_chem_prio_p_count': 0,
          'list_chem_prio_u_count': 0,
          'list_reach_14_count': 0,
          'list_reach_17_count': 0,
          'list_reach_candidate_count': 0,
          'list_sin_count': 0,
          'list_sll_count': 0,
          'list_water_directive_count': 0,
          'list_BASTA_count': 0,
          'no_list_count': 0
        });

        var duplicateaArray=[];
        var temprestriktionList=[];
        if (this.restriktionList[restrictionProductIndex]) {
            for (var key = 0; key < this.restriktionList[restrictionProductIndex].length; key++) {
              var count = 1;
              if (duplicateaArray.indexOf(this.restriktionList[restrictionProductIndex][key].Sareq_id) < 1) {
              count=1;
              duplicateaArray.push(this.restriktionList[restrictionProductIndex][key].Sareq_id);
              var item = ({
                  'item': this.restriktionList[restrictionProductIndex][key].Sareq_id,
                  'list_amv_a_count': 0,
                  'list_amv_b_count': 0,
                  'list_chem_limit_count': 0,
                  'list_chem_prio_p_count': 0,
                  'list_chem_prio_u_count': 0,
                  'list_reach_14_count': 0,
                  'list_reach_17_count': 0,
                  'list_reach_candidate_count': 0,
                  'list_sin_count': 0,
                  'list_sll_count': 0,
                  'list_water_directive_count': 0,
                  'list_BASTA_count': 0,
                  'count':count
              });
              for (var key2 = 0; key2 < this.restriktionList[restrictionProductIndex].length; key2++) {
                if (item.item == this.restriktionList[restrictionProductIndex][key2].Sareq_id) {
                  var count=1;
                  if (this.restriktionList[restrictionProductIndex][key2].list_amv_a == 1) {
                    item.list_amv_a_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_amv_b == 1) {
                    item.list_amv_b_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_chem_limit == 1) {
                    item.list_chem_limit_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_chem_prio_p == 1) {
                    item.list_chem_prio_p_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_chem_prio_u == 1) {
                    item.list_chem_prio_u_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_reach_14 == 1) {
                    item.list_reach_14_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_reach_17 == 1) {
                    item.list_reach_17_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_reach_candidate == 1) {
                    item.list_reach_candidate_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_sin == 1 && this.subscribesToSIN == true) {
                    item.list_sin_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_sll == 1 && this.subscribesToSLL == true) {
                    item.list_sll_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_water_directive == 1) {
                    item.list_water_directive_count += count;
                  }
                  if (this.restriktionList[restrictionProductIndex][key2].list_BASTA == 1 && this.subscribesToBASTA == true) {
                    item.list_BASTA_count += count;
                  }
                }
              }
              temprestriktionList.push(item);
            } else {
              continue;
            }
          }
        }
        if (this.selectedDepartment.id != this.selectedOrganisation.id) {
          var deps = [];
          var depProductsId = [];
          var depProductsIdandCount = [];
          for (var depsid = 0; depsid < this.selectedDepartment.orgs.length; depsid++) {
            deps.push(this.selectedDepartment.orgs[depsid]);
            if (this.selectedDepartment.orgs[depsid].orgs) {
              for (var depsid2 = 0; depsid2 < this.selectedDepartment.orgs[depsid].orgs.length; depsid2++) {
                deps.push(this.selectedDepartment.orgs[depsid].orgs[depsid2]);
              }
            }
          }
          deps.push(this.selectedDepartment);
          for (var index = 0;index < this.products[restrictionProductIndex].length; index++) {
            if (deps.indexOf(this.products[restrictionProductIndex][index].organisation_id > -1)&&this.products[restrictionProductIndex][index].organisation_id_list) {
              var depIndexList = this.products[restrictionProductIndex][index].organisation_id_list.split(',');
              var depCount = 0;
              for (var depIndex = 0; depIndex < depIndexList.length; depIndex++){
                for (var depDep = 0; depDep < deps.length; depDep++){
                  if (depIndexList[depIndex] == deps[depDep].id){
                    depCount++;
                  }
                }
              }

            depProductsId.push(this.products[restrictionProductIndex][index].sareq_id);
            depProductsIdandCount.push({'id': this.products[restrictionProductIndex][index].sareq_id, 'count':depCount});
          }
        }

        var numOnList=0;
        var numNotOnList=0;
        temprestriktionList=_.uniqBy(temprestriktionList, 'item');
        // Remove items not in products
        temprestriktionList.forEach((element,index)=>{
        var found=false;
        this.products[restrictionProductIndex].forEach(element2=>{
          if(element2.sareq_id==element.item){
            found=true;
            return;
          }
        })
        if(found==false){
          temprestriktionList.splice(index,1);
        }
      })
      for (var key = 0; key < temprestriktionList.length; key++){
        var isOnList = false;
        var count = 0;
        if ( depProductsId.indexOf( temprestriktionList[key].item) >- 1 ) {
          for ( var depProductsIdandCountindex = 0; depProductsIdandCountindex < depProductsIdandCount.length; depProductsIdandCountindex++) {
            if (depProductsIdandCount[depProductsIdandCountindex].id == temprestriktionList[key].item){
              count = depProductsIdandCount[depProductsIdandCountindex].count;
            }
          }

          if (temprestriktionList[key].list_amv_a_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_amv_a_count += count;
            isOnList = true;
          }

          if (temprestriktionList[key].list_amv_b_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_amv_b_count += count;
            isOnList = true;
          }

          if (temprestriktionList[key].list_chem_limit_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_chem_prio_p_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_chem_prio_u_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_reach_14_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_reach_14_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_reach_17_count > 0){
            this.restrictionListsCount[restrictionProductIndex].list_reach_17_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_reach_candidate_count > 0){
            this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_sin_count > 0&& this.subscribesToSIN == true) {
            this.restrictionListsCount[restrictionProductIndex].list_sin_count += count;
            if (this.subscribesToSIN == true) {
            isOnList=true;
            }
          }

          if(temprestriktionList[key].list_sll_count > 0 && this.subscribesToSLL == true) {
            this.restrictionListsCount[restrictionProductIndex].list_sll_count += count;
            if (this.subscribesToSLL == true) {
            isOnList=true;
            }
          }

          if(temprestriktionList[key].list_water_directive_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_water_directive_count += count;
            isOnList=true;
          }

          if(temprestriktionList[key].list_BASTA_count > 0 && this.subscribesToBASTA == true) {
            this.restrictionListsCount[restrictionProductIndex].list_BASTA_count += count;
            isOnList=true;
          }
        }

        if(isOnList==true) {
          numOnList++;
        }
      }
      this.restrictionListsCount[restrictionProductIndex].no_list_count = this.products[restrictionProductIndex].length - numOnList;
    } else {
      temprestriktionList=_.uniqBy(temprestriktionList, 'item');
      // Remove items not in products
      temprestriktionList.forEach((element,index)=>{
        var found=false;
        this.products[restrictionProductIndex].forEach(element2=>{
          if(element2.sareq_id==element.item){
            found=true;
            return;
          }
        })
        if(found==false){
          temprestriktionList.splice(index,1);
        }
      })
      for (var key = 0; key < temprestriktionList.length; key++) {
        if (temprestriktionList[key].list_amv_a_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_amv_a_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_amv_b_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_amv_b_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_chem_limit_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_chem_prio_p_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count+=temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_chem_prio_u_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count += temprestriktionList[key].count;
        }

        if(temprestriktionList[key].list_reach_14_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_reach_14_count += temprestriktionList[key].count;
        }
        if(temprestriktionList[key].list_reach_17_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_reach_17_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_reach_candidate_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_sin_count > 0 && this.subscribesToSIN == true) {
          this.restrictionListsCount[restrictionProductIndex].list_sin_count += temprestriktionList[key].count;
        }

        if(temprestriktionList[key].list_sll_count > 0 && this.subscribesToSLL == true) {
          this.restrictionListsCount[restrictionProductIndex].list_sll_count += temprestriktionList[key].count;
        }

        if(temprestriktionList[key].list_water_directive_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_water_directive_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_BASTA_count > 0 && this.subscribesToBASTA == true) {
          this.restrictionListsCount[restrictionProductIndex].list_BASTA_count += temprestriktionList[key].count;
        }
      }
      this.restrictionListsCount[restrictionProductIndex].no_list_count = this.products[restrictionProductIndex].length - temprestriktionList.length;
    }
  }
    var list_amv_a_count = [];
    var list_amv_b_count = [];
    var list_chem_limit_count = [];
    var list_chem_prio_p_count = [];
    var list_chem_prio_u_count = [];
    var list_reach_14_count = [];
    var list_reach_17_count = [];
    var list_reach_candidate_count = [];
    var list_reach_candidate_count = [];
    var list_sin_count = [];
    var list_sll_count = [];
    var list_water_directive_count = [];
    var list_BASTA_count = [];
    var no_list_count = [];


    for (var restrictionProductIndex = 0; restrictionProductIndex < this.restrictionListsCount.length; restrictionProductIndex++) {
      if ( this.showPercent == true ){
        this.yearlyRestrictionChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>%';
        total[restrictionProductIndex] =  this.restrictionListsCount[restrictionProductIndex].list_amv_a_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_amv_b_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_reach_14_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_reach_17_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_sin_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_sll_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_water_directive_count+
                                          this.restrictionListsCount[restrictionProductIndex].list_BASTA_count+
                                          this.restrictionListsCount[restrictionProductIndex].no_list_count;

        if (total[restrictionProductIndex] == 0) {
          list_amv_a_count[restrictionProductIndex] = 0;
          list_amv_b_count[restrictionProductIndex] = 0;
          list_chem_limit_count[restrictionProductIndex] = 0;
          list_chem_prio_p_count[restrictionProductIndex] = 0;
          list_chem_prio_u_count[restrictionProductIndex] =  0;
          list_reach_14_count[restrictionProductIndex] = 0;
          list_reach_17_count[restrictionProductIndex] = 0;
          list_reach_candidate_count[restrictionProductIndex] = 0;
          list_sin_count[restrictionProductIndex] = 0;
          list_sll_count[restrictionProductIndex] = 0;
          list_water_directive_count[restrictionProductIndex] = 0;
          list_BASTA_count[restrictionProductIndex] = 0;
          no_list_count[restrictionProductIndex] = 0;
        } else {
          list_amv_a_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_amv_a_count / total[restrictionProductIndex] ) * 100 );
          list_amv_b_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_amv_b_count / total[restrictionProductIndex] ) * 100 );
          list_chem_limit_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count / total[restrictionProductIndex] ) * 100 );
          list_chem_prio_p_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count / total[restrictionProductIndex] ) * 100 );
          list_chem_prio_u_count[restrictionProductIndex] =  Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count / total[restrictionProductIndex] ) * 100 );
          list_reach_14_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_reach_14_count / total[restrictionProductIndex] ) * 100 );
          list_reach_17_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_reach_17_count / total[restrictionProductIndex] ) * 100 );
          list_reach_candidate_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count / total[restrictionProductIndex] ) * 100 );
          list_sin_count[restrictionProductIndex] =  Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_sin_count / total[restrictionProductIndex] ) * 100 );
          list_sll_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_sll_count / total[restrictionProductIndex] ) * 100 );
          list_water_directive_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_water_directive_count / total[restrictionProductIndex] ) * 100 );
          list_BASTA_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].list_BASTA_count / total[restrictionProductIndex] ) * 100 );
          no_list_count[restrictionProductIndex] = Math.floor( ( this.restrictionListsCount[restrictionProductIndex].no_list_count / total[restrictionProductIndex] ) * 100 );
        }
      } else {
        this.yearlyRestrictionChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>';
        list_amv_a_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_amv_a_count;
        list_amv_b_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_amv_b_count;
        list_chem_limit_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count;
        list_chem_prio_p_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count;
        list_chem_prio_u_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count;
        list_reach_14_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_reach_14_count;
        list_reach_17_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_reach_17_count;
        list_reach_candidate_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count;
        list_sin_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_sin_count;
        list_sll_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_sll_count;
        list_water_directive_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_water_directive_count;
        list_BASTA_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].list_BASTA_count;
        no_list_count[restrictionProductIndex] = this.restrictionListsCount[restrictionProductIndex].no_list_count;
      }
    }

    this.yearlyRestrictionChartConfig.series[0]["data"] = list_amv_a_count;
    this.yearlyRestrictionChartConfig.series[1]["data"] = list_amv_b_count;
    this.yearlyRestrictionChartConfig.series[2]["data"] = list_chem_limit_count;
    this.yearlyRestrictionChartConfig.series[3]["data"] = list_chem_prio_p_count;
    this.yearlyRestrictionChartConfig.series[4]["data"] = list_chem_prio_u_count;
    this.yearlyRestrictionChartConfig.series[5]["data"] = list_reach_14_count;
    this.yearlyRestrictionChartConfig.series[6]["data"] = list_reach_17_count;
    this.yearlyRestrictionChartConfig.series[7]["data"] = list_reach_candidate_count;
    this.yearlyRestrictionChartConfig.series[8]["data"] = list_sin_count;
    if (!this.subscribesToSIN) {
      this.yearlyRestrictionChartConfig.series[8]["visible"] = false;
      this.yearlyRestrictionChartConfig.series[8]["showInLegend"] = false;
    }
    this.yearlyRestrictionChartConfig.series[9]["data"] = list_sll_count;
    if (!this.subscribesToSLL) {
      this.yearlyRestrictionChartConfig.series[9]["visible"] = false;
      this.yearlyRestrictionChartConfig.series[9]["showInLegend"] = false;
    }
    this.yearlyRestrictionChartConfig.series[10]["data"] = list_water_directive_count;
    this.yearlyRestrictionChartConfig.series[11]["data"] = list_BASTA_count;
    if (!this.subscribesToBASTA) {
      this.yearlyRestrictionChartConfig.series[11]["visible"] = false;
      this.yearlyRestrictionChartConfig.series[11]["showInLegend"] = false;
    }
    this.yearlyRestrictionChartConfig.series[12]["data"] = no_list_count;
    this.updateYearlyRestrictionChart = true;
  }

  getHHPhrases() {
    return new Observable(observer => {
      this.productService.getHPhrasesSeveralArrays(this.selectedOrganisation.id, this.sareqIds);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: hPhrases => {
        if (hPhrases != null) {
          for(var productItemIndex = 0; productItemIndex < this.products.length; productItemIndex++){
            for(var i = 0; i < hPhrases[productItemIndex].length; i++){
              for(var j = 0; j < this.products[productItemIndex].length; j++) {
                if(hPhrases[productItemIndex][i].sareq_id == this.products[productItemIndex][j].sareq_id) {
                  if(this.products[productItemIndex][j].h_phrases == undefined) {
                    this.products[productItemIndex][j]['h_phrases'] = [];
                  }
                  this.products[productItemIndex][j]['h_phrases'].push(hPhrases[productItemIndex][i].hphrases);
                }
              }
            }
          }
        }
          observer.next();
          observer.complete();
        },
        error: error => {
        console.log("Error: ", error);
      }
    })
  });
  }

  getCMRA_Category(){
    var numberOfCharts = null;
    if ( this.timeperiod == "Monthly" ) {
      numberOfCharts = this.products.length;
    }
    else {
      numberOfCharts = this.products.length;
    }
    this.CMRA_amount = [];
      for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
        this.CMRA_amount[productIndex] = [
          {name: 'C', count: 0},
          {name: 'M', count: 0},
          {name: 'R', count: 0},
          {name: 'A', count: 0}
        ];
      }
    for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
      this.products[productIndex].forEach(element => {
        element.CMRA_Category=[];
        if((!element.h_phrases||element.h_phrases==null)&&element.allergic!=1){
          return;
        }
        if(_.toUpper(element.h_phrases).indexOf("H350")>-1||_.toUpper(element.h_phrases).indexOf("H351")>-1){
          element.CMRA_Category.push('C');
          this.CMRA_amount[productIndex][0].count++;
        }
        if(_.toUpper(element.h_phrases).indexOf("340")>-1||_.toUpper(element.h_phrases).indexOf("H341")>-1){
          element.CMRA_Category.push('M');
          this.CMRA_amount[productIndex][1].count++;
        }
        if(_.toUpper(element.h_phrases).indexOf("360")>-1||_.toUpper(element.h_phrases).indexOf("H361")>-1||_.toUpper(element.h_phrases).indexOf("H362")>-1){
          element.CMRA_Category.push('R');
          this.CMRA_amount[productIndex][2].count++;
        }
        if(_.toUpper(element.h_phrases).indexOf("H317")>-1||_.toUpper(element.h_phrases).indexOf("H334")>-1
            ||_.toUpper(element.h_phrases).indexOf("EUH208")>-1||_.toUpper(element.h_phrases).indexOf("EUH205")>-1
            ||_.toUpper(element.h_phrases).indexOf("EUH204")>-1||_.toUpper(element.h_phrases).indexOf("EUH202")>-1
            ||element.allergic==1)
        {
          element.CMRA_Category.push('A');
          this.CMRA_amount[productIndex][3].count++;
        }
      })
    }
    this.createCMRACharts(); // To make sure the charts are created after the data is set
  }

  createCMRACharts(){
    var numberOfCharts = null;
    var total = null;

    // This is temporary arrays to hold the data for the chart
    var Cancerogen = [];
    var Mutagen = [];
    var Reprotoxic = [];
    var Allergic = [];

    if (this.timeperiod == "Monthly") {
      this.yearlyreportCMRAChartConfig['title'].text = this.translate.instant("CMRA_MONTHLY")+' '+this.dynamicTitleHeader;
      this.yearlyreportCMRAChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyreportCMRAChartConfig['xAxis']['title'].text =this.translate.instant("MONTH");
      const currentDate = new Date();
      const months = Array.from({length: this.products.length}, (_, i) => {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
      });
      this.yearlyreportCMRAChartConfig['xAxis']['categories'] = months.reverse();
      numberOfCharts = this.products.length;
      total = [0,0,0,0,0,0,0,0,0,0,0,0];
    } else {
      this.yearlyreportCMRAChartConfig['title'].text = this.translate.instant("CMRA_YEARLY")+' '+this.dynamicTitleHeader;
      this.yearlyreportCMRAChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyreportCMRAChartConfig['xAxis']['title'].text =this.translate.instant("YEAR");
      const currentYear = new Date().getFullYear();
      const years = Array.from({length: this.products.length}, (_, i) => currentYear - i);
      this.yearlyreportCMRAChartConfig['xAxis']['categories'] = years.reverse();
      numberOfCharts = this.products.length;
      total = [0,0,0,0,0,0];
    }


    for ( var productIndex = 0; productIndex < numberOfCharts; productIndex++ ) {
      if ( this.showPercent == true ){
        this.yearlyreportCMRAChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>%';
        total[productIndex] = this.CMRA_amount[productIndex][0].count +
                              this.CMRA_amount[productIndex][1].count +
                              this.CMRA_amount[productIndex][2].count +
                              this.CMRA_amount[productIndex][3].count;

        if (total[productIndex] == 0) {
          Cancerogen[productIndex] = 0;
          Mutagen[productIndex] = 0;
          Reprotoxic[productIndex] = 0;
          Allergic[productIndex] = 0;
        } else {
          Cancerogen[productIndex] = Math.floor ( ( this.CMRA_amount[productIndex][0].count / total[productIndex]) * 100 );
          Mutagen[productIndex] =  Math.floor ( ( this.CMRA_amount[productIndex][1].count / total[productIndex]) * 100 );
          Reprotoxic[productIndex] = Math.floor ( ( this.CMRA_amount[productIndex][2].count / total[productIndex]) * 100 );
          Allergic[productIndex] = Math.floor ( ( this.CMRA_amount[productIndex][3].count / total[productIndex]) * 100 );
        }
        this.yearlyreportCMRAChartConfig.yAxis['max'] = 100;
      } else {
        this.yearlyreportCMRAChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>';
        Cancerogen[productIndex] = this.CMRA_amount[productIndex][0].count;
        Mutagen[productIndex] = this.CMRA_amount[productIndex][1].count;
        Reprotoxic[productIndex] = this.CMRA_amount[productIndex][2].count;
        Allergic[productIndex] = this.CMRA_amount[productIndex][3].count;
        this.yearlyreportCMRAChartConfig.yAxis['max'] = null;
      }
    }

    if (this.yearlyreportCMRAChartConfig.series.length < 4) {
      if (!this.yearlyreportCMRAChartConfig.series[1]) {
        var seriesCopy = Object.assign({}, this.yearlyreportCMRAChartConfig.series[0]);
        this.yearlyreportCMRAChartConfig.series.push(seriesCopy);
      }
      if (!this.yearlyreportCMRAChartConfig.series[2]) {
        var seriesCopy = Object.assign({}, this.yearlyreportCMRAChartConfig.series[0]);
        this.yearlyreportCMRAChartConfig.series.push(seriesCopy);
      }
      if (!this.yearlyreportCMRAChartConfig.series[3]) {
        var seriesCopy = Object.assign({}, this.yearlyreportCMRAChartConfig.series[0]);
        this.yearlyreportCMRAChartConfig.series.push(seriesCopy);
      }
    }

    if (this.yearlyreportCMRAChartConfig.series.length > 4) {
      this.yearlyreportCMRAChartConfig.series.splice(4);
    }

    this.yearlyreportCMRAChartConfig.series[0]["name"] = this.translate.instant("C_LONG");
    this.yearlyreportCMRAChartConfig.series[1]["name"] = this.translate.instant("M_LONG");
    this.yearlyreportCMRAChartConfig.series[2]["name"] = this.translate.instant("R_LONG");
    this.yearlyreportCMRAChartConfig.series[3]["name"] = this.translate.instant("A_LONG");
    this.yearlyreportCMRAChartConfig.series[0]["data"] = [];
    this.yearlyreportCMRAChartConfig.series[1]["data"] = [];
    this.yearlyreportCMRAChartConfig.series[2]["data"] = [];
    this.yearlyreportCMRAChartConfig.series[3]["data"] = [];
    this.yearlyreportCMRAChartConfig.series[0]["data"] = Cancerogen;
    this.yearlyreportCMRAChartConfig.series[1]["data"] = Mutagen;
    this.yearlyreportCMRAChartConfig.series[2]["data"] = Reprotoxic;
    this.yearlyreportCMRAChartConfig.series[3]["data"] = Allergic;
    this.updateCMRAChart = true;
  }

  // Create the special chart
  createSpecialCharts() {
    var numberOfCharts = null;
    var total = null;
    if (this.timeperiod == "Monthly") {
      this.yearlyreportSpecialChartConfig['title'].text = this.translate.instant("SPECIAL_MONTHLY")+' '+this.dynamicTitleHeader;
      this.yearlyreportSpecialChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyreportSpecialChartConfig['xAxis']['title'].text =this.translate.instant("MONTH");
      const currentDate = new Date();
      const months = Array.from({length: this.products.length}, (_, i) => {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
      });
      this.yearlyreportSpecialChartConfig['xAxis']['categories'] = months.reverse();
      numberOfCharts = this.products.length;
      var information = new Array(numberOfCharts).fill(0);
      var training = new Array(numberOfCharts).fill(0);
      var health_control = new Array(numberOfCharts).fill(0);
      var fitness_for_duty = new Array(numberOfCharts).fill(0);
      var no_special_demands = new Array(numberOfCharts).fill(0);
      total = new Array(numberOfCharts).fill(0);
    } else {
      this.yearlyreportSpecialChartConfig['title'].text = this.translate.instant("SPECIAL_YEARLY")+' '+this.dynamicTitleHeader;
      this.yearlyreportSpecialChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyreportSpecialChartConfig['xAxis']['title'].text =this.translate.instant("YEAR");
      const currentYear = new Date().getFullYear();
      const years = Array.from({length: this.products.length}, (_, i) => currentYear - i);
      this.yearlyreportSpecialChartConfig['xAxis']['categories'] = years.reverse();
      numberOfCharts = this.products.length;
      var information = new Array(numberOfCharts).fill(0);
      var training = new Array(numberOfCharts).fill(0);
      var health_control = new Array(numberOfCharts).fill(0);
      var fitness_for_duty = new Array(numberOfCharts).fill(0);
      var no_special_demands = new Array(numberOfCharts).fill(0);
      total = new Array(numberOfCharts).fill(0);
    }

    for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
      for(var i = 0; i < this.products[productIndex].length; i++){
        if ((this.products[productIndex][i].information != null) && (this.products[productIndex][i].information != "") && (this.products[productIndex][i].information != " ")) {
          information[productIndex] += 1;
        }
        if ((this.products[productIndex][i].training != null) && (this.products[productIndex][i].training != "") && (this.products[productIndex][i].training != " ")) {
          training[productIndex] += 1;
        }
        if ((this.products[productIndex][i].health_control != null) && (this.products[productIndex][i].health_control != "") && (this.products[productIndex][i].health_control != " ")) {
          health_control[productIndex] += 1;
        }
        if ((this.products[productIndex][i].fitness_for_duty != null) && (this.products[productIndex][i].fitness_for_duty != "") && (this.products[productIndex][i].fitness_for_duty != " ")) {
          fitness_for_duty[productIndex] += 1;
        }
        if ((this.products[productIndex][i].no_special_demands != null) && (this.products[productIndex][i].no_special_demands != "") && (this.products[productIndex][i].no_special_demands != " ")) {
          no_special_demands[productIndex] += 1;
        }
      }
    }

      for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
        total[productIndex] = information[productIndex] +
                              training[productIndex] +
                              health_control[productIndex] +
                              fitness_for_duty[productIndex] +
                              no_special_demands[productIndex];
      }


      if (this.yearlyreportSpecialChartConfig.series.length < 5) {
        if (!this.yearlyreportSpecialChartConfig.series[1]) {
          var seriesCopy = Object.assign({}, this.yearlyreportSpecialChartConfig.series[0]);
          this.yearlyreportSpecialChartConfig.series.push(seriesCopy);
        }
        if (!this.yearlyreportSpecialChartConfig.series[2]) {
          var seriesCopy = Object.assign({}, this.yearlyreportSpecialChartConfig.series[0]);
          this.yearlyreportSpecialChartConfig.series.push(seriesCopy);
        }
        if (!this.yearlyreportSpecialChartConfig.series[3]) {
          var seriesCopy = Object.assign({}, this.yearlyreportSpecialChartConfig.series[0]);
          this.yearlyreportSpecialChartConfig.series.push(seriesCopy);
        }
        if (!this.yearlyreportSpecialChartConfig.series[4]) {
          var seriesCopy = Object.assign({}, this.yearlyreportSpecialChartConfig.series[0]);
          this.yearlyreportSpecialChartConfig.series.push(seriesCopy);
        }
      }

      if (this.yearlyreportSpecialChartConfig.series.length > 5) {
        this.yearlyreportSpecialChartConfig.series.splice(5);
      }

      this.yearlyreportSpecialChartConfig.series[0]["name"] = "<div class='text-center'>" +
          this.translate.instant("INFORMATION_DEMAND") + "<br>(AFS 2011:19 §37d)" + "</div>";


      this.yearlyreportSpecialChartConfig.series[1]["name"] = "<div class='text-center'>" +
          this.translate.instant("TRAINING") + "<br>(AFS 2011:19 §37e)" + "</div>";


      this.yearlyreportSpecialChartConfig.series[2]["name"] = "<div class='text-center'>" +
          this.translate.instant("HEALTH_CONTROL") + "<br>(AFS 2011:19 §37f)" + "</div>";


      this.yearlyreportSpecialChartConfig.series[3]["name"] = "<div class='text-center'>" +
          this.translate.instant("FITNESS_FOR_DUTY") + "<br>(AFS 2011:19 §37g)" + "</div>";

      this.yearlyreportSpecialChartConfig.series[4]["name"] = "<div class='text-center'>" +
          this.translate.instant("NO_SPECIAL_DEMANDS") + "</div>";

      if ( this.showPercent == true ){
        this.yearlyreportSpecialChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>%';
        for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
          if (total[productIndex] == 0) {
            information[productIndex]       = 0;
            training[productIndex]          = 0;
            health_control[productIndex]    = 0;
            fitness_for_duty[productIndex]  = 0;
            no_special_demands[productIndex]  = 0;
          } else {
            information[productIndex]       = Math.floor ( ( information[productIndex] / total[productIndex]) * 100 );
            training[productIndex]          = Math.floor ( ( training[productIndex] / total[productIndex]) * 100 );
            health_control[productIndex]    = Math.floor ( ( health_control[productIndex] / total[productIndex]) * 100 );
            fitness_for_duty[productIndex]  = Math.floor ( ( fitness_for_duty[productIndex] / total[productIndex]) * 100 );
            no_special_demands[productIndex]  = Math.floor ( ( no_special_demands[productIndex] / total[productIndex]) * 100 );
          }
        }

        this.yearlyreportSpecialChartConfig.series[0]["data"] = information;
        this.yearlyreportSpecialChartConfig.series[1]["data"] = training;
        this.yearlyreportSpecialChartConfig.series[2]["data"] = health_control;
        this.yearlyreportSpecialChartConfig.series[3]["data"] = fitness_for_duty;
        this.yearlyreportSpecialChartConfig.series[4]["data"] = no_special_demands;
      } else {
        this.yearlyreportSpecialChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>';
        this.yearlyreportSpecialChartConfig.series[0]["data"] = information;
        this.yearlyreportSpecialChartConfig.series[1]["data"] = training;
        this.yearlyreportSpecialChartConfig.series[2]["data"] = health_control;
        this.yearlyreportSpecialChartConfig.series[3]["data"] = fitness_for_duty;
        this.yearlyreportSpecialChartConfig.series[4]["data"] = no_special_demands;
      }
    // }

    this.updateSpecialChart = true;
  }

  // Create the Pictogram chart
  createPictogramCharts() {
    var numberOfCharts = null;
    var pictogramsCount;

    if (this.timeperiod == "Monthly") {
      this.yearlyreportPictogramChartConfig.title.text = this.translate.instant("PICTOGRAM_MONTHLY")+' '+this.dynamicTitleHeader;
      this.yearlyreportPictogramChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyreportPictogramChartConfig['xAxis']['title'].text =this.translate.instant("MONTH");
      const currentDate = new Date();
      const months = Array.from({length: this.products.length}, (_, i) => {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
      });
      this.yearlyreportPictogramChartConfig['xAxis']['categories'] = months.reverse();
      numberOfCharts = this.products.length;
      var clp_is_corrosive = new Array(numberOfCharts).fill(0);
      var clp_is_gas_under_pressure = new Array(numberOfCharts).fill(0);
      var clp_is_caution = new Array(numberOfCharts).fill(0);
      var clp_is_explosive = new Array(numberOfCharts).fill(0);
      var clp_is_flammable = new Array(numberOfCharts).fill(0);
      var clp_is_dangerous_to_the_environment = new Array(numberOfCharts).fill(0);
      var clp_is_oxidising = new Array(numberOfCharts).fill(0);
      var clp_is_longer_term_health_hazards = new Array(numberOfCharts).fill(0);
      var clp_is_toxic = new Array(numberOfCharts).fill(0);
      var total = new Array(numberOfCharts).fill(0);
    } else {
      this.yearlyreportPictogramChartConfig.title.text = this.translate.instant("PICTOGRAM_YEARLY")+' '+this.dynamicTitleHeader;
      this.yearlyreportPictogramChartConfig['yAxis']['title'].text =this.dynamicTitle;
      this.yearlyreportPictogramChartConfig['xAxis']['title'].text =this.translate.instant("YEAR");
      const currentYear = new Date().getFullYear();
      const years = Array.from({length: this.products.length}, (_, i) => currentYear - i);
      this.yearlyreportPictogramChartConfig['xAxis']['categories'] = years.reverse();
      numberOfCharts = this.products.length;
      var clp_is_corrosive = new Array(numberOfCharts).fill(0);
      var clp_is_gas_under_pressure = new Array(numberOfCharts).fill(0);
      var clp_is_caution = new Array(numberOfCharts).fill(0);
      var clp_is_explosive = new Array(numberOfCharts).fill(0);
      var clp_is_flammable = new Array(numberOfCharts).fill(0);
      var clp_is_dangerous_to_the_environment = new Array(numberOfCharts).fill(0);
      var clp_is_oxidising = new Array(numberOfCharts).fill(0);
      var clp_is_longer_term_health_hazards = new Array(numberOfCharts).fill(0);
      var clp_is_toxic = new Array(numberOfCharts).fill(0);
      var total = new Array(numberOfCharts).fill(0);
    }

    for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
      for(var i = 0; i < this.products[productIndex].length; i++){
        if ((this.products[productIndex][i].pictograms) && (this.products[productIndex][i].pictograms.length > 0)) {
          pictogramsCount = this.products[productIndex][i].pictograms.length;
          for (var j = 0; j < pictogramsCount; j += 1) {
            switch (this.products[productIndex][i].pictograms[j].name) {
              case "clp_is_corrosive":
                clp_is_corrosive[productIndex] += 1;
                break;
              case "clp_is_gas_under_pressure":
                clp_is_gas_under_pressure[productIndex] += 1;
                break;
              case "clp_is_caution":
                clp_is_caution[productIndex] += 1;
                break;
              case "clp_is_explosive":
                clp_is_explosive[productIndex] += 1;
                break;
              case "clp_is_flammable":
                clp_is_flammable[productIndex] += 1;
                break;
              case "clp_is_dangerous_to_the_environment":
                clp_is_dangerous_to_the_environment[productIndex] += 1;
                break;
              case "clp_is_oxidising":
                clp_is_oxidising[productIndex] += 1;
                break;
              case "clp_is_longer_term_health_hazards":
                clp_is_longer_term_health_hazards[productIndex] += 1;
                break;
              case "clp_is_toxic":
                clp_is_toxic[productIndex] += 1;
                break;
              default:
                break;
            }
          }
        }
      }
    }

      for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
        if (this.showPercent == true ){
          this.yearlyreportPictogramChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>%';
          total[productIndex] = clp_is_corrosive[productIndex] +
                              clp_is_gas_under_pressure[productIndex] +
                              clp_is_caution[productIndex] +
                              clp_is_explosive[productIndex] +
                              clp_is_flammable[productIndex] +
                              clp_is_dangerous_to_the_environment[productIndex] +
                              clp_is_oxidising[productIndex] +
                              clp_is_longer_term_health_hazards[productIndex] +
                              clp_is_toxic[productIndex];
          if (total[productIndex] == 0) {
            clp_is_corrosive[productIndex] = 0;
            clp_is_gas_under_pressure[productIndex] = 0;
            clp_is_caution[productIndex] = 0;
            clp_is_explosive[productIndex] = 0;
            clp_is_flammable[productIndex] = 0;
            clp_is_dangerous_to_the_environment[productIndex] = 0;
            clp_is_oxidising[productIndex] = 0;
            clp_is_longer_term_health_hazards[productIndex] = 0;
            clp_is_toxic[productIndex] = 0;
          } else {
            clp_is_corrosive[productIndex] = Math.floor ( ( clp_is_corrosive[productIndex] / total[productIndex]) * 100 );
            clp_is_gas_under_pressure[productIndex] = Math.floor ( ( clp_is_gas_under_pressure[productIndex] / total[productIndex]) * 100 );
            clp_is_caution[productIndex] = Math.floor ( ( clp_is_caution[productIndex] / total[productIndex]) * 100 );
            clp_is_explosive[productIndex] = Math.floor ( ( clp_is_explosive[productIndex] / total[productIndex]) * 100 );
            clp_is_flammable[productIndex] = Math.floor ( ( clp_is_flammable[productIndex] / total[productIndex]) * 100 );
            clp_is_dangerous_to_the_environment[productIndex] = Math.floor ( ( clp_is_dangerous_to_the_environment[productIndex] / total[productIndex]) * 100 );
            clp_is_oxidising[productIndex] = Math.floor ( ( clp_is_oxidising[productIndex] / total[productIndex]) * 100 );
            clp_is_longer_term_health_hazards[productIndex] = Math.floor ( ( clp_is_longer_term_health_hazards[productIndex] / total[productIndex]) * 100 );
            clp_is_toxic[productIndex] = Math.floor ( ( clp_is_toxic[productIndex] / total[productIndex]) * 100 );
          }
        } else {
          this.yearlyreportPictogramChartConfig['tooltip']['pointFormat'] = '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b>';
        }
      }
      this.yearlyreportPictogramChartConfig.series[0]["data"] = clp_is_corrosive;
      this.yearlyreportPictogramChartConfig.series[1]["data"] = clp_is_gas_under_pressure;
      this.yearlyreportPictogramChartConfig.series[2]["data"] = clp_is_caution;
      this.yearlyreportPictogramChartConfig.series[3]["data"] = clp_is_explosive;
      this.yearlyreportPictogramChartConfig.series[4]["data"] = clp_is_flammable;
      this.yearlyreportPictogramChartConfig.series[5]["data"] = clp_is_dangerous_to_the_environment;
      this.yearlyreportPictogramChartConfig.series[6]["data"] = clp_is_oxidising;
      this.yearlyreportPictogramChartConfig.series[7]["data"] = clp_is_longer_term_health_hazards;
      this.yearlyreportPictogramChartConfig.series[8]["data"] = clp_is_toxic;
      for ( var pictogramLinksIndex = 0; pictogramLinksIndex < this.pictogramLinks.length; pictogramLinksIndex++ ) {
        let anchortext = "";
        anchortext += '<span>'+ this.pictogramLinks[pictogramLinksIndex].name +'';
        if ( this.pictogramLinks[pictogramLinksIndex].src != "" ) {
          anchortext += '<br/><img class="" title="' + this.pictogramLinks[pictogramLinksIndex].name +
          '" id="pictogram' + this.pictogramLinks[pictogramLinksIndex].name + '"' +
          'style="padding-bottom: 30px; padding-top: 5px; padding-right:40px; max-width:100!important;cursor:pointer;" + ' +
          'src="/images/pictograms/' + this.pictogramLinks[pictogramLinksIndex].src + '.png" /></span>';
        } else {
          anchortext += '</span>';
        }
        this.yearlyreportPictogramChartConfig.series[this.pictogramLinks[pictogramLinksIndex].index]["name"] = anchortext
      }
      this.updatePictogramChart = true;

  }

  getWidth() {
    var width = window.screen.width;
    if (width > 2000) {
      width = 1600;
    } else if (width > 1500) {
      width = 1200;
    } else if (width > 1000) {
      width = 800;
    } else if (width > 700) {
    width = 600;
    } else {
      width = width - 100;
    }
    return width;
  }

  buttonClickedYearlyReport() {
    if ( this.timeperiod == "Monthly" ) {
      this.getProducts( "Yearly" );
    } else {
      this.getProducts( "Monthly" );
    }
  }

  buttonPercentOrAmount(booleandata){
    if (booleandata == this.showPercent) {return;}
    this.showPercent = booleandata;
    if ( this.showPercent == false ) {
      this.dynamicTitle=this.translate.instant("QUANTITY");
      this.dynamicTitleHeader = this.translate.instant("QUANTITY");
    } else {
      this.dynamicTitle = this.translate.instant("PERCENT");
      this.dynamicTitleHeader = this.translate.instant("PERCENTSIGN");
    }
    this.createYearlyReportChart();
    this.createYearlyRiskChart();
    this.createYearlyRestrictionChart();
    this.getHHPhrases();
    this.createCMRACharts();
    this.createSpecialCharts();
    this.createPictogramCharts();
  }
}
