import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { OrganisationService } from 'src/app/services/organisation.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-modal-select-organisation',
  templateUrl: './modal-select-organisation.component.html',
  styleUrls: ['./modal-select-organisation.component.css']
})
export class ModalSelectOrganisationComponent implements OnInit, OnDestroy  {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() orgList;

  selectedOrganisation: any;
  itemsPerPage:number=10;
  pageNumber:number=1;
  organisations: any;
  path: any;
  userSearch = {
    all: "",
    name: "",
  };

  constructor(public activeModal: NgbActiveModal, 
              private authService: AuthService,
              private organisationService: OrganisationService) {}

  ngOnInit(): void {
    this.organisations = this.orgList;
    this.path = this.path;
  }

  ngOnDestroy(): void {
      this.organisationService.organisationChanged.next(this.selectedOrganisation);
  }

  add(){
    localStorage.setItem("newItem","new");
    this.activeModal.close(ModalSelectOrganisationComponent);
  }

  select(organisation) {
    this.organisationService.getOrganisation(organisation.id);
    this.organisationService.getOrganisationOrgDataListener().pipe(take(1)).subscribe({
      next: organisation => {
          localStorage.setItem('selectedOrganisation', JSON.stringify(organisation));
          localStorage.setItem('rootOrganisation', JSON.stringify(organisation));
          localStorage.setItem('selectedDepartment', JSON.stringify(organisation));
          localStorage.removeItem("allDepartments");
          this.passEntry.emit(organisation);
        this.activeModal.close(ModalSelectOrganisationComponent);
      },
      error: error => {
        this.passEntry.emit(organisation);
        this.activeModal.close(ModalSelectOrganisationComponent);
      }
    })
  }

  logout() {
    this.authService.logout();
  }
}
