import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import * as _ from "lodash";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.css"],
})
export class NewsComponent implements OnInit {
  organisation: any;
  loadingData: boolean = false;
  selectedDepartment: any;
  listAlldepartments: any;
  currentNews: any = [];
  selectedNews;
  news_header;
  news_body;
  editNewsProfile: boolean = false;

  constructor(
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.organisation = JSON.parse(localStorage.getItem("rootOrganisation"));
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );
    this.getAllDepartments();
    setTimeout(() => {
      this.loadNews();
    }, 500);
  }

  departFullName(department) {
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;
            }
          },
          error: (error) => {
            console.log("Error: ", error);
            return null;
          },
        });
    } else {
      return null;
    }
  }

  gotoControlPanel() {
    this.router.navigate(["/controlpanel"]);
  }

  loadNews() {
    this.currentNews = [];
    this.organisationService.getAllNews();
    this.organisationService
      .getOrganisationNewsDataListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.currentNews = response;
          this.loadingData=false;
        },
        error: (error) => {
          console.log("Error: ", error);
          this.loadingData=false;
          return null;
        },
      });
  }

  saveNews() {
    this.loadingData = true;
    if (this.news_header) {
      var newsInfo = {
        news_header: this.news_header,
        news_body: this.news_body,
      };
      this.organisationService.addNews(newsInfo);
      this.organisationService
        .getOrganisationNewsDataListener()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (_.isObject(response.status)) {
              this.loadingData = false;
            } else {
              window.location.reload();
            }
          },
          error: (error) => {
            console.log("getNews error: ", error);
            this.loadingData = false;
            this.utilService.onError(error);
            return null;
          },
        });
    } else {
      window.location.reload();
    }
  }

  cancelEditNews() {
    this.editNewsProfile = false;
    this.selectedNews = null;
  }

  removeNews(Id) {
    this.loadingData = true;
    this.organisationService.deleteNews(Id);
    this.organisationService
      .getOrganisationNewsDataListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (_.isObject(response.status)) {
            this.loadingData = false;
          } else {
            this.loadingData=false;
            window.location.reload();
          }
        },
        error: (error) => {
          console.log("getNews error: ", error);
          this.loadingData = false;
          this.utilService.onError(error);
          return null;
        },
      });
  }
  
  logout() {
    this.authService.logout();
  }
}
