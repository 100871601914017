import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import { AuthService } from '../auth/auth.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalSelectProductDepartmentComponent } from 'src/app/modals/modal-select-product-department/modal-select-product-department.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssessmentChemicalService } from "src/app/services/assessment-chemical.service";
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-assessments-chemical',
  templateUrl: './assessments-chemical.component.html',
  styleUrls: ['./assessments-chemical.component.css']
})
export class AssessmentsChemicalComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  assessment: any;
  assessments: any;
  listAlldepartments: any;
  sortDirection: any = null;
  sortValue: any = null;
  assessmentfiles: any = [];
  showSubdepartments: boolean = false;
  products: any = [];
  subscribesToSds: any;
  subscribesToGrb: any;
  lrbDBFilter: any;
  lrbDBFilterName: string = "";
  order=['asc','asc','asc','asc','asc','asc','asc','asc'];

  assessmentSearch = {
    all:""
  };

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private router: Router,
    private materialListService: MaterialListService,
    private assessmentService: AssessmentService,
    private userService: UserService,
    private modalService: NgbModal,
    private assessmentChemicalService: AssessmentChemicalService) { }

  ngOnInit(): void {

    localStorage.removeItem('assessment_chemicals');

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    this.sortDirection = localStorage.getItem('sortDirection', );
    if (this.sortDirection == null)
    {
      this.sortDirection = 'start';
    }

    this.sortValue = localStorage.getItem('sortValue', );
    if (this.sortValue == null)
    {
      this.sortValue = 'updated_date';
    }

    this.lrbDBFilter = JSON.parse(localStorage.getItem("lrbDBFilter"));
    if (this.lrbDBFilter == 1)
    {
      this.lrbDBFilterName = this.translate.instant('ONGOING');
    }
    if (this.lrbDBFilter == 2)
    {
      this.lrbDBFilterName = this.translate.instant('DONE');
    }
    localStorage.removeItem('lrbDBFilter');

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');

    this.loadingData = true;

    this.getAllDepartments();
    this.loadTableData();

    // Change department
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      this.ngOnInit();
    })
  }

  removeFilter() {
    localStorage.removeItem('lrbDBFilter');
    this.ngOnInit();
  }

  openFileInNewWindow(file) {
    let fileId = file.file_id;
    this.assessmentChemicalService.getcDocFileWithId(fileId);
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
        }
        else {
          // var popup = window.open('');
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  onToggleShowSubDepartments() {
    this.showSubdepartments = !this.showSubdepartments;
    localStorage.setItem('showSubdepartments', JSON.stringify(this.showSubdepartments));
    this.loadTableData();
  }

  loadTableData() {
    this.products = [];
    if (this.showSubdepartments) {

      this.assessmentChemicalService.getProductsWithSuborganisations();
      this.assessmentChemicalService.getAssessmentProductsDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products == null) {
            this.products = [];
            this.loadingData = false;
          }
          else {
            if(!(this.currentUser.is_admin || this.currentUser.is_useradmin || this.currentUser.is_super_user)){
              this.products = _.filter(products, function(o)
                {
                  return o.LRB_status==3;
                }
              );
            }else{
              //this.products = products;
              this.products = this.sortByDate(products)
            }
            if (this.sortDirection === 'desc') {
              this.products = _.orderBy(this.products, ['type', this.sortValue]).reverse();
            }

            if (this.sortDirection === 'asc') {
              this.products = _.orderBy(this.products, ['type', this.sortValue]);
            }

            if (!this.sortDirection||(this.sortDirection&&this.sortDirection == 'start')) {
              this.products = this.sortByDate(products);
            }

            if (this.lrbDBFilter != null)
            {
              if (this.lrbDBFilter === '1')
              {
                this.products = this.products.filter(product =>
                  [0, 1, 2].includes(product.LRB_status)
                );
              }

              if (this.lrbDBFilter === '2')
              {
                this.products = this.products.filter(product =>
                  [3].includes(product.LRB_status)
                );
              }

              if (this.lrbDBFilter === '3')
              {
                // Get the current date and subtract one year
                const oneYearAgo = new Date();
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

                this.products = this.products.filter(product =>
                  new Date(product.created_date) < oneYearAgo
                );
              }
            }

            this.sampleProductData(this.products);
          }
          this.getItemsPerPage();
        },
        error: error => {
          this.loadingData = false;
          this.products = [];
          console.log("Error: ", error);
        }
      })
    } else {

      this.assessmentChemicalService.getProducts();
      this.assessmentChemicalService.getAssessmentProductsDataListener().pipe(take(1)).subscribe({
        next: products => {
          if (products == null) {
            this.loadingData = false;
            this.products = [];
          }
          else {
            this.products = this.sortByDate(products);
            if(!(this.currentUser.is_admin || this.currentUser.is_useradmin || this.currentUser.is_super_user)){
              this.products = _.filter(products, function(o)
                {
                  return o.status==3;
                }
              );
            }else{
              this.products = this.sortByDate(products);
            }

            if (this.sortDirection === 'desc') {
              this.products = _.orderBy(this.products, ['type', this.sortValue]).reverse();
            }

            if (this.sortDirection === 'asc') {
              this.products = _.orderBy(this.products, ['type', this.sortValue]);
            }

            if (!this.sortDirection||(this.sortDirection&&this.sortDirection == 'start')) {
              this.products = this.sortByDate(products);
            }

            this.sampleProductData(this.products);
          }
        },
        error: error => {
          this.loadingData = false;
          this.products = [];
          console.log("Error: ", error);
        }
      })
    }
  }

  sampleProductData(products) {
    var tempProductsId = [];
    var tempProducts = [];
    for (var n = 0; n < this.products.length; n++){
      if (tempProductsId.indexOf(this.products[n].sareq_id) === -1) {
        // Must exist if used later
        products[n].departments = [];
        tempProductsId.push(this.products[n].sareq_id);
        for (var o = 0; o < this.products.length; o++) {
          if (this.products[o].sareq_id == this.products[n].sareq_id){
              products[n].departments.push({
                  name: this.products[o].department,
                  id: this.products[o].organisation_id,
                  riskassessmentstatus: this.products[o].LRB_status
              })
          }
        }
        tempProducts.push(products[n]);
      }
    }
    this.products = this.sortByDate(tempProducts)
    this.loadingData = false;
  }

  updatedGrbFile(chemical) {
    if (chemical.grp_last_updated > chemical.updated_date)
    {
      return true;
    }

    return false;
  }

  removeDuplicates(departments: any[]) {
    const seenIds = new Set<string>();
    const filteredDepartments = departments.filter(department => {
        if (seenIds.has(department.organisation_id)) {
            return false; // Skip this department as its ID is a duplicate
        } else {
            seenIds.add(department.organisation_id);
            return true; // Keep this department, its ID is unique so far
        }
    });
    return filteredDepartments;
  }

  editChemical(chemical) {
    localStorage.removeItem('assessment_chemicals');
      this.assessmentChemicalService.getDepartmentsWhereProductAppearsByProductId(chemical.product_id, false);
      this.assessmentChemicalService.getAssessmentChemicalDepartmentDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if ((departments == null) || (departments.length == 0)) {}
          else {
            departments = this.removeDuplicates(departments);
            if ( departments.length === 1 || this.showSubdepartments == false ) {
              if (_.isString(chemical.organisation_id)) {
                if (chemical.organisation_id !== this.selectedDepartment.id) {
                  this.organisationService.changeDepartmentById(chemical.organisation_id);
                  this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                    this.utilService.departmentChangeClicked(this.router.url);
                    localStorage.setItem('departmentchanged','set');
                    this.router.navigate(['/assessmentchemicaledit', chemical.product_id]);
                  })
                }
                else
                {
                  localStorage.setItem('departmentchanged','set');
                  this.router.navigate(['/assessmentchemicaledit', chemical.product_id]);
                }
              }
                // Probably old code
                // this.router.navigate(['/assessmentchemicaledit', chemical.product_id]);
            }
            else {
              const modalRef = this.modalService.open(ModalSelectProductDepartmentComponent);
              modalRef.componentInstance.productName = chemical.product_name + " " + this.translate.instant("POST_EXISTS_ON_MULTIPLE_DEPARTMENTS");
              modalRef.componentInstance.departments = departments;
              modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((selectedDepartment) => {
                if (selectedDepartment != null) {
                  this.organisationService.changeDepartmentById(selectedDepartment.id);
                  this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                    this.utilService.departmentChangeClicked(this.router.url);
                    this.router.navigate(['/assessmentchemicaledit', chemical.product_id]);
                  })
                }
              })
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  sortHeader(value) {
    if ( value == 'product_name' ) {
      if ( this.order[1] == 'asc') {
        this.sortDirection = 'asc';
        this.order[1] = 'desc';
      } else {
        this.order[1] = 'asc';
        this.sortDirection = 'desc';
      }
      if( this.sortDirection == "desc") {
        this.products.sort( (a, b) => (a[value]?.toLowerCase() > b[value]?.toLowerCase()) ? 1 : -1);
      } else if ( this.sortDirection == "asc" ) {
        this.products.sort( (a, b) => (a[value]?.toLowerCase() > b[value]?.toLowerCase()) ? -1 : 1);
      }
    } else if( value == 'updated_date' ) {
      if ( this.order[0] == 'asc' ) {
        this.sortDirection = 'asc';
        this.order[0] = 'desc';
      } else {
        this.order[0] = 'asc';
        this.sortDirection = 'desc';
      }
      if( this.sortDirection == "desc" ) {
        this.products = _.orderBy( this.products, [value], ['desc'] );
      } else if(this.sortDirection=="asc"){
        this.products=_.orderBy(this.products,[value], ['asc']);
      }
    } else if ( value == 'supplier_name' ) {
      if (this.order[2] == 'asc') {
        this.sortDirection = 'asc';
        this.order[2] = 'desc';
      } else {
        this.order[2] = 'asc';
        this.sortDirection = 'desc';
      }
      if(this.sortDirection=="desc"){
        this.products.sort((a, b) => (a[value]?.toLowerCase() > b[value]?.toLowerCase()) ? 1 : -1);
      } else if(this.sortDirection=="asc"){
        this.products.sort((a, b) => (a[value]?.toLowerCase() > b[value]?.toLowerCase()) ? -1 : 1);
      }
    } else if(value=='department') {
      if (this.order[3] == 'asc') {
        this.sortDirection = 'asc';
        this.order[3] = 'desc';
      } else {
        this.order[3] = 'asc';
        this.sortDirection = 'desc';
      }
      if(this.sortDirection=="desc"){
        this.products.sort((a, b) => (a[value]?.toLowerCase() > b[value]?.toLowerCase()) ? 1 : -1);
      } else if(this.sortDirection=="asc"){
        this.products.sort((a, b) => (a[value]?.toLowerCase() > b[value]?.toLowerCase()) ? -1 : 1);
      }
    } else if(value=='allergic') {
      if (this.order[4] == 'asc') {
        this.sortDirection = 'asc';
        this.order[4] = 'desc';
      } else {
        this.order[4] = 'asc';
        this.sortDirection = 'desc';
      }
      if(this.sortDirection=="desc"){
        this.products.sort((a, b) => (a[value]?.toLowerCase() > b[value]?.toLowerCase()) ? 1 : -1);
      } else if(this.sortDirection=="asc"){
        this.products.sort((a, b) => (a[value]?.toLowerCase() > b[value]?.toLowerCase()) ? -1 : 1);
      }
    } else if ( value=='LRB_status' ) {
      if (this.order[5] == 'asc') {
        this.sortDirection = 'asc';
        this.order[5] = 'desc';
      } else {
        this.order[5] = 'asc';
        this.sortDirection = 'desc';
      }
      if(this.sortDirection=="desc"){
        this.products.sort((a, b) => (a[value] > b[value]) ? 1 : -1);
      } else if(this.sortDirection=="asc"){
        this.products.sort((a, b) => (a[value] > b[value]) ? -1 : 1);
      }
    } else if(value=='assessment_risk') {
      if (this.order[6] == 'asc') {
        this.sortDirection = 'asc';
        this.order[6] = 'desc';
      } else {
        this.order[6] = 'asc';
        this.sortDirection = 'desc';
      }
      if(this.sortDirection=="desc"){
        this.products=_.orderBy(this.products, [( o ) => { if(o[value]!=null){return o[value]}else{return 999} }], ['asc']);
      } else if(this.sortDirection=="asc"){
        this.products=_.orderBy(this.products, [( o ) => { if(o[value]!=null){return o[value]}else{return -999} }], ['desc']);
      }
    } else if(value=='risk_prio') {
      if (this.order[7] == 'asc') {
        this.sortDirection = 'asc';
        this.order[7] = 'desc';
      }
      else {
        this.order[7] = 'asc';
        this.sortDirection = 'desc';
      }
      if(this.sortDirection=="desc"){
        this.products=_.orderBy(this.products, [( o ) => { if(o[value]!=null){return o[value]}else{return 999} }], ['asc']);
      } else if(this.sortDirection=="asc"){
        this.products=_.orderBy(this.products, [( o ) => { if(o[value]!=null){return o[value]}else{return -999} }], ['desc']);
      }
    } else {
      localStorage.setItem('sortDirection', this.sortDirection);
      localStorage.setItem('sortValue', value);
      this.ngOnInit();
    }
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  departmentsDisplayed(department){
    let departments = this.utilService.departFullName(department, this.listAlldepartments);
    return this.truncateText(departments, 10);
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  copyChemical(chemical) {
    this.assessmentChemicalService.getDepartmentsWhereProductAppearsByProductId(chemical.product_id, false);
    this.assessmentChemicalService.getAssessmentChemicalDepartmentDataListener().pipe(take(1)).subscribe({
      next: departments => {
        if (departments == null) {}
        else {
          // Filter out unique organizations
          let uniqueDepartments = departments.filter((department, index, self) =>
            index === self.findIndex((d) => d.organisation_id === department.organisation_id)
          );

          // Get non-unique organizations
          let nonUniqueDepartments = departments.filter((department) =>
            !uniqueDepartments.includes(department)
          );

          // Sort non-unique organizations by updated_date
          nonUniqueDepartments.sort((a, b) => b.updated_date - a.updated_date);

          // Combine unique and sorted non-unique organizations
          departments = [...uniqueDepartments, ...nonUniqueDepartments];

          // Filter out departments where riskfile is not null or an empty string
          departments = departments.filter(department => department.riskfile === null || department.riskfile === "");

          if( departments.length === 0) {
            let warningHeader = `${this.translate.instant("NO_DEPARTMENT_TO_COPY_HEADER")}`;
            let warnings = ["NO_DEPARTMENT_TO_COPY_WARNING_1", "NO_DEPARTMENT_TO_COPY_WARNING_2"];
            let warningList = warnings.map(warning => `<li>${this.translate.instant(warning)}</li>`).join('');
            Swal.fire({
              title: this.translate.instant("NO_DEPARTMENT_TO_COPY"),
              html: `${warningHeader}`+`<br/><br/><ul>${warningList}</ul><br/>`,
              icon: 'warning'
            });
            return;
          }
          const modalRef = this.modalService.open(ModalSelectProductDepartmentComponent);
          modalRef.componentInstance.productName = this.translate.instant("COPY_LRB");
          modalRef.componentInstance.departments = departments;
          modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((selectedDepartment) => {
            this.assessmentChemicalService.copyChemicalToDepartment(selectedDepartment.id, chemical.id);
            this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
              next: data => {
                if (data == null) {
                }
                else {
                  this.router.navigate(['/assessmentschemical']);
                }
              },
              error: error => {
                console.log("Error: ", error);
              }
            })
          })
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }


  openSdsInNewWindow(product) {

    var filename = product.product_name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  openGrbInNewWindow(product) {

    var filename = product.product_name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  openLrbInNewWindow(product) {
    var filename = product.product_name + '_risk.pdf';
    // var popup = window.open('');
    this.assessmentChemicalService.getLrbAsTrustedResourceUrl(this.selectedDepartment.id, product, 'chemical_riskassessment');
    this.assessmentChemicalService.getAssessmentChemicalDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  logout() {
    this.authService.logout();
  }

  sortByDate(products) {
    products.sort((a, b) => {
      const dateA = new Date(a.updated_date);
      const dateB = new Date(b.updated_date);
      if (dateA !== dateB) {
        if (a.updated_date === null) { return 1; }// a is larger (should come later)
        if (b.updated_date === null) { return -1; } // b is larger (should come earlier)
        return this.sortDirection === 'desc' ? dateB.getTime() - dateA.getTime() : dateB.getTime() - dateA.getTime();
      }
      return 0; // a and b are equal
    });
    return products
  }

  openExtSdsInNewWindow(prod) {
    var filename = prod.name + '.pdf';
    // var popup = window.open('')
    this.productService.getExtSdsAsTrustedResourceUrl(prod.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }
}
