import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SupportService } from 'src/app/services/support.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.css']
})
export class ContactformComponent implements OnInit {

  contact_name: any;
  contact_email: any;
  contact_phone: any;
  contact_subject: any;
  contact_message: any;
  organisation: any;
  selectedDepartment: any;

  constructor(private formBuilder:FormBuilder, private supportService: SupportService,
              private translate: TranslateService, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    if (localStorage.getItem('selectedDepartment', ) == null) {
      this.selectedDepartment = JSON.parse(localStorage.getItem('rootOrganisation', ));
    }
    else {
      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      if (this.selectedDepartment == null) {
        this.selectedDepartment = JSON.parse(localStorage.getItem('rootOrganisation', ));
      }
    }
  }

  logout() {
    this.authService.logout();
  }
}
