import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { SubstitutionService } from 'src/app/services/substitution.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { SupportService } from 'src/app/services/support.service';
import { SareqService } from 'src/app/services/sareq.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
import * as cloneDeep from 'lodash/cloneDeep';
declare var $: any;

@Component({
  selector: 'app-workstep-substitution',
  templateUrl: './workstep-substitution.component.html',
  styleUrls: ['./workstep-substitution.component.css']
})
export class WorkstepSubstitutionComponent implements OnInit {

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  loadingData: boolean = false;
  organisation: any = null;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any;
  substitution: any = null;
  subscribesToSds: any;
  subscribesToGrb: any;
  workstepId: any = null;
  pictures: any = [];
  issuer: any = null;
  picture: any = null;
  pictures2: any = [];
  picturenames: any = [];
  product: any = null;
  pictureHeight = 200;
  pictureWidth = 260;
  substitutionfile: any;
  substitutionimage: any;
  filenames: any = [];
  editWorkstepActive: boolean = false;
  workstep: any;
  sareqProducts: any = [];
  products: any = [];
  isChanged:boolean=false;
  tmpProduct: any;
  candidates: any;
  candidateName: string = '';

  substitutionSearch = {
    all:""
  };

  ngAfterViewInit(): void {
    $('#substitutiontable').tablesort();
  }

  productAddSearch = {
    all:"",
    name: "",
    supplier_name: ""
  };

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private router: Router,
    private substitutionService: SubstitutionService,
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private uploadService: FileUploadService,
    private supportService: SupportService,
    private sareqService: SareqService) { }

  ngOnInit(): void {

    this.loadingData = true;

    this.route.params.pipe(take(1)).subscribe(params => {
      this.workstepId = params['id'];
      if (this.workstepId == '0')
      {
        this.editWorkstepActive = false;
      }
      else {
        this.editWorkstepActive = true;
      }
    });

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');

    this.getAllDepartments();

    this.substitution = JSON.parse(localStorage.getItem('substitution', ));

    if (this.substitution && this.editWorkstepActive == false)
    {
      this.workstep = {
            id: "",
            comment: "",
            fileid : "",
            substitutionid: this.substitution.id,
            product: null,
            images: [],
            files: [],
            approved: 0,
            product_id: null
        };
    }
    else
    {
      if (this.substitution && this.substitution.worksteps) {
        this.workstep = this.substitution.worksteps[this.workstepId-1];
        if(this.workstepId=='all'){
          this.workstep=this.substitution.worksteps;
        }
      }
    }

    this.getCandidates(this.substitution.product_id);
    this.loadTableData();
  }

  getCandidates(sareqId) {
    this.substitutionService.getSingleItemInSubstitutionList(sareqId);
    this.substitutionService.getSubstitutionListListener().pipe(take(1)).subscribe({
      next: response => {
        if (response == null) {
        }
        else {
          this.candidates = response.data;
        }
      },
      error: error => {
      }
    })
  }

  loadTableData() {

    if (this.substitution) {
      this.userService.getSingleUser(this.substitution.issuer);
      this.userService.getCurrentSingleUserDataListener().pipe(take(1)).subscribe({
        next: user => {
          this.issuer = user;
          this.loadingData = false;
        },
        error: error => {
          this.issuer = null;
          this.loadingData = false;
        }
      })
    }
  }

  incomingfile(event) {
    this.substitutionfile = event.target.files[0];

    if (this.substitutionfile != null) {

      this.uploadService.upload(this.substitutionfile);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.substitutionfile = null;
          }
          else {
            if (!this.workstep.files)
            {
              this.workstep.files = [];
            }

            var file_id = {"file_id": result};
            this.workstep.files.push({
                'Substitution_id':this.substitution.id,
                'file_id':result,
                'File_name':this.substitutionfile.name,
                'Comment_id':this.workstep.id
            });
            this.picturenames.push(this.substitutionfile.name);
          }
        },
        error: error => {
          this.substitutionfile = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  incomingimage(event) {

    this.substitutionimage = event.target.files[0];

    if (this.substitutionimage != null) {

      this.uploadService.upload(this.substitutionimage);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.substitutionimage = null;
          }
          else {
            if (!this.workstep.images)
            {
              this.workstep.images = [];
            }

            var file_id = {"file_id": result};
            this.workstep.images.push({
                'Substitution_id':this.substitution.subid,
                'file_id':result,
                'File_name':this.substitutionimage.name,
                'Comment_id':this.workstep.id
            });

            this.picturenames.push(this.substitutionimage.name);
          }
        },
        error: error => {
          this.substitutionimage = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  noproductinsareq() {
    Swal.fire({
      title: this.translate.instant("NO_PRODUCT_IN_SAREQ"),
      text: this.translate.instant("NO_PRODUCT_IN_SAREQ_TEXT"),
      input: 'text',
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
      cancelButtonText: this.translate.instant("CANCEL"),
      inputPlaceholder: this.translate.instant("PRODUCT")
    }).then((result) => {
      if (result.dismiss == 'cancel')
      {}
      else {
        var object=({
          email: this.currentUser.mail,
          cc: "",
          subject: "Substitution, produkt saknas",
          body:
            "Företag: " + this.selectedOrganisation.name + " (" + this.selectedOrganisation.id + ")\n" +
            "--------------------------------------------\n" +
            "Meddelande: \n" + result.value + "\n" +
            "--------------------------------------------\n" +
            "Beställare: Namn: " + this.issuer.first_name + " " + this.issuer.last_name + "\n" +
            "            Email: " +  this.issuer.email + "\n" +
            "            Tel: " +  this.issuer.phone
        });
        this.supportService.mail(object);
        this.supportService.getSupportDataListener().pipe(take(1)).subscribe({
          next: pdfContent => {
            Swal.fire({
              title: this.translate.instant("MESSAGE_SENT"),
              text: this.translate.instant("YOUR_MESSAGE_HAS_BEEN_SENT"),
              icon: "success",
              showCancelButton: false,
            });
          },
          error: error => {
            console.log("Error: ", error);
            Swal.fire({
              title: this.translate.instant("AN_ERROR_OCCURED"),
              text: this.translate.instant("YOUR_MESSAGE_HAS_BEEN_SENT"),
              icon: "error",
              showCancelButton: false,
            });
          }
        })
      }
    });
  }

  submit(workstep) {
  }

  save(){
    if(this.workstepId=='all'){
      this.substitution.worksteps=this.workstep;
    } else if(this.workstepId==0&&this.workstep!=null)
    {
      if(!this.substitution.worksteps){
        this.substitution.worksteps=[];
      }
      this.substitution.worksteps.push(this.workstep);
    }


    localStorage.setItem('substitution', JSON.stringify(this.substitution));
    this.router.navigate(['/substitutionedit', this.substitution.id]);
  }

  goToSubstitutionList() {
    this.router.navigate(['/substitutionedit', this.substitution.id]);
  }

  addProduct(product) {
    var productToAdd = cloneDeep(product);
    this.tmpProduct = product;
    if (this.workstep.product == null)
    {
      this.workstep.product = productToAdd;
      this.workstep.product_id = productToAdd.id;
      this.tmpProduct.isSelected=true;
      this.isChanged = true;
    }
  }

  removeProduct(product) {
    this.tmpProduct.isSelected = false;
    if (this.substitution.worksteps) {
      for (let i = 0; i < this.substitution.worksteps.length; i++)
      {
        if (product.product_id == this.substitution.worksteps[i].product_id) {
          this.substitution.worksteps.splice(i, 1);
          if(this.substitution.worksteps.length<1){
            this.editWorkstepActive=false;
          }

        }
      }
    }
    for (let i = 0; i < this.products.length; i++){
      if(this.products[i].isSelected){
        delete this.products[i].isSelected;
        break;
      }
    }

    this.workstep.product = null;
  }

  approve(workstep) {
    workstep.approved==1?workstep.approved=0:workstep.approved=1;
    if(workstep.approved==1 && this.substitution.worksteps){
      for (var i = 0; i < this.substitution.worksteps.length; i++) {
        if (this.substitution.worksteps[i]!=workstep) {
          this.substitution.worksteps[i].approved = 0;
        }
      }
    }
  }

  fileRemove(index) {
    this.substitution.substitutionfiles.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  pictureRemove(index) {
    this.substitution.substitutionimages.splice(index, 1);
    this.pictures.splice(index, 1);
  }

  openDocumentInNewWindow(fileId) {
    this.substitutionService.getcDocFileWithId(fileId);
    this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {

        // var popup = window.open('');
        // window.open(pdfContent);
        var opened=window.open(pdfContent,"_blank");
        if(!opened){
          window.location.href=pdfContent;
        }
      },
      error: error => {
      }
    })
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  openSdsInNewWindow(product) {

    var filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  openGrbInNewWindow(product) {

    var filename = product.name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  logout() {
    this.authService.logout();
  }

  approvestatus(approve){
    if(approve==1){
      return this.translate.instant('APPROVED2');
    } else {
      return this.translate.instant('NOT_APPROVE');
    }
  }

  choseCandidate(name) {
    this.candidateName = name;
  }

  openExtSdsInNewWindow(product) {

    var filename = product.name + '.pdf';
    // var popup = window.open('')
    this.productService.getExtSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }
}
