<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white mb-20">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-left">
            <div class="text-center">
                <div class="text-xl text-gray-900 font-bold mb-4">{{product.product_name}}</div>
            </div>
            <!-- Modal Body -->
            <div class="text-center mb-4">
                <span class="text-xl font-bold">{{"PRINT_LABELS" | translate}}</span><br>
            </div>
            <span class="font-bold mt-4 mb-4">{{"WRITE_LABELS_TO_DOC" | translate}}</span><br>
            <em><span class="text-sm mb-4">{{"LABELS_TEXT1" | translate}}</span><br>
            <span class="text-sm mb-4">{{"LABELS_TEXT2" | translate}}</span></em>
            <div class="flex flex-col justify-center items-start mt-4">
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="size2" [(ngModel)]="size2" (ngModelChange)="size2 = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleSize2Checkbox($event)">
                    <span class="text-sm mr-1">{{"2 "}}{{"LABELS" | translate}}{{" (202x105)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="size2b" [(ngModel)]="size2b" (ngModelChange)="size2b = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleSize2bCheckbox($event)">
                    <span class="text-sm mr-1">{{"2 "}}{{"LABELS" | translate}}{{" (210x148)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="size4b" [(ngModel)]="size4b" (ngModelChange)="size4b = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleSize4bCheckbox($event)">
                    <span class="text-sm mr-1">{{"4 "}}{{"LABELS" | translate}}{{" (99x139)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="size4" [(ngModel)]="size4" (ngModelChange)="size4 = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleSize4Checkbox($event)">
                    <span class="text-sm mr-1">{{"4 "}}{{"LABELS" | translate}}{{" (142x105)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="size8" [(ngModel)]="size8" (ngModelChange)="size8 = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleSize8Checkbox($event)">
                    <span class="text-sm mr-1">{{"8 "}}{{"LABELS" | translate}}{{" (74x105)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="size8b" [(ngModel)]="size8b" (ngModelChange)="size8b = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleSize8bCheckbox($event)">
                    <span class="text-sm mr-1">{{"8 "}}{{"LABELS" | translate}}{{" (99x68)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="size21" [(ngModel)]="size21" (ngModelChange)="size21 = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleSize21Checkbox($event)">
                    <span class="text-sm mr-1">{{"21 "}}{{"LABELS" | translate}}{{" (70x41)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="size24" [(ngModel)]="size24" (ngModelChange)="size24 = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleSize24Checkbox($event)">
                    <span class="text-sm mr-1">{{"24 "}}{{"LABELS" | translate}}{{" (64x40)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="qrcode" [(ngModel)]="qrcode" (ngModelChange)="qrcode = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="toggleQrcodeCheckbox($event)">
                    <span class="text-sm mr-1">{{"QRCODE" | translate}}</span>
                </div>
                <span class="font-bold mt-4">{{"PRINT_LABELS_ON_PRINTER" | translate}}</span><br>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="printer" [(ngModel)]="printer" (ngModelChange)="printer = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="togglePrinterCheckbox($event)">
                    <span class="text-sm mr-1">{{"PRINT_LABELS" | translate}}{{" (50x88)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="printer991" [(ngModel)]="printer991" (ngModelChange)="printer991 = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="togglePrinter991Checkbox($event)">
                    <span class="text-sm mr-1">{{"PRINT_LABELS" | translate}}{{" (42,3x99,1)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="printer635" [(ngModel)]="printer635" (ngModelChange)="printer635 = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="togglePrinter635Checkbox($event)">
                    <span class="text-sm mr-1">{{"PRINT_LABELS" | translate}}{{" (33,9x63,5)"}}</span>
                </div>
                <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                    <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="printerqrcode" [(ngModel)]="printerqrcode" (ngModelChange)="printerqrcode = $event" 
                        [ngModelOptions]="{standalone: true}" (change)="togglePrinterqrcodeCheckbox($event)">
                    <span class="text-sm mr-1">{{"PRINT_LABELS" | translate}} {{"QRCODE" | translate}}{{" (50x88)"}}</span>
                </div>
            </div>
            <div class="flex flex-row justify-center">
                <button class="button-blue mt-10 mb-20" title="{{'PRINT' | translate}}" (click)="save()">
                    <img title="{{'PRINT' | translate}}" src="images/icons/print-svgrepo-com.svg" class="w-7 h-7">
                    <span class="text-lg">{{"PRINT" | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>

