import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ReportService } from 'src/app/services/report.service';
import { ProductService } from 'src/app/services/product.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash";
declare var $: any;

@Component ( {
  selector: 'app-report-fire',
  templateUrl: './report-fire.component.html',
  styleUrls: [ './report-fire.component.css' ]
} )
export class ReportFireComponent implements OnInit {
  dtOptions: any = {};
  @Input() reportInfo;

  itemsPerPage: number = 10;
  pageNumber: number = 1;
  reportInformation: any = [];
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  currentLanguage: any;
  subscribesToClassification: any;
  subscribesToTransport: any;
  listAlldepartments: any;
  loadingData: boolean = false;
  category: any = 'all';
  categoryName: any = "";
  allProducts=[];

  reportSearch = {
    all: "",
  };

  constructor (
                private router: Router,
                private route: ActivatedRoute,
                private utilService: UtilService,
                private authService: AuthService,
                private reportService: ReportService,
                private productService: ProductService,
                private translate: TranslateService,
                private organisationService: OrganisationService,
                public activeModal: NgbActiveModal,
                private formBuilder:FormBuilder
              ) { }

  ngOnInit(): void {
    this.reportInformation = {
      name: "",
      start_date: new Date( "2015 1 January" ),
      date: new Date(),
      sub: false,
      actual: false
    };

    this.categoryName = this.translate.instant("ALL");

    if ( this.reportInfo ) {
      this.reportInformation.name = this.reportInfo.reportType.name;
      this.reportInformation.start_date = this.reportInfo.date;
      this.reportInformation.date = this.reportInfo.date;
      this.reportInformation.sub = this.reportInfo.includeSubDepartments;
      this.reportInformation.start_date = this.reportInfo.Actual;
    }

    this.organisation = JSON.parse( localStorage.getItem('rootOrganisation', ) );
    this.selectedDepartment = JSON.parse( localStorage.getItem('selectedDepartment', ) );
    this.selectedOrganisation = JSON.parse( localStorage.getItem('selectedOrganisation', ) );
    if (
      ( this.organisation == null ) ||
      ( this.selectedDepartment == null ) ||
      ( this.selectedOrganisation == null ) ) {
        this.logout();
    }

    if (
      ( this.organisation == null ) ||
      ( this.selectedDepartment == null ) ||
      ( this.selectedOrganisation == null ) ) {
      this.logout();
    }

    var data = localStorage.getItem( 'currentUser' , );
    if (
      data == null ||
      data == undefined ) {
      this.logout();
    }
    else {
      this.currentUser = JSON.parse( data );
    }

    this.currentLanguage = localStorage.getItem( 'usedLanguage' );
    switch( this.currentLanguage ) {
      case 'sv':
        this.currentLanguage = 0;
      break;
      case 'en':
        this.currentLanguage = 1;
      break;
      case 'fi':
        this.currentLanguage = 2;
      break;
      default:
        this.currentLanguage = 0;
      break;
    }

    if (
        this.reportInformation&&
        this.reportInfo ) {
      var result="";
      if ( typeof this.reportInformation.date === 'object' &&
           this.reportInformation.date !== null &&
           'toLocaleDateString' in this.reportInformation.date ) {
        result = this.reportInformation.date.toLocaleDateString( 'sv' );
      } else {
        result = this.reportInformation.date;
        this.reportInformation.date=new Date( this.reportInformation.date );
      }
      if (
        this.currentLanguage=='sv'||
        this.currentLanguage===0 ) {
        this.dtOptions = {
          pageLength: 10,
          lengthMenu: [10, 25, 50, 100],
          dom: 'Blfrtip',
          language: {
            "sEmptyTable": "Ingen data hittades",
            "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_",
            "sInfoEmpty": "Visar 0 till 0 av totalt 0",
            "sInfoFiltered": "(filtrerat från totalt _MAX_)",
            "sLoadingRecords": "Laddar...",
            "sSearch": "Filtrera:",
            "sLengthMenu": "Visa _MENU_ rader",
            "sZeroRecords": "Inga poster hittades",
            "oPaginate": {
              "sFirst": "Första",
              "sLast": "Sista",
              "sNext": "Nästa",
              "sPrevious": "Föregående"
            }
          },
          buttons: [
            {
              extend: 'colvis',
              text: this.translate.instant("CHOOSE_COLUMNS")
            },
            {
              extend: 'print',
              text: this.translate.instant("PRINT"),
              title: this.translate.instant(this.reportInformation.name),
              messageTop: "Datum: " + result,
              exportOptions: {
                stripHtml: false,
                columns: ':visible',
              }
            },
            {
              title: "report",
              extend: "excel",
              exportOptions: {
                columns: ':visible'
              }
            }
          ]
        };
      }
      else {
        this.dtOptions = {
          pageLength: 10,
          lengthMenu: [10, 25, 50, 100],
          dom: 'Bfrtlip',
          buttons: [
            {
              extend: 'colvis',
              text: this.translate.instant("CHOOSE_COLUMNS")
            },
            {
              extend: 'print',
              text: this.translate.instant("PRINT"),
              title: this.translate.instant(this.reportInformation.name),
              messageTop: "Datum: " + result,
              exportOptions: {
                stripHtml: false,
                columns: ':visible',
              }
            },
            {
              title: "report",
              extend: "excel",
              exportOptions: {
                columns: ':visible'
              }
            }
          ]
        };
      }
    }
    this.getAllDepartments();
    this.getReport();
  }

  setCategory ( category ) {
    if ( category == 'extremely' ) {
      this.category = 'extremely';
      this.categoryName = this.translate.instant("EXTREMELY_HIGHLY_FLAMMALBE");
    }
    else if ( category == 'high' ) {
      this.category = 'high';
      this.categoryName = this.translate.instant("EXPLOSIVE_AND_HIGHLY_FLAMMABLE_PRODUCTS");
    }
    else if ( category == 'low' ) {
      this.category = 'low';
      this.categoryName = this.translate.instant("FLAMMABLE_AND_OXIDIZING_PRODUCTS");
    }
    else if ( category == 'oxidizing' ) {
      this.category = 'oxidizing';
      this.categoryName = this.translate.instant("OXIDIZING");
    }
    else if ( category == 'gases' ) {
      this.category = 'gases';
      this.categoryName = this.translate.instant("FLAMMABLE_GASES");
    }
    else if ( category == 'all' ) {
      this.category = 'all';
      this.categoryName = this.translate.instant("ALL");
    }
    else {
      this.category = 'error';
      this.categoryName = this.translate.instant("ERROR");
    }
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe( {
        next: departments => {
          if ( departments == null ) {

          }
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      } )
    }
    else
    {
      return (null);
    }
  }

  departFullName( department ) {
    return this.utilService.departFullName( department, this.listAlldepartments );
  }

  getSubscription( subscription ) {
    return ( this.utilService.subscribesTo( subscription ) );
  }

  getReport() {
    this.loadingData = true;
    if ( this.reportInfo ) {
      var reportDate = new Date( this.reportInfo.date );
      reportDate = this.utilService.adjustDateForTimezoneOffset( reportDate );
      var info = {
        start_date: reportDate,
        date: reportDate,
        sub: this.reportInfo.includeSubDepartments,
        actual: this.reportInfo.Actual
      }
      this.reportService.getFireProducts( info );
      this.reportService.getReportDataListener().pipe( take( 1 ) ).subscribe( {
        next: products => {
          if ( products == null ) {
            this.loadingData = false;
          }
          else
          {
            this.reportInformation.products = products;
            var ids = [];
            for ( var i = 0; i < this.reportInformation.products.length; i++) {
              ids.push( { id: this.reportInformation.products[i].sareq_id,
                          lang: this.currentLanguage
                      } );
            }

            for ( var i = 0; i < this.reportInformation.products.length; i++ ) {
              if ( this.reportInformation.products[i].override != null) {
                this.reportInformation.products.splice(i, 1);
              }
            }
            this.loadingData = false;
            if (this.currentLanguage != 0) {
              this.productService.checkForOtherLanguages( this.organisation.id, ids );
              this.productService.getProductDataListener().pipe( take( 1 ) ).subscribe( {
                next: otherLangs => {
                  if ( otherLangs == null) {}
                  else {
                    for (var i = 0; i < this.reportInformation.products.length; i++) {
                      for (var j = 0; j < otherLangs.length; j++) {
                        if (this.reportInformation.products[i].sareq_id == otherLangs[j].override) {
                            this.reportInformation.products[i].name = otherLangs[j].name;
                            this.reportInformation.products[i].sds_file_id = otherLangs[j].sds_file_id;
                            this.reportInformation.products[i].overridden = true;
                            this.reportInformation.products[i].origProdId = this.reportInformation.products[i].id;
                            this.reportInformation.products[i].id = otherLangs[j].id;
                        }
                      }
                    }
                  }
                },
                error: error => {
                  console.log("Error: ", error);
                  this.loadingData = false;
                }
              } )
            }
            else {
              for ( var i = 0; i < this.reportInformation.products.length; i++ ) {
                if (this.reportInformation.products[i].override != undefined ) {
                  this.reportInformation.products.splice(i, 1 );
                }
              }
            }
          }

          this.reportInformation.products.extremely.forEach( element => {
            element.category="EXTREMELY_HIGHLY_FLAMMALBE"
          } );
          this.reportInformation.products.high.forEach( element => {
            element.category="EXPLOSIVE_AND_HIGHLY_FLAMMABLE_PRODUCTS"
          } );
          this.reportInformation.products.low.forEach( element => {
            element.category="FLAMMABLE_AND_OXIDIZING_PRODUCTS"
          } );
          this.reportInformation.products.oxidizing.forEach( element => {
            element.category="OXIDIZING"
          } );
          this.reportInformation.products.gases.forEach( element => {
            element.category="FLAMMABLE_GASES"
          } );

          this.allProducts = this.reportInformation.products.extremely.
                              concat( this.reportInformation.products.high ).
                              concat( this.reportInformation.products.low ).
                              concat( this.reportInformation.products.oxidizing ).
                              concat( this.reportInformation.products.gases );
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire( {
            title: this.translate.instant( "NO_PRODUCTS" ),
            text: this.translate.instant( "NO_PRODUCTS_WARNING" ),
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant( "OK" ),
            closeOnConfirm: true
          } );
          this.loadingData = false;
        }
      } )
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {}

  remove() {
    this.activeModal.close( ReportFireComponent );
  }
}
