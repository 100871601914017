<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
    <div class="loader"></div>
</div>

<div class="flex flex-row">
    <button class="px-4 py-2 w-1/2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
        <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
        </svg>
    </button>

    <div class="text-2xl font-bold w-1/2 m-4 underline">{{organisation.name}}</div>
</div>

<div class="container mr-auto px-4 mt-2">
    <ul class="flex flex-col md:flex-row">
        <li class="mr-1">
            <a (click)="setActiveTab(0)" [class.active]="activeTab==0" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"CUSTOMER_INFO" | translate}}</a>
        </li>
        <li class="mr-1">
            <a (click)="setActiveTab(1)" [class.active]="activeTab==1" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"SUBSCRIPTIONS" | translate}}</a>
        </li>
        <li class="mr-1">
            <a (click)="setActiveTab(5)" [class.active]="activeTab==5" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"LEVERANS" | translate}}</a>
        </li>
        <li class="mr-1">
            <a (click)="setActiveTab(2)" [class.active]="activeTab==2" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"USERS" | translate}}</a>
        </li>
        <li class="mr-1">
            <a (click)="setActiveTab(6)" [class.active]="activeTab==6" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"CUSTOMERACTIONS" | translate}}</a>
        </li>
    </ul>
</div>

<div *ngIf="activeTab==0" class="mt-1 px-4 py-2 mb-4">
    <div class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
        <div class="flex flex-col w-full mt-2">
            <div class="mb-4">
                <label class="font-bold mb-1">{{"NAME" | translate}}:</label><br>
                <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.name" (ngModelChange)="organisation.name = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"ORGANISATION_NUMBER" | translate}}:</label><br>
                <input title="{{'ORGANISATION_NUMBER' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.org_nr" (ngModelChange)="organisation.org_nr = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"ADDRESS" | translate}}:</label><br>
                <input title="{{'ADDRESS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.address_1" (ngModelChange)="organisation.address_1 = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"ADDRESS" | translate}}:</label><br>
                <input title="{{'ADDRESS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.address_2" (ngModelChange)="organisation.address_2 = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"ADDRESS_VISIT" | translate}}:</label><br>
                <input title="{{'ADDRESS_VISIT' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.address_visit" (ngModelChange)="organisation.address_visit = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"ZIP" | translate}}:</label><br>
                <input title="{{'ZIP' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.zip" (ngModelChange)="organisation.zip = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"CITY" | translate}}:</label><br>
                <input title="{{'CITY' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.city" (ngModelChange)="organisation.city = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"COUNTRY" | translate}}:</label><br>
                <input title="{{'COUNTRY' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.country" (ngModelChange)="organisation.country = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"WEB_PAGE" | translate}}:</label><br>
                <input title="{{'WEB_PAGE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.www" (ngModelChange)="organisation.www = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"EMAIL" | translate}}:</label><br>
                <input title="{{'EMAIL' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.email" (ngModelChange)="organisation.email = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"PHONE" | translate}}:</label><br>
                <input title="{{'PHONE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.phone" (ngModelChange)="organisation.phone = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"CONTACT_PERSON" | translate}}:</label><br>
                <input title="{{'CONTACT_PERSON' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.contact_person" (ngModelChange)="organisation.contact_person = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"RESPONSIBLE_CONSULTANT" | translate}}:</label><br>
                <input title="{{'RESPONSIBLE_CONSULTANT' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisation.resp_consultant" (ngModelChange)="organisation.resp_consultant = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"KAM" | translate}}:</label><br>
                <div class="flex justify-between items-center w-full relative inline-block text-left z-20" appDropdown>
                    <button type="button" class="button-white justify-between w-full items-start border border-gray-300">
                        <span class="text-sm">{{organisationMoreInfo.kam}}</span>
                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                        <div class="py-1">
                          <div *ngFor="let kamItem of kamArray" data-value="kamItem" class="dropdown-menu-item" (click)="setKam(kamItem)" tabindex="1">{{ kamItem }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <div class="flex items-center space-x-2 mt-2 mr-10">
                    <input title="{{'SMS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" type="checkbox" name="sms" [checked]="organisationMoreInfo.sms==true" (click)="checkboxclicked('sms')">
                    <span class="text-sm mr-1">{{"SMS" | translate}}</span>
                </div>
            </div>
            <div class="mb-4">
                <div class="flex items-center space-x-2 mt-2 mr-10">
                    <input title="{{'GAN' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue ml-4" type="checkbox" name="gan" [checked]="organisationMoreInfo.gan==true" (click)="checkboxclicked('gan')">
                    <span class="text-sm mr-1">{{"GAN" | translate}}</span>
                </div>
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"KONCERN" | translate}}:</label><br>
                <input title="{{'KONCERN' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisationMoreInfo.koncern" (ngModelChange)="organisationMoreInfo.koncern = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"RESELLER" | translate}}:</label><br>
                <input title="{{'RESELLER' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisationMoreInfo.reseller" (ngModelChange)="organisationMoreInfo.reseller = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"NUMBER_OF_EMPLOYEES" | translate}}:</label><br>
                <input title="{{'NUMBER_OF_EMPLOYEES' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="organisationMoreInfo.numberOfEmployees" (ngModelChange)="organisationMoreInfo.numberOfEmployees = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"MISC" | translate}}:</label><br>
                <textarea title="{{'MISC' | translate}}" class="w-full border border-gray-300 p-2" rows="5" [(ngModel)]="organisation.misc" (ngModelChange)="organisation.misc = $event" [ngModelOptions]="{standalone: true}" ng-disabled="false" maxlength="400"></textarea>
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"CHOOSE_LOGOTYPE" | translate}}:</label><br>
                <input title="{{'CHOOSE_LOGOTYPE' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" (change)="fileChange($event)" placeholder="Upload file" id="upload-file" accept=".png, .jpg, .jpeg" />
                <table *ngIf="organisation?.logo_file_id" class="table-auto w-full bg-white mb-4 mt-4 border border-gray-400">
                  <thead>
                      <tr>
                          <th class="table-row-header">{{"LOGOTYPE" | translate}}</th>
                          <th class="table-row-header"></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngIf="imageLogo">
                            <td class="table-row-column">
                                <div class="relative">
                                    <img title="logo" [src]="imageLogo" class="logo-image" />
                                </div>
                            </td>
                            <td class="table-row-column">
                                <a class="ml-2" (click)="organisation.logo_file=null;imageLogo=null">
                                <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
                                    21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
                                    5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
                                    3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
                                    stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </a>
                            </td>
                      </tr>
                  </tbody>
               </table>
              </div>
        </div>
    </div>
</div>

<div *ngIf="activeTab==1" class="mt-1 px-4 py-2 mb-4">
    <div class="flex flex-col md:flex-row">
        <div class="flex flex-col bg-white w-full rounded shadow-lg m-2 p-7 w-1/2">
            <div class="flex flex-col w-full mt-2">
                <div class="mb-4">
                    <label class="text-xl font-bold mb-1">{{"PLAN" | translate}}</label><br>
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"TYPE_OF_SUBSCRIPTION" | translate}}:</label><br>
                    <div class="flex justify-between items-center w-full relative inline-block text-left z-20" appDropdown>
                        <button type="button" class="button-white justify-between w-full items-start border border-gray-300">
                            <span class="text-sm">{{subscriptionMoreInfo.typeOfSubscription}}</span>
                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                            <div class="py-1">
                            <div *ngFor="let typeOfSubscriptionItem of typeOfSubscriptionArray" data-value="typeOfSubscriptionItem" class="dropdown-menu-item" (click)="selectPlan(typeOfSubscriptionItem)" tabindex="1">{{ typeOfSubscriptionItem }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"NUMBER_OF_CHEMICALS" | translate}}:</label><br>
                    <input title="{{'NUMBER_OF_CHEMICALS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="subscriptionMoreInfo.numberOfChemicals" (ngModelChange)="subscriptionMoreInfo.numberOfChemicals = $event" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'HGV' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" name="HGV" [checked]="subscriptionMoreInfo.HGV==true" (click)="checkboxclicked('HGV')">
                        <span class="text-sm mr-1">{{"HGV" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'NEW_CHEMICALADMINISTRATOR' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" name="HGV" [checked]="subscriptionMoreInfo.newChemicaladministrator==true" (click)="checkboxclicked('newChemicaladministrator')">
                        <span class="text-sm mr-1">{{"NEW_CHEMICALADMINISTRATOR" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"EHSCONSULT" | translate}}:</label><br>
                    <input title="{{'EHSCONSULT' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="subscriptionMoreInfo.EHSConsult" (ngModelChange)="subscriptionMoreInfo.EHSConsult = $event" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"LAWENFORCEMENT" | translate}}:</label><br>
                    <div class="flex justify-between items-center w-full relative inline-block text-left z-20" appDropdown>
                        <button type="button" class="button-white justify-between w-full items-start border border-gray-300">
                            <span class="text-sm">{{lawenforcementItem}}</span>
                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                            <div class="py-1">
                                <div *ngFor="let lawenforcementItem of lawenforcementArray" data-value="lawenforcementItem" class="dropdown-menu-item" (click)="itemChange(lawenforcementItem)" tabindex="1">{{ lawenforcementItem }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'SAMTEC' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="this.Samtec==true" (click)="oldcheckboxclicked('subscription_samtec')">
                        <span class="text-sm mr-1">{{"SAMTEC" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'ONLINE_CLOOCAST' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="this.Cloocast==true" (click)="oldcheckboxclicked('subscription_education')">
                        <span class="text-sm mr-1">{{"ONLINE_CLOOCAST" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'CDOC_DISTRIBUTION' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="this.Distributor==true" (click)="oldcheckboxclicked('subscription_distribution')">
                        <span class="text-sm mr-1">{{"CDOC_DISTRIBUTION" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'API' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.API==true" (click)="checkboxclicked('API')">
                        <span class="text-sm mr-1">{{"API" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'TRANSPORT_MODULE' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="Transport==true" (click)="oldcheckboxclicked('subscription_transport')">
                        <span class="text-sm mr-1">{{"TRANSPORT_MODULE" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'PROJECTS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="Project==true" (click)="oldcheckboxclicked('subscription_projects')">
                        <span class="text-sm mr-1">{{"PROJECTS" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'CMR_LIST' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="CMR==true" (click)="oldcheckboxclicked('subscription_report_cmr')">
                        <span class="text-sm mr-1">{{"CMR_LIST" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'RESTRICTION_LIST_SIN' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="SIN==true" (click)="oldcheckboxclicked('subscription_sin')">
                        <span class="text-sm mr-1">{{"RESTRICTION_LIST_SIN" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'SUBSCRIPTION_BASTA' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="BASTA==true" (click)="oldcheckboxclicked('subscription_basta')">
                        <span class="text-sm mr-1">{{"SUBSCRIPTION_BASTA" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'SUNDAHUS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.SundaHus==true" (click)="checkboxclicked('SundaHus')">
                        <span class="text-sm mr-1">{{"SUNDAHUS" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'SVANEN_LIST' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="Svanen==true" (click)="oldcheckboxclicked('subscription_report_enviroment')">
                        <span class="text-sm mr-1">{{"SVANEN_LIST" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'EXPOSURE_REGISTER' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="Exposures==true" (click)="oldcheckboxclicked('subscription_exposures')">
                        <span class="text-sm mr-1">{{"EXPOSURE_REGISTER" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'SECRET_CHEMICAL_SDB' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="Secret==true" (click)="oldcheckboxclicked('subscription_secret_chemical')">
                        <span class="text-sm mr-1">{{"SECRET_CHEMICAL_SDB" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'CHEMICALADMINISTRATORMODULE' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="Substitution==true" (click)="oldcheckboxclicked('subscription_substitution')">
                        <span class="text-sm mr-1">{{"CHEMICALADMINISTRATORMODULE" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"REVISION" | translate}}:</label><br>
                    <input title="{{'REVISION' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="subscriptionMoreInfo.revision" (ngModelChange)="subscriptionMoreInfo.revision = $event" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="mb-4">
                    <label class="font-bold mb-1">{{"REVISION_FOREIGN" | translate}}:</label><br>
                    <input title="{{'REVISION_FOREIGN' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="subscriptionMoreInfo.revisionForeignLanguage" (ngModelChange)="subscriptionMoreInfo.revisionForeignLanguage = $event" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'TRYGGHETSPAKET' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.trygghetspaket==true" (click)="checkboxclicked('trygghetspaket')">
                        <span class="text-sm mr-1">{{"TRYGGHETSPAKET" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'RISK_ASSESSMENT_MODULE' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="riskAssessmentModule==true" (click)="oldcheckboxclicked('subscription_risk_assessment')">
                        <span class="text-sm mr-1">{{"RISK_ASSESSMENT_MODULE" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'SEVESO_RISKASSESSMENT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.sevesoRiskassessment==true" (click)="checkboxclicked('sevesoRiskassessment')">
                        <span class="text-sm mr-1">{{"SEVESO_RISKASSESSMENT" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'PICTURES' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.pictures==true" (click)="checkboxclicked('pictures')">
                        <span class="text-sm mr-1">{{"PICTURES" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'HISTORY' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.history==true" (click)="checkboxclicked('history')">
                        <span class="text-sm mr-1">{{"HISTORY" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'M_FAKTOR_REVISION' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.MFaktorRevision==true" (click)="checkboxclicked('MFaktorRevision')">
                        <span class="text-sm mr-1">{{"M_FAKTOR_REVISION" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'GOVERNMENTREP' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.governmentRep==true" (click)="checkboxclicked('governmentRep')">
                        <span class="text-sm mr-1">{{"GOVERNMENTREP" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'CDOCSUPPORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.cdocSupport==true" (click)="checkboxclicked('cdocSupport')">
                        <span class="text-sm mr-1">{{"CDOCSUPPORT" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'YEARLYENVIRONMENTREPORT' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.yearlyEnvironmentReport==true" (click)="checkboxclicked('yearlyEnvironmentReport')">
                        <span class="text-sm mr-1">{{"YEARLYENVIRONMENTREPORT" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'SITEINSPECTOR' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="subscriptionMoreInfo.siteInspector==true" (click)="checkboxclicked('siteInspector')">
                        <span class="text-sm mr-1">{{"SITEINSPECTOR" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center space-x-2">
                        <input title="{{'PRODUCT_APPLICATION' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="productApplication==true" (click)="oldcheckboxclicked('subscription_product_application')">
                        <span class="text-sm mr-1">{{"PRODUCT_APPLICATION" | translate}}</span>
                    </div>
                </div>
                <div class="mb-4">
                  <div class="flex items-center space-x-2">
                      <input title="{{'LIST_POP' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="listPop==true" (click)="oldcheckboxclicked('subscription_list_pop')">
                      <span class="text-sm mr-1">{{"LIST_POP" | translate}}</span>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="flex items-center space-x-2">
                      <input title="{{'LIST_ROHS' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [checked]="listRohs==true" (click)="oldcheckboxclicked('subscription_list_rohs')">
                      <span class="text-sm mr-1">{{"LIST_ROHS" | translate}}</span>
                  </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col bg-white w-full rounded shadow-lg m-2 p-7 w-1/2">
            <div class="flex flex-col w-full mt-2">
                <label class="text-xl font-bold mb-1">{{"SUBSCRIPTIONS_PORTAL" | translate}}</label>
            </div>
            <div class="overflow-x-auto ">
                <table class="table-auto bg-white mb-4 w-full">
                  <thead>
                    <tr>
                      <th class="table-row-header-no-border" colspan="12">
                          <div class="flex flex-row items-center">
                              <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="subscriptionName" class="prompt" (ngModelChange)="subscriptionName = $event"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_NAME' | translate}} ..." />
                              </div>
                          </div>
                      </th>
                    </tr>
                    <tr>
                        <th class="table-row-header sort-header">{{"NAME" | translate}}</th>
                        <th class="table-row-header sort-header">
                            <div class="flex items-center space-x-2">
                                <input title="{{'NAME' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" (change)="toggleSelectAllSubscriptions()">
                                <span *ngIf="!selectAllSubscriptions" class="text-sm mr-1">{{"SELECT_ALL" | translate}}</span>
                                <span *ngIf="selectAllSubscriptions" class="text-sm mr-1">{{"DESELECT_ALL" | translate}}</span>
                            </div>
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let subscription of subscriptions  | filterBy  : ['name'] : subscriptionName;let index=index"
                            data-toggle="tooltip" title="{{subscription.description | translate}}">
                        <td class="table-row-column">{{subscription.name | translate}}</td>
                        <td class="table-row-column">
                            <div class="flex items-center space-x-2">
                                <input title="{{'NAME' | translate}}" type="checkbox" class="form-checkbox h-5 w-5 text-cdocblue" [(ngModel)]="subscription.active" [checked]="subscription.active==true"
                                    (change)="toggleSelect(subscription)" [ngModelOptions]="{standalone: true}">
                            </div>
                        </td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="activeTab==2" class="mt-1 px-4 py-2 mb-4">
    <div class="max-w-screen mr-4 mb-40 md:mb-20">
        <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
            <div class="flex flex-col md:flex-row justify-end mt-4">
                <button class="button-cdocblue mb-2" title="{{'ADD' | translate}}" (click)="AddNewUser()">
                    <svg class="w-7 h-7" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                        <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    <span class="text-lg text-white">{{ 'ADD' | translate }} {{'USER' | translate}}</span>
                </button>
            </div>
            <div class="overflow-x-auto mb-2">
                <table class="table-auto w-full bg-white mb-4 border border-gray-400 mt-4" st-table="allUsersOnOrganisation" st-safe-src="allUsersOnOrganisation" id="usertable">
                    <thead class="bg-white">
                        <tr>
                            <th class="table-row-header" colspan="12">
                                <div class="flex flex-row items-center">
                                    <div class="mt-4">
                                        <input st-search="" type="search" [(ngModel)]="userSearch.all" class="prompt"
                                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th class="table-row-header sort-header">{{"USERNAME" | translate}}</th>
                            <th class="table-row-header sort-header">{{"EMAIL" | translate}}</th>
                            <th class="table-row-header sort-header">{{"DEPARTMENT" | translate}}</th>
                            <th class="table-row-header sort-header">{{"AUTHORISATION" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of allUsersOnOrganisation
                            | filter : userSearch.all
                            | filterBy  : ['username'] : userSearch.username
                            | filterBy  : ['email'] : userSearch.email
                            | filterBy  : ['role'] : userSearch.role
                            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };"
                            (click)="editUser(user.id)" class="tablerow">
                            <td class="table-row-column">{{user.username}}</td>
                            <td class="table-row-column">{{user.email}}</td>
                            <td class="table-row-column">{{user.local_user_departments?user.local_user_departments:user.departmentName}}</td>
                            <td class="table-row-column">{{user.role}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="p-6" colspan="2">
                                <div class="relative inline-block text-left" appDropdown>
                                    <button type="button" class="button-gray">
                                        <span class=" text-sm">{{"USERS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <div id="dropdownMenu" class="hidden dropdown-menu left-40" #dropdownMenuInside>
                                        <div class="py-1">
                                            <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                                            <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                                            <a (click)="itemsPerPage=15;pageNumber=1" class="dropdown-menu-item" tabindex="3">15</a>
                                        </div>
                                        </div>
                                </div>
                            </td>
                            <td colspan="2">
                                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                                    previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="activeTab==5" class="mt-1 px-4 py-2 mb-4">
    <div class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
        <div class="flex flex-col w-full mt-2">
            <div class="mb-4">
                <label class="font-bold mb-1">{{"CONTRACT_DATE" | translate}}:</label><br>
                <input title="{{'CONTRACT_DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.contractDate | date:'yyyy-MM-dd'" (ngModelChange)="delivery.contractDate = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"CHEMICAL_COORDINATOR" | translate}}:</label><br>
                <div class="flex justify-between items-center w-full relative inline-block text-left z-20" appDropdown>
                    <button type="button" class="button-white justify-between w-full items-start border border-gray-300">
                        <span class="text-sm">{{delivery.customerCaller}}</span>
                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                        <div class="py-1">
                          <div *ngFor="let customerCalleritem of customerCallerArray" data-value="customerCalleritem" class="dropdown-menu-item" (click)="setCustomerCaller(customerCalleritem)" tabindex="1">{{ customerCalleritem }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"WELCOME_CALL_PERFORMED" | translate}}:</label><br>
                <input title="{{'WELCOME_CALL_PERFORMED' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.welcomeCallPerformed | date:'yyyy-MM-dd'" (ngModelChange)="delivery.welcomeCallPerformed = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"ORGANISATIONLIST_ARRIVED" | translate}}:</label><br>
                <input title="{{'ORGANISATIONLIST_ARRIVED' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.organisationListArrived | date:'yyyy-MM-dd'" (ngModelChange)="delivery.organisationListArrived = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"CHEMICALLIST_ARRIVED" | translate}}:</label><br>
                <input title="{{'CHEMICALLIST_ARRIVED' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.chemicalListArrived | date:'yyyy-MM-dd'" (ngModelChange)="delivery.chemicalListArrived = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"ACCOUNT_CREATE_DONE" | translate}}:</label><br>
                <input title="{{'ACCOUNT_CREATE_DONE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.accountCreatedDone | date:'yyyy-MM-dd'" (ngModelChange)="delivery.accountCreatedDone = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"LAWPOINT" | translate}}:</label><br>
                <input title="{{'LAWPOINT' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.lawpoint | date:'yyyy-MM-dd'" (ngModelChange)="delivery.lawpoint = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"SAMTEC" | translate}}:</label><br>
                <input title="{{'SAMTEC' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.samtec | date:'yyyy-MM-dd'" (ngModelChange)="delivery.samtec = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"APTOR" | translate}}:</label><br>
                <input title="{{'APTOR' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.aptor | date:'yyyy-MM-dd'" (ngModelChange)="delivery.aptor = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"LAWPOINTMAIL" | translate}}:</label><br>
                <input title="{{'LAWPOINTMAIL' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="delivery.lawpointMail" (ngModelChange)="delivery.lawpointMail = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"SETUP_DONE_DATE" | translate}}:</label><br>
                <input title="{{'SETUP_DONE_DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.setupDoneDate | date:'yyyy-MM-dd'" (ngModelChange)="delivery.setupDoneDate = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"DEVIATIONS" | translate}}:</label><br>
                <input title="{{'DEVIATIONS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="delivery.deviations" (ngModelChange)="delivery.deviations = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"COMMENTS" | translate}}:</label><br>
                <input title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="delivery.comments" (ngModelChange)="delivery.comments = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"REVISION_STARTED" | translate}}:</label><br>
                <input title="{{'REVISION_STARTED' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.revisionStarted | date:'yyyy-MM-dd'" (ngModelChange)="delivery.revisionStarted = $event" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mb-4">
                <label class="font-bold mb-1">{{"REVISION_DONE" | translate}}:</label><br>
                <input title="{{'REVISION_DONE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] = "delivery.revisionDone | date:'yyyy-MM-dd'" (ngModelChange)="delivery.revisionDone = $event" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
    </div>
</div>

<div *ngIf="activeTab==6" class="mt-1 px-4 py-2 mb-4">
    <app-customeractions [inputData]="organisation"></app-customeractions>
</div>

<div *ngIf="activeTab!==6" class="flex flex-row justify-center mb-40 mt-4">
    <button class="button-red mr-2" title="{{'REMOVE' | translate}}" (click)="removeOrganisation()">
      <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
      <span class="text-lg">{{ 'INACTIVATE' | translate }}</span>
    </button>
    <button class="button-green" title="{{'SAVE' | translate}}" (click)="saveOrganisation()">
      <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
      <span class="text-lg">{{ 'SAVE' | translate }}</span>
    </button>
</div>

