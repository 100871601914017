<div class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
  <div class="flex flex-row justify-between">
      <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
          <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
        </svg>
      </button>

      <button (click)="editNewsProfile=true" class="button-cdocblue mt-4 mr-4">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
            <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        <span class="text-white text-lg">{{"ADD" | translate}}</span>
      </button>
  </div>
</div>

<div class="flex flex-row">
  <div *ngIf="editNewsProfile" class="flex flex-col bg-white rounded shadow-lg m-4 p-7 w-full">
    <div class="flex flex-col w-full">
      <div class="mb-4">
          <label class="font-bold mb-1">{{"HEADING" | translate}}:</label><br>
          <textarea title="{{'HEADING' | translate}}" class="w-full border border-gray-300 p-2" rows="1" maxlength="60" [(ngModel)]="news_header" [disabled]="!editNewsProfile"></textarea>
      </div>
      <div class="mb-4">
        <label class="font-bold mb-1">{{"NEWS" | translate}}:</label><br>
          <textarea title="{{'NEWS' | translate}}" class="w-full border border-gray-300 p-2" rows="5" maxlength="1024" [(ngModel)]="news_body" [disabled]="!editNewsProfile"></textarea>
      </div>
    </div>
    <div class="flex flex-row justify-center mb-20 mt-4">
      <button class="button-green  mr-1" title="{{'SAVE' | translate}}" (click)="saveNews()">
        <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
        <span class="text-lg">{{ 'SAVE' | translate }}</span>
      </button>
      <button class="button-red" title="{{'CANCEL' | translate}}" (click)="cancelEditNews()">
        <span class="text-lg">{{ 'CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<div class="flex flex-col mb-20">
  <div class="flex flex-col bg-white rounded shadow-lg m-4 p-7">
    <div *ngFor="let news of currentNews | orderBy: 'news'">
      <div class="m-4">
        <span class="text-lg font-bold">{{news.news_header}}</span><br>
        <span>{{news.news_body}}</span><br>
        <div title="{{'REMOVE' | translate}} {{'NEWS' | translate}}" (click)="removeNews(news.id)">
          <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
            21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
            5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
            3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
            stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>




