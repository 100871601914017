<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
    <!-- Modal Content -->
    <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"REPORT_NOTE" | translate}}</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-4 p-2">
                <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                    <div class="loader"></div>
                </div>

                <div *ngIf="loadingData==false" class="overflow-auto mb-20">
                    <table datatable [dtOptions]="dtOptions" class="table">
                        <thead>
                            <tr>
                                <th class="table-row-header">{{"NAME" | translate}}</th>
                                <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                                <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                                <th class="table-row-header">{{"RESTRICTION_LIST_REACH_14_SHORT" | translate}}</th>
                                <th class="table-row-header">{{"RESTRICTION_LIST_REACH_17_SHORT" | translate}}</th>
                                <th class="table-row-header">Reach Kandidat</th>
                                <th class="table-row-header">{{"RESTRICTION_LIST_CHEM_PRIO_P_SHORT" | translate}}</th>
                                <th class="table-row-header">{{"RESTRICTION_LIST_CHEM_PRIO_U_SHORT" | translate}}</th>
                                <th class="table-row-header">{{"RESTRICTION_LIST_WATER_DIRECTIVE_SHORT" | translate}}</th>
                                <th class="table-row-header">{{"RESTRICTION_LIST_AMV_A_SHORT" | translate}}</th>
                                <th class="table-row-header">{{"RESTRICTION_LIST_AMV_B_SHORT" | translate}}</th>
                                <th class="table-row-header" *ngIf="subscribesToSin">{{"RESTRICTION_LIST_SIN_SHORT" | translate}}</th>
                                <th class="table-row-header" *ngIf="subscribesToSll">{{"RESTRICTION_LIST_SLL_SHORT" | translate}}</th>
                                <th class="table-row-header" *ngIf="subscribesToBasta">{{"RESTRICTION_LIST_BASTA_SHORT" | translate}}</th>
                                <th class="table-row-header" *ngIf="subscribesToPop">{{"LIST_POP_SHORT" | translate}}</th>
                                <th class="table-row-header" *ngIf="subscribesToRohs">{{"LIST_ROHS_SHORT" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of reportInformation.products">
                              <td class="table-row-column">{{product.name}}</td>
                              <td class="table-row-column">{{product.supplier_name}}</td>
                              <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                              <td class="table-row-column">
                                <span *ngIf="product.list_reach_14">X</span>
                              </td>
                              <td class="table-row-column">
                                <span class="text-red-500" *ngIf="product.list_reach_17">X</span>
                              </td>
                              <td class="table-row-column">
                                <span class="text-red-500" *ngIf="product.list_reach_candidate">X</span>
                              </td>
                              <td class="table-row-column">
                                <span class="text-red-500" *ngIf="product.list_chem_prio_p">X</span>
                              </td>
                              <td class="table-row-column">
                                <span class="text-red-500" *ngIf="product.list_chem_prio_u">X</span>
                              </td>
                              <td class="table-row-column">
                                <span class="text-red-500" *ngIf="product.list_water_directive">X</span>
                              </td>
                              <td class="table-row-column">
                                <span class="text-red-500" *ngIf="product.list_amv_a">X</span>
                              </td>
                              <td class="table-row-column">
                                <span class="text-red-500" *ngIf="product.list_amv_b">X</span>
                              </td>
                              <td class="table-row-column" *ngIf="subscribesToSin">
                                <span class="text-red-500" *ngIf="product.list_sin">X</span>
                              </td>
                              <td class="table-row-column" *ngIf="subscribesToSll">
                                <span class="text-red-500" *ngIf="product.list_sll">X</span>
                              </td>
                              <td class="table-row-column" *ngIf="subscribesToBasta">
                                <span class="text-red-500" *ngIf="product.list_BASTA">X</span>
                              </td>
                              <td class="table-row-column" *ngIf="subscribesToPop">
                                <span class="text-red-500" *ngIf="product.list_pop==1">X</span>
                              </td>
                              <td class="table-row-column" *ngIf="subscribesToRohs">
                                <span class="text-red-500" *ngIf="product.list_rohs==1">X</span>
                              </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
