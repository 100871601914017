import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilService } from "src/app/services/util.service";
import { ManufacturerService } from "src/app/services/manufacturer.service";
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: "app-manufacturer-item",
  templateUrl: "./manufacturer-item.component.html",
  styleUrls: ["./manufacturer-item.component.css"],
})
export class ManufactureritemComponent implements OnInit {
  newManufacturer: boolean;
  editManufacturerActive: boolean;
  manufacturer: any;
  itemid: string;
  currentUser: any;

  constructor(
    private router: Router,
    private previousrouter: ActivatedRoute,
    private utilService: UtilService,
    private manufacturerService: ManufacturerService,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.itemid = this.previousrouter.snapshot.queryParamMap.get("id");
    if (this.itemid && this.itemid != "new") {
      this.newManufacturer = false;
      this.editManufacturerActive = false;
      this.loadManufacturer();
    } else {
      this.newManufacturer = true;
      this.editManufacturerActive = true;
      this.manufacturer = {
        name: "",
        address_1: "",
        city: "",
        zip: "",
        country: "",
        www: "",
        email: "",
        phone: "",
        fax: "",
      };
    }
  }

  editManufacturer() {
    this.editManufacturerActive = !this.editManufacturerActive;
  }

  cancel() {
    if (!this.editManufacturerActive) {
      this.router.navigate(["/manufacturer"]);
    } else {
      this.editManufacturerActive = false;
      this.loadManufacturer();
    }
  }

  save() {
    //Backend name fix
    if ( this.manufacturer.name == "" ) {
      Swal.fire("Error", this.translate.instant("INPUT_NAME"));
      return;
    }
    this.manufacturer.homepage = this.manufacturer.www;
    if (this.newManufacturer) {
      this.manufacturerService.createManufacturerWithId(this.manufacturer);
    } else {
      this.manufacturerService.updateManufacturerWithId(
        this.itemid,
        this.manufacturer
      );
    }
  }

  loadManufacturer() {
    let object = { type: "get", id: this.itemid };
    this.manufacturerService.getSingle(object);
    this.manufacturerService.getManufacturerDataListener().pipe(take(1))
      .subscribe({
        next: (thismanufacturer) => {
          if (thismanufacturer == null) {
          } else {
            if (thismanufacturer) {
              this.manufacturer = thismanufacturer[0];
            }
          }
        },
        error: (error) => {},
      });
  }

  delete() {
    Swal.fire({
      title: this.translate.instant("REMOVE"),
      text: this.translate.instant("CONFIRM_REMOVE_MANUFACTURER"),
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
      cancelButtonText: this.translate.instant("NO"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.manufacturerService.deleteManufacturerWithId(this.itemid);
      } else if (result.isDenied) {

      }
    });
  }

  logout() {
    this.authService.logout();
  }
}
