import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import * as _ from "lodash";
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { SponsorService } from 'src/app/services/sponsor.service';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from 'src/app/auth/auth.service';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FilterByPipe } from 'ngx-pipes';
import { OrderByPipe} from 'ngx-pipes';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-customeractions',
  templateUrl: './customeractions.component.html',
  styleUrls: ['./customeractions.component.css']
})

export class CustomeractionsComponent implements OnInit {
  @Input() inputData: any;
  activeTab: any = 0;
  allUsersOnOrganisation: any = [];
  isSuperUser: boolean;
  currentUser: any;
  organisation: any = [];
  selectedOrganisation: any;
  organisationId: any;
  selectproducttoremoveform: FormGroup;
  allDepartmentNames: any;
  loadingData: boolean = true;
  lastyear;
  yearArray;
  year;
  listAlldepartments: any;
  selectedDepartment: any;
  subscribesToMailSDS: any;
  revisionList;
  revisionHistory;
  tempdrevisionList;
  path = "";


  constructor(
    private router: Router,
    private previousrouter: ActivatedRoute,
    private organisationService: OrganisationService,
    private productService: ProductService,
    private userService: UserService,
    private sponsorService: SponsorService,
    private staticDataService: StaticDataService,
    private utilService: UtilService,
    private translate: TranslateService,
    private authService: AuthService
  ) {
    this.selectproducttoremoveform = new FormGroup({

    });
  }

  ngOnInit(): void {
    var firstyear = 2000;
    this.lastyear = new Date().getFullYear()-2;
    this.yearArray=[this.lastyear];
    for(var year = this.lastyear;year >= firstyear;year--){
      if(year!=this.lastyear){
        this.yearArray.push(year);
      }

    }

    this.activeTab = JSON.parse(
      localStorage.getItem("controlPanelCustomerActionsActiveTab")
    );
    if (
      this.activeTab != 0 &&
      this.activeTab != 1 &&
      this.activeTab != 2 &&
      this.activeTab != 3
    ) {
      this.activeTab = 0;
    }

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (this.currentUser) {
      this.isSuperUser = this.currentUser.is_super_user;
      this.selectedOrganisation = this.inputData;
    }

    if (this.selectedOrganisation == null) {
      this.logout();
    }
    else
    {
      this.organisationId = this.selectedOrganisation.id;
    }
    this.loadingData = false;
  }

  logout() {
    this.authService.logout();
  }

  gotoControlPanel() {
    this.router.navigate(["/controlpanel"]);
  }

  setActiveTab(tabnumber) {
    this.activeTab = tabnumber;
    localStorage.setItem(
      "controlPanelOrganisationActiveTab",
      JSON.stringify(tabnumber)
    );
  }

  save(){
      var thisYear = this.year;
      if(typeof(this.year)=='undefined'){
        thisYear=this.lastyear
      }
      this.productService.deleteImportedProducts(thisYear,this.selectedOrganisation.id,this.selectedOrganisation.name);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: responce => {
          if (responce == null) {
            Swal.fire({
              title: "Radering av importerade produkter",
              text: "Det fanns inget att radera",
              icon: "info",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK"),
            });
          }
          else {
            Swal.fire({
              title: "Radering av importerade produkter",
              text: responce,
              icon: "info",
              showCancelButton: false,
              confirmButtonText: this.translate.instant("OK"),
            });
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
  }

  createRevision(path){
    if(path=="addtoexcel"||path=="show") {
      this.path=path
    }
    this.loadingData = true;
    if(path=="show") {
      this.revisionList = null;
    }
    var organisationId=this.selectedOrganisation.id;
    if(!organisationId||organisationId==""){return}
      this.productService.revisionList(organisationId,this.path);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: responce => {
          if (responce == null) {
            this.loadingData = false;
          } else {
            if(this.path=="addtoexcel") {
              const workbook = XLSX.utils.book_new();
              const worksheet = XLSX.utils.json_to_sheet(responce);

              // Add the worksheet to the workbook
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

              // Save the workbook as an Excel file
              XLSX.writeFile(workbook, 'exported-data.xlsx');
              this.loadingData = false;
              return;
            }
            if(this.path=="show") {
              this.revisionList = responce;
              this.tempdrevisionList = _.cloneDeep(this.revisionList);
            }
            this.path="";
            this.loadingData = false;
          }
        },
        error: error => {
          console.log("Error: ", error);
          this.loadingData = false;
        }
      })
  }

  createHistory(){
    this.loadingData = true;
    var organisationId=this.selectedOrganisation.id;
    if(!organisationId||organisationId==""){return}
      this.productService.revisionHistory(organisationId);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: responce => {
          if (responce == null) {
            this.loadingData = false;
          } else {
            this.revisionHistory = responce;
            this.loadingData = false;
          }
        },
        error: error => {
          console.log("Error: ", error);
          this.loadingData = false;
        }
      })
  }

  unselectForRevision(){
    this.path = "unmark";
    this.revisionList.forEach(element=>{
      element.ongoing_revision = 0;
    })
  }

  selectForRevision(){
    this.path = "mark";
    this.revisionList.forEach(element=>{
      element.ongoing_revision = 1;
    })
  }

  reset(){
    this.path = "";
    this.revisionList=this.tempdrevisionList;
    this.tempdrevisionList = _.cloneDeep(this.revisionList);
  }

}
