<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="mb-20">
    <div class="flex flex-col min-h-screen border border-gray-300">
        <button class="px-4 py-2 w-10" title="{{'BACK' | translate}}" (click)="cancel()">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
            <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
            </svg>
        </button>

        <div *ngIf="assessment" class="flex flex-col md:flex-row justify-between bg-cdocbanner p-2">
            <p class="text-xl font-bold px-4 text-white break-all w-1/3">{{'RISK_ASSESSMENT' | translate}} - {{assessment?.heading}}</p>
            <div class="flex flex-col px-4 text-white">
                <p *ngIf='assessment.status==0'><strong>{{'STATUS' | translate}}:</strong> &nbsp;&nbsp;{{'ONGOING' | translate}}<br></p>
                <p *ngIf='assessment.status==1'><strong>{{'STATUS' | translate}}:</strong> &nbsp;&nbsp;{{'FOR_REVIEW' | translate}}<br></p>
                <p *ngIf='assessment.status==2'><strong>{{'STATUS' | translate}}:</strong> &nbsp;&nbsp;{{'FOR_VERIFICATION' | translate}}<br></p>
                <p *ngIf='assessment.status==3'><strong>{{'STATUS' | translate}}:</strong> &nbsp;&nbsp;{{'APPROVED2' | translate}}<br></p>
                <p><strong>{{'APPROVED_BY' | translate}}:</strong> &nbsp;&nbsp;{{assessment.approved_by}}<br></p>
                <p *ngIf='assessment.substitution==1'><strong>{{'ACTION' | translate}}:</strong> &nbsp;&nbsp;{{'SUBSTITUTION' | translate}}<br></p>
                <p *ngIf='assessment.phasing_out==1'><strong>{{'ACTION' | translate}}:</strong> &nbsp;&nbsp;{{'OUT_PHASING' | translate}}<br></p>
                <p *ngIf='assessment.risk_minimzation==1'><strong>{{'ACTION' | translate}}:</strong> &nbsp;&nbsp;{{'RISK_MINIMISATION' | translate}}<br></p>
            </div>
            <button *ngIf="assessment" (click)="createARB()" [disabled]="loadingButton" class="px-4" [class.cursor-not-allowed]="loadingButton">
                <ng-container *ngIf="!loadingButton; else loading">
                    <img class="w-8 h-8 cursor-pointer" title="{{'PRINT' | translate}} {{'ACTIVITY_RISK_ASSESSMENT' | translate}}" src="images/icons/print-svgrepo-com.svg" area-hidden="true" />
                </ng-container>
                <ng-template #loading>
                    <svg class="w-8 h-8 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </ng-template>
            </button>
        </div>

        <div *ngIf="assessment" class="flex flex-col w-full py-2 px-4">
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <div class="mb-4">
                        <span *ngIf="assessment.heading?.length > 500" class="text-red-500">{{" (max 500 tecken) "}}</span>
                        <label class="font-bold mb-1">{{"HEADING" | translate}}:</label><br>
                        <input title="{{'HEADING' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="assessment.heading" (ngModelChange)="assessment.heading=$event" [ngModelOptions]="{standalone: true}" maxlength="501">
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"DATE" | translate}}:</label><br>
                        <input title="{{'DATE' | translate}}" class="w-full border border-gray-300 p-2" type="date" [ngModel] ="assessment.created_date | date:'yyyy-MM-dd'" (ngModelChange)="$event?assessment.created_date = $event:null" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div *ngIf="issuer" class="mb-4">
                        <label class="font-bold mb-1">{{"REGISTRAR" | translate}}:</label><br>
                        <input title="{{'REGISTRAR' | translate}}" class="w-full border border-gray-300 p-2" type="text" placeholder="{{issuer.first_name}} {{issuer.last_name}}" [required]="true" [disabled]="true">
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RESPONSIBLE" | translate}}:</label><br>
                        <input title="{{'RESPONSIBLE' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="assessment.responsible" (ngModelChange)="assessment.responsible=$event" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"STATUS" | translate}}:</label>
                        <div class="flex justify-between items-center w-full relative inline-block text-left z-30" appDropdown>
                            <button type="button" class="button-white justify-between w-full items-start border border-gray-300 mr-2">
                            <span *ngIf="assessment.status==0" class="text-sm">{{'ONGOING' | translate}}</span>
                            <span *ngIf="assessment.status==1" class="text-sm">{{'FOR_VERIFICATION' | translate}}</span>
                            <span *ngIf="assessment.status==2" class="text-sm">{{'FOR_VERIFICATION' | translate}}</span>
                            <span *ngIf="assessment.status==3" class="text-sm">{{'APPROVED2' | translate}}</span>
                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <div data-value="0" class="dropdown-menu-item" (click)="assessment.status=0" tabindex="1">{{'ONGOING' | translate}}</div>
                                    <div data-value="2" class="dropdown-menu-item" (click)="assessment.status=2" tabindex="2">{{'FOR_VERIFICATION' | translate}}</div>
                                    <div data-value="3" class="dropdown-menu-item" (click)="assessment.status=3" tabindex="3">{{'APPROVED2' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <span *ngIf="assessment.participants?.length > 500" class="text-red-500">{{" (max 500 tecken) "}}</span>
                        <label class="font-bold mb-1">{{"PARTICIPANTS" | translate}}:</label><br>
                        <input title="{{'PARTICIPANTS' | translate}}" class="w-full border border-gray-300 p-2" type="text" [(ngModel)]="assessment.participants" (ngModelChange)="assessment.participants=$event" [ngModelOptions]="{standalone: true}" maxlength="501">
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"DESCRIPTION" | translate}}:<span class="text-red-500" *ngIf="assessment.description?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'DESCRIPTION' | translate}}" class="w-full border border-gray-300 p-2" rows="3" [(ngModel)]="assessment.description" (ngModelChange)="assessment.description=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"ATTACHE_FILES" | translate}}:</label><br>
                        <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" accept="application/pdf" (change)="incomingfile($event)" />
                        <div *ngFor="let file of assessment.assessmentfiles; let i = index">
                            <div class="flex flex-row items-center mt-4">
                                <a class="cursor-pointer" (click)="openDocumentInNewWindow(file.file_id)">
                                    <img class="w-6 h-6 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" title="{{file.file_name}}"/> 
                                </a>
                                <span class="text-black mr-1">{{file.file_name}}</span>
                                <a (click)="fileRemove(i)">
                                    <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label class="font-bold mb-4">{{"ATTACHE_PICTURES" | translate}}:</label><br>
                        <input title="{{'ATTACHE_PICTURES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1 mb-2" type="file" id="upload-file" accept=".png, .jpg, .jpeg" (change)="incomingimage($event)" />
                        <div class="flex flex-col" *ngFor="let image of pictures; let i = index">
                            <div class="flex flex-row">
                                <a>
                                    <img title="{{'ATTACHE_PICTURES' | translate}}" [src]=image height="{{pictureHeight}}" width="{{pictureWidth}}" />
                                </a>
                                <a (click)="pictureRemove(i)">
                                    <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="assessment" class="flex flex-col w-full py-2 px-4">
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RISK_DESCRIPTION" | translate}}:<span class="text-red-500" *ngIf="assessment.general_risks?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'RISK_DESCRIPTION' | translate}}" class="w-full border border-gray-300 p-2" rows="3" [(ngModel)]="assessment.general_risks" (ngModelChange)="assessment.general_risks=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>
                </div>

                <div class="flex justify-between">
                    <p class="text-xl font-bold mt-6 px-4">{{"WORK_STEP" | translate}}</p>
                    <button *ngIf="currentUser && ((currentUser.is_local_assignee || currentUser.is_local_admin
                            || currentUser.is_admin || currentUser.is_useradmin) || currentUser.is_super_user)"
                            (click)="addWorkstep()" class="button-cdocblue mt-4">
                        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                            <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                        <span class="text-white font-bold">{{"ADD" | translate}} {{"WORK_STEP" | translate}}</span>
                    </button>
                </div>

                <div class="overflow-x-auto">
                    <table class="table-auto w-full bg-white mt-4 mb-4 border border-gray-400" id="worksteptable">
                        <thead class="bg-white">
                            <tr>
                                <th class="table-row-header">{{"HEADING" | translate}}</th>
                                <th class="table-row-header">{{"PRESENCE" | translate}}</th>
                                <th class="table-row-header">{{"DESCRIPTION" | translate}}</th>
                                <th class="table-row-header">{{"PRODUCTS" | translate}}</th>
                                <th class="table-row-header">{{"PROTECTIVE_GEAR" | translate}}</th>
                                <th class="table-row-header">{{"CONSEQUENCE" | translate}}</th>
                                <th class="table-row-header">{{"PROPABILITY" | translate}}</th>
                                <th class="table-row-header">{{"COMMENTS2" | translate}}</th>
                                <th class="table-row-header"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let workstep of assessment.worksteps; let i = index" (click)="workstepSelected(workstep, i)">
                                <td class="table-row-column">{{workstep.heading}}</td>
                                <td class="table-row-column">{{workstep.place}}</td>
                                <td class="table-row-column">{{workstep.risk_description}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-col">
                                        <ul *ngFor="let product of workstep.products" class="list-disc list-inside text-xs">
                                            <li>{{product.name}}</li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="table-row-column">
                                    <div class="flex flex-row mr-1 justify-center items-center">
                                        <span *ngFor="let protective of workstep.protectiveArray" tooltips tooltip-smart="true" title={{protective.description}}>
                                            <img class="w-6 h-6" title={{protective.description}} src="/images/protective/{{protective.filename}}-small.png" />
                                        </span>
                                    </div>
                                </td>
                                <td class="table-row-column">{{workstep.consequence}}</td>
                                <td class="table-row-column">{{workstep.propability}}</td>
                                <td class="table-row-column">{{workstep.comments}}</td>
                                <td class="table-row-column" (click)="workstepRemoved(i)">
                                    <svg class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 
                                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 
                                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 
                                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
                                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="flex justify-between">
                    <p class="text-xl font-bold mt-6 px-4">{{"CHEMICALS" | translate}}</p>
                </div>

                <div class="overflow-x-auto">
                    <table class="table-auto w-full bg-white mt-4 mb-4 border border-gray-400" id="worksteptable">
                        <thead class="bg-white">
                            <tr>
                                <th class="table-row-header">{{"NAME" | translate}}</th>
                                <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                                <th class="table-row-header">{{"RISK_LEVEL" | translate}}</th>
                                <th class="table-row-header">{{"ALLERGIC" | translate}}</th>
                                <th class="table-row-header">{{"HGV" | translate}}</th>
                                <th class="table-row-header">{{"PICTOGRAM" | translate}}</th>
                                <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of uniqueproducts; let i = index">
                                <td class="table-row-column">{{product.product_name}}</td>
                                <td class="table-row-column">{{product.supplier_name}}</td>
                                <td class="table-row-column">{{product.risk_prio}}</td>
                                <td class="table-row-column" *ngIf="product.allergic==1">{{"YES" | translate}}</td>
                                <td class="table-row-column" *ngIf="product.allergic!=1">{{"NO" | translate}}</td>
                                <td class="table-row-column" *ngIf="product.hygiene_limits==1">{{"YES" | translate}}</td>
                                <td class="table-row-column" *ngIf="product.hygiene_limits!=1&&product.no_hygiene_limits==1">{{"NO" | translate}}</td>
                                <td class="table-row-column" *ngIf="product.hygiene_limits!=1&&product.no_hygiene_limits!=1">{{"-" | translate}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-row mr-1 justify-center items-center">
                                        <span *ngFor="let pictogram of product.pictograms" tooltips tooltip-smart="true" title={{pictogram.description}}>
                                            <img class="w-6 h-6" title={{pictogram.description}} src="/images/pictograms/{{pictogram.filename}}-small.png" />
                                        </span>
                                    </div>
                                </td>
                                <td class="table-row-column">
                                    <div class="flex flex-row">
                                        <img class="-mr-3" *ngIf="subscribesToSds && ( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                                        <img class="-mr-3" *ngIf="subscribesToSds && ( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                                        <img class="-mr-3" *ngIf="subscribesToSds && ( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                                        <img *ngIf="subscribesToGrb && product.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div *ngIf="assessment" class="flex flex-col w-full py-2 px-4">
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <span class="text-lg font-bold mb-4">{{"RISK_LIMITATION" | translate}}</span>
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"VENTILATION" | translate}}:<span class="text-red-500" *ngIf="assessment.ventilation?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'VENTILATION' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.ventilation" (ngModelChange)="assessment.ventilation=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"STORAGE2" | translate}}:<span class="text-red-500" *ngIf="assessment.storage?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'STORAGE2' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.storage" (ngModelChange)="assessment.storage=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"HAND_PROTECTION" | translate}}:<span class="text-red-500" *ngIf="assessment.hand_protection?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'HAND_PROTECTION' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.hand_protection" (ngModelChange)="assessment.hand_protection=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"EYE_PROTECTION" | translate}}:<span class="text-red-500" *ngIf="assessment.eye_protection?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'EYE_PROTECTION' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.eye_protection" (ngModelChange)="assessment.eye_protection=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RESPIRATORY" | translate}}:<span class="text-red-500" *ngIf="assessment.respiratory?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'RESPIRATORY' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.respiratory" (ngModelChange)="assessment.respiratory=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"PROTECTIVE_CLOTHING" | translate}}:<span class="text-red-500" *ngIf="assessment.body_protection?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'PROTECTIVE_CLOTHING' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.body_protection" (ngModelChange)="assessment.body_protection=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"MISC" | translate}}:<span class="text-red-500" *ngIf="assessment.miscellanious?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'MISC' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.miscellanious" (ngModelChange)="assessment.miscellanious=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"EMERGENCY_PREPAREDNESS" | translate}}:<span class="text-red-500" *ngIf="assessment.incident_info?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'EMERGENCY_PREPAREDNESS' | translate}}" class="w-full border border-gray-300 p-2" rows="2" [(ngModel)]="assessment.incident_info" (ngModelChange)="assessment.incident_info=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="assessment" class="flex flex-col w-full py-2 px-4">
            <div class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full">
                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"RISK" | translate}}:</label><br>
                        <div class="static inline-block text-left w-full" id="selectRisk" appDropdown>
                            <button type="button" class="button-white w-full justify-between border border-gray-300">
                                <span class="text-sm">{{assessment?.risk}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static overflow-y-auto max-h-64 right-1 top-10" #dropdownMenuInside>
                                <div *ngFor="let i of [1, 2, 3, 4, 5]" data-value="i" class="dropdown-menu-item" (click)="assessment.risk=i" tabindex="i">{{i}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Form Container -->
            <div class="flex flex-col bg-white w-full rounded overflow-hidden shadow-lg mb-2 p-7">
                <div class="flex flex-col w-full mt-2">
                    <span class="text-lg font-bold mb-2">{{"ACTIONS" | translate}}</span>
                    <div class="flex flex-col md:flex-row mb-4">
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'SUBSTITUTION' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="substitution" [(ngModel)]="substitution" (ngModelChange)="substitution=$event" (change)="onToggleShowSubstitution()" [ngModelOptions]="{standalone: true}">
                            <span class="text-sm mr-1">{{"SUBSTITUTION" | translate}}:</span>
                        </div>
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'OUT_PHASING' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="phasing_out" [(ngModel)]="phasing_out" (ngModelChange)="phasing_out=$event" (change)="onToggleShowOutphasing()" [ngModelOptions]="{standalone: true}">
                            <span class="text-sm mr-1">{{"OUT_PHASING" | translate}}:</span>
                        </div>
                        <div class="flex items-center space-x-2 ml-4 mt-2 md:m-4">
                            <input title="{{'RISK_MINIMISATION' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="risk_minimzation" [(ngModel)]="risk_minimzation" (ngModelChange)="risk_minimzation=$event" (change)="onToggleShowRiskMinimzation()" [ngModelOptions]="{standalone: true}">
                            <span class="text-sm mr-1">{{"RISK_MINIMISATION" | translate}}:</span>
                        </div>
                    </div>

                    <div class="mb-4">
                        <label class="font-bold mb-1">{{"COMMENTS" | translate}}:<span class="text-red-500" *ngIf="assessment.comments?.length > 1024" id="texterror">{{" (max 1024 tecken) "}}</span></label><br>
                        <textarea title="{{'COMMENTS' | translate}}" class="w-full border border-gray-300 p-2" rows="3" [(ngModel)]="assessment.comments" (ngModelChange)="assessment.comments=$event" ng-disabled="false" [ngModelOptions]="{standalone: true}" maxlength="1025"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-center mb-20 mt-4">
            <button class="button-red" title="{{'REMOVE' | translate}}" (click)="remove()">
                <img title="{{'REMOVE' | translate}}" src="images/icons/trash-alt-svgrepo-com.svg" class="nav-home">
                <span class="text-white text-lg mr-2">{{ 'REMOVE' | translate }}</span>
            </button>
            <button (click)="generateARBAndSubmit()" [disabled]="loadingButton" class="button-green" [class.cursor-not-allowed]="loadingButton==true">
            <ng-container *ngIf="!loadingButton; else loading">
                <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
                <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
            </ng-container>
            <ng-template #loading>
                <svg class="w-6 h-6 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
            </ng-template>
            </button>
        </div>
    </div>

    <!-- Hidden PDF content -->
    <div #pdfContent class="pdf-content">
        <app-assessment-pdf [assessment]="assessment" [pictures]="pictures" [uniqueproducts]="uniqueproducts"></app-assessment-pdf>
        <div class="flex justify-center items-center mt-4">
            <div class="loader"></div>
        </div>
    </div>
</div>



