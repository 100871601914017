import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../auth/auth.service';
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  getReportDataUpdated = new Subject<any>();
  createReportUpdated = new Subject<any>();

  constructor(private router: Router, private http: HttpClient, private utilService: UtilService,
              private authService: AuthService, private productConversionsService: ProductConversionsService) {}

  getProducts(info) {

    let token = localStorage.getItem("token");

    if (JSON.parse(localStorage.getItem('selectedDepartment', )) == null)
    {
      this.authService.logout();
    }
    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = info;
    this.http.get<any>(BackendUrl + "/organisations/" + orgId + "/reports/" + info.reportType, { headers: headers, params: params })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.productConversionsService.convertPictogramsOnProductArray(response);
          this.productConversionsService.convertFootprintOnProductArray(response);
          this.productConversionsService.setProductArrayRiskLists(response);
          this.productConversionsService.convertClassificationOnProductArray(response);
          this.getReportDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getReportDataUpdated.next(null);
        },
    });
  }

  RegisterSub(info) {

    let token = localStorage.getItem("token");

    if (JSON.parse(localStorage.getItem('selectedDepartment', )) == null)
    {
      this.authService.logout();
    }
    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = info;

    this.http.get<any>(BackendUrl + "/organisations_k_products/" + orgId + "/sub/", { headers: headers, params: params })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.productConversionsService.convertPictogramsOnProductArray(response);
          this.productConversionsService.convertFootprintOnProductArray(response);
          this.productConversionsService.setProductArrayRiskLists(response);
          this.productConversionsService.convertClassificationOnProductArray(response);
          this.getReportDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getReportDataUpdated.next(null);
        },
    });
  }

  Register(info) {

    let token = localStorage.getItem("token");

    if (JSON.parse(localStorage.getItem('selectedDepartment', )) == null)
    {
      this.authService.logout();
    }
    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = info;

    this.http.get<any>(BackendUrl + "/organisations_k_products/" + orgId, { headers: headers, params: params })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.productConversionsService.convertPictogramsOnProductArray(response);
          this.productConversionsService.convertFootprintOnProductArray(response);
          this.productConversionsService.setProductArrayRiskLists(response);
          this.productConversionsService.convertClassificationOnProductArray(response);
          this.getReportDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getReportDataUpdated.next(null);
        },
    });
  }

  Article(info) {

    let token = localStorage.getItem("token");

    if (JSON.parse(localStorage.getItem('selectedDepartment', )) == null)
    {
      this.authService.logout();
    }
    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = info;

    this.http.get<any>(BackendUrl + "/organisations/" + orgId + "/products/subMoreInformation", { headers: headers, params: params })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.getReportDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getReportDataUpdated.next(null);
        },
    });
  }

  convertFireReport(data) {
    if (_.isObject(data.extremely)) {
      this.productConversionsService.convertPictogramsOnProductArray(data.extremely);
      this.productConversionsService.convertPhrasesOnProductArray(data.extremely);
      this.productConversionsService.convertFootprintOnProductArray(data.extremely);
      this.productConversionsService.setProductArrayRiskLists(data.extremely);
      this.productConversionsService.convertClassificationOnProductArray(data.extremely);
    }

    if (_.isObject(data.high)) {
      this.productConversionsService.convertPictogramsOnProductArray(data.high);
      this.productConversionsService.convertPhrasesOnProductArray(data.high);
      this.productConversionsService.convertFootprintOnProductArray(data.high);
      this.productConversionsService.setProductArrayRiskLists(data.high);
      this.productConversionsService.convertClassificationOnProductArray(data.high);
    }

    if (_.isObject(data.low)) {
      this.productConversionsService.convertPictogramsOnProductArray(data.low);
      this.productConversionsService.convertPhrasesOnProductArray(data.low);
      this.productConversionsService.convertFootprintOnProductArray(data.low);
      this.productConversionsService.setProductArrayRiskLists(data.low);
      this.productConversionsService.convertClassificationOnProductArray(data.low);
    }

    if (_.isObject(data.oxidizing)) {
      this.productConversionsService.convertPictogramsOnProductArray(data.oxidizing);
      this.productConversionsService.convertPhrasesOnProductArray(data.oxidizing);
      this.productConversionsService.convertFootprintOnProductArray(data.oxidizing);
      this.productConversionsService.setProductArrayRiskLists(data.oxidizing);
      this.productConversionsService.convertClassificationOnProductArray(data.oxidizing);
    }

    if (_.isObject(data.gases)) {
      this.productConversionsService.convertPictogramsOnProductArray(data.gases);
      this.productConversionsService.convertPhrasesOnProductArray(data.gases);
      this.productConversionsService.convertFootprintOnProductArray(data.gases);
      this.productConversionsService.setProductArrayRiskLists(data.gases);
      this.productConversionsService.convertClassificationOnProductArray(data.gases);
    }

    return data;
}

  getFireProducts(info) {

    let token = localStorage.getItem("token");

    if (JSON.parse(localStorage.getItem('selectedDepartment', )) == null)
    {
      this.authService.logout();
    }
    var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;

    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });

    let params = new HttpParams();
    params = info;

    this.http
      .get<any>(BackendUrl + "/organisations/" + orgId + "/reports/fire", { headers: headers, params: params })
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.convertFireReport(response);
          this.getReportDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getReportDataUpdated.next(null);
        },
    });
  }

  getStyrenReport(ids,date) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      let params = new HttpParams();
      params = params.append("data", ids);
      params = params.append("date", date);

      this.http
        .get(BackendUrl + "/organisations/" + orgId + "/reports/getstyrenreport", {
          headers: headers,
          params: params
        })
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.productConversionsService.convertPictogramsOnProductArray(response);
            this.productConversionsService.convertFootprintOnProductArray(response);
            this.productConversionsService.setProductArrayRiskLists(response);
            this.productConversionsService.convertClassificationOnProductArray(response);
            this.getReportDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getReportDataUpdated.next(null);
          },
        });
    }
  }

  getSareqHistoryReport(date) {
    let token = localStorage.getItem("token");

    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }

      const headers = new HttpHeaders({
        Accept: "application/json",
        Authorization: token,
      });

      let params = new HttpParams();
      params = params.append("date", date);

      this.http
        .get(BackendUrl + "/organisations/" + orgId + "/reports/getsareqhistoryreport", {
          headers: headers,
          params: params
        })
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.productConversionsService.convertPictogramsOnProductArray(response);
            this.productConversionsService.convertFootprintOnProductArray(response);
            this.productConversionsService.setProductArrayRiskLists(response);
            this.productConversionsService.convertClassificationOnProductArray(response);
            this.productConversionsService.convertProtectionOnProduct(response);
            this.getReportDataUpdated.next(response);
          },
          error: (error) => {
            if (error.status === 401) {
              this.authService.logout();
            }
            this.getReportDataUpdated.next(null);
          },
        });
    }
  }

  public getReportDataListener() {
    return this.getReportDataUpdated.asObservable();
  }
}
