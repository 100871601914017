import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ReportService } from 'src/app/services/report.service';
import { ProductService } from 'src/app/services/product.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { SubstitutionService } from 'src/app/services/substitution.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-report-sareq-history',
  templateUrl: './report-sareq-history.component.html',
  styleUrls: ['./report-sareq-history.component.css']
})
export class ReportSareqHistoryComponent implements OnInit {

  dtOptions: any = {};

  @Input() reportInfo;

  itemsPerPage: number = 10;
  pageNumber: number = 1;
  reportInformation: any = [];
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  currentLanguage: any;
  subscribesToClassification: any;
  subscribesToTransport: any;
  listAlldepartments: any;
  loadingData: boolean = false;
  styrenproducts: any = [];
  tempstyrenproducts: any = [];
  departMents: any = [];
  protectiveImages = [
    { name: "image_mask", filename: "mask" },
    { name: "image_boots", filename: "boots" },
    { name: "image_breath", filename: "breath" },
    { name: "image_clothes", filename: "clothes" },
    { name: "image_gloves", filename: "gloves" },
    { name: "image_glasses", filename: "glasses" },
  ];

  reportSearch = {
    all:"",
  };

  styrenSearch = {
    all: "",
  };

  showSubdepartments:boolean=false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private utilService: UtilService,
              private authService: AuthService,
              private reportService: ReportService,
              private productService: ProductService,
              private translate: TranslateService,
              private organisationService: OrganisationService,
              private substitutionService: SubstitutionService,
              public activeModal: NgbActiveModal,
              private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.reportInformation = {
      start_date: new Date("2015 1 January"),
      date: new Date(),
      reportType: "",
      name: "",
      id: "",
      includeSubDepartments: false,
      registerProducts: false,
      product: false
    };

    this.reportInformation = this. reportInfo;

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToClassification = this.getSubscription('subscription_classification');
    this.subscribesToTransport = this.getSubscription('subscription_transport');


    this.currentLanguage = localStorage.getItem('usedLanguage');
    switch(this.currentLanguage) {
      case 'sv':
        this.currentLanguage = 0;
      break;

      case 'en':
        this.currentLanguage = 1;
      break;

      case 'fi':
        this.currentLanguage = 2;
      break;

      default:
        this.currentLanguage = 0;
      break;
    }

    if (this.reportInformation) {
      var result="";
      if (typeof this.reportInformation.date === 'object' && this.reportInformation.date !== null && 'toLocaleDateString' in this.reportInformation.date) {
        result = this.reportInformation.date.toLocaleDateString('sv');
      } else {
        result = this.reportInformation.date;
        this.reportInformation.date=new Date(this.reportInformation.date);
      }
      if(this.currentLanguage=='sv'||this.currentLanguage===0) {
        this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        order:[ 0, 'desc' ],
        lengthMenu: [10,25,50,100],
        dom: 'Blfrtip',
        language:{"sEmptyTable": "Ingen data hittades",
        "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_",
        "sInfoEmpty": "Visar 0 till 0 av totalt 0",
        "sInfoFiltered": "(filtrerat från totalt _MAX_)",
        "sLoadingRecords": "Laddar...",
        "sSearch": "Filtrera:",
        "sLengthMenu": "Visa _MENU_ rader",
        "sZeroRecords": "Inga poster hittades",
        "oPaginate": {
            "sFirst": "Första",
            "sLast": "Sista",
            "sNext": "Nästa",
            "sPrevious": "Föregående"}
        },
        buttons: [
          {
            extend: 'colvis',
            text: this.translate.instant("CHOOSE_COLUMNS")
          },
          {
            extend: 'print',
            text: this.translate.instant("PRINT"),
            title: this.translate.instant(this.reportInformation.reportType.name),
            messageTop: "Datum: " + result,
            exportOptions: {
                stripHtml: false,
                columns: ':visible',
            }
          },
          {
            title: "report",
            extend: "excel",
            exportOptions: {
                columns: ':visible'
            }
          }
        ]
      };
    } else {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthMenu: [10,25,50,100],
        dom: 'Blfrtip',
        buttons: [
          {
            extend: 'colvis',
            text: this.translate.instant("CHOOSE_COLUMNS")
          },
          {
            extend: 'print',
            text: this.translate.instant("PRINT"),
            title: this.translate.instant(this.reportInformation.reportType.name),
            messageTop: "Datum: " + result,
            exportOptions: {
                stripHtml: false,
                columns: ':visible',
            }
          },
          {
            title: "report",
            extend: "excel",
            exportOptions: {
                columns: ':visible'
            }
          }
        ]
      };
    }
  }

  this.getReport();
  }

  getReport() {
    this.loadingData = true;
    if (this.reportInfo) {
      var reportDate = new Date(this.reportInfo.date);
      reportDate = this.utilService.adjustDateForTimezoneOffset(reportDate);
      var info = {
        reportType: this.reportInfo.reportType.url,
        start_date: reportDate,
        date: reportDate,
        sub: false,
        actual: this.reportInfo.Actual
      }
      this.reportInformation.products=[];
      this.showSubdepartments=false;


      this.reportService.getSareqHistoryReport(reportDate);
      this.reportService
      .getReportDataListener()
      .pipe(take(1))
      .subscribe({
        next: sareqhistoryproducts => {
          if (sareqhistoryproducts == null) {
            this.loadingData = false;
          }
          else {
            if (sareqhistoryproducts == null || sareqhistoryproducts.length == 0) {
              this.loadingData = false;
            } else {
                this.reportInformation.products = sareqhistoryproducts;
                this.loadingData = false;
            }
          }
        },
        error: error => {
          console.log("Error: ", error);
          Swal.fire({
            title: this.translate.instant("NO_PRODUCTS"),
            text: this.translate.instant("NO_PRODUCTS_WARNING"),
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.translate.instant("OK"),
            closeOnConfirm: true
          });
          this.loadingData = false;
        }
      })
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
  }

  remove() {
    this.activeModal.close(ReportSareqHistoryComponent);
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  protectiveInSareq(name,item) {
    if (item && !item.no_protection) {
      if (item[name]==1) {
        return true;
      }
      return false;
    }
  }

  getStatus(status){
    var status_text;
      switch (status) {
        case 0:
          status_text = this.translate.instant("SAREQ_STATUS_0");
          return status_text;
          break;

        case 1:
          status_text = this.translate.instant("SAREQ_STATUS_1");
          return status_text;
          break;

        case 2:
          status_text = this.translate.instant("SAREQ_STATUS_2");
          return status_text;
          break;

        case 3:
          status_text = this.translate.instant("SAREQ_STATUS_3");
          return status_text;
          break;

        case 4:
          status_text = this.translate.instant("SAREQ_STATUS_4");
          return status_text;
          break;

        case 5:
          status_text = this.translate.instant("SAREQ_STATUS_5");
          return status_text;
          break;
        default:
          status_text = "";
          return status_text;
          break;
    }
  }
}

