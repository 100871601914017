<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 w-full z-20" id="my-modal">
    <!-- Modal Content -->
    <div class="relative mx-auto p-5 border shadow-lg overflow-x-auto w-full rounded-md bg-white max-h-screen overflow-auto">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CLOSE' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{"REPORT_K_SIMPLIFIED" | translate}}</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-4 p-2">
                <div *ngIf="loadingData==true" class="flex justify-center items-center mt-4 border border-gray-200">
                    <div class="loader"></div>
                </div>
  
                <div *ngIf="loadingData==false" class="overflow-auto mb-20">
                    <table datatable [dtOptions]="dtOptions" class="table">
                        <thead>
                            <tr>
                                <th class="table-row-header">{{"SUPPLIER" | translate}}</th>
                                <th class="table-row-header">{{"NAME" | translate}}</th>
                                <th class="table-row-header">{{"DEPARTMENT" | translate}}</th>
                                <th class="table-row-header">{{"AMOUNT_ON_DEPARTMENT" | translate}}</th>
                                <th class="table-row-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                                <th class="table-row-header">{{"UNIT" | translate}}</th>
                                <th class="table-row-header">{{"COMMON_USE" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of reportInformation.products">
                                <td class="table-row-column">{{product.supplier_name}}</td>
                                <td class="table-row-column">{{product.name}}</td>
                                <td class="table-row-column">{{departFullName(product.departmentid)}}</td>
                                <td class="table-row-column">{{product.amount_in_stock}}</td>
                                <td class="table-row-column">{{product.estimated_use_per_year}}</td>
                                <td class="table-row-column">{{product.unit}}</td>
                                <td class="table-row-column">{{product.description}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
