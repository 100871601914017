<div class="container mx-auto px-4 mt-2">
    <ul class="flex flex-col md:flex-row mb-2">
      <li class="mr-1">
          <a (click)="showUserProfile()" [class.active]="isUserProfile"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"PROFILE" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_local_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showCompanyUsers()" [class.active]="isCompanyUsers"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"USERS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showDepartmentSetup()" [class.active]="isDepartmentSetup"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DEPARTMENT_SETUP" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showGroups()" [class.active]="isGroups"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"PRODUCT_GROUPS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showCompanyInfo()" [class.active]="isCompanyInfo"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"COMPANY_INFO" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showArticles()" [class.active]="isArticles"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"ARTICLES" | translate}}</a>
      </li>
      <li *ngIf="subscription_stamped_SDS && subscribesToFreemium==false && currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user || currentUser.is_maintenance_user)" class="mr-1">
        <a (click)="showStampedSds()" [class.active]="isStampedSds"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SUBSCRIPTION_STAMPED_SDS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showMiscInfo()" [class.active]="isMisc"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"MISC" | translate}}</a>
      </li>
    </ul>
</div>

<div class="mr-4 mb-20 max-w-screen mb-40 md:mb-20" *ngIf="isDepartmentSetup">
    <div class="flex flex-row justify-end mb-4">
        <button class="button-cdocblue" title="{{'ADD_DEPARTMENT' | translate}}" (click)="showAddDepartmentModal(organisation)">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          <span class="text-lg text-white">{{ 'ADD_DEPARTMENT' | translate }}</span>
        </button>
    </div>
    <div *ngIf="organisation" class="flex flex-col md:flex-row px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
        <div class="flex flex-col w-full md:w-1/2 px-7 py-2">
            <div (dragstart)="onDragStart($event, organisation)" id="organisation" (drop)="onDrop($event, organisation)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">
                <span class="text-xl font-bold underline">{{organisation.name}}</span>
            </div>
            <div *ngFor="let department of allDepartments">
                <div [ngSwitch]="department.level">
                    <div draggable="true" (click)="selectDep(department)"  (dragstart)="onDragStart($event, department)" id="department" (drop)="onDrop($event, department)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">
                        <div class="flex flex-row justify-between items-center cursor-pointer text-lg ml-4" *ngSwitchCase="1" [class.showorhidetreeitem]="showOrHideTreeItem(department)">
                            <div>
                                <i (click)="displayTreeItem(department)" [class]="hasChildren(department).split('|')[0]" [id]="hasChildren(department).split('|')[1]"></i>
                                - {{department.name}}
                            </div>
                            <div class="flex flex-row items-center">
                                <span class="cursor-pointer" (click)="showAddDepartmentModal(department)">
                                    <svg class="w-5 h-5" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Edit / Add_Plus">
                                            <path id="Vector" d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#2185D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg> 
                                </span>
                                <span class="cursor-pointer" (click)="showEditDepartmentModal(department)">
                                    <img class="w-4 h-4" title="{{'EDIT' | translate}}" src="/images/icons/edit-svgrepo-com.svg"/>
                                </span>
                                <span class="cursor-pointer" (click)="removeDepartment(department)">
                                    <svg class="w-4 h-4 ml-1" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 
                                        3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 
                                        6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 
                                        20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6" stroke="#DB2828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div draggable="true" (click)="selectDep(department)" (dragstart)="onDragStart($event, department)" id="department" (drop)="onDrop($event, department)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">
                        <div class="flex flex-row justify-between items-center cursor-pointer text-lg ml-8" *ngSwitchCase="2" [class.showorhidetreeitem]="showOrHideTreeItem(department)">
                            <div>
                                <i (click)="displayTreeItem(department)" [class]="hasChildren(department).split('|')[0]" [id]="hasChildren(department).split('|')[1]"></i>
                                -- {{department.name}}
                            </div>
                            <div class="flex flex-row items-center">
                                <span class="cursor-pointer" (click)="showAddDepartmentModal(department)">
                                    <svg class="w-5 h-5" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Edit / Add_Plus">
                                            <path id="Vector" d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#2185D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg> 
                                </span>
                                <span class="cursor-pointer" (click)="showEditDepartmentModal(department)">
                                    <img class="w-4 h-4" title="{{'EDIT' | translate}}" src="/images/icons/edit-svgrepo-com.svg"/>
                                </span>
                                <span class="cursor-pointer" (click)="removeDepartment(department)">
                                    <svg class="w-4 h-4 ml-1" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 
                                        3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 
                                        6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 
                                        20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6" stroke="#DB2828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div draggable="true" (click)="selectDep(department)" (dragstart)="onDragStart($event, department)" id="department" (drop)="onDrop($event, department)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">
                        <div class="flex flex-row justify-between items-center cursor-pointer text-lg ml-12" *ngSwitchCase="3" [class.showorhidetreeitem]="showOrHideTreeItem(department)">
                            <div>
                                <i (click)="displayTreeItem(department)" [class]="hasChildren(department).split('|')[0]" [id]="hasChildren(department).split('|')[1]"></i>
                                --- {{department.name}}
                            </div>
                            <div class="flex flex-row items-center">
                                <span class="cursor-pointer" (click)="showAddDepartmentModal(department)">
                                    <svg class="w-5 h-5" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Edit / Add_Plus">
                                            <path id="Vector" d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#2185D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg> 
                                </span>
                                <span class="cursor-pointer" (click)="showEditDepartmentModal(department)">
                                    <img class="w-4 h-4" title="{{'EDIT' | translate}}" src="/images/icons/edit-svgrepo-com.svg"/>
                                </span>
                                <span class="cursor-pointer" (click)="removeDepartment(department)">
                                    <svg class="w-4 h-4 ml-1" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 
                                        3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 
                                        6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 
                                        20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6" stroke="#DB2828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div draggable="true" (click)="selectDep(department)" (dragstart)="onDragStart($event, department)" id="department" (drop)="onDrop($event, department)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">
                        <div class="flex flex-row justify-between items-center cursor-pointer text-lg ml-16" *ngSwitchCase="4" [class.showorhidetreeitem]="showOrHideTreeItem(department)">
                            <div>
                                <i (click)="displayTreeItem(department)" [class]="hasChildren(department).split('|')[0]" [id]="hasChildren(department).split('|')[1]"></i>
                                ---- {{department.name}}
                            </div>
                            <div class="flex flex-row items-center">
                                <span class="cursor-pointer" (click)="showAddDepartmentModal(department)">
                                    <svg class="w-5 h-5" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Edit / Add_Plus">
                                            <path id="Vector" d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#2185D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg> 
                                </span>
                                <span class="cursor-pointer" (click)="showEditDepartmentModal(department)">
                                    <img class="w-4 h-4" title="{{'EDIT' | translate}}" src="/images/icons/edit-svgrepo-com.svg"/>
                                </span>
                                <span class="cursor-pointer" (click)="removeDepartment(department)">
                                    <svg class="w-4 h-4 ml-1" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 
                                        3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 
                                        6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 
                                        20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6" stroke="#DB2828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div draggable="true" (dragstart)="onDragStart($event, department)" id="department" (drop)="onDrop($event, department)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">
                        <div class="flex flex-row justify-between items-center cursor-pointer text-lg ml-20" *ngSwitchCase="5" [class.showorhidetreeitem]="showOrHideTreeItem(department)">
                            <div>
                                <i (click)="displayTreeItem(department)" [class]="hasChildren(department).split('|')[0]" [id]="hasChildren(department).split('|')[1]"></i>
                                ----- {{department.name}}
                            </div>
                            <div class="flex flex-row items-center">
                                <span class="cursor-pointer" (click)="showAddDepartmentModal(department)">
                                    <svg class="w-5 h-5" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Edit / Add_Plus">
                                            <path id="Vector" d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#2185D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg> 
                                </span>
                                <span class="cursor-pointer" (click)="showEditDepartmentModal(department)">
                                    <img class="w-4 h-4" title="{{'EDIT' | translate}}" src="/images/icons/edit-svgrepo-com.svg"/>
                                </span>
                                <span class="cursor-pointer" (click)="removeDepartment(department)">
                                    <svg class="w-4 h-4 ml-1" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 
                                        3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 
                                        6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 
                                        20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6" stroke="#DB2828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="selectedDepartment2&&selectedDepartment2.name" class="flex flex-col grow-0 w-full md:w-1/2 px-2 py-2 mt-4">
            <span class="text-lg text-center font-bold">{{selectedDepartment2.name}}</span>
            <hr>
            <span class="font-bold mt-4">{{"GENERAL" | translate}}</span>
            <p class="break-words">{{selectedDepartment2.misc}}</p>
            <span class="font-bold mt-2">{{"DOCUMENT" | translate}}</span>
            <span *ngFor="let file of departmentFiles" (click)="openDepartmentDocumentInNewWindow(file.id)">
                <div class="flex flex-row items-center">
                    <img class="w-6 h-6 cursor-pointer" title="{{'DOCUMENT' | translate}}" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                    <p class="break-words truncate">{{file.filename}}</p>
                </div>
            </span>
            <span class="font-bold mt-2">{{"ADDRESS" | translate}}</span>
            <p>{{selectedDepartment2.address_visit}}</p>
        </div>
    </div>
</div>



