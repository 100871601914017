<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-20" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-1/3 shadow-lg rounded-md bg-white mb-20">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold text-lg">{{"CHOOSE_ORGANISATION" | translate}}</div>
            <!-- Modal Body -->
            <div class="mt-2">
                <table class="table-auto bg-white mb-4 w-full" st-table="orgList" st-safe-src="orgList">
                    <thead>
                        <tr>
                            <th class="table-row-header no-sort">
                                <div class="mb-4 mt-4">
                                    <input st-search="" type="search" [(ngModel)]="userSearch.name"
                                        class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th class="table-row-header" st-sort="name" class="sort-header">{{"NAME" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let organisation of organisations
                            | filterBy  : ['name'] : userSearch.name
                            | orderBy : ['name']
                            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };"
                            (click)="select(organisation)" class="tablerow hover:bg-gray-200" id="modalSelectOrganisationButton2">
                            <td class="table-row-column">{{organisation.name}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <div class="mt-4">
                                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                                previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                            </div>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
