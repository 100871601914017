<div class="container mx-auto px-4 mt-2">
    <ul class="flex flex-col md:flex-row mb-2">
      <li class="mr-1">
          <a (click)="showUserProfile()" [class.active]="isUserProfile"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"PROFILE" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_local_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showCompanyUsers()" [class.active]="isCompanyUsers"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"USERS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showDepartmentSetup()" [class.active]="isDepartmentSetup"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DEPARTMENT_SETUP" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showGroups()" [class.active]="isGroups"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"PRODUCT_GROUPS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showCompanyInfo()" [class.active]="isCompanyInfo"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"COMPANY_INFO" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showArticles()" [class.active]="isArticles"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"ARTICLES" | translate}}</a>
      </li>
      <li *ngIf="subscription_stamped_SDS && subscribesToFreemium==false && currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user || currentUser.is_maintenance_user)" class="mr-1">
        <a (click)="showStampedSds()" [class.active]="isStampedSds"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SUBSCRIPTION_STAMPED_SDS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showMiscInfo()" [class.active]="isMisc"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"MISC" | translate}}</a>
      </li>
    </ul>
</div>

<div class="flex flex-col md:flex-row max-w-screen m-4 mb-40 md:mb-20" *ngIf="isCompanyInfo">
    <div *ngIf="organisation && subscribesToFreemium==false" class="flex w-full md:w-1/3">
      <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
        <div class="flex flex-col w-full mt-2">
          <div class="mb-4">
            <label class="font-bold mb-1">{{"COMPANY" | translate}}:</label><br>
            <input title="{{'COMPANY' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.name" (ngModelChange)="organisation.name = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"ORGANISATION_NUMBER" | translate}}:</label><br>
            <input title="{{'ORGANISATION_NUMBER' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.org_nr" (ngModelChange)="organisation.org_nr = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"ADDRESS" | translate}}:</label><br>
            <input title="{{'ADDRESS' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.address_1" (ngModelChange)="organisation.address_1 = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"ZIP" | translate}}:</label><br>
            <input title="{{'ZIP' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.zip" (ngModelChange)="organisation.zip = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"CITY" | translate}}:</label><br>
            <input title="{{'CITY' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.city" (ngModelChange)="organisation.city = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"COUNTRY" | translate}}:</label><br>
            <input title="{{'COUNTRY' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.country" (ngModelChange)="organisation.country = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"WEB_PAGE" | translate}}:</label><br>
            <input title="{{'WEB_PAGE' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.www" (ngModelChange)="organisation.www = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"EMAIL" | translate}}:</label><br>
            <input title="{{'EMAIL' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.email" (ngModelChange)="organisation.email = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
          <div class="mb-4">
            <label class="font-bold mb-1">{{"PHONE" | translate}}:</label><br>
            <input title="{{'PHONE' | translate}}" class="w-full border border-gray-300 p-2" autocomplete="none" [(ngModel)]="organisation.phone" (ngModelChange)="organisation.phone = $event" [ngModelOptions]="{standalone: true}" type="text">
          </div>
        </div>
        <div class="flex flex-row justify-center mb-20 mt-4">
            <button class="button-green" title="{{'SAVE' | translate}}" (click)="save()">
              <img title="icon" src="images/icons/save-svgrepo-com.svg" class="nav-home">
              <span class="text-lg">{{ 'SAVE' | translate }}</span>
            </button>
          </div>
      </div>
    </div>
    <div *ngIf="organisation && subscribesToFreemium==false" class="flex w-full md:w-1/3">
        <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
            <input class="w-full border border-gray-300 p-2 mb-2" type="file" (change)="fileChange($event)" placeholder="Upload file" id="upload-file" accept=".pdf">
            <span class="mt-1" *ngFor="let file of companyFiles">
                <div class="flex flex-row items-center">
                    <img  (click)="openDocumentInNewWindow(file.id)" class="w-6 h-6 cursor-pointer" title="{{'DOCUMENT' | translate}}" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                    <p class="break-words truncate">{{file.filename}}</p>
                    <span class="cursor-pointer" (click)="removeDocument(file.id)">
                        <svg class="w-4 h-4 ml-1" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376
                            3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8
                            6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803
                            20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6" stroke="#DB2828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                </div>
            </span>
        </div>
      </div>
    <div class="flex w-full md:w-1/3">
        <div class="px-6 py-4 md:ml-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
          <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="cataloguetable">
            <thead class="bg-white">
                <tr>
                  <th class="table-row-header">{{"ACTIVE_SUBSCRIPTIONS" | translate}}</th>
                  <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="organisation.subscription_activities">
                    <td class="table-row-column">{{ "SUBSCRIPTION_ACTIVITIES" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'USERADMIN' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_app">
                    <td class="table-row-column">{{ "SUBSCRIPTION_APP" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_APP' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_catalogue">
                    <td class="table-row-column">{{ "SUBSCRIPTION_CATALOGUE" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_CATALOGUE' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_classification">
                    <td class="table-row-column">{{ "SUBSCRIPTION_CLASSIFICATION" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_CLASSIFICATION' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_coordinator">
                    <td class="table-row-column">{{ "SUBSCRIPTION_COORDINATOR" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_COORDINATOR' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_distribution">
                    <td class="table-row-column">{{ "SUBSCRIPTION_DISTRIBUTION" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_DISTRIBUTION' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_exposures">
                    <td class="table-row-column">{{ "SUBSCRIPTION_EXPOSURES" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_EXPOSURES' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_grb">
                    <td class="table-row-column">{{ "SUBSCRIPTION_GRB" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_GRB' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_lawpoint">
                    <td class="table-row-column">{{ "SUBSCRIPTION_LAWPOINT" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_LAWPOINT' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_products">
                    <td class="table-row-column">{{ "SUBSCRIPTION_PRODUCTS" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_PRODUCTS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_productsheet">
                    <td class="table-row-column">{{ "SUBSCRIPTION_PRODUCTSHEET" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_PRODUCTSHEET' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_projects">
                    <td class="table-row-column">{{ "SUBSCRIPTION_PROJECTS" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_PROJECTS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_reports">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORTS" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORTS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_classification">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_CLASSIFICATION" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_CLASSIFICATION' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_cmr">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_CMR" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_CMR' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_fire">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_FIRE" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_FIRE' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_grb">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_GRB" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_GRB' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_notes">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_NOTES" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_NOTES' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_sds">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_SDS" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_SDS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_simple">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_SIMPLE" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_SIMPLE' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_statutory">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_STATUTORY" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_STATUTORY' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_sds">
                    <td class="table-row-column">{{ "SUBSCRIPTION_SDS" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_SDS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_enviroment">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_ENVIROMENT" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_ENVIROMENT' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_sds_activity">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_SDS_ACTIVITY" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_SDS_ACTIVITY' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_transport">
                    <td class="table-row-column">{{ "SUBSCRIPTION_TRANSPORT" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_TRANSPORT' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_special_demands">
                    <td class="table-row-column">{{ "SUBSCRIPTION_SPECIAL_DEMANDS" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_SPECIAL_DEMANDS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_sll">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_SLL" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_SLL' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_labels">
                    <td class="table-row-column">{{ "SUBSCRIPTION_LABELS" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_LABELS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_freemium">
                    <td class="table-row-column">{{ "SUBSCRIPTION_FREEMIUM" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_FREEMIUM' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_risk_assessment">
                    <td class="table-row-column">{{ "SUBSCRIPTION_RISK_ASSESSMENT" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_RISK_ASSESSMENT' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_substitution">
                    <td class="table-row-column">{{ "SUBSCRIPTION_SUBSTITUTION" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_SUBSTITUTION' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_samtec">
                    <td class="table-row-column">{{ "SUBSCRIPTION_Samtec" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_Samtec' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_revise_date">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REVISE_DATE" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REVISE_DATE' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_qrcode">
                    <td class="table-row-column">{{ "SUBSCRIPTION_QRCODE" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_QRCODE' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_secret_chemical">
                    <td class="table-row-column">{{ "SUBSCRIPTION_SECRET_CHEMICAL" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_SECRET_CHEMICAL' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_stamped_SDS">
                    <td class="table-row-column">{{ "SUBSCRIPTION_STAMPED_SDS" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_STAMPED_SDS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_sin">
                    <td class="table-row-column">{{ "SUBSCRIPTION_SIN" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_SIN' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_sll">
                    <td class="table-row-column">{{ "SUBSCRIPTION_SLL" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_SLL' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_basta">
                    <td class="table-row-column">{{ "SUBSCRIPTION_BASTA" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_BASTA' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_report_allergenic">
                    <td class="table-row-column">{{ "SUBSCRIPTION_REPORT_ALLERGENIC" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_REPORT_ALLERGENIC' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_footprint">
                    <td class="table-row-column">{{ "SUBSCRIPTION_FOOTPRINT" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_FOOTPRINT' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_education">
                    <td class="table-row-column">cDoc Online Academy
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="cDoc Online Academy" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_product_application">
                    <td class="table-row-column">{{ "SUBSCRIPTION_PRODUCT_APPLICATION" | translate }}
                    <td class="table-row-column">
                        <img class="w-8 h-8" title="{{'SUBSCRIPTION_PRODUCT_APPLICATION' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                    </td>
                </tr>
                <tr *ngIf="organisation.subscription_list_pop">
                  <td class="table-row-column">{{ "SUBSCRIPTION_LIST_POP" | translate }}
                  <td class="table-row-column">
                      <img class="w-8 h-8" title="{{'SUBSCRIPTION_LIST_POP' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                  </td>
              </tr>
              <tr *ngIf="organisation.subscription_list_rohs">
                <td class="table-row-column">{{ "SUBSCRIPTION_LIST_ROHS" | translate }}
                <td class="table-row-column">
                    <img class="w-8 h-8" title="{{'SUBSCRIPTION_LIST_ROHS' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
      </div>
</div>

