import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { MaterialListService } from 'src/app/services/material-list.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import * as _ from 'lodash';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SupportService } from 'src/app/services/support.service';
import { PdfService } from 'src/app/services/pdf.service';
declare var $: any;

@Component({
  selector: 'app-edit-assessment',
  templateUrl: './edit-assessment.component.html',
  styleUrls: ['./edit-assessment.component.css']
})
export class EditAssessmentComponent implements OnInit {
  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;

  itemsPerPage:number = 10;
  pageNumber:number = 1;
  currentUser: any;
  project: any;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  listAlldepartments: any;
  created_date: any = 0;
  assessment: any;
  prevAssessment: any;
  substitution: boolean = false;
  phasing_out: boolean = false;
  risk_minimzation: boolean = false;
  products: any = [];
  uniqueproducts: any = [];
  assessmentfile: any;
  filenames: any = [];
  assessmentimage: any;
  picturenames: any = [];
  subscribesToSds: any;
  subscribesToGrb: any;
  assessmentId: any;
  pictograms: any;
  loadingData: boolean = false;
  loadingButton: boolean = false;
  protectiveArray: any = [];
  picture: any;
  pictures: any = [];
  pictures2: any = [];
  departmentProducts; any;
  riskfile: any = "";
  issuer: any = null;
  pictureHeight = 200;
  pictureWidth = 260;
  protectiveImages = [{"name": "image_mask", "filename": "mask"}, {"name": "image_boots", "filename": "boots"}, {"name": "image_breath", "filename": "breath"}, {"name": "image_clothes", "filename": "clothes"},{"name": "image_gloves", "filename": "gloves"}, {"name": "image_glasses", "filename": "glasses"}];
  assessment_activity:any;
  innerObservable;
  errorArray;

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, private materialListService: MaterialListService,
    private organisationService: OrganisationService, private uploadService: FileUploadService,
    private modalService: NgbModal, private assessmentService: AssessmentService, private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,private supportService: SupportService, private pdfService: PdfService) { }

  ngAfterViewInit() {
    // Wait for the view to be initialized to ensure pdfContent is available
  }

  ngOnInit(): void {

    this.route.params.pipe(take(1)).subscribe(params => {
      this.assessmentId = params['id'];
    });

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if (this.organisation == null || this.selectedDepartment == null || this.selectedOrganisation == null) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');

    this.loadingData = true;

    this.getAllDepartments();
    this.getSingleAssessment();

    this.assessment_activity = localStorage.getItem('assessment_activity');
    if(this.assessment_activity&&this.assessment_activity!=null){
      this.submit();
    }
  }

  openDocumentInNewWindow(fileId) {
    this.assessmentService.getcDocFileWithId(fileId);
    this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {

        // var popup = window.open('')
        // popup.location.href = pdfContent;
        var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
      },
      error: error => {
      }
    })
  }

  fileRemove(index) {
    this.assessment.assessmentfiles.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  pictureRemove(index) {
    this.assessment.assessmentimages.splice(index, 1);
    this.pictures.splice(index, 1);
  }

  validation() {
    this.assessment.status = 1;
  }

  verification() {
    this.assessment.status = 2;
  }

  // Method to check if two objects are different
  hasAssessmentChanged(): boolean {
    return JSON.stringify(this.assessment) != JSON.stringify(this.prevAssessment);
  }

  cancel() {
    if (this.hasAssessmentChanged() == true)
    {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        } else {
          this.router.navigate(['/assessments']);
        }
      })
    }
    else
    {
      this.router.navigate(['/assessments']);
    }
  }

  openPicture(fileId) {
    this.picture = null;
    this.assessmentService.getAssessmentPictureDataListener().pipe(concatMap( val => {
      this.picture = val;
      this.pictures.push(this.picture);
      this.pictures2.push(this.picture);
      this.picturenames.push("");
      return this.innerObservable
    })).subscribe({
      next: pdfContent => {

      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  approve() {
    if(this.checkIfFieldIsTooLong()){
      return;
    }
    this.assessment.status = 3;
    this.assessment.approved_by = this.currentUser.first_name + " " + this.currentUser.last_name;
    this.createARB();
  }

  notApproved() {
    if(this.checkIfFieldIsTooLong()){
      return;
    }
    this.assessment.status = 0;
    this.createARB();
  }

  createARB() {
    // Make the hidden content visible
    this.loadingButton=true;
    const content = this.pdfContent.nativeElement;
    content.style.visibility = 'visible';

    // Detect changes to ensure the component is rendered
    this.cdr.detectChanges();

    // Generate the PDF
    this.pdfService.generatePDF(content).then((file) => {
      // Hide the content again after PDF generation
      content.style.visibility = 'hidden';
  
      this.uploadService.upload(file);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result==null)
          {
            this.assessment.riskfile = null;
            this.loadingButton=false;
            Swal.fire("Error", "generiskt fel");
          }
          else {
            this.assessment.riskfile = result.filename;
            this.submit();

            var fileURL = URL.createObjectURL(file);
            var opened=window.open(fileURL,"_blank");
            if(!opened){
              window.location.href=file;
            }
          }
        },
        error: error => {
          this.assessment.riskfile = null;
          this.loadingButton=false;
          Swal.fire("Error", "generiskt fel");
        }
      })

    }).catch((error) => {
      content.style.visibility = 'hidden';
      this.loadingButton=false;
      Swal.fire("Error", "generiskt fel");
    });
  }

  generateARBAndSubmit() {
    // Make the hidden content visible
    this.loadingButton=true;
    const content = this.pdfContent.nativeElement;
    content.style.visibility = 'visible';

    // Detect changes to ensure the component is rendered
    this.cdr.detectChanges();

    // Generate the PDF
    this.pdfService.generatePDF(content).then((file) => {
      // Hide the content again after PDF generation
      content.style.visibility = 'hidden';
  
      this.uploadService.upload(file);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.filename == null)
          {
            this.assessment.riskfile = null;
            this.loadingButton = false;
            Swal.fire("Error", "generiskt fel");
          }
          else {
            this.assessment.riskfile = result.filename;
            this.submit();
            this.router.navigate(['/assessments']);
          }
        },
        error: error => {
          this.assessment.riskfile = null;
          this.loadingButton = false;
          Swal.fire("Error", "generiskt fel");
        }
      })

    }).catch((error) => {
      content.style.visibility = 'hidden';
      this.loadingButton = false;
      Swal.fire("Error", "generiskt fel");
    });
  }

  submit() {
    
    if (!this.assessment.issuer) {
      this.assessment.issuer = this.currentUser;
    }

    if (this.assessment.status==3)
    {
      this.assessment.approved_by = this.currentUser.first_name + " " + this.currentUser.last_name;
    }

    this.assessmentService.updateAssessment(this.assessment.id, this.assessment);
    this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: info => {
        if (info == null) {
          Swal.fire("Error", "generiskt fel");
          this.loadingButton = false;
        }
        else {
          localStorage.removeItem('assessment_activity');
          this.loadingButton = false;
        }
      },
      error: error => {
        console.log("Error: ", error);
        this.loadingData = false;
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  remove() {
    this.loadingData = true;
    Swal.fire({
      title: this.translate.instant("REMOVE"),
      text: this.translate.instant("CONFIRM_REMOVE_ASSESSMENT"),
      showCancelButton: true,
      confirmButtonText: this.translate.instant("YES"),
      cancelButtonText: this.translate.instant("NO"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.assessmentService.deleteAssessment(this.assessment.id);
        this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: info => {
        if (info == null) {
          Swal.fire("Error", "generiskt fel");
          this.loadingData = false;
        }
        else {
          localStorage.removeItem('assessment_activity');
          this.router.navigate(['/assessments']);
          this.loadingData = false;
        }
      },
      error: error => {
        console.log("Error: ", error);
        Swal.fire("Error", "generiskt fel");
        this.loadingData = false;
      }
    })
      } else if (result.isDenied || result.isDismissed) {
        this.loadingData = false;

      }
    });

  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  goToAssessmentList() {
    this.router.navigate(['/assessments']);
  }

  onToggleShowSubstitution() {
    if (this.substitution == true)
    {
      this.assessment.substitution = 1;
    }
    else
    {
      this.assessment.substitution = 0;
    }
  }

  onToggleShowOutphasing() {
    if (this.phasing_out == true)
    {
      this.assessment.phasing_out = 1;
    }
    else
    {
      this.assessment.phasing_out = 0;
    }
  }

  onToggleShowRiskMinimzation() {
    if (this.risk_minimzation == true)
    {
      this.assessment.risk_minimzation = 1;
    }
    else
    {
      this.assessment.risk_minimzation = 0;
    }
  }

  workstepSelected(workstep, index) {
    localStorage.setItem('assessment_activity', JSON.stringify(this.assessment));
    this.router.navigate(['/workstep', index+1]);
  }

  addWorkstep() {
    localStorage.setItem('assessment_activity', JSON.stringify(this.assessment));
    this.router.navigate(['/workstep', 0]);
  }

  workstepRemoved(index) {
    this.assessment.worksteps.splice(index, 1);
    this.picturenames.splice(index, 1);
    localStorage.setItem('assessment_activity', JSON.stringify(this.assessment));
  }

  openSdsInNewWindow(product) {

    var filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  openGrbInNewWindow(product) {

    var filename = product.name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else
        {
          var popup = window.open('')
          popup.location.href = pdfContent;
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  getSingleAssessment(){
    this.assessment = JSON.parse(localStorage.getItem('assessment_activity', ));
    if (!this.assessment)
    {
      this.assessmentService.getSingle(this.assessmentId);
      this.assessmentService.getAssessmentSingleDataListener().pipe(take(1)).subscribe({
        next: activity => {
          if (activity == null) {
            this.loadingData = false;
          }
          setTimeout( () => {

            this.assessment = activity[0];
            this.prevAssessment = _.cloneDeep(this.assessment);
            this.loadTableData();
          },100)
        },
        error: error => {
          console.log("Error: ", error);
          this.loadingData = false;
        }
      })
    }
    else
    {
      this.loadTableData();
    }
  }

  loadTableData() {

    if(this.assessment && this.assessment.assessmentimages)
    {
      let unique = false;
      this.pictures = [];
      this.pictures2 = [];
      this.picturenames = [];

      for (var i = 0; i < this.assessment.assessmentimages.length; i++) {
        this.innerObservable = of(this.assessmentService.getFileAsTrustedResourceUrl(this.assessment.assessmentimages[i].file_id));
        if (unique == false) {
            this.openPicture(this.assessment.assessmentimages[i].file_id);
            unique = true;
        }
      }
    }

    if (this.assessment.substitution == 1)
    {
      this.substitution = true;
    }

    if (this.assessment.phasing_out == 1)
    {
      this.phasing_out = true;
    }

    if (this.assessment.risk_minimzation == 1)
    {
      this.risk_minimzation = true;
    }

    if (this.assessment.worksteps)
    {
      for(var j = 0; j < this.assessment.worksteps.length; j++)
      {
        this.assessment.worksteps[j].protectiveArray = [];
        for(var i = 0; i < this.protectiveImages.length; i++)
        {
          if (this.assessment.worksteps[j][this.protectiveImages[i].name] === 1)
          {
            var productFound = false;
            for (var k = 0; k < this.protectiveArray.length; k++)
            {
              if (this.protectiveArray[k].name === this.protectiveImages[i].name)
              {
                  productFound = true;
                  this.assessment.worksteps[j].protectiveArray.push(this.protectiveImages[i]);
                  break;
              }
            }

            if (productFound === false)
            {
              this.protectiveArray.push(this.protectiveImages[i]);
              this.assessment.worksteps[j].protectiveArray.push(this.protectiveImages[i]);
            }
          }
        }
      }
    }

    if (this.assessment.issuer) {
      this.userService.getSingleUser(this.assessment.issuer);
      this.userService.getCurrentSingleUserDataListener().pipe(take(1)).subscribe({
        next: user => {
          this.issuer = user;
        },
        error: error => {
          this.issuer = null;
          this.loadingData = false;
        }
      })
    }

    this.assessmentService.getAllProducts();
    this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: departmentProducts => {
        if (departmentProducts == null) {
          this.loadingData = false;
        }
        else {
          this.departmentProducts = departmentProducts;
          this.pictograms = [];
          this.products = [];
          this.uniqueproducts = [];
          this.loadingData = false;

          //Get all products
          if(this.assessment.worksteps){
            for (var i = 0; i < this.assessment.worksteps.length; i++) {
              if(this.assessment.worksteps[i].products){
                for (var j = 0; j < this.assessment.worksteps[i].products.length; j++) {
                  if(this.departmentProducts){
                    for (var k = 0; k <  this.departmentProducts.length; k++) {
                      if (this.assessment.worksteps[i].products[j].product_id == this.departmentProducts[k].product_id) {
                        this.products.push(this.departmentProducts[k]);
                      }
                    }
                  }
                }
              }
            }
          }


          for (var i = 0; i < this.products.length; i++) {
            for (var m = 0; m < this.products[i].pictograms.length; m++)
            {
              var productFound = false;
              for (var k = 0; k < this.pictograms.length; k++)
              {
                if (this.pictograms[k].name === this.products[i].pictograms[m].name)
                {
                    productFound = true;
                }
              }
              if (productFound === false)
              {
                this.pictograms.push(this.products[i].pictograms[m]);
              }
            }
          }
        }
        this.uniqueproducts = _.uniq(this.products);
      },
      error: error => {
        this.loadingData = false;
        console.log("Error: ", error);
      }
    });
  }

  incomingfile(event) {
    this.assessmentfile = event.target.files[0];

    if (this.assessmentfile != null) {

      this.uploadService.upload(this.assessmentfile);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.assessmentfile = null;
          }
          else {
            if (!this.assessment.assessmentfiles)
            {
              this.assessment.assessmentfiles = [];
            }
            var file_id = {"file_id": result.filename, "file_name": this.assessmentfile.name};
            this.assessment.assessmentfiles.push(file_id);
            this.filenames.push(this.assessmentfile.name);
          }
        },
        error: error => {
          this.assessmentfile = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  incomingimage(event) {
    this.assessmentimage = event.target.files[0];

    if (this.assessmentimage != null) {

      const fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        this.pictures.push(e.target.result);
      };
      fileReader.readAsDataURL(this.assessmentimage);

      this.uploadService.upload(this.assessmentimage);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            this.assessmentimage = null;
          }
          else {
            if (!this.assessment.assessmentimages)
            {
              this.assessment.assessmentimages = [];
            }
            var file_id = {"file_id": result.filename};
            this.assessment.assessmentimages.push(file_id);
            this.picturenames.push(this.assessmentimage.name);
          }
        },
        error: error => {
          this.assessmentimage = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  checkIfFieldIsTooLong(){
    let arrayItems = [
      { 'name': 'general_risks', 'translatename': 'GENERAL_RISKS', 'length': 270 },
      { 'name': 'ventilation', 'translatename': 'VENTILATION', 'length':200 },
      { 'name': 'storage', 'translatename':'STORAGE2','length':200 },
      { 'name': 'hand_protection', 'translatename':'HAND_PROTECTION','length':200 },
      { 'name': 'eye_protection', 'translatename':'EYE_PROTECTION','length':200 },
      { 'name': 'respiratory', 'translatename':'RESPIRATORY','length':200 },
      { 'name': 'body_protection', 'translatename':'PROTECTIVE_CLOTHING','length':200 },
      { 'name': 'miscellanious', 'translatename':"MISC",'length':200 },
      { 'name': 'incident_info', 'translatename':'EMERGENCY_PREPAREDNESS','length':300 }
    ]

    this.errorArray=[];
    var toLongText="";
    var requiredText="";
    var allergicText="";
    var totalErrorText="";

    for ( var n=0; n<arrayItems.length; n++) {
      if ( this['assessment'][arrayItems[n].name]?.length > arrayItems[n].length ) {
          toLongText += this.translate.instant( arrayItems[n].translatename ) + "<br/>";
      }
    }

    if ( toLongText != "") {
      totalErrorText = "<hr/>" + this.translate.instant( "TEXT_IN_FIELDS_TOO_LONG" );
    }

    if ( totalErrorText != "" ) {
      Swal.fire (
        this.translate.instant( "ERROR_IN_INPUT" ),
        totalErrorText,
        "error"
      );
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.authService.logout();
  }

  openExtSdsInNewWindow(prod) {
    var filename = prod.name + '.pdf';
    // var popup = window.open('')
    this.productService.getExtSdsAsTrustedResourceUrl(prod.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }
}
