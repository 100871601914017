import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { ManufacturerService } from 'src/app/services/manufacturer.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.css'],
})


export class ManufacturerComponent implements OnInit {
  manufacturerSearch:any=[];
  displayedManufacturers:any=[];
  itemsPerPage:number=10;
  pageNumber:number=1;
  searchedKeyword: string;
  sortDirection='asc';

  substanceSearch = {
    all:""
  };

  constructor(
    private utilService:UtilService,
    private translate: TranslateService,
    private router:Router,
    private manufacturerService: ManufacturerService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.loadManufacturerList();
        this.manufacturerSearch = {
            all: "",
        }
  }

  addManufacturer() {
    this.router.navigate(['/manufactureritem'],{ queryParams: { id: 'new'}});
  }

  manufacturerSelected(manufacturer) {
    this.router.navigate(['/manufactureritem'],{ queryParams: { id: manufacturer.id}});
  }

  loadManufacturerList() {
    this.manufacturerService.getAll();
    this.manufacturerService.getManufacturerDataListener().pipe(take(1)).subscribe({
      next: manufacturer => {
        if (manufacturer == null) {

        }
        else
        {
          this.displayedManufacturers=manufacturer;
          this.sortDirection='asc';
          this.sortName();
        }
      },
      error: error => {

      }
    })
  }
  gotoControlPanel(){
    this.router.navigate(['/controlpanel']);
  }

  sortName(){
    if(this.sortDirection=='desc'){
      this.sortDirection='asc';
      this.displayedManufacturers.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase()) ? -1 : 1);
    }
    else if(this.sortDirection=='asc'){
      this.sortDirection='desc';
      this.displayedManufacturers.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase()) ? 1 : -1);
    }
  }

  logout() {
    this.authService.logout();
  }
}


