<div class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
  <div class="flex flex-row justify-between">
      <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlpanel()">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
          <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
        </svg>
      </button>
  </div>
</div>

<div class="mt-4 px-4 py-2 mb-20">
  <div class="overflow-x-auto ">
      <table class="table-auto bg-white mb-4 w-full" st-table="kebnekaisetable" st-safe-src="kebnekaisetable">
        <thead>
            <tr>
              <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-row items-center">
                      <div>
                          <input st-search="" type="search" [(ngModel)]="productSearch.all" class="prompt" 
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                      </div>
                  </div>
              </th>
            </tr>
            <tr>
              <th class="table-row-header sort-header" st-sort="name">{{"NAME" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="supplier_name">{{"MANUFACTURER" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="com_use">{{"DESCRIPTION" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="flammable">{{"FLAMMABLE" | translate}}</th>
              <th class="table-row-header">{{"AMOUNT" | translate}}</th>
              <th class="table-row-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
              <th class="table-row-header">{{"UNIT" | translate}}</th>
              <th class="table-row-header">{{"PROTECTIVE_GEAR" | translate}}</th>
              <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of products
            | filter : productSearch.all
            | orderBy : 'name'
            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };"
            (click)="productSelected(product)" >
              <td class="table-row-column">{{product.name}}</td>
              <td class="table-row-column">{{product.supplier_name}}</td>
              <td class="table-row-column">{{product.description}}</td>
              <td class="table-row-column" *ngIf="product.flammable">{{"YES" | translate}}</td>
              <td class="table-row-column" *ngIf="!product.flammable">{{"NO" | translate}}</td>
              <td class="table-row-column">{{product.amount_in_stock}}</td>
              <td class="table-row-column">{{product.estimated_use_per_year}}</td>
              <td class="table-row-column">{{product.unit}}</td>
              <td class="table-row-column">{{product.protective_gear}}</td>
              <td class="table-row-column" *ngIf="product.sds_file_id">
                <img tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="$event.stopPropagation(); openSdsInNewWindow(product)" />
              </td>
              <td class="table-row-column" *ngIf="!product.sds_file_id"></td>
          </tr>
        </tbody>
        <tfoot>
            <tr class="mt-4">
              <td class="p-6" colspan="12">
                  <div class="flex flex-row items-center">
                  <div class="relative inline-block text-left mr-20" appDropdown>
                      <button type="button" class="button-gray">
                          <span class="text-sm">{{"Kebnekaise" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                          <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </button>
                      <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                          <div class="py-1">
                              <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                              <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                              <a (click)="itemsPerPage=25;pageNumber=1" class="dropdown-menu-item" tabindex="3">25</a>
                          </div>
                      </div>
                  </div>
                  <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                      previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </div>
              </td>
            </tr>
        </tfoot>
      </table>
  </div>
</div>


