import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { take } from 'rxjs/operators';
import * as _ from "lodash";

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  getProductDataUpdated = new Subject<any>();
  getProductsDataUpdated = new Subject<any>();
  getProductSingleDataUpdated = new Subject<any>();
  getProductRestDataUpdated = new Subject<any>();
  getProducthistoryDataUpdated = new Subject<any>();
  getProductPphraseDataUpdated = new Subject<any>();
  getProductCphraseDataUpdated = new Subject<any>();
  getProductCasDataUpdated = new Subject<any>();
  getProductRestrictionDataUpdated = new Subject<any>();
  getProductGroupDataUpdated = new Subject<any>();
  getProductLangDataUpdated = new Subject<any>();
  getProductCargoDataUpdated = new Subject<any>();
  getProductDepartmentDataUpdated = new Subject<any>();
  getProductPictureDataUpdated = new Subject<any>();
  getChangedProductsDataUpdated = new Subject<any>();
  getProductStyrenDataUpdated = new Subject<any>();
  getAllProductDataUpdated = new Subject<any>();
  getProductsAllSupUpdated = new Subject<any>();
  getProductsCountUpdated = new Subject<any>();
  getDataSheetUpdated = new Subject<any>();
  getHistoryStatisticsUpdated = new Subject<any>();
  getProductRestTwoDatesUpdated = new Subject<any>();
  getProductsAllSupTopUpdated = new Subject<any>();
  getLogoUpdated = new Subject<any>();

  constructor(private http: HttpClient, private utilService: UtilService, private sanitizer:DomSanitizer,
              private authService: AuthService, private productConversionsService: ProductConversionsService) {}

  fetchLabels(organisationId, productId, size, thistoken) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("data", size);
    params = params.append("token", thistoken);

    this.http.get(BackendUrl + "/organisations/" + organisationId + "/products/labels/" + productId, {headers: headers, params: params, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {

        var contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        var file = new Blob([response], { type: contentType });
        var fileURL = URL.createObjectURL(file);
        this.getProductDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getLabelForPrinter(organisationId, productId, size, thistoken) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("data", size);
    params = params.append("token", thistoken);

    this.http.get(BackendUrl + "/organisations/" + organisationId + "/products/printer/" + productId, {headers: headers, params: params, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {

        var contentType = 'application/pdf';
        var file = new Blob([response], { type: contentType });
        var fileURL = URL.createObjectURL(file);
        this.getProductDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  createNewGroup(orgId, groupName) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    var info = {
      name: groupName,
    }

    this.http.post(BackendUrl + "/organisations/" + orgId + "/groups", info, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  updateGroup(orgId, group) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    var info = {
      name: group.name,
    }

    this.http.put(BackendUrl + "/organisations/" + orgId + "/groups/" + group.id, info, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  removeGroup(orgId, group) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.delete(BackendUrl + "/organisations/" + orgId + "/groups/" + group.id, {headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getProductDataUpdated.next(true);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(true);
      }
    });
  }

  getProductsWithLessInformation(orgId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/organisations/' + orgId + '/products/subForUI', this.utilService.setHeadersAndParamsObject())
      .pipe(take(1)).subscribe({next: response => {
        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getGroups(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get<any>(BackendUrl + "/organisations/" + orgId + "/groups", {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

        this.getProductGroupDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductGroupDataUpdated.next(null);
      }
    });
  }

  getSdsAsTrustedResourceUrl(sareqId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    var file,fileURL,contentType;

    this.http.get(BackendUrl + "/sareq/" + sareqId + "/sds", {headers: headers, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {
        if(response==null||response.byteLength==0){
          this.getDataSheetUpdated.next(null);
        } else {
          contentType = 'application/pdf';
          file = new Blob([response], { type: contentType });
          fileURL = URL.createObjectURL(file);
          this.getDataSheetUpdated.next(fileURL);
        }
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getDataSheetUpdated.next(null);
      }
    });
  }

  getGrbAsTrustedResourceUrl(sareqId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    var file,fileURL,contentType;

    this.http.get(BackendUrl + "/sareq/" + sareqId + "/grb", {headers: headers, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {
        if(response==null||response.byteLength==0){
          this.getDataSheetUpdated.next(null);
        } else {
          contentType = 'application/pdf';
          file = new Blob([response], { type: contentType });
          fileURL = URL.createObjectURL(file);
          this.getDataSheetUpdated.next(fileURL);
        }

      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getDataSheetUpdated.next(null);
      }
    });
  }

  getStampedSdsAsTrustedResourceUrl(sareqId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    var file,fileURL,contentType;

    this.http.get(BackendUrl + "/sareq/" + sareqId + "/StampedsdsProductItem", {headers: headers, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {
        if(response==null||response.byteLength==0){
          this.getDataSheetUpdated.next(null);
        } else {
          contentType = 'application/pdf';
          file = new Blob([response], { type: contentType });
          fileURL = URL.createObjectURL(file);
          this.getDataSheetUpdated.next(fileURL);
        }
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getDataSheetUpdated.next(null);
      }
    });
  }

  getProductSheetAsTrustedResourceUrl(sareqId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    var file,fileURL,contentType;

    this.http.get(BackendUrl + "/sareq/" + sareqId + "/productsheet", {headers: headers, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {
        if(response==null||response.byteLength==0){
          this.getDataSheetUpdated.next(null);
        } else {
          contentType = 'application/pdf';
          file = new Blob([response], { type: contentType });
          fileURL = URL.createObjectURL(file);
          this.getDataSheetUpdated.next(fileURL);
        }
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getDataSheetUpdated.next(null);
      }
    });
  }

  getPictureAsTrustedResourceUrl(sareqId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    var file,fileURL,contentType;

    this.http.get(BackendUrl + "/sareq/" + sareqId + "/picture", {headers: headers, responseType: "blob"})
      .pipe(take(1)).subscribe({next: response => {
        file = new Blob([response], {type: 'application/image'});
        if (file.size !== 0)
        {
          const unsafeImg = URL.createObjectURL(file);
          var image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
          this.getProductPictureDataUpdated.next(image);
        }
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductPictureDataUpdated.next(null);
      }
    });
  }

  getLogoAsTrustedResourceUrl(sareqId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    var file,fileURL,contentType;

    this.http.get(BackendUrl + "/sareq/" + sareqId + "/logo", {headers: headers, responseType: "arraybuffer"})
      .pipe(take(1)).subscribe({next: response => {
        file = new Blob([response], {type: 'image/jpeg'});
        if (file.size !== 0)
        {
          fileURL = URL.createObjectURL(file);
          this.getLogoUpdated.next(fileURL);
        }
      },
      error: error => {
        this.getLogoUpdated.next(null);
      }
    });
  }

  getTransportInfoAsTrustedUrl(sareqId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    var file,fileURL,contentType;
    this.http.get(BackendUrl + "/sareq/" + sareqId + "/sdsTransportInfo2", {headers: headers, responseType: "arraybuffer"})
    .pipe(take(1)).subscribe({next: response => {
      if(response==null||response.byteLength==0){
        this.getDataSheetUpdated.next(null);
      } else {
        contentType = 'application/pdf';
        file = new Blob([response], { type: contentType });
        fileURL = URL.createObjectURL(file);
        this.getDataSheetUpdated.next(fileURL);
      }
    },
    error: error => {
      if (error.status === 401)
      {
        this.authService.logout();
      }
      this.getDataSheetUpdated.next(null);
    }
    });
  }

  getOtherAvailableLangs(productId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    var file,fileURL,contentType;
    this.http.get(BackendUrl + "/organisations/products/lang/" + productId, this.utilService.setHeadersAndParamsObject())
      .pipe(take(1)).subscribe({next: response => {
        this.getProductLangDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductLangDataUpdated.next(null);
      }
    });
  }

  AllWithSuborganisationsLessInformationAndStamped(orgId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/organisations/' + orgId + '/products/subForUIStamped',{headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  AllWithSuborganisationsLessInformation(orgId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + '/organisations/' + orgId + '/products/subForUI',{headers: headers}).pipe(take(1)).subscribe({next: response => {

        this.productConversionsService.convertPictogramsOnProductArray(response);
        this.getAllProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getAllProductDataUpdated.next(null);
      }
    });
  }

  GetL(info) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);
    let productId=info.productId;
    let lang=info.lang;
    this.http.get(BackendUrl + "/organisations/products/lang/"+productId+"/"+lang+"", {headers: headers})
      .pipe(take(1)).subscribe({next: response => {
        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getRestriktionList(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/getRestriktionList/", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {
        this.getProductRestDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductRestDataUpdated.next(null);
      }
    });
  }

  getHistoryForProducts(orgId, labels, year, productgroup) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("year", year);
    params = params.append("productgroup", productgroup);

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/history/" + labels, {headers: headers, params: params}).pipe(take(1)).
      subscribe({next: response => {
        this.getProducthistoryDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProducthistoryDataUpdated.next(null);
      }
    });
  }

  AllWithSuborganisationsMoreInformation(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/subMoreInformation", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.productConversionsService.convertPictogramsOnProductArray(response);
        this.productConversionsService.convertFootprintOnProductArray(response);
        this.productConversionsService.convertIdOnProductArray(response);
        this.productConversionsService.convertClassificationOnProductArray(response);
        this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
        this.getProductsAllSupUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductsAllSupUpdated.next(null);
      }
    });
  }

  AllWithSuborganisationsMoreInformationTop(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/subMoreInformation", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.productConversionsService.convertPictogramsOnProductArray(response);
        this.productConversionsService.convertFootprintOnProductArray(response);
        this.productConversionsService.convertIdOnProductArray(response);
        this.productConversionsService.convertClassificationOnProductArray(response);
        this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
        this.getProductsAllSupTopUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductsAllSupTopUpdated.next(null);
      }
    });
  }

  getDepartmentproductCount(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/subMoreInformation", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductsCountUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductsCountUpdated.next(null);
      }
    });
  }

  AllWithSuborganisationsMoreInformationStamped(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/subMoreInformationStamped", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.productConversionsService.convertPictogramsOnProductArray(response);
        this.productConversionsService.convertFootprintOnProductArray(response);
        this.productConversionsService.convertIdOnProductArray(response);
        this.productConversionsService.convertClassificationOnProductArray(response);
        this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
        this.getProductsAllSupUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductsAllSupUpdated.next(null);
      }
    });
  }

  AllWithSuborganisationsMoreInformationStampedTop(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/subMoreInformationStamped", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.productConversionsService.convertPictogramsOnProductArray(response);
        this.productConversionsService.convertFootprintOnProductArray(response);
        this.productConversionsService.convertIdOnProductArray(response);
        this.productConversionsService.convertClassificationOnProductArray(response);
        this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
        this.getProductsAllSupTopUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductsAllSupTopUpdated.next(null);
      }
    });
  }

  AllMoreInformationStamped(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/moreStamped", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.productConversionsService.convertPictogramsOnProductArray(response);
        this.productConversionsService.convertFootprintOnProductArray(response);
        this.productConversionsService.convertIdOnProductArray(response);
        this.productConversionsService.convertClassificationOnProductArray(response);
        this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
        this.getProductsAllSupUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductsAllSupUpdated.next(null);
      }
    });
  }

  AllMoreInformation(orgId) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/more", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.productConversionsService.convertPictogramsOnProductArray(response);
        this.productConversionsService.convertFootprintOnProductArray(response);
        this.productConversionsService.convertIdOnProductArray(response);
        this.productConversionsService.convertClassificationOnProductArray(response);
        this.productConversionsService.convertProtectiveGearImagesOnProductArray(response);
        this.getProductsAllSupUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductsAllSupUpdated.next(null);
      }
    });
  }

  checkForOtherLanguages(orgId, products) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post(BackendUrl + "/organisations/" + orgId + "/otherLanguage", products, {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getExtSdsAsTrustedResourceUrl(sareqId) {

    var file, fileURL;

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/sareq/" + sareqId + "/extsds", {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).
      subscribe({next: response => {
        if(response==null||response.byteLength==0){
          this.getDataSheetUpdated.next(null);
        } else {
          var file = new Blob([response], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          this.getDataSheetUpdated.next(fileURL);
        }
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getDataSheetUpdated.next(null);
      }
    });
  }

  getHPhrases(orgId, sareqProduct) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post(BackendUrl + "/organisations/" + orgId + "/getHPhrases/", sareqProduct, {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getPPhrases(orgId, sareqProducts) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post(BackendUrl + "/organisations/" + orgId + "/getPPhrases/", sareqProducts, {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductPphraseDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductPphraseDataUpdated.next(null);
      }
    });
  }

  getCPhrases(orgId, sareqProduct) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.post(BackendUrl + "/organisations/" + orgId + "/getCPhrases/", sareqProduct, {headers: headers}).pipe(take(1)).
      subscribe({next: response => {
        this.getProductCphraseDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductCphraseDataUpdated.next(null);
      }
    });
  }

  getCas(orgId, sareqProducts) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post(BackendUrl + "/organisations/" + orgId + "/getCas/", sareqProducts, {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductCasDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductCasDataUpdated.next(null);
      }
    });
  }

  getHPhrasesSeveralArrays(orgId, sareqProduct) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.post(BackendUrl + "/organisations/" + orgId + "/products/getHPhrasesSeveralArrays/", sareqProduct, {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getRestriktionListAllAdded(orgId) {

    var file, fileURL;

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/getRestriktionListAllAdded/", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductRestrictionDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductRestrictionDataUpdated.next(null);
      }
    });
  }

  fetchInventoryTemplate(orgId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/excel", {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).
      subscribe({next: response => {

        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        var file = new Blob([response], { type: contentType });
        var fileURL = URL.createObjectURL(file);
        this.getProductDataUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  postInventoryList(orgId, file_id) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    let params = new HttpParams();
    params = params.append("data", file_id);

    this.http.post<any>(BackendUrl + "/organisations/" + orgId + "/products/excel", null, {headers: headers, params: params}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  addSareqProductToDepartment(orgId, sareqProduct) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    var info = {
      product_id: sareqProduct.id,
      amount: sareqProduct.amount,
      annual_consumption: sareqProduct.annual_consumption,
      maximum_amount: sareqProduct.maximum_amount,
      unit: _.isObject(sareqProduct.unit) ? sareqProduct.unit.id : null,
      group_id: _.isObject(sareqProduct.group) ? sareqProduct.group.id : null,
      own_article_number: sareqProduct.own_article_number ? sareqProduct.own_article_number:"",
      new_own_article_number: sareqProduct.new_own_article_number ? sareqProduct.new_own_article_number:""
    }

    this.http.post<any>(BackendUrl + "/organisations/" + orgId + "/products", info, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getProductIdFromName(name, sname) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    let params = new HttpParams();
    params = params.append("product_name", name.replace('+','plussign'));
    params = params.append("supplier_name", sname.replace('+','plussign'));

    this.http.get<any>(BackendUrl + "/sareq/get_sareq_id_by_product_and_supplier", {headers: headers, params: params}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  postProductInOrg(orgId, product) {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    product.name=product.name.replace('+','plussign');
    product.manufacturer=product.manufacturer.replace('+','plussign');

    this.http.post<any>(BackendUrl + "/organisations/" + orgId + "/products/", product, {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getSingleProduct(orgId, productId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get<any>(BackendUrl + "/organisations/" + orgId + "/products/" + productId, {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        response = response[0];
        this.productConversionsService.convertPictogramsOnProduct(response);
        this.productConversionsService.convertPhrasesOnProduct(response);
        this.productConversionsService.convertFootprintOnProduct(response);
        this.productConversionsService.convertICEOnProduct(response);
        this.productConversionsService.convertProtectionOnProduct(response);
        this.productConversionsService.setProductRiskLists(response);
        this.productConversionsService.convertIdOnProduct(response);
        this.productConversionsService.convertClassificationOnProduct(response);
        this.productConversionsService.convertProtectiveGearImagesToProduct(response);
        this.getProductSingleDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductSingleDataUpdated.next(null);
      }
    });
  }

  AllWithSuborganisations() {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + "/organisations/" + orgId + "/products/sub", {headers: headers}).pipe(take(1)).
        subscribe({next: response => {

          this.getProductDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        }
      });
    }
  }

  getAllProducts() {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get<any>(BackendUrl + "/organisations/" + orgId + "/products", {headers: headers}).pipe(take(1)).
        subscribe({next: response => {

          this.getProductDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        }
      });
    }
  }

  getDepartmentsWhereProductAppearsByProductId(productId, org) {

    if (org)
    {
      var departmentId = JSON.parse(localStorage.getItem('selectedOrganisation', )).id;
    }
    else
    {
      var departmentId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
    }

    if (departmentId == null) {
      this.authService.logout();
    }

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get<any>(BackendUrl + "/organisations/" + departmentId + "/products/" + productId + "/suborgs", {headers: headers}).pipe(take(1)).
      subscribe({next: response => {

        this.getProductDepartmentDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDepartmentDataUpdated.next(null);
      }
    });
  }

  createTransportInfo(productId) {

    var file, fileURL;

    if (localStorage.getItem('selectedOrganisation', ) != null )
    {
      var departmentId = JSON.parse(localStorage.getItem('selectedOrganisation', )).id;
      if (departmentId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      let usedLanguage = localStorage.getItem('usedLanguage');

      let params = new HttpParams();
      params = params.append("language", usedLanguage);

      this.http.get(BackendUrl + "/organisations/" + departmentId + "/products/" + productId + "/transport_info", {headers: headers, params: params, responseType:"arraybuffer"}).pipe(take(1)).
        subscribe({next: response => {

          var file = new Blob([response], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          this.getProductDataUpdated.next(fileURL);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        }
      });
    }
  }

  createCargoDeclaration(productId, numberOfPackages, typeOfPackages, netWeight, volume, shipper, shipper2, shipper3, consignee1, consignee2, consignee3, environment, overpack) {

    var file, fileURL;

    if (localStorage.getItem('selectedOrganisation', ) != null )
    {
      var departmentId = JSON.parse(localStorage.getItem('selectedOrganisation', )).id;
      if (departmentId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');

      let usedLanguage = localStorage.getItem('usedLanguage');

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      let params = new HttpParams();
      params = params.append("numberOfPackages", numberOfPackages||0);
      params = params.append("typeOfPackages", typeOfPackages||'');
      params = params.append("netWeight", netWeight||0);
      params = params.append("volume", volume||0);
      params = params.append("shipper", shipper||'');
      params = params.append("shipper2", shipper2||'');
      params = params.append("shipper3", shipper3||'');
      params = params.append("consignee1", consignee1||'');
      params = params.append("consignee2", consignee2||'');
      params = params.append("consignee3", consignee3||'');
      params = params.append("environment", environment);
      params = params.append("overpack", overpack);
      params = params.append("language", usedLanguage);

      this.http.get(BackendUrl + "/organisations/" + departmentId + "/products/" + productId + "/cargo_declaration", {headers: headers, params: params, responseType:"arraybuffer"}).pipe(take(1)).
        subscribe({next: response => {

          var file = new Blob([response], {type: 'application/pdf'});
          this.getProductCargoDataUpdated.next(file);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getProductCargoDataUpdated.next(null);
        }
      });
    }
  }

  getSdsWithRiskIdAsTrustedResourceUrl(riskId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/sareq/sds/" + riskId, {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).
      subscribe({next: response => {

        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getDataSheetUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getDataSheetUpdated.next(null);
      }
    });
  }

  getGrbWithRiskIdAsTrustedResourceUrl(riskId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    this.http.get(BackendUrl + "/sareq/grb/" + riskId, {headers: headers, responseType: "arraybuffer"}).pipe(take(1)).
      subscribe({next: response => {

        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getDataSheetUpdated.next(fileURL);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getDataSheetUpdated.next(null);
      }
    });
  }

  updateSingleProduct(product) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      var info = {
        productId: product.id
      }

      this.http.put(BackendUrl + "/organisations/" + orgId + "/products/" + product.id, product, {headers: headers})
        .pipe(take(1)).subscribe({next: response => {

          this.getProductDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        }
      });
    }
  }

  removeSingleProduct(product) {

    let token = localStorage.getItem('token');

    if (localStorage.getItem('selectedDepartment', ) != null )
    {
      var orgId = JSON.parse(localStorage.getItem('selectedDepartment', )).id;
      if (orgId == null)
      {
        this.authService.logout();
      }

      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      let params = new HttpParams();
      params = product;

      this.http.delete<any>(BackendUrl + "/organisations/" + orgId + "/products/" + product.productId, {headers: headers, params:params}).pipe(take(1)).subscribe({next: response => {

          this.getProductDataUpdated.next(true);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(true);
        }
      });
    }
  }

  AllWithSuborganisationsMoreInformationControlPanel(id)
  {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("orgid", id);
    this.http.get(BackendUrl + "/organisations/"+id+"/products/subMoreInformationControlPanel", {headers: headers})
    .pipe(take(1)).subscribe({next: response => {
      this.getProductDataUpdated.next(response);
    },
    error: error => {
      this.getProductDataUpdated.next(null);
    }

    })
  }

  checkForOtherLanguagesControlPanel(products)
  {
    if (localStorage.getItem('selectedOrganisation2', ) != null )
    {
      var departmentId = JSON.parse(localStorage.getItem('selectedOrganisation2', )).id;
      if (departmentId == null)
      {
        this.authService.logout();
      }

      let token = localStorage.getItem('token');
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.post(BackendUrl + '/organisations/' + departmentId + "/otherLanguage", products, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {
        if(response == undefined)
        {
          this.getProductDataUpdated.next(null);
        }else{
          this.getProductDataUpdated.next(response);
        }
      },
      error: error => {
        this.getProductDataUpdated.next(null);
      }

      })
    }
  }

  getDatasheetInfo(orgId)
  {
    var departmentId = orgId;
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get(BackendUrl + '/organisations/' + departmentId + '/getdatasheetinfo', {headers: headers})
    .pipe(take(1)).subscribe({next: response => {
      if(response == undefined)
      {
        this.getProductDataUpdated.next(null);
      }else{
        this.getProductDataUpdated.next(response);
      }
    },
    error: error => {
      this.getProductDataUpdated.next(null);
    }

    })
  }

  getMultipleSdsAsTrustedResourceUrlBySareqIdArray(sareqIdArray, date) {

    var i,
        query = {
          sareq_list: [],
          date : date
      };

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    if (_.isArray(sareqIdArray)) {
      for (i = 0; i < sareqIdArray.length; i += 1) {
        query.sareq_list.push({sareq_id: sareqIdArray[i]});
      }

      this.http.post<any>(BackendUrl + "/sareq/multiple_sds", query, {headers: headers}).pipe(take(1)).
        subscribe({next: response => {

          this.getProductDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        }
      });
    }
  }

  getMultipleSdsBySareqIdArray(fileId)
  {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    let params = new HttpParams();
    params = params.append("file_id", fileId);

    this.http.get(BackendUrl + "/sareq/multiple_sds", {headers: headers, params: params, responseType: "arraybuffer"})

      .pipe(take(1)).subscribe({next: response => {

        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getProductDataUpdated.next(fileURL);
      },
      error: error => {
        this.getProductDataUpdated.next(null);
      }
    })
  }

  getMultipleGrbAsTrustedResourceUrlBySareqIdArray(sareqIdArray, date) {

    var i,
        query = {
          sareq_list: [],
          date : date
      };

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    if (_.isArray(sareqIdArray)) {
      for (i = 0; i < sareqIdArray.length; i += 1) {
        query.sareq_list.push({sareq_id: sareqIdArray[i]});
      }

      this.http.post<any>(BackendUrl + "/sareq/multiple_grb", query, {headers: headers}).pipe(take(1)).
        subscribe({next: response => {
          this.getProductDataUpdated.next(response);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        }
      });
    }
  }

  getMultipleGrbBySareqIdArray(fileId)
  {
    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

    let params = new HttpParams();
    params = params.append("file_id", fileId);

    this.http.get(BackendUrl + "/sareq/multiple_grb", {headers: headers, params: params, responseType: "arraybuffer"})

      .pipe(take(1)).subscribe({next: response => {

        var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        this.getProductDataUpdated.next(fileURL);
      },
      error: error => {
        this.getProductDataUpdated.next(null);
      }
    })
  }

  getDepartmentsOfProduct(organisationId, productId) {

    let token = localStorage.getItem('token');

    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("token", token);

    this.http.get(BackendUrl + "/organisations/" + organisationId + "/products/departments/" + productId, {headers: headers})
      .pipe(take(1)).subscribe({next: response => {
        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  deleteImportedProducts(year,orgid,orgname) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("year",year);
    params = params.append("orgname", orgname);

    this.http.delete(BackendUrl + "/organisations/" + orgid + "/products/deleteimportedproducts/", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  replaceDuplicate(oldProductId, newProductId, departments, keep) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedDepartment")).id;
      if (orgId == null) {
        this.authService.logout();
      }
      var info = {
        "oldProductId":oldProductId,
        "newProductId": newProductId,
        "departments":departments,
        "keep":keep
      };
    }
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .put<any>(
        BackendUrl + "/organisations/" + orgId + "/replaceduplicate",
        info,
        { headers: headers }
      )
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getProductDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        },
      });
  }

  checkifidexistsondepartment(newProductId,oldProductId) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedOrganisation")).id;
      if (orgId == null) {
        this.authService.logout();
      }
    }
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    let params = new HttpParams();
    params = params.append("oldproductid",oldProductId);
    this.http
      .get<any>(
        BackendUrl + "/organisations/" + orgId + "/checkifidexistsondepartment/"+newProductId,
        { headers: headers, params: params})
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getProductDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        },
      });
  }

  mailUpdatedSds(id) {
    if (localStorage.getItem("selectedDepartment") != null) {
      var orgId = JSON.parse(localStorage.getItem("selectedOrganisation")).id;
      if (orgId == null) {
        this.authService.logout();
      }
    }
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: token,
    });
    this.http
      .get<any>(
        BackendUrl + "/organisations/" + id + "/mailupdatedsds/",{headers: headers})
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.getProductDataUpdated.next(response);
        },
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
          this.getProductDataUpdated.next(null);
        },
      });
  }

  getChangedProducts(orgId,departments,changedProductsFrom,changedProductsTo) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("departments", departments);
    params = params.append("changedProductsFrom", changedProductsFrom);
    params = params.append("changedProductsTo", changedProductsTo);

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/getChangedProducts/", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        this.getChangedProductsDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getChangedProductsDataUpdated.next(null);
      }
    });


  }

  getStyrenProducts(orgId,departments) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("departments", departments);

    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/getStyrenProducts/", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        this.getProductStyrenDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductStyrenDataUpdated.next(null);
      }
    });


  }

  revisionList(orgId,path) {
    let token = localStorage.getItem('token');
    let data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      return;
    }
    else
    {
      var currentUser = JSON.parse(data);
    }
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    let params = new HttpParams();
    params = params.append("path", path);
    params = params.append("userId", currentUser.id);
    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/revisionList/", {headers: headers, params: params})
      .pipe(take(1)).subscribe({next: response => {
        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  revisionHistory(orgId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get(BackendUrl + "/organisations/" + orgId + "/products/revisionHistory/", {headers: headers})
      .pipe(take(1)).subscribe({next: response => {
        this.getProductDataUpdated.next(response);
      },
      error: error => {
        if (error.status === 401)
        {
          this.authService.logout();
        }
        this.getProductDataUpdated.next(null);
      }
    });
  }

  getAllForEdit( orgId ) {
    let token = localStorage.getItem( 'token' );
    const headers = new HttpHeaders( {
      'Accept': 'application/json',
      'Authorization': token
    } );
    this.http.get( BackendUrl + "/organisations/" + orgId + "/getallforedit", { headers: headers } ).pipe( take( 1 ) ).
      subscribe( { next: response => {
        this.getProductsDataUpdated.next( response );
      },
      error: error => {
        if (error.status === 401) {
          this.authService.logout();
        }
        this.getProductsDataUpdated.next( null );
      }
    } );
  }

  saveAllEditedProducts(orgId, info){
    if ( localStorage.getItem( "selectedOrganisation" ) != null) {
      var orgId = JSON.parse( localStorage.getItem( "selectedOrganisation" ) ).id;
      if ( orgId == null ) {
        this.authService.logout();
      }
    }
    let token = localStorage.getItem("token");
    const headers = new HttpHeaders( {
      Accept: "application/json",
      Authorization: token,
    } );

    this.http
      .put< any > (
        BackendUrl + "/organisations/" + orgId + "/savealledited",
        info,
        {
          headers: headers
        } )
      .pipe( take( 1 ) )
      .subscribe( {
        next: ( response ) => {
          this.getProductDataUpdated.next( response );
        },
        error: ( error ) => {
          if ( error.status === 401 ) {
            this.authService.logout();
          }
          this.getProductDataUpdated.next( null );
        },
      } );
  }

  historyStatisticsReport(orgId,allDep, date1, date2) {
    let params = new HttpParams();
    params = params.append("allDep", allDep);
    params = params.append("date1", date1);
    params = params.append("date2", date2);

    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get(BackendUrl + '/organisations/' + orgId + '/products/gethistorystatistics/',
      {
        headers: headers,
        params: params
      }).
      pipe(take(1)).
      subscribe({next: response => {
        this.getHistoryStatisticsUpdated.next(response);
      },
      error: error => {
        if (error.status === 401) {
          this.authService.logout();
        }
          this.getHistoryStatisticsUpdated.next(null);
      }
    });
  }

  getRestriktionListFromTwoDates(orgId,allDep, date1, date2) {
    let params = new HttpParams();
    params = params.append("allDep", allDep);
    params = params.append("date1", date1);
    params = params.append("date2", date2);

    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
    this.http.get(BackendUrl + '/organisations/' + orgId + '/products/getRestriktionListFromTwoDates/',
      {
        headers: headers,
        params: params
      }).
      pipe(take(1)).
      subscribe({next: response => {
        this.getProductRestTwoDatesUpdated.next(response);
      },
      error: error => {
        if (error.status === 401) {
          this.authService.logout();
        }
          this.getProductRestTwoDatesUpdated.next(null);
      }
    });
  }

  public getProductsDataListener() {
    return this.getProductsDataUpdated.asObservable();
  }

  public getProductDataListener() {
    return this.getProductDataUpdated.asObservable();
  }

  public getAllProductDataListener() {
    return this.getAllProductDataUpdated.asObservable();
  }

  public getProductSingleDataListener() {
    return this.getProductSingleDataUpdated.asObservable();
  }

  public getProductRestDataListener() {
    return this.getProductRestDataUpdated.asObservable();
  }

  public getProductHistoryDataListener() {
    return this.getProducthistoryDataUpdated.asObservable();
  }

  public getProductPphraseDataListener() {
    return this.getProductPphraseDataUpdated.asObservable();
  }

  public getProductCphraseDataListener() {
    return this.getProductCphraseDataUpdated.asObservable();
  }

  public getProductCasDataListener() {
    return this.getProductCasDataUpdated.asObservable();
  }

  public getProductRestrictionDataListener() {
    return this.getProductRestrictionDataUpdated.asObservable();
  }

  public getProductGroupDataListener() {
    return this.getProductGroupDataUpdated.asObservable();
  }

  public getProductLangDataListener() {
    return this.getProductLangDataUpdated.asObservable();
  }

  public getProductCargoDataListener() {
    return this.getProductCargoDataUpdated.asObservable();
  }

  public getProductDepartmentDataListener() {
    return this.getProductDepartmentDataUpdated.asObservable();
  }

  public getProductPictureDataListener() {
    return this.getProductPictureDataUpdated.asObservable();
  }

  public getChangedProductsDataListener() {
    return this.getChangedProductsDataUpdated.asObservable();
  }

  public getProductStyrenDataListener() {
    return this.getProductStyrenDataUpdated.asObservable();
  }

  public getProductAllSubListener() {
    return this.getProductsAllSupUpdated.asObservable();
  }

  public getProductAllSubTopListener() {
    return this.getProductsAllSupTopUpdated.asObservable();
  }

  public getProductCountSubListener() {
    return this.getProductsCountUpdated.asObservable();
  }

  public getDataSheetUpdatedListener() {
    return this.getDataSheetUpdated.asObservable();
  }

  public getHistoryStatisticsListener() {
    return this.getHistoryStatisticsUpdated.asObservable();
  }

  public getProductRestTwoDatesDataListener() {
    return this.getProductRestTwoDatesUpdated.asObservable();
  }

  public getLogoListener() {
    return this.getLogoUpdated.asObservable();
  }
}
