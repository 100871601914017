
<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div [hidden]="loadingData" class="flex flex-col px-2 max-w-full py-2 m-2 border border-gray-200">
    <div class="flex justify-between">
        <p class="flex flex-row items-center hidden md:block space-x-2" *ngIf="products">
            {{"REGISTERED_CHEMICALS" | translate}}: {{products.length}}
        </p>
        <button class="button-cdocblue" (click)="addCatalogue()">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white text-lg">{{"ADD_CHEMICAL" | translate}}</span>
        </button>
    </div>
</div>

<div class="overflow-x-auto" *ngIf="loadingData==false">
    <div class="px-2 py-2 mb-20">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="cataloguetable">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header" colspan="12">
                        <div class="flex flex-row items-center">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="catalogueSearch.all" class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header">{{"NAME" | translate}}</th>
                    <th class="table-row-header">{{"MANUFACTURER" | translate}}</th>
                    <th class="table-row-header">{{"DESCRIPTION" | translate}}</th>
                    <th class="table-row-header">{{"FLAMMABLE" | translate}}</th>
                    <th class="table-row-header">{{"AMOUNT" | translate}}</th>
                    <th class="table-row-header">{{"ANNUAL_CONSUMPTION" | translate}}</th>
                    <th class="table-row-header">{{"UNIT" | translate}}</th>
                    <th class="table-row-header">{{"PROTECTIVE_GEAR" | translate}}</th>
                    <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="hover:bg-gray-200" *ngFor="let product of products
                    | filter : catalogueSearch.all
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" (click)="productSelected(product)">
                    <td class="table-row-column">{{product.name}}</td>
                    <td class="table-row-column">{{product.supplier_name}}</td>
                    <td class="table-row-column">{{product.description}}</td>
                    <td class="table-row-column"*ngIf="product.flammable"><img title="icon" src="images/icons/check-svgrepo-com.svg" class="nav-home"></td>
                    <td class="table-row-column"*ngIf="!product.flammable"></td>
                    <td class="table-row-column">{{product.amount_in_stock}}</td>
                    <td class="table-row-column">{{product.estimated_use_per_year}}</td>
                    <td class="table-row-column">{{product.unit}}</td>
                    <td class="table-row-column">{{product.protective_gear}}</td>
                    <td class="table-row-column" *ngIf="product.sds_file_id">
                        <img tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="$event.stopPropagation();openSdsInNewWindow(product)" />
                    </td>
                    <td class="table-row-column" *ngIf="!product.sds_file_id"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6 static" colspan="2">
                        <div class="inline-block text-left" appDropdown>
                            <button class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static right-0 overflow-y-auto" #dropdownMenuInside>
                                <div class="py-1">
                                <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
