import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StaticDataService } from 'src/app/services/static-data.service';
import { UtilService } from 'src/app/services/util.service';
import { ProductService } from 'src/app/services/product.service';
import { SareqService } from 'src/app/services/sareq.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import { OrganisationService } from 'src/app/services/organisation.service';
declare var $: any;

@Component({
  selector: 'app-modal-edit-product',
  templateUrl: './modal-edit-product.component.html',
  styleUrls: ['./modal-edit-product.component.css']
})
export class ModalEditProductComponent implements OnInit {

  @Input() thisProduct;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  product: any = null;
  subscribesToFootprint: any;
  units: any;
  modalTitle: any;
  groups: any = [];
  selectedDepartment: any;
  replacedProductName: any;
  result: any;
  placeholder=({
    'footprint_comment':'',
    'department_info':'',
    'unit':'',
    'group':''
  });
  listAlldepartments: any;
  organisation: any;
  replace:boolean=false;
  replaceproduct:boolean=true;
  oldproductarray = [];
  exchangeDepsItemAll:boolean=false;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder:FormBuilder,
              private staticDataService: StaticDataService,
              private utilService: UtilService,
              private productService: ProductService,
              private organisationService: OrganisationService,
              private sareqService: SareqService,
              private authService: AuthService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.product = this.thisProduct;
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));

    if (this.product != null) {

      if(this.product.o_footprint_comment!=null){
        this.placeholder.footprint_comment=this.product.o_footprint_comment;
      }else{
        this.placeholder.footprint_comment=this.translate.instant('FOOTPRINT_COMMENT_PLACEHOLDER');
      }

      if(this.product.department_info!=null){
        this.placeholder.department_info=this.product.department_info;
      }else{
        this.placeholder.department_info=this.translate.instant('DEPARTMENT_INFORMATION');
      }

      if(this.product.unit&&this.product.unit!=null&&this.product.unit.trim()!=""){
        this.placeholder.unit=this.product.unit;
      }else{
        this.placeholder.unit=this.translate.instant("UNIT");
      }

      if(this.product.group_name&&this.product.group_name!=null&&this.product.group_name.trim()!=""){
        this.placeholder.group=this.product.group_name;
      }else{
        this.placeholder.group=this.translate.instant("PRODUCT_GROUP");
      }

      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      this.subscribesToFootprint = this.utilService.subscribesTo('subscription_footprint');
      this.units = this.staticDataService.getUnitDefinitions();

      if (this.product) {

        this.modalTitle = this.product.product_name;

        this.result = {
          status: "",
          departmentId: this.selectedDepartment.id,
          product: {
            "department_info": this.product.department_info,
            "amount": this.product.amount,
            "annual_consumption": this.product.annual_consumption,
            "maximum_amount": this.product.maximum_amount,
            "unit": "",
            "group": "",
            "o_footprint_water": this.product.o_footprint_water,
            "o_footprint_air": this.product.o_footprint_air,
            "o_footprint_product": this.product.o_footprint_product,
            "o_footprint_waste": this.product.o_footprint_waste,
            "o_footprint_comment": this.product.o_footprint_comment,
            "replaced_by":this.product.replaced_by
          }
        };
      }
      this.getAllDepartments();
      this.reloadGroups();
    }
  }

  ngOnDestroy(): void {
  }

  getAllDepartments() {
    if (this.listAlldepartments==null||this.organisation&&this.organisation.id!=JSON.parse(localStorage.getItem('rootOrganisation', )).id) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  reloadGroups() {
    this.productService.getGroups(this.selectedDepartment.id);
    this.productService.getProductGroupDataListener().pipe(take(1)).subscribe({
      next: groups => {
        this.groups = groups;
      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  addGroup() {
    var newGroup = null;
    Swal.fire({
      title: this.translate.instant("ADD_GROUP"),
      text: this.translate.instant("GROUP_NAME"),
      input: 'text',
      showCancelButton: true,
      cancelButtonText: this.translate.instant("CANCEL"),
      inputPlaceholder: this.translate.instant("GROUP_NAME")
    }).then((result) => {
      if (result.value) {
        this.productService.createNewGroup(this.selectedDepartment.id, result.value);
        this.productService.getProductDataListener().pipe(take(1)).subscribe({
          next: group => {
            if (group != null) {
              this.reloadGroups();
            }
          },
          error: error => {
            console.log("Error: ", error);
          }
        })
      }
    });
  }

  setUnit(unit) {
    this.result.product["unit"] = unit;
    this.product["unit"] = unit.name;
  }

  setGroup(group) {
    this.result.product["group"] = group;
    this.product["group_name"] = group.name;
  }

  save() {
    if(this.result.product["unit"]==""&&this.product.unit!=""){
      if (_.isString(this.product.unit)) {
        for (var i = 0; i < this.units.length; i += 1) {
            if (this.product.unit?.toLowerCase() === this.units[i].id) {
              this.result.product["unit"] = this.units[i];
                break;
            }
        }
      }else{
        this.result.product["unit"]=this.product.unit;
      }
    }

    if (this.product["o_footprint_water"]) {
      this.result.product["o_footprint_water"] = true;
    } else {
      this.result.product["o_footprint_water"] = false;
    }

    if (this.product["o_footprint_air"]) {
      this.result.product["o_footprint_air"] = true;
    } else {
      this.result.product["o_footprint_air"] = false;
    }

    if (this.product["o_footprint_product"]) {
      this.result.product["o_footprint_product"] = true;
    } else {
      this.result.product["o_footprint_product"] = false;
    }

    if (this.product["o_footprint_waste"]) {
      this.result.product["o_footprint_waste"] = true;
    } else {
      this.result.product["o_footprint_waste"] = false;
    }

    if (_.isObject(this.result.product["group"])) {

    }

    this.result.product.amount=this.formatNumbers(this.result.product.amount);
    this.result.product.annual_consumption=this.formatNumbers(this.result.product.annual_consumption);
    this.result.product.maximum_amount=this.formatNumbers(this.result.product.maximum_amount);
    this.result.status = "update";
    this.passEntry.emit(this.result);
    this.activeModal.close(ModalEditProductComponent);
  }

  change(){
    // First I check that it's a valid product
    this.oldproductarray = [];
    let newproductarray = [];
    this.sareqService.getname(this.product.replaced_by);
    this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
      next: (responce) => {
          if(responce=='Not found'){
            Swal.fire({
              title: this.translate.instant('PRODUCT_NOT_FOUND'),
              text:  this.translate.instant('PRODUCT_NOT_FOUND_DATABASE'),
              icon: "error",
              confirmButtonText: this.translate.instant("OK"),
            });
            return;
          }
          else
          {
            this.replacedProductName = responce.rows[0].name;
            this.productService.checkifidexistsondepartment(this.product.replaced_by,this.product.id);
            this.productService
              .getProductDataListener()
              .pipe(take(1))
              .subscribe({
                next: (exists) => {
                  if (exists&&exists.length>0) {
                    for(var n=0;n<exists.length;n++){
                      if(exists[n].product_id==this.product.replaced_by){
                        if(exists[n].organisation_id==this.selectedDepartment.id){
                          let text = this.translate.instant("POST_ALREADY_EXISTS_ON_DEPARTMENT");
                          Swal.fire({
                            title: this.translate.instant("REPLACEMENT_ON_DEPARTMENT"),
                            text:  responce.rows[0].name+' '+text,
                            icon: "error",
                            confirmButtonText: this.translate.instant("OK"),
                          });
                          return;
                        }
                        newproductarray.push(exists[n].organisation_id);
                      }
                    }

                    for(var n=0;n<exists.length;n++){
                      if(exists[n].product_id==this.product.id){
                        if(newproductarray.indexOf(exists[n].organisation_id)>-1){

                        }else{
                          if(exists[n].organisation_id==this.selectedDepartment.id){
                            this.oldproductarray.push({
                              "orgid":exists[n].organisation_id,
                              "name":this.utilService.departFullName(exists[n].organisation_id, this.listAlldepartments),
                              "active":true
                            })
                          } else {
                            this.oldproductarray.push({
                              "orgid":exists[n].organisation_id,
                              "name":this.utilService.departFullName(exists[n].organisation_id, this.listAlldepartments),
                              "active":false
                            })
                          }
                        }
                      }
                    }
                    this.oldproductarray=_.orderBy(this.oldproductarray, 'active', 'desc');
                    this.replace=true;
                  } else {
                    Swal.fire(
                      this.translate.instant("WARNING"),
                      this.translate.instant("UNKNOWN_ERROR"),
                      "error"
                    );
                    return;
                  }
              },
          error: (error) => {
            Swal.fire(
              this.translate.instant("WARNING"),
              this.translate.instant("UNKNOWN_ERROR"),
              "error"
            );
            return;
          },
        });
          }
      },
    error: error => {
      console.log("Update error: ", error);
      this.utilService.onError(error);
    }
  });
  }

  remove() {
    Swal.fire({
      title: this.translate.instant("REMOVE") + "?",
      input: 'text',
      html: this.translate.instant("CONFIRM_REMOVE_PRODUCT_FROM_DEPARTMENT") + "<br><br><div align='left'>" + this.translate.instant("COMMENT") + ":</div>",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("NO"),
      confirmButtonText: this.translate.instant("YES"),
      closeOnConfirm: true,
      inputPlaceholder: this.translate.instant("COMMENT")
    }).then((result) => {
      if (result.isConfirmed) {
        this.result.status = "remove";
        this.result.comment = result.value;
        this.passEntry.emit(this.result);
      }
      this.activeModal.close(ModalEditProductComponent);
    });
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalEditProductComponent);
  }

  formatNumbers(string){
    // The database doesnt accept swedish decimal comma on numbers
    if(!string){ return; }
    else if(string.toString().indexOf(",")==-1) {
      string = Math.round(Number(string)*1000)/1000;
      return string;
    } else {
      string = Math.round(Number(string.replace(',','.'))*1000)/1000;
      return string;
    }
  }

  logout() {
    this.authService.logout();
  }

  doexchange(){
    this.productService.replaceDuplicate(this.product.id,this.product.replaced_by,this.oldproductarray,this.replaceproduct);
    this.productService.getProductDataListener().pipe(take(1)).subscribe({
    next: response => {
      if (response.message == 'Ok') {
        Swal.fire(
          this.translate.instant("INFORMATION"),
          this.translate.instant("PRODUCT_EXCHANGED"),
          "success"
        );
        if(this.replaceproduct!=true){
          this.result.status = "exchangeandkeep";
        } else {
          this.result.status = "exchangeandremove";
        }

        this.result.replaced_by = this.product.replaced_by;
        this.passEntry.emit(this.result);
        this.activeModal.close(ModalEditProductComponent);
        return;
      } else {
        Swal.fire(
          this.translate.instant("WARNING"),
          this.translate.instant("UNKNOWN_ERROR"),
          "error"
        );
    return;
  }
},
error: error => {
  Swal.fire(
    this.translate.instant("WARNING"),
    this.translate.instant("UNKNOWN_ERROR"),
    "error"
  );
  return;
}
})
  }

  checkitem(replaceproduct){
   if(replaceproduct==this.replaceproduct){
    return;
   }
   else if(replaceproduct==true){
    this.replaceproduct=true;
   }
   else if(replaceproduct==false){
    this.replaceproduct=false;
    }
  }

  activateordeactivateitem(index){
    if(index==-1) {
      this.exchangeDepsItemAll=!this.exchangeDepsItemAll;
      for(var n=0;n<this.oldproductarray.length;n++) {
        if(this.selectedDepartment.id!=this.oldproductarray[n].orgid){
          this.oldproductarray[n].active=this.exchangeDepsItemAll;
        }
      }
    }
    else {
      if(this.oldproductarray[index].active==true) {
        this.oldproductarray[index].active=false;
      } else {
        this.oldproductarray[index].active=true;
      }
    }
  }
}
