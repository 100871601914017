<div class="max-w-screen m-8">
  <div class="px-6 py-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg text-center">
    <div class="text-2xl font-bold text-center mb-4">{{"SUPPORT" | translate}}</div>
    <div class="mb-4">{{"SUPPORT_TEXT_1" | translate}}<br/>{{"SUPPORT_TEXT_2" | translate}}</div>
    <p>{{"SUPPORT_TEXT_3" | translate}}</p>
    <p>{{"SUPPORT_TEXT_4" | translate}}</p>
    <p class="mb-4">{{"SUPPORT_TEXT_5" | translate}}</p>
    <p class="text-blue-700 mb-4"><a href="mailto:support@cdoc.se">{{"SUPPORT_TEXT_6" | translate}}</a></p>
    <hr/>
    <p>Chemical Documentation cDoc AB<br/>Telefon: 035-10 81 89<br/>E-mail: support@cdoc.se</p>
  </div>
</div>


