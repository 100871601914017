import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ProductService } from 'src/app/services/product.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { take } from 'rxjs/operators';
import { FilterByPipe } from 'ngx-pipes';
import { OrderByPipe} from 'ngx-pipes';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-stamped-sds',
  templateUrl: './stamped-sds.component.html',
  styleUrls: ['./stamped-sds.component.css']
})

export class StampedSdsComponent implements OnInit {

  currentUser: any;
  isUserProfile: boolean;
  isCompanyUsers: boolean;
  isDepartmentSetup: boolean;
  isGroups: boolean;
  isCompanyInfo: boolean;
  isArticles: boolean;
  isStampedSds: boolean;
  isMisc: boolean;
  subscription_stamped_SDS: boolean;
  subscribesToFreemium: boolean;
  currentLanguage: any;
  organisation: any;
  products: any;
  productNames: any = [];
  allDepartmentNames: any[];
  itemsPerPage:number=10;
  selectedDepartment: any;
  depid: any;
  pageNumber:number=1;
  file: any;
  stampedSdsSearch = {
    all: ""
  };
  productsArticle;
  productsStampDate;
  tempproductsStampDate;
  productStampNames = [];
  sortItems:any;

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
              private translate: TranslateService, private formBuilder:FormBuilder, private organisationService: OrganisationService,
              private productService: ProductService, private userService:UserService, private uploadService: FileUploadService) { }

  ngOnInit(): void {

    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = true;
    this.isMisc = false;
    this.subscription_stamped_SDS = false;
    this.subscribesToFreemium = false;
    this.file = null;
    this.productNames = [];
    this.currentLanguage = localStorage.getItem('usedLanguage');

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    if (localStorage.getItem('selectedDepartment', ) == null) {
      this.selectedDepartment = JSON.parse(localStorage.getItem('rootOrganisation', ));
    }
    else {
      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      if (this.selectedDepartment == null) {
        this.selectedDepartment = JSON.parse(localStorage.getItem('rootOrganisation', ));
      }
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscription_stamped_SDS = this.utilService.subscribesTo("subscription_stamped_SDS");
    this.subscribesToFreemium = this.utilService.subscribesTo("subscription_freemium");

    this.getDepartmentNames();
    this.getStampedInformation();
    this.sortItems=[
      {'name':'name',           'order':'asc'},
      {'name':'organisation_id','order':'asc'},
      {'name':'name',           'order':'asc'},
      {'name':'supplier_name',  'order':'asc'},
      {'name':'sds_date',       'order':'desc'},
      {'name':'sds_version',    'order':'desc'},
      {'name':'stamp_date',     'order':'desc'}
    ];

    this.getItemsPerPage();
  }

  logout() {
    this.authService.logout();
  }

  getDepartmentNames() {

    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments != null)
          {
            this.allDepartmentNames = departments;
          }
        },
        error: error => {
          console.error('getDepartmentNames()', error);
        }
      })
    }
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  getStampedInformation() {

    if (this.organisation) {
      this.productService.AllWithSuborganisationsLessInformationAndStamped(this.organisation.id);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: products => {
          this.products = products;
          this.productsArticle = _.clone(products);
          this.productsStampDate = _.clone(products);

          for( var i = 0; i < products.length; i++ ) {
            this.productNames.push( products[i].name );
            if(this.products[i].stamped_sds.sds_version&&this.products[i].stamped_sds.sds_version!=null){
              this.products[i].sds_version=this.products[i].stamped_sds.sds_version?.toString();
            }else{
              this.products[i].sds_version='';
            }
          }

          this.tempproductsStampDate=[];
            for( var i = 0; i < this.productsStampDate.length; i++ ) {
              if(this.productStampNames.indexOf(this.productsStampDate[i].name)==-1){
                this.productStampNames.push(this.productsStampDate[i].name);
                this.productsStampDate[i].departments=[];
                this.productsStampDate[i].departments.push(this.productsStampDate[i].organisation_id);
                this.tempproductsStampDate.push(this.productsStampDate[i]);
                } else {
                  for( var j = 0; j < this.tempproductsStampDate.length; j++ ) {
                    if(this.tempproductsStampDate[j].name==this.productsStampDate[i].name){
                      this.tempproductsStampDate[j].departments.push(this.productsStampDate[i].organisation_id);
                    }
                  }
                }
            }
        this.products=this.tempproductsStampDate;
          this.sortItem(6);
        },
        error: error => {
        }
      })
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.allDepartmentNames);
  }

  openSdsInNewWindow(product) {
    var filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          // window.open(pdfContent);
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  openStampedSdsInNewWindow(product) {
    var filename = product.name + '.pdf';
    this.productService.getStampedSdsAsTrustedResourceUrl(product.stamped_sds.sds_stamp_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          // window.open(pdfContent);
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  incomingfile(event, product) {
    product.uploadStampedDocument = event.target.files[0];
  }

  uploadFile(product) {

    var object;

    this.depid=[];
    this.depid.push(product.organisation_id);

    this.uploadService.upload(product.uploadStampedDocument);
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
      next: response => {
        if (response.filename)
        {
          if (product.stamped_sds.sds_stamp_id != "") {
            object=({
              'org_id': this.organisation.id,
              'file_id': response.filename,
              'sds_id': product.stamped_sds.sds_id,
              'action':'update'
            });
          } else {
              object=({
                'org_id': this.organisation.id,
                'dep_id': this.depid,
                'file_id': response.filename,
                'sds_id': product.stamped_sds.sds_id,
                'sds_date': product.stamped_sds.sds_date,
                'version': product.stamped_sds.sds_version,
                'action':'create'
              });
          }

          this.organisationService.addEachFile(object);
          this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
            next: pdfContent => {
              product.uploadStampedDocument = null;
              window.location.reload();
            },
            error: error => {
              console.log("Error: ", error);
              product.uploadStampedDocument = null;
            }
          })
        }
      },
      error: error => {
        console.log("Error: ", error);
        product.uploadStampedDocument = null;
      }
    })

    product.uploadStampedDocument = null;
  }

  showUserProfile() {
    this.isUserProfile = true;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/userprofile']);
  }

  showCompanyUsers() {
    this.isUserProfile = false;
    this.isCompanyUsers = true;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyusers']);
  }

  showDepartmentSetup() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = true;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/editdepartments']);
  }

  showGroups() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = true;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/productgroup']);
  }

  showCompanyInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = true;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyinfo']);
  }

  showArticles() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = true;
    this.isStampedSds = false;
    this.isMisc = false;

    this.router.navigate(['/companyarticles']);
  }

  showStampedSds() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = true;
    this.isMisc = false;

    this.router.navigate(['/stampedsds']);
  }

  showMiscInfo() {
    this.isUserProfile = false;
    this.isCompanyUsers = false;
    this.isDepartmentSetup = false;
    this.isGroups = false;
    this.isCompanyInfo = false;
    this.isArticles = false;
    this.isStampedSds = false;
    this.isMisc = true;

    this.router.navigate(['/companymisc']);
  }

  sortItem(id){
    // Check the documentation at
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort

    if(id==1){ // Dont want to search for department when its not needed
      if(this.sortItems[id].order=='asc'){
        this.sortItems[id].order='desc';
        this.products.sort((a, b) => (this.departFullName(a[this.sortItems[id].name]) > this.departFullName(b[this.sortItems[id].name])) ? 1 : -1);
      } else if(this.sortItems[id].order=='desc'){
        this.sortItems[id].order='asc';
        this.products.sort((a, b) => (this.departFullName(a[this.sortItems[id].name]) > this.departFullName(b[this.sortItems[id].name])) ? -1 : 1);
      }
    }else{
      if(this.sortItems[id].order=='asc'){
        this.sortItems[id].order='desc';
        this.products.sort((a, b) => (a[this.sortItems[id].name]?.toLowerCase() > b[this.sortItems[id].name]?.toLowerCase()) ? 1 : -1);
      } else if(this.sortItems[id].order=='desc'){
        this.sortItems[id].order='asc';
        this.products.sort((a, b) => (a[this.sortItems[id].name]?.toLowerCase() > b[this.sortItems[id].name]?.toLowerCase()) ? -1 : 1);
      }
    }
  }

  openGrbInNewWindow(product) {
    var filename = product.product_name + '_risk.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        }
        else {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  openExtSdsInNewWindow(product) {
    var filename = product.product_name + '.pdf';
    this.productService.getExtSdsAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          // var popup = window.open('')
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if(!opened){
            window.location.href=pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }
}
