<div class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
      <div class="flex flex-row">
          <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
              <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
              <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
            </svg>
          </button>
      </div>
</div>

<div class="flex flex-col bg-white w-full rounded shadow-lg mb-40 md:mb-20 p-7">
      <div class="overflow-x-auto ">
          <table class="table-auto bg-white mb-4 w-full">
            <thead>
                  <tr>
                        <th class="table-row-header sort-header">{{ 'RESTRICTION_LISTS' | translate }}</th>
                        <th class="table-row-header sort-header"></th>
                  </tr>
            </thead>
            <tbody>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp AMV A lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfile"
                                          (change)="fileChange($event,'AMV_A')" placeholder="Upload file" id="upload-file" accept=".*" max-size="30MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListAmvA()" [hidden]="!restrictionfile">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp AMV B lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileb"
                                          (change)="fileChange($event,'AMV_B')" placeholder="Upload file" id="upload-file" accept=".*" max-size="30MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListAmvB()" [hidden]="!restrictionfileb">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp Reach 14 lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileR14"
                                          (change)="fileChange($event,'R14')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListR14()" [hidden]="!restrictionfileR14">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp Reach 17 lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileR17"
                                          (change)="fileChange($event,'R17')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListR17()" [hidden]="!restrictionfileR17">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp Reach limit lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileRLimit"
                                          (change)="fileChange($event,'RLimit')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListRLimit()" [hidden]="!restrictionfileRLimit">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp Reach PRIO lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileRPrio"
                                          (change)="fileChange($event,'RPrio')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListRPrio()" [hidden]="!restrictionfileRPrio">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp Reach Candidate lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileRCandidate"
                                          (change)="fileChange($event,'RCandidate')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListRCandidate()" [hidden]="!restrictionfileRCandidate">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp water directive lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileWater"
                                          (change)="fileChange($event,'Water')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListWater()" [hidden]="!restrictionfileWater">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp SLL lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileSLL"
                                          (change)="fileChange($event,'SLL')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListSLL()" [hidden]="!restrictionfileSLL">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>

                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp SIN lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileSIN"
                                          (change)="fileChange($event,'SIN')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListSIN()" [hidden]="!restrictionfileSIN">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                        <td class="table-row-column" class="two wide column"><label>{{"Ladda upp BASTA / ED lista:  "}}</label></td>
                        <td class="table-row-column" class="four wide column">
                              <div class="flex flex-row">
                                    <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileBASTA"
                                          (change)="fileChange($event,'BASTA')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                    <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListBASTA()" [hidden]="!restrictionfileBASTA">
                                          <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                                9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                                21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                          </svg>
                                    </button>
                              </div>
                        </td>
                  </tr>
                  <tr>
                    <td class="table-row-column" class="two wide column"><label>{{"Ladda upp PoPs lista:  "}}</label></td>
                    <td class="table-row-column" class="four wide column">
                          <div class="flex flex-row">
                                <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfilePOPS"
                                      (change)="fileChange($event,'POP')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                                <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListPOP()" [hidden]="!restrictionfilePOPS">
                                      <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                            9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                            21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                      </svg>
                                </button>
                          </div>
                    </td>
              </tr>
              <tr>
                <td class="table-row-column" class="two wide column"><label>{{"Ladda upp RoHS lista:  "}}</label></td>
                <td class="table-row-column" class="four wide column">
                      <div class="flex flex-row">
                            <input title="{{'ATTACHE_FILES' | translate}}" class="button-white mt-2 border border-gray-300 mr-1" type="file" [hidden]="restrictionfileROHS"
                                  (change)="fileChange($event,'ROHS')" placeholder="Upload file" id="upload-file" accept=".*" max-size="20MB" />
                            <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadRestrictionListROHS()" [hidden]="!restrictionfileROHS">
                                  <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                                        9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                                        21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                  </svg>
                            </button>
                      </div>
                </td>
          </tr>
            </tbody>
          </table>
  </div>
</div>

