import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/services/report.service';
import { FormBuilder, Validators } from '@angular/forms';
import { StaticDataService } from 'src/app/services/static-data.service';
import { ReportSimplifiedComponent } from 'src/app/reports/report-simplified/report-simplified.component';
import { ReportInventoryComponent } from 'src/app/reports/report-inventory/report-inventory.component';
import { ReportFireComponent } from 'src/app/reports/report-fire/report-fire.component';
import { ReportCmrComponent } from 'src/app/reports/report-cmr/report-cmr.component';
import { ReportNoteComponent } from 'src/app/reports/report-note/report-note.component';
import { ReportSdsComponent } from 'src/app/reports/report-sds/report-sds.component';
import { ReportGrbComponent } from 'src/app/reports/report-grb/report-grb.component';
import { ReportClassificationComponent } from 'src/app/reports/report-classification/report-classification.component';
import { ReportEnvironmentComponent } from 'src/app/reports/report-environment/report-environment.component';
import { ReportSdsActivityComponent } from 'src/app/reports/report-sds-activity/report-sds-activity.component';
import { ReportSllComponent } from 'src/app/reports/report-sll/report-sll.component';
import { ReportKSimplifiedComponent } from 'src/app/reports/report-k-simplified/report-k-simplified.component';
import { ReportArticleComponent } from 'src/app/reports/report-article/report-article.component';
import { ReportAllergenicComponent } from 'src/app/reports/report-allergenic/report-allergenic.component';
import { ReportSubstitutionComponent } from 'src/app/reports/report-substitution/report-substitution.component';
import { ReportStyrenComponent } from 'src/app/reports/report-styren/report-styren.component';
import { ReportSareqHistoryComponent } from 'src/app/reports/report-sareq-history/report-sareq-history.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {

  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any;
  showSubdepartments: boolean = false;
  subscribesToFreemium: boolean = false;
  supportedReportTypes: any = [];
  reportInformation: any;
  dateoptions: any;
  chosenComponent: any;
  departmentChanged:boolean=false;
  Report_Sareq_History:boolean=false;
  reportForm: any;

  constructor(private utilService: UtilService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService,
    private productService: ProductService,
    private router: Router,
    private formBuilder: FormBuilder,
    private staticDataService: StaticDataService,
    private reportService: ReportService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    this.reportForm = this.formBuilder.group({
      type: [''],
      date: ['']
    });

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToFreemium = this.utilService.subscribesTo('subscription_freemium');

    if(!this.departmentChanged){
      this.supportedReportTypes = this.staticDataService.getSupportedReportTypes();

      if (this.supportedReportTypes[0]) {
        this.reportInformation = {
          start_date: new Date("2015 1 January"),
          date: new Date(),
          reportType: this.supportedReportTypes[0],
          name: this.supportedReportTypes[0].name,
          id: this.supportedReportTypes[0].id,
          includeSubDepartments:this.reportInformation&&this.reportInformation.includeSubDepartments==false?false:true,
          registerProducts: this.reportInformation?this.reportInformation.registerProducts:false,
          products: this.reportInformation?this.reportInformation.product:false,
          product: this.reportInformation?this.reportInformation.product:false
        };
      }
    } else {
      this.departmentChanged=false;
    }

    this.dateoptions = {
      maxDate: new Date()
    };

    this.getAllDepartments();

    // Change department
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);
      this.departmentChanged=true;
      this.ngOnInit();
    })
  }

  choseReport(type) {
    this.chosenComponent = null;

    switch(type) {

      case this.translate.instant("REPORT_SIMPLIFIED"):
      case "REPORT_SIMPLIFIED":
        this.chosenComponent = ReportSimplifiedComponent;
        this.getChosenReportType("REPORT_SIMPLIFIED");
        break;

      case this.translate.instant("REPORT_STATUTORY"):
      case "REPORT_STATUTORY":
        this.chosenComponent = ReportInventoryComponent;
        this.getChosenReportType("REPORT_STATUTORY");
        break;

      case this.translate.instant("REPORT_FIRE"):
      case "REPORT_FIRE":
        this.chosenComponent = ReportFireComponent;
        this.getChosenReportType("REPORT_FIRE");
        break;

      case this.translate.instant("REPORT_CMR"):
      case "REPORT_CMR":
        this.chosenComponent = ReportCmrComponent;
        this.getChosenReportType("REPORT_CMR");
        break;

      case this.translate.instant("REPORT_NOTE"):
      case "REPORT_NOTE":
        this.chosenComponent = ReportNoteComponent;
        this.getChosenReportType("REPORT_NOTE");
        break;

      case this.translate.instant("REPORT_SDS"):
      case "REPORT_SDS":
        this.chosenComponent = ReportSdsComponent;
        this.getChosenReportType("REPORT_SDS");
        break;

      case this.translate.instant("REPORT_GRB"):
      case "REPORT_GRB":
        this.chosenComponent = ReportGrbComponent;
        this.getChosenReportType("REPORT_GRB");
        break;

      case this.translate.instant("REPORT_PH_CLASSIFICATION"):
      case "REPORT_PH_CLASSIFICATION":
        this.chosenComponent = ReportClassificationComponent;
        this.getChosenReportType("REPORT_PH_CLASSIFICATION");
        break;

      case this.translate.instant("REPORT_ENVIROMENT"):
      case "REPORT_ENVIROMENT":
        this.chosenComponent = ReportEnvironmentComponent;
        this.getChosenReportType("REPORT_ENVIROMENT");
        break;

      case this.translate.instant("REPORT_SDS_ACTIVITY"):
      case "REPORT_SDS_ACTIVITY":
        this.chosenComponent = ReportSdsActivityComponent;
        this.getChosenReportType("REPORT_SDS_ACTIVITY");
        break;

      case this.translate.instant("REPORT_SLL"):
      case "REPORT_SLL":
        this.chosenComponent = ReportSllComponent;
        this.getChosenReportType("REPORT_SLL");
        break;

      case this.translate.instant("REPORT_K_SIMPLIFIED"):
      case "REPORT_K_SIMPLIFIED":
        this.chosenComponent = ReportKSimplifiedComponent;
        this.getChosenReportType("REPORT_K_SIMPLIFIED");
        break;

      case this.translate.instant("REPORT_ARTICLES"):
      case "REPORT_ARTICLES":
        this.chosenComponent = ReportArticleComponent;
        this.getChosenReportType("REPORT_ARTICLES");
        break;

      case this.translate.instant("REPORT_ALLERGENIC"):
      case "REPORT_ALLERGENIC":
        this.chosenComponent = ReportAllergenicComponent;
        this.getChosenReportType("REPORT_ALLERGENIC");
        break;

      case this.translate.instant("REPORT_SUBSTITUTION"):
      case "REPORT_SUBSTITUTION":
        this.chosenComponent = ReportSubstitutionComponent;
        this.getChosenReportType("REPORT_SUBSTITUTION");
        break;

      case this.translate.instant("REPORT_STYREN"):
      case "REPORT_STYREN":
            this.chosenComponent = ReportStyrenComponent;
            this.getChosenReportType("REPORT_STYREN");
            break;

      case this.translate.instant("REPORT_SAREQ_HISTORY"):
      case "REPORT_SAREQ_HISTORY":
            this.chosenComponent = ReportSareqHistoryComponent;
            this.getChosenReportType("REPORT_SAREQ_HISTORY");
            break;

      default:
        break;
    }
  }

  createReport() {
    if (this.chosenComponent == null) {
      this.choseReport("REPORT_SIMPLIFIED");
    }

    if (this.chosenComponent != null) {
      const modalRef = this.modalService.open(this.chosenComponent, {windowClass: 'gr-modal-full', backdrop: "static"});
      modalRef.componentInstance.reportInfo = this.reportInformation;
    }
  }

  onToggleShowSubDepartments(){
    this.reportInformation.includeSubDepartments = !this.reportInformation.includeSubDepartments;
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
          return (null);
        }
      })
    }
    else
    {
      return (null);
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  logout() {
    this.authService.logout();
  }

  getChosenReportType(name){
    var reportLength=this.supportedReportTypes.length;
    for(let n=0;n<reportLength;n++){
      if(this.supportedReportTypes[n].translation==name){
        this.reportInformation.reportType = this.supportedReportTypes[n];
        this.reportInformation.name = this.supportedReportTypes[n].name;
        this.reportInformation.id = this.supportedReportTypes[n].id;
        if(this.reportInformation.name=="REPORT_SAREQ_HISTORY"||this.reportInformation.name==this.translate.instant("REPORT_SAREQ_HISTORY")){
          this.Report_Sareq_History=true;
        } else {
          this.Report_Sareq_History=false;
        }
        break;
      }
    }
  }
}
