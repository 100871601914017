<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-30" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-center">
            <div class="text-gray-900 font-bold">{{productName}}!</div>
            <!-- Modal Body -->
            <div class="flex flex-col center-items mt-10 p-2">
                <div *ngIf="departments!=null">
                    <span class="font-bold text-gray-600 text-left">{{"CHOOSE_DEPARTMENT" | translate}}</span>
                    <div class="relative inline-block text-left z-10 w-full" id="selectRisk" appDropdown>
                        <button type="button" class="text-black-900 flex items-start p-2 justify-between border border-gray-400 w-full rounded-md mt-4 mb-4">
                            <span>{{departments[0].name}}</span>
                            <svg class="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div id="dropdownMenu" class="hidden dropdown-menu" #dropdownMenuInside>
                            <div class="py-5" *ngFor="let department of departments" data-value="department.name" class="dropdown-menu-item" 
                                (click)="setDepartment(department)" id="modalSelectProductButton1" tabindex="1">{{ department.name }} </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Footer -->
            <div class="items-center mt-10">
                <button type="button" 
                    class="px-4 py-2 bg-cdoclightblue text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300" 
                    (click)="submit()">
                    {{"CHOOSE" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>