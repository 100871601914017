import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-modal-select-product-department',
  templateUrl: './modal-select-substitution-product.component.html',
  styleUrls: ['./modal-select-substitution-product.component.css']
})
export class ModalSelectSubstitutionProductComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() productName;
  @Input() substitutionProducts;
  @Input() variousDepartments;
  substitutionDepsItemAll:boolean=false;
  selectedDepartment;
  selectedDepartmentValue;
  approvedProduct;
  selectSubstitutionProductForm: any;

  chosenSubstitutionProduct: any = null;
  noProduct: boolean = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder, private authService: AuthService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.selectedDepartmentValue = localStorage.getItem('selectedDepartment', )
    if (this.selectedDepartmentValue) {
      this.selectedDepartment = JSON.parse(this.selectedDepartmentValue).id;
    }

    this.selectSubstitutionProductForm = this.formBuilder.group({
      selectedSubstitutionProduct: [''],
    });

    if(this.substitutionProducts){
      for(let n = 0;n<this.substitutionProducts.length;n++){
        if(this.substitutionProducts[n].approved==1){
          this.approvedProduct=[{'name':this.substitutionProducts[n].name,'id':this.substitutionProducts[n].id}];
        }
      }
    }

    if(!this.approvedProduct){
      this.approvedProduct=[{'name':'noproduct','id':'noproduct'}];
    }
  }

  ngOnDestroy(): void {
  }

  toggleNoProductCheckbox(event) {
    this.noProduct = false;
    if (event.target.checked == true)
    {
      this.noProduct = true;
    }
  }

  activateordeactivateitem(index){
    if(index==-1) {
      this.substitutionDepsItemAll=!this.substitutionDepsItemAll;
      for(var n=0;n<this.variousDepartments.length;n++) {
        if(this.selectedDepartment!=this.variousDepartments[n].organisation_id){
          this.variousDepartments[n].active=this.substitutionDepsItemAll;
        }
      }
    }
    else {
      if(this.variousDepartments[index].active==true) {
        this.variousDepartments[index].active=false;
      } else {
        this.variousDepartments[index].active=true;
      }
    }
  }

  setSubstitutionProduct(substitutionProduct) {
    this.chosenSubstitutionProduct = this.approvedProduct;
    this.submit();
  }

  submit() {
    var info = ({
      'product':this.approvedProduct,
      'departments':this.variousDepartments
    });
    this.passEntry.emit(info);
    this.activeModal.close(ModalSelectSubstitutionProductComponent);
  }

  logout() {
    this.authService.logout();
  }

  close() {
    this.passEntry.emit(null);
    this.activeModal.close(ModalSelectSubstitutionProductComponent);
  }
}
