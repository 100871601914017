<div class="container mx-auto px-4 mt-2">
    <ul class="flex flex-col md:flex-row mb-2">
      <li class="mr-1">
          <a (click)="showUserProfile()" [class.active]="isUserProfile"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"PROFILE" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_local_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showCompanyUsers()" [class.active]="isCompanyUsers"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"USERS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showDepartmentSetup()" [class.active]="isDepartmentSetup"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DEPARTMENT_SETUP" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showGroups()" [class.active]="isGroups"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"PRODUCT_GROUPS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showCompanyInfo()" [class.active]="isCompanyInfo"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"COMPANY_INFO" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showArticles()" [class.active]="isArticles"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"ARTICLES" | translate}}</a>
      </li>
      <li *ngIf="subscription_stamped_SDS && subscribesToFreemium==false && currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user || currentUser.is_maintenance_user)" class="mr-1">
        <a (click)="showStampedSds()" [class.active]="isStampedSds"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SUBSCRIPTION_STAMPED_SDS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showMiscInfo()" [class.active]="isMisc"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"MISC" | translate}}</a>
      </li>
    </ul>
</div>

<div class="mr-8 mb-4 max-w-screen mb-40 md:mb-20" *ngIf="isGroups">
    <div class="flex flex-row justify-end m-4">
        <button class="button-cdocblue" title="{{'ADD_PRODUCT_GROUP' | translate}}" (click)="AddNewGroup()">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-lg text-white">{{ 'ADD_PRODUCT_GROUP' | translate }}</span>
        </button>
    </div>

    <div class="flex flex-col md:flex-row px-6 py-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4 mx-4">
        <ul class="list-disc list-inside mt-2 px-4">
            <li *ngFor="let group of groups" class="flex items-center">
                <span class="mr-40">{{ group.name }}</span>
                <span class="cursor-pointer ml-auto" (click)="editGroup(group)">
                    <img class="w-4 h-4" title="{{'EDIT' | translate}}" src="/images/icons/edit-svgrepo-com.svg"/>
                </span>
                <span class="cursor-pointer" (click)="removeGroup(group)">
                    <svg class="w-4 h-4 ml-1" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 12L14 16M14 12L10 16M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 
                        3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 
                        6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 
                        20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6" stroke="#DB2828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </li>
        </ul>
    </div>
</div>

<!--

<div class="ui fluid container" style="margin-top: 20px; margin-bottom: 20px">
    <button class="ui positive button" style="width:250px; margin-bottom: 20px" (click)="AddNewGroup()"><i class="ui icon add"></i>{{'ADD_PRODUCT_GROUP' | translate }}</button>
    <ul style="width:30%">
        <li *ngFor="let group of groups">
            {{ group.name }}
            <i class="ui icon red trash" style="float:right;padding-right: 2px;cursor:pointer"
            (click)="removeGroup(group)"></i>
            <i class="ui icon green pencil" style="float:right;padding-right: 2px;cursor:pointer"
            (click)="editGroup(group)"></i>
        </li>
    </ul>
</div>
-->

